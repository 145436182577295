import { useContext, useMemo } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { keyBy } from 'lodash';

import LightWeightCellWrapper from 'components/mdfEditor/fields/wrapper/LightWeightCellWrapper';
import UserContext from 'contexts/UserContext';
import { getMdfFieldMappings, MdfFieldMappings } from 'features/gridDeck/utils';
import { getMdfFromKeyedMdfs } from 'features/grids/common/components/utils/mdfUtils';
import { hasPermission } from 'features/mdf/mdf-utils';
import { ErrorMap } from 'hooks/useMdfErrorMap';
import type { Order } from 'types/forms/forms';
import {
  FieldTypeEnum,
  type FieldValue,
  type Mdf,
  type MdfField,
  type Metadata,
} from 'types/graphqlTypes';
import type { PlatformAccount } from 'types/members';

export type ColumnType = {
  mType?: string;
  mdfId?: string;
  mProperties?: {
    platformKind?: string;
    account?: PlatformAccount;
    platform?: string;
  };
  mdf?: Mdf;
  metadata: Metadata;
  errorMap?: ErrorMap;
};

export type ParsedOrderType = Omit<Order, 'metadata'> & {
  metadata: Metadata;
  mId: string;
  mdf?: Mdf;
  errorMap?: ErrorMap;
  validFieldMap?: Record<string, MdfField>;
};

interface GridWidgetMdfColumnProps {
  groups: string[];
  mdfFieldMappings: MdfFieldMappings;
}

const getMaxWidth = (fieldModel: MdfField) => {
  switch (fieldModel.type) {
    case FieldTypeEnum.checkbox:
      return 100;
    case FieldTypeEnum.choice:
      return 160;
    case FieldTypeEnum.text:
      return 300;
    default:
      return undefined;
  }
};

function getMdfColumnDefinitions<T extends ColumnType>({
  groups,
  mdfFieldMappings,
}: GridWidgetMdfColumnProps) {
  const columns: ColumnDef<T>[] = [];

  // Loop through all unique field models and generate columns.
  for (const [fieldId, fieldModels] of Object.entries(mdfFieldMappings)) {
    if (!fieldModels.some((m) => m.settings.visible)) continue;

    // Use the first model we find as source of truth for header generation.
    // The assumption is that in this kind of configuration that the configured labels
    // are equal or similar.
    const fieldModel = fieldModels[0];
    columns.push({
      accessorKey: `metadata.${fieldId}`,
      id: `metadata.${fieldId}`,
      header: fieldModel.settings?.label,
      maxSize: getMaxWidth(fieldModel),
      aggregatedCell: () => null,
      sortingFn: (a, b) => {
        // TODO: Extract this sorting function into a separate fn. Support for more field types.
        const aValue = a.original.metadata[fieldId];
        const bValue = b.original.metadata[fieldId];

        const stringifyValue = (value: FieldValue) => {
          if (value === null || value === undefined) return '';
          if (typeof value === 'object') return JSON.stringify(value);
          return value.toString();
        };

        const aString = stringifyValue(aValue);
        const bString = stringifyValue(bValue);

        if (aString === bString) return 0;
        if (aString === '') return -1;
        if (bString === '') return 1;
        return aString.localeCompare(bString);
      },
      cell: (context) => {
        const { cell, row, table } = context;
        const { metadata, mdf, errorMap } = row.original;
        const errorValue = errorMap ? errorMap[cell.column.id.split('_').pop() ?? ''] : '';

        // Preview
        const showPreview = () => {
          if (table.options.meta?.setPreview) {
            // @ts-expect-error - fix: combine order and member previews.
            table.options.meta?.setPreview(row.original);
          }
        };

        const actualFieldModel = fieldModels.find((m) => m.formId === row.original.mdfId);

        if (!actualFieldModel) {
          return null;
        }

        if (mdf) {
          return (
            <LightWeightCellWrapper
              key={`${row.id}-${fieldId}`}
              showPreview={showPreview}
              mdf={mdf}
              disableEdit={!hasPermission(mdf.permissions.write[fieldId], groups)}
              fieldModel={actualFieldModel}
              value={metadata[fieldId]}
              errorValue={errorValue}
              fieldSettings={actualFieldModel.settings}
              setValue={(newValue) => {
                if (newValue !== row.original.metadata[fieldId]) {
                  if (table.options.meta?.updateData) {
                    table.options.meta?.updateData(row.original, [{ fieldId, value: newValue }]);
                  }
                }
              }}
            />
          );
        }
        return <span key={`${row.id}-${fieldId}`}>{mdf ? 'Missing field' : 'Missing schema'}</span>;
      },
    });
  }

  return columns;
}

/**
 * Given a set of mdf ids, filter the provided keyed fields so only fields sitting in the provided
 * mdfId array are kept in the map
 *
 * @param mdfIds Set of unique mdf ids
 * @param keyedFields All keyed fields
 * @returns Filtered map, returning only fields belonging to mdfs in mdfIds
 */
const filterUnused = (mdfIds: Set<string>, keyedFields: MdfFieldMappings): MdfFieldMappings => {
  const ids = Array.from(mdfIds);
  const filtered: MdfFieldMappings = {};
  for (const [fieldId, models] of Object.entries(keyedFields)) {
    const modelIds = models.map((m) => m.formId);
    if (ids.some((id) => modelIds.includes(id))) {
      filtered[fieldId] = models;
    }
  }
  return filtered;
};

interface GetMdfColumnsParams<T> {
  items: T[];
  mdfs: Mdf[];
}

export function useGetMdfColumns<T extends ColumnType>({ items, mdfs }: GetMdfColumnsParams<T>) {
  // Groups user is a part of. Used to determine if a field has read/write
  const { groups } = useContext(UserContext);
  // Map of MDF schemas by ID.
  const keyedMdfs = useMemo(() => keyBy(mdfs, (m) => m.id), [mdfs]);
  // Map of all fields by field ID.
  const fieldMappings = useMemo(() => {
    return getMdfFieldMappings(keyedMdfs);
  }, [keyedMdfs]);

  // Set of MDF Ids used in the items.
  const usedMdfIds = new Set<string>();

  // Loop through member items and add mdfIds to the usedMdfIds set.
  for (const member of items) {
    // If the member has an MDF ID, add it to the usedMdfIds set.
    if (member.mdfId) {
      usedMdfIds.add(member.mdfId);
    } else {
      // If not, try to get default MDF by member type
      const id = getMdfFromKeyedMdfs({
        mType: member.mType,
        mProperties: member.mProperties,
        keyedMdfs,
      })?.id;
      if (id) usedMdfIds.add(id);
    }
  }

  const joinedUsedMdfIds = Array.from(usedMdfIds)
    .toSorted((a, b) => a.localeCompare(b))
    .join(',');

  const fieldModels = useMemo(
    () => filterUnused(usedMdfIds, fieldMappings),
    [joinedUsedMdfIds, fieldMappings],
  );

  const mdfColumns = useMemo(
    () => getMdfColumnDefinitions<T>({ mdfFieldMappings: fieldModels, groups }),
    [fieldModels, groups],
  );

  return mdfColumns;
}
