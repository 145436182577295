import {
  BACK_TIME_FIELD_KEY,
  CLIP_TIME_FIELD_KEY,
  CUME_TIME_FIELD_KEY,
  SPEAK_TIME_FIELD_KEY,
  TIMING_FIELDS,
  type TimingField,
  TOTAL_TIME_FIELD_KEY,
} from 'features/mdf/mdf-defaults';
import convertDurationToMillisecond from 'screens/rundown/utils/convertDurationToMillisecond';
import type { FieldValue, Metadata, TimingValue } from 'types/graphqlTypes';
import { isObject } from 'types/utilities';
import getTimeString from 'utils/getTimeString';

import { RundownCellData } from './types';

export const isTimingValue = (value: FieldValue): value is TimingValue => {
  if (!isObject(value)) return false;
  const maybe = value as Partial<TimingValue>;
  const ms = convertDurationToMillisecond(maybe?.value ?? maybe?.autoValue ?? '');
  return typeof ms === 'number';
};

export const hasTiming = (value: FieldValue) => {
  if (!value) return false;

  if (isTimingValue(value)) {
    const ms = convertDurationToMillisecond(value.value);
    return ms !== null && ms > 0;
  }
  return false;
};

export const isTimingField = (fieldId: string) => TIMING_FIELDS.includes(fieldId as TimingField);

const isPartOfTotal = (val: TimingField) =>
  [CLIP_TIME_FIELD_KEY, SPEAK_TIME_FIELD_KEY].includes(val);

const getPropToPlusWith = (val: TimingField): TimingField => {
  return val === CLIP_TIME_FIELD_KEY ? SPEAK_TIME_FIELD_KEY : CLIP_TIME_FIELD_KEY;
};

const getTimingValue = (val: TimingValue | undefined) => {
  if (val?.manual) return val.value;
  return val?.autoValue;
};

const getUpdatedTotal = (
  member: Pick<RundownCellData, 'metadata'>,
  fieldId: TimingField,
  value: TimingValue | null,
) => {
  const msValue = value ? convertDurationToMillisecond(getTimingValue(value)) : 0;
  const propToPlusWith = getPropToPlusWith(fieldId);
  const otherValue = convertDurationToMillisecond(
    getTimingValue(member.metadata[propToPlusWith] as TimingValue | undefined) ?? '00:00',
  );

  if (msValue === null && otherValue === null) return getTimeString(0);
  if (msValue === null && otherValue !== null) return getTimeString(otherValue);
  if (otherValue === null && msValue !== null) return getTimeString(msValue);
  return getTimeString(msValue! + otherValue!);
};

export const getClipDurationFromMetadata = (
  metadata: Metadata | undefined | null,
): TimingValue | null => {
  if (!metadata) return null;
  if (isTimingValue(metadata[CLIP_TIME_FIELD_KEY])) {
    return metadata[CLIP_TIME_FIELD_KEY];
  }
  return null;
};

export const getSpeakDurationFromMetadata = (
  metadata: Metadata | undefined | null,
): TimingValue | null => {
  if (!metadata) return null;
  if (isTimingValue(metadata[SPEAK_TIME_FIELD_KEY])) {
    return metadata[SPEAK_TIME_FIELD_KEY];
  }
  return null;
};

export const getBackTimeFromMetadata = (
  metadata: Metadata | undefined | null,
): TimingValue | null => {
  if (!metadata) return null;
  if (isTimingValue(metadata[BACK_TIME_FIELD_KEY])) {
    return metadata[BACK_TIME_FIELD_KEY];
  }
  return null;
};

export const getCumeTimeFromMetadata = (
  metadata: Metadata | undefined | null,
): TimingValue | null => {
  if (!metadata) return null;
  if (isTimingValue(metadata[CUME_TIME_FIELD_KEY])) {
    return metadata[CUME_TIME_FIELD_KEY];
  }
  return null;
};

export const getTotalTimeFromMetadata = (
  metadata: Metadata | undefined | null,
): TimingValue | null => {
  if (!metadata) return null;
  if (isTimingValue(metadata[TOTAL_TIME_FIELD_KEY])) {
    return metadata[TOTAL_TIME_FIELD_KEY];
  }
  return null;
};

export const getParsedTotalTimeFromMetadata = (
  metadata: Metadata | undefined | null,
): number | null => {
  const md = getTotalTimeFromMetadata(metadata);
  if (!md) return md;
  return convertDurationToMillisecond(md.value) ?? 0;
};

export const getTimingsFromMetadata = (
  metadata: Metadata | undefined | null,
): {
  [SPEAK_TIME_FIELD_KEY]: number;
  [TOTAL_TIME_FIELD_KEY]: number;
  [CLIP_TIME_FIELD_KEY]: number;
} => {
  if (!metadata)
    return {
      [SPEAK_TIME_FIELD_KEY]: 0,
      [TOTAL_TIME_FIELD_KEY]: 0,
      [CLIP_TIME_FIELD_KEY]: 0,
    };
  return {
    [SPEAK_TIME_FIELD_KEY]:
      convertDurationToMillisecond((metadata[SPEAK_TIME_FIELD_KEY] as TimingValue)?.value) ?? 0,
    [TOTAL_TIME_FIELD_KEY]:
      convertDurationToMillisecond((metadata[TOTAL_TIME_FIELD_KEY] as TimingValue)?.value) ?? 0,
    [CLIP_TIME_FIELD_KEY]:
      convertDurationToMillisecond((metadata[CLIP_TIME_FIELD_KEY] as TimingValue)?.value) ?? 0,
  };
};

const produceTotalTimeMetadataValue = (member: RundownCellData, value: string) => {
  if (isTimingValue(member.metadata[TOTAL_TIME_FIELD_KEY])) {
    return {
      ...member.metadata[TOTAL_TIME_FIELD_KEY],
      value,
    };
  } else {
    return {
      value,
    };
  }
};

/**
 * Takes care of updating timing fields, and consequently updating related fields.
 *
 * This function is intended to only be used via values input by an end user.
 * @param fieldId Identifier for the field that is being updated
 * @param member The row entity
 * @param value The new value
 */
export const applyTiming = (
  fieldId: TimingField,
  member: RundownCellData,
  value: TimingValue | null,
): Metadata => {
  const updatedTotal = isPartOfTotal(fieldId) ? getUpdatedTotal(member, fieldId, value) : undefined;
  return {
    [fieldId]: value,
    ...(updatedTotal && {
      [TOTAL_TIME_FIELD_KEY]: produceTotalTimeMetadataValue(member, updatedTotal),
    }),
  };
};

export const applyTimingToMember = (
  fieldId: TimingField,
  member: RundownCellData,
  value: TimingValue | null,
): RundownCellData => {
  const partialTimingData = applyTiming(fieldId, member, value);
  return {
    ...member,
    metadata: {
      ...member.metadata,
      ...partialTimingData,
    },
  };
};

export const calculateTotalTime = (members: RundownCellData[]) => {
  let time = 0;
  for (const member of Object.values(members)) {
    if (member.isFloated) continue;
    const instanceTotalTime = getTotalTimeFromMetadata(member.metadata)?.value;
    if (
      typeof instanceTotalTime === 'string' &&
      instanceTotalTime !== '00:00' &&
      instanceTotalTime !== '00:00:00'
    ) {
      time += convertDurationToMillisecond(instanceTotalTime) ?? 0;
    }
  }
  return time;
};
