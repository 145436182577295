import { useContext } from 'react';
import { Mutex } from 'async-mutex';
import { atom, useAtomValue, useSetAtom } from 'jotai';
import { molecule, useMolecule } from 'jotai-molecules';

import useToast from 'components/toast/useToast';
import ConfigContext from 'contexts/configContext';
import { InstanceScope } from 'features/instance/store/instance';
import { ColorVariants, KanbanStatus, Script } from 'types';
import defaultStateValues from 'utils/statusBoards/defaultStateValues';
import useLogger from 'utils/useLogger';

import useUpdateScript from '../api/useUpdateScript';

import { scriptMolecule } from '.';

// Mutex to prevent race conditions in state updates
export const scriptMStateMutex = new Mutex();

export const scriptStateMolecule = molecule((getMol, getScope) => {
  getScope(InstanceScope);

  const { kanbanBoardStates = [] as KanbanStatus[] } = useContext(ConfigContext);
  const { updateScript } = useUpdateScript();

  const { baseAtom } = getMol(scriptMolecule);

  const { error: errorLog } = useLogger('scriptStore:state');
  const { errorToast } = useToast();

  const mStateAtom = atom<
    | KanbanStatus
    | {
        backgroundColor: keyof ColorVariants;
        opacity: number;
        id: string;
      }
  >((get) => {
    const { script } = get(baseAtom);

    const kanbanState = kanbanBoardStates.find((s) => s.id === script?.mState);

    if (kanbanState)
      return { ...kanbanState, opacity: kanbanState.opacity ?? defaultStateValues.opacity };

    const backgroundColor: keyof ColorVariants = defaultStateValues.backgroundColor;
    const opacity = defaultStateValues.opacity;

    return {
      backgroundColor,
      opacity,
      id: 'todo',
    };
  });

  const updateStateAtom = atom(null, async (get, set, state: string) => {
    const { script, canUpdate } = get(baseAtom);

    if (!script || !canUpdate) return;

    const release = await scriptMStateMutex.acquire();

    try {
      set(baseAtom, (prev) => ({
        ...prev,
        isLoading: true,
        script: {
          ...(prev.script as Script),
          mState: state,
        },
      }));
      const response = await updateScript(
        {
          mId: script.mId,
          mRefId: script.mRefId,
          mState: state,
          audit: {
            message: 'update script state',
            source: 'scriptStateMolecule: updateStateAtom',
          },
          locked: script.locked || null,
        },
        true,
      );
      set(baseAtom, (prev) => ({
        ...prev,
        isLoading: false,
        script: {
          ...(prev.script as Script),
          mState: response?.mState || state,
        },
      }));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      errorToast(error, 'There was an error trying to lock story');
      if (error instanceof Error) errorLog(error);

      set(baseAtom, (prev) => ({
        ...prev,
        isLoading: false,
        script,
      }));
    } finally {
      release();
    }
  });

  return {
    updateStateAtom,
    mStateAtom,
  };
});

export const useScriptState = () => {
  const { mStateAtom, updateStateAtom } = useMolecule(scriptStateMolecule);

  const mState = useAtomValue(mStateAtom);
  const updateMState = useSetAtom(updateStateAtom);

  return {
    mState,
    updateMState,
  };
};
