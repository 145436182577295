import { memo, useCallback, useMemo, useState } from 'react';
import styled from '@emotion/styled';

import Radio from 'components/buttons/radioButton';
import {
  ColumnHeader,
  StyledText,
  ToolbarItem,
  ToolbarItemColumn,
  ToolbarItemLabel,
} from 'components/command/toolbar/styled';
import Popover from 'components/dialogs/PopoverBuilder';
import { CloseIcon } from 'components/orderFormDialog/styled';
import { CommandToolbarProps, Option, Order, SortOption } from 'features/command/command-types';
import { HStack } from 'layouts/box/Box';
import { SortDirection } from 'types/graphqlTypes';

import { sortToOption, ToolbarIcons } from './command-constants';

const orders: Order[] = [
  {
    key: 'desc',
    label: 'Descending',
  },
  {
    key: 'asc',
    label: 'Ascending',
  },
];

const Trigger = styled(Popover.Trigger)`
  all: unset;
`;

const Content = styled(Popover.Content)`
  min-width: fit-content;
`;

interface SortPopperProps {
  toolbarState: CommandToolbarProps;
  updateToolbarState: (newToolbarState: Partial<CommandToolbarProps>) => void;
  availableSorts: SortOption[];
}

const getSortLabel = (sortedBy: SortOption, ord: SortDirection) => {
  if (sortedBy === 'best') return 'Sort';
  return `Sorted by ${sortToOption[sortedBy].shortLabel}, ${ord}`;
};
const SortPopper = ({
  toolbarState,
  updateToolbarState,
  availableSorts = [],
}: Readonly<SortPopperProps>) => {
  const [open, setOpen] = useState(false);

  const availableOptions = useMemo(() => {
    if (availableSorts.length) {
      const opt: Option[] = [];
      for (const o of availableSorts) {
        opt.push(sortToOption[o]);
      }
      return opt;
    }
    return Object.values(sortToOption);
  }, [availableSorts]);

  const setSortOption = useCallback(
    (val: SortOption) => {
      updateToolbarState({ sortBy: val });
      setOpen(false);
    },
    [updateToolbarState],
  );

  const setSortDirection = useCallback(
    (val: SortDirection) => {
      updateToolbarState({ order: val });
      setOpen(false);
    },
    [updateToolbarState],
  );

  return (
    <Popover onOpenChange={setOpen} open={open}>
      <Trigger>
        <StyledText disabled variant="caption">
          <ToolbarIcons.Sort />
          {getSortLabel(toolbarState.sortBy, toolbarState.order)}
        </StyledText>
      </Trigger>
      <Content>
        <ToolbarItem>
          <ToolbarItemColumn>
            <ColumnHeader variant="overline">Sort results by</ColumnHeader>
            {availableOptions.map((op) => (
              <HStack key={op.key} onClick={() => setSortOption(op.key)} margin="0 0 4px 0">
                <Radio selected={toolbarState.sortBy === op.key} size={24} />
                <ToolbarItemLabel variant="listItemLabel">{op.label}</ToolbarItemLabel>
              </HStack>
            ))}
          </ToolbarItemColumn>
          <ToolbarItemColumn className={toolbarState.sortBy === 'best' ? 'disabled' : ''}>
            <ColumnHeader variant="overline">Sort direction</ColumnHeader>
            {orders.map((op) => (
              <HStack
                key={op.key}
                onClick={() => (toolbarState.sortBy !== 'best' ? setSortDirection(op.key) : {})}
                margin="0 0 4px 0"
              >
                <Radio selected={toolbarState.order === op.key} size={24} />
                <ToolbarItemLabel variant="listItemLabel">{op.label}</ToolbarItemLabel>
              </HStack>
            ))}
          </ToolbarItemColumn>
          <Popover.Close asChild>
            <CloseIcon />
          </Popover.Close>
        </ToolbarItem>
      </Content>
    </Popover>
  );
};

export default memo(SortPopper);
