import styled from '@emotion/styled';
import { ScopeProvider } from 'jotai-molecules';
import type { Instance } from 'types';

import { InstanceWithRightCollapsiblePane } from 'features/instance/Instance';
import { HStack } from 'layouts/box/Box';

import type { PreviewProps } from '../utils/childMap';

import { InstancePreviewScope } from './instancePreviewContext';

const StyledHStack = styled(HStack)`
  border: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
  border-radius: 8px;
  background-color: ${({ theme }) => theme.palette.dina.blackHoverOverlay};
`;

export default function InstanceDrawer({ member, onClose }: Readonly<PreviewProps>) {
  if (!member) {
    return <div>No preview found</div>;
  }

  return (
    <StyledHStack height="100%">
      <ScopeProvider scope={InstancePreviewScope} value="instance-preview">
        <InstanceWithRightCollapsiblePane
          instance={member as Instance}
          autoUpdate
          isSearchItem
          view="preview"
          onClose={onClose}
          onOpen={onClose}
        />
      </ScopeProvider>
    </StyledHStack>
  );
}
