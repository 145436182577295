import React from 'react';
import { TZDate } from '@date-fns/tz';
import { format } from 'date-fns';
import isHotkey from 'is-hotkey';
import { Editor, Element, Transforms } from 'slate';

import { elementTypes } from 'components/editor/constants';
import { EditorVariant } from 'components/editor/types';
import { matchRestriction } from 'components/editor/utils';
import { CustomElement } from 'types';
import { KeyMap } from 'types/keyboardShortcut';
import is24HourFormat from 'utils/dateTime/is24HourFormat';

import getIndentLevel from './getIndentLevel';
import onBackspace from './onBackspace';
import setIndentLevel from './setIndentLevel';

const { nodes } = Editor;

const isPrevented = (isCmsBlock: boolean, isAllowed: boolean, variant: EditorVariant) =>
  !isCmsBlock && isAllowed && matchRestriction(variant);

const isShiftOrTab = (isShiftTab: boolean, isShiftEnter: boolean, isTab: boolean) =>
  isShiftTab || isShiftEnter || isTab;

const getTimestamp = (timezone?: string): string => {
  const formatStr = is24HourFormat() ? 'HH:mm:ss' : 'hh:mm:ss a';
  return format(new TZDate(new Date(), timezone), formatStr);
};

/**
 * Handles onKeyDown event on paragraph element
 *
 * @param editor SlateJS editor instance
 * @param event React synthetic event
 * @param variant EditorVariant to count for
 * @param isAllowed Whether general block allowed
 * @param isCmsBlock Whether cms block allowed
 * @param maxIndentLevel Maximum allowed indentation level, default 3
 * @returns SlateJS editor instance
 */
const onParagraphKeyDown = (
  editor: Editor,
  event: React.KeyboardEvent<HTMLDivElement>,
  variant: EditorVariant,
  isAllowed: boolean,
  isCmsBlock: boolean,
  timezone: string,
  maxIndentLevel = 3,
  keyMap?: Record<string, KeyMap>,
) => {
  const [match] = nodes<CustomElement>(editor, {
    match: (node) => Element.isElement(node) && node.type === elementTypes.PARAGRAPH,
  });

  if (!match) return editor;

  const sequence = keyMap?.INSERT_TIMESTAMP?.sequences[0];
  const shortcut = sequence as string;

  const { key } = event;
  const isShiftTab = isHotkey('shift+tab')(event);
  const isShiftEnter = isHotkey('shift+enter')(event);
  // Should be configurable to other numbers down the line
  const isInsertTimestamp = isHotkey(shortcut)(event);
  const isTab = key === 'Tab';
  const isBackspace = key === 'Backspace';
  const shouldPrevent = isPrevented(isCmsBlock, isAllowed, variant) || isInsertTimestamp;

  if (shouldPrevent || isShiftOrTab(isShiftTab, isShiftEnter, isTab)) event.preventDefault();

  const [element, path] = match;
  if (isInsertTimestamp) {
    Transforms.insertText(editor, getTimestamp(timezone));
  }

  if (isShiftTab) {
    const indentLevel = getIndentLevel(element);
    if (indentLevel > 0) setIndentLevel(editor, path, indentLevel - 1);
  }

  if (!isShiftTab && isTab) {
    const indentLevel = getIndentLevel(element);
    if (indentLevel < maxIndentLevel) setIndentLevel(editor, path, indentLevel + 1);
  }

  if (isShiftEnter) Transforms.insertText(editor, '\n');

  if (isBackspace) onBackspace(editor, match, shouldPrevent, event);

  return editor;
};

export default onParagraphKeyDown;
