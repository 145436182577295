import Tabs from 'components/tabs/contained';
import { TabsContent } from 'lib/tabs';
import { useCurrentOrganization } from 'screens/main/components/header/navbar/settings/atomsTs';
import type { MemberType, Organization } from 'types/graphqlTypes';

const tabs = ['Details', 'Choose Users'];

import { useState } from 'react';

import Text from 'components/text';
import { Box, HStack } from 'layouts/box/Box';

import Details from './Details';
import Users from './Users';

const styleProps = {
  fullWidth: true,
};

interface OrganizationProps {
  groupPolicies: MemberType[];
}

function Component(componentProps: Readonly<Organization & OrganizationProps>) {
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const { groupPolicies, ...organization } = componentProps;

  return (
    <Box width="100%" height="100%" padding="8px">
      <HStack height="32px">
        <Text variant="h7" color="highEmphasis">{`Organizations -> ${organization.title}`}</Text>
      </HStack>
      <Box width="100%" height="100%" overflow="auto">
        <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} styleProps={styleProps}>
          <TabsContent value="Details">
            <Details {...organization} />
          </TabsContent>
          <TabsContent value="Choose Users">
            <Users orgId={organization.id} groupPolicies={groupPolicies} />
          </TabsContent>
        </Tabs>
      </Box>
    </Box>
  );
}

function Container({ groupPolicies }: Readonly<OrganizationProps>) {
  const [currentOrganization] = useCurrentOrganization();
  if (!currentOrganization) return <div />;
  return <Component {...currentOrganization} groupPolicies={groupPolicies} />;
}

export default Container;
