import { memo, useLayoutEffect, useMemo, useRef } from 'react';
import { noop } from 'lodash';

import { useTreeChoiceDialog } from 'components/treeChoiceDialog/TreeChoiceDialog';

import type CellProps from '../cellProps';
import { InlineTagWrapper } from '../multiplechoice/styled';

import TreeChoiceDefault from './TreeChoiceDefault';

/**
 * Designed to only render when editing - it will bring up
 * the tree choice dialog automatically.
 */
function TreeChoiceCell({
  fieldModel,
  fieldSettings,
  setValue,
  value,
  disableEdit,
  onFocus,
  onBlur,
}: Readonly<CellProps>) {
  const ref = useRef<HTMLDivElement | null>(null);
  const [, openTreeChoiceDialog] = useTreeChoiceDialog();
  const parsed: string[] = useMemo(() => {
    return Array.isArray(value) ? (value as string[]) : [];
  }, [value]);

  const openTreeChoice = () => {
    onFocus?.();
    openTreeChoiceDialog({
      open: true,
      fieldLabel: fieldSettings?.label ?? fieldModel.fieldId,
      selected: parsed,
      setSelected: (v: string[] | []) => {
        setValue(v);
      },
      onClose: () => {
        setTimeout(() => {
          ref.current?.closest('td')?.focus();
          onBlur?.();
        }, 100);
      },
      treeAlternatives: fieldModel.treeAlternatives ?? [],
      optionListId: fieldModel.optionListId,
    });
  };

  useLayoutEffect(() => {
    ref.current?.focus();
  }, []);

  return (
    <InlineTagWrapper
      ref={ref}
      onKeyDown={noop}
      tabIndex={disableEdit ? -1 : 0}
      onFocus={openTreeChoice}
    >
      <TreeChoiceDefault onClick={noop} choice={parsed} />
    </InlineTagWrapper>
  );
}

export default memo(TreeChoiceCell);
