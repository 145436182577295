import { useCallback } from 'react';

import AssigneesComponent from 'components/assignees/Assignees';
import useGetAssignees from 'hooks/useGetAssignees';
import useShareStory from 'hooks/useShareStory';
import { HStack } from 'layouts/box/Box';
import memberTypes from 'operations/memberTypes';
import { useMembersDialog } from 'store';
import { AssignedMember, User } from 'types/members';

import { GroupInline } from '../Group';

function Assignees({
  storyId,
  assignedMembers,
}: Readonly<{ storyId: string; assignedMembers?: User[] }>) {
  const [, setGetMembers] = useMembersDialog();
  const [, shareStoryWith] = useShareStory();

  const { getAssignees } = useGetAssignees(assignedMembers ?? [], false);
  const { assignedContacts } = getAssignees();

  const openDialog = useCallback((): void => {
    setGetMembers({
      variant: 'contact',
      open: true,
      removeTooltipText: 'Remove',
      dialogTitle: 'Add contacts to the story',
      subHeader: 'Add contacts:',
      startWith: assignedContacts as AssignedMember[],
      showMessage: true,
      doCallApi: true,
      onOk: (newMembers: AssignedMember[], messageAssign: string): void => {
        if (newMembers) {
          void shareStoryWith(
            storyId,
            newMembers,
            assignedContacts,
            memberTypes.STORY_CONTACT,
            messageAssign,
            true,
          );
        }
      },
    });
  }, [assignedContacts, setGetMembers, shareStoryWith, storyId]);

  return (
    <GroupInline label="Contacts">
      <HStack gap="4px">
        <AssigneesComponent
          members={[]}
          contacts={assignedContacts as AssignedMember[]}
          size={24}
          maxAvatarToShow={2}
          onClick={openDialog}
        />
      </HStack>
    </GroupInline>
  );
}

export default Assignees;
