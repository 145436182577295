import { useCallback, useContext } from 'react';
import { useMutation } from '@apollo/client';
import { isEmpty, keyBy } from 'lodash';

import { useGetMdfs } from 'api/mdf/useGetMdfs';
import { initialValues } from 'components/editor/constants';
import { EditorVariant } from 'components/editor/types';
import configCtx from 'contexts/configContext';
import useCheckUserRight from 'hooks/useCheckUserRight';
import useDinaNavigate from 'hooks/useDinaNavigate';
import useGetRundown from 'hooks/useGetRundown';
import useNewItems from 'hooks/useNewItems';
import useSettingsValue from 'hooks/useSettingsValue';
import memberTypes from 'operations/memberTypes';
import CREATE_INSTANCE from 'operations/mutations/createInstance';
import { useStoryMolecule } from 'screens/storyV2/store/story';
import { useStoryPaneMolecule } from 'screens/storyV2/store/storyPane';
import useStoryPanes from 'screens/storyV2/useStoryPanes';
import { useLoadingIndicator } from 'store';
import { useCurrentTabId, useCurrentTabTitle, useCurrentTabType } from 'store/tabs';
import { Instance, Platform } from 'types';
import { CreateInstanceInput, CreatePlatformInput, Mdf, MemberType } from 'types/graphqlTypes';
import getEmptyMetadataForForm from 'utils/getEmptyMetadata';
import getIdentifier from 'utils/instance/getAccountIdentifier';
import getPlatformMetadata from 'utils/instance/getPlatformMetadata';
import { PlatformData } from 'utils/instance/platform';
import updateStoryInstanceCache from 'utils/instance/updateStoryInstanceCache';
import { getDefaultValues } from 'utils/mdf/utils';
import { PlatformVariant } from 'utils/mergePlatforms';
import { getTwitterMetaKey } from 'utils/metadata';
import useLogger from 'utils/useLogger';

import { getErrorMessage } from '../commonKeys';

interface CreateInstance {
  createInstance: MemberType;
}

const getInstanceMdf = (properties: CreatePlatformInput, instanceMdfs: Mdf[]) => {
  if (!properties || !instanceMdfs) return;
  const byId = keyBy(instanceMdfs, (m) => m.id);
  const { platform, platformKind, account } = properties;
  if (platform === 'linear') {
    return platformKind === 'audio' ? byId['linear-audio'] : byId[platform];
  }
  return byId[platformKind ?? ''] ?? byId[account?.accountId ?? ''] ?? byId[platform ?? ''];
};

export const useCreateInstance = () => {
  const { mdfsSeparated } = useGetMdfs();
  const logger = useLogger('CreateInstance');
  const { navigateTo } = useDinaNavigate();
  const { metadataForms } = useContext(configCtx);
  const { updateStoryPane } = useStoryPanes();
  const form = metadataForms[0];
  const blankMetaData = getEmptyMetadataForForm(form);
  const { isWithinStoryScope } = useStoryMolecule();
  const { usePaneIndexValue, useNewlyAddedInstance, useCreatingInstance } = useStoryPaneMolecule();
  const paneIndex = usePaneIndexValue();
  const [, setNewlyAddedInstance] = useNewlyAddedInstance();
  const [, setCreatingInstance] = useCreatingInstance();

  const [storyId] = useCurrentTabId();
  const [title] = useCurrentTabTitle();
  const [storyType] = useCurrentTabType();

  const [, setLoadingIndicator] = useLoadingIndicator();
  const [getRundown] = useGetRundown();
  const [getSettingsValue] = useSettingsValue();
  const defaultReadSpeed = getSettingsValue('rundown.defaultReadSpeed') as string;
  const [checkUserRight] = useCheckUserRight();
  const canSeeNewCmsWorkflow = checkUserRight('feature', 'cms-blocks');
  const { addItem } = useNewItems();
  const [createInstanceForStory] = useMutation<CreateInstance>(CREATE_INSTANCE, {
    update: (proxy, mutationResult) => {
      const createInstance = mutationResult.data?.createInstance;
      if (!createInstance || storyId === null) return;
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      updateStoryInstanceCache(proxy, storyId, createInstance as Instance);
      if (mutationResult?.data?.createInstance?.mId) {
        addItem(mutationResult?.data?.createInstance);
        if (isWithinStoryScope) {
          setNewlyAddedInstance(createInstance as Instance);
          updateStoryPane(paneIndex ?? 0, 'instances', createInstance.mRefId);
        } else {
          navigateTo('story', storyId, {
            tab: 'instances',
            entityId: mutationResult?.data?.createInstance?.mId,
          });
        }
      }
    },
  });

  const createNewInstance = useCallback(
    async (
      platformData: PlatformData,
      text?: string,
      platformInfo?: Platform,
      platformVariant?: PlatformVariant,
    ) => {
      const { platform, account, platformKind } = platformData;
      if (!platform || !account) return;

      const hasPermission = checkUserRight(
        'platform',
        getIdentifier(platform, account?.accountTitle || ''),
      );

      if (!hasPermission) return;
      if (isWithinStoryScope) {
        setCreatingInstance(true);
        updateStoryPane(paneIndex ?? 0, 'instances');
      }

      const platformProperties = {
        __typename: 'PlatformType',
        platform,
        account: {
          accountUrl: account.accountUrl,
          accountLogo: account.accountLogo,
          accountTitle: account.accountTitle,
          accountId: account.accountId,
        },
      } as CreatePlatformInput;

      platformProperties.platformKind =
        (platformKind as string) ??
        (account.variant && account.variant !== platform ? account.variant : undefined);

      const mdf = getInstanceMdf(platformProperties, mdfsSeparated.instances);
      const defaultMetadataValues = mdf
        ? getDefaultValues({}, mdf, mdfsSeparated.subTypes)
        : undefined;

      const input = {
        mId: storyId,
        mTitle: title,
        mDescription: '',
        mProperties: platformProperties,
        mStoryId: storyId,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        mMetaData: getPlatformMetadata(platform, metadataForms, { title }),
        ...(!isEmpty(defaultMetadataValues) && { metadata: JSON.stringify(defaultMetadataValues) }),
      } as CreateInstanceInput;

      if (text) {
        const platformStructure =
          platformVariant?.platformStructure ?? platformInfo?.mProperties?.platformStructure;
        const isCmsBlock =
          platformStructure && platform === 'cms' && platformStructure.variant === 'blocks';

        const mContent = JSON.stringify(
          initialValues(platform as EditorVariant, true, isCmsBlock, canSeeNewCmsWorkflow, text),
        );
        input.mContent = mContent;
      }

      if (storyType === memberTypes.RESTRICTED_STORY) input.isRestricted = true;

      if (!account || !platform) return;

      setLoadingIndicator('create');

      if (platform === 'linear') input.mProperties!.account!.accountId = account.accountId ?? null;

      if (platform === 'twitter') {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        input.mMetaData!.push({
          key: getTwitterMetaKey(blankMetaData),
          value: 1,
        });
      }

      await createInstanceForStory({
        variables: {
          input,
        },
      });
      setLoadingIndicator('');
    },
    [
      checkUserRight,
      isWithinStoryScope,
      mdfsSeparated.instances,
      mdfsSeparated.subTypes,
      storyId,
      title,
      metadataForms,
      storyType,
      setLoadingIndicator,
      setCreatingInstance,
      updateStoryPane,
      paneIndex,
      createInstanceForStory,
      getRundown,
      defaultReadSpeed,
      blankMetaData,
      canSeeNewCmsWorkflow,
    ],
  );

  const createInstance = useCallback(
    async (
      platformData?: PlatformData,
      text = '',
      platform?: Platform,
      platformVariant?: PlatformVariant,
    ) => {
      try {
        if (platformData && platform)
          await createNewInstance(platformData, text, platform, platformVariant);
      } catch (err) {
        logger.log(getErrorMessage(err, platformData));
      }
    },
    [createNewInstance, logger],
  );
  return { createInstance };
};
