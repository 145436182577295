import { memo, useMemo } from 'react';
import styled from '@emotion/styled';

import AvatarGroup from 'components/assignees/AvatarGroup';
import Text from 'components/text';
import Tooltip from 'components/tooltip/Tooltip';
import useUserEnrichedItems, { ItemEnricher } from 'hooks/useEnrichedUsers';
import { getSessionUser, getUserIdFromParticipant, Participant } from 'hooks/useSharedResource';
import { User } from 'types/members';
import { SessionUser } from 'types/sessionUser';
import { getParseMetadata } from 'utils/getParsedMetadata';
import { getUserColor } from 'utils/userColor';

function getUserBorderColorFromVisibility(user: SessionUser) {
  return user.status === 'visible' ? getUserColor(user) : undefined;
}

function getUserKey(user: SessionUser) {
  return `${user.mId}@${user.sessionId}`;
}

/**
 * This function wraps `getSessionUser`, applying additional filtering to ensure
 * that users with `notListed` metadata are excluded.
 *
 * @param participant extra information about user as provided by `useSharedResource`
 * @param user {@link User} to be enriched to {@link SessionUser}
 * @returns `undefined` user is "not listed" otherwise a {@link SessionUser}.
 */
export function getListedSessionUser(
  participant: Participant<unknown>,
  user: User | undefined,
): SessionUser | undefined {
  return getParseMetadata(user?.metadata)?.notListed
    ? undefined
    : getSessionUser(participant, user);
}

const TooltipText = styled(Text)`
  white-space: pre-line;
`;

export interface ActiveUsersProps<T, U extends SessionUser> {
  participants: readonly Participant<T>[];
  enrichUser: ItemEnricher<Participant<T>, U>;

  /**
   * Optional function to get the border color for each user's avatar
   * (defaults to `getUserColor(user)` if `borderColor` is set to `'automatic'` and
   * `borderColor` otherwise)
   */
  getUserBorderColor?: (user: U) => string | undefined;

  /** Optional function to get the border width for each user's avatar (defaults to 2px) */
  getUserBorderWidth?: (user: U) => number;

  /** Optional function to get the opacity for a user */
  getUserOpacity?: (user: U) => number;

  maxAvatarToShow?: number;
}

function ActiveUsers<T, U extends SessionUser>({
  participants,
  enrichUser,
  maxAvatarToShow = 5,
  getUserBorderColor,
  getUserBorderWidth,
  getUserOpacity,
}: Readonly<ActiveUsersProps<T, U>>) {
  const otherUsers = useUserEnrichedItems(participants, getUserIdFromParticipant, enrichUser);
  const overflowUsers = Math.max(0, otherUsers.length - maxAvatarToShow);
  const users = overflowUsers > 0 ? otherUsers.slice(0, maxAvatarToShow) : otherUsers;

  const titleOverflow = Math.max(0, otherUsers.length - 10);
  const visibleTitles = titleOverflow > 0 ? otherUsers.slice(0, 10) : otherUsers;

  const tooltipTitle = useMemo(() => {
    const titles = visibleTitles.map((member) => member.mTitle).join('\n');
    return titleOverflow > 0 ? `${titles}\n...and ${titleOverflow} more` : titles;
  }, [visibleTitles, titleOverflow]);

  return (
    <Tooltip
      title={
        <TooltipText variant="caption" color="whiteHighEmphasis">
          <Text variant="caption" color="whiteMediumEmphasis">
            Active Users{'\n'}
          </Text>
          {tooltipTitle}
        </TooltipText>
      }
    >
      <div>
        <AvatarGroup
          users={users}
          getUserBorderColor={getUserBorderColor ?? getUserBorderColorFromVisibility}
          getUserBorderWidth={getUserBorderWidth}
          getUserOpacity={getUserOpacity}
          getUserKey={getUserKey}
          borderWidth={2}
          overflow={overflowUsers}
        />
      </div>
    </Tooltip>
  );
}

export default memo(ActiveUsers) as typeof ActiveUsers;
