import { useCallback } from 'react';
import { isEqual } from 'lodash';

import useUpdateMetadata from 'api/useUpdateMetadata';
import useToast from 'components/toast/useToast';
import { TimingField } from 'features/mdf/mdf-defaults';
import convertDurationToMillisecond from 'screens/rundown/utils/convertDurationToMillisecond';
import {
  DateToTimeString,
  isValidTimeString,
  TimeStringToDate,
} from 'screens/rundown/utils/updateDuration';
import { MemberTypeEnum, TimingValue } from 'types/graphqlTypes';
import { invariant } from 'types/invariant';

import { useRundownV2 } from '../state/rundown';
import { isSpecialRow } from '../utils/helper';
import {
  applyTiming,
  getBackTimeFromMetadata,
  getCumeTimeFromMetadata,
  getParsedTotalTimeFromMetadata,
  getTotalTimeFromMetadata,
} from '../utils/timing';
import type { CalculatedTimeData, RundownCellData, Segment } from '../utils/types';

const STABLE_ARR: Segment[] = [];
const STABLE_MAP: CalculatedTimeData = {};

/**
 * Produces segments of members based the value of BACK_TIME_FIELD_KEY,
 * going backwards from the end of the array to the start.
 */
export const getBackSegments = (orderedMembers: RundownCellData[]): Segment[] => {
  if (orderedMembers.length === 0) return STABLE_ARR;

  const newSegments: Segment[] = [];
  let currentSegment: Segment = {
    members: [],
    totalTime: 0,
  };
  for (let i = orderedMembers.length - 1; i >= 0; i--) {
    const member = orderedMembers[i];
    if (isSpecialRow(member.mId)) continue;
    const isEmptySegment = currentSegment.members.length === 0;
    const totalTime = getParsedTotalTimeFromMetadata(member.metadata);
    const md = getBackTimeFromMetadata(member.metadata);
    const hasValidTiming = isValidTimeString(md?.value);
    const isHardHit = hasValidTiming && isEmptySegment;
    const belongsToCurrentSegment = !isEmptySegment && !hasValidTiming;

    // Add to current segment
    if (isHardHit || belongsToCurrentSegment) {
      currentSegment.members.push(member);
      currentSegment.totalTime = totalTime
        ? currentSegment.totalTime + totalTime
        : currentSegment.totalTime;
    }

    // Create new segment if we find a new hit time
    // while we already have a segment
    if (hasValidTiming && !isEmptySegment) {
      newSegments.push(currentSegment);
      currentSegment = {
        members: [member],
        totalTime: 0,
      };
    }
  }

  if (currentSegment.members.length) {
    newSegments.push(currentSegment);
  }

  return newSegments;
};

/**
 * Produces segments of members based on the value of CUME_TIME_FIELD_KEY
 * going forward from the start of the array to the end.
 */
export const getCumeSegments = (
  orderedMembers: RundownCellData[],
  currentlyPlayingInstance?: string,
): Segment[] => {
  if (orderedMembers.length === 0) return STABLE_ARR;

  const newSegments: Segment[] = [];
  let currentSegment: Segment = {
    members: [],
    totalTime: 0,
  };

  for (const member of orderedMembers) {
    if (isSpecialRow(member.mId)) continue;

    const isCurrentlyPlaying = member.mId === currentlyPlayingInstance;

    const isEmptySegment = currentSegment.members.length === 0;

    const totalTime = getParsedTotalTimeFromMetadata(member.metadata);
    const md = getCumeTimeFromMetadata(member.metadata);
    const hasValidTiming = isValidTimeString(md?.value);

    const isFirstMember = hasValidTiming && isEmptySegment;
    const belongsToCurrentSegment = !isCurrentlyPlaying && !isEmptySegment && !hasValidTiming;

    // Add to current segment
    if (isFirstMember || belongsToCurrentSegment) {
      currentSegment.members.push(member);
      currentSegment.totalTime = totalTime
        ? currentSegment.totalTime + totalTime
        : currentSegment.totalTime;
    }

    // Create new segment if we find a new cume time
    // while we already have a segment, or if the current instance
    // is playing.
    if (isCurrentlyPlaying || (hasValidTiming && !isEmptySegment)) {
      newSegments.push(currentSegment);
      currentSegment = {
        members: [member],
        totalTime: 0,
      };
    }
  }

  if (currentSegment.members.length) {
    newSegments.push(currentSegment);
  }

  return newSegments;
};

/**
 * Loop through the cume segments to produce timings off of each member. The member
 * in first position is assumed to be the base. All consequent members will
 * add its predecessors' total time to its timestamp.
 * @param map The cumulative map to update
 * @param backSegments the segments to process
 */
const handleCumeSegments = (
  map: CalculatedTimeData,
  cumeSegments: Segment[],
  currentInstance: string | undefined,
) => {
  const isPlaying = currentInstance !== undefined;
  for (const segment of cumeSegments) {
    const cumeTime = getCumeTimeFromMetadata(segment.members[0].metadata);
    invariant(cumeTime, 'Expected cumetime set');
    const startTime =
      isPlaying && segment.members[0].cumeOverrideTime
        ? TimeStringToDate(segment.members[0].cumeOverrideTime)
        : TimeStringToDate(cumeTime.value);
    let acc = startTime.getTime();
    for (let i = 0; i < segment.members.length; i++) {
      const member = segment.members[i];
      const override = member.cumeOverrideTime ?? DateToTimeString(acc);
      const durationValue = getTotalTimeFromMetadata(member.metadata);
      const msTotalForInstance = convertDurationToMillisecond(durationValue?.value);
      const data = map[member.mId] ?? { cume: null, back: null };
      const valueToDisplay = isPlaying ? override : DateToTimeString(acc);

      data.cume = {
        isBase: false,
        value: valueToDisplay ?? '',
      };

      if (i === 0) {
        data.cume.isBase = true;
      }

      map[member.mId] = data;
      acc += msTotalForInstance ?? 0;
    }
  }
};

/**
 * Loop through the back segments to produce timings off of each member. The member
 * in first position is assumed to be the base. All consequent members will
 * subtract its predecessors' total time to its timestamp.
 * @param map The cumulative map to update
 * @param backSegments the segments to process
 */
const handleBackSegments = (map: CalculatedTimeData, backSegments: Segment[]) => {
  for (const segment of backSegments) {
    const backTime = getBackTimeFromMetadata(segment.members[0].metadata);
    invariant(backTime, 'Expected back time set');
    const endTime = TimeStringToDate(backTime.value);
    let backAcc = endTime.getTime();

    // First member in segment should retain its back time value
    const firstData = map[segment.members[0].mId] ?? { cume: null, back: null };
    firstData.back = {
      isBase: true,
      value: backTime.value,
    };
    map[segment.members[0].mId] = firstData;

    for (let i = 1; i < segment.members.length; i++) {
      const member = segment.members[i];
      const durationValue = getTotalTimeFromMetadata(member.metadata);
      const msTotalForInstance = convertDurationToMillisecond(durationValue?.value);
      const data = map[member.mId] ?? { cume: null, back: null };
      backAcc -= msTotalForInstance ?? 0;
      data.back = {
        isBase: false,
        value: DateToTimeString(backAcc),
      };
      map[member.mId] = data;
    }
  }
};

/**
 * Based off of segments, produce the accumulative time starting with its first
 * member as the start point.
 */
export const produceTimings = (
  cumeSegments: Segment[],
  backSegments: Segment[],
  currentInstance?: string,
): CalculatedTimeData => {
  if (cumeSegments.length === 0 && backSegments.length === 0) return STABLE_MAP;
  const updatedMap: CalculatedTimeData = {};
  handleCumeSegments(updatedMap, cumeSegments, currentInstance);
  handleBackSegments(updatedMap, backSegments);
  return updatedMap;
};

export const generateTimings = (orderedInstances: RundownCellData[]) => {
  return produceTimings(getCumeSegments(orderedInstances), getBackSegments(orderedInstances));
};

export function useUpdateTimingData(type: 'ready' | 'preparing') {
  const updateMetadata = useUpdateMetadata();
  const { errorToast } = useToast();
  const { useUpdateInstances } = useRundownV2();
  const updateInstanceCache = useUpdateInstances();

  const updateTiming = useCallback(
    (mId: string, newValue: TimingValue, id: TimingField) => {
      updateInstanceCache((prev) => {
        const allMembersForType = prev[type];
        const member = allMembersForType[mId];
        const updatedPartialMetadata = applyTiming(id, member, newValue);

        const updatedMetadata = {
          ...member.metadata,
          ...updatedPartialMetadata,
        };

        if (isEqual(member.metadata, updatedMetadata)) {
          return prev;
        }

        updateMetadata(
          mId,
          mId,
          updatedPartialMetadata,
          member.metadata,
          MemberTypeEnum.Instance,
          'linear',
          member.mTitle,
          true,
          true,
        ).catch(errorToast);

        const newMember: RundownCellData = {
          ...member,
          metadata: updatedMetadata,
        };

        return {
          ...prev,
          [type]: {
            ...allMembersForType,
            [mId]: newMember,
          },
        };
      });
    },
    [updateInstanceCache, updateMetadata, type],
  );

  return updateTiming;
}
