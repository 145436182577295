import styled from '@emotion/styled';
import * as DialogPrimitive from '@radix-ui/react-dialog';

import { dialogBoxShadow } from 'theme/utils/commonStyles';

export const Overlay = styled(DialogPrimitive.Overlay)`
  background-color: ${({ theme }) => theme.palette.dina.dialogBackdropOverlay};
  position: fixed;
  inset: 0;
  backdrop-filter: blur(2px);
  z-index: 1300;
`;

export const Content = styled(DialogPrimitive.Content)`
  ${dialogBoxShadow};
  z-index: 1300;
  position: fixed;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 32rem;
  height: fit-content;
  gap: 8px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.palette.dina.surfaceDialogs};
  &[data-modal='true'] {
    background-color: ${({ theme }) => theme.palette.dina.surfaceModal};
  }

  transition-property: opacity, transform;
  transition-duration: 200ms;

  &.command {
    top: 10vh;
    max-height: 75vh;
    overflow: hidden;
    margin-top: 0;
    :focus-visible {
      outline: none;
    }
  }

  &.dnd {
    margin: 0;
  }

  &[data-state='open'] {
    animation: fadeIn 0.2s ease-out;
  }
  &[data-state='closed'] {
    animation: fadeOut 0.2s ease-out;
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }
`;

export const Close = styled(DialogPrimitive.Close)`
  position: relative;
  right: -12px;
  height: 40px;
  width: 40px;
  border-radius: 6px;
  opacity: 0.7;
  background-color: transparent;
  cursor: pointer;
  border: none;
  &:hover {
    opacity: 1;
  }
  &:focus {
    opacity: 1;
    outline: none;
  }
`;

export const Header = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline: 12px;
  background-color: ${({ theme }) => theme.palette.dina.overlaysHeaderFooter};
  border-bottom: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
  height: 40px;
  user-select: none;

  &.dragHandler {
    cursor: grab;
  }
`;

export const Footer = styled('div')`
  height: 48px;
  border-top: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
  background-color: ${({ theme }) => theme.palette.dina.overlaysHeaderFooter};
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;
`;

export const Title = styled(DialogPrimitive.Title)`
  ${({ theme }) => theme.typography.dina.h7};
  color: ${({ theme }) => theme.palette.dina.highEmphasis};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
