/* eslint-disable import/no-extraneous-dependencies */
import { TZDate } from '@date-fns/tz';
import { format } from 'date-fns';
import get from 'lodash/get';

type TemplateVariables = {
  version: string;
  versionDate: string;
  branchName: string;
  region: string;
};

const getVersionDate = (
  timezone: string,
  sdate = import.meta.env.REACT_APP_VERSION_DATE as string,
) => {
  if (!sdate) return;
  const vdate = new TZDate(sdate, timezone);
  return `${format(vdate, 'dd/MM/yyyy hh:mm:ss a')}`;
};

const transform = (templateString: string, templateVariables: TemplateVariables) =>
  templateString.replace(/\${(.*?)}/g, (_, key: string) => get(templateVariables, key) as string);

export const formatAppTitle = (applicationTitleFormat: string | undefined, timezone: string) => {
  if (!applicationTitleFormat) return '';

  return transform(applicationTitleFormat, {
    version: (import.meta.env.REACT_APP_VERSION as string) || '',
    versionDate: getVersionDate(timezone) ?? '',
    branchName: (import.meta.env.REACT_APP_REPO_BRANCHNAME as string) || '',
    region: (import.meta.env.REACT_APP_AWS_APPSYNC_REGION as string) || '',
  });
};
