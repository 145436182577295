import { events } from 'aws-amplify/api';

import { ChannelState, CommunicationChannel, SharingEvent } from './types';
import { broadcastEvent, isTestEventData, unicastEvent } from './utils';

/**
 * Maps from a combo of `channelNamespace` and target IDs of the targets of this session
 * (browser tab) to the event handler registered for that target.
 * This makes it possible to call those handlers directly when unicasting events to those targets
 * instead of using appsync events.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SESSION_TARGET_HANDLERS: Record<string, (event: SharingEvent<any>) => void> = {};

export function createAppsyncEventsCommunicationChannel<T>(
  channelNamespace: string,
  myId: string,
): CommunicationChannel<T> {
  const sessionMapKey = `${channelNamespace}:${myId}`;
  let isConnecting = false;
  let releaseConnection: (() => void) | undefined = undefined;

  return {
    get state() {
      if (isConnecting) return ChannelState.Connecting;
      return releaseConnection ? ChannelState.Connected : ChannelState.Disconnected;
    },
    send: (targetId, event) => {
      if (targetId === null) {
        broadcastEvent(channelNamespace, event);
      } else {
        const targetSessionMapKey = `${channelNamespace}:${targetId}`;
        const inSessionHandler = SESSION_TARGET_HANDLERS[targetSessionMapKey];
        if (inSessionHandler) {
          setTimeout(() => {
            if (SESSION_TARGET_HANDLERS[targetSessionMapKey] === inSessionHandler) {
              inSessionHandler(event);
            }
          }, 0);
        } else {
          unicastEvent(channelNamespace, event, targetId);
        }
      }
    },
    connectAsync: async (eventHandler) => {
      isConnecting = true;
      const toBeReleased: (() => void)[] = [];
      const releaser = () => toBeReleased.forEach((release) => release());
      const aborted = () => {
        releaser();
        return false;
      };

      // Register for unicast shortcut
      SESSION_TARGET_HANDLERS[sessionMapKey] = eventHandler;
      toBeReleased.push(() => delete SESSION_TARGET_HANDLERS[sessionMapKey]);

      // Connect unicast channel
      const myChannel = await events.connect(`${channelNamespace}/${myId}`);
      toBeReleased.push(() => myChannel.close());
      if (!isConnecting) return aborted();

      // Connect broadcast channel
      const channel = await events.connect(channelNamespace);
      toBeReleased.push(() => channel.close());
      if (!isConnecting) return aborted();

      // Subscribe to events in both channels
      const mySubscription = myChannel.subscribe({
        next: (data) => {
          if (!isTestEventData<T>(data) || data.event.sourceId === myId) return;
          eventHandler(data.event);
        },
        // eslint-disable-next-line no-console
        error: (err) => console.error('broadcast event receive error:', err),
      });
      toBeReleased.push(() => mySubscription.unsubscribe());
      const subscription = channel.subscribe({
        next: (data) => {
          if (!isTestEventData<T>(data) || data.event.sourceId === myId) return;
          eventHandler(data.event);
        },
        // eslint-disable-next-line no-console
        error: (err) => console.error('unicast event receive error:', err),
      });
      toBeReleased.push(() => subscription.unsubscribe());
      isConnecting = false;
      releaseConnection = releaser;

      return true;
    },
    disconnect() {
      if (isConnecting) {
        isConnecting = false;
      } else if (releaseConnection) {
        releaseConnection();
        releaseConnection = undefined;
      }
    },
  };
}
