import { useRef } from 'react';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';

import useGetPlatforms from 'hooks/useGetPlatforms';
import { HStack } from 'layouts/box/Box';
import { InstanceAggregation } from 'types';

import Badge from './Badge';
import HiddenBadges from './HiddenBadges';
import { getIconByPlatform } from './icons';
import useVisibleBadges, { BADGE_GAP, BADGE_WITH_GAP } from './useVisibleBadges';
import { transformKey } from './utils';

interface InstanceBadgesProps {
  badgeCountsByType?: InstanceAggregation;
  maxWidth?: number;
  maxLines?: number;
}

function InstanceBadges({
  badgeCountsByType,
  maxWidth,
  maxLines = 1,
}: Readonly<InstanceBadgesProps>) {
  const { platformVariants } = useGetPlatforms(new Date());
  const containerRef = useRef<HTMLDivElement>(null);
  const { visibleBadges, hiddenBadges } = useVisibleBadges(
    maxLines,
    containerRef,
    badgeCountsByType,
  );

  if (!badgeCountsByType || isEmpty(badgeCountsByType)) return null;

  const badgeKeys = Object.keys(omit(badgeCountsByType, 'total'));
  const containerWidth = maxWidth
    ? `${Math.min(badgeKeys.length * BADGE_WITH_GAP, maxWidth)}px`
    : '100%';

  return (
    <HStack gap={`${BADGE_GAP}px`} width={containerWidth} flexWrap="wrap" ref={containerRef}>
      {visibleBadges.map((key) => {
        const Icon = getIconByPlatform(key, platformVariants);
        return (
          <Badge
            key={key}
            badgeType={transformKey(key)}
            count={badgeCountsByType[key]}
            icon={<Icon className="skipOverride" />}
          />
        );
      })}
      {hiddenBadges.length > 0 && (
        <HiddenBadges hiddenBadges={hiddenBadges} badgeCountsByType={badgeCountsByType} />
      )}
    </HStack>
  );
}

export default InstanceBadges;
