/* eslint-disable import/prefer-default-export */
import { atom, useAtom } from 'jotai';

/**
 * Set cookie for current domain
 *
 * @param {String[]} cookies array of cookie values to be set
 * @param {Object} options value to concatenate at the end of cookie i.e. max-age, secure
 */

export const setCookies = (cookies) => {
  cookies.forEach((cookie) => {
    document.cookie = cookie;
  });
};

const cookieKeys = ['CloudFront-Policy', 'CloudFront-Key-Pair-Id', 'CloudFront-Signature'];

/**
 * Check whether the cookies have valid keys
 *
 * @param cookies arrays of cookie
 * @returns {Boolean}
 */

const hasValidCookie = (cookies) =>
  cookies.reduce((acc, currentValue) => {
    const [key] = currentValue.split('=', 1);
    return cookieKeys.includes(key);
  }, false);

/* Cookie atom */

const cookiesAtom = atom([]);
const signedCookieAtom = atom(
  (get) => get(cookiesAtom),
  (_get, set, nextValue) => {
    const newCookies = nextValue?.cookies || [];
    if (hasValidCookie(newCookies)) {
      setCookies(newCookies);
      set(cookiesAtom, newCookies);
    }
  },
);

export const useSignedCookie = () => useAtom(signedCookieAtom);
