/**
 * This module contains functionality for tree or list views where the tree or list view
 * is rendered with a UL tag and the items/nodes in the list/tree are all represented with LI
 * tags each containing a `Button` to hold item focus and visualize item selection
 * (the BUTTON's DOM attribute `data-selected `must be set to true for the selected item).
 * The BUTTON must be a directly contained in the LI or with a single DIV between.
 * @module
 */

/**
 * Moves the focus up or down inside a tree view or list view where the items are represented
 * by LI tags containing a `Button` where the button's selected stated is used to visualize
 * the selection in the collection view. (the DOM attribute data-selected is set to true
 * for the)
 * @param treeElement The DOM element representing the tree view
 * @param direction   The direction to move
 */
export function moveUlViewFocus(treeElement: HTMLUListElement | null, direction: 'up' | 'down') {
  if (!treeElement) return;
  const current = document.activeElement;
  if (current?.tagName.toLowerCase() !== 'button') return;
  const buttons: NodeListOf<HTMLButtonElement> = treeElement.querySelectorAll(
    'li>button[data-selected], li>div>button[data-selected]',
  );
  const focusIndex = Array.from(buttons).indexOf(current as HTMLButtonElement);
  if (focusIndex < 0) return;
  const newFocusIndex = focusIndex + (direction === 'up' ? -1 : 1);
  if (newFocusIndex >= 0 && newFocusIndex < buttons.length) {
    buttons[newFocusIndex].focus();
  }
}

/**
 * Focuses the item in view that contains a given `HTMLElement`
 * @param el The `HTMLElement` that is contained in the tree/list view node
 */
export function focusEnclosingListItem(el: HTMLElement | null) {
  do {
    el = el?.parentElement ?? null;
  } while (el && el.tagName !== 'BUTTON' && !el.hasAttribute('data-selected'));
  (el as HTMLButtonElement | null)?.focus();
}

/**
 * Focuses the the tree or list view node that is selected
 * @param ulRoot The `HTMLUListElement` that contains the whole rendered tree or list
 */
export function focusSelectedListItem(ulRoot: HTMLUListElement | null, defer = false) {
  if (!ulRoot) return;
  const ul = ulRoot;
  function focus() {
    const nodes: NodeListOf<HTMLButtonElement> = ul.querySelectorAll(
      'li>button[data-selected=true], li>div>button[data-selected=true]',
    );
    nodes.item(0)?.focus();
  }
  if (defer) setTimeout(focus, 0);
  else focus();
}
