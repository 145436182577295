import { useContext } from 'react';
import { Mutex } from 'async-mutex';
import { atom, useAtomValue, useSetAtom } from 'jotai';
import { molecule, useMolecule } from 'jotai-molecules';

import UserContext from 'contexts/UserContext';
import { InstanceScope } from 'features/instance/store/instance';
import { EditorValue, Script } from 'types';
import { resetLockToken } from 'utils/lock/lockTokenV2';
import { isSubscriptionUpdateNeeded } from 'utils/subscription/isSubscriptionUpdateNeeded';

import useScriptScope from '../hooks/useScriptScope';

export interface ScriptState {
  script: Script | null;
  content: EditorValue | null;
  isLocked: boolean;
  lockedBy: string | null;
  isLoading: boolean;
  isSaving: boolean;
  canUpdate: boolean;
  scriptDuration: string;
}

// Mutex to prevent race conditions in locking, unlocking and updating operations
export const scriptStateMutex = new Mutex();

export const scriptMolecule = molecule((_getMol, getScope) => {
  getScope(InstanceScope);

  const { mId: currentUserId } = useContext(UserContext);
  const currentEditingScope = useScriptScope();

  const baseAtom = atom<ScriptState>({
    script: null,
    content: null,
    isLocked: false,
    lockedBy: null,
    isLoading: false,
    isSaving: false,
    canUpdate: false,
    scriptDuration: '00:00',
  });

  const updateScriptAtom = atom(null, (get, set, script: Script | null) => {
    /** check if we should update state for subscription data */
    if (!isSubscriptionUpdateNeeded(script, get(baseAtom).script as Script, currentUserId)) return;

    set(baseAtom, (prev) => ({
      ...prev,
      script: script,
      isLocked: !!script?.locked,
      lockedBy: script?.locked ?? null,
    }));

    if (!script?.locked) resetLockToken(currentEditingScope);
  });

  const updateEditPermissionAtom = atom(null, (get, set, canUpdate: boolean) => {
    set(baseAtom, (prev) => ({ ...prev, canUpdate }));
  });

  return {
    baseAtom,
    updateScriptAtom,
    updateEditPermissionAtom,
  };
});

export const useScriptStore = () => {
  const { baseAtom, updateScriptAtom, updateEditPermissionAtom } = useMolecule(scriptMolecule);

  const state = useAtomValue(baseAtom);
  const updateState = useSetAtom(updateScriptAtom);

  const updateEditPermission = useSetAtom(updateEditPermissionAtom);

  return {
    state,
    updateState,
    updateEditPermission,
  };
};
