import { useQuery, WatchQueryFetchPolicy } from '@apollo/client';

import GET_ASSET from 'operations/queries/getAsset';
import { Asset } from 'types';

interface GetAsset {
  getAsset: Asset;
}

const useGetAsset = (
  mId?: string,
  mRefId?: string,
  skip: boolean = false,
  fetchType: WatchQueryFetchPolicy = 'cache-and-network',
) => {
  const { data, error, loading } = useQuery<GetAsset>(GET_ASSET, {
    variables: {
      input: {
        mId,
        mRefId,
      },
    },
    fetchPolicy: fetchType,
    nextFetchPolicy: fetchType,
    skip: skip || !mId || !mRefId,
  });

  if (skip) return [null];

  const asset = data?.getAsset;

  return [asset, error, loading] as const;
};

export default useGetAsset;
