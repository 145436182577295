import { useState } from 'react';
import styled from '@emotion/styled';

import Tabs from 'components/tabs/regular/Tabs';
import { CommandToolbarProps } from 'features/command/command-types';
import { TabsContent as RTabsContent, TabsList, TabsTrigger } from 'lib/tabs';
import { SearchItemTypeEnum } from 'types/graphqlTypes';

import { TabsWrapper } from '../MemberDetails';

import Past from './Past';
import Upcoming from './Upcoming';

const OuterWrapper = styled('div')`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  min-height: 40px;
`;

export const TabsListWrapper = styled(TabsList)`
  width: 100%;
  display: flex;
  padding: 1px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.palette.dina.buttonBorderOutlined};
`;

export const TabTriggerButton = styled(TabsTrigger)`
  all: unset;
  width: 50%;
  height: 28px;
  overflow: hidden;
  font-family: inherit;
  background-color: transparent;
  ${({ theme }) => theme.typography.dina.tabLabel};
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  cursor: pointer;
  position: relative;
  white-space: nowrap;
  border: none;
  border-radius: 4px;
  &:hover {
    color: ${({ theme }) => theme.palette.dina.highEmphasis};
  }
  &[data-state='active'] {
    background-color: ${({ theme }) => theme.palette.dina.onSelected};
    border-radius: 4px;
    color: ${({ theme }) => theme.palette.dina.whiteHighEmphasis};
  }
`;

const TabsContent = styled(RTabsContent)`
  width: 100%;
  height: 100%;
  outline: none;
  flex-grow: 1;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  overflow: hidden;
  &[data-state='active'] {
    display: flex;
  }
`;

export const baseToolbarState: CommandToolbarProps = {
  sortBy: 'scheduledAt',
  order: 'desc',
  rangeBy: null,
  defaultMdfId: null,
  mdfId: '',
  isFiltering: false,
  statusFilter: [],
  mTypes: [
    SearchItemTypeEnum.story,
    SearchItemTypeEnum.instance,
    SearchItemTypeEnum.rundown,
    SearchItemTypeEnum.order,
    SearchItemTypeEnum.draft,
  ],
  assignedIds: [],
  createdByIds: [],
};

const menuTabs = ['Upcoming', 'Past'];

export default function Activity({ memberId }: Readonly<{ memberId: string }>) {
  const [activeTab, setActiveTab] = useState('Upcoming');

  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
  };

  return (
    <TabsWrapper>
      <Tabs
        defaultTab={menuTabs[0]}
        activeTab={activeTab}
        direction="vertical"
        setActiveTab={handleTabClick}
      >
        <OuterWrapper>
          <TabsListWrapper>
            {menuTabs.map((tab) => (
              <TabTriggerButton key={tab} value={tab}>
                {tab}
              </TabTriggerButton>
            ))}
          </TabsListWrapper>
        </OuterWrapper>

        <TabsContent value="Upcoming">
          <Upcoming memberId={memberId} />
        </TabsContent>

        <TabsContent value="Past">
          <Past memberId={memberId} />
        </TabsContent>
      </Tabs>
    </TabsWrapper>
  );
}
