import { memo } from 'react';

import Divider from 'components/divider';
import Scrollbar from 'components/scrollbar';
import { Box } from 'layouts/box/Box';

import useWorkflowSettings from './useWorkflowSettings';
import WorkflowGroup from './WorkflowGroup';

const WorkflowSettings = () => {
  const {
    selectedMenu,
    states,
    scheduleWorkflowOn,
    stateOnSchedule,
    approvalWorkflowOn,
    stateOnApproval,
    copyWorkflowOn,
    stateOnCopy,
    toggleScheduleWorkflow,
    toggleApprovalWorkflow,
    toggleCopyWorkflow,
    onScheduledStateChange,
    onApprovalStateChange,
    onCopyStateChange,
  } = useWorkflowSettings();

  return (
    <Scrollbar>
      <Box
        container
        height="100%"
        width="100%"
        padding="1rem"
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="flex-start"
        gap="0.5rem"
        overflow="visible"
      >
        {selectedMenu !== 'linear' && (
          <>
            <WorkflowGroup
              title="Scheduling Workflow"
              description="Automatically change the Workflow State when an Instance is scheduled."
              switchSelected={scheduleWorkflowOn}
              onSwitchClick={toggleScheduleWorkflow}
              stateOn={stateOnSchedule}
              states={states}
              onStateChange={onScheduledStateChange}
              stateLabel="Change Workflow State when Scheduling"
              subChoiceLabel="When scheduling, automatically change Workflow State to:"
            />
            <Divider flexItem style={{ width: '100%', height: '1px' }} />
            <WorkflowGroup
              title="Approval Workflow"
              description="Require approval from designated User Groups before 
              publishing an Instance."
              switchSelected={approvalWorkflowOn}
              onSwitchClick={toggleApprovalWorkflow}
              stateOn={stateOnApproval}
              states={states}
              onStateChange={onApprovalStateChange}
              stateLabel="Require Approval before Publishing"
              subChoiceLabel="Publishing can only be done when Workflow State is:"
            />
            <Divider flexItem style={{ width: '100%', height: '1px' }} />
          </>
        )}

        <WorkflowGroup
          title="Copy Workflow"
          description="Automatically change the Workflow State when an Instance is copied."
          switchSelected={copyWorkflowOn}
          onSwitchClick={toggleCopyWorkflow}
          stateOn={stateOnCopy}
          states={states}
          onStateChange={onCopyStateChange}
          stateLabel="Change Workflow State when Copying"
          subChoiceLabel="When copying, automatically change Workflow State to:"
          warningLabel="Note: Changes may take a couple of minutes to reflect."
        />
      </Box>
    </Scrollbar>
  );
};

export default memo(WorkflowSettings);
