/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { useContext, useEffect, useMemo, useState } from 'react';
import { withYjs, YjsEditor } from '@slate-yjs/core';
import { createEditor, Editor, Transforms } from 'slate';
import { Editable, Slate, withReact } from 'slate-react';
import * as Y from 'yjs';

import { CustomYjsProvider } from 'components/collaboration/CustomYjsProvider';
import UserContext from 'contexts/UserContext';
import { CustomChannel } from 'types/customChannel';

const emptyNode = {
  type: '',
  children: [{ text: '' }],
};

function SlateEditor({ sharedType }: Readonly<{ sharedType: Y.XmlText }>) {
  const editor = useMemo(() => {
    const e = withReact(
      withYjs(createEditor(), sharedType),
      // withCursors(withYjs(createEditor(), sharedType), provider.awareness as any, {
      //   data: {
      //     name: user,
      //     color: COLORS[randomIntFromInterval(1, 8)],
      //   },
      // }),
    );

    // Ensure editor always has at least 1 valid child
    const { normalizeNode } = e;
    e.normalizeNode = (entry) => {
      const [node] = entry;

      if (!Editor.isEditor(node) || node.children.length > 0) {
        return normalizeNode(entry);
      }

      Transforms.insertNodes(editor, emptyNode, { at: [0] });
    };

    return e;
  }, []);

  useEffect(() => {
    YjsEditor.connect(editor);
    return () => YjsEditor.disconnect(editor);
  }, [editor]);

  return (
    <div style={{ height: '100%', maxHeight: '100%' }}>
      <Slate editor={editor} initialValue={[emptyNode]}>
        <Editable
          style={{ height: '100%', maxHeight: '100%', overflowY: 'scroll', padding: '8px' }}
          placeholder="Start typing here…"
        />
        {/* <Cursors>
          <Editable
            style={{ height: '100%', maxHeight: '100%', overflowY: 'scroll' }}
            placeholder="Start typing here…"
          />
        </Cursors> */}
      </Slate>
    </div>
  );
}

export function CollaborativeEditor({ customChannel }: Readonly<{ customChannel: CustomChannel }>) {
  const user = useContext(UserContext);
  const [connected, setConnected] = useState(false);
  const [sharedType, setSharedType] = useState<Y.XmlText>();
  const [provider, setProvider] = useState<CustomYjsProvider | null>(null);

  // Set up custom Yjs provider
  useEffect(() => {
    const yDoc = new Y.Doc();
    const yProvider = new CustomYjsProvider(yDoc, customChannel, false, false);
    const sharedDoc = yDoc.get('slate', Y.XmlText);
    yProvider.on('sync', setConnected);

    setSharedType(sharedDoc);
    setProvider(yProvider);

    return () => {
      yDoc?.destroy();
      yProvider?.off('sync', setConnected);
      yProvider?.destroy();
    };
  }, [customChannel]);

  if (!connected || !sharedType || !provider || !user.attributes?.mTitle) {
    return <div>Loading…</div>;
  }

  return <SlateEditor sharedType={sharedType} />;
}
