import {
  Close,
  Popover,
  PopoverAnchor,
  PopoverContent,
  PopoverPortal,
  PopoverTrigger,
} from 'lib/popover';

const PopoverNamespace = Object.assign(Popover, {
  Content: PopoverContent,
  Portal: PopoverPortal,
  Trigger: PopoverTrigger,
  Anchor: PopoverAnchor,
  Close: Close,
});

export default PopoverNamespace;
