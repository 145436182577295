import { useCallback, useEffect, useMemo, useState } from 'react';

import PlatformIcons from 'components/menu/createInstanceMenu/PlatformIcons';
import { PublishSettingsInputType } from 'features/instance/hooks/useInstancePublishing';
import useDateTimeUtils from 'hooks/useDateTimeUtils';
import useFilterPlatforms from 'hooks/useFilterPlatforms';
import useGetPlatforms from 'hooks/useGetPlatforms';
import useGetRundownTimings from 'hooks/useGetRundownTimings';
import usePlatformWorkflow from 'hooks/usePlatformWorkflow';
import { usePolicies } from 'store';
import { Destination } from 'types';
import { MMetaDataField } from 'types/graphqlTypes';
import checkUserRight from 'utils/checkUserRight';
import { getPlatformIcon } from 'utils/instance/platform';
import variants from 'utils/instance/variants';

import useScheduleDateUtils from './hooks/useScheduleDateUtils';
import scheduleOptions from './utils/scheduleOptions';

interface UsePublishingStateProps {
  variant: string;
  publishingPoint?: string;
  platformKind?: string;
  selectedDestination: Destination | null;
  publishingTime?: string;
  expiryTime?: string;
  publishMetadata: MMetaDataField[];
  updateInstanceMetadata: (newMetadata: MMetaDataField[]) => Promise<void>;
  canRepublishInstance: boolean;
  anchorEl?: EventTarget | null;
  isCmsBlock?: boolean;
  publishingPointIcon?: string;
  onOk: (newPublishingSettings: PublishSettingsInputType) => Promise<void>;
}

const { UNSCHEDULE, SCHEDULE, UPDATE, UNPUBLISH, REPUBLISH } = scheduleOptions;

const getSelectedOption = (publishTime: string | undefined, isCmsRepublish: boolean) => {
  if (isCmsRepublish) return UPDATE;
  return publishTime ? SCHEDULE : UNSCHEDULE;
};

export const usePublishingState = ({
  variant,
  publishingPoint,
  platformKind,
  selectedDestination,
  publishingTime,
  expiryTime,
  publishMetadata,
  updateInstanceMetadata,
  canRepublishInstance,
  anchorEl,
  publishingPointIcon,
  onOk,
}: UsePublishingStateProps) => {
  const { isPast, isAfter, differenceInMinutes, addMinutes } = useDateTimeUtils();
  const { scheduleNow, setHoursIsoString, setDateIsoString } = useScheduleDateUtils();
  const { getRundownPublishingTime } = useGetRundownTimings();
  const { filterPlatforms } = useFilterPlatforms();
  const [policies] = usePolicies();

  const { getApprovalState } = usePlatformWorkflow();

  const [forcePublish, setForcePublish] = useState<string | undefined>(publishMetadata[0]?.value);
  const [expiryOffset, setExpiryOffset] = useState<number>(
    expiryTime ? differenceInMinutes(new Date(expiryTime), new Date(publishingTime!)) : 0,
  );

  const isCmsRepublish = canRepublishInstance && !!publishingTime;

  const { isApprovalOn, hasAccessToApprovalState } = useMemo(() => {
    const approvalState = getApprovalState(publishingPoint);
    const hasAccess = checkUserRight(policies, 'state', approvalState ?? undefined);
    return { isApprovalOn: !!approvalState, hasAccessToApprovalState: hasAccess };
  }, [getApprovalState, policies, publishingPoint]);

  const getInitialTimeISOString = useCallback(
    (forceReset: boolean): string => {
      const idatestr = publishingTime || selectedDestination?.publishingTime;
      if (!idatestr || forceReset) return new Date().toISOString();
      const pdate = new Date(idatestr);
      const idate = isPast(pdate) ? new Date() : pdate;
      return idate.toISOString();
    },
    [publishingTime, selectedDestination, isPast],
  );

  const [newPublishingTime, setNewPublishingTime] = useState<string>(
    getInitialTimeISOString(false),
  );
  const [showExpiryDate, setShowExpiryDate] = useState<boolean>(Boolean(expiryTime));
  const [newExpiryTime, setNewExpiryTime] = useState<string | undefined>(expiryTime);
  const [isPublishingTimeChanged, setIsPublishingTimeChanged] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<string>(
    getSelectedOption(publishingTime, canRepublishInstance && !!publishingTime),
  );
  const [publishNow, setPublishNow] = useState<boolean>(scheduleNow(newPublishingTime));
  const [confirmPublish, setConfirmPublish] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] = useState<string | undefined>(
    selectedDestination?.value || undefined,
  );

  const getLinearPublishingTime = useCallback(
    (destination: Destination, date: string): string => {
      const { startTime, timeZone } = destination;
      return getRundownPublishingTime(date, startTime!, timeZone!);
    },
    [getRundownPublishingTime],
  );

  const { error, loading, destinations } = useGetPlatforms(
    newPublishingTime,
    publishingPoint,
    platformKind,
    !anchorEl,
    filterPlatforms,
  );

  const isLinear = variant === variants.LINEAR;

  const shouldDisablePublish = useMemo(() => {
    const canScheduleInstance = checkUserRight(policies, 'instance', 'schedule');

    if (!canScheduleInstance) return true;

    return !destinations.some((destination) => destination.value === selectedValue);
  }, [destinations, selectedValue]);

  const PlatformIcon = useMemo(
    () =>
      PlatformIcons[publishingPointIcon || ''] ?? getPlatformIcon(publishingPoint, platformKind),
    [publishingPointIcon, publishingPoint, platformKind],
  );

  const toggleShowExpiryDate = useCallback(() => setShowExpiryDate((prevState) => !prevState), []);

  const handleForcePublishClick = useCallback(async () => {
    const newForcePublishValue = forcePublish === 'true' ? 'false' : 'true';
    await updateInstanceMetadata([
      {
        key: publishMetadata[0].key,
        value: newForcePublishValue,
      },
    ]);
    setForcePublish(newForcePublishValue);
  }, [forcePublish, publishMetadata, updateInstanceMetadata]);

  const onResetDateTime = useCallback(() => {
    setIsPublishingTimeChanged(true);
    const calculatedTime = getInitialTimeISOString(true);
    setNewPublishingTime(calculatedTime);
    setNewExpiryTime(addMinutes(new Date(calculatedTime), expiryOffset).toISOString());
  }, [getInitialTimeISOString, addMinutes, expiryOffset]);

  const calculateExpiryTime = useCallback((): string | null => {
    if (
      selectedOption === UNSCHEDULE ||
      selectedOption === UNPUBLISH ||
      !showExpiryDate ||
      !newExpiryTime
    )
      return null;
    if (isPast(new Date(newExpiryTime))) return new Date().toISOString();
    return newExpiryTime;
  }, [selectedOption, showExpiryDate, newExpiryTime, isPast]);

  const calculatePublishingTime = useCallback(
    (destination: Destination): string | null => {
      if (isLinear) return getLinearPublishingTime(destination, newPublishingTime);
      if (selectedOption === UNSCHEDULE || selectedOption === UNPUBLISH) {
        return null;
      }
      if (
        !isPublishingTimeChanged &&
        showExpiryDate &&
        publishingTime &&
        expiryTime !== newExpiryTime
      )
        return publishingTime;
      if (isPast(new Date(newPublishingTime))) return new Date().toISOString();
      return newPublishingTime;
    },
    [
      isLinear,
      getLinearPublishingTime,
      newPublishingTime,
      selectedOption,
      isPublishingTimeChanged,
      showExpiryDate,
      publishingTime,
      expiryTime,
      newExpiryTime,
      isPast,
    ],
  );

  const handleOK = useCallback(
    async (event: React.MouseEvent) => {
      event.stopPropagation();

      /* If publish/unpublish button clicked, set the button to confirm publish */
      if (
        !isLinear &&
        (selectedOption === SCHEDULE || selectedOption === UNPUBLISH) &&
        !confirmPublish
      ) {
        setConfirmPublish(true);
        return;
      }

      const destination = destinations.find((d) => d.value === selectedValue);

      /* If update or republish, send silent-update and if expiry date is selected,
    update schedule with that */
      if (!isLinear && (selectedOption === UPDATE || selectedOption === REPUBLISH)) {
        const silentMetadataValue = selectedOption === UPDATE ? 'true' : 'false';
        await updateInstanceMetadata([{ key: publishMetadata[1].key, value: silentMetadataValue }]);
      }

      /* If no destination selected (unassigned) for linear instance
       return with publishing time to be null */
      if (isLinear && !destination?.id) {
        await onOk({ selectedDestination: null, publishingTime: null, expiryTime: null });
      }

      const newPublishingSettings: PublishSettingsInputType = {
        selectedDestination:
          (destination as PublishSettingsInputType['selectedDestination']) ?? null,
        publishingTime: calculatePublishingTime(destination!) ?? null,
        expiryTime: null,
      };

      /* calculate expiry time if it's not a linear instance */
      if (!isLinear) {
        newPublishingSettings.expiryTime = calculateExpiryTime() ?? null;
      }

      await onOk(newPublishingSettings);
      setIsPublishingTimeChanged(false);

      if (selectedOption === UNPUBLISH) setSelectedOption(UNSCHEDULE);
      setConfirmPublish(false);
    },
    [
      isLinear,
      selectedOption,
      confirmPublish,
      destinations,
      selectedValue,
      updateInstanceMetadata,
      publishMetadata,
      calculatePublishingTime,
      calculateExpiryTime,
      onOk,
    ],
  );

  const handleCancel = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      setNewPublishingTime(getInitialTimeISOString(false));
      setConfirmPublish(false);
    },
    [getInitialTimeISOString],
  );

  const handleDateChange = useCallback(
    (value: Date) => {
      if (!value) return;
      const calculatedDate = setDateIsoString(newPublishingTime, value);
      if (newExpiryTime)
        setNewExpiryTime(addMinutes(new Date(calculatedDate), expiryOffset).toISOString());
      setNewPublishingTime(calculatedDate);
      setIsPublishingTimeChanged(true);
    },
    [setDateIsoString, newPublishingTime, newExpiryTime, addMinutes, expiryOffset],
  );

  const handleTimeChange = useCallback(
    (value: Date) => {
      if (!value) return;
      const calculatedTime = setHoursIsoString(newPublishingTime, value);
      if (newExpiryTime)
        setNewExpiryTime(addMinutes(new Date(calculatedTime), expiryOffset).toISOString());
      setNewPublishingTime(calculatedTime);
      setIsPublishingTimeChanged(true);
    },
    [newPublishingTime, newExpiryTime, setHoursIsoString, addMinutes, expiryOffset],
  );

  const handleExpiryDateChange = useCallback(
    (value: Date) => {
      if (!value) return;
      const calculatedExpiryDate = setDateIsoString(
        newExpiryTime || addMinutes(new Date(), 1).toISOString(),
        value,
      );
      if (
        isAfter(new Date(calculatedExpiryDate), new Date(newPublishingTime)) &&
        isAfter(new Date(calculatedExpiryDate), new Date())
      ) {
        setExpiryOffset(
          differenceInMinutes(new Date(calculatedExpiryDate), new Date(newPublishingTime)),
        );
        setNewExpiryTime(calculatedExpiryDate);
      }
    },
    [newExpiryTime, addMinutes, setDateIsoString, isAfter, newPublishingTime, differenceInMinutes],
  );

  const handleExpiryTimeChange = useCallback(
    (value: Date) => {
      if (!value) return;
      const calculatedExpiryDate = setHoursIsoString(
        newExpiryTime || new Date().toISOString(),
        value,
      );
      if (
        isAfter(new Date(calculatedExpiryDate), new Date(newPublishingTime)) &&
        isAfter(new Date(calculatedExpiryDate), new Date())
      ) {
        setExpiryOffset(
          differenceInMinutes(new Date(calculatedExpiryDate), new Date(newPublishingTime)),
        );
        setNewExpiryTime(calculatedExpiryDate);
      }
    },
    [newExpiryTime, setHoursIsoString, isAfter, newPublishingTime, differenceInMinutes],
  );

  const onDestinationChange = useCallback((value: string) => {
    setSelectedValue(value);
  }, []);

  useEffect(() => {
    setPublishNow(scheduleNow(newPublishingTime));
    if (!selectedValue) {
      setSelectedValue(
        selectedDestination?.value ||
          (destinations?.length > 0 ? destinations[0].value : undefined),
      );
    }
  }, [newPublishingTime, selectedDestination, destinations, selectedValue, scheduleNow]);

  useEffect(() => {
    setSelectedOption(getSelectedOption(publishingTime, isCmsRepublish));
  }, [publishingTime, isCmsRepublish]);

  return {
    forcePublish,
    expiryOffset,
    newPublishingTime,
    showExpiryDate,
    newExpiryTime,
    isPublishingTimeChanged,
    selectedOption,
    publishNow,
    confirmPublish,
    selectedValue,
    error,
    loading,
    destinations,
    isLinear,
    shouldDisablePublish,
    isCmsRepublish,
    PlatformIcon,
    isApprovalOn,
    hasAccessToApprovalState,
    toggleShowExpiryDate,
    handleForcePublishClick,
    onResetDateTime,
    calculateExpiryTime,
    calculatePublishingTime,
    handleOK,
    handleCancel,
    handleDateChange,
    handleTimeChange,
    handleExpiryDateChange,
    handleExpiryTimeChange,
    onDestinationChange,
    setSelectedOption,
    setConfirmPublish,
  };
};
