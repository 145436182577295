import { useCallback, useMemo } from 'react';

/**
 * Adds an event handler.
 * @param handler The event handler
 * @returns       A function that must be called to remove the handler when it is no longer
 *                needed.
 */
export type EventHandlerRegistrar<T> = (handler: (data: T) => void) => () => void;

/**
 * Triggers an event and passes the event data to all the event handlers.
 * @param data The parameter for the event.
 */
export type EventTrigger<T> = (data: T) => void;

/**
 * Represents an event source. Is a tuple containing two functions:
 * First a function to add an event handler
 * @template T The type of the event data
 */
export type EventSource<T> = [EventHandlerRegistrar<T>, EventTrigger<T>];

/**
 * Creates an event source with stable methods
 * @returns the event source
 * @template T The type of the event data
 */
export default function useEventSource<T>(): EventSource<T> {
  const listeners: ((data: T) => void)[] = useMemo(() => [], []);
  const addHandler = useCallback((handler: (data: T) => void) => {
    listeners.push(handler);
    return () => {
      const index = listeners.indexOf(handler);
      if (index >= 0) {
        listeners.splice(index, 1);
      }
    };
  }, []);
  const trigger = useCallback((data: T) => {
    listeners.forEach((listener) => listener(data));
  }, []);
  return [addHandler, trigger];
}
