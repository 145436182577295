import { ColumnDef } from '@tanstack/react-table';

export const getDefaultVisibilityState = <TData>(
  columns: ColumnDef<TData, unknown>[],
  defaultColumnsToShow: string[],
) => {
  return columns.reduce((acc, column) => {
    if (!column.id) return acc;

    if (defaultColumnsToShow.includes(column.id)) {
      acc[column.id] = true;
    } else {
      acc[column.id] = false;
    }

    return acc;
  }, {} as Record<string, boolean>);
};

export const isFixedSize = (minSize?: number, maxSize?: number) => {
  if (typeof minSize !== 'number' || typeof maxSize !== 'number') return false;
  return minSize === maxSize;
};
