/* eslint-disable no-console */
import { downloadData } from 'aws-amplify/storage';

/** input //S3:config/automation/templates.json */
const loadConfigFromS3 = async (path) => {
  console.log('path', path);
  try {
    const result = await downloadData({ path, options: { noCache: true } }).result;
    const automationResultS3 = await result.body.text();
    if (automationResultS3) {
      return JSON.parse(automationResultS3);
    }
    return { mProperties: { templateSets: [], resources: null } };
  } catch (error) {
    console.error(error);
    return { mProperties: { templateSets: [], resources: null } };
  }
};

const saveAutoTempOnContext = (automationResult, config) => {
  /** temp solution for loading templates from S3 */
  config.automationTemplateConfigs = [];

  automationResult.data.getMemberFromId.forEach(async (entry) => {
    let { mProperties } = entry;

    if (entry.mTitle.startsWith('S3:')) {
      const path = entry.mTitle.replace('S3:', '');
      const automationTemplates = await loadConfigFromS3(path);
      mProperties = automationTemplates.mProperties;
    }

    const studio = {
      templateSets: (mProperties && mProperties.templateSets) || [],
      resources: (mProperties && mProperties.resources) || null,
    };

    config.automationTemplateConfigs.push(studio);
  });

  /** old solution shall be used when the backend is ready to load from S3 */
  // config.automationTemplateConfigs = [
  //   ...automationResult.data.getMemberFromId.map(({ mProperties }) => ({
  //     templateSets: (mProperties && mProperties.templateSets) || [],
  //     resources: (mProperties && mProperties.resources) || null,
  //   })),
  // ];
};

export default saveAutoTempOnContext;
