import { CustomChannel } from 'types/customChannel';

export function createFilteredCustomChannel(channel: CustomChannel, name: string): CustomChannel {
  const prefix = `${name}:`;
  const result: CustomChannel = {
    myId: channel.myId,
    get isReady() {
      return channel.isReady;
    },
    addIsReadyListener: (listener) => channel.addIsReadyListener(listener),
    addEventListener: (type, listener) => {
      const filteredListener: typeof listener = (sourceId, fullType, data) => {
        if (fullType.startsWith(prefix)) {
          listener(sourceId, type, data);
        }
      };
      return channel.addEventListener(`${prefix}${type}`, filteredListener);
    },
    broadcastEvent: (type, data) => channel.broadcastEvent(`${prefix}${type}`, data),
    unicastEvent: (targetId, type, data) =>
      channel.unicastEvent(targetId, `${prefix}${type}`, data),
  };
  return Object.freeze(result);
}
