import { Row, TableMeta } from '@tanstack/react-table';
import { capitalize } from 'lodash';

import { ReactComponent as DeleteIcon } from 'assets/icons/systemicons/delete_small.svg';
import { ReactComponent as SpaceIcon } from 'assets/icons/systemicons/spaces_off.svg';
import { IconButton } from 'components/buttons/IconButton';
import Comments from 'features/comments';
import { Flex } from 'layouts/box/Box';
import { Order, ResourceType } from 'types/forms/forms';

import { getMemberIcon } from './getMemberIcons';

export interface ExtendedTableMeta extends TableMeta<Order> {
  deleteOrder: (id: string, resourceId: string) => void;
  goToResource: (id: string, type: ResourceType) => void;
  showPreview: (row: Row<Order>) => void;
}

interface Props {
  row: Row<Order>;
  tableMeta: ExtendedTableMeta;
}

export function OrderActions({ row, tableMeta }: Readonly<Props>) {
  const { mId, mResourceType, mSpaceId, mResourceId, mFormId, mOwner, mAssignee } = row.original;
  const ResourceIcon = getMemberIcon(mResourceType);
  const { deleteOrder, goToResource } = tableMeta;

  return (
    <Flex>
      <IconButton onClick={() => goToResource('home', 'space')} title="Go to home space">
        <SpaceIcon />
      </IconButton>
      <IconButton
        onClick={() => goToResource(mResourceId, mResourceType)}
        title={`Go to ${capitalize(mResourceType)}`}
      >
        <ResourceIcon />
      </IconButton>
      <IconButton onClick={() => deleteOrder(mId, mResourceId)} title="Delete order">
        <DeleteIcon />
      </IconButton>
      <Comments
        blockId={row.id}
        resourceId={mResourceId}
        resourceType="order"
        spaceId={mSpaceId}
        formId={mFormId}
        ownerId={mOwner}
        assigneeId={mAssignee}
      />
    </Flex>
  );
}
