import { useCallback, useContext, useMemo, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import keyBy from 'lodash/keyBy';

import { useGetMdfs } from 'api/mdf/useGetMdfs';
import { getOptionLists } from 'api/optionLists/useGetOptionLists';
import { getInput, search } from 'api/search';
import useRollingDateUtils from 'components/calendar/rollingDate/useRollingDate';
import UserContext from 'contexts/UserContext';
import { CommandToolbarProps } from 'features/command/command-types';
import { batchGetMembersFromFieldValue } from 'features/gridDeck/api/useBatchGetItems';
import { getOrdersWithMdf } from 'features/print/hooks/useGetOrdersWithMdf';
import { OrderWithLabel } from 'features/sidepanel/ComponentUtils';
import useCopyText from 'hooks/useCopyText';
import useGetMembersInfo from 'hooks/useGetMembersInfo';
import useSettingsValue from 'hooks/useSettingsValue';
import { getOrdersForEntities, rawToOrder } from 'screens/space/api/useGetOrdersAndForms';
import type { MiniMember } from 'types/forms/forms';
import {
  GetOrderEnum,
  type MemberType,
  type Metadata,
  SearchItemTypeEnum,
  TaskStatusEnum,
} from 'types/graphqlTypes';
import { findContactIdsInMetadata } from 'utils/mdf/findContactIdsInMetadata';
import { findRelationMembersInMetadata } from 'utils/mdf/findRelationMembersInMetadata';

import useCopyUtils from './useCopyUtils';

const baseToolbarState: CommandToolbarProps = {
  sortBy: 'createdAt',
  order: 'desc',
  rangeBy: null,
  defaultMdfId: null,
  mdfId: '',
  isFiltering: false,
  statusFilter: [],
  assignedIds: [],
  createdByIds: [],
  mTypes: [],
};

const useCopyTasks = (tasks: OrderWithLabel[]) => {
  const client = useApolloClient();
  const { getOrderContent } = useCopyUtils();
  const { groups } = useContext(UserContext);
  const { getMember, getMemberTitle } = useGetMembersInfo();
  const { onCopy: copyToClipboard } = useCopyText();
  const [getSettingsValue] = useSettingsValue();
  const { getRollingDateTime } = useRollingDateUtils();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const contactFieldsToPrint =
    getSettingsValue('app.contactFieldsToPrint', 'app.contactFieldsToPrint', 'all')?.toString() ??
    'all';

  const mIds = useMemo(() => tasks.map((task) => task.mId), [tasks]);

  const { mdfs, mdfsSeparated, loading: mdfsLoading, error: mdfsError } = useGetMdfs();

  const subTypes = keyBy(mdfsSeparated?.subTypes, (mdf) => mdf.label);

  const contactMdf = useMemo(
    () => mdfsSeparated?.defaults.find((mdf) => mdf.id.toLowerCase().includes('contact')),
    [mdfsSeparated],
  );

  const onCopy = useCallback(async () => {
    if (mdfsLoading) {
      return;
    }

    if (mdfsError) {
      setError(new Error('Error loading MDFs'));
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const {
        data: { getOrdersForEntities: subTasks },
      } = await getOrdersForEntities(client, {
        mIds,
        requestType: GetOrderEnum.Resource,
        mStatus: TaskStatusEnum.all,
      });

      const tasksWithMdf = getOrdersWithMdf(tasks, mdfs, mdfsSeparated);
      const subtasksWithMdf = getOrdersWithMdf(subTasks?.map(rawToOrder), mdfs, mdfsSeparated);

      const items = [...tasksWithMdf, ...subtasksWithMdf];

      const { contacts: contactIds, relationMembers: relationMiniMembers } = items.reduce(
        (acc, item) => {
          const { mdf, metadata } = item;

          acc.contacts.push(...findContactIdsInMetadata(mdf, metadata, groups, subTypes));
          acc.relationMembers.push(
            ...findRelationMembersInMetadata(mdf, metadata, groups, subTypes),
          );

          return acc;
        },
        { contacts: [] as string[], relationMembers: [] as MiniMember[] },
      );

      const {
        data: { batchGetMembers: relationMembersData },
      } = relationMiniMembers.length
        ? await batchGetMembersFromFieldValue(client, relationMiniMembers)
        : { data: { batchGetMembers: [] as MemberType[] } };

      const { items: contactsData } = await search(
        client,
        getInput(
          '',
          undefined,
          { ...baseToolbarState, mTypes: [SearchItemTypeEnum.contact] },
          25,
          {},
          undefined,
          [],
          contactIds,
          undefined,
          getRollingDateTime,
        ),
      );

      const { contacts: connectedContacts, relationMembers: connectedRelationMembers } = [
        ...contactsData,
        ...relationMembersData,
      ].reduce(
        (acc, member) => {
          if (!member.metadata) return acc;

          let metadata: Metadata;
          try {
            metadata = JSON.parse(member.metadata) as Metadata;
          } catch {
            return acc;
          }

          acc.contacts.push(...findContactIdsInMetadata(contactMdf, metadata, groups, subTypes));
          acc.relationMembers.push(
            ...findRelationMembersInMetadata(contactMdf, metadata, groups, subTypes),
          );

          return acc;
        },
        { contacts: [] as string[], relationMembers: [] as MiniMember[] },
      );

      const {
        data: { batchGetMembers: contactRelationFields },
      } = connectedRelationMembers.length
        ? await batchGetMembersFromFieldValue(client, connectedRelationMembers)
        : { data: { batchGetMembers: [] as MemberType[] } };

      const { items: connectedContactsData } = await search(
        client,
        getInput(
          '',
          undefined,
          { ...baseToolbarState, mTypes: [SearchItemTypeEnum.contact] },
          25,
          {},
          undefined,
          [],
          connectedContacts,
          undefined,
          getRollingDateTime,
        ),
      );

      const allRelationFields = [...relationMembersData, ...contactRelationFields];
      const allContacts = [...contactsData, ...connectedContactsData];

      const { data: optionLists } = await getOptionLists(client);

      const allContent = tasksWithMdf
        .map((task, index) =>
          getOrderContent({
            order: task,
            groups,
            getMemberTitle,
            getMember,
            index,
            subMdfs: mdfsSeparated.subTypes,
            subOrders: subtasksWithMdf,
            relationMembers: allRelationFields,
            contacts: allContacts,
            contactFieldsToPrint,
            mdfsSeparated,
            optionLists,
          }),
        )
        .join('\n');

      await copyToClipboard(allContent);
      setLoading(false);
    } catch (err) {
      setError(err instanceof Error ? err : new Error('An error occurred while copying'));
      setLoading(false);
    }
  }, [
    client,
    contactFieldsToPrint,
    contactMdf,
    copyToClipboard,
    getMember,
    getMemberTitle,
    getOrderContent,
    getRollingDateTime,
    groups,
    mIds,
    mdfs,
    mdfsError,
    mdfsLoading,
    mdfsSeparated,
    subTypes,
    tasks,
  ]);

  return { onCopy, loading: loading || mdfsLoading, error };
};

export default useCopyTasks;
