import { useReadableOrganizations, useSelectedOrgId } from 'store/organization';

import useCheckUserRight from './useCheckUserRight';
import useSettingsValue from './useSettingsValue';

export const getLocalTimezone = (): string => Intl.DateTimeFormat().resolvedOptions().timeZone;
export const getAllTimezones = () =>
  Intl.supportedValuesOf('timeZone').map((timezone) => ({ id: timezone, value: timezone }));

const useTimezoneSettings = () => {
  const [checkUserRight] = useCheckUserRight();
  const [getSettingsValue] = useSettingsValue();
  const [currentOrganization] = useSelectedOrgId();
  const [readableOrganizations] = useReadableOrganizations();
  const organizationTimeZone = readableOrganizations.find(
    (o) => o.id === currentOrganization,
  )?.timeZone;

  const timezoneFromSettings = getSettingsValue('app.timezone') as string;
  const hasOrganizationPermission = checkUserRight('feature', 'organization');

  const timezone = timezoneFromSettings ?? getLocalTimezone();

  if (hasOrganizationPermission && organizationTimeZone) return organizationTimeZone ?? timezone;

  return timezone;
};

export default useTimezoneSettings;
