import { UpdateInstanceInput } from 'types/graphqlTypes';
import { inReviewState } from 'utils/instance/restrictedPlatformPropsHelper';

export const addInstancePublishDetails = ({
  platform,
  mState,
  input,
  approvalState,
  hasAccessToApprovalState,
  isApproved,
  isRestrictedPlatform,
}: {
  platform: string;
  mState: string;
  input: UpdateInstanceInput;
  approvalState: string | null;
  hasAccessToApprovalState: boolean;
  isApproved: (mState: string) => boolean;
  isRestrictedPlatform: (platformName?: string | null) => boolean;
}) => {
  let updatedInput = { ...input };
  if (!!approvalState && hasAccessToApprovalState) {
    /** platform workflow is enabled & user has direct publishing rights */
    updatedInput = {
      ...updatedInput,
      mState: approvalState,
      ignoreReviewState: true,
    };
  } else if (!!approvalState && !hasAccessToApprovalState) {
    /** platform workflow is enabled & user doesn't have direct publishing rights */
    updatedInput = {
      ...updatedInput,
      mProperties: {
        __typename: 'PlatformType',
        provider: {
          message: `Publishing to ${platform} is restricted, needs approval.`,
        },
      },
    };
  } else if (!approvalState && !!isRestrictedPlatform(platform) && isApproved(mState)) {
    /** platform workflow is not enabled but old approval workflow is enabled */
    updatedInput = {
      ...updatedInput,
      mState: inReviewState,
      mProperties: {
        __typename: 'PlatformType',
        provider: {
          message: `Publishing to ${platform} is restricted, needs approval.`,
        },
      },
    };
  }

  return updatedInput;
};
