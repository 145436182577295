import styled from '@emotion/styled';

import Text from 'components/text';
import Tooltip from 'components/tooltip';
import { InstanceAggregation } from 'types';

import { renderHiddenBadgeTooltip } from './utils';

const OverflowText = styled(Text)`
  font-weight: bold;
  &:hover {
    text-decoration: underline;
  }
`;
export const PreLine = styled(Text)`
  white-space: pre-line;
`;

interface HiddenBadgesProps {
  hiddenBadges: string[];
  badgeCountsByType: InstanceAggregation;
}

function HiddenBadges({ hiddenBadges, badgeCountsByType }: Readonly<HiddenBadgesProps>) {
  return (
    <Tooltip
      title={
        <PreLine variant="caption" color="whiteHighEmphasis">
          {renderHiddenBadgeTooltip(hiddenBadges, badgeCountsByType)}
        </PreLine>
      }
    >
      <OverflowText variant="captionRegular">+{hiddenBadges.length}</OverflowText>
    </Tooltip>
  );
}

export default HiddenBadges;
