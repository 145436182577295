import { memo, useCallback, useEffect, useMemo, useState } from 'react';

import { useGetMdfs } from 'api/mdf/useGetMdfs';
import { useGetOrderForms } from 'api/order_forms/useGetOrderForms';
import AddMemberDropdown from 'components/addMember';
import MemberLabel from 'components/addMember/MemberLabel';
import Input from 'components/input/Input';
import { MdfEditor } from 'components/mdfEditor/MdfEditor';
import { FieldHeader } from 'components/mdfEditor/styled';
import StatusCell from 'features/orderForm/components/StatusCell';
import useCustomDateTimeUtils from 'hooks/useCustomDateTimeUtils';
import useMetadata from 'hooks/useMetadata';
import { useGetOrder } from 'screens/space/api/useGetOrdersAndForms';
import useUpdateOrder from 'screens/space/api/useUpdateOrder';
import { useAllMembersKeyed } from 'store';
import { AssignedMember } from 'types';
import { GetOrderEnum, Mdf } from 'types/graphqlTypes';
import { OrderFormMemberType } from 'types/memberTypes/order_form';
import getLocaleTimeFormat from 'utils/dateTime/getLocaleTimeFormat';
import preventDefaultAndPropagation from 'utils/preventDefaultAndStopPropagation';

import { FieldWrapper } from './styled';

interface EditOrderProps {
  orderId?: string;
  taskType?: string;

  // If set, the component will act as a preview without an actual order object.
  doPreview?: {
    mdf: Mdf; // We need to pass in the "changedMdf" to get correct preview.
    form: OrderFormMemberType;
    assignee?: string;
    owner?: string;
    status?: string;
    setStatus: (val: string) => void;
    setAssignee: (val: string) => void;
    setOwner: (val: string) => void;
  };
}

function EditOrderBlock({ orderId, taskType, doPreview }: Readonly<EditOrderProps>) {
  const { getRelativeDateTime } = useCustomDateTimeUtils();
  const [members] = useAllMembersKeyed();
  const { order } = useGetOrder(orderId, GetOrderEnum.Resource);
  const { updateOrder } = useUpdateOrder();
  const { keyedOrderForms } = useGetOrderForms();
  const { mdfsSeparated } = useGetMdfs();

  const [isFindingAssignee, setIsFindingAssignee] = useState<boolean>(false);
  const [isFindingOwner, setIsFindingOwner] = useState<boolean>(false);

  const assignee = useMemo(() => {
    return order?.mAssignee ? members[order.mAssignee] : null;
  }, [order?.mAssignee]);

  const owner = useMemo(() => (order?.mOwner ? members[order.mOwner] : null), [order?.mOwner]);

  const mdf = useMemo(() => {
    if (doPreview) {
      return doPreview.mdf;
    }
    return mdfsSeparated.custom.find((m) => m.id === order?.mdfId);
  }, [order?.mdfId, mdfsSeparated.custom, doPreview]);

  const { metadata, errorMap, updateFieldValues } = useMetadata(
    mdf,
    order?.metadata,
    'default',
    undefined,
    order?.mId,
  );

  const onRemoveClick = (event: React.MouseEvent<Element, MouseEvent>) => {
    event.stopPropagation();
  };

  const updateOrderProperty = useCallback(
    (key: string, value: string) => {
      if (doPreview) {
        if (key === 'mStatus') doPreview.setStatus(value);
        else if (key === 'mAssignee') doPreview.setAssignee(value);
        else if (key === 'mOwner') doPreview.setOwner(value);
      }

      if (!order) return;

      updateOrder({
        mId: order.mId,
        mResourceId: order.mResourceId,
        [key]: value,
      }).then(
        () => {},
        () => {},
      );
    },
    [order, doPreview, updateOrder],
  );

  useEffect(() => {
    if (!doPreview) {
      updateOrderProperty('metadata', JSON.stringify(metadata));
    }
  }, [metadata, doPreview]);

  const doFormatLocale = useCallback(
    (date: string) =>
      getRelativeDateTime(date, {
        dateFormat: `MMM d yyyy, ${getLocaleTimeFormat()}`,
        timeFormat: getLocaleTimeFormat(),
      }),
    [],
  );
  return (
    mdf && (
      <div onClick={preventDefaultAndPropagation} onKeyDown={() => {}}>
        {taskType && (
          <FieldWrapper>
            <FieldHeader variant="overline">Task type</FieldHeader>
            <Input initialValue={taskType} readOnly />
          </FieldWrapper>
        )}
        <MdfEditor
          view="default"
          fields={mdf.fields}
          errorMap={errorMap}
          permissions={mdf.permissions}
          updateFieldValue={updateFieldValues}
          metadata={metadata}
          layoutSettings={mdf.views.default}
          defaultLayoutSettings={mdf.views.default}
        />
        {doPreview && (
          <StatusCell
            showLabel
            form={doPreview.form}
            value={doPreview.status}
            setValue={doPreview.setStatus}
          />
        )}
        {order?.mStatus && keyedOrderForms[order.mFormId] && (
          <FieldWrapper>
            <FieldHeader>Status</FieldHeader>
            <StatusCell
              form={keyedOrderForms[order.mFormId]}
              value={order.mStatus}
              setValue={(value: string) => updateOrderProperty('mStatus', value)}
            />
          </FieldWrapper>
        )}
        <FieldWrapper>
          <FieldHeader>Assignee</FieldHeader>
          {isFindingAssignee ? (
            <AddMemberDropdown
              variant="all"
              autoFocus
              singleChoice
              disableClearable={true}
              selectedMembers={assignee ? [assignee] : []}
              setSelectedMembers={([newMember]: AssignedMember[]) =>
                updateOrderProperty('mAssignee', newMember.mId)
              }
              placeholderText="Set assignee"
              noOptionsText="No matches found"
              injectedMembers={undefined}
              onClose={() => setIsFindingAssignee(false)}
            />
          ) : (
            <MemberLabel
              variant="form"
              memberId={order?.mAssignee ?? doPreview?.assignee}
              onClick={() => setIsFindingAssignee(true)}
              onRemoveClick={onRemoveClick}
            />
          )}
        </FieldWrapper>
        <FieldWrapper>
          <FieldHeader>Owner</FieldHeader>
          {isFindingOwner ? (
            <AddMemberDropdown
              variant="all"
              singleChoice
              autoFocus
              disableClearable={true}
              selectedMembers={owner ? [owner] : []}
              setSelectedMembers={([newMember]: AssignedMember[]) =>
                updateOrderProperty('mOwner', newMember.mId)
              }
              placeholderText="Set owner"
              noOptionsText="No matches found"
              injectedMembers={undefined}
              onClose={() => setIsFindingOwner(false)}
            />
          ) : (
            <MemberLabel
              variant="form"
              memberId={order?.mOwner ?? doPreview?.owner}
              onClick={() => setIsFindingOwner(true)}
              onRemoveClick={onRemoveClick}
            />
          )}
        </FieldWrapper>
        {order?.mCreatedAt && (
          <FieldWrapper>
            <FieldHeader>Created</FieldHeader>
            <Input initialValue={doFormatLocale(order.mCreatedAt)} readOnly />
          </FieldWrapper>
        )}
        {order?.mUpdatedAt && (
          <FieldWrapper>
            <FieldHeader>Updated</FieldHeader>
            <Input initialValue={doFormatLocale(order.mUpdatedAt)} readOnly />
          </FieldWrapper>
        )}
      </div>
    )
  );
}

export default memo(EditOrderBlock);
