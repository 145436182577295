import { gql } from '@apollo/client';

export default gql`
  mutation SelectActiveOrganization($input: SwitchUserActiveOrgInput) {
    switchUserActiveOrg(input: $input) {
      id
      type
      title
      description
      mdfId
      metadata
      createdAt
      createdById
      updatedAt
      updatedById
      groups
      isArchived
      isDisabled
      permissions
    }
  }
`;
