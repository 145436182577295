import { useCallback } from 'react';

import { useActiveTab, useSettingsOpen } from '../atomsTs';

export const TABS = [
  'User settings',
  'System settings',
  'Organization',
  'Integrations',
  'Schemas',
  'Tasks',
  'Platform',
  'Planning items',
] as const;
export const DEFAULT_TABS = ['User settings'] as const;
export type SettingsTab = (typeof TABS)[number];

const useOpenSettings = () => {
  const [, setOpenSettings] = useSettingsOpen();
  const [, setActiveTab] = useActiveTab();

  const openSettingsTab = useCallback(
    (tab: SettingsTab) => {
      setOpenSettings(true);
      setActiveTab(tab);
    },
    [setActiveTab, setOpenSettings],
  );

  return { openSettingsTab };
};

export default useOpenSettings;
