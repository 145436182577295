import { useCallback, useEffect, useRef, useState } from 'react';

const browserPrefixes = ['moz', 'ms', 'o', 'webkit'];

const getHiddenPropertyName = (prefix: string | null) => (prefix ? `${prefix}Hidden` : 'hidden');

const getBrowserPrefix = () =>
  browserPrefixes.find((browserPrefix) => getHiddenPropertyName(browserPrefix) in document) || null;

const useVisibilityState = () => {
  const [isVisible, setIsVisible] = useState(true);
  const isVisibleRef = useRef(isVisible);

  const getVisibilityEvent = (prefix: string | null) =>
    prefix ? `${prefix}visibilitychange` : 'visibilitychange';

  const browserPrefix = getBrowserPrefix();
  const hiddenPropertyName = getHiddenPropertyName(browserPrefix);
  const visibilityEventName = getVisibilityEvent(browserPrefix);

  const makeVisible = useCallback(() => {
    if (isVisibleRef.current) return;
    setIsVisible(true);
    isVisibleRef.current = true;
  }, []);

  const makeHidden = useCallback(() => {
    if (!isVisibleRef.current) return;
    setIsVisible(false);
    isVisibleRef.current = false;
  }, []);

  const handleVisibilityChange = useCallback(() => {
    if ((document as unknown as Record<string, unknown>)[hiddenPropertyName]) {
      return makeHidden();
    }

    return makeVisible();
  }, [hiddenPropertyName, makeHidden, makeVisible]);

  useEffect(() => {
    document.addEventListener(visibilityEventName, handleVisibilityChange, false);
    document.addEventListener('focus', makeVisible, false);
    document.addEventListener('blur', makeHidden, false);
    window.addEventListener('focus', makeVisible, false);
    window.addEventListener('blur', makeHidden, false);
    return () => {
      document.removeEventListener(visibilityEventName, handleVisibilityChange, false);
      document.removeEventListener('focus', makeVisible, false);
      document.removeEventListener('blur', makeHidden, false);
      window.removeEventListener('focus', makeVisible, false);
      window.removeEventListener('blur', makeHidden, false);
    };
  }, [handleVisibilityChange, makeVisible, visibilityEventName]);

  return isVisible;
};

export default useVisibilityState;
