import { gql } from '@apollo/client';

const COMMON_KEYS = `
  id
  label
  color
  type
  visibleTo
  visibleIn
  data
  mType
`;

export const UPDATE_SCOPED_CONFIG = gql`
  mutation UpdateScopedConfig($input: UpdateScopedConfigInput) {
    updateScopedConfig(input: $input) {
      ${COMMON_KEYS}
    }
  }
`;

export const GET_SCOPED_CONFIGS = gql`
  query GetScopedConfigs($input: GetScopedConfigsInput) {
    getScopedConfigs(input: $input) {
      ${COMMON_KEYS}
    }
  }
`;

export const DELETE_SCOPED_CONFIG = gql`
  mutation DeleteScopedConfig($input: DeleteScopedConfigInput) {
    deleteScopedConfig(input: $input) {
      id
    }
  }
`;

export const CREATE_SCOPED_CONFIG = gql`
  mutation CreateScopedConfig($input: CreateScopedConfigInput) {
    createScopedConfig(input: $input) {
      ${COMMON_KEYS}
    }
  }
`;
