import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import {
  AuthSession,
  AuthUser,
  confirmResetPassword,
  confirmSignIn,
  fetchAuthSession,
  getCurrentUser,
  resetPassword,
  signIn,
  signOut,
} from 'aws-amplify/auth';
import { Hub } from 'aws-amplify/utils';
import { v1 as uuidv1 } from 'uuid';

import { AuthFlow, AuthStates, getAuthState, setAuthState, UseHostedUI } from 'features/auth';
import useCustomDateTimeUtils from 'hooks/useCustomDateTimeUtils';
import useSessionExpirationHandler from 'hooks/useSessionExpirationHandler';
import CREATE_SESSION from 'operations/mutations/createSession';
import LOGOUT from 'operations/mutations/logout';
import GET_SESSION from 'operations/queries/getSession';
import { setCookies, useForcedLogout } from 'store';
import { SessionType } from 'types/graphqlTypes';
import { resetLockToken } from 'utils/lock/lockToken';

interface Session {
  mId: string;
  mTitle: string;
  mType: 'session';
  mState: string; // 'active' | ?
  mProperties: SessionType;
}

interface CreateSessionType {
  createSession: Session;
}

interface GetSessionType {
  getMember: Session;
}

export interface DinaUser extends Readonly<AuthUser> {
  readonly dinaUserId: string;
}

const forgotPasswordSubmit = async (
  username: string,
  confirmationCode: string,
  newPassword: string,
) => {
  try {
    await confirmResetPassword({ username, confirmationCode, newPassword });
  } catch (error) {
    AuthFlow.error('forgotPasswordSubmit failed:', error);
    throw error;
  }
};

const forgotPassword = async (username: string) => {
  try {
    await resetPassword({ username });
  } catch (error) {
    AuthFlow.error('forgotPassword failed:', error);
    throw error;
  }
};

export interface AuthContextProps {
  readonly user: DinaUser | null;
  readonly attributes: Record<string, string>;
  readonly groups: string[];
  readonly verified: boolean;
  readonly loading?: boolean;
  readonly showFederatedSignIn?: boolean;
  readonly urlParams?: Readonly<Record<string, string | true>>;
  readonly login?: (username: string, password: string) => Promise<boolean | 'reset-password'>;
  readonly logout?: (userId?: string) => Promise<void>;
  readonly forgotPassword?: (username: string) => Promise<void>;
  readonly forgotPasswordSubmit?: (
    username: string,
    code: string,
    newPassword: string,
  ) => Promise<void>;
  readonly completeSignup?: (
    firstName: string,
    lastName: string,
    password: string,
  ) => Promise<void>;
  readonly refreshSession?: () => Promise<AuthContextProps>;
}

const initialState: AuthContextProps = {
  user: null,
  attributes: {},
  groups: [],
  verified: false,
  loading: false,
  logout: () => Promise.resolve(undefined),
};

export const AuthContext = createContext(initialState);

const SESSION_ID_KEY = 'sessionId';

function isString(x: unknown): x is string {
  return typeof x === 'string';
}

const getExistingSessionId = () => window.localStorage.getItem(SESSION_ID_KEY);

const setSessionId = (id: string) => window.localStorage.setItem(SESSION_ID_KEY, id);

const getUserSession = async (
  client: ApolloClient<NormalizedCacheObject>,
  loggedInUser: DinaUser,
) => {
  AuthFlow.log1?.('getUserSession', loggedInUser);
  const userId = loggedInUser.dinaUserId;
  const sessionId = getExistingSessionId();
  if (!userId || !sessionId) return null;
  try {
    const result = await client.query<GetSessionType>({
      query: GET_SESSION,
      variables: {
        input: {
          mId: userId,
          mRefId: sessionId,
        },
      },
      fetchPolicy: 'network-only',
    });

    const { data, error } = result;
    if (!error) return data?.getMember;
  } catch (e) {
    AuthFlow.log0?.('getUserSessionFailed', e);
  }

  return null;
};

const logOutUserSession = async (client: ApolloClient<NormalizedCacheObject>, userId: string) => {
  try {
    AuthFlow.log1?.('logOutUserSession', userId);
    await client.mutate({
      mutation: LOGOUT,
      variables: {
        input: {
          mId: userId,
          mRefId: getExistingSessionId(),
        },
      },
    });
  } catch (e) {
    AuthFlow.log0?.('logOutUserSession failed', e);
  }
};

function getDinaUser(authUser: AuthUser, session: AuthSession) {
  const idToken = session.tokens?.idToken;
  const dinaUserId =
    typeof idToken?.payload.userId === 'string' ? idToken.payload.userId : authUser.userId;
  return Object.freeze({ ...authUser, dinaUserId });
}

interface ProviderProps {
  children: React.ReactElement;
}

const getAttributesFromPayload = (payload: Record<string, string>): Record<string, string> => {
  const attributes = {} as Record<string, string>;
  Object.keys(payload ?? {}).forEach((key) => {
    if (key === 'isa' || key === 'orgId') {
      attributes[key] = payload[key];
    }
  });
  return attributes;
};

const AuthProvider = ({ children }: Readonly<ProviderProps>) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const client = children.props.client as ApolloClient<NormalizedCacheObject>;
  const [loading, setLoading] = useState(true);
  const [userState, setUserState] = useState(initialState);
  const [, setShowForcedLogout] = useForcedLogout();
  const [handleExpiration, resetExpirationHandler] = useSessionExpirationHandler();
  const { isValidSession } = useCustomDateTimeUtils();

  const setLoggedInUser = useCallback(
    (user: DinaUser, session: AuthSession, verified = false) => {
      AuthFlow.log1?.('setLoggedInUser', user, session, verified);
      const idToken = session.tokens?.idToken;
      if (idToken?.payload) {
        const attributes = getAttributesFromPayload(idToken.payload as Record<string, string>);
        const rawGroups = idToken.payload['cognito:groups'];
        const groups: string[] = Array.isArray(rawGroups) ? rawGroups.filter(isString) : [];
        const newUserState: AuthContextProps = { user, groups, verified, attributes };
        setUserState(newUserState);
        return newUserState;
      } else {
        const newUserState: AuthContextProps = { user, groups: [], verified, attributes: {} };
        setUserState(newUserState);
        return newUserState;
      }
    },
    [setUserState],
  );

  const clearLoggedInUser = useCallback(
    (verified = false) => {
      AuthFlow.log1?.('clearLoggedInUser', verified);
      setUserState({ ...initialState, verified });
    },
    [setUserState],
  );

  const logout = useCallback(
    async (userId?: string) => {
      AuthFlow.log1?.('logout', userId);
      if (!UseHostedUI) setAuthState(AuthStates.NOT_VERIFIED);
      window.onbeforeunload = null;

      try {
        if (userId || userState?.user)
          await logOutUserSession(client, userId || userState.user!.dinaUserId);
        await signOut();
        setCookies([]);
        // clearStore clears the store without refetching active queries.
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        client.clearStore();
      } catch (error) {
        AuthFlow.error('logout failed', error);
      }

      setSessionId('');
      resetLockToken();
      clearLoggedInUser();
    },
    [client, userState],
  );

  const verifyUser = useCallback(async () => {
    AuthFlow.log0?.('verifyUser', !!userState.user);
    try {
      if (!userState.user) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const loggedInUser = await getCurrentUser();
        const session = await fetchAuthSession();
        const dinaUser = getDinaUser(loggedInUser, session);
        const userSession = await getUserSession(client, dinaUser);
        if (!isValidSession(userSession)) {
          AuthFlow.log0?.('session is not valid');
          setAuthState(AuthStates.NOT_VERIFIED);
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          logout(dinaUser.dinaUserId);
          setLoading(false);
          return;
        }
        setLoggedInUser(dinaUser, session, true);
        handleExpiration(userSession, logout);
        setAuthState(AuthStates.VERIFIED);
      }
    } catch (error) {
      setAuthState(AuthStates.NOT_VERIFIED);
      AuthFlow.error('verifyUser failed', !!userState.user, error);
    }
    setLoading(false);
  }, [userState, client, handleExpiration]);

  const createSession = useCallback(
    async (loggedInUser: AuthUser) => {
      AuthFlow.log1?.('createSession', loggedInUser);
      const authSession = await fetchAuthSession();
      const dinaUser = getDinaUser(loggedInUser, authSession);
      const sub = dinaUser.dinaUserId;
      if (!sub) {
        AuthFlow.log0?.('No valid sub, ignores session');
        setLoading(false);
        return;
      }

      // To filter multiple event from sign in event
      if (getExistingSessionId()) {
        AuthFlow.log0?.('Session entry already exists. verifying session');
        const session = await getUserSession(client, dinaUser);
        if (isValidSession(session)) {
          setLoggedInUser(dinaUser, authSession, true);
          handleExpiration(session, logout);
          setLoading(false);
          return;
        }
        AuthFlow.log0?.('Existing session is not valid.');
      }

      AuthFlow.log0?.('Creating new session.');

      const sessionId = uuidv1();
      setSessionId(sessionId);

      try {
        const result = await client.mutate<CreateSessionType>({
          mutation: CREATE_SESSION,
          variables: {
            input: {
              mId: sub,
              mRefId: sessionId,
            },
          },
        });

        const { data } = result;
        setLoggedInUser(dinaUser, authSession, true);
        resetLockToken();
        handleExpiration(data?.createSession, logout);
      } catch (e) {
        await logout(sub);
        setSessionId('');
        AuthFlow.log0?.('createSession failed', e);
      }
      setLoading(false);
    },
    [client, logout, setLoggedInUser, setLoading, setShowForcedLogout, handleExpiration],
  );

  useEffect(() => {
    const stopListen = Hub.listen('auth', ({ payload }) => {
      AuthFlow.log1?.('auth event', payload.event, payload);
      const event = payload.event;
      window.localStorage.setItem('referrer', '');
      switch (event) {
        case 'signedIn':
          setAuthState(AuthStates.VERIFIED);
          createSession(payload.data).catch((e) => AuthFlow.error('failed creating session:', e));
          break;
        case 'signedOut':
          if (!UseHostedUI) setAuthState(AuthStates.NOT_VERIFIED);
          setSessionId('');
          resetLockToken();
          clearLoggedInUser();
          resetExpirationHandler();
          setLoading(false);
          break;
        default:
          AuthFlow.log0?.(`Amplify Auth event ${event} not processed`, event);
      }
    });

    const authState = getAuthState();
    if (!UseHostedUI || authState === AuthStates.VERIFIED) {
      AuthFlow.log0?.('verifying user');
      verifyUser().catch((e) => AuthFlow.error('failed verifying user', e));
    }

    return stopListen;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verifyUser]);

  const login = useCallback(
    async (username: string, password: string) => {
      AuthFlow.log1?.('login', username, !!password);
      try {
        setShowForcedLogout(0);
        try {
          await signOut();
        } catch (e) {
          AuthFlow.log0?.('Cognito signOut failed', e);
        }
        const result = await signIn({ username, password });
        if (result.isSignedIn) {
          const authSession = await fetchAuthSession();
          const dinaUser = getDinaUser(await getCurrentUser(), authSession);
          setLoggedInUser(dinaUser, authSession);
        }
        resetLockToken();
        AuthFlow.log1?.('login result', result.isSignedIn, result.nextStep.signInStep);
        return !result.isSignedIn &&
          result.nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED'
          ? 'reset-password'
          : result.isSignedIn;
      } catch (error) {
        AuthFlow.error('login failed', error);
        throw error;
      }
    },
    [setShowForcedLogout, setLoggedInUser],
  );

  const completeSignup = useCallback(
    async (firstName: string, lastName: string, password: string) => {
      try {
        AuthFlow.log0?.('completeSignup');
        AuthFlow.log1?.('completeSignup parameters', firstName, lastName, !!password);
        const result = await confirmSignIn({
          challengeResponse: password,
          options: {
            userAttributes: {
              given_name: firstName,
              family_name: lastName,
            },
          },
        });
        if (result.isSignedIn) {
          const authSession = await fetchAuthSession();
          const dinaUser = getDinaUser(await getCurrentUser(), authSession);
          setLoggedInUser(dinaUser, authSession);
        } else {
          AuthFlow.error('complete sign-up not signed in!');
        }
      } catch (error) {
        AuthFlow.log0?.('completeSignup failed', error);
        if (
          error instanceof Error &&
          'name' in error &&
          error.name === 'InvalidPasswordException'
        ) {
          throw error;
        }
      }
    },
    [setLoggedInUser],
  );

  /**
   * An object with properties reflecting the url parameters.
   * url parameters are located in window.location.search if present
   * Sample: url: https://dina.7mountains.com?k1=v1&k2=v2
   * Value: { k1: "v1", k2: "v2" }
   */
  const urlParams = useMemo(() => {
    const params = window.location.search;
    if (!params) return {};
    return params
      .slice(1)
      .split('&')
      .reduce((kv, kvpair) => {
        const [key, value] = kvpair.split('=');
        kv[key] = value ?? true;
        return kv;
      }, {} as Record<string, string | true>);
  }, [window.location.search]);

  const refreshSession = useCallback(async () => {
    const authSession = await fetchAuthSession({ forceRefresh: true });
    const dinaUser = getDinaUser(await getCurrentUser(), authSession);
    return setLoggedInUser(dinaUser, authSession, true);
  }, [setLoggedInUser]);

  const context = useMemo(
    () => ({
      loading,
      user: userState.user,
      groups: userState.groups,
      verified: userState.verified,
      attributes: userState.attributes,
      urlParams,
      showFederatedSignIn: UseHostedUI,
      login,
      forgotPassword,
      forgotPasswordSubmit,
      logout,
      completeSignup,
      refreshSession,
    }),
    [
      loading,
      userState,
      urlParams,
      UseHostedUI,
      login,
      forgotPassword,
      forgotPasswordSubmit,
      logout,
      completeSignup,
      refreshSession,
    ],
  );
  return <AuthContext.Provider value={context}>{children}</AuthContext.Provider>;
};

const AuthConsumer = AuthContext.Consumer;

const useAuthContext = () => useContext<AuthContextProps>(AuthContext);

export { AuthProvider, AuthConsumer, useAuthContext };
