import { DragPreviewImage } from 'react-dnd';
import styled from '@emotion/styled';
import { Skeleton } from '@material-ui/lab';

import { ReactComponent as NoteIcon } from 'assets/icons/systemicons/notes.svg';
import Text from 'components/text';
import useCommonDrag from 'hooks/useCommonDrag';
import { HStack } from 'layouts/box/Box';
import { useStoryPaneMolecule } from 'screens/storyV2/store/storyPane';
import { useSetPreview } from 'store/preview';
import { Note as NoteType } from 'types';
import dndTypes from 'utils/dndTypes';

const NoteWrapper = styled(HStack)`
  cursor: pointer;
  width: 100%;
  height: 32px;
  padding-inline: 10px 12px;
  gap: 4px;
  :hover {
    background-color: ${({ theme }) => theme.palette.dina.hoverOverlay};
  }
`;

export function NoteSkeleton() {
  return (
    <HStack width="100%" height="32px" padding="0 12px" gap="6px">
      <Skeleton animation="wave" variant="rect" height="24px" width="24px" />
      <Skeleton animation="wave" variant="text" style={{ flex: 1 }} />
    </HStack>
  );
}

function Note({ note }: Readonly<{ note: NoteType }>) {
  const { useMoveItemDialog } = useStoryPaneMolecule();
  const [, setMoveItemProps] = useMoveItemDialog();
  const setPreview = useSetPreview();

  const { dragRef, previewConnect, previewSrc } = useCommonDrag([note.mRefId], setMoveItemProps, {
    id: note.mRefId,
    type: dndTypes.STORY_NOTE,
    storyId: note.mId,
    unscoped: true,
  });

  return (
    <NoteWrapper onClick={() => setPreview(note)} ref={dragRef}>
      <DragPreviewImage connect={previewConnect} src={previewSrc} />
      <NoteIcon />
      <HStack flex="1">
        {note.mTitle !== '' ? (
          <Text variant="listItemLabel" color="highEmphasis" truncate style={{ width: '100%' }}>
            {note.mTitle}
          </Text>
        ) : (
          <Text variant="listItemLabelItalic" color="mediumEmphasis">
            Untitled
          </Text>
        )}
      </HStack>
    </NoteWrapper>
  );
}

export default Note;
