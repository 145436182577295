import styled from '@emotion/styled';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';

import { Label as RadixLabel } from 'lib/label';
export const Root = styled(CheckboxPrimitive.Root)`
  width: 18px;
  height: 18px;
  padding: 0px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: none;
  border: 1px solid transparent;
  border-radius: 2px;
  cursor: pointer;
  outline-color: ${({ theme }) => theme.palette.dina.onSelected};
  &[data-state='checked'] {
    background-color: ${({ theme }) => theme.palette.dina.onSelected};
    border-color: ${({ theme }) => theme.palette.dina.onSelected};
  }
  &[data-state='unchecked'] {
    background-color: ${({ theme }) => theme.palette.dina.inputBackground};
    border-color: ${({ theme }) => theme.palette.dina.iconInactive};
  }
  &[data-state='indeterminate'] {
    background-color: ${({ theme }) => theme.palette.dina.inputBackgroundFocused};
    border-color: ${({ theme }) => theme.palette.dina.onSelected};
  }
  :focus-visible {
    outline: none;
    border-width: 2px;
    border-color: ${({ theme }) => theme.palette.dina.onSelected};
  }
`;

export const Label = styled(RadixLabel)`
  ${({ theme }) => theme.typography.dina.listItemLabel};
  cursor: pointer;
`;

export const MediumLabel = styled(RadixLabel)`
  ${({ theme }) => theme.typography.dina.listItemLabelMedium};
  cursor: pointer;
`;
export const LabelDescription = styled('p')`
  ${({ theme }) => theme.typography.dina.listItemLabel};
  color: ${({ theme }) => theme.palette.dina.mediumEmphasis};
  margin-top: 2px;
  width: auto;
  max-width: 250px;
  max-height: 60px; // Set a maximum height to allow wrapping but not too much
  overflow: hidden; // Hide overflow text
  display: flex;
  flex-direction: column; // Arrange children in a column
  line-clamp: 3; // Limit to 3 lines
  box-orient: vertical; // Vertical orientation for the box
`;

export const CheckBoxLabelAndDescriptionContainer = styled('div')``;
