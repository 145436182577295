import { ApolloCache } from '@apollo/client';

import { MemberTypeEnum, ScopedConfigEntity, VisiblePlaces } from 'types/graphqlTypes';

import { GET_SCOPED_CONFIGS } from './gql';
import { GetScopedConfigs } from './useGetScopedConfigs';

// All locations configs can be present in need to be in this array for
// cache updates to work correctly.
const visiblePlaces: VisiblePlaces[] = ['contacts_sidebar', 'left_sidebar', 'rundown_layouts'];

const writeToLocation = (
  client: ApolloCache<unknown>,
  newConfig: ScopedConfigEntity,
  canAdministrate: boolean,
  mType: MemberTypeEnum,
  location?: VisiblePlaces,
) => {
  const cachedConfigs = client.readQuery<GetScopedConfigs>({
    query: GET_SCOPED_CONFIGS,
    variables: {
      input: {
        all: canAdministrate,
        mType,
        location,
      },
    },
  });

  const updated = [
    newConfig,
    ...(cachedConfigs?.getScopedConfigs ?? []).filter((c) => c.id !== newConfig.id),
  ];

  client.writeQuery({
    query: GET_SCOPED_CONFIGS,
    variables: {
      input: {
        all: canAdministrate,
        mType,
        location,
      },
    },
    data: {
      getScopedConfigs: updated,
    },
  });
};

const doRemove = (
  client: ApolloCache<unknown>,
  id: string,
  canAdministrate: boolean,
  mType: MemberTypeEnum,
  location?: VisiblePlaces,
) => {
  const cachedConfigs = client.readQuery<GetScopedConfigs>({
    query: GET_SCOPED_CONFIGS,
    variables: {
      input: {
        all: canAdministrate,
        mType,
        location,
      },
    },
  });

  const updated = (cachedConfigs?.getScopedConfigs ?? []).filter((c) => c.id !== id);
  client.writeQuery({
    query: GET_SCOPED_CONFIGS,
    variables: {
      input: {
        all: canAdministrate,
        mType,
        location,
      },
    },
    data: {
      getScopedConfigs: updated,
    },
  });
};

export const writeToCache = (
  client: ApolloCache<unknown>,
  newConfig: ScopedConfigEntity,
  mType: MemberTypeEnum,
  canAdministrate: boolean,
) => {
  // Default
  writeToLocation(client, newConfig, canAdministrate, mType);

  // Ensure cache for various locations updates too
  if (newConfig.visibleIn?.length) {
    newConfig.visibleIn.forEach((s) =>
      writeToLocation(client, newConfig, canAdministrate, mType, s as VisiblePlaces),
    );
  }

  // In case visibleIn has changed, remove from caches it's no longer part of
  for (const v of visiblePlaces) {
    if (!newConfig.visibleIn?.includes(v)) {
      doRemove(client, newConfig.id, canAdministrate, mType, v);
    }
  }
};

export const removeFromCache = (
  client: ApolloCache<unknown>,
  id: string,
  mType: MemberTypeEnum,
  canAdministrate: boolean,
) => {
  doRemove(client, id, canAdministrate, mType);
  visiblePlaces.forEach((v) => doRemove(client, id, canAdministrate, mType, v));
};
