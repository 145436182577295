import {
  createEncoder,
  Encoder,
  toUint8Array,
  writeVarUint,
  writeVarUint8Array,
} from 'lib0/encoding';
import { ObservableV2 } from 'lib0/observable';
import { Awareness, encodeAwarenessUpdate } from 'y-protocols/awareness';
import { writeSyncStep1, writeUpdate } from 'y-protocols/sync';
import * as Y from 'yjs';

import { CustomChannel, CustomEventData, CustomEventListener } from 'types/customChannel';

import {
  messageAwareness,
  messageQueryAwareness,
  messageSync,
  readYjsMessage,
  yjsLog,
} from './yjsUtils';

interface AwarenessUpdate {
  added: readonly number[];
  updated: readonly number[];
  removed: readonly number[];
}

interface PeerInfo {
  readonly isSynced: boolean;
}

enum RestoreOption {
  Silent = 0,
  Broadcast = 1,
}

enum BackupOption {
  ClearSelection = 1,
}

export class CustomYjsProvider extends ObservableV2<{
  sync: (connected: boolean) => void;
  update: (update: Uint8Array) => void;
}> {
  readonly #peers = new Map<string, PeerInfo>();

  readonly #doc: Y.Doc;

  readonly #awareness: Awareness;

  readonly #myId: string;

  readonly #cleanup: () => void;

  readonly #broadcastEvent: (type: string, data?: CustomEventData) => void;

  readonly #unicastEvent: (targetId: string, type: string, data?: CustomEventData) => void;

  readonly #docUpdateHandler = (update: Uint8Array, source: unknown) => {
    yjsLog?.('doc updated', source !== this);
    if (source === this) return;
    const encoder = createEncoder();
    writeVarUint(encoder, messageSync);
    writeUpdate(encoder, update);
    if (this.#isConnected) this.#broadcastEvent('yjsEvent', toUint8Array(encoder));
  };

  readonly #awarenessUpdateHandler = ({ added, updated, removed }: AwarenessUpdate) => {
    if (this.#readOnly) return;
    yjsLog?.('awareness updated');
    const encoderAwareness = createEncoder();
    const changedClients = added.concat(updated, removed);
    writeVarUint(encoderAwareness, messageAwareness);
    writeVarUint8Array(encoderAwareness, encodeAwarenessUpdate(this.#awareness, changedClients));
    this.#broadcastEvent('yjsEvent', toUint8Array(encoderAwareness));
  };

  #isSynced = false;

  #isConnecting = false;

  #isConnected = false;

  #readOnly: boolean;

  #awarenessBackup: Record<string, unknown> | null = null;

  /**
   *
   * @param yDoc        The yjs document to connect to
   * @param channel     The {@link CustomChannel} to use for communication
   * @param isInitiator `true` if this peer is the initiator of the collaborative editing.
   *                    This will cause immediate access to the document since there is nobody
   *                    to sync with.
   *                    If providing `false` here, you should also make sure to provide an empty
   *                    document to the `yDoc` parameter so that you don't provide anything to
   *                    the shared document, but rather receives the document from the other
   *                    collaborating parties.
   * @param readOnly    The initial value for the `readOnly` property.
   *                    See the {@link CustomYjsProvider.readOnly} property for documentation.
   */
  constructor(yDoc: Y.Doc, channel: CustomChannel, isInitiator: boolean, readOnly: boolean) {
    super();
    this.#doc = yDoc;
    this.#awareness = new Awareness(yDoc);
    this.#readOnly = readOnly;
    this.#myId = channel.myId;

    const channelListener: CustomEventListener = (sourceId, type, data) => {
      if (type !== 'yjsEvent') return;

      if (typeof data === 'string') {
        switch (data) {
          case 'disconnect':
            yjsLog?.('disconnected', sourceId, 'in', this.#myId);
            this.#peers.delete(sourceId);
            this.#checkIsSynced();
            break;
          case 'connect':
            yjsLog?.('connected', sourceId, 'in', this.#myId);
            this.#peers.set(sourceId, { isSynced: false });
            this.#sendSyncEvent(sourceId, writeSyncStep1);
            yjsLog?.('unicast step 1');
            // We don't check sync since we don't want to go to syncing state when someone connects
            break;
        }
      } else if (data instanceof Uint8Array) {
        const peerInfo = this.#peers.get(sourceId);
        let checkSync = false;
        const answer = readYjsMessage(this, data, !!peerInfo?.isSynced, () => {
          this.#peers.set(sourceId, { isSynced: true });
          yjsLog?.('new synced peer', sourceId, 'in', this.#myId);
          checkSync = true;
        });

        if (answer) {
          yjsLog?.('Sending answer to:', sourceId);
          channel.unicastEvent(sourceId, 'yjsEvent', toUint8Array(answer));
        }
        if (checkSync) {
          this.#checkIsSynced();
        }
      }
    };

    const isReadyListener = (isReady: boolean) => {
      if (isReady) {
        this.#connect();
      } else {
        this.#disconnect();
      }
    };
    const listenersRemovers = [
      channel.addEventListener('yjsEvent', channelListener),
      channel.addIsReadyListener(isReadyListener),
    ];

    this.#broadcastEvent = (type, data) => channel.broadcastEvent(type, data);
    this.#unicastEvent = (targetId, type, data) => channel.unicastEvent(targetId, type, data);

    const onDestroy = (): void => this.destroy();
    yDoc.on('destroy', onDestroy);
    this.#cleanup = () => {
      yDoc.off('destroy', onDestroy);
      listenersRemovers.forEach((remover) => remover());
      yDoc.off('update', this.#docUpdateHandler);
    };

    if (channel.isReady) this.#connect(isInitiator);
  }

  #checkIsSynced() {
    if (this.#isConnecting) return;
    let isSynced = true;
    for (const entry of this.#peers) {
      if (!entry[1].isSynced) {
        isSynced = false;
        break;
      }
    }
    yjsLog?.('synced:', isSynced);
    if (isSynced !== this.#isSynced) {
      this.#isSynced = isSynced;
      this.emit('sync', [isSynced]);
    }
  }

  #sendSyncEvent(targetId: string | null, dataWriter: (encoder: Encoder, doc: Y.Doc) => void) {
    const encoder = createEncoder();
    writeVarUint(encoder, messageSync);
    dataWriter(encoder, this.#doc);
    const data = toUint8Array(encoder);
    if (targetId) {
      this.#unicastEvent(targetId, 'yjsEvent', data);
    } else {
      this.#broadcastEvent('yjsEvent', data);
    }
  }

  #broadcastLocalAwareness() {
    const encoderAwarenessState = createEncoder();
    writeVarUint(encoderAwarenessState, messageAwareness);
    writeVarUint8Array(
      encoderAwarenessState,
      encodeAwarenessUpdate(this.awareness, [this.doc.clientID]),
    );
    yjsLog?.('broadcast awareness');
    this.#broadcastEvent('yjsEvent', toUint8Array(encoderAwarenessState));
  }

  #connect(isInitiating = false) {
    yjsLog?.('connect');

    this.#isConnecting = true;
    this.#doc.on('update', this.#docUpdateHandler);
    this.#awareness.on('update', this.#awarenessUpdateHandler);

    this.#broadcastEvent('yjsEvent', 'connect');

    // write sync step 1
    yjsLog?.('broadcast sync step 1');
    this.#sendSyncEvent(null, writeSyncStep1);

    // write queryAwareness
    const encoderAwarenessQuery = createEncoder();
    writeVarUint(encoderAwarenessQuery, messageQueryAwareness);
    yjsLog?.('broadcast query awareness');
    this.#broadcastEvent('yjsEvent', toUint8Array(encoderAwarenessQuery));

    // restore and broadcast local awareness state
    if (!this.readOnly) {
      this.#restoreAwareness(RestoreOption.Silent);
    }
    this.#broadcastLocalAwareness();

    // Unless we are initiating the collaborative editing, we must wait a little bit before we mark
    // ourself as synced so that we get the document from the peers before we create our own
    // (which contains a paragraph which will cause an extra line when we eventually gets synced).
    setTimeout(
      () => {
        if (!this.#isConnecting) return;
        this.#isConnecting = false;
        this.#isConnected = true;
        this.#checkIsSynced();
        yjsLog?.('connected');
      },
      isInitiating ? 0 : 3000,
    );
  }

  #disconnect() {
    yjsLog?.('disconnect', this.#myId);
    this.#isConnecting = false;
    this.#isConnected = false;
    this.#doc.off('update', this.#docUpdateHandler);
    this.#awareness.off('update', this.#awarenessUpdateHandler);
    this.#broadcastEvent('yjsEvent', 'disconnect');
    this.#backupAwareness();
  }

  /**
   * Backups the awareness local state and removes the local state so that this user's cursor and
   * selection is removed in collaborators.
   * @param option If this is set to `BackupOption.ClearSelection`, the selection in the backup
   * is cleared so that when the awareness is restored it is without selection.
   */
  #backupAwareness(option?: BackupOption): void {
    const localState = this.#awareness.getLocalState();
    if (localState) {
      this.#awarenessBackup = localState;
      this.#awareness.setLocalState(null);
      this.#broadcastLocalAwareness();
    }
    if (option === BackupOption.ClearSelection && this.#awarenessBackup) {
      delete this.#awarenessBackup.selection;
    }
  }

  /**
   * Restores the awareness local-state backup if available.
   * @param option If set to `RestoreOption.Broadcast` and the backup contained a selection,
   *               the change will be broadcasted immediately.
   */
  #restoreAwareness(option: RestoreOption): void {
    if (!this.#awarenessBackup) return;
    this.#awareness.setLocalState(this.#awarenessBackup);
    const hasSelection = !!this.#awarenessBackup.selection;
    this.#awarenessBackup = null;
    if (hasSelection && option === RestoreOption.Broadcast) {
      this.#broadcastLocalAwareness();
    }
  }

  get doc(): Y.Doc {
    return this.#doc;
  }

  get awareness(): Awareness {
    return this.#awareness;
  }

  get synced() {
    return this.#isSynced;
  }

  /**
   * If `true` indicates that this user is currently not editing the document, he is just watching.
   * This will cause no cursor/selection for this user in the collaborating parties.
   * This user will still be able to see the other non-readonly users' cursors/selections.
   */
  get readOnly() {
    return this.#readOnly;
  }

  set readOnly(readOnly: boolean) {
    if (this.#readOnly === readOnly) return;
    this.#readOnly = readOnly;
    yjsLog?.('awareness read-only:', readOnly);
    if (readOnly) {
      this.#backupAwareness(BackupOption.ClearSelection);
    } else if (this.#isConnecting || this.#isConnected) {
      this.#restoreAwareness(RestoreOption.Broadcast);
    }
  }

  destroy(): void {
    if (this.#isConnected) this.#disconnect();
    this.#cleanup();
    super.destroy();
  }
}
