import { useMemo } from 'react';
import { isEmpty } from 'lodash';

import { Button } from 'components/buttons';
import { LoadingButton } from 'components/buttons/LoadingButton';
import DialogBuilder from 'components/dialogs/DialogBuilder';
import { VStack } from 'layouts/box/Box';

import { useIsOpenConfirmDialog } from '../../atoms';
import { useDisableSaveError } from '../../atomsTs';

import { RootWrapper } from './footer-styled';

interface FooterProps {
  onCloseSettings: () => void;
  onDiscardAndClose: () => void;
  onSaveAndClose: () => void;
  onSave: () => Promise<void>;
  disabled?: boolean;
  isSaving: boolean;
  onCloseConfirmDialog: () => void;
}

export default function Footer({
  onCloseSettings,
  onDiscardAndClose,
  disabled = false,
  isSaving,
  onSaveAndClose,
  onSave,
  onCloseConfirmDialog,
}: Readonly<FooterProps>) {
  const [isOpenConfirmDialog] = useIsOpenConfirmDialog();
  const [disableSaveErrors] = useDisableSaveError();

  const errorTooltip = useMemo(() => {
    if (isEmpty(disableSaveErrors)) return '';
    return (
      <VStack>
        {Object.values(disableSaveErrors).map((error) => (
          <div key={error}>{error}</div>
        ))}
      </VStack>
    );
  }, [disableSaveErrors]);

  return (
    <RootWrapper>
      <Button width={104} variant="outlined" usage="outlined" onClick={onCloseSettings}>
        Cancel
      </Button>
      <LoadingButton
        title={errorTooltip}
        loading={isSaving}
        disabled={disabled || !isEmpty(disableSaveErrors)}
        onClick={() => void onSave()}
        variant="contained"
        width={104}
      >
        Save
      </LoadingButton>
      <Button
        disabled={disabled || isSaving}
        width={104}
        variant="contained"
        usage="significant"
        onClick={onSaveAndClose}
      >
        Save & Close
      </Button>
      <DialogBuilder open={isOpenConfirmDialog} onClose={onCloseConfirmDialog} modal={true}>
        <DialogBuilder.Header>You have unsaved changes</DialogBuilder.Header>
        <DialogBuilder.Body>
          Please choose whether to discard or save the changes you have made before you close the
          dialog.
        </DialogBuilder.Body>
        <DialogBuilder.Footer>
          <Button width={128} usage="outlined" variant="outlined" onClick={onCloseConfirmDialog}>
            Cancel
          </Button>
          <Button width={136} usage="warning" variant="contained" onClick={onDiscardAndClose}>
            Discard & Close
          </Button>
          <Button width={136} usage="significant" variant="contained" onClick={onSaveAndClose}>
            Save & Close
          </Button>
        </DialogBuilder.Footer>
      </DialogBuilder>
    </RootWrapper>
  );
}
