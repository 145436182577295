import { useRef } from 'react';
import { isEqual, keyBy } from 'lodash';

const NONE = Object.freeze([]);

/**
 * Get an array where the items are stable as long as they are equal to the corresponding item
 * (the item with the same key) in the previous array and the array itself is also stable,
 * if it contains exactly the same items as before.
 *
 * **NOTE: This function expects that the items in the array have unique keys.**
 * @param array      The incoming unstable array with unstable items
 * @param getItemKey A function to retrieve the key from the items of the array (to be able to
 *                   map items to items in previous array)
 * @returns          The stabilized array
 */
export default function useStabilizedArray<T extends object>(
  array: readonly T[],
  getItemKey: (item: T) => string,
) {
  const prevResultRef = useRef<readonly T[]>(NONE);

  // Use a stable array if empty
  if (!array.length) {
    prevResultRef.current = NONE;
    return NONE;
  }

  // If previous array was empty, just return the incoming array
  if (prevResultRef.current === NONE) {
    prevResultRef.current = array;
    return array;
  }

  // Use SAME items in the array if equal to previous
  const prevResult = prevResultRef.current;
  const prevKeyed = keyBy(prevResult, getItemKey);
  const rawResult = Object.freeze(
    array.map((item) => {
      const key = getItemKey(item);
      const prevItem = prevKeyed[key];
      return !prevItem || !isEqual(item, prevItem) ? item : prevItem;
    }),
  );

  // If the resulting array contains exactly the SAME items as before, use the previous array
  const result =
    rawResult.length !== prevResult.length || rawResult.some((item, i) => item !== prevResult[i])
      ? rawResult
      : prevResult;

  // Update previous result and return
  prevResultRef.current = result;
  return result;
}
