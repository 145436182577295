import { AccessTypeEnum, ResourcePolicy } from 'types/graphqlTypes';

const getResourcePermission = (resource: ResourcePolicy, action: string, defaultValue: boolean) => {
  const { permissions = [] } = resource ?? {};
  const permission = permissions.find((p) => p.action === action);
  if (!permission) return defaultValue;
  return !!(permission.access && permission.access === AccessTypeEnum.Allow);
};

const checkUserRight = (
  policies: readonly ResourcePolicy[] = [],
  resourceName = 'feature',
  action = '',
) => {
  const defaultPermission = resourceName !== 'feature';
  const resource = policies.find((r) => r.resourceName === resourceName);
  if (!resource) return defaultPermission;
  return getResourcePermission(resource, action, defaultPermission);
};

export default checkUserRight;
