/**
 *  Hook for creating a new story and attach it to a user.
 *  It takes the mId of the user and the title of the story .
 *  Returns the story id of the created story.
 *
 */
import { useState } from 'react';
import { useMutation } from '@apollo/client';
import type { AssignedMember } from 'types';

import useGetArchiveTime from 'hooks/useGetArchiveTime';
import useNewItems from 'hooks/useNewItems';
import useSettingsValue from 'hooks/useSettingsValue';
import useUpdateLeftSidebarCache from 'hooks/useUpdateLeftSidebarCache';
import CREATE_STORY_FROM_FEED from 'operations/mutations/createStoryFromFeed';
import { type MemberType, MemberTypeEnum } from 'types/graphqlTypes';
import { MTypeToMdfId } from 'types/utilities';

import { CreateStoryInput } from './useCreateStory';

interface CreateStoryFromFeedInput extends CreateStoryInput {
  provider?: string;
}

interface CreateStoryVariables {
  input: {
    mId: string;
    mTitle: string;
    mType: MemberTypeEnum;
    messageAssign: string | undefined;
    isRestricted: boolean;
    mCreatedById: string;
    mPublishingAt?: string;
    mPublishingEnd?: string;
    mAssignedMembers?: Pick<AssignedMember, 'mId' | 'mType'>[];
    provider?: string;
    isSyncActive?: boolean;
    ttl?: number;
    metadata?: string;
    mdfId?: string;
    mPriority?: string;
  };
}

interface StoryCreateMutation {
  createStoryFromFeed: MemberType;
}

const useCreateStoryFromFeed = () => {
  const [createStoryFromFeed] = useMutation<StoryCreateMutation>(CREATE_STORY_FROM_FEED);
  const [loading, setLoading] = useState(false);
  const [getSettingsValue] = useSettingsValue();
  const [updateCache] = useUpdateLeftSidebarCache();
  const [getAutoArchiveTime] = useGetArchiveTime();
  const { addItem } = useNewItems();

  let createdStoryId: string | undefined;

  const create = async (props: CreateStoryFromFeedInput) => {
    const {
      mId,
      type,
      mTitle,
      isRestricted,
      mCreatedById,
      provider,
      messageAssign,
      mPublishingAt,
      mPublishingEnd,
      mAssignedMembers,
      metadata,
      mPriority,
    } = props;
    const isSyncActive = getSettingsValue('story.disableAutoSync') !== 'true';
    const isPitch = type === 'pitch';
    const mType = type === 'pitch' ? MemberTypeEnum.UsrPitch : MemberTypeEnum.UsrStr;
    const isAutoArchiveStory = getSettingsValue('app.autoArchiveStory') === 'true';
    const variables: CreateStoryVariables = {
      input: {
        mId,
        mTitle,
        mType,
        isRestricted,
        mCreatedById,
        provider,
        messageAssign,
        mPriority,
      },
    };
    if (mPublishingAt) {
      variables.input.mPublishingAt = mPublishingAt;
    }
    if (mPublishingEnd) {
      variables.input.mPublishingEnd = mPublishingEnd;
    }

    if (mAssignedMembers) {
      variables.input.mAssignedMembers = mAssignedMembers;
    }

    if (!isPitch && isSyncActive) {
      variables.input.isSyncActive = isSyncActive;
    }

    if (mPublishingAt && isAutoArchiveStory && !isPitch) {
      variables.input.ttl = getAutoArchiveTime(mPublishingAt);
    }

    if (metadata) {
      variables.input.metadata = metadata;
      variables.input.mdfId = type === 'pitch' ? MTypeToMdfId.pitch : MTypeToMdfId.story;
    }
    setLoading(true);
    await createStoryFromFeed({
      variables,
      update: (_, mutationResult) => {
        if (mutationResult.data) {
          const { createStoryFromFeed: createdStory } = mutationResult.data;
          createdStoryId = createdStory.mId;
          addItem(createdStory);
          updateCache(createdStory, mType);
        }
      },
    });
    setLoading(false);
    return createdStoryId;
  };

  return { create, loading };
};

export default useCreateStoryFromFeed;
