import { SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import useToast from 'components/toast/useToast';
import UserContext from 'contexts/UserContext';
import useCheckUserRight from 'hooks/useCheckUserRight';
import useSharedResource from 'hooks/useSharedResource';
import useSyncedRef from 'hooks/useSyncedRef';
import { CustomEventListener } from 'types/customChannel';
import { createFilteredCustomChannel } from 'utils/filteredCustomChannel';
import { getTimestampFromLockedId } from 'utils/lock/lockTokenV2';

import {
  CollaborationInfoForEditor,
  CollaborationInfoForLockBar,
  CollaborationUsage,
} from './types';

const LOCK_TRANSFER_TIME = 5000;

const RELEASE_LOCK_EVENTS = Object.freeze([
  'lockReleasedOnSave',
  'releaseLockOnCancel',
  'forceUnlock',
]);

interface UnlockInfo {
  lock: string;
  userTitle: string;
}

function isUnlockInfo(x: unknown): x is UnlockInfo {
  return (
    !!x &&
    typeof x === 'object' &&
    'lock' in x &&
    'userTitle' in x &&
    typeof x.lock === 'string' &&
    typeof x.userTitle === 'string'
  );
}

const enableLogging = false;
export const tempLog: undefined | typeof console.log = enableLogging
  ? (...args) => console.log('TEMP:', ...args) // eslint-disable-line no-console
  : undefined;

export interface CollaborationOptions {
  /** The lock used to protect saving */
  lockedBy: string | null;
  writeLock: boolean;
  locking: boolean;
  lockAsync: (timeStamp: string) => Promise<string | null>;
  addLockReleasedOnSaveHandler: (handler: (lock: string) => void) => () => void;
  collaborationFeature?: string;
}

export default function useCollaboration(
  resourceType: string,
  resourceId: string | undefined,
  {
    lockedBy,
    writeLock,
    locking,
    lockAsync,
    addLockReleasedOnSaveHandler,
    collaborationFeature = 'collaborative-editing',
  }: Readonly<CollaborationOptions>,
): CollaborationUsage {
  const userContext = useContext(UserContext);
  const sharedResourceId = resourceId ? `${resourceType}-${resourceId}` : '';
  const [collaborating, setCollaborating] = useState(false);
  const [prevSyncSessionId, setPrevSyncSessionId] = useState<string | null>(null);
  const [releasedLock, setReleasedLock] = useState('');
  const sharedResourceNsChannel = sharedResourceId
    ? `default/${sharedResourceId.replace(/[^a-zA-Z0-9-]/g, '-')}`
    : '';
  const { toast } = useToast();
  const lockedByRef = useSyncedRef(lockedBy);
  const myLock = writeLock ? lockedBy : undefined;
  const writeLockRef = useSyncedRef(writeLock);
  const myConfirmedLock = myLock && !locking ? myLock : undefined;
  useEffect(() => tempLog?.('my lock:', myLock, !locking), [myLock, locking]);
  const sharedResource = useSharedResource(sharedResourceNsChannel, {
    initialState: collaborating,
    myLock: myConfirmedLock,
  });
  const customChannel = sharedResource.customChannel;
  const [checkUserRight] = useCheckUserRight();
  const collaborationEnabled =
    !!sharedResourceNsChannel && checkUserRight('feature', collaborationFeature);
  const collabLock = useMemo(() => {
    if (!collaborationEnabled) {
      return undefined;
    }
    if (myConfirmedLock) {
      return myConfirmedLock;
    }
    const lockOwner = sharedResource.others.find((p) => !!p.lock);
    return lockOwner?.lock;
  }, [myConfirmedLock, collaborationEnabled, sharedResource.others]);
  const hasCollaboratingEditors =
    collaborating || sharedResource.others.some((p) => !!p.lock || p.state);
  const syncSessionId = collabLock ? getTimestampFromLockedId(collabLock) : null;
  const stableSyncSessionId = syncSessionId ?? prevSyncSessionId;
  const yjsSyncChannel = useMemo(() => {
    tempLog?.('new filtered channel', stableSyncSessionId);
    return stableSyncSessionId
      ? createFilteredCustomChannel(sharedResource.customChannel, stableSyncSessionId ?? '')
      : null;
  }, [stableSyncSessionId, sharedResource.customChannel]);

  const setCollaboratingEtc = useCallback(
    (param: SetStateAction<boolean>) => {
      if (typeof param === 'function') {
        setCollaborating((prev) => {
          const next = param(prev);
          if (next !== prev) sharedResource.updateState(next);
          return next;
        });
      } else {
        sharedResource.updateState(param);
        setCollaborating(param);
      }
    },
    [setCollaborating, sharedResource.updateState],
  );
  const hasOtherActiveUsers = !!sharedResource.others.length;

  const prepareCancel = useCallback(
    () =>
      customChannel.broadcastEvent('releaseLockOnCancel', {
        lock: lockedByRef.current,
        userTitle: userContext.attributes?.mTitle ?? 'unnamed user',
      }),
    [customChannel, lockedByRef, userContext],
  );

  const prepareForceUnlock = useCallback(
    () =>
      customChannel.broadcastEvent('forceUnlock', {
        lock: lockedByRef.current,
        userTitle: userContext.attributes?.mTitle ?? 'unnamed user',
      }),
    [customChannel, lockedByRef, userContext],
  );

  useEffect(() => tempLog?.('stable sync session id:', stableSyncSessionId), [stableSyncSessionId]);

  const shouldTakeoverLock =
    !lockedBy &&
    stableSyncSessionId &&
    !syncSessionId &&
    collaborating &&
    !locking &&
    releasedLock &&
    getTimestampFromLockedId(releasedLock) === stableSyncSessionId;
  if (stableSyncSessionId && shouldTakeoverLock) {
    tempLog?.('taking over lock');
    setReleasedLock('');
    lockAsync(stableSyncSessionId)
      .then((lock) => {
        tempLog?.('YJS: locked', lock);
      })
      .catch(() => undefined);
  }

  // This effect is responsible for keeping `prevSyncSessionId` equal to the last non-empty
  // `syncSessionId` for a period of `LOCK_TRANSFER_TIME` if there are still some active editors.
  const isCollaborating = collaborating || hasCollaboratingEditors;
  useEffect(() => {
    if (syncSessionId || !isCollaborating) {
      setPrevSyncSessionId(syncSessionId);
    } else {
      const id = setTimeout(() => setPrevSyncSessionId(null), LOCK_TRANSFER_TIME);
      return () => clearTimeout(id);
    }
  }, [syncSessionId, isCollaborating, setPrevSyncSessionId]);

  // Turn off collaborating if the resource is changed (e.g. when changing daily-note date)
  useEffect(() => {
    tempLog?.('clearing collaboration');
    setCollaborating(false);
  }, [sharedResourceNsChannel, stableSyncSessionId, setCollaborating]);

  // Turn off collaborating if the resource is changed (e.g. when changing daily-note date)
  useEffect(() => {
    if (!myConfirmedLock) return;
    tempLog?.('clearing collaboration B');
    setCollaborating(false);
  }, [!!myConfirmedLock]);

  // This effect is responsible for notifying collaborating parties that the lock owner has
  // released the lock as part of leaving the editing session (save pressed or leaving scope)
  useEffect(() => {
    function onLockReleasedOnSave(lock: string) {
      sharedResource.customChannel.broadcastEvent('lockReleasedOnSave', lock);
    }
    return addLockReleasedOnSaveHandler(onLockReleasedOnSave);
  }, [addLockReleasedOnSaveHandler, sharedResource.customChannel]);

  // This effect is responsible for listening to the 'lockReleasedOnSave' event sent from
  // the lock owner when the lock is released due to a normal save operation,
  // and also to the other released events to turn off collaboration to prevent
  // taking over the lock in force-unlock and cancel cases.
  useEffect(() => {
    const releaseLockHandler: CustomEventListener = (sourceId, type, lockEtc) => {
      if (type === 'lockReleasedOnSave') {
        if (typeof lockEtc !== 'string') return;
        setReleasedLock(lockEtc);
        setTimeout(
          () => setReleasedLock((prev) => (prev === lockEtc ? '' : prev)),
          LOCK_TRANSFER_TIME,
        );
      } else if (isUnlockInfo(lockEtc)) {
        const { lock, userTitle } = lockEtc;
        if (lock !== lockedByRef.current) return;
        let collaborationEnded = false;
        setCollaboratingEtc((wasCollaborating) => {
          collaborationEnded = wasCollaborating;
          return false;
        });
        if (collaborationEnded || writeLockRef.current) {
          const forced = type === 'forceUnlock';
          toast({
            title: forced ? 'Force unlock' : 'Cancel',
            description: `${userTitle} ${forced ? 'aborted' : 'cancelled'} ${
              collaborationEnded ? 'collaborative editing session' : 'editing'
            }`,
            type: 'warn',
          });
        }
      }
    };
    const listenerRemovers = RELEASE_LOCK_EVENTS.map((eventName) =>
      customChannel.addEventListener(eventName, releaseLockHandler),
    );
    return () => listenerRemovers.forEach((removeListener) => removeListener());
  }, [customChannel, setReleasedLock]);

  const editorInfo: CollaborationInfoForEditor = useMemo(
    () => ({
      collaborationEnabled,
      collaborating: collaborating && collaborationEnabled,
      hasOtherActiveUsers,
      sharedResourceId,
      yjsSyncChannel,
    }),
    [collaborating, hasOtherActiveUsers, sharedResourceId, yjsSyncChannel, collaborationEnabled],
  );

  const lockBarInfo: CollaborationInfoForLockBar = useMemo(
    () => ({
      others: sharedResource.others,
      collaborating: collaborating && collaborationEnabled,
      setCollaborating: collaborationEnabled ? setCollaboratingEtc : undefined,
      collabLock,
      prepareCancel,
      prepareForceUnlock,
    }),
    [collaborating, collaborationEnabled, setCollaboratingEtc, sharedResource.others, collabLock],
  );
  return {
    editorInfo,
    lockBarInfo,
  };
}
