import gql from 'graphql-tag';

export default gql`
  subscription notifyAssetUpdate($mIdSubscribed: String!) {
    notifyAssetSubscription(mIdSubscribed: $mIdSubscribed) {
      mId
      mRefId
      mAssetId
      mTitle
      mThumbnailUrl
      mContentUrl
      mType
      mMetaData {
        key
        value
      }
      mSyncProviders {
        mId
        provider
        mMetaData {
          key
          value
        }
        endpoint
      }
      mediaType
      itemType
      mosObj
    }
  }
`;
