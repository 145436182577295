import type { User } from 'types';
import uniqolor from 'uniqolor';

import { deepFreeze } from './objectUtils';

const COLOR_OPTIONS: Parameters<typeof uniqolor>[1] = deepFreeze({
  saturation: [30, 70],
  lightness: [20, 60],
});

export function getUserColor(user: { readonly mId: User['mId'] }): string {
  return uniqolor(user.mId, COLOR_OPTIONS).color;
}

export function getStaticColor(str: string): string {
  return uniqolor(str, COLOR_OPTIONS).color;
}
