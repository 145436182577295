import { memo } from 'react';

import AddMemberDropdown from 'components/addMember';
import { AssignedMember } from 'types';

import type CellProps from '../cellProps';
import { normalizeFilter } from '../utils';

import { Wrapper } from './styled';

/**
 * Designed to only be rendered when editing.
 */
function UserCell({ fieldModel, setValue, onFocus, onBlur }: Readonly<CellProps>) {
  const { filter, required } = fieldModel;
  const normalizedFilter = normalizeFilter(filter);
  const isContact = normalizedFilter?.includes('contact');

  const selectAssignee = (m: AssignedMember | null) => {
    setValue(m ? m.mId : null);
    onBlur?.();
  };

  return (
    <Wrapper>
      <AddMemberDropdown
        variants={normalizedFilter}
        variant="all"
        singleChoice
        autoFocus
        showChips
        selectOnFocus
        disableClearable={required}
        selectedMembers={[]}
        setSelectedMembers={(val: AssignedMember[]) => selectAssignee(val?.[0] ?? null)}
        noOptionsText="No members found"
        injectedMembers={undefined}
        onFocus={onFocus}
        onBlur={onBlur}
        doCallApi={isContact}
      />
    </Wrapper>
  );
}

export default memo(UserCell);
