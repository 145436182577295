import { useCallback, useState } from 'react';
import { ConnectableElement, DropTargetMonitor, useDrop } from 'react-dnd';
import styled from '@emotion/styled';

import useGetMember from 'api/useGetMember';
import Divider from 'components/divider';
import Tabs from 'components/tabs/regular/Tabs';
import useToast from 'components/toast/useToast';
import { PreviewProps } from 'features/preview/utils/childMap';
import useShareStory from 'hooks/useShareStory';
import { Box, VStack } from 'layouts/box/Box';
import { TabsContent as RTabsContent, TabsList, TabsTrigger } from 'lib/tabs';
import { MemberType } from 'types/graphqlTypes';
import { User } from 'types/members';
import dndTypes from 'utils/dndTypes';

import Activity from './activity/Activity';
import Details from './Details';
import Header from './Header';
import Skeleton from './Skeleton';
import useMemberDetails from './useMemberDetails';

const menuTabs = ['Details', 'Activity'];
const Container = styled(VStack)<{ hovered?: boolean; width?: string }>`
  width: ${({ width }) => width ?? '450px'};
  height: 100%;
  justify-content: flex-start;
  border: 1px solid
    ${({ hovered, theme }) => (hovered ? theme.palette.dina.statusWarning : 'transparent')};
`;

export const TabsWrapper = styled('div')`
  height: 100%;
  width: 100%;
`;

export const TabsListWrapper = styled(TabsList)`
  width: 100;
  display: flex;
  background-color: ${({ theme }) => theme.palette.dina.overlaysHeaderFooter};
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TabsContent = styled(RTabsContent)`
  width: 100%;
  height: 100%;
  outline: none;
  flex-grow: 1;
  overflow: hidden;
  &[data-state='active'] {
    display: flex;
  }
`;

const TabsTriggerButton = styled(TabsTrigger)`
  all: unset;
  color: ${({ theme }) => theme.palette.dina.mediumEmphasis};
  width: 50%;
  height: 31px;
  text-align: center;
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.palette.dina.highEmphasis};
  }
  &[data-state='active'] {
    color: ${({ theme }) => theme.palette.dina.tabSelected};
    box-shadow: inset 0 -2px 0 0 ${({ theme }) => theme.palette.dina.tabSelected};
  }
`;

type MemberDetailsPreviewProps = Omit<PreviewProps, 'member'> & {
  memberId?: string;
  member: MemberType | null;
  width?: string;
  isPreview?: boolean;
  openItem?: (val: MemberType) => void;
};

function MemberDetails({
  member,
  memberId,
  onClose,
  width,
  isPreview = false,
  openItem = () => {},
}: Readonly<MemberDetailsPreviewProps>) {
  const { errorToast } = useToast();
  const [assignMemberToStory] = useShareStory();
  const [activeTab, setActiveTab] = useState('Details');
  const { data, loading } = useGetMember<User>({
    mId: memberId ?? member?.mId ?? '',
    mRefId: memberId ?? member?.mId ?? '',
    fetchPolicy: 'cache-and-network',
    skip: !memberId && !member?.mId,
  });

  const { users } = useMemberDetails(data);

  const [{ hovered }, dropRef] = useDrop({
    accept: [dndTypes.STORY, dndTypes.PITCH],
    drop: (_, monitor: DropTargetMonitor<{ id: string }, User>) => {
      if (monitor) {
        assignMemberToStory(monitor?.getItem()?.id, data?.mId, data?.mType).catch((err) =>
          errorToast(err),
        );
      }
    },
    collect: (monitor) => ({ hovered: monitor.isOver() }),
  });

  const attachRef = useCallback(
    (el: ConnectableElement) => {
      dropRef(el);
    },
    [dropRef],
  );

  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
  };

  if (data) {
    return (
      <Container ref={attachRef} hovered={hovered} width={width}>
        <Header
          member={data}
          userCount={users?.length}
          onClose={onClose}
          isPreview={isPreview}
          openItem={openItem}
        />

        <TabsWrapper>
          <Tabs
            defaultTab={menuTabs[0]}
            activeTab={activeTab}
            direction="vertical"
            setActiveTab={handleTabClick}
          >
            <TabsListWrapper>
              {menuTabs.map((tab) => (
                <TabsTriggerButton key={tab} id={tab} title={tab} value={tab}>
                  {tab}
                </TabsTriggerButton>
              ))}
            </TabsListWrapper>
            <Divider />
            <TabsContent value="Details">
              <Details member={data} />
            </TabsContent>

            <TabsContent value="Activity">
              <Activity memberId={data.mId} />
            </TabsContent>
          </Tabs>
        </TabsWrapper>
      </Container>
    );
  }

  if (loading)
    return (
      <Container width={width}>
        <Box width="100%" padding="0px 6px">
          <Skeleton />
        </Box>
      </Container>
    );

  return <span></span>;
}

export default MemberDetails;
