import { useCallback } from 'react';
import { gql, useApolloClient, useMutation } from '@apollo/client';
import { sortBy } from 'lodash';

import { GetInput, SearchResult } from 'api/search/types';
import useToast from 'components/toast/useToast';
import useNewItems from 'hooks/useNewItems';
import { ParsedMemberType } from 'types';
import { MemberType, UpdateMemberInput } from 'types/graphqlTypes';
import { planningViews } from 'utils/planningViews';

import { useGetQueryInput } from '../hooks/useGetInput';
import { useStoryHubMolecule } from '../store/storyHub';

import useCrudAction from './useCrudAction';

interface UpdateMemberMutation {
  updateMember: MemberType;
}

const UPDATE_QUERY = gql`
  mutation UpdateMember($input: UpdateMemberInput) {
    updateMember(input: $input) {
      mId
      mRefId
      mPriority
    }
  }
`;

function useUpdatePriority() {
  const { modify } = useCrudAction();
  const [update] = useMutation<UpdateMemberMutation>(UPDATE_QUERY);
  const { query, variables } = useGetQueryInput();
  const { errorToast } = useToast();
  const client = useApolloClient();
  const { addItem } = useNewItems();
  const { useStoryHubPolling, useStoryHubTab } = useStoryHubMolecule();
  const [, setPolling] = useStoryHubPolling();
  const [tab] = useStoryHubTab();

  const updateCache = useCallback(
    (updatedMember: MemberType) => {
      const result = client.readQuery<SearchResult, GetInput>({ query, variables });
      if (!result) return;

      const updatedResult = modify(result, updatedMember);
      if (!updatedResult) return;

      client.writeQuery<SearchResult, GetInput>({
        query,
        variables,
        data: {
          ...updatedResult,
          searchItem: {
            ...updatedResult.searchItem,
            items: sortBy(updatedResult.searchItem.items, ['mPriority']),
          },
        },
      });
    },
    [client, query, variables],
  );

  const updatePriority = useCallback(
    (item: MemberType | ParsedMemberType, mPriority: string | null) => {
      const { mId, mRefId } = item;
      if (!mId || !mRefId) return;
      const input: UpdateMemberInput = {
        mId,
        mRefId,
        mPriority,
      };

      const optimisticResponse = {
        updateMember: {
          mId,
          mRefId,
          mPriority: mPriority ?? (null as unknown as string),
          __typename: 'MemberType',
        } as MemberType,
      };
      // support for update in storyhub dayview
      if (tab.label === planningViews.DAY)
        optimisticResponse.updateMember.mUpdatedAt = new Date().toISOString();
      updateCache(optimisticResponse.updateMember);

      setPolling(false);
      update({
        variables: { input },
        optimisticResponse,
        update: (_, mutationResult) => {
          const updatedMember = mutationResult.data?.updateMember;
          if (!updatedMember) return;
          addItem(updatedMember);
          updateCache(updatedMember);
        },
      })
        .catch((err) => errorToast(err))
        .finally(() => setPolling(true));
    },
    [addItem, setPolling, update, updateCache],
  );

  return { updatePriority };
}

export default useUpdatePriority;
