/**
 * This module contains helper functionality for MDF schemas, option lists and option trees
 * @module
 */

/**
 * Base interface for MDF items (common properties between `OptionList` and `Mdf`)
 */
export interface MdfItem {
  /** The ID of the item */
  readonly id: string;
  /** The label/name of the item */
  readonly label: string;
}

/**
 * A function to get the type display text for an {@link MdfItem}.
 * @param item       The {@link MdfItem} to get the type display text for
 * @param indefinite Set to `true` to get the display text on singular indefinite form
 *                   (e.g.: `"an option list"`) and on `false` to get only the noun
 *                   (e.g.: `"option list"`)
 * @template T       The type of Mdf items of interest (`OptionList` and `Mdf`)
 */
export type GetMdfItemTypeText<T extends MdfItem> = (item: T, indefinite: boolean) => string;

/**
 * Contains functionality to retrieve from the class of MDF items and its corresponding change info.
 * @template T The type of Mdf items of interest (`OptionList` and `Mdf`)
 * @template I The type of the values in the change map
 */
export interface MdfItemInfoMapper<T extends MdfItem, I> {
  /** An {@link GetMdfItemTypeText} function to retrieve display text for the type  */
  readonly getItemTypeText: GetMdfItemTypeText<T>;
  /** A function to retrieve the MDF item (`T`) from change information */
  readonly getChangeInfoItem: (changeInfo: I) => T;
}

export interface MdfItemCreationInfoMapper<T extends MdfItem, I, C extends string>
  extends MdfItemInfoMapper<T, I> {
  readonly getCategoryTypeText: (category: C, indefinite: boolean) => string;
  readonly infoTexts: Readonly<Record<C, string>>;
}

/**
 * Creates a function that can be used to validate the label of an option list/tree.
 * When creating a new item we have to consider labels of both the saved labels and the
 * labels of items (since we may end up both saving or discard the changes and creation
 * is immediate).
 * @param items             The related mdf items in the system as retrieved from the back-end
 * @param changeMap         The related items that have been changed but not yet saved
 * @param originalLabel     The original label of the MFD item or `undefined` if creating a new item
 * @param typeText          The display text for the type of the item to rename/create
 * @param infoMapper        Interface containing functionality to retrieve information from
 *                          `items` and `changeMap`.
 * @returns                 The validator function that receives a label and returns an error
 *                          message text if not OK or `true` if ok.
 */
export function createLabelValidator<T extends MdfItem, I>(
  items: readonly T[],
  changeMap: Readonly<Record<string, I>>,
  originalLabel: string | undefined,
  typeText: string,
  infoMapper: MdfItemInfoMapper<T, I>,
): (label: string) => string | boolean {
  const byId = Object.fromEntries(items.map((l) => [l.id, l]));
  Object.entries(changeMap).forEach(
    ([id, info]) => (byId[id] = infoMapper.getChangeInfoItem(info)),
  );
  const byLabel = Object.fromEntries(Object.values(byId).map((l) => [l.label, l]));

  return (label) => {
    if (!label) {
      return `A label must be provided for the ${typeText}`;
    }
    if (label === originalLabel) {
      return false;
    }
    const existing =
      byLabel[label] ??
      (originalLabel === undefined ? items.find((item) => item.label === label) : undefined);
    if (!existing) return true;
    const existingTypeText = infoMapper.getItemTypeText(existing, true);
    return `'${label}' is already used as label for ${existingTypeText}`;
  };
}
