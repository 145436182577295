import { useCallback, useMemo } from 'react';

import usePlatformWorkflow from 'hooks/usePlatformWorkflow';

export const inReviewState = '03-in-review';
export const isApproved = (mState: string, approvalState?: string) =>
  approvalState ? mState === approvalState : mState === '04-ready';

export const restrictedPlatformProps = (platformName: string) => {
  return {
    __typename: 'PlatformType',
    provider: {
      message: `Publishing to ${platformName} is restricted, needs approval.`,
    },
  };
};

/**
 * Hook to get approval state and restricted platform props for a platform
 * based on platform workflow.
 */
export const useRestrictedPlatformPropsHelper = (platform: string) => {
  const { getApprovalState } = usePlatformWorkflow();

  const { approvalState } = useMemo(
    () => ({
      approvalState: getApprovalState(platform),
    }),
    [getApprovalState, platform],
  );

  const memoizedIsApproved = useCallback(
    (mState: string) => isApproved(mState, approvalState ?? undefined),
    [approvalState],
  );

  return {
    inReviewState,
    isApprovalWorkflowEnabled: !!approvalState,
    approvalState,
    restrictedPlatformProps,
    isApproved: memoizedIsApproved,
  };
};
