import { Editor, Path, Range, Text, Transforms } from 'slate';

import { CustomElement } from 'types';

/**
 * Converts a string to uppercase or lowercase based on the `isActive` flag.
 */
const convertToCase = (text: string, isActive: boolean) => {
  return isActive ? text.toLowerCase() : text.toUpperCase();
};

/**
 * Toggles the case of text within the current selection in a Slate editor.
 * This function iterates through all text nodes in the selection and converts
 * their text to either uppercase or lowercase, depending on the `isActive` flag.
 *
 * It preserves the structure of the document, including void nodes.
 */
const toggleCase = (editor: Editor, isActive: boolean) => {
  const { selection } = editor;

  if (!selection && !Range.isRange(selection)) return;

  if (Range.isCollapsed(selection)) return;

  const [start, end] = Range.edges(selection);

  for (const [node, path] of Editor.nodes(editor, {
    at: selection,
    match: Text.isText,
  })) {
    const [parentNode] = Editor.parent(editor, path);
    if (Editor.isVoid(editor, parentNode as CustomElement)) continue; // Skip void nodes

    const isStartNode = Path.equals(path, start.path);
    const isEndNode = Path.equals(path, end.path);

    const range = {
      anchor: {
        path,
        offset: isStartNode ? start.offset : 0,
      },
      focus: {
        path,
        offset: isEndNode ? end.offset : node.text.length,
      },
    };

    const selectedText = node.text.slice(range.anchor.offset, range.focus.offset);
    const transformedText = convertToCase(selectedText, isActive);

    Transforms.insertText(editor, transformedText, { at: range });
  }
  Transforms.select(editor, selection);
};

export default toggleCase;
