import { capitalize } from 'lodash';

import Text from 'components/text';
import useDateTimeUtils from 'hooks/useDateTimeUtils';
import { HStack, VStack } from 'layouts/box/Box';
import { topLevelMediaTopicsMap } from 'utils/constants/mediaTopics';
import getLocaleTimeFormat from 'utils/dateTime/getLocaleTimeFormat';

import { Metadata, SectionChip, TitleSection } from './styled';

interface HeaderProps {
  href?: string;
  mPublishedAt: string;
  mediatopics?: string[];
  source?: string;
  searchWords?: string[];
  title?: string;
  firstcreated?: string;
  versioncreated?: string;
}

const getSourceUrl = (href?: string) => {
  if (href && href.length > 0) {
    return (
      <Text
        as="a"
        variant="caption"
        color="blackMediumEmphasis"
        href={href}
        target="_blank"
        rel="noopener noreferrer"
      >
        open original article
      </Text>
    );
  }
  return '';
};

export default function FeedViewerHeader({
  href,
  mPublishedAt,
  mediatopics,
  source,
  title,
  firstcreated,
  versioncreated,
}: Readonly<HeaderProps>) {
  const { format } = useDateTimeUtils();
  const sourceUrl = getSourceUrl(href);

  const topics =
    mediatopics
      ?.map((topic) => capitalize(topLevelMediaTopicsMap[topic]?.name) ?? undefined)
      .filter(Boolean) ?? [];

  return (
    <>
      <TitleSection>
        <HStack gap="2px">
          {topics.map((topic) => (
            <SectionChip key={topic} label={topic} />
          ))}
        </HStack>
        <Text variant="h4" color="blackHighEmphasis">
          {title}
        </Text>
      </TitleSection>
      <Metadata>
        <span>
          <Text variant="listItemLabel" color="blackHighEmphasis" as="p">
            {`${source} `}
            {sourceUrl}
          </Text>
          <Text variant="caption" color="blackMediumEmphasis">
            {topics.join(', ') ?? <>&nbsp;</>}
          </Text>
        </span>
        <VStack alignItems="flex-end" gap="4px">
          <Text variant="caption" color="blackMediumEmphasis" style={{ textAlign: 'end' }}>
            First created:{' '}
            {format(firstcreated ?? mPublishedAt, `MM/dd/yyyy, ${getLocaleTimeFormat()}`)}
          </Text>
          <Text variant="caption" color="blackMediumEmphasis" style={{ textAlign: 'end' }}>
            Version created:{' '}
            {format(versioncreated ?? mPublishedAt, `MM/dd/yyyy, ${getLocaleTimeFormat()}`)}
          </Text>
        </VStack>
      </Metadata>
    </>
  );
}
