import { FormEventHandler, useReducer } from 'react';

import InputField from 'components/inputField/InputField';
import { useAuthContext } from 'contexts/AuthContext';

import { useCurrentForm, useLoading, useTempUser } from '../store/atom';

import {
  Form,
  FormContainer,
  FormTitle,
  InfoText,
  LinksWrapper,
  StyledButton,
  StyledLink,
} from './forms-styled';
import { getErrorText } from './utils';

const INITIAL_STATE = {
  username: '',
  usernameDescription: 'Type your Username here...',
  severity: 'mandatory',
};

const reducer = (
  state: typeof INITIAL_STATE,
  { type, payload }: { type: string; payload: string },
) => {
  switch (type) {
    case 'updateUsername':
      return state.severity === 'error'
        ? { ...INITIAL_STATE, username: payload }
        : { ...state, username: payload };
    case 'forgotError':
      return {
        ...state,
        severity: 'error',
        usernameDescription: payload,
      };
    default:
      throw new Error(`Action ${type} is not defined`);
  }
};

const ForgotPassword = () => {
  const { forgotPassword } = useAuthContext();
  const [, setTempUser] = useTempUser();
  const [loading, setLoading] = useLoading();
  const [, setCurrentForm] = useCurrentForm();
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  async function onForgot() {
    setLoading(true);
    try {
      await forgotPassword?.(state.username.trim());
      setTempUser(state.username);
      setLoading(false);
      setCurrentForm('RESET_PASSWORD');
    } catch (error) {
      dispatch({ type: 'forgotError', payload: getErrorText(error) });
      setLoading(false);
    }
  }
  const handleForgot: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    void onForgot();
  };

  return (
    <FormContainer>
      <FormTitle>Forgot Password?</FormTitle>
      <InfoText>Please enter your Username below</InfoText>
      <Form onSubmit={handleForgot}>
        <InputField
          label="Username"
          type="text"
          autoComplete="username"
          value={state.username}
          severity={state.severity}
          description={state.usernameDescription}
          onChange={(value: string) => dispatch({ type: 'updateUsername', payload: value })}
        />
        <StyledButton
          type="submit"
          disabled={state.severity === 'error' || !state.username || loading}
        >
          SUBMIT
        </StyledButton>
      </Form>
      <LinksWrapper>
        <StyledLink component="button" onClick={() => setCurrentForm('DEFAULT_LOGIN')}>
          Go Back
        </StyledLink>
      </LinksWrapper>
    </FormContainer>
  );
};

export default ForgotPassword;
