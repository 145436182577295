import { useCallback } from 'react';

import { isMiniMember } from 'components/mdfEditor/fields/relation/relation-utils';
import FeedViewer from 'features/feedViewer/FeedViewer';
import { VStack } from 'layouts/box/Box';
import MemberDetails from 'screens/main/components/rightPanel/memberDetails/MemberDetails';
import { useClosePreview, usePreviewValue } from 'store/preview';
import { MemberType, MemberTypeEnum } from 'types/graphqlTypes';

import { FeedHeader } from './components/Headers';
import { VesselComponents } from './utils/vesselMap';
import PreviewChild from './ChildFactory';
import PreviewVessel from './VesselFactory';

const isMemberType = (item: unknown): item is MemberType => {
  return (item as MemberType)?.mType !== undefined;
};

export default function Preview() {
  const previewItem = usePreviewValue();
  const closePreview = useClosePreview();

  const closePreviewAndClearParams = useCallback(() => {
    closePreview();
    const params = new URLSearchParams(window.location.search);
    params.delete('previewPanes');
    window.history.replaceState({}, '', `?${params.toString()}`);
  }, [closePreview]);

  if (typeof previewItem === 'string') {
    return (
      <PreviewVessel
        type={VesselComponents.DRAWER}
        open={Boolean(previewItem)}
        onClose={closePreviewAndClearParams}
        title=""
      >
        <MemberDetails member={null} memberId={previewItem} onClose={closePreviewAndClearParams} />
      </PreviewVessel>
    );
  }

  if (previewItem && previewItem.__typename === 'FeedItem') {
    return (
      <PreviewVessel
        type={VesselComponents.DRAWER}
        open={Boolean(previewItem)}
        onClose={closePreviewAndClearParams}
        title={previewItem?.mTitle}
      >
        <VStack height="100%" minWidth="600px" maxWidth="800px">
          <FeedHeader onClose={closePreviewAndClearParams} title={previewItem?.mTitle} />
          <FeedViewer feedItem={previewItem} noBorderRadius />
        </VStack>
      </PreviewVessel>
    );
  }

  const isInstance = isMemberType(previewItem) && previewItem?.mType === MemberTypeEnum.Instance;
  const type = isInstance ? VesselComponents.DIALOG : VesselComponents.DRAWER;
  const title = !isMiniMember(previewItem) ? previewItem?.mTitle : '';

  return (
    <PreviewVessel
      type={type}
      open={Boolean(previewItem)}
      onClose={closePreviewAndClearParams}
      title={title}
    >
      {previewItem && (
        <PreviewChild member={previewItem as MemberType} onClose={closePreviewAndClearParams} />
      )}
    </PreviewVessel>
  );
}
