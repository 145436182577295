import { memo, useCallback, useState } from 'react';

import type CellProps from '../cellProps';
import { CellMap } from '../fields';
import { doesNotNeedLightWeight } from '../utils';
import ValueOnlyCell from '../ValueOnlyCell';

/**
 * A light cell wrapper to take care of rendering light weight cells where possible.
 *
 * This component ensures that cells that require a lot of event listeners are not in the
 * render loop by default.
 */
function LightWeightCellWrapper({
  fieldModel,
  fieldSettings,
  mdf,
  value,
  disableEdit,
  ...rest
}: Readonly<CellProps>) {
  const [editing, setEditing] = useState(false);

  const Cell = CellMap[fieldModel.type];

  const onBlur = useCallback(() => {
    setEditing(false);
  }, []);

  if (!doesNotNeedLightWeight(fieldModel.type)) {
    return (
      <Cell
        fieldModel={fieldModel}
        disableEdit={disableEdit}
        mdf={mdf}
        fieldSettings={fieldSettings}
        value={value}
        {...rest}
      />
    );
  }

  return (
    <>
      {editing ? (
        <Cell
          fieldModel={fieldModel}
          disableEdit={disableEdit}
          mdf={mdf}
          fieldSettings={fieldSettings}
          value={value}
          onBlur={onBlur}
          {...rest}
        />
      ) : (
        <ValueOnlyCell
          disableEdit={disableEdit}
          visible={!editing}
          fieldModel={fieldModel}
          fieldSettings={fieldSettings}
          value={value}
          setIsEditing={setEditing}
        />
      )}
    </>
  );
}

export default memo(LightWeightCellWrapper);
