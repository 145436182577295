import { useCallback } from 'react';

/**
 * Creates a stable (given that its parameters are stable) function that takes one parameter,
 * a lock-time, and locks the current document to the user, passed in to the hook, and the lock
 * time passed into the function.
 * (The resulting function can be used as the `lockAsync` property of the collaboration options
 * passed to the `useCollaboration` hook.)
 * @param lockCurrentDocument A function locking the current open document to a provided user and
 *                            lock time.
 * @param currentUserId       The ID of the authenticated user
 * @returns                   A function taking one parameter, the lock time, that locks the current
 *                            document to the current authenticated user and the provided lock time.
 */
export function useTakeoverLockAsync(
  lockCurrentDocument: (userId: string, lockTime?: string) => Promise<string | null | undefined>,
  currentUserId: string,
) {
  return useCallback(
    async (lockTime: string) => (await lockCurrentDocument(currentUserId, lockTime)) ?? null,
    [currentUserId, lockCurrentDocument],
  );
}
