import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { HStack } from 'layouts/box/Box';
import { ResizableHandle } from 'lib/resize';

export const StyledNotificationIcon = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  cursor: pointer;
  &:hover svg path {
    fill-opacity: 1 !important;
  }
`;

export const StyledChatEnabled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  ${({ theme }) => css`
    svg {
      width: 24px;
      height: 32px;
      path {
        fill: ${theme.palette.dina.whiteHighEmphasis};
        fill-opacity: ${theme.palette.mode === 'light' ? 0.65 : 0.54};
      }
    }
  `}
  &:hover {
    svg {
      path {
        fill-opacity: 1;
      }
    }
  }
`;

export const NotificationIndicatorWrapper = styled.div`
  position: absolute;
  left: 12px;
  top: 0px;
`;

export const MessagesWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.dina.surfaceCardDark};
  border: 0.5px solid ${({ theme }) => theme.palette.dina.dividerLight};
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

export const HeaderWrapper = styled(HStack)`
  height: 40px;
  padding: 0 12px;
  justify-content: flex-start;
  gap: 8px;
  background-color: ${({ theme }) => theme.palette.dina.headerFooterOverlay};
`;

export const StyledBody = styled('div')`
  display: flex;
  flex: 1;
  overflow: hidden;
`;

export const StyledResizableHandle = styled(ResizableHandle)`
  transition: 250ms linear all;
  width: 2px;
  outline: 1px solid transparent;
  background-color: ${({ theme }) => theme.palette.dina.dividerLight};
  :hover,
  &[data-resize-handle-active] {
    background-color: ${({ theme }) => theme.palette.dina.onFocus};
    outline-color: ${({ theme }) => theme.palette.dina.onFocus};
  }
`;

export const NewMessageContainer = styled('div')`
  z-index: 100;
  position: absolute;
  width: 500px;
  top: 1px;
  right: 1px;
  bottom: 1px;
`;
