import { ResourcesConfig } from 'aws-amplify';
import { fetchAuthSession, getCurrentUser, signInWithRedirect } from 'aws-amplify/auth';

import { type AuthContextProps } from 'contexts/AuthContext';
import { type UserContextProps } from 'contexts/UserContext';

const ENABLE_AUTH_FLOW_LOG1 = new URLSearchParams(location.search).has('auth-flow-logging');
// eslint-disable-next-line no-console
const authFlowLogger: typeof console.log = (...args) => console.log('AUTH-FLOW:', ...args);
export const AuthFlow = Object.freeze({
  log0: authFlowLogger as undefined | typeof console.log,
  log1: ENABLE_AUTH_FLOW_LOG1 ? authFlowLogger : undefined,
  // eslint-disable-next-line no-console
  error: (...args: unknown[]) => console.error('AUTH-FLOW:', ...args),
});

const AuthStates = {
  VERIFIED: 'verified',
  NOT_VERIFIED: 'not_verified',
  VERIFYING: 'verifying',
};
const AuthStateId = 'state';

const getAuthState = () => {
  const { localStorage } = window;
  return localStorage.getItem(AuthStateId);
};

const setAuthState = (state: string) => {
  const { localStorage } = window;
  const currentState = getAuthState();
  localStorage.setItem(AuthStateId, state);
  return currentState;
};

const openFederatedSignIn = async () => {
  try {
    await signInWithRedirect();
  } catch (error) {
    AuthFlow.error('openFederatedSignIn failed', error);
    AuthFlow.log1?.('current user', await getCurrentUser());
    AuthFlow.log1?.('auth session', await fetchAuthSession());
  }
};

const getSignInUser = (context: AuthContextProps | null): UserContextProps => {
  const user = { mId: '-', groups: [] as string[] };
  if (!context?.user) {
    return user;
  }

  user.mId = context.user.dinaUserId;
  user.groups = context.groups;
  return user;
};

const toBoolean = (strBool: string | undefined) => strBool?.toUpperCase() === 'TRUE';

const UseHostedUI = toBoolean(import.meta.env.REACT_APP_USE_HOSTED_UI as string | undefined);
const UseAuthAudit = toBoolean(import.meta.env.REACT_APP_AUTH_AUDIT as string | undefined);

/** Cognito authorization */
const COGNITO_AUTH: Exclude<ResourcesConfig['Auth'], undefined> = {
  Cognito: {
    userPoolId: import.meta.env.REACT_APP_AWS_COGNITO_USER_POOL_ID as string,
    userPoolClientId: import.meta.env.REACT_APP_AWS_COGNITO_USER_POOL_CLIENT_ID as string,
    identityPoolId: import.meta.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID as string,
  },
};

/** Hosted UI authorization */
const HostedUIAuth: Exclude<ResourcesConfig['Auth'], undefined> = {
  Cognito: {
    userPoolId: import.meta.env.REACT_APP_AWS_COGNITO_USER_POOL_ID as string,
    userPoolClientId: import.meta.env.REACT_APP_AWS_COGNITO_USER_POOL_CLIENT_ID as string,
    identityPoolId: import.meta.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID as string,
    loginWith: {
      oauth: {
        domain: import.meta.env.REACT_APP_AWS_COGNITO_DOMAIN as string,
        scopes: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
        redirectSignIn: [`${window.origin}/`],
        redirectSignOut: [`${window.origin}/`],
        responseType: 'code', // REFRESH token will only be created for responseType=code
      },
    },
  },
};

const getAuthConfiguration = () => (UseHostedUI ? HostedUIAuth : COGNITO_AUTH);

const isGroupMember = (userGroups: readonly string[], groups: readonly string[]) =>
  groups.some((group) => userGroups.includes(group));

export {
  openFederatedSignIn,
  getSignInUser,
  getAuthConfiguration,
  UseHostedUI,
  UseAuthAudit,
  isGroupMember as default,
  getAuthState,
  setAuthState,
  AuthStates,
};
