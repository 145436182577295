import { useCallback } from 'react';
import { useMutation } from '@apollo/client';

import UPDATE_ORGANIZATION from 'operations/mutations/updateOrganization';
import { useReadableOrganizations } from 'store/organization';
import type { Organization, UpdateOrganizationInput } from 'types/graphqlTypes';

interface UpdateOrganization {
  updateOrganization: Organization;
}

function useUpdateOrganization() {
  const [create, { loading, error }] = useMutation<
    UpdateOrganization,
    { input: UpdateOrganizationInput }
  >(UPDATE_ORGANIZATION);
  const [, setReadableOrganizations] = useReadableOrganizations();

  const updateCache = useCallback(
    ({ id, title, description, isArchived, isRestricted, timeZone }: UpdateOrganizationInput) => {
      setReadableOrganizations((prev) =>
        prev.map((o) => {
          if (o.id !== id) return o;
          return { ...o, title, description, isArchived, isRestricted, timeZone };
        }),
      );
    },
    [setReadableOrganizations],
  );

  const updateOrganization = useCallback(
    async (updateOrgProps: UpdateOrganizationInput) => {
      const { id, title, description, isArchived, isRestricted, timeZone } = updateOrgProps;
      const result = await create({
        variables: { input: { id, title, description, isArchived, isRestricted, timeZone } },
        update: () => updateCache(updateOrgProps),
      });
      return result.data?.updateOrganization;
    },
    [create, updateCache],
  );

  return { updateOrganization, loading, error };
}

export default useUpdateOrganization;
