import { CustomElement, isCustomElement } from 'types';

const extractText = (nodes: CustomElement[]) => {
  let textContent = '';

  nodes.forEach((node) => {
    if (node.type === 'paragraph' && node.children) {
      node.children.forEach((child) => {
        if (isCustomElement(child)) return;
        if (child.text) {
          textContent += child.text + '\n';
        }
      });
    }
  });
  return textContent.trim();
};

export default extractText;
