import { useCallback } from 'react';
import { useApolloClient, useMutation } from '@apollo/client';

import { Script } from 'types';
import { UpdateScriptInput } from 'types/graphqlTypes';

import { UPDATE_SCRIPT } from './graphql';

export type UpdateScriptInputType = {
  input: UpdateScriptInput;
};

export type UpdateScriptResponseType = {
  updateScript: Script | null;
};

const useUpdateScript = () => {
  const client = useApolloClient();
  const [updateScriptMutation, { data, loading, error }] = useMutation<
    UpdateScriptResponseType,
    UpdateScriptInputType
  >(UPDATE_SCRIPT);

  const updateScript = useCallback(
    async (input: UpdateScriptInput, optimistic: boolean = false) => {
      if (optimistic) {
        try {
          client.cache.modify({
            id: client.cache.identify({ mId: input.mId, mRefId: input.mRefId }),
            fields: {
              locked: () => input.locked ?? null,
              mState: () => input.mState,
            },
          });
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error('Error updating script optimistic response:', err);
        }
      }

      const result = await updateScriptMutation({
        variables: {
          input,
        },
      });

      return result?.data?.updateScript ?? null;
    },
    [client, updateScriptMutation],
  );

  return {
    updateScript,
    data: loading ? undefined : data?.updateScript ?? null,
    loading,
    error,
  };
};

export default useUpdateScript;
