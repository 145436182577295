/* eslint-disable sort-imports */
import { useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';

import {
  ChoiceOptionList,
  GetMemberInput,
  OptionListRaw,
  TreeChoiceOptionList,
} from 'types/graphqlTypes';

import { STANDARD_OPTION_LIST_KEYS } from '../commonKeys';

import { generateIdForAlternatives } from './useGetOptionLists';

const GET_LIST = gql`
  query GetOptionList($input: GetMemberInput) {
    getOptionList(input: $input) {
      ${STANDARD_OPTION_LIST_KEYS}
    }
  }
`;

interface GetList {
  getOptionList: OptionListRaw;
}

interface GetMemberInputType {
  input: GetMemberInput;
}

function useListQuery(id: string | undefined | null, forceFetch: boolean) {
  return useQuery<GetList, GetMemberInputType>(GET_LIST, {
    variables: {
      input: {
        mId: id ?? '',
      },
    },
    nextFetchPolicy: forceFetch ? 'cache-and-network' : 'cache-first',
    fetchPolicy: forceFetch ? 'network-only' : 'cache-and-network',
    skip: !id,
  });
}

export const useGetOptionList = (id: string | undefined | null, forceFetch = false) => {
  const { data, error, loading, refetch } = useListQuery(id, forceFetch);

  const optionList = useMemo(() => {
    if (data?.getOptionList) {
      return generateIdForAlternatives(data.getOptionList);
    }
    return null;
  }, [data]);

  return { optionList, error, loading, refetch };
};

type OptionType = OptionListRaw['optionListType'];

type OptionListType<T> = T extends 'choice'
  ? ChoiceOptionList
  : T extends 'treechoice'
  ? TreeChoiceOptionList
  : never;

export const useGetTypedOptionList = <T extends OptionType>(
  id: string | undefined | null,
  type: T,
  forceFetch = false,
) => {
  const { data, error, loading, refetch } = useListQuery(id, forceFetch);

  const optionList = useMemo(() => {
    if (data?.getOptionList.optionListType === type) {
      return generateIdForAlternatives(data.getOptionList);
    }
    return null;
  }, [data]);

  return { optionList: optionList as OptionListType<T>, error, loading, refetch };
};
