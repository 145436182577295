import React, { memo } from 'react';
import styled from '@emotion/styled';

import { ReactComponent as CMSIcon } from 'assets/icons/systemicons/instanceBadges/CMS.svg';
import { ReactComponent as DefaultIcon } from 'assets/icons/systemicons/instanceBadges/default.svg';
import { ReactComponent as FacebookIcon } from 'assets/icons/systemicons/instanceBadges/Facebook.svg';
import { ReactComponent as InstagramIcon } from 'assets/icons/systemicons/instanceBadges/Instagram.svg';
import { ReactComponent as LinkedInIcon } from 'assets/icons/systemicons/instanceBadges/LinkedIn.svg';
import { ReactComponent as OTTIcon } from 'assets/icons/systemicons/instanceBadges/OTT.svg';
import { ReactComponent as RadioIcon } from 'assets/icons/systemicons/instanceBadges/Radio.svg';
import { ReactComponent as TiktokIcon } from 'assets/icons/systemicons/instanceBadges/TikTok.svg';
import { ReactComponent as LinearIcon } from 'assets/icons/systemicons/instanceBadges/TV.svg';
import { ReactComponent as XIcon } from 'assets/icons/systemicons/instanceBadges/X.svg';
import { ReactComponent as YoutubeIcon } from 'assets/icons/systemicons/instanceBadges/YouTUbe.svg';
import { ReactComponent as BlueskyIcon } from 'assets/icons/systemicons/publishing_points/bluesky.svg';
import Text from 'components/text';
import { Box } from 'layouts/box/Box';
import { PlatformVariant } from 'utils/mergePlatforms';

const instanceBadgeIcons = {
  facebook: FacebookIcon,
  cms: CMSIcon,
  instagram: InstagramIcon,
  linkedin: LinkedInIcon,
  tiktok: TiktokIcon,
  linear: LinearIcon,
  linear_audio: RadioIcon,
  tv: LinearIcon,
  twitter: XIcon,
  x: XIcon,
  youtube: YoutubeIcon,
  ott: OTTIcon,
  bluesky: BlueskyIcon,
} as const;
Object.freeze(instanceBadgeIcons);

export type InstanceBadgeIconTypes = keyof typeof instanceBadgeIcons;

const badgeTypesWithValidIcons = Object.keys(instanceBadgeIcons);

const IconBox = styled(Box)`
  background-color: ${({ theme }) => theme.palette.dina.buttonBackgroundHighEmphasis};
`;

interface CustomIconProps {
  readonly iconCharacter: string;
}
function CustomIcon({ iconCharacter }: CustomIconProps) {
  return (
    <IconBox height="16px" width="16px" container>
      <Text variant="overline" color="highEmphasis">
        {iconCharacter}
      </Text>
    </IconBox>
  );
}

const customIconCache = new Map<string, React.ComponentType>();

export const getIconByPlatform = (
  platform: string,
  platformVariants: Record<string, PlatformVariant>,
) => {
  if (badgeTypesWithValidIcons.includes(platform))
    return instanceBadgeIcons[platform as InstanceBadgeIconTypes];

  const platformIcon = platformVariants[platform]?.icon;
  if (platformIcon?.includes('custom')) {
    // check cache first
    if (customIconCache.has(platform)) return customIconCache.get(platform)!;

    const [, character] = platformIcon.split('_');
    const iconCharacter = (character ?? platform.charAt(0)).toUpperCase();

    // create new component if not in cache
    const CustomIconComponent = memo(() => <CustomIcon iconCharacter={iconCharacter} />);
    CustomIconComponent.displayName = `CustomIcon${platform}`;
    customIconCache.set(platform, CustomIconComponent);

    return CustomIconComponent;
  }

  return DefaultIcon;
};
