/* eslint-disable sort-imports */
import { useCallback, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { ClickAwayListener } from '@material-ui/core';
import { capitalize } from 'lodash';

import { ReactComponent as Edit } from 'assets/icons/systemicons/edit.svg';
import { IconButton } from 'components/buttons';
import Checkbox from 'components/checkbox';
import { StyledFormControl } from 'components/command/toolbar/styled';
import { RowCell } from 'components/editMdfDialog/components/FieldModelRow';
import { Input } from 'components/input/styled';
import { CloseIcon } from 'components/orderFormDialog/styled';
import Popper from 'components/shared/popper';
import Text from 'components/text';
import Tooltip from 'components/tooltip';
import LWCheckbox from 'features/orderForm/components/LWCheckbox';
import useCustomDateTimeUtils from 'hooks/useCustomDateTimeUtils';
import { Box, HStack, VStack } from 'layouts/box/Box';
import {
  AuthType,
  ConfigurableActionMTypes,
  IntegrationType,
  IntegrationUserType,
  MemberTypeEnum,
} from 'types/graphqlTypes';

import { EditAuthDialogue } from '../authDialogue/EditAuthDialogue';

import { configurableActionMTypes, TypeToLabel } from './constants';
import { authOptions, getAuthLabel } from './utils';

import { DeleteButton, ResetButton, StyledRowCell, StyledTableRow, TypesSelector } from './styled';

const typesWithRes = ['story', 'pitch'];

const addType = (currentTypes: ConfigurableActionMTypes[], type: ConfigurableActionMTypes) => {
  return typesWithRes.includes(type)
    ? [...currentTypes, type, `res_${type}` as ConfigurableActionMTypes]
    : [...currentTypes, type];
};

const removeType = (currentTypes: ConfigurableActionMTypes[], type: ConfigurableActionMTypes) => {
  return typesWithRes.includes(type)
    ? currentTypes.filter((f) => f !== type && f !== (`res_${type}` as ConfigurableActionMTypes))
    : currentTypes.filter((f) => f !== type);
};

const filterTypesWithRes = (currentTypes: ConfigurableActionMTypes[] | null) =>
  currentTypes?.filter(
    (type) => type !== MemberTypeEnum.ResStory && type !== MemberTypeEnum.ResPitch,
  );

const TruncatedText = styled(Text)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
`;

interface ActionRowProps {
  action: IntegrationType;
  isSelected: boolean;
  hasChanges: boolean;
  availableSearchPluginTypes: IntegrationUserType[];
  onSelect: (action: IntegrationType) => void;
  onDeleteAction: (id: string) => void;
  onUpdateAction: (updates: Partial<IntegrationType>) => void;
  onResetAction: (id: string) => void;
}

export function EditActionRow({
  action,
  hasChanges,
  onDeleteAction,
  onUpdateAction,
  onResetAction,
  isSelected,
  onSelect,
  availableSearchPluginTypes,
}: Readonly<ActionRowProps>) {
  const {
    mTitle,
    mDescription,
    auth,
    mTypes,
    connectedIds = [],
    iconUrl,
    mUpdatedAt,
    endpoint,
    mActive,
    externalId,
  } = action;
  const { isoToLocaleShort } = useCustomDateTimeUtils();
  const [title, setTitle] = useState(mTitle);
  const [localEndpoint, setLocalEndpoint] = useState(endpoint ?? '');
  const [localIconUrl, setLocalIconUrl] = useState(iconUrl ?? '');
  const [description, setDescription] = useState(mDescription ?? '');
  const [anchor, setAnchor] = useState<EventTarget | null>(null);
  const [showAuthDialog, setShowAuthDialog] = useState(false);

  const toggleMType = useCallback(
    (type: ConfigurableActionMTypes) => {
      const currentTypes = mTypes ?? [];
      const newTypes = currentTypes.includes(type)
        ? removeType(currentTypes, type)
        : addType(currentTypes, type);

      onUpdateAction({ mTypes: newTypes });
    },
    [mTypes],
  );

  const showAuth = useCallback(() => {
    setShowAuthDialog(true);
  }, [showAuthDialog]);

  const togglePlugin = useCallback(
    (config: IntegrationUserType) => {
      const currentPlugins = connectedIds ?? [];
      const newPlugins = currentPlugins.includes(config.mRefId)
        ? [...currentPlugins.filter((f) => f !== config.mRefId)]
        : [...currentPlugins, config.mRefId];
      onUpdateAction({ connectedIds: newPlugins });
    },
    [mTypes, connectedIds],
  );

  const selectedLabel = useMemo(() => {
    const types = filterTypesWithRes(mTypes)
      ?.map((type) => capitalize(TypeToLabel[type]))
      ?.join(', ');

    const pluginLabels = connectedIds
      ?.map((id) => {
        const plugin = availableSearchPluginTypes.find((p) => p.mRefId === id);
        if (plugin) {
          return capitalize(plugin.mTitle);
        }
      })
      ?.join(', ');

    if (types && pluginLabels) return `${types}, ${pluginLabels}`;
    else if (types) return types;
    else if (pluginLabels) return pluginLabels;
    return '';
  }, [mTypes, connectedIds]);

  return (
    <StyledTableRow $selected={isSelected}>
      <StyledRowCell
        style={hasChanges ? { color: 'orange' } : undefined}
        onClick={() => onSelect(action)}
      >
        <Tooltip title="Test action">
          <span>
            {externalId}
            {hasChanges ? '*' : ''}
          </span>
        </Tooltip>
      </StyledRowCell>
      <RowCell>
        <Input
          value={title}
          onChange={(ev) => setTitle(ev.target.value)}
          onBlur={() => onUpdateAction({ mTitle: title })}
          placeholder="Type menu label here.."
        />
      </RowCell>
      <RowCell>
        <Input
          value={description}
          onChange={(ev) => setDescription(ev.target.value)}
          onBlur={() =>
            onUpdateAction({ mDescription: description.length === 0 ? null : description })
          }
          placeholder="Optional description"
        />
      </RowCell>
      <RowCell>
        <Input
          value={localIconUrl}
          onChange={(ev) => setLocalIconUrl(ev.target.value)}
          onBlur={() =>
            onUpdateAction({ iconUrl: localIconUrl.length === 0 ? null : localIconUrl })
          }
          placeholder="https://url-to-your-icon.com"
        />
      </RowCell>
      <RowCell>
        <Tooltip title={selectedLabel}>
          <TypesSelector
            container
            padding="4px"
            height="32px"
            maxWidth="180px"
            borderRadius="4px"
            overflow="hidden"
            justifyContent="flex-start"
            onClick={(ev) => setAnchor(ev.currentTarget)}
          >
            <TruncatedText variant="caption">{selectedLabel}</TruncatedText>
          </TypesSelector>
        </Tooltip>
        <Popper anchorEl={anchor} position="bottom">
          <ClickAwayListener onClickAway={() => setAnchor(null)}>
            <Box padding="5px 15px 5px 5px">
              <VStack>
                <Box margin="0 0 4px 0">Types</Box>
                {(filterTypesWithRes(configurableActionMTypes) ?? []).map((type) => {
                  return (
                    <div key={type}>
                      <StyledFormControl
                        control={
                          <Checkbox
                            onClick={() => toggleMType(type)}
                            selected={(mTypes ?? []).includes(type)}
                          />
                        }
                        label={TypeToLabel[type]}
                      />
                    </div>
                  );
                })}
                <Box margin="4px 0 4px 0">Search plugins</Box>
                {availableSearchPluginTypes.map((plugin) => {
                  return (
                    <div key={plugin.mRefId}>
                      <StyledFormControl
                        control={
                          <Checkbox
                            onClick={() => togglePlugin(plugin)}
                            selected={(connectedIds ?? []).includes(plugin.mRefId)}
                          />
                        }
                        label={capitalize(plugin.mTitle)}
                      />
                    </div>
                  );
                })}
              </VStack>
              <CloseIcon
                style={{ position: 'absolute', top: '3px', right: '3px' }}
                onClick={() => setAnchor(null)}
              />
            </Box>
          </ClickAwayListener>
        </Popper>
      </RowCell>
      <RowCell>
        <Box container alignItems="center" justifyContent="start">
          <IconButton
            usage="text"
            size={24}
            iconSize={20}
            onClick={() => showAuth()}
            title="Configure Auth"
          >
            <Edit />
          </IconButton>
          <Text variant="caption"> {getAuthLabel(auth?.type)} </Text>
        </Box>
      </RowCell>
      <RowCell>
        <Input
          value={localEndpoint}
          onChange={(ev) => setLocalEndpoint(ev.target.value)}
          onBlur={() =>
            onUpdateAction({ endpoint: localEndpoint.length === 0 ? null : localEndpoint })
          }
          placeholder="REST endpoint"
        />
      </RowCell>
      <RowCell>
        <LWCheckbox selected={mActive} setValue={() => onUpdateAction({ mActive: !mActive })} />
      </RowCell>
      <RowCell>{isoToLocaleShort(mUpdatedAt)}</RowCell>
      <RowCell>
        <HStack>
          <Tooltip title="Discard changes">
            <ResetButton onMouseDown={() => onResetAction(action.mRefId)} />
          </Tooltip>
          <Tooltip title="Delete field">
            <DeleteButton onMouseDown={() => onDeleteAction(action.mRefId)} />
          </Tooltip>
        </HStack>
      </RowCell>
      {showAuthDialog && (
        <EditAuthDialogue
          open={showAuthDialog}
          setOpen={setShowAuthDialog}
          alternatives={authOptions}
          savedOptionList={auth?.type ?? AuthType.None}
          doUpdate={onUpdateAction}
        />
      )}
    </StyledTableRow>
  );
}
