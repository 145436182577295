import { useCallback, useContext } from 'react';

import configCtx from 'contexts/configContext';
import { MetadataFormFieldType, MetadataFormType } from 'types/graphqlTypes';

/**
 *  Hook to expose a function that returns the field for given field-id
 *
 * e.g: const [getFieldsForBlock]=useGetFieldsForBlock();
 *
 * const field=getFieldsForBlock('custom-tags');
 */

const useGetFieldsForBlock = () => {
  const { metadataForms } = useContext(configCtx);
  const form = metadataForms[0] as MetadataFormType;

  const getFieldsForBlock = useCallback(
    (
      id: string,
      defaultField: Partial<MetadataFormFieldType> = {
        id: '',
        name: '',
        type: '',
      },
    ) => {
      if (form?.fields) {
        const searchedField = form.fields.find((field) => field.id === id);
        if (searchedField) return searchedField;
      }

      return defaultField as MetadataFormFieldType;
    },
    [form?.fields],
  );

  return [getFieldsForBlock];
};

export default useGetFieldsForBlock;
