import { useCallback, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { uniqBy } from 'lodash';

import { GetInput, SearchResult } from 'api/search';
import memberTypes from 'operations/memberTypes';
import SEARCH_MEMBER from 'operations/queries/searchMembers';
import { AssignedMember } from 'types';
import { MemberType, MemberTypeEnum, Metadata } from 'types/graphqlTypes';

const insertMProperties = (assignees?: MemberType[]) => {
  return (assignees ?? []).map((assignee) => {
    if (assignee?.metadata) {
      const metadata = JSON.parse(assignee?.metadata ?? '{}') as Metadata;
      const mProperties = {
        __typename: 'ContactType',
        firstName: metadata?.firstName ?? '',
        surname: metadata?.surname ?? '',
        username: metadata?.username ?? '',
        notListed: metadata?.notListed ?? '',
        email: metadata?.email ?? '',
        phone: metadata?.phone ?? '',
        dateOfBirth: metadata?.dateOfBirth ?? '',
        jobTitle: assignee.mType === MemberTypeEnum.User ? 'User' : 'Contact',
        readSpeed: metadata?.readSpeed ?? '',
      };
      return {
        ...assignee,
        mProperties: { ...mProperties },
      };
    }
    return {
      ...assignee,
    };
  });
};

const useGetAssignees = (mAssignedMembers: AssignedMember[], skip: boolean = false) => {
  const assignedMIds = useMemo(
    () =>
      uniqBy(Array.isArray(mAssignedMembers) ? mAssignedMembers : [], (option) => option.mId).map(
        (member) => member.mId,
      ),
    [mAssignedMembers],
  );

  const input = {
    mIds: assignedMIds,
  };

  const searchResult = useQuery<SearchResult, GetInput>(SEARCH_MEMBER, {
    variables: {
      input,
    },
    fetchPolicy: 'cache-first',
    skip: skip || !assignedMIds.length,
  });
  const { data, loading, error } = searchResult;
  const items = useMemo(
    () => insertMProperties(data?.searchItem?.items ?? []),
    [data?.searchItem?.items],
  );

  const getAssignees = useCallback(() => {
    const assignedUsers = uniqBy(items, 'mId').filter(
      (m) => m.mType === (memberTypes.USER as MemberTypeEnum),
    );
    const assignedTeams = uniqBy(items, 'mId').filter(
      (m) => m.mType === (memberTypes.TEAM as MemberTypeEnum),
    );
    const assignedDepartments = uniqBy(items, 'mId').filter(
      (m) => m.mType === (memberTypes.DEPARTMENT as MemberTypeEnum),
    );
    const assignedMarkets = uniqBy(items, 'mId').filter(
      (m) => m.mType === (memberTypes.MARKET as MemberTypeEnum),
    );
    const assignedContacts = uniqBy(items, 'mId').filter(
      (m) => m.mType === (memberTypes.CONTACT as MemberTypeEnum),
    );
    return {
      all: items as AssignedMember[],
      assignedUsers,
      assignedTeams,
      assignedDepartments,
      assignedMarkets,
      assignedContacts,
    };
  }, [items]);

  return {
    getAssignees,
    loading,
    error,
  };
};

export default useGetAssignees;
