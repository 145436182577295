import { useCallback, useMemo, useState } from 'react';
import { Tooltip } from '@material-ui/core';
import { keyBy } from 'lodash';

import Dialog from 'components/dialogs/DialogBuilder';
import Text from 'components/text/Text';
import useToast from 'components/toast/useToast';
import LWSelect from 'features/orderForm/components/LWSelect';
import { StyledTextField } from 'features/reusableStyled';
import { VStack } from 'layouts/box/Box';
import { Alternative, PlatformDTOSettings, ProviderDTO } from 'types/graphqlTypes';

import { useCreatePlatform } from '../../../hooks/useCreatePlatform';
import { OptionWrapper } from '../styled';

import { serviceOptions } from './constant';

interface Props {
  open: boolean;
  setOpen: (val: boolean) => void;
  providers: ProviderDTO[] | undefined;
  platforms: PlatformDTOSettings[] | undefined;
  setSelectedOption: (val: string | null) => void;
}

export function CreatePlatformDialogue({
  open,
  setOpen,
  providers,
  platforms,
  setSelectedOption,
}: Readonly<Props>) {
  const { createPlatform } = useCreatePlatform();
  const [formValues, setFormValues] = useState({
    platform: '',
    service: '',
    provider: '',
    title: '',
  });
  const { errorToast } = useToast();

  const setChangedValue = (key: keyof typeof formValues, value: string) => {
    setFormValues((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const platformById = useMemo(() => {
    return keyBy(platforms, (p) => p.id);
  }, [platforms]);

  const ProviderOptions = [
    {
      id: 'None',
      label: 'None',
      value: 'None',
    },
    ...(providers
      ? providers.map((prov) => ({
          id: prov.id,
          label: prov.id,
          value: prov.id,
        }))
      : []),
  ];

  const createPlatformConfirm = useCallback(async () => {
    setOpen(false);
    setSelectedOption(formValues.platform);
    await createPlatform(formValues).catch(errorToast);
  }, [createPlatform, errorToast, formValues, setOpen, setSelectedOption]);

  const tooltipMessage = useMemo(() => {
    if (providers?.length === 0) {
      return 'No provider is available. Please create one first!';
    }
    if (!formValues.provider) {
      return 'Provider is required.';
    }
    return '';
  }, [formValues.provider, providers]);

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      style={{ minWidth: '600px', maxWidth: '800px' }}
    >
      <Dialog.Header>Create Platform</Dialog.Header>
      <Dialog.Body>
        <OptionWrapper>
          <VStack>
            <Text variant="caption"> Provider : </Text>
          </VStack>
          <VStack minWidth="100px">
            <Tooltip title={tooltipMessage}>
              <div style={{ all: 'inherit' }}>
                <LWSelect
                  value={formValues.provider}
                  options={ProviderOptions ? (ProviderOptions as Alternative[]) : []}
                  setValue={(val) => setChangedValue('provider', val)}
                />
              </div>
            </Tooltip>
          </VStack>
        </OptionWrapper>
        <OptionWrapper>
          <VStack>
            <Text variant="caption"> Service : </Text>
          </VStack>
          <VStack minWidth="100px" flex="1">
            <Tooltip title={'This field is required.'}>
              <div style={{ all: 'inherit' }}>
                <LWSelect
                  value={formValues.service}
                  options={serviceOptions}
                  setValue={(val) => setChangedValue('service', val)}
                />
              </div>
            </Tooltip>
          </VStack>
        </OptionWrapper>

        <OptionWrapper>
          <VStack>
            <Text variant="caption"> Platform : </Text>
          </VStack>
          <VStack flex="1">
            <Tooltip
              title={
                platformById[formValues.platform]
                  ? 'Platform already exists! '
                  : 'This field is required.'
              }
            >
              <StyledTextField
                name="platform"
                variant="filled"
                value={formValues.platform}
                onChange={(ev) => setChangedValue('platform', ev.target.value)}
                required
                error={!formValues.platform || !!platformById[formValues.platform]}
              />
            </Tooltip>
          </VStack>
        </OptionWrapper>
        <OptionWrapper>
          <VStack>
            <Text variant="caption"> Title : </Text>
          </VStack>
          <VStack flex="1">
            <StyledTextField
              name="title"
              variant="filled"
              value={formValues.title}
              onChange={(ev) => setChangedValue('title', ev.target.value)}
            />
          </VStack>
        </OptionWrapper>
      </Dialog.Body>
      <Dialog.Footer>
        <Dialog.CancelButton />
        <Dialog.ConfirmButton
          label="Confirm"
          onConfirm={createPlatformConfirm}
          disabled={
            !formValues.platform ||
            !!platformById[formValues.platform] ||
            !formValues.service ||
            !formValues.provider
          }
        />
      </Dialog.Footer>
    </Dialog>
  );
}
