import getDefaultTemplate from 'components/editor/utils/getDefaultTemplate';
import { ReactEditor } from 'slate-react';
import insertPrimaryAutomationElement from 'components/editor/components/primaryAutomation/utils/insertPrimaryAutomationElement';

const onPrimaryDrop = ({ type }, editor, update, templates) => {
  const { variant: templateVariant, name, description, mosobj } = getDefaultTemplate(type, templates);
  const data = { templateVariant, name, description };
  if ( mosobj ) data.mosobj = mosobj;

  insertPrimaryAutomationElement({
    editor,
    type,
    update,
    data,
    isNewline: false,
  });

  ReactEditor.focus(editor);
};

export default onPrimaryDrop;
