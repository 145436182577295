import { useCallback } from 'react';
import { gql, useMutation } from '@apollo/client';
import graphql from 'graphql-tag';

import {
  type MemberType,
  MemberTypeEnum,
  type Metadata,
  type UpdateMetadataInput,
} from 'types/graphqlTypes';

const UPDATE_METADATA = graphql`
  mutation UpdateMetadata($input: UpdateMetadataInput) {
    updateMetadata(input: $input) {
      mId
      mRefId
      mTitle
      metadata
    }
  }
`;

interface UpdateMetadata {
  updateMetadata: MemberType;
}

interface Input {
  input: UpdateMetadataInput;
}

const metadataFragment = gql`
  fragment MemberMeta on SearchItem {
    metadata
    mUpdatedAt
    mId
    mRefId
  }
`;

const useUpdateMetadata = () => {
  const [updateMetadata] = useMutation<UpdateMetadata, Input>(UPDATE_METADATA);

  const update = useCallback(
    async (
      mId: string,
      mRefId: string | undefined,
      updatedPartialMetadata: Metadata,
      fullMetadata: Metadata,
      mType: MemberTypeEnum,
      mdfId?: string,
      mTitle?: string,
      isSearchItem?: boolean,
      skipOptimisticUpdate?: boolean,
    ) => {
      const input: UpdateMetadataInput = {
        mId,
        mRefId,
        metadata: JSON.stringify(updatedPartialMetadata),
        mType,
        mdfId,
      };

      return updateMetadata({
        variables: {
          input,
        },
        ...(!skipOptimisticUpdate && {
          optimisticResponse: {
            updateMetadata: {
              mId,
              mRefId,
              metadata: JSON.stringify({ ...fullMetadata, ...updatedPartialMetadata }),
              mType,
              ...(mdfId && { mdfId }),
              ...(mTitle && { mTitle }),
            },
          },
        }),
        update(cache, { data }) {
          if (!isSearchItem || !data) return;

          /**
           *
           * We might have a conflict with the SearchItem vs MemberType type names.
           * This might cause problems when trying updating the cache
           *
           * The isSearchItem check is a temporary workaround to avoid updating
           * the cache for non-SearchItem types.
           *
           */

          const fragment = cache.readFragment({
            id: `searchItem:${mId}`,
            fragment: metadataFragment,
          });

          if (!fragment) return;

          cache.writeFragment({
            id: `searchItem:${mId}`,
            fragment: metadataFragment,
            data: {
              mUpdatedAt: new Date().toISOString(),
              mId,
              mRefId,
              metadata: JSON.stringify({ ...fullMetadata, ...updatedPartialMetadata }),
            },
          });
        },
      });
    },
    [],
  );

  return update;
};

export default useUpdateMetadata;
