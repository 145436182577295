/* eslint-disable import/no-extraneous-dependencies */
import { useCallback, useEffect, useRef, useState } from 'react';
import { OnDataOptions, useQuery } from '@apollo/client';

import useApolloSubscription from 'hooks/useApolloSubscriptionV2';
import useBatchGetAssets, { AssetToFetch, ExtendedAsset } from 'hooks/useBatchGetAssets';
import GET_RUNDOWN_INSTANCES from 'operations/queries/getInstancesOfRundownOrder';
import NOTIFY_ASSET_UPDATE_SUBSCRIPTION from 'operations/subscriptions/notifyAssetUpdate';
import { Asset, Instance } from 'types';
import AssetTypes from 'utils/constants/assetTypes';

export const supportedAssetTypes = [AssetTypes.VIDEO, AssetTypes.IMAGE] as string[];

type GetRundownInstances = {
  rundownInstances?: Instance[];
};

type AssetSubscriptionType = {
  notifyAssetSubscription: Asset;
};

const useGetRundownAssets = (mId: string, assetsType = supportedAssetTypes) => {
  const [loading, setLoading] = useState(false);
  const [assets, setAssets] = useState<ExtendedAsset[]>([]);
  const ignoreCacheRef = useRef(true);
  const [batchGetAssets] = useBatchGetAssets();

  const { data } = useQuery<GetRundownInstances>(GET_RUNDOWN_INSTANCES, {
    variables: {
      input: {
        mId,
        mRefId: 'mOrder',
      },
    },
    fetchPolicy: 'network-only',
  });

  const getAssets = useCallback(async () => {
    if (!data?.rundownInstances) return;

    // Normalize asset information for each cached instances
    const assetsList = data.rundownInstances.reduce(
      (aList: AssetToFetch[], { mId: instanceId, assets: instanceAssets }) => {
        if (!Array.isArray(instanceAssets)) return aList;

        const updatedAssets = instanceAssets.reduce((uList: AssetToFetch[], uAsset) => {
          if (
            uAsset?.itemType &&
            assetsType.includes(uAsset?.itemType) &&
            uAsset?.mId &&
            uAsset?.assetRefId
          ) {
            return [
              ...uList,
              { mId: uAsset.mId, mRefId: uAsset.assetRefId, instanceId, state: uAsset.state },
            ] as AssetToFetch[];
          }
          return uList;
        }, []);

        return [...aList, ...updatedAssets];
      },
      [],
    );

    if (
      ignoreCacheRef.current ||
      assets?.length !== assetsList?.length ||
      !assets.every((assetItem, index) => {
        const assetListItem = assetsList[index];
        return (
          assetItem?.mId === assetListItem?.mId &&
          assetItem?.mRefId === assetListItem?.mRefId &&
          assetItem?.instanceId === assetListItem?.instanceId &&
          assetItem?.state === assetListItem?.state
        );
      })
    ) {
      setLoading(true);
      const queriedAssets = await batchGetAssets(assetsList, ignoreCacheRef.current);
      ignoreCacheRef.current = false;
      setAssets(queriedAssets as ExtendedAsset[]);
      setLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assets, assetsType, data]);

  useEffect(() => {
    getAssets().then(
      () => {},
      () => {},
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, assetsType]);

  useApolloSubscription(NOTIFY_ASSET_UPDATE_SUBSCRIPTION, {
    variables: {
      mIdSubscribed: mId,
    },
    source: 'useGetRundownAssets',
    onSubscriptionData: ({ data: subscriptionData }: OnDataOptions<AssetSubscriptionType>) => {
      const updatedAsset = subscriptionData.data?.notifyAssetSubscription;
      if (!updatedAsset) return;

      if (!(updatedAsset?.itemType && assetsType.includes(updatedAsset?.itemType))) return;
      const updatedAssets = assets.map((asset) => {
        if (asset.mRefId !== updatedAsset.mRefId) return asset;
        return {
          ...asset,
          ...updatedAsset,
        };
      });
      setAssets(updatedAssets);
    },
  });

  return { assets, loading };
};

export default useGetRundownAssets;
