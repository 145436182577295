import { useMemo } from 'react';
import capitalize from 'lodash/capitalize';

import { DateRange, useDatePicker } from 'components/mdfEditor/fields/date/DatePicker';
import Text from 'components/text/Text';
import Tooltip from 'components/tooltip';
import useCustomDateTimeUtils from 'hooks/useCustomDateTimeUtils';
import { Flex } from 'layouts/box/Box';

import { CloseIcon, DatePickerButton, Icon, IconOff } from './styled';

interface CreateStoryDatePickerProps {
  dateRange: DateRange | null;
  showTime?: boolean;
  onDateRangeChange: (dateRange: DateRange | null) => void;
  disableUpdate: boolean;
  canSelectRange: boolean;
  height?: number;
  fullWidth?: boolean;
  transparent?: boolean;
  transparentBackground?: boolean;
  hideCloseIcon?: boolean;
  hideBorder?: boolean;
}

const CreateStoryDatePickerButton = ({
  dateRange,
  onDateRangeChange,
  disableUpdate,
  canSelectRange,
  height,
  fullWidth,
  showTime,
  transparent,
  transparentBackground,
  hideCloseIcon,
  hideBorder,
}: CreateStoryDatePickerProps) => {
  const { getRelativeDate } = useCustomDateTimeUtils();
  const [, openPicker] = useDatePicker();

  const { startDate: sDate = undefined, endDate: eDate = undefined } = dateRange ?? {};
  const showDatePicker = (ev: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    if (!disableUpdate) {
      openPicker({
        anchorEl: ev.currentTarget,
        startValue: dateRange ?? undefined,
        hideUnscheduleButton: false,
        selectRange: canSelectRange,
        onUnschedule: () => {
          onDateRangeChange(null);
        },
        selectDate: (val: DateRange) => {
          onDateRangeChange(val);
        },
      });
    }
  };

  const unschedule = (ev: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    ev.stopPropagation();
    if (!disableUpdate) {
      onDateRangeChange(null);
    }
  };

  const format = showTime ? 'dd MMM. yyyy h:mm a' : 'dd MMM. yyyy';
  const labelStart = sDate
    ? `${capitalize(getRelativeDate(sDate, format, showTime))}`
    : 'Unscheduled';

  const labelEnd = eDate ? `to ${capitalize(getRelativeDate(eDate, format, showTime))}` : '';

  const textColor = useMemo(() => {
    if (transparent) return 'whiteHighEmphasis';
    return sDate ? 'highEmphasis' : 'mediumEmphasis';
  }, [sDate, transparent]);

  return (
    <DatePickerButton
      role="presentation"
      onClick={showDatePicker}
      $disabled={disableUpdate}
      $height={height}
      $fullWidth={fullWidth}
      $transparent={transparent}
      $transparentBackground={transparentBackground}
      $hideBorder={hideBorder}
    >
      <Tooltip
        title={
          disableUpdate
            ? 'You do not have permission to change schedule date of a story'
            : `${sDate ? 'Change' : 'Set'} schedule date`
        }
      >
        <Flex gap="3px">
          {dateRange?.startDate ? (
            <Icon $dimmed={!sDate} className={transparent ? 'skipOverride' : undefined} />
          ) : (
            <IconOff $bright={transparent} className={transparent ? 'skipOverride' : undefined} />
          )}
          <Text variant="listItemLabel" color={textColor}>
            {labelStart}
          </Text>
          <Text variant="listItemLabel" color={textColor}>
            {labelEnd}
          </Text>
        </Flex>
      </Tooltip>
      {!hideCloseIcon && (
        <Tooltip
          title={
            disableUpdate
              ? 'You do not have permission to change schedule date of a story'
              : 'Unschedule'
          }
        >
          <CloseIcon onClick={unschedule} />
        </Tooltip>
      )}
    </DatePickerButton>
  );
};

export default CreateStoryDatePickerButton;
