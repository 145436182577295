import { useMemo } from 'react';

import { isDateRange } from 'api/search';
import { getRollingLabel } from 'components/calendar/rollingDate';
import Text, { TextProps } from 'components/text/Text';
import Tooltip from 'components/tooltip/Tooltip';
import useCustomDateTimeUtils from 'hooks/useCustomDateTimeUtils';
import useDateTimeUtils from 'hooks/useDateTimeUtils';
import getLocaleTimeFormat from 'utils/dateTime/getLocaleTimeFormat';

import { DateRange } from './DatePicker';

import { DateLabel } from './styled';

const NoDateSelectedLabel = 'Select a date';

interface DateDefaultProps extends TextProps {
  date?: string | DateRange;
  onClick: (ev: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
  hint?: string;
}

function DateDefault({ date, onClick, variant, color, hint }: Readonly<DateDefaultProps>) {
  const { isoToLocaleShort } = useCustomDateTimeUtils();
  const { format } = useDateTimeUtils();

  const label = useMemo(() => {
    if (typeof date === 'string' && date.length > 0) {
      return format(date, `EEEE, MMMM d, yyyy ${getLocaleTimeFormat()}`);
    } else if (date && isDateRange(date)) {
      return date.rollingDate
        ? getRollingLabel(date.rollingDate)
        : `${isoToLocaleShort(date.startDate)} - ${isoToLocaleShort(
            date.endDate ?? date.startDate,
          )}`;
    }
    return NoDateSelectedLabel;
  }, [date]);

  const dateSelected = label !== NoDateSelectedLabel;

  return (
    <Tooltip title={hint ?? 'Edit default date'}>
      <DateLabel onClick={onClick}>
        <Text
          capitalize={dateSelected}
          variant={variant ?? 'listItemLabel'}
          color={color ?? (date ? 'highEmphasis' : 'lowEmphasis')}
        >
          {label}
        </Text>
      </DateLabel>
    </Tooltip>
  );
}

export default DateDefault;
