import styled from '@emotion/styled';

import Text from 'components/text/Text';
import { useMousePosition } from 'hooks/useMousePosition';
import { VStack } from 'layouts/box/Box';
import { ResizableHandle } from 'lib/resize';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from 'lib/Tooltip';

const Container = styled('div')`
  ${({ theme }) => theme.typography.dina.caption};
  z-index: 3500;
  user-select: none;
  max-width: 300px;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
  color: ${({ theme }) => theme.palette.dina.whiteHighEmphasis};
  background-color: ${({ theme }) => theme.palette.dina.tooltip};
  box-shadow: 0px 0px 4px ${({ theme }) => theme.palette.dina.boxShadowDark};
`;

export const VerticalResizeHandle = styled(ResizableHandle)<{ lightBackground?: boolean }>`
  height: 100%;
  width: 4px;
  position: relative;
  background: ${({ theme, lightBackground }) =>
    lightBackground ? theme.palette.dina.hoverOverlay : 'transparent'};
  &[data-resize-handle-state='hover']:after,
  &[data-resize-handle-state='drag']:after {
    opacity: 1;
    background-color: ${({ theme }) => theme.palette.dina.onFocus};
  }
  &:after {
    content: '';
    position: absolute;
    height: 100%;
    width: 4px;
    left: 1px;
    opacity: 0;
    transition: opacity 120ms ease-in;
    background-color: ${({ theme }) => theme.palette.dina.onFocus};
  }
`;

export const HorizontalResizeHandle = styled(ResizableHandle)<{ lightBackground?: boolean }>`
  width: 100%;
  height: 4px;
  position: relative;
  background: ${({ theme, lightBackground }) =>
    lightBackground ? theme.palette.dina.surfaceAppBackgroundMain : 'transparent'};
  &[data-resize-handle-state='hover']:after,
  &[data-resize-handle-state='drag']:after {
    opacity: 1;
  }

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 4px;
    top: 1px;
    opacity: 0;
    transition: opacity 120ms ease-in;
    background-color: ${({ theme }) => theme.palette.dina.onFocus};
  }
`;

export const ResizeHandleWithTooltip = ({
  direction,
  title,
  onDoubleClick,
}: {
  direction: 'vertical' | 'horizontal';
  title: string;
  onDoubleClick: () => void;
}) => {
  const { ref, x, y } = useMousePosition();

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <div ref={ref as React.RefObject<HTMLDivElement>}>
            {direction === 'vertical' && <VerticalResizeHandle onDoubleClick={onDoubleClick} />}
            {direction === 'horizontal' && <HorizontalResizeHandle onDoubleClick={onDoubleClick} />}
          </div>
        </TooltipTrigger>
        <TooltipContent align="start" alignOffset={x + 10} sideOffset={-y + 5} hideWhenDetached>
          <Container>
            <VStack alignItems="flex-start" gap="4px">
              <div>
                <Text variant="captionMedium" color="whiteHighEmphasis">
                  Double Click
                </Text>
                <Text variant="caption" color="whiteInactive">
                  {' '}
                  Close
                </Text>
                <Text variant="caption" color="whiteInactive">
                  {' '}
                  {title}
                </Text>
              </div>
              <div>
                <Text variant="captionMedium" color="whiteHighEmphasis">
                  Drag
                </Text>
                <Text variant="caption" color="whiteInactive">
                  {' '}
                  Resize
                </Text>
              </div>
            </VStack>
          </Container>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};
