import gql from 'graphql-tag';

export default gql`
  mutation UpdateRundownSync($input: UpdateRundownInput) {
    updateRundownSync(input: $input) {
      mTitle
      mOrder
      mPreorder
      mId
      mRefId
      mSecRefId
      mState
      mStudios
      mPreparingState
      mPlannedDuration
      mPrePlannedDuration
      mAssignedMembers {
        mId
        mType
      }
      mPublishingAt
      version
      recurrence {
        duration
        startTime
        timeZone
        dailyExclusive
      }
      mProperties {
        __typename
        ... on RundownStateType {
          ready {
            state
            startTime
            stopTime
            currentInstance {
              mId
              startTime
            }
          }
          preparing {
            state
            startTime
            stopTime
            currentInstance {
              mId
              startTime
            }
          }
        }
      }
      mMetaData {
        key
        value
      }
      metadata
      mdfId
      useV2
      groupInfo
      permissions {
        read
        write
      }
    }
  }
`;
