import { useEffect, useRef } from 'react';
import { Slide } from '@material-ui/core';
import Popper from '@material-ui/core/Popper';
import { ScopeProvider } from 'jotai-molecules';

import { ReactComponent as Add } from 'assets/icons/systemicons/add_small.svg';
import { ReactComponent as Close } from 'assets/icons/systemicons/close.svg';
import { ReactComponent as ChatResting } from 'assets/icons/systemicons/HeaderNavbar/chat_resting.svg';
import { ReactComponent as ChatSelected } from 'assets/icons/systemicons/HeaderNavbar/chat_selected.svg';
import { Button, IconButton } from 'components/buttons';
import Divider from 'components/divider';
import ResizableDialog from 'components/resizableDialog';
import NotificationIndicator from 'components/statusIndicators/Notification';
import Text from 'components/text/Text';
import Tooltip from 'components/tooltip';
import useCheckUserRight from 'hooks/useCheckUserRight';
import useUpdateConvoReadAt from 'hooks/useUpdateConvoReadAt';
import { Box } from 'layouts/box/Box';
import { ResizablePanel, ResizablePanelGroup } from 'lib/resize';
import { useIsMessageHubOpen, useSelectedConversationId } from 'store';
import { useLocalNotifications } from 'store/notifications';
import { Conversation } from 'types/messageHub';

import Alert from '../alert';

import MessageContents from './components/messageContent/MessageContent';
import MessageList from './components/messageList/MessageList';
import NewMessage from './components/newMessage/NewMessage';
import { ConversationScope, useConversationMolecule } from './store/conversation';

import {
  HeaderWrapper,
  MessagesWrapper,
  NewMessageContainer,
  NotificationIndicatorWrapper,
  StyledBody,
  StyledChatEnabled,
  StyledNotificationIcon,
  StyledResizableHandle,
} from './styled';

interface MessageHubProps {
  messages: Conversation[];
}

function MessageHubView({ messages }: Readonly<MessageHubProps>) {
  const messageHubRef = useRef(null);
  const containerRef = useRef(null);
  const [checkUserRight] = useCheckUserRight();
  const canUseChat = checkUserRight('chat', 'access');

  const [isMessageHubOpen] = useIsMessageHubOpen();
  const [selectedConversationId] = useSelectedConversationId();

  const [updateConvoReadAt] = useUpdateConvoReadAt();
  const [localNotifications] = useLocalNotifications();

  const {
    useNotifications,
    useNotifiedCount,
    useToggleMessageHub,
    useCurrentConversation,
    useIsCreateNewChat,
    useToggleIsCreateNewChat,
  } = useConversationMolecule();
  const [notifications, setNotifications] = useNotifications();
  const [notifiedCount, setNotifiedCount] = useNotifiedCount();
  const toggleMessageHub = useToggleMessageHub();
  const currentConversation = useCurrentConversation();
  const isCreateNewChat = useIsCreateNewChat();
  const toggleIsCreateNewChat = useToggleIsCreateNewChat();

  useEffect(() => {
    if (messages.length > notifiedCount) {
      const numOfMessagesToBeNotified = messages.length - notifiedCount;
      const latestMessages = messages.slice(-numOfMessagesToBeNotified);
      setNotifiedCount(latestMessages.length + notifiedCount);
      setNotifications([...latestMessages, ...notifications]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages]);

  useEffect(() => {
    window.onbeforeunload = async (e) => {
      e.preventDefault();
      if (selectedConversationId) void updateConvoReadAt(selectedConversationId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedConversationId]);

  return (
    canUseChat && (
      <>
        <Tooltip title="Message Hub" enterDelay={700}>
          <StyledNotificationIcon onClick={toggleMessageHub}>
            <StyledChatEnabled ref={messageHubRef}>
              {isMessageHubOpen ? <ChatSelected /> : <ChatResting />}
            </StyledChatEnabled>
            {notifications.length > 0 && (
              <NotificationIndicatorWrapper>
                <NotificationIndicator notificationCount={notifications.length} />
              </NotificationIndicatorWrapper>
            )}
          </StyledNotificationIcon>
        </Tooltip>
        <ResizableDialog
          asPopper
          open={isMessageHubOpen}
          anchorEl={messageHubRef.current}
          initialWidth={690}
          initialHeight={690}
          minWidth={600}
          minHeight={400}
          initialPosition={{ x: -640, y: 12 }}
        >
          <MessagesWrapper ref={containerRef}>
            <HeaderWrapper className="dragHandler">
              <Box flex="1">
                <Text variant="h6" color="highEmphasis">
                  Chat
                </Text>
              </Box>
              <Button
                height={28}
                width={130}
                usage="cta"
                variant="contained"
                onClick={toggleIsCreateNewChat}
              >
                <Add />
                New Message
              </Button>
              <IconButton usage="text" onClick={toggleMessageHub} size={24}>
                <Close />
              </IconButton>
            </HeaderWrapper>
            <Divider />
            <StyledBody>
              <ResizablePanelGroup direction="horizontal" autoSaveId="messageHub">
                <ResizablePanel defaultSize={30} minSize={28}>
                  <MessageList />
                </ResizablePanel>
                <StyledResizableHandle />
                <ResizablePanel defaultSize={70} minSize={45}>
                  {currentConversation?.mId ? <MessageContents /> : <div />}
                </ResizablePanel>
              </ResizablePanelGroup>
            </StyledBody>
            <Slide in={isCreateNewChat} direction="left">
              <NewMessageContainer>
                <NewMessage />
              </NewMessageContainer>
            </Slide>
          </MessagesWrapper>
        </ResizableDialog>
        {!isMessageHubOpen && messageHubRef?.current && (
          <Popper anchorEl={messageHubRef?.current} open disablePortal placement="bottom-end">
            <Alert
              notifications={[...notifications, ...localNotifications]}
              onOpenMessageHub={toggleMessageHub}
              removeNotificationMessageCount={() => null}
            />
          </Popper>
        )}
      </>
    )
  );
}

function MessageHub({ messages }: Readonly<MessageHubProps>) {
  return (
    <ScopeProvider scope={ConversationScope} value="message">
      <MessageHubView messages={messages} />
    </ScopeProvider>
  );
}

export default MessageHub;
