import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { isEqual } from 'lodash';

import { useGetMdfs } from 'api/mdf/useGetMdfs';
import useGetMember from 'api/useGetMember';
import { ReactComponent as Schema } from 'assets/icons/systemicons/schema.svg';
import useOpenMember from 'components/contextMenu/useOpenMember';
import { PopoverColorConfig } from 'components/editMdfDialog/components/PopoverColorConfig';
import { RelationItem } from 'components/mdfEditor/fields/relation/RelationItem';
import { StyledTextField } from 'components/mdfEditor/fields/text/styled';
import Text from 'components/text';
import { HStack, VStack } from 'layouts/box/Box';
import {
  useActiveTab,
  useSelectMdfSchema,
  useSettingsOpen,
} from 'screens/main/components/header/navbar/settings/atomsTs';
import { generateColors } from 'types/forms/forms';
import { Alternative, ConfigType } from 'types/graphqlTypes';
import { OrderFormMemberType } from 'types/memberTypes/order_form';

import { EditStatusAlternatives } from './EditStatusAlternatives';
import EditVisibleInProperties from './EditVisibleInProperties';
import OpenChild from './OpenChild';

import { Root } from './styled';

interface Props {
  orderForm: OrderFormMemberType;
  onFormChange: (updatedForm: OrderFormMemberType) => void;
}

const getCopyForColor = (index: number, configs: ConfigType[], alts: Alternative[]) => {
  if (index >= 0) {
    return { ...configs[index], alternatives: alts };
  }
  return { ...generateColors(), alternatives: alts };
};

function EditOrderFormProperties({ orderForm, onFormChange }: Readonly<Props>) {
  const { openItem } = useOpenMember();
  const [, setOpenSettings] = useSettingsOpen();
  const [, setActiveTab] = useActiveTab();
  const [, setSelectedMdfId] = useSelectMdfSchema();
  const [section, setSection] = useState(orderForm.mTitle);
  const [color, setColor] = useState(orderForm.mColor ?? null);
  const [taskLabel, setTaskLabel] = useState(orderForm.mDescription);

  const { data: space, loading: loadingSpace } = useGetMember({
    mId: orderForm?.mId ?? '',
    mRefId: orderForm?.mId ?? '',
    skip: !orderForm?.mId,
  });

  const { mdfsSeparated } = useGetMdfs();

  const mdf = useMemo(() => {
    return mdfsSeparated.custom.find((m) => m.id === orderForm.mSecId) ?? null;
  }, [orderForm, mdfsSeparated]);

  const handleUpdate = useCallback(
    (upd: Partial<OrderFormMemberType>) => {
      onFormChange({
        ...orderForm,
        ...upd,
      });
    },
    [onFormChange, orderForm],
  );

  const handleSectionChange = (val: string) => {
    if (val === orderForm.mTitle) return;
    handleUpdate({ mTitle: val });
  };

  const handleTaskLabelChange = (val: string) => {
    if (val === orderForm.mDescription) return;
    if (val.length > 0) {
      handleUpdate({ mDescription: val });
    } else {
      setTaskLabel(orderForm.mDescription);
    }
  };

  const handleColorChange = (val: string | null) => {
    if (val === orderForm.mColor) return;
    handleUpdate({ mColor: val });
    setColor(val);
  };

  useEffect(() => {
    setSection(orderForm.mTitle);
    setTaskLabel(orderForm.mDescription);
    setColor(orderForm.mColor ?? null);
  }, [setSection, setTaskLabel, setColor, orderForm]);

  const updateStatuses = useCallback(
    (alts: Alternative[]) => {
      const idx = orderForm.configs.findIndex((c) => c.key === 'statuses');
      if (idx >= 0) {
        const copy: ConfigType = { ...orderForm.configs[idx], alternatives: alts };
        const newConfigs = [...orderForm.configs.filter((c) => c.key !== 'statuses'), copy];
        if (!isEqual(orderForm.configs, newConfigs)) {
          handleUpdate({ configs: newConfigs });
        }
      }
    },
    [handleUpdate, orderForm],
  );

  const onColorChange = useCallback(
    (val: Record<string, string>) => {
      const idx = orderForm.configs.findIndex((c) => c.key === 'status_colors');
      const asAlternatives: Alternative[] = Object.entries(val).map(([value, statusColor]) => {
        return {
          id: value,
          label: value,
          value: statusColor,
        };
      });

      const copy = getCopyForColor(idx, orderForm.configs, asAlternatives);
      const newConfigs = [...orderForm.configs.filter((c) => c.key !== 'status_colors'), copy];
      if (!isEqual(orderForm.configs, newConfigs)) {
        handleUpdate({ configs: newConfigs });
      }
    },
    [handleUpdate, orderForm],
  );

  const goToSelectedSchema = useCallback(() => {
    if (orderForm.mSecId) {
      setActiveTab('Schemas');
      setSelectedMdfId(orderForm.mSecId);
    }
  }, [setActiveTab, setSelectedMdfId, orderForm]);

  return (
    <Root gap="12px">
      <VStack gap="8px">
        <HStack gap="40px">
          <VStack>
            <Text variant="overline">Configured schema</Text>
            <OpenChild onClick={goToSelectedSchema} tooltip="Go to schema">
              <>
                <Schema />
                <Text variant="avatarHeaderTitle">{mdf?.label ?? 'Unknown schema'}</Text>
              </>
            </OpenChild>
          </VStack>
          <VStack style={{ overflow: 'visible', width: '240px' }} flexShrink={0}>
            <Text variant="overline">Configured space</Text>
            <OpenChild
              onClick={() => {
                if (space) {
                  openItem(space);
                  setOpenSettings(false);
                }
              }}
              tooltip="Go to space"
            >
              {loadingSpace && <div>Loading..</div>}
              {space && !loadingSpace && (
                <RelationItem tooltip={null} member={space} onOpenClick={() => {}} />
              )}
              {!loadingSpace && !space && <div>Unknown..</div>}
            </OpenChild>
          </VStack>
        </HStack>

        <Text variant="overline">Task label</Text>
        <StyledTextField
          value={taskLabel}
          variant="filled"
          onChange={(ev) => setTaskLabel(ev.target.value)}
          onBlur={() => handleTaskLabelChange(taskLabel)}
        />
        <Text variant="overline">Section</Text>
        <StyledTextField
          value={section}
          variant="filled"
          onChange={(ev) => setSection(ev.target.value)}
          onBlur={() => handleSectionChange(section)}
        />
      </VStack>
      <VStack justifyContent="start" gap="10px">
        <Text variant="overline">Look &amp; feel</Text>
        <PopoverColorConfig
          onColorChoice={(c: string) => handleColorChange(c)}
          onClearColor={() => handleColorChange(null)}
          selectedColor={color ?? null}
        />
      </VStack>
      <EditStatusAlternatives
        form={orderForm}
        onColorsChange={onColorChange}
        alternatives={orderForm.configs.find((c) => c.key === 'statuses')?.alternatives ?? []}
        onChange={(updatedAlts) => updateStatuses(updatedAlts)}
      />
      <Text variant="overline">Choose where this form should be available</Text>
      <EditVisibleInProperties form={orderForm} handleUpdate={handleUpdate} />
    </Root>
  );
}

export default memo(EditOrderFormProperties);
