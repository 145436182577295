import { ApolloClient } from '@apollo/client';

import { Script } from 'types';

export const updateScriptsCache = (proxy: ApolloClient<object>, item: Script) => {
  try {
    proxy.cache.modify({
      id: proxy.cache.identify({ mId: item.mId, mType: item.mType }),
      fields: {
        locked: () => item.locked ?? null,
        mState: () => item.mState,
        mUpdatedAt: () => item.mUpdatedAt,
        mUpdatedById: () => item.mUpdatedById,
        mContentKey: () => item.mContentKey,
        mLastVersion: () => item.mLastVersion,
      },
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('updateScriptsCache', error);
  }
};
