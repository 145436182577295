import { useCallback } from 'react';

import { timeVariants } from 'utils/planningViews';

import useDateTimeUtils from './useDateTimeUtils';

const useGetDates = () => {
  const {
    addDays,
    eachDayOfInterval,
    endOfDay,
    format,
    getISODay,
    lastDayOfISOWeek,
    startOfDay,
    startOfISOWeek,
    startOfMonth,
    subDays,
  } = useDateTimeUtils();

  const getDates = useCallback(
    (time: Date | string | number, timeVariant: 'date' | 'week' | 'month') => {
      let startDate: Date;
      let endDate: Date;

      switch (timeVariant) {
        case timeVariants.DAY:
          startDate = startOfDay(time);
          endDate = endOfDay(time);
          break;
        case timeVariants.WEEK:
          startDate = startOfDay(startOfISOWeek(time));
          endDate = endOfDay(lastDayOfISOWeek(time));
          break;
        case timeVariants.MONTH:
          startDate = startOfDay(startOfMonth(time));
          startDate = subDays(startDate, getISODay(startDate) - 1);
          endDate = endOfDay(addDays(startDate, 41));
          break;
        default:
          throw new Error('Invalid time variant');
      }

      const data: Record<string, []> = {};
      eachDayOfInterval({ start: startDate, end: endDate }).forEach((date) => {
        data[format(date, 'yyyy-MM-dd')] = [];
      });

      return {
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        data,
      };
    },
    [],
  );
  return { getDates };
};

export default useGetDates;
