import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import MuiListItem from '@material-ui/core/ListItem';

import transientOptions from 'theme/helpers';
import { RundownStateEnum } from 'types/graphqlTypes';

export const ImageWrapper = styled('div')`
  width: 48px;
  height: 48px;
  margin: 12px;
  position: relative;
`;

export const Image = styled('img', transientOptions)<{ state?: RundownStateEnum }>`
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 14px;
  border: ${(props) => {
    if (props.state === RundownStateEnum.Active) return '2px solid orange';
    if (props.state === RundownStateEnum.Ready) return '2px solid red';
    return '';
  }};
`;

export const PlatformImage = styled('img')`
  width: 22px;
  height: 22px;
  position: absolute;
  right: 0px;
  bottom: 0px;
`;

export const ContentWrapper = styled('div')`
  flex: 1;
`;

export const RundownTitle = styled('p')`
  ${({ theme }) => theme.typography.dina.teamInfoType};
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

export const TimingInfoWrapper = styled('div')`
  display: flex;
  margin-top: 3px;
`;

export const TimingInfo = styled('div')`
  max-width: 56px;
  margin-right: 16px;
`;

export const TimingLabel = styled('p')`
  ${({ theme }) => theme.typography.dina.caption};
  margin: 0;
`;

export const TimingValue = styled('p')`
  ${({ theme }) => css`
    ${theme.typography.dina.caption};
    color: ${theme.palette.dina.highEmphasis};
  `};
  margin: 0;
  margin-top: 1px;
`;

export const ListItem = styled(MuiListItem, transientOptions)<{ $isDragging: boolean }>`
  ${({ theme, $isDragging }) => css`
    background-color: ${theme.palette.dina.surfaceAppBackgroundNavLevel2};
    ${$isDragging && 'opacity: .4;'}
  `}
  padding: 0px;
  left: 0;
  max-height: 72px;
  :hover {
    background-color: ${({ theme }) => theme.palette.dina.storyCardHover};
  }
  &.Mui-selected,
  &.Mui-selected:hover {
    background-color: ${({ theme }) => theme.palette.dina.onFocus};
  }
  &.Mui-selected {
    ${RundownTitle}, ${TimingLabel}, ${TimingValue} {
      color: ${({ theme }) => theme.palette.dina.whiteHighEmphasis};
    }
  }
`;
