import { useContext } from 'react';
import { atom, useSetAtom } from 'jotai';
import { molecule, useMolecule } from 'jotai-molecules';

import useToast from 'components/toast/useToast';
import UserContext from 'contexts/UserContext';
import { InstanceScope } from 'features/instance/store/instance';
import useEventSource from 'hooks/useEventSource';
import { EditorValue } from 'types';
import { AuditMessageInput } from 'types/graphqlTypes';
import { isLockedHereByUser, setLockToken } from 'utils/lock/lockTokenV2';
import useLogger from 'utils/useLogger';

import useUpdateScript from '../api/useUpdateScript';
import useScriptScope from '../hooks/useScriptScope';

import { scriptMolecule, scriptStateMutex } from '.';

export const scriptLockMolecule = molecule((getMol, getScope) => {
  getScope(InstanceScope);

  const { updateScript } = useUpdateScript();
  const [addLockReleasedOnSaveHandler, triggerLockReleasedOnSave] = useEventSource<string>();

  const { baseAtom } = getMol(scriptMolecule);

  const currentEditingScope = useScriptScope();
  const { mId: currentUserId } = useContext(UserContext);

  const { error: errorLog } = useLogger('scriptStore:lock');
  const { errorToast } = useToast();

  const lockScriptAtom = atom(null, async (get, set, userId: string, lockTime?: string) => {
    const { script, isLocked, lockedBy, canUpdate } = get(baseAtom);

    if (script && currentEditingScope && canUpdate) {
      if (isLocked) return lockedBy;

      const release = await scriptStateMutex.acquire();

      const lockToken = setLockToken(currentEditingScope, userId, lockTime);
      set(baseAtom, (prev) => ({
        ...prev,
        isLoading: true,
        isLocked: true,
        lockedBy: lockToken,
      }));

      try {
        const lockResponse = await updateScript({
          mId: script.mId,
          mRefId: script.mRefId,
          locked: lockToken,
          audit: {
            message: 'lock script',
            source: 'scriptLockMolecule: lockScriptAtom',
          },
        });

        set(baseAtom, (prev) => ({
          ...prev,
          isLoading: false,
          isLocked: !!lockResponse?.locked,
          lockedBy: lockResponse?.locked ?? null,
        }));

        return lockResponse?.locked ?? null;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        errorToast(error, 'There was an error trying to lock script');
        if (error instanceof Error) errorLog(error);
        set(baseAtom, (prev) => ({ ...prev, isLoading: false, isLocked: false, lockedBy: null }));

        return null;
      } finally {
        release();
      }
    }
  });

  const unlockScriptAtom = atom(
    null,
    async (
      get,
      set,
      {
        content,
        cancelled,
        audit,
        forceUnlock = false,
      }: {
        content: EditorValue | null;
        cancelled: boolean;
        audit: AuditMessageInput;
        forceUnlock?: boolean;
      },
    ) => {
      const { isLocked, lockedBy, content: initialContent, script, canUpdate } = get(baseAtom);

      if (
        script &&
        canUpdate &&
        ((isLocked && isLockedHereByUser(lockedBy, currentUserId, currentEditingScope)) ||
          forceUnlock)
      ) {
        const release = await scriptStateMutex.acquire();

        set(baseAtom, (prev) => ({
          ...prev,
          content: forceUnlock || cancelled ? prev.content : content,
          isSaving: true,
          isLocked: false,
          lockedBy: null,
        }));

        try {
          const unlockResponse = await updateScript(
            {
              mId: script.mId,
              mRefId: script.mRefId,
              locked: null,
              ...(!(cancelled || forceUnlock) && content && { content: JSON.stringify(content) }),
              ...(cancelled && { isCancelEvent: cancelled }),
              ...(audit && { audit }),
            },
            true,
          );
          if (!cancelled && !forceUnlock) triggerLockReleasedOnSave(lockedBy!);

          // Update state after unlocking completes
          set(baseAtom, (prev) => ({
            ...prev,
            isSaving: false,
            isLocked: !!unlockResponse?.locked,
            lockedBy: unlockResponse?.locked ?? null,
            content: !unlockResponse?.mLastVersion ? null : prev.content,
          }));

          return unlockResponse?.locked;
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error);
          errorToast(error, 'There was an error trying to unlock story');
          if (error instanceof Error) errorLog(error);
          set(baseAtom, (prev) => ({
            ...prev,
            content: initialContent,
            isLoading: false,
            isSaving: false,
            isLocked,
            lockedBy,
          }));

          return script?.locked ?? null;
        } finally {
          release();
        }
      }
    },
  );

  return {
    lockScriptAtom,
    unlockScriptAtom,
    addLockReleasedOnSaveHandler,
  };
});

export const useScriptLock = () => {
  const { lockScriptAtom, unlockScriptAtom, addLockReleasedOnSaveHandler } =
    useMolecule(scriptLockMolecule);

  const lockScript = useSetAtom(lockScriptAtom);
  const unlockScript = useSetAtom(unlockScriptAtom);

  return {
    lockScript,
    unlockScript,
    addLockReleasedOnSaveHandler,
  };
};
