import { v4 as uuid } from 'uuid';

import { getDefaultLayoutSettings } from 'components/editMdfDialog/utils';
import {
  type Alternative,
  type ConfigType,
  CrudActionEnum,
  FieldTypeEnum,
  type FieldValue,
  type LayoutSettings,
  type Mdf,
  type MdfField,
  MemberTypeEnum,
  type Metadata,
  type ViewTypes,
} from 'types/graphqlTypes';

export interface Form {
  mProperties: FormProperties;
  mId: string; // SpaceId
  mRefId: string; // FormId
  mRefTitle: string; // Space title
  mTitle: string; // Form title
  mType: 'order_form';
}

interface FormProperties {
  fields?: FormField[];
  types: FilterType[] | null;
}

type FilterType = ResourceType | 'all';

type FieldType = 'boolean' | 'text' | 'select';

export interface Option {
  id: string;
  title: string;
  value: string;
}
export interface FormField {
  id: string;
  name: string;
  label: string;
  type: FieldType;
  options?: Option[];
}

export interface NewFieldValue {
  fieldId: string;
  value: FieldValue;
}

export type ResourceType =
  | 'story'
  | 'pitch'
  | 'instance'
  | 'rundown'
  | 'order'
  | 'space'
  | 'block'
  | 'res_story'
  | 'res_pitch';

export type SearchFieldValue = FieldValue | { from: string; to: string };
export type SearchMetadata = Record<string, SearchFieldValue>;
export type Payload = Record<string, FieldValue>;

type Modify<T, R> = Omit<T, keyof R> & R;

export interface MiniMember {
  id: string;
  type: MemberTypeEnum;
  connectedId?: string;
}

// As used by application where we have parsed the metadata
export type Order = Modify<RawOrder, { metadata: Metadata }>;

export type OrderWithMdf = Order & { mdf: Mdf };

// As received by GraphQL
export interface RawOrder extends CreateOrder {
  mId: string;
  mdfId: string;
  metadata: string | null;
  mSpaceId: string;
  mUpdatedAt: string;
  mCreatedAt: string;
  mCreatedById: string;
  mResourceType: ResourceType;
  mStatus: string;
  mActive: boolean;
  crudAction?: CrudActionEnum;
  mType: string;
}

/**
 * We prepend mState with these to cheaply separate these two queries
 */
export type OrderState = 'active' | 'inactive';

export interface CreateOrder {
  mResourceId: string; // mId in database
  mResourceType: ResourceType; // What type of resource is the order to be connected to
  mFormId: string;
  metadata?: string | null; // stringified into mutation
  mOwner?: string; // mTertId in db
  mAssignee?: string; //mTertRefId in db
  mStatus?: string;
}

export interface UpdateOrder {
  mResourceId: string; // mId in database
  mId: string; // mRefId in database = orderId;
  metadata?: string; // stringified into mutation
  mOwner?: string; // mTertId in db
  mAssignee?: string; //mTertRefId in db
  mStatus?: string;
}

/**
 * Create a new order
 * @param resourceId The resource the order is connected to
 * @param formId The model of the order
 * @param creator Creator & initial owner
 * @param metadata Optionally what metadata to start with
 * @returns Order that can be sent in mutation
 */
export const createOrder = (
  resourceId: string,
  formId: string,
  resourceType: ResourceType,
  creator: string,
  metadata?: Metadata,
  assigneeId?: string,
): CreateOrder => {
  return {
    metadata: JSON.stringify(metadata),
    mResourceId: resourceId,
    mResourceType: resourceType,
    mFormId: formId,
    mOwner: creator,
    mStatus: 'created',
    ...(assigneeId && { mAssignee: assigneeId }),
  };
};

export const getStatusFromMState = (mState: string, field?: FormField): string => {
  if (field) {
    const option = (field.options ?? []).find((opt) => opt.value == mState);
    return option ? option.title : 'Unknown';
  }
  return mState;
};

export const getDefaultValue = (field: MdfField) => {
  if (!field) return null;
  switch (field.type) {
    case FieldTypeEnum.checkbox:
      return false;
    case FieldTypeEnum.text:
    case FieldTypeEnum.choice:
      return null;
  }
  return null;
};

const getSettingsAndFieldFor = (
  field: MdfField,
  mdf: Mdf,
  view: ViewTypes,
): MdfField & { settings: LayoutSettings } => {
  const settings =
    mdf.views[view].find((s) => s.fieldId === field.fieldId) ??
    mdf.views.default.find((s) => s.fieldId === field.fieldId) ??
    getDefaultLayoutSettings(field);
  return {
    ...field,
    settings: settings,
  };
};

/**
 * Provides all fields & settings for a given MDF. Will recursively look for
 * fields and their settings if there are sub type fields present.
 * @param mdf The main mdf to produce a map for.
 * @param subMdfsByLabel
 * All subtype mdfs mapped by label, due to values in alternatives containing sub mdf label.
 * @param view
 * for which view to prefer to fetch layout settings for, if not found will fetch from default.
 * @returns
 */
export const getAllFieldsAndSettings = (
  mdf: Mdf,
  subMdfsByLabel: Record<string, Mdf>,
  view: ViewTypes,
): Record<string, MdfField & { settings: LayoutSettings }> => {
  let map: Record<string, MdfField & { settings: LayoutSettings }> = {};
  for (const field of mdf.fields) {
    if (field.type === FieldTypeEnum.subtype) {
      for (const alt of field.alternatives ?? []) {
        map = {
          ...map,
          ...(subMdfsByLabel[alt.value] &&
            getAllFieldsAndSettings(subMdfsByLabel[alt.value], subMdfsByLabel, view)),
        };
      }
    } else {
      map = {
        ...map,
        [field.fieldId]: getSettingsAndFieldFor(field, mdf, view),
      };
    }
  }
  return map;
};

export const getLayoutSettings = (mdf: Mdf, field: MdfField, view: ViewTypes) => {
  const specificSettings = mdf.views[view]?.find((l) => l.fieldId === field.fieldId);
  if (!specificSettings) {
    return (
      mdf.views.default.find((l) => l.fieldId === field.fieldId) ?? getDefaultLayoutSettings(field)
    );
  }
  return specificSettings;
};

const getDefaultMStateAlternatives = (): Alternative[] => {
  return [
    {
      id: uuid(),
      label: 'Created',
      value: 'active#created',
    },
    {
      id: uuid(),
      label: 'In progress',
      value: 'active#in_progress',
    },
    {
      id: uuid(),
      label: 'Closed',
      value: 'inactive#closed',
    },
  ];
};

const getDefaultColors = (): Alternative[] => {
  return [
    {
      id: 'active#created',
      label: 'Created',
      value: '#FF9800',
    },
    {
      id: 'active#in_progress',
      label: 'In progress',
      value: '#119BFD',
    },
    {
      id: 'inactive#closed',
      label: 'Closed',
      value: '#19FF47',
    },
  ];
};

export const generateColors = (): ConfigType => {
  return {
    key: 'status_colors',
    alternatives: getDefaultColors(),
  };
};

export const generateOrderFormConfigs = (): ConfigType[] => {
  return [
    {
      key: 'statuses',
      alternatives: getDefaultMStateAlternatives(),
    },
    {
      key: 'types',
      values: [],
    },
    generateColors(),
  ];
};
