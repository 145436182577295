import { gql, useMutation } from '@apollo/client';
import { MutationFetchPolicy } from '@apollo/client/core/watchQueryOptions';

import { Instance } from 'types';
import { getUserLockToken } from 'utils/lock/lockToken';
import { resetLockToken, setLockToken } from 'utils/lock/lockTokenV2';
import useLogger from 'utils/useLogger';

const LOCK_INSTANCE = gql`
  mutation lockInstance($input: LockMemberInput) {
    lockMember(input: $input) {
      mId
      mRefId
      mMetaData {
        key
        value
        autoValue
        manual
        mostag
      }
      mTitle
      locked
      mUpdatedAt
      mContentKey
    }
  }
`;

const useLockInstance = () => {
  const logger = useLogger('use lock instance');
  const [lockInstanceMutation] = useMutation<{ lockMember: Instance }>(LOCK_INSTANCE);

  const lock = async (
    instanceId: string,
    userId: string,
    scope: string,
    fetchPolicy?: MutationFetchPolicy,
    lockTime?: string,
  ) => {
    const userLockToken = scope
      ? setLockToken(scope, userId, lockTime)
      : getUserLockToken(userId, lockTime);
    const input = {
      mId: instanceId,
      userId: userLockToken,
    };

    try {
      const result = await lockInstanceMutation({
        variables: { input },
        fetchPolicy: fetchPolicy || 'network-only',
      });

      return result;
    } catch (e) {
      if (scope) resetLockToken(scope);
      logger.log(e);
      return null;
    }
  };

  return [lock];
};

export default useLockInstance;
