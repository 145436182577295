import Scrollbar from 'components/scrollbar';
import { VStack } from 'layouts/box/Box';
import { PlatformDTOSettings, ProviderDTO } from 'types/graphqlTypes';

import { ContentWrapper } from '../styled';

import { EditFieldPlatform } from './EditPlatformFields';

interface EditPlatformProps {
  platform: PlatformDTOSettings;
  providers: ProviderDTO[] | undefined;
}

export function EditPlatform({ platform, providers }: Readonly<EditPlatformProps>) {
  return (
    <ContentWrapper>
      {platform && (
        <VStack height="60vh" gap="40px">
          <Scrollbar>
            <EditFieldPlatform selectedPlatform={platform} providers={providers} />
          </Scrollbar>
        </VStack>
      )}
    </ContentWrapper>
  );
}
