import { useCallback, useMemo, useState } from 'react';
import { Tooltip } from '@material-ui/core';
import { keyBy } from 'lodash';

import Dialog from 'components/dialogs/DialogBuilder';
import Text from 'components/text/Text';
import { StyledTextField } from 'features/reusableStyled';
import { VStack } from 'layouts/box/Box';
import { PlatformAccountSettings, PlatformDTOSettings } from 'types/graphqlTypes';

import { OptionWrapper } from '../styled';

interface Props {
  open: boolean;
  setOpen: (val: boolean) => void;
  platformData: PlatformDTOSettings;
  doUpdate: (val: {}) => void;
}

export function AddLocalAccountDialogue({
  open,
  setOpen,
  platformData,
  doUpdate,
}: Readonly<Props>) {
  const [formValues, setFormValues] = useState({
    acc_url: '',
    acc_logo: '',
    acc_title: '',
  });

  const staticAccounts = (
    (platformData.platformInfo?.accounts as PlatformAccountSettings[]) || []
  ).filter((account) => account.isStaticAccount === true);

  const accountById = useMemo(() => {
    return keyBy(staticAccounts, (acc) => acc.accountUrl);
  }, [staticAccounts]);

  const setChangedValue = (key: keyof typeof formValues, value: string) => {
    setFormValues((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const addAccConfirm = useCallback(() => {
    setOpen(false);
    const newAccount = {
      accountUrl: formValues.acc_url,
      accountTitle: formValues.acc_title,
      accountLogo: formValues.acc_logo,
      isActive: true,
    };
    const updatedAccounts = [...(staticAccounts ?? []), newAccount];

    doUpdate({
      platformInfo: {
        ...platformData.platformInfo,
        platform: platformData.platformInfo?.platform ?? '',
        accounts: updatedAccounts,
      },
    });
  }, [
    doUpdate,
    formValues.acc_logo,
    formValues.acc_title,
    formValues.acc_url,
    platformData.platformInfo,
    setOpen,
    staticAccounts,
  ]);

  const tooltipMessage = useMemo(() => {
    if (!formValues.acc_url) {
      return 'This field is required.';
    }
    if (accountById[formValues.acc_url]) {
      return 'This account url already exists.';
    }
    return '';
  }, [accountById, formValues.acc_url]);

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      style={{ minWidth: '600px', maxWidth: '800px' }}
    >
      <Dialog.Header>Add Local Account </Dialog.Header>
      <Dialog.Body>
        <OptionWrapper>
          <VStack>
            <Text variant="caption"> Title : </Text>
          </VStack>
          <VStack>
            <Tooltip title={!formValues.acc_title ? 'This field is required.' : ''}>
              <StyledTextField
                name="title"
                variant="filled"
                value={formValues.acc_title}
                onChange={(ev) => setChangedValue('acc_title', ev.target.value)}
                required
                error={!formValues.acc_title}
              />
            </Tooltip>
          </VStack>
        </OptionWrapper>
        <OptionWrapper>
          <VStack>
            <Text variant="caption"> Url : </Text>
          </VStack>
          <VStack>
            <Tooltip title={tooltipMessage}>
              <StyledTextField
                name="url"
                variant="filled"
                value={formValues.acc_url}
                onChange={(ev) => setChangedValue('acc_url', ev.target.value)}
                required
                error={!formValues.acc_url || !!accountById[formValues.acc_url]}
              />
            </Tooltip>
          </VStack>
        </OptionWrapper>
        <OptionWrapper>
          <VStack>
            <Text variant="caption"> Logo : </Text>
          </VStack>
          <VStack>
            <Tooltip title={!formValues.acc_logo ? 'This field is required.' : ''}>
              <StyledTextField
                name="url"
                variant="filled"
                value={formValues.acc_logo}
                onChange={(ev) => setChangedValue('acc_logo', ev.target.value)}
                required
                error={!formValues.acc_logo}
              />
            </Tooltip>
          </VStack>
        </OptionWrapper>
      </Dialog.Body>
      <Dialog.Footer>
        <Dialog.CancelButton />
        <Dialog.ConfirmButton
          label="Confirm"
          onConfirm={addAccConfirm}
          disabled={
            !formValues.acc_title ||
            !formValues.acc_logo ||
            !formValues.acc_url ||
            !!accountById[formValues.acc_url]
          }
        />
      </Dialog.Footer>
    </Dialog>
  );
}
