import React, { memo } from 'react';

import Text from 'components/text/Text';
import Tooltip from 'components/tooltip';
import { ColorVariants } from 'types';

import { HDivider, ListItem, ListItemText } from './listItem-styled';

interface ListItemViewProps {
  text: string | React.ReactElement;
  textLabel?: string;
  firstChild?: JSX.Element;
  iconChild?: JSX.Element;
  excludeDivider?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  disabled?: boolean;
  maxHeight?: number;
  borderRadius?: number;
  whiteSpace?: string;
  tooltipTitle?: string;
  selected?: boolean;
  textColor?: keyof ColorVariants;
  /* TODO: remove this later when all props are defined */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any;
}

const ListItemView = ({
  text,
  textColor,
  textLabel,
  firstChild,
  iconChild,
  excludeDivider = false,
  onClick = () => {},
  disabled = false,
  maxHeight = 48,
  borderRadius = 0,
  whiteSpace = 'normal',
  tooltipTitle = '',
  selected = false,
  ...rest
}: ListItemViewProps) => (
  <Tooltip title={tooltipTitle} hide={!tooltipTitle}>
    <label>
      <ListItem
        disabled={disabled}
        button
        onClick={onClick}
        borderRadius={borderRadius}
        maxHeight={maxHeight}
        selected={selected}
        {...rest}
      >
        {firstChild}
        <ListItemText $whiteSpace={whiteSpace}>
          <Text
            variant="listItemLabel"
            truncate
            color={textColor ?? (selected ? 'whiteHighEmphasis' : 'highEmphasis')}
          >
            {text}
          </Text>
          {textLabel && (
            <Text variant="listItemLabel" color="statusWarning">
              {textLabel}
            </Text>
          )}
        </ListItemText>
        {iconChild}
      </ListItem>
      {!excludeDivider && <HDivider />}
    </label>
  </Tooltip>
);

export default memo(ListItemView);
