import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import Tooltip from 'components/tooltip';
import uuidv1 from 'uuid/v1';
import {
  useSelectedMenuSystemSettings,
  useSelectedCategory,
  useLinkFields,
  useNewLinkFieldId,
  useChangedLinkFields,
} from '../../../../../atoms';
import SETTING_TYPES from '../../../../../utils/settingTypes';
import fieldTypes from '../../../../../utils/fieldTypes';
import Collapse from '../../../../collapseList';
import { StyledSpanWrapper, StyledWrapper, StyledSpan, StyledClose } from './styled';

const Links = ({ links }) => {
  const [selectedSetting, setSelectedSetting] = useSelectedMenuSystemSettings();
  const [category, setCategory] = useSelectedCategory();
  const [linkFields, setLinkFields] = useLinkFields(links);
  const [newLinkFieldId, setNewLinkFieldId] = useNewLinkFieldId();
  const [changedLinkField, setChangedLinkField] = useChangedLinkFields();

  useEffect(() => {
    setLinkFields(links);
  }, [links, setLinkFields]);

  const onAddNewLinkField = () => {
    const id = uuidv1();
    const name = 'Untitled link';
    setNewLinkFieldId(id);

    setLinkFields([
      {
        __typename: 'MetadataFormFieldType',
        id,
        name,
        type: fieldTypes.TEXT,
      },
      ...linkFields,
    ]);
    setSelectedSetting(id);
    setCategory(SETTING_TYPES.LINKS);
    setChangedLinkField([
      ...changedLinkField,
      {
        key: `${id}:id`,
        value: name,
        originalValue: '',
      },
    ]);
  };

  const onRemoveLinkField = (linkId) => {
    const toBeRemoved = linkFields.find((f) => f.id === linkId);
    setLinkFields(linkFields.filter((f) => f.id !== linkId));
    if (linkId === newLinkFieldId) {
      setNewLinkFieldId(undefined);
      setChangedLinkField(changedLinkField.filter((f) => !f.key.startsWith(linkId)));
    } else {
      setChangedLinkField([
        ...changedLinkField,
        {
          key: `${linkId}:id`,
          value: toBeRemoved.name,
          originalValue: '',
        },
      ]);
    }
  };

  const onLinkClick = (id) => {
    setSelectedSetting(id);
    setCategory(SETTING_TYPES.LINKS);
  };

  return (
    <Collapse>
      <Collapse.Header
        onCreateClick={onAddNewLinkField}
        itemCount={linkFields?.length}
        createButtonTooltip="Add link"
        selected={category === SETTING_TYPES.LINKS}
        createButtonDisabled={!!newLinkFieldId}
        onClick={() => onLinkClick(linkFields[0]?.id)}
      >
        Links
      </Collapse.Header>
      <Collapse.Body>
        {linkFields?.map((field) => (
          <StyledSpanWrapper>
            <Collapse.Item
              className="link-collapse-item"
              key={field.id}
              selected={selectedSetting === field.id}
              onClick={() => onLinkClick(field.id)}
              italic={newLinkFieldId === field?.id}
            >
              <StyledWrapper>
                <StyledSpan>{field.name}</StyledSpan>
                <Tooltip title="Remove link">
                  <StyledClose
                    className="link-remove"
                    onClick={() => onRemoveLinkField(field.id)}
                  />
                </Tooltip>
              </StyledWrapper>
            </Collapse.Item>
          </StyledSpanWrapper>
        ))}
      </Collapse.Body>
    </Collapse>
  );
};

Links.propTypes = {
  links: PropTypes.array,
};

Links.defaultProps = {
  links: [],
};

export default Links;
