import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import ConfigContext from 'contexts/configContext';
import usePlatformWorkflow from 'hooks/usePlatformWorkflow';
import { useSelectedMenuSystemSettings } from 'screens/main/components/header/navbar/settings/atoms';
import { useChangedPlatformWorkflowSettings } from 'screens/main/components/header/navbar/settings/atomsTs';
import { KanbanBoardStateType } from 'types';
import { InstanceStateType, InstanceStateViewType } from 'types/graphqlTypes';
import returnStates from 'utils/statusBoards/returnStates';

const useWorkflowSettings = () => {
  const [selectedMenu] = useSelectedMenuSystemSettings() as unknown as [string];
  const {
    kanbanBoardStates = [] as InstanceStateType[],
    kanbanBoardViews = [] as InstanceStateViewType[],
  } = useContext(ConfigContext);

  const states = useMemo(
    () =>
      returnStates(
        /** as long as linear platforms have the mRefId linear, this should work */
        selectedMenu.includes('linear') ? 'status-linear' : 'status-SoMe',
        kanbanBoardStates,
        kanbanBoardViews,
      ) as KanbanBoardStateType[],
    [kanbanBoardStates, kanbanBoardViews, selectedMenu],
  );

  const defaultState = useMemo(() => states[0], [states]);

  const { getApprovalState, getScheduledState, getCopyState } = usePlatformWorkflow();

  const { initialApprovalState, initialScheduledState, initialCopyState } = useMemo(
    () => ({
      initialApprovalState: getApprovalState(selectedMenu),
      initialScheduledState: getScheduledState(selectedMenu),
      initialCopyState: getCopyState(selectedMenu),
    }),
    [getApprovalState, getScheduledState, getCopyState, selectedMenu],
  );

  const [scheduleWorkflowOn, setScheduleWorkflowOn] = useState(!!initialScheduledState);
  const [stateOnSchedule, setStateOnSchedule] = useState<string | null>(
    getScheduledState(selectedMenu) ?? defaultState?.id ?? null,
  );

  const [approvalWorkflowOn, setApprovalWorkflowOn] = useState(!!initialApprovalState);
  const [stateOnApproval, setStateOnApproval] = useState<string | null>(
    getApprovalState(selectedMenu) ?? defaultState?.id ?? null,
  );

  const [copyWorkflowOn, setCopyWorkflowOn] = useState(!!initialCopyState);
  const [stateOnCopy, setStateOnCopy] = useState<string | null>(
    getCopyState(selectedMenu) ?? defaultState?.id ?? null,
  );

  const [changedPlatformWorkflowSettings, setChangedPlatformWorkflowSettings] =
    useChangedPlatformWorkflowSettings();

  const onScheduledStateChange = useCallback(
    (state: string | null) => {
      setChangedPlatformWorkflowSettings((prev) => ({
        ...prev,
        [selectedMenu]: {
          stateOnSchedule: state ?? undefined,
          approvalState: approvalWorkflowOn ? stateOnApproval ?? undefined : undefined,
          stateOnCopy: copyWorkflowOn ? stateOnCopy ?? undefined : undefined,
        },
      }));
      setStateOnSchedule(state);
    },
    [
      approvalWorkflowOn,
      copyWorkflowOn,
      selectedMenu,
      setChangedPlatformWorkflowSettings,
      stateOnApproval,
      stateOnCopy,
    ],
  );

  const onApprovalStateChange = useCallback(
    (state: string | null) => {
      setChangedPlatformWorkflowSettings((prev) => ({
        ...prev,
        [selectedMenu]: {
          stateOnSchedule: scheduleWorkflowOn ? stateOnSchedule ?? undefined : undefined,
          approvalState: state ?? undefined,
          stateOnCopy: copyWorkflowOn ? stateOnCopy ?? undefined : undefined,
        },
      }));
      setStateOnApproval(state);
    },
    [
      scheduleWorkflowOn,
      copyWorkflowOn,
      selectedMenu,
      setChangedPlatformWorkflowSettings,
      stateOnSchedule,
      stateOnCopy,
    ],
  );

  const onCopyStateChange = useCallback(
    (state: string | null) => {
      setChangedPlatformWorkflowSettings((prev) => ({
        ...prev,
        [selectedMenu]: {
          stateOnSchedule: scheduleWorkflowOn ? stateOnSchedule ?? undefined : undefined,
          approvalState: approvalWorkflowOn ? stateOnApproval ?? undefined : undefined,
          stateOnCopy: state ?? undefined,
        },
      }));
      setStateOnCopy(state);
    },
    [
      scheduleWorkflowOn,
      approvalWorkflowOn,
      selectedMenu,
      setChangedPlatformWorkflowSettings,
      stateOnSchedule,
      stateOnApproval,
    ],
  );

  const toggleScheduleWorkflow = useCallback(() => {
    if (!scheduleWorkflowOn) {
      setScheduleWorkflowOn(true);
      setStateOnSchedule(defaultState.id);
      onScheduledStateChange(defaultState.id);
    } else {
      setScheduleWorkflowOn(false);
      setStateOnSchedule(null);
      onScheduledStateChange(null);
    }
  }, [scheduleWorkflowOn, onScheduledStateChange, defaultState.id]);

  const toggleApprovalWorkflow = useCallback(() => {
    if (!approvalWorkflowOn) {
      setApprovalWorkflowOn(true);
      setStateOnApproval(defaultState.id);
      onApprovalStateChange(defaultState.id);
    } else {
      setApprovalWorkflowOn(false);
      setStateOnApproval(null);
      onApprovalStateChange(null);
    }
  }, [approvalWorkflowOn, defaultState.id, onApprovalStateChange]);

  const toggleCopyWorkflow = useCallback(() => {
    if (!copyWorkflowOn) {
      setCopyWorkflowOn(true);
      setStateOnCopy(defaultState.id);
      onCopyStateChange(defaultState.id);
    } else {
      setCopyWorkflowOn(false);
      setStateOnCopy(null);
      onCopyStateChange(null);
    }
  }, [copyWorkflowOn, onCopyStateChange, defaultState.id]);

  useEffect(() => {
    // reset all local states when the selected menu changes
    setScheduleWorkflowOn(!!initialScheduledState);
    setStateOnSchedule(initialScheduledState ?? defaultState?.id ?? null);
    setApprovalWorkflowOn(!!initialApprovalState);
    setStateOnApproval(initialApprovalState ?? defaultState?.id ?? null);
    setCopyWorkflowOn(!!initialCopyState);
    setStateOnCopy(initialCopyState ?? defaultState?.id ?? null);
  }, [defaultState?.id, initialApprovalState, initialScheduledState, initialCopyState]);

  useEffect(() => {
    /**  check if the selected menu has a scheduled state, approval state, or copy state in
     * changedPlatformWorkflowSettings and reset local state based on that and return */
    if (changedPlatformWorkflowSettings[selectedMenu]) {
      setScheduleWorkflowOn(!!changedPlatformWorkflowSettings[selectedMenu].stateOnSchedule);
      setStateOnSchedule(changedPlatformWorkflowSettings[selectedMenu].stateOnSchedule ?? null);
      setApprovalWorkflowOn(!!changedPlatformWorkflowSettings[selectedMenu].approvalState);
      setStateOnApproval(changedPlatformWorkflowSettings[selectedMenu].approvalState ?? null);
      setCopyWorkflowOn(!!changedPlatformWorkflowSettings[selectedMenu].stateOnCopy);
      setStateOnCopy(changedPlatformWorkflowSettings[selectedMenu].stateOnCopy ?? null);
    } else {
      // reset all local states when the selected menu changes
      setScheduleWorkflowOn(!!initialScheduledState);
      setStateOnSchedule(initialScheduledState ?? defaultState?.id ?? null);
      setApprovalWorkflowOn(!!initialApprovalState);
      setStateOnApproval(initialApprovalState ?? defaultState?.id ?? null);
      setCopyWorkflowOn(!!initialCopyState);
      setStateOnCopy(initialCopyState ?? defaultState?.id ?? null);
    }
  }, [
    changedPlatformWorkflowSettings,
    defaultState?.id,
    initialApprovalState,
    initialScheduledState,
    initialCopyState,
    selectedMenu,
  ]);

  return {
    selectedMenu,
    states,
    scheduleWorkflowOn,
    stateOnSchedule,
    approvalWorkflowOn,
    stateOnApproval,
    copyWorkflowOn,
    stateOnCopy,
    toggleScheduleWorkflow,
    toggleApprovalWorkflow,
    toggleCopyWorkflow,
    onScheduledStateChange,
    onApprovalStateChange,
    onCopyStateChange,
  };
};

export default useWorkflowSettings;
