import isHotkey from 'is-hotkey';
import { Editor } from 'slate';

import { hotkeys } from 'components/editor/constants';
import { isMarkActive, toggleMark } from 'components/editor/utils';
import { KeyMap } from 'types/keyboardShortcut';

const onLeafKeyDown = (
  editor: Editor,
  event: React.KeyboardEvent<HTMLDivElement>,
  keyMap: Record<string, KeyMap>,
) => {
  Object.values(keyMap).forEach((item) => {
    if (isHotkey(item.sequences[0], event) && item.mark) {
      event.preventDefault();
      event.stopPropagation();

      const mark = hotkeys[item.mark];

      const isActive = isMarkActive(editor, mark);

      toggleMark(editor, mark, isActive);
    }
  });

  return editor;
};

export default onLeafKeyDown;
