import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from '@emotion/styled';

import { ReactComponent as DropdownIcon } from 'assets/icons/systemicons/arrows/arrow_drop_down_discreet_unboxed.svg';
import { Button } from 'components/buttons';
import Popover from 'components/dialogs/PopoverBuilder';
import Tabs from 'components/tabs/regular/Tabs';
import Text from 'components/text';
import useCalculateContainerDimension from 'hooks/useCalculateContainerDimension';
import { TabsTrigger } from 'lib/tabs';
import { moveArrayItem } from 'utils/arrayUtils';

export const TabsList = styled(Tabs.List)`
  height: 32px;
  align-items: center;
  justify-content: center;
`;

export const RTabsTrigger = styled(TabsTrigger)`
  cursor: pointer;
  color: ${({ theme }) => theme.palette.dina.tabResting};
  height: 32px;
  width: 100%;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  :hover {
    color: ${({ theme }) => theme.palette.dina.highEmphasis};
  }
  :focus-visible {
    background: ${({ theme }) => theme.palette.dina.surfaceAppBackgroundMain};
    outline: 2px solid ${({ theme }) => theme.palette.dina.onFocus};
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    outline-offset: -2px;
  }
`;

interface TriggersProps<T extends string> {
  tabs: T[];
  onTabsReorder?: (newTabOrder: T[]) => void;
  activeTab?: T;
  setActiveTab?: (tab: T) => void;
}

function Triggers<T extends string>({
  tabs,
  onTabsReorder,
  activeTab,
  setActiveTab,
}: Readonly<TriggersProps<T>>) {
  const [open, setOpen] = useState(false);
  const [localTabs, setLocalTabs] = useState<T[]>([...tabs]);

  // When tabs prop changes, update localTabs
  useEffect(() => {
    setLocalTabs([...tabs]);
  }, [tabs]);

  const ref = useRef<HTMLDivElement>(null);
  const size = useCalculateContainerDimension(ref);

  const maxVisibleTabs = useMemo(() => {
    if (!size.width) return 0;
    const visibleTabs = Math.floor(size.width / 120);
    return visibleTabs >= tabs.length ? tabs.length : visibleTabs;
  }, [size.width, tabs.length]);

  const onSelect = useCallback(
    (tab: T) => {
      // Update local state
      setLocalTabs((prev) => {
        const index = prev.findIndex((t) => t === tab);
        if (index === -1) return prev;

        const newTabs = [...prev];
        moveArrayItem(newTabs, index, maxVisibleTabs - 1);

        // Notify parent component of tab order change
        if (onTabsReorder) {
          onTabsReorder(newTabs);
        }

        setOpen(false);

        // Set active tab
        if (setActiveTab) {
          setActiveTab(tab);
        }

        return newTabs;
      });
    },
    [maxVisibleTabs, onTabsReorder, setActiveTab],
  );

  const visibleTabs =
    localTabs.length > maxVisibleTabs ? localTabs.slice(0, maxVisibleTabs) : localTabs;

  const hiddenTabs = localTabs.length > maxVisibleTabs ? localTabs.slice(maxVisibleTabs) : [];

  return (
    <TabsList ref={ref} aria-orientation="horizontal">
      {visibleTabs.map((tab) => (
        <Tabs.Trigger
          key={tab}
          id={tab}
          title={tab}
          aria-selected={tab === activeTab}
          aria-controls={`panel-${tab}`}
        />
      ))}

      {hiddenTabs.length > 0 && (
        <Popover open={open} onOpenChange={setOpen}>
          <Popover.Trigger asChild>
            <Button
              usage="text"
              width={50}
              height={24}
              aria-label={`${hiddenTabs.length} more tabs`}
              aria-haspopup="true"
              aria-expanded={open}
            >
              +{hiddenTabs.length}
              <DropdownIcon />
            </Button>
          </Popover.Trigger>
          <Popover.Content
            side="top"
            sideOffset={8}
            align="center"
            style={{ minWidth: 'auto' }}
            role="menu"
          >
            <>
              {hiddenTabs.map((tab) => (
                <RTabsTrigger
                  key={tab}
                  value={tab}
                  asChild
                  onClick={() => onSelect(tab)}
                  role="menuitem"
                >
                  <Text variant="tabLabel">{tab}</Text>
                </RTabsTrigger>
              ))}
            </>
          </Popover.Content>
        </Popover>
      )}
    </TabsList>
  );
}

export default memo(Triggers) as typeof Triggers;
