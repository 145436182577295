function isSaveKeyEvent(event: React.KeyboardEvent): boolean {
  if (
    (event.key === 's' || event.key === 'ß' || event.key === 'S') &&
    (event.ctrlKey || event.metaKey)
  ) {
    return true;
  }
  return false;
}

export default isSaveKeyEvent;
