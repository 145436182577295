import { gql, useQuery } from '@apollo/client';

import { getAllPlatformsQuery } from 'operations/queryVariables/query-variables';
import { PlatformDTOSettings } from 'types/graphqlTypes';

export const GET_ALL_PLATFORMS = gql`
  query GetAllPlatforms($input: GetAllPlatformsInput) {
    getAllPlatforms(input: $input) {
      id
      title
      platformInfo {
        platform
        provider {
          id
          pagesEndpoint {
            id
            url
          }
          publishEndpoint {
            id
            url
          }
        }
        platformIcon
        accounts {
          accountLogo
          accountTitle
          accountUrl
          isStaticAccount
          isActive
        }
      }
    }
  }
`;

export interface GetPlatformType {
  getAllPlatforms: PlatformDTOSettings[];
}

const useGetAllPlatforms = () => {
  const variables = getAllPlatformsQuery(false, true);
  const { data, loading, error } = useQuery<GetPlatformType>(GET_ALL_PLATFORMS, {
    variables,
    fetchPolicy: 'cache-and-network',
  });

  return { data: data?.getAllPlatforms, loading, error };
};

export default useGetAllPlatforms;
