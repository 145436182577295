import styled from '@emotion/styled';

import { HStack } from 'layouts/box/Box';

export const Container = styled('div')`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 164px;
  background-color: ${({ theme }) => theme.palette.dina.surfaceAppBackgroundMain};
  border-top: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
`;

export const Header = styled(HStack)`
  height: 32px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
  padding-left: 8px;
`;

export const TriggerWrapper = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const Body = styled(HStack)`
  flex: 1;
  width: 100%;
`;
