import { CSSProperties, RefObject, useCallback, useEffect, useRef } from 'react';

import { treeContainsActiveElement } from 'utils/dom/ulTree';
import { focusSelectedListItem, moveUlViewFocus } from 'utils/dom/ulView';
import { getKeyCommand } from 'utils/keyboardShortcuts';
import { deepFreeze } from 'utils/objectUtils';

import { handleAddChildNode, handleDelete, handleRename } from './keyHandlers';
import { ContentRenderer, MemoTreeNode } from './TreeNode';
import { TreeItem } from './treeUtils';

import { UnorderedList } from './styled';

export type CSSLayoutProperties = Pick<
  CSSProperties,
  'height' | 'width' | 'overflow' | 'overflowX' | 'overflowY'
>;

/**
 * This renders the content of a tree node by rendering the ID of the tree item
 * @param item The `TreeItem` to be rendered
 * @returns    The ID of the tree item
 */
const defaultContentRenderer: ContentRenderer = (item) => {
  return item.id;
};

const CMD_MAP = {
  UP: { key: 'ArrowUp' },
  DOWN: { key: 'ArrowDown' },
  RENAME: [{ key: 'F2' }, { key: 'Enter', mac: true }],
  DELETE: { key: 'Backspace' },
  ADD: { key: '+' },
} as const;
deepFreeze(CMD_MAP);

export interface TreeViewProps<T extends TreeItem> {
  readonly items: readonly T[];
  readonly selectableFolders?: boolean;
  readonly fillWidth?: boolean;
  readonly accordion?: boolean;
  readonly selectionPath: readonly string[] | undefined;
  readonly updateSelectionPath: (path: readonly string[]) => void;
  /**
   * An optional render function to render the content of the tree nodes.
   * If not provided
   */
  readonly contentRenderer?: (item: T) => React.ReactNode;
  readonly treeRef?: RefObject<HTMLUListElement>;
  /**
   * Optional function to rename. If this function is provided the user can use Enter to rename the
   * focused node (it will be selected as well).
   * @param path The path of IDs of the tree node to rename
   */
  readonly onRenameNode?: (path: readonly string[]) => void;
  /**
   * Optional function to delete. If this function is provided the user can use Backspace to request
   * deletion of the focused node.
   * @param path The path of IDs of the tree node to delete
   */
  readonly onDeleteNode?: (path: readonly string[]) => void;
  /**
   * Optional function to add a child node to a parent node that has children.
   * If this function is provided the user can use + to trigger it if a node with children is
   * focused.
   * @param parentPath The path of IDs to the parent node
   */
  readonly onAddChildNode?: (parentPath: readonly string[]) => void;
  readonly style?: CSSLayoutProperties;
}

/**
 * Renders a TreeView in the DOM provided the {@link TreeViewProps}.
 * Note that `focusSelectedListItem` can be used to focus the node in the tree that is selected.
 * @param props The `TreeViewProps` defining the content and selection of the tree view.
 */
export function TreeView<T extends TreeItem = TreeItem>({
  items,
  selectableFolders,
  fillWidth,
  accordion,
  selectionPath,
  updateSelectionPath,
  contentRenderer,
  treeRef,
  onRenameNode,
  onDeleteNode,
  onAddChildNode,
  style,
}: TreeViewProps<T>) {
  const fallbackRef = useRef<HTMLUListElement>(null);
  const myRef = treeRef ?? fallbackRef;
  const itemsRef = useRef(items);
  itemsRef.current = items;

  const handleKeydown = useCallback(
    (e: React.KeyboardEvent<HTMLUListElement>) => {
      const cmd = getKeyCommand(e, CMD_MAP);
      switch (cmd) {
        case 'UP':
        case 'DOWN':
          moveUlViewFocus(myRef.current, cmd === 'UP' ? 'up' : 'down');
          e.preventDefault();
          break;
        case 'RENAME':
          handleRename(e, itemsRef.current, myRef.current, onRenameNode, updateSelectionPath);
          break;
        case 'DELETE':
          handleDelete(e, itemsRef.current, myRef.current, onDeleteNode);
          break;
        case 'ADD':
          handleAddChildNode(e, itemsRef.current, myRef.current, onAddChildNode);
          break;
      }
    },
    [onRenameNode, onRenameNode, onAddChildNode, updateSelectionPath],
  );

  useEffect(() => {
    setTimeout(() => {
      if (treeContainsActiveElement(myRef.current)) focusSelectedListItem(myRef.current);
    });
  }, [JSON.stringify(selectionPath)]);
  return (
    <UnorderedList ref={myRef} style={style ?? {}} onKeyDown={handleKeydown}>
      {items.map((item, index) => {
        return (
          <MemoTreeNode
            key={item.id}
            item={item}
            depth={0}
            tabbable={!index && !selectionPath?.length}
            accordion={accordion}
            selectableFolders={!!selectableFolders}
            fillWidth={fillWidth || undefined}
            selectionPath={selectionPath?.[0] === item.id ? selectionPath : undefined}
            updateSelectionPath={updateSelectionPath}
            contentRenderer={
              (contentRenderer as ContentRenderer | undefined) ?? defaultContentRenderer
            }
          />
        );
      })}
    </UnorderedList>
  );
}
