import { memo, useMemo } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { ReactComponent as UIcon } from 'assets/icons/systemicons/scheduled_ultrasmall_off.svg';
import { ReactComponent as SIcon } from 'assets/icons/systemicons/scheduled_ultrasmall_on.svg';
import TextComponent from 'components/text/Text';
import useCustomDateTimeUtils, {
  defaultPublishedDateOptions,
  PublishedDateOptions,
} from 'hooks/useCustomDateTimeUtils';
import { HStack } from 'layouts/box/Box';
import transientOptions from 'theme/helpers';

type DateWrapperProps = {
  $warn: boolean;
  $dark?: boolean;
};

export const CUSTOM_UPDATED_AT_DATE_FORMAT = {
  anotherYear: 'd MMM yy',
  formatString: 'd MMM',
  today: 'HH:mm',
  tomorrow: "'Tomorrow'",
  yesterday: "'Yesterday'",
} as const;

export const CUSTOM_PUBLISHED_DATE_FORMAT = {
  anotherYear: 'd MMM yy',
  formatString: 'd MMM, HH:mm',
  today: "'Today', HH:mm",
  tomorrow: "'Tomorrow', HH:mm",
  yesterday: "'Yesterday', HH:mm",
} as const;

const DateWrapper = styled(HStack, transientOptions)<DateWrapperProps>`
  height: 14px;
  gap: 4px;
  border-radius: 4px;
  justify-content: flex-start;
  white-space: nowrap;
  color: ${({ theme }) => theme.palette.dina.whiteInactive};
  font-size: 12px;
  ${({ $warn, theme }) =>
    $warn &&
    css`
      border: 1px solid ${theme.palette.dina.statusWarning};
      background-color: ${theme.palette.dina.statusWarningBackground};
    `}
  ${({ $dark, theme }) =>
    $dark &&
    css`
      span {
        color: ${theme.palette.dina.whiteMediumEmphasis};
      }
      svg path {
        fill: ${theme.palette.dina.whiteHighEmphasis};
        fill-opacity: 0.65;
      }
    `}
`;

const ScheduledIcon = styled(SIcon)`
  flex-shrink: 0;
`;

const UnscheduledIcon = styled(UIcon)`
  flex-shrink: 0;
`;

const Scheduled = ({
  mPublishingAt,
  warn = false,
  dateFormat = defaultPublishedDateOptions,
  isIcon = true,
  dark,
}: {
  mPublishingAt?: string;
  warn?: boolean;
  dateFormat?: PublishedDateOptions;
  isIcon?: boolean;
  dark?: boolean;
}) => {
  const { formatPublishedDate } = useCustomDateTimeUtils();
  const text = useMemo(
    () => (mPublishingAt ? formatPublishedDate(mPublishingAt, dateFormat) : 'Unscheduled'),
    [dateFormat, formatPublishedDate, mPublishingAt],
  );

  return (
    <DateWrapper $warn={warn} $dark={dark}>
      {isIcon && (mPublishingAt ? <ScheduledIcon /> : <UnscheduledIcon />)}
      <TextComponent variant="storyCardDescription">{text}</TextComponent>
    </DateWrapper>
  );
};

export default memo(Scheduled);
