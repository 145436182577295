import { useCallback } from 'react';
import { gql, useMutation } from '@apollo/client';
import { capitalize } from 'lodash';

import { getErrorMessage } from 'api/commonKeys';
import useToast from 'components/toast/useToast';
import { PlatformDTOSettings } from 'types/graphqlTypes';
import useLogger from 'utils/useLogger';

import { writePlatformToCache } from './useCreatePlatform';

const UPDATE_PLATFORM = gql`
  mutation UpdatePlatformSettings($input: UpdatePlatformSettingsInput) {
    updatePlatformSettings(input: $input) {
      id
      title
      platformInfo {
        platform
        provider {
          id
          pagesEndpoint {
            id
            url
          }
          publishEndpoint {
            id
            url
          }
        }
        platformIcon
        accounts {
          accountUrl
          accountLogo
          accountTitle
          isActive
        }
      }
    }
  }
`;

interface UpdatePlatform {
  updatePlatformSettings: PlatformDTOSettings;
}

interface UpdateInput {
  input: PlatformDTOSettings;
}

export const useUpdatePlatform = () => {
  const { toast } = useToast();
  const [updateMutation] = useMutation<UpdatePlatform, UpdateInput>(UPDATE_PLATFORM);
  const logger = useLogger('UpdatePlatform');
  const updatePlatform = useCallback(
    async (input: PlatformDTOSettings) => {
      try {
        const result = await updateMutation({
          variables: {
            input: {
              ...input,
            },
          },
          update: (proxy, mutationResult) => {
            toast({
              title: 'Updated ' + input.id,
              description: `${capitalize(input.id)}  updated successfully`,
              type: 'success',
            });
            const updatedPlatform = mutationResult.data?.updatePlatformSettings;
            if (updatedPlatform) {
              writePlatformToCache(proxy, updatedPlatform, logger);
            }
          },
        });
        return result?.data?.updatePlatformSettings;
      } catch (err) {
        logger.log(getErrorMessage(err, input));
      }
    },
    [logger, toast, updateMutation],
  );

  return { updatePlatform };
};
