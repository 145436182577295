import styled from '@emotion/styled';

export const StyledDropdownWrapper = styled('div')`
  .MuiAutocomplete-root {
    height: 32px !important;
    * {
      border: none !important;
    }
  }
`;

export const Wrapper = styled('div')`
  display: flex;
  width: 100%;
  height: 100%;
  min-width: 200px;
  flex-direction: column;
  gap: 6px;
  z-index: 1000;
  background-color: ${({ theme }) => theme.palette.dina.surfaceCard};
  .MuiFormControl-root {
    margin: 0;
    width: 100%;
    height: 100%;
    * {
      border: none !important;
    }
  }

  .MuiAutocomplete-root {
    height: 100%;
  }

  .MuiInputBase-root {
    height: 100%;
  }
  .MuiInputBase-input {
    font-size: 11px;
    height: 100%;
    padding: 0 0 0 6px !important;
  }
`;
