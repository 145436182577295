/* eslint-disable arrow-body-style */

/**
 * This function returns one snapshot Image from Video File Object
 *
 * @param file
 * @param filePath
 * @returns
 */
const generateVideoThumbnail = (file, filePath = undefined) => {
  return new Promise((resolve) => {
    const canvas = document.createElement('canvas');
    const video = document.createElement('video');

    video.autoplay = true;
    video.muted = true;
    video.src = filePath ?? URL.createObjectURL(file);

    video.onloadeddata = () => {
      const ctx = canvas.getContext('2d');

      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
      video.pause();
      if (!filePath) URL.revokeObjectURL(video.src);

      return resolve(canvas.toDataURL('image/jpeg'));
    };

    video.onerror = () => {
      if (!filePath) URL.revokeObjectURL(video.src);
      return resolve(null);
    };
  });
};
export default generateVideoThumbnail;
