import groupPoliciesConfig from 'config/permissions.json';
import { PlatformAccount } from 'types';
import { AccessTypeEnum, GroupPolicy, InstanceStateType, MemberType } from 'types/graphqlTypes';
import { PermissionType, PlatformInstanceType } from 'types/groupPermissions/permissions';
import getSoMeBrandName from 'utils/constants/socialMediaBrandNames';
import getIdentifier from 'utils/instance/getAccountIdentifier';

const { policies: policiesConfig } = groupPoliciesConfig;

const getResourcePermission = (
  groupPolicy: MemberType,
  action: string,
  resourceName: string,
  defaultAccess: string | undefined,
) => {
  const { policies } = groupPolicy?.mProperties as GroupPolicy;
  const resource = policies?.find((policy) => policy.resourceName === resourceName);
  if (!resource) return true;
  const { permissions } = resource;
  const matchedPermission = permissions?.find((permission) => permission.action === action);
  if (!matchedPermission) {
    if (defaultAccess) return defaultAccess === 'allow';
    return true;
  }
  if (matchedPermission?.access === AccessTypeEnum.Deny) return false;
  return true;
};

const getKanbanStatePermissions = (
  groupPolicy: MemberType,
  selectedLeftMenu: string,
  kanbanStates: InstanceStateType[],
) => {
  const permissionRows = [] as PermissionType[];
  if (!kanbanStates) return permissionRows;
  if (!Array.isArray(kanbanStates) || kanbanStates.length === 0) return permissionRows;
  let permissionIndex = 2000;
  const resourceName = 'state';
  const defaultAccess = 'allow';
  kanbanStates
    .filter((state) => state.id !== 'none')
    .forEach((state) => {
      const { id: action, name } = state;
      const access = getResourcePermission(groupPolicy, action, resourceName, defaultAccess);
      permissionRows.push({
        permission: `Allow workflow state "${name}"`,
        resourceName,
        id: `${selectedLeftMenu}-${permissionIndex}`,
        action,
        access,
      });
      permissionIndex += 1;
    });
  return permissionRows;
};

const getInstancePlatformPermissions = (
  groupPolicy: MemberType,
  selectedLeftMenu: string,
  instanceTypes: PlatformInstanceType[],
) => {
  const permissionRows = [] as PermissionType[];
  if (!instanceTypes) return permissionRows;
  if (!Array.isArray(instanceTypes)) return permissionRows;
  const validTypes = instanceTypes.filter((type) => type.mTitle !== 'linear');
  if (validTypes.length === 0) return permissionRows;

  let permissionIndex = 1000;
  const resourceName = 'platform';
  const defaultAccess = 'allow';

  validTypes.forEach((instance) => {
    const accounts = instance?.accounts;
    const platform = instance.platform!;
    if (!accounts?.length) return;
    accounts
      .filter((account) => account?.accountTitle)
      .forEach((account: PlatformAccount) => {
        const accountTitle = account?.accountTitle;
        const action = getIdentifier(platform, accountTitle);
        const access = getResourcePermission(groupPolicy, action, resourceName, defaultAccess);
        permissionRows.push({
          permission: `Allow ${getSoMeBrandName(platform)}: ${accountTitle}`,
          resourceName,
          id: `${selectedLeftMenu}-${permissionIndex}`,
          action,
          access,
        });
        permissionIndex += 1;
      });
  });
  return permissionRows;
};

const getGroupPermissions = (
  groupPolicy: MemberType,
  selectedLeftMenu: string,
  instanceTypes = [] as PlatformInstanceType[],
  kanbanStates = [] as InstanceStateType[],
) => {
  const permissionRows = [];
  policiesConfig.forEach((policy, indexFirst) => {
    const { permissions, resourceName } = policy;
    permissions.forEach((permission, indexSecond) => {
      const { description, action, defaultAccess } = permission;
      const access = getResourcePermission(groupPolicy, action, resourceName, defaultAccess);
      permissionRows.push({
        permission: description,
        resourceName,
        id: `${selectedLeftMenu}-${indexFirst}${indexSecond}`,
        action,
        access,
      });
    });
  });
  const platformPermissions = getInstancePlatformPermissions(
    groupPolicy,
    selectedLeftMenu,
    instanceTypes,
  );
  const kanbanStatePermissions = getKanbanStatePermissions(
    groupPolicy,
    selectedLeftMenu,
    kanbanStates,
  );
  permissionRows.push(...platformPermissions);
  permissionRows.push(...kanbanStatePermissions);
  return permissionRows;
};

export default getGroupPermissions;
