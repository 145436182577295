import { useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';

import useContainerWidth from 'hooks/useContainerWidth';
import { InstanceAggregation } from 'types';

export const BADGE_GAP = 4;
export const MIN_BADGE_WIDTH = 32;
export const BADGE_WITH_GAP = MIN_BADGE_WIDTH + BADGE_GAP;

/**
 * Custom hook that calculates which badges should be visible based on
 * container width and maximum number of lines.
 *
 * @param maxLines - Maximum number of lines to display badges
 * @param containerRef - Reference to the container element
 * @param badgeCountsByType - Mapping of badge types to their counts
 * @returns Object containing arrays of visible and hidden badge keys
 */
const useVisibleBadges = (
  maxLines: number,
  containerRef: React.RefObject<HTMLDivElement>,
  badgeCountsByType?: InstanceAggregation,
) => {
  const { containerWidth } = useContainerWidth(containerRef);

  const { visibleBadges, hiddenBadges } = useMemo(() => {
    if (!containerWidth || isEmpty(badgeCountsByType))
      return { visibleBadges: [], hiddenBadges: [] };

    const badgesPerLine = Math.floor(containerWidth / BADGE_WITH_GAP);
    const badgeKeys = Object.keys(omit(badgeCountsByType, 'total'));

    if (badgeKeys.length <= badgesPerLine * maxLines) {
      return {
        visibleBadges: badgeKeys,
        hiddenBadges: [],
      };
    }
    const maxVisible = badgesPerLine * maxLines - 1; // Save space for +N badge
    return {
      visibleBadges: badgeKeys.slice(0, maxVisible),
      hiddenBadges: badgeKeys.slice(maxVisible),
    };
  }, [containerWidth, badgeCountsByType]);

  return { visibleBadges, hiddenBadges };
};

export default useVisibleBadges;
