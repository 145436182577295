import { useCallback, useMemo, useRef, useState } from 'react';
import styled from '@emotion/styled';

import useCalculateContainerDimension from 'hooks/useCalculateContainerDimension';
import useGetRundownAssets from 'hooks/useGetRundownAssets';
import { HStack } from 'layouts/box/Box';
import { type Asset as AssetType } from 'types';
import AssetTypes from 'utils/constants/assetTypes';

import Group, { Empty } from '../Group';
import Asset, { AssetSkeleton } from '../story/Asset';

const ITEM_SIZE = 92;
const ITEM_GAP = 8;

const Wrapper = styled(HStack)<{ $collapsed?: boolean }>`
  height: ${({ $collapsed }) => ($collapsed ? '96px' : 'auto')};
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding-inline: 12px 12px;
  gap: 8px;
`;

const assetTypes = [AssetTypes.VIDEO, AssetTypes.IMAGE];

function Assets({
  rundownId,
  isInstanceLoading,
}: Readonly<{ rundownId: string; isInstanceLoading: boolean }>) {
  const container = useRef(null);
  const size = useCalculateContainerDimension(container);

  const [collapsed, setCollapsed] = useState(true);
  const { assets, loading } = useGetRundownAssets(rundownId, assetTypes);

  const toggleCollapsed = useCallback(() => setCollapsed((prev) => !prev), [setCollapsed]);
  const moreCount = useMemo(
    () =>
      Math.max(assets?.length - Math.floor((size.width + ITEM_GAP) / (ITEM_SIZE + ITEM_GAP)), 0),
    [assets?.length, size.width],
  );

  return (
    <div ref={container}>
      <Group
        label="Assets"
        moreCount={moreCount}
        collapsed={moreCount > 0 ? collapsed : undefined}
        toggleCollapsed={toggleCollapsed}
        collapsedHeight={ITEM_SIZE + 2}
      >
        {assets?.length === 0 && !loading && !isInstanceLoading ? (
          <Empty label="Assets" />
        ) : (
          <Wrapper $collapsed={collapsed}>
            {loading || isInstanceLoading
              ? Array(5)
                  .fill(null)
                  .map((_, index) => <AssetSkeleton key={index} />)
              : assets?.map((asset: AssetType) => <Asset asset={asset} key={asset?.mRefId} />)}
          </Wrapper>
        )}
      </Group>
    </div>
  );
}

export default Assets;
