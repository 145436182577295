import { DocumentType } from '@apollo/client';
import { events } from 'aws-amplify/data';
import { fromUint8Array, toUint8Array } from 'js-base64';

import { getDummyUser } from 'store/members';
import { Mutable, User } from 'types';
import { CustomEventData } from 'types/customChannel';
import { SessionUser } from 'types/sessionUser';

import type {
  CustomEvent,
  CustomEventHandler,
  CustomEventHandlerMap,
  Participant,
  SharingEvent,
} from './types';

export function getUserIdFromParticipant(participant: Participant<unknown>): string {
  return participant.userId;
}

export function getSessionUser(
  participant: Participant<unknown>,
  user: User | undefined,
): SessionUser {
  return Object.freeze({
    ...(user ?? getDummyUser(participant.userId)),
    sessionId: participant.sessionId,
    status: isNaN(participant.hiddenTime) ? 'visible' : 'hidden',
  });
}

export function broadcastEvent<T>(channelNamespace: string, event: SharingEvent<T>) {
  events
    .post(channelNamespace, event as unknown as DocumentType)
    // eslint-disable-next-line no-console
    .catch((error) => console.error('failed publishing', error));
}

export function unicastEvent<T>(
  channelNamespace: string,
  event: SharingEvent<T>,
  receiverId: string,
) {
  broadcastEvent(`${channelNamespace}/${receiverId}`, event);
}

export function isTestEventData<T>(x: unknown): x is { event: SharingEvent<T> } {
  return (
    !!x &&
    typeof x === 'object' &&
    'event' in x &&
    !!x.event &&
    typeof x.event === 'object' &&
    'type' in x.event &&
    typeof x.event.type === 'string'
  );
}

export function createCustomEvent(
  sourceId: string,
  customType: string,
  data?: CustomEventData,
  eventId?: string,
): CustomEvent {
  const result: Mutable<CustomEvent> = { type: 'custom', sourceId, customType };
  if (eventId !== undefined) result.eventId = eventId;
  if (data !== undefined) {
    const dataIsUint8Array = data instanceof Uint8Array;
    result.customData = dataIsUint8Array ? fromUint8Array(data) : data;
    result.dataIsUint8Array = dataIsUint8Array;
  }
  return result;
}

function removeCustomEventHandler(
  map: CustomEventHandlerMap,
  type: string,
  handler: CustomEventHandler,
) {
  if (!(type in map)) return;
  const handlers = map[type];
  const pos = handlers.indexOf(handler);
  if (pos < 0) return;
  handlers.splice(pos, 1);
  if (handlers.length === 0) delete map[type];
}

export function addCustomEventHandler(
  map: CustomEventHandlerMap,
  type: string,
  handler: CustomEventHandler,
) {
  if (!(type in map)) map[type] = [handler];
  else map[type].push(handler);
  return () => removeCustomEventHandler(map, type, handler);
}

export function handleCustomEvent(
  map: CustomEventHandlerMap,
  { customType: type, sourceId, customData, dataIsUint8Array }: CustomEvent,
) {
  if (!(type in map)) return;
  const data = dataIsUint8Array ? toUint8Array(customData as string) : customData;
  map[type].forEach((handler) => handler(sourceId, type, data));
}

let sessionIndex = 0;
export function getNextSessionIndex() {
  if (sessionIndex >= 100000000000) sessionIndex = 0; // We cannot use more than 13 chars
  sessionIndex += 1;
  return sessionIndex;
}

export function getSessionIdFromParticipant(participant: Participant<unknown>) {
  return participant.sessionId;
}
