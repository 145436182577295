import { Alternative } from 'types/graphqlTypes';

export const serviceOptions: Alternative[] = [
  {
    id: 'None',
    label: 'None',
    value: 'None',
  },
  {
    id: 'facebook',
    label: 'Facebook',
    value: 'Facebook',
    icon: 'facebook',
  },
  {
    id: 'twitter',
    label: 'Twitter',
    value: 'Twitter',
    icon: 'twitter',
  },
  {
    id: 'cms',
    label: 'CMS',
    value: 'cms',
    icon: 'cms',
  },
  {
    id: 'youtube',
    label: 'Youtube',
    value: 'youtube',
    icon: 'youtube',
  },
];
