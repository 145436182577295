import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ListSubheader, List } from '@material-ui/core';
import EllipsisButton from 'components/buttons/ellipsisButton';
import Popover from 'components/popover';
import { ReactComponent as Padlock } from 'assets/icons/systemicons/padlock_off.svg';
import { ReactComponent as Delete } from 'assets/icons/systemicons/delete.svg';
import { ReactComponent as History } from 'assets/icons/systemicons/time.svg';
import { ReactComponent as Copy } from 'assets/icons/systemicons/copy.svg';
import Divider from 'components/divider';
import InstanceVersionHistory from 'features/versionHistory';
import FoldersMenuList from '../foldersMenuList';
import MenuItem from '../menuItem';
import useStyles from './general-ellipsis-menu-styles';
import useCopyText from 'hooks/useCopyText';
import { createInstanceLink } from 'utils/instance/createInstanceLink';
import { useInstanceMolecule } from 'features/instance/store/instance';
import { DeleteInstanceDialog } from '../dialogs/DeleteInstanceDialog';

const topRight = {
  vertical: 'top',
  horizontal: 'right',
};

const centerLeft = {
  vertical: 'center',
  horizontal: 'left',
};

function GeneralMenuList({ storyId, instanceId }) {
  const classes = useStyles();
  const { copyTooltip, onCopy } = useCopyText('Copy instance link');

  const onCopyClick = () => {
    if (!storyId || !instanceId) return;
    const textToCopy = createInstanceLink(storyId, instanceId);
    onCopy(textToCopy, 'Link copied!');
  };
  return (
    <List disablePadding classes={{ root: classes.menuItem }}>
      <ListSubheader classes={{ root: classes.listSubheader }}>GENERAL</ListSubheader>
      <MenuItem
        label="Copy Link"
        title={copyTooltip}
        showArrow={false}
        icon={<Copy />}
        onClick={onCopyClick}
        disabled={!storyId || !instanceId}
      />
    </List>
  );
}

const GeneralEllipsisMenu = ({
  isDeleteEnabled,
  onDeleteInstance,
  onForceUnlock,
  isLockedByAnotherUser,
  lockedByUser,
  disableEdit,
  title,
  instanceId,
  onRestoreVersion,
  checkVersionRestorability,
  isSavingContent,
  canDeleteInstance,
  canUpdateInstance,
  folders,
  canCreateNewTemplate,
  canDeleteTemplateFolder,
  canDeleteTemplate,
  canPinTemplateFolder,
  canReorderTemplates,
  onDeleteFolder,
  onSaveTemplate,
  onSelectTemplate,
  onDeleteTemplate,
  onCreateFolder,
  hideTemplateOptions,
  canSetDefaultTemplate,
  defaultTemplateRefId,
  onSetDefaultTemplate,
  isContentLoaded,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [deleteInstanceOpen, setDeleteInstanceOpen] = useState(false);
  const { useInstanceValue } = useInstanceMolecule();
  const instance = useInstanceValue();

  const [anchor, setAnchor] = useState(null);
  const [popoverComponent, setPopoverComponent] = useState(null);
  const [historyDialogOpen, setHistoryDialogOpen] = useState(false);

  const handleOpenPopover = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClosePopover = () => {
    setAnchor(null);
    setPopoverComponent(null);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const handleForceUnlock = () => {
    closeMenu();
    onForceUnlock();
  };

  const handleDelete = () => {
    setDeleteInstanceOpen(false);
    handleClosePopover();
    closeMenu();
    onDeleteInstance();
  };

  const handleHistory = async () => {
    setHistoryDialogOpen(true);
  };

  const handleRestoreVersion = async (content) => {
    await onRestoreVersion(content);
    setHistoryDialogOpen(false);
    setAnchorEl(null);
  };

  const OpenDelete = () => {
    setDeleteInstanceOpen(true);
  };

  const disableEditOrFalse = disableEdit || false;

  const moreOptions = [
    {
      icon: <History />,
      label: 'History',
      callback: handleHistory,
      disabled: !canUpdateInstance,
    },
    {
      icon: <Padlock />,
      label: 'Force unlock',
      callback: handleForceUnlock,
      disabled: disableEditOrFalse,
    },
    {
      icon: <Delete />,
      label: 'Delete Instance',
      callback: OpenDelete,
      disabled: disableEditOrFalse || !canDeleteInstance,
    },
  ];

  const MoreOptionsMenuList = () => (
    <List disablePadding classes={{ root: classes.menuItem }}>
      <ListSubheader classes={{ root: classes.listSubheader }}>MORE OPTIONS</ListSubheader>
      {moreOptions.map(({ icon, label, callback, disabled }) => (
        <MenuItem
          key={label}
          icon={icon}
          label={label}
          onClick={callback}
          showSecondaryItem={false}
          disabled={disabled}
        />
      ))}

      <Popover
        anchorEl={anchor}
        anchorOrigin={centerLeft}
        transformOrigin={topRight}
        onClose={handleClosePopover}
      >
        <div className={classes.popover}>{popoverComponent}</div>
      </Popover>
    </List>
  );

  return (
    <div>
      {historyDialogOpen && (
        <InstanceVersionHistory
          title={title}
          id={instanceId}
          open={historyDialogOpen}
          onCancel={() => setHistoryDialogOpen(false)}
          onOk={handleRestoreVersion}
          checkVersionRestorability={checkVersionRestorability}
          lockedByUser={lockedByUser}
          isSavingContent={isSavingContent}
        />
      )}
      <div>
        <EllipsisButton onClick={handleOpenPopover} />
        <Popover
          anchorEl={anchorEl}
          onClose={closeMenu}
          anchorOrigin={topRight}
          transformOrigin={topRight}
        >
          <div className={classes.menu}>
            <GeneralMenuList storyId={instance?.mStoryId} instanceId={instance?.mId} />
            {!hideTemplateOptions && (
              <>
                <Divider className={classes.divider} />
                <FoldersMenuList
                  anchorEl={anchorEl}
                  folders={folders}
                  canCreateNewTemplate={canCreateNewTemplate}
                  canDeleteTemplateFolder={canDeleteTemplateFolder}
                  canDeleteTemplate={canDeleteTemplate}
                  canPinTemplateFolder={canPinTemplateFolder}
                  canReorderTemplates={canReorderTemplates}
                  isLockedByAnotherUser={isLockedByAnotherUser}
                  onDeleteFolder={onDeleteFolder}
                  closeMenu={closeMenu}
                  onSaveTemplate={onSaveTemplate}
                  onSelectTemplate={onSelectTemplate}
                  onDeleteTemplate={onDeleteTemplate}
                  onCreateFolder={onCreateFolder}
                  canSetDefaultTemplate={canSetDefaultTemplate}
                  defaultTemplateRefId={defaultTemplateRefId}
                  onSetDefaultTemplate={onSetDefaultTemplate}
                  isContentLoaded={isContentLoaded}
                />
              </>
            )}
            <>
              <Divider className={classes.divider} />
              <MoreOptionsMenuList />
            </>
          </div>
        </Popover>
      </div>
      <DeleteInstanceDialog
        open={deleteInstanceOpen}
        onClose={() => setDeleteInstanceOpen(false)}
        onDelete={handleDelete}
        canDelete={!isDeleteEnabled}
      />
    </div>
  );
};

GeneralEllipsisMenu.propTypes = {
  /** Reference to the container element */
  containerRef: PropTypes.shape({ current: PropTypes.shape({}) }),
  /** callback to forcefully unlock the instance */
  onForceUnlock: PropTypes.func,
  /** Boolean that disables edit */
  disableEdit: PropTypes.bool,
  canPinTemplateFolder: PropTypes.bool,
  canReorderTemplates: PropTypes.bool,
  canSetDefaultTemplate: PropTypes.bool,
  onSetDefaultTemplate: PropTypes.func,
  defaultTemplateRefId: PropTypes.string,
  instanceId: PropTypes.string,
  storyId: PropTypes.string,
};

GeneralEllipsisMenu.defaultProps = {
  containerRef: { current: null },
  onForceUnlock: () => {},
  disableEdit: false,
};

export default GeneralEllipsisMenu;
