import {
  alternativesAreDeeplyEqual,
  TreeNode,
  treesAreEqual,
} from 'components/editMdfDialog/utils';
import {
  ChoiceOptionList,
  OptionList,
  OptionListBase,
  TreeChoiceOptionList,
} from 'types/graphqlTypes';

import { OptionListChangeInfo, OptionListChangeInfoMap } from '../../atomsTs';

import {
  createLabelValidator,
  MdfItemCreationInfoMapper,
  MdfItemInfoMapper,
} from './mdfItemHelper';

/**
 * Updates an {@link OptionListChangeInfoMap} with an incoming change notification from an option
 * list/tree label editor.
 * Will remove the option list from change map if there are no changes from original and if there
 * are changes it will ensure that the option list is mentioned in the map and has its
 * `labelChanged` set to `true` if label is changed and `false` otherwise.
 * @param prevState      The previous state of the change info map
 * @param label          The new label for the option list/tree
 * @param origOptionList The original option list/tree as retrieved from the server
 * @returns              The updated change info map.
 */
export function updateOptionListLabel(
  prevState: OptionListChangeInfoMap,
  label: string,
  origOptionList: OptionList,
): OptionListChangeInfoMap {
  const listId = origOptionList.id;
  const labelChanged = label !== origOptionList.label;
  const prevChangeInfo: OptionListChangeInfo | undefined = prevState[listId];
  const optionsChanged = prevChangeInfo?.optionsChanged;
  if (!optionsChanged && !labelChanged) {
    const updatedChangeMap = { ...prevState };
    delete updatedChangeMap[listId];
    return updatedChangeMap;
  } else if (!prevChangeInfo) {
    return {
      ...prevState,
      [listId]: { item: { ...origOptionList, label }, labelChanged: true, optionsChanged: false },
    };
  } else {
    return {
      ...prevState,
      [listId]: {
        ...prevChangeInfo,
        item: { ...prevChangeInfo.item, label },
        labelChanged,
      },
    };
  }
}

/**
 * Updates an {@link OptionListChangeInfoMap} with an incoming change notification from an option
 * list editor.
 * Will remove the option list from change map if there are no changes from original and if there
 * are changes it will ensure that the option list is mentioned in the map and has its
 * `optionsChanged` set to `true` if the options differs from the original options and `false`
 *  otherwise.
 * @param prevState    The previous state of the change info map
 * @param list         The updated option list
 * @param originalList The original option list as retrieved from the back-end
 * @returns            The updated change info map.
 */
export function updateOptionListOptions(
  prevState: OptionListChangeInfoMap,
  list: ChoiceOptionList,
  originalList: ChoiceOptionList,
): OptionListChangeInfoMap {
  if (!alternativesAreDeeplyEqual(list.alternatives, originalList.alternatives)) {
    const labelChanged = !!prevState[list.id]?.labelChanged;
    const label = prevState[list.id]?.item.label;
    return {
      ...prevState,
      [list.id]: {
        item: labelChanged ? { ...list, label } : list,
        optionsChanged: true,
        labelChanged,
      },
    };
  } else {
    if (!prevState[list.id]?.optionsChanged) return prevState;
    const newState = { ...prevState };
    if (!newState[list.id].labelChanged) {
      delete newState[list.id];
      return newState;
    }
    const changeInfo = { ...newState[list.id] };
    changeInfo.item = { ...originalList, label: prevState[list.id].item.label };
    changeInfo.optionsChanged = false;
    newState[list.id] = changeInfo;
    return newState;
  }
}

/**
 * Updates an {@link OptionListChangeInfoMap} with an incoming change notification from an option
 * tree editor.
 * Will remove the option list from change map if there are no changes from original and if there
 * are changes it will ensure that the option list is mentioned in the map and has its
 * `optionsChanged` set to `true`if the options differs from the original options and `false`
 *  otherwise.
 * @param prevState       The previous state of the change info map
 * @param updatedOptions  The updated tree options
 * @param originalOptions The original tree options as created from the `treeAlternatives` of the
 *                        as retrieved from the back-end.
 * @param originalList    The original option tree as retrieved from the back-end
 * @returns               The updated change info map.
 */
export function updateOptionTreeOptions(
  prevState: OptionListChangeInfoMap,
  updatedOptions: readonly TreeNode[],
  originalOptions: readonly TreeNode[],
  originalList: TreeChoiceOptionList,
): OptionListChangeInfoMap {
  if (!treesAreEqual(updatedOptions, originalOptions)) {
    const labelChanged = !!prevState[originalList.id]?.labelChanged;
    const label = prevState[originalList.id]?.item.label;
    return {
      ...prevState,
      [originalList.id]: {
        item: labelChanged ? { ...originalList, label } : originalList,
        optionsChanged: true,
        treeOptions: updatedOptions,
        labelChanged,
      },
    };
  } else {
    if (!prevState[originalList.id]?.optionsChanged) return prevState;
    const newState = { ...prevState };
    if (!newState[originalList.id].labelChanged) {
      delete newState[originalList.id];
      return newState;
    }
    const changeInfo = { ...newState[originalList.id] };
    changeInfo.item = { ...originalList, label: prevState[originalList.id].item.label };
    changeInfo.optionsChanged = false;
    delete changeInfo.treeOptions;
    newState[originalList.id] = changeInfo;
    return newState;
  }
}

export const OPTIONS_DISPLAY_TEXTS: Readonly<
  Record<OptionListBase['optionListType'], [string, string]>
> = Object.freeze({
  choice: ['option list', 'an option list'],
  treechoice: ['option tree', 'an option tree'],
});

const infoTexts: Readonly<Record<OptionListBase['optionListType'], string>> = Object.freeze({
  choice:
    // eslint-disable-next-line max-len
    'Option lists contain options that can be shared between many Choice and Multi-choice fields across schemas',
  treechoice:
    // eslint-disable-next-line max-len
    'Option trees contain hierarchic options that can be shared between many Tree-choice fields across schemas.',
});

function getCategoryTypeText(category: OptionListBase['optionListType'], indefinite: boolean) {
  return OPTIONS_DISPLAY_TEXTS[category][indefinite ? 1 : 0];
}

function getItemTypeText(options: OptionListBase, indefinite: boolean): string {
  return OPTIONS_DISPLAY_TEXTS[options.optionListType][indefinite ? 1 : 0];
}

function getChangeInfoItem(info: OptionListChangeInfo) {
  return info.item;
}

export const OPTIONS_INFO_MAPPER_EX: MdfItemCreationInfoMapper<
  OptionList,
  OptionListChangeInfo,
  OptionListBase['optionListType']
> = {
  getItemTypeText,
  getChangeInfoItem,
  getCategoryTypeText,
  infoTexts,
};

export const OPTIONS_INFO_MAPPER: MdfItemInfoMapper<OptionList, OptionListChangeInfo> =
  OPTIONS_INFO_MAPPER_EX;

/**
 * Creates a function that can be used to validate the label of an option list/tree
 * @param allOptionLists     The option lists/trees in the system as retrieved from the back-end
 * @param changedOptionLists The option lists/trees that have been changed but not yet saved
 * @param type               The type of the option list to check
 * @param originalLabel      The original label
 * @returns                  The validator function that receives a label and returns an error
 *                           message text if not OK or `true` if ok.
 */
export function createOptionListLabelValidator(
  allOptionLists: readonly OptionList[],
  changedOptionLists: OptionListChangeInfoMap,
  type: OptionListBase['optionListType'],
  originalLabel: string,
): (label: string) => string | boolean {
  return createLabelValidator(
    allOptionLists,
    changedOptionLists,
    originalLabel,
    OPTIONS_DISPLAY_TEXTS[type][0],
    OPTIONS_INFO_MAPPER,
  );
}
