import { memo } from 'react';

import type CellProps from '../cellProps';

import ChoiceField from './ChoiceField';

/**
 * This Cell should only be rendered for editing purposes.
 */
function ChoiceCell({
  fieldModel,
  fieldSettings,
  setValue,
  value,
  onBlur,
  onFocus,
}: Readonly<CellProps>) {
  return (
    <ChoiceField
      cellMode
      autoFocus
      hideLabel
      fieldModel={fieldModel}
      defaultFieldSettings={fieldSettings!}
      fieldSettings={fieldSettings}
      value={value}
      setValue={setValue}
      view="default"
      onBlur={onBlur}
      onFocus={onFocus}
    />
  );
}

export default memo(ChoiceCell);
