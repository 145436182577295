import { ActiveRundownInstance } from 'types/graphqlTypes';

import respectHostReadSpeed from './respectHostReadSpeed';
import { getRundownPlayState, RundownPlayStates, RundownPlayStatesEnum } from './rundownStates';
import { isFloated, LinearProvider, RundownInstance, RundownSequence } from './rundownTypes';
import { getMetadataValue, toNumber } from './typeUtils';
import { DateToTimeString, TimeStringToDate, timeToSeconds } from './updateDuration';

const isValidTime = (value?: string | null): boolean => {
  // A valid time must be a non-null, non-undefined string that does not start with '-'
  return value != null && !value.startsWith('-');
};

export const RundownTimingFieldKeys = {
  BACK_TIME: 'back-time',
  CLIP_DURATION: '05-clip-duration',
  CUME_TIME: 'cume-time',
  HOST_READ_SPEED: 'hostReadSpeed',
  IS_FLOAT: '10-isFloat',
  ORDER: '11-order',
  PLANNED_DURATION: '06-planned-duration',
  SPEAK_DURATION: '08-speak-duration',
  TOTAL_DURATION: '07-total-duration',
  WORD_COUNT: '12-word-count',
  LINE_STYLE: 'rundownLineStyles',
  PRESENTER_ID: 'presenterId',
} as const;

export type RundownTimingFieldKey = keyof typeof RundownTimingFieldKeys;

const getInstanceFieldTime = (instance: RundownInstance, key: string): Date | undefined => {
  if (!instance?.mMetaData) return undefined; // Handle undefined or null `mMetaData`

  // Find the field with the matching key
  const field = instance.mMetaData.find((f) => f.key === key);
  if (!field?.value) return undefined; // Handle missing or null value

  const valueString = String(field.value); // Convert value to string
  return isValidTime(valueString) ? TimeStringToDate(valueString) : undefined;
};

/**
 * Returns the cumetime of the instance if it exists and is valid, otherwise returns undefined.
 * Instances stores hard-hit cumetimes in key/value metadata with key='cume-time'
 * @param instance - The instance
 * @returns Hard-hit cumetime of the instance if it exists.
 */
export const getInstanceCumeTime = (instance: RundownInstance) =>
  getInstanceFieldTime(instance, RundownTimingFieldKeys.CUME_TIME);

/**
 * Returns the backtime of the instance if it exists and is valid, otherwise returns undefined.
 * Instances stores hard-hit backtimes in key/value metadata with key='back-time'
 * @param instance - The instance
 * @returns Hard-hit backtime of the instance if it exists.
 */
export const getInstanceBackTime = (instance: RundownInstance) =>
  getInstanceFieldTime(instance, RundownTimingFieldKeys.BACK_TIME);

const ensureTimeOfToday = (date: Date): Date => {
  const todaysDate = new Date();
  todaysDate.setHours(date.getHours());
  todaysDate.setMinutes(date.getMinutes());
  todaysDate.setSeconds(date.getSeconds());
  todaysDate.setMilliseconds(date.getMilliseconds());
  return todaysDate;
};

/**
 * Makes a prioritized list of fields to use when calculating total duration of the rundown.
 * The first field that has a valid duration will be used.
 * @param csvFields {string} - Comma separated string of keys in key/value metadata to use.
 * @returns {string[]} - List of keys to use. Defaults to total-duration
 */
export const getTotalDurationFields = (csvFields: string | undefined) => {
  const fields = csvFields?.split(',');
  if (!Array.isArray(fields) || fields.length === 0) return [RundownTimingFieldKeys.TOTAL_DURATION];
  return fields.map((f) => (f ? f.trim() : RundownTimingFieldKeys.TOTAL_DURATION));
};

/**
 * Rundown configuration used when creating the rundown timing information for a rundown
 */
export interface RundownTimingConfig {
  /**
   * Indicates whether the rundown timing should be based on duration
   * (relative timing) or absolute times.
   */
  useDurationBasedTiming?: boolean;
  /**
   * Rundown host read speed in words / minute
   */
  hostReadSpeed?: number;
  /**
   * Comma separated list of fields to use when calculating total duration of the rundown.
   * The first field that has a valid duration will be used.
   */
  totalDurationFields?: string;
  /**
   * If set will mark aired instances in Ux. Only used when playing the rundown.
   */
  markAiredInstances?: boolean;
  /**
   * If set will set the cume time to the instance start time when the instance has been aired.
   * This will only be used when the rundown is playing.
   */
  updateCumetimeOnTimelineMove?: boolean;
}

/**
 * Represents timing information for a rundown.
 * This includes all information passed as part of RundownTimingConfig
 * Currently seperated due to type difference in totalDurationFields
 */
export interface RundownTimingInfo {
  /** The planned start time of the rundown as specified in the rundown header. */
  inTime: Date;
  /** The planned end time of the rundown, calculated as `inTime` + `plannedDuration`. */
  outTime: Date;
  /** The planned duration of the rundown in milliseconds. */
  plannedDuration: number;
  /** The current playback state of the rundown. */
  state: RundownPlayStates;
  /**
   * Indicates whether the rundown timing should be duration based (relative timing)
   * or absolute times. Note that this is mostly used in rundown planning mode.
   */
  useDurationBasedTiming: boolean;
  /**
   * If set will mark aired instances in Ux. Only used when playing the rundown.
   */
  markAiredInstances: boolean;
  /**
   * If set will set the cume time to the instance start time when the instance has been aired.
   * Only be used when the rundown is playing.
   */
  updateCumetimeOnTimelineMove?: boolean;
  /**
   * Rundown host read speed in words / minute
   */
  hostReadSpeed: number;
  /**
   * List of fields to use when calculating total duration of the rundown.
   * The first field that has a valid duration will be used.
   */
  totalDurationFields: string[];
  /**
   * The actual start time of the rundown when it is being played.
   * Null if the rundown is not currently playing.
   */
  startTime?: Date;
  /**
   * Tentative end time of the rundown when it is being played.
   * Equals startTime + plannedDuration.
   * Null if the rundown is not currently playing.
   */
  endTime?: Date;
  /**
   * The identifier of the currently active instance within the rundown.
   * Null if no instance is currently active.
   */
  currentInstance?: ActiveRundownInstance;
  /** The time zone associated with the rundown timing information. */
  timeZone?: string;
}

/**
 * Calculates and returns the rundown timing information for a given rundown sequence and instance.
 * @param {RundownSequence} sequence
 *  - The rundown sequence containing metadata such as publishing time, duration, and play state.
 *    A rundown sequence represents either the ready or preparing lists within the rundown.
 * @param {UpdateInstanceInput} firstInstance
 *  - The first instance of the rundown, used to calculate initial timing values.
 * @param {boolean} useDurationBasedTiming
 *  - A flag to determine whether relative timing should be applied.
 * @returns {RundownTimingInfo} - The calculated timing information for the rundown.
 */
export const getRundownTimes = (
  sequence: RundownSequence,
  firstInstance: RundownInstance,
  config: RundownTimingConfig,
): RundownTimingInfo => {
  const ct0 = getInstanceCumeTime(firstInstance);
  const bt0 = getInstanceBackTime(firstInstance);

  const { publishingAt, plannedDuration, playState = {} } = sequence;
  const { startTime, currentInstance, state = RundownPlayStatesEnum.UNKNOWN } = playState;

  const inTime =
    ct0 || bt0 || ensureTimeOfToday(publishingAt ? new Date(publishingAt) : new Date());

  const tStart = startTime ? new Date(startTime) : undefined;

  return {
    inTime,
    outTime: new Date(inTime.getTime() + plannedDuration),
    plannedDuration,
    state: getRundownPlayState(state),
    useDurationBasedTiming: !!config.useDurationBasedTiming,
    markAiredInstances: !!config.markAiredInstances,
    updateCumetimeOnTimelineMove: !!config.updateCumetimeOnTimelineMove,
    hostReadSpeed: toNumber(config.hostReadSpeed),
    totalDurationFields: getTotalDurationFields(config.totalDurationFields),
    timeZone: sequence.timeZone,
    ...(tStart != null && {
      startTime: tStart,
      endTime: new Date(tStart.getTime() + plannedDuration),
    }),
    ...(currentInstance != null && { currentInstance }),
  };
};
/**
 * Returns the duration to be used in rundown calculations for an instance.
 * Ensures that the instance script timing is updated according to current hostReadSpeed
 * Picks the prioritized instance timing fields to use when calculating duration.
 * Typically, either total-duration or planned-duration.
 * @param instance {RundownInstance} - The linear instance to fetch duration from.
 * @param rundownTimes {RundownTimingInfo} - The rundown timing information
 * @returns {number} - The duration of the instance in seconds. 0 if no duration is found.
 */
export const getInstanceDuration = (instance: RundownInstance, rundownTimes: RundownTimingInfo) => {
  const { mMetaData = [] } = instance;
  const { totalDurationFields, hostReadSpeed } = rundownTimes;

  const metadata = respectHostReadSpeed(mMetaData, hostReadSpeed);

  for (const tf of totalDurationFields) {
    const tdur = timeToSeconds(getMetadataValue(metadata, tf));
    if (tdur) return tdur;
  }
  return 0;
};

/**
 * Represents timing information for a rundown instance.
 * Used when calculating cumetime and backtime for a given instance
 * as part of a rundown sequence.
 */
export interface RundownInstanceTiming {
  /** Rundown instance id */
  mId: string;
  /** Rundown instance title */
  mTitle: string;
  /** Rundown instance play state: Typically [READY, PLAY, STOP] */
  state: RundownPlayStates;
  /** Rundown instance start time. Only available after aired. ISO formatted */
  startTime?: string;
  /** Rundown instance stop time. Only available after been onair. ISO formatted */
  stopTime?: string;
  /** Rundown instance duration in seconds */
  dur: number;
  /** Accumulated rest duration from last hard-hit backtime or rundown end. In seconds */
  segmentRestDuration: number;
  /** String used to display cumeTime. "hh:mm:ss" formatted */
  cumeTime: string;
  /** String used to display backTime. "hh:mm:ss" formatted */
  backTime: string;
  /** Number field used for cumeTime. In milliseconds since Unix epoch. */
  cumeTimeMs?: number;
  /** Number field used for backTime. In milliseconds since Unix epoch. */
  backTimeMs?: number;
  /** True if the instance has been onair */
  isAired: boolean;
  /** True if cume time contains a hard-hit time. */
  isCumeTimeAbsolute: boolean;
  /** True if back time contains a hard-hit time. */
  isBackTimeAbsolute: boolean;
  /** True if cume time contains marked hit time (ux). */
  isCumeTimeFixed: boolean;
  /** True if back time contains marked hit time (ux). */
  isBackTimeFixed: boolean;
  /** True if the rundown instance is the current instance onair */
  isCurrentInstance: boolean;
  /** True if the rundown instance is floated */
  isFloat: boolean;
  /** True if the rundown instance startTime shall be shown in the UX */
  showAiredTime: boolean;
}

const oneDayInMs = 24 * 60 * 60 * 1000;

/**
 * Threshold for shifting a time to the next day relative to the rundown start.
 * Times that are more than 18 hours before the start time
 * should be considered as belonging to the next day.
 * Since times are stored in rundown local time (00:00:00 to 24:00:00),
 * only times after 18:00 (6 PM) will be affected.
 */
const nextDayLimitMs = 18 * 60 * 60 * 1000;

/**
 * Calculates the rundown time properties for an instance,
 * adjusting it based on the rundown's timing information.
 *
 * @param {Date} itime - The instance time to calculate.
 * @param {RundownTimingInfo} rundownTimes - The rundown timing information.
 * @param {Date} rundownTimes.startTime - The start time of the rundown.
 * @returns {number} - The adjusted instance time as a timestamp (milliseconds since Unix epoch).
 */
export const getInstanceRundownTime = (itime: Date, rundownTimes: RundownTimingInfo) => {
  const tInstance = itime.getTime();

  const { startTime } = rundownTimes;
  if (!startTime) return tInstance;

  const tStart = startTime.getTime();

  // If the instance time is on or after the start time, return as-is
  if (tInstance >= tStart) return tInstance;

  // If the instance time is more than the threshold before the start time, move it to the next day
  return tStart - tInstance > nextDayLimitMs ? tInstance + oneDayInMs : tInstance;
};

/**
 * Returns the timing information for a rundown instance.
 * @param instance {RundownInstance}
 *   - The linear/rundown instance to extract timing information from.
 * @returns {RundownInstanceTiming} - Initial timing information from the instance.
 */
export const getRundownInstanceTiming = (
  instance: RundownInstance,
  rundownTimes: RundownTimingInfo,
): RundownInstanceTiming => {
  const { mId, mTitle, mProperties: { provider = {} } = {} } = instance || {};
  const { state, startTime, stopTime } = provider || ({} as LinearProvider);

  const timingFields: RundownInstanceTiming = {
    mTitle,
    mId,
    state: state ?? RundownPlayStatesEnum.UNKNOWN,
    startTime,
    stopTime,
    dur: 0,
    segmentRestDuration: 0,
    cumeTime: '-',
    backTime: '-',
    isAired: false,
    isCumeTimeAbsolute: false,
    isBackTimeAbsolute: false,
    isCumeTimeFixed: false,
    isBackTimeFixed: false,
    isCurrentInstance: false,
    isFloat: isFloated(instance),
    showAiredTime: true,
  };

  const cumeTime = getInstanceCumeTime(instance);
  if (cumeTime) {
    timingFields.cumeTimeMs = getInstanceRundownTime(cumeTime, rundownTimes);
    timingFields.cumeTime = DateToTimeString(timingFields.cumeTimeMs);
    timingFields.isCumeTimeAbsolute = true;
  }

  const backTime = getInstanceBackTime(instance);
  if (backTime) {
    timingFields.backTimeMs = getInstanceRundownTime(backTime, rundownTimes);
    timingFields.backTime = DateToTimeString(timingFields.backTimeMs);
    timingFields.isBackTimeAbsolute = true;
  }

  return timingFields;
};
