import { useState } from 'react';
import { ClickAwayListener, Tooltip } from '@material-ui/core';

import { ReactComponent as Edit } from 'assets/icons/systemicons/edit.svg';
import { IconButton } from 'components/buttons';
import Checkbox from 'components/checkbox';
import { StyledFormControl } from 'components/command/toolbar/styled';
import { CloseIcon } from 'components/orderFormDialog/styled';
import Popper from 'components/shared/popper';
import { Box, HStack, VStack } from 'layouts/box/Box';
import { PlatformAccountSettings, PlatformDTOSettings } from 'types/graphqlTypes';

import { TypesSelector } from '../../integrations/styled';
import { AddLocalAccountDialogue } from '../dialogs/AddLocalAccountDialog';

interface AccountSelectorProps {
  selectedAccounts: PlatformAccountSettings[];
  toggleAccounts: (acc: PlatformAccountSettings) => void;
  selectedLabel: string;
  platformData: PlatformDTOSettings;
  onUpdatePlatform: (val: {}) => void;
}

export const AccountSelector: React.FC<AccountSelectorProps> = ({
  selectedAccounts,
  toggleAccounts,
  selectedLabel,
  platformData,
  onUpdatePlatform,
}) => {
  const [anchor, setAnchor] = useState<EventTarget | null>(null);
  const [showEditAccountsDialog, setShowEditAccountsDialog] = useState(false);

  return (
    <HStack>
      <IconButton
        usage="text"
        size={24}
        iconSize={20}
        onClick={() => setShowEditAccountsDialog(true)}
        title="Configure endpoint"
      >
        <Edit />
      </IconButton>
      <Tooltip title={selectedLabel}>
        <TypesSelector
          container
          padding="4px"
          height="32px"
          maxWidth="200px"
          borderRadius="4px"
          overflow="hidden"
          justifyContent="flex-start"
          onClick={(ev) => {
            if (selectedAccounts.length === 0) {
              return;
            } else setAnchor(ev.currentTarget);
          }}
        >
          {selectedLabel}
        </TypesSelector>
      </Tooltip>
      <Popper anchorEl={anchor} position="bottom">
        <ClickAwayListener onClickAway={() => setAnchor(null)}>
          <Box padding="5px 15px 5px 5px">
            <VStack>
              {selectedAccounts?.map((acc) => {
                return (
                  <div key={acc.accountTitle}>
                    <StyledFormControl
                      control={
                        <Checkbox
                          onClick={() => {
                            toggleAccounts(acc);
                          }}
                          selected={selectedAccounts.some(
                            (a) => a.accountTitle === acc.accountTitle && a.isActive,
                          )}
                          disabled={!acc.isStaticAccount}
                        />
                      }
                      label={acc.accountTitle}
                    />
                  </div>
                );
              })}
            </VStack>
            <CloseIcon
              style={{ position: 'absolute', top: '3px', right: '3px' }}
              onClick={() => setAnchor(null)}
            />
          </Box>
        </ClickAwayListener>
      </Popper>
      {showEditAccountsDialog && (
        <AddLocalAccountDialogue
          open={showEditAccountsDialog}
          setOpen={setShowEditAccountsDialog}
          platformData={platformData}
          doUpdate={onUpdatePlatform}
        />
      )}
    </HStack>
  );
};
