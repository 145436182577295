import { memo, useCallback, useMemo } from 'react';

import PrioritySelect from 'features/prioritySelect/PrioritySelect';
import useUpdatePriority from 'screens/storyHub/api/useUpdatePriority';
import { ParsedMemberType } from 'types';
import { MemberType } from 'types/graphqlTypes';
import { isStory } from 'utils/member/mTypes';

const PriorityComponent = ({
  item,
  width,
  maxWidth,
  height,
  borderRadius,
  onChange,
}: Readonly<{
  item: MemberType | ParsedMemberType;
  width?: string;
  maxWidth?: string;
  height?: string;
  borderRadius?: string;
  onChange?: (value?: string) => void;
}>) => {
  const { updatePriority } = useUpdatePriority();

  const onValueChange = useCallback(
    (priorityValue: string) => {
      if (!item.mPriority && priorityValue === 'unset') return onChange?.();
      onChange?.(priorityValue === 'unset' ? undefined : priorityValue);
      updatePriority(item, priorityValue === 'unset' ? null : priorityValue);
    },
    [item, onChange, updatePriority],
  );

  const isStoryType = useMemo(() => isStory(item.mType ?? ''), [item.mType]);
  if (!isStoryType) return null;

  return (
    <PrioritySelect
      item={item}
      onChange={onValueChange}
      width={width}
      maxWidth={maxWidth}
      height={height}
      borderRadius={borderRadius}
    />
  );
};

export default memo(PriorityComponent);
