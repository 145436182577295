import { useCallback, useMemo } from 'react';
import { ApolloClient, useApolloClient, useQuery, WatchQueryFetchPolicy } from '@apollo/client';

import { parseMember } from 'api/gridAndKanban/utils';
import { getInputFromValue, MemberKeys } from 'components/mdfEditor/fields/relation/relation-utils';
import { ParsedMemberType } from 'types';
import { MiniMember } from 'types/forms/forms';
import type { BatchGetMembersInput, FieldValue, MemberType } from 'types/graphqlTypes';
import useLogger from 'utils/useLogger';

import { BATCH_GET_MEMBERS } from './graphql';

type ReturnType = {
  batchGetMembers: MemberType[];
};

type InputType = {
  input: BatchGetMembersInput;
};

export const batchGetMembersFromFieldValue = (
  client: ApolloClient<object>,
  miniMembers: MiniMember[],
) => {
  const input = getInputFromValue(miniMembers);

  return client.query<ReturnType, InputType>({
    query: BATCH_GET_MEMBERS,
    variables: {
      input: {
        keys: input,
      },
    },
    fetchPolicy: 'network-only',
  });
};

const STABLE_ARR: MemberType[] = [];

export const useBatchGetMembersHook = (
  keys: MemberKeys[],
  fetchPolicy: WatchQueryFetchPolicy = 'cache-first',
  gql = BATCH_GET_MEMBERS,
) => {
  const { data, error, loading } = useQuery<ReturnType, InputType>(gql, {
    variables: {
      input: {
        keys,
      },
    },
    fetchPolicy,
    skip: !keys?.length,
  });

  return { members: data?.batchGetMembers ?? STABLE_ARR, loading, error };
};

export const useBatchGetParsedMembersHook = (
  keys: MemberKeys[],
  fetchPolicy: WatchQueryFetchPolicy = 'cache-first',
) => {
  const { members, loading, error } = useBatchGetMembersHook(keys, fetchPolicy);

  const parsedMembers: ParsedMemberType[] = useMemo(() => {
    return members.map(parseMember);
  }, [members]);

  return { members: parsedMembers, loading, error };
};

export const useGetMembersFromFieldValue = (miniMembers: FieldValue) => {
  const { members, loading, error } = useBatchGetMembersHook(
    useMemo(() => getInputFromValue(miniMembers), [miniMembers]) ?? [],
  );
  return { members, loading, error };
};

const useBatchGetMembers = () => {
  const client = useApolloClient();
  const logger = useLogger();

  const loadItems = useCallback(
    async (input: BatchGetMembersInput) => {
      const result = await client.query<ReturnType, InputType>({
        query: BATCH_GET_MEMBERS,
        variables: {
          input,
        },
        fetchPolicy: 'network-only',
      });

      if (result?.errors) {
        logger.log(`Error loading items: ${result.errors.toString()}`);
        return [];
      }

      if (!result?.data?.batchGetMembers) return [];

      return result?.data?.batchGetMembers;
    },
    [client, logger],
  );

  return [loadItems];
};

export default useBatchGetMembers;
