import styled from '@emotion/styled';

import Text from 'components/text';
import { HStack } from 'layouts/box/Box';
import transientOptions from 'theme/helpers';

export const StyledHeaderText = styled(Text, transientOptions)<{ $small: boolean }>`
  white-space: pre;
  font-size: ${({ $small }) => ($small ? '10px' : '12px')};
`;

export const ScopedConfigLabelText = styled(Text, transientOptions)<{ $small: boolean }>`
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Number of lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: ${({ $small }) => ($small ? '10px' : '12px')};
`;

export const StyledText = styled(Text)`
  cursor: pointer;
  :hover {
    color: ${({ theme }) => theme.palette.dina.highEmphasis};
  }
`;

export const ColorWrapper = styled(HStack)`
  cursor: pointer;
  :hover * {
    color: ${({ theme }) => theme.palette.dina.highEmphasis};
  }
`;

export const CheckboxWrapper = styled(HStack)`
  position: relative;
  top: -4px;
  left: -4px;
  :hover .checkbox-label {
    color: ${({ theme }) => theme.palette.dina.highEmphasis};
  }
  .checkbox-label {
    cursor: pointer;
  }
`;

export const StyledHStack = styled(HStack, transientOptions)<{ $selected: boolean }>`
  * {
    color: ${({ $selected }) => ($selected ? 'white' : 'inherit')};
  }
  cursor: pointer;
  height: 32px;
  flex-shrink: 0;
  padding-inline: 4px;
  border-radius: 4px;
  background-color: ${({ theme, $selected }) =>
    $selected ? theme.palette.dina.onSelected : 'transparent'};
  :hover {
    .extra-info {
      display: none;
    }
    .actions {
      display: block;
    }
    background-color: ${({ theme, $selected }) =>
      $selected ? theme.palette.dina.onSelected : theme.palette.dina.hoverOverlay};
    * {
      color: ${({ theme }) => theme.palette.dina.highEmphasis};
    }
  }
`;

export const Actions = styled(HStack)`
  display: none;

  svg {
    height: 20px;
    cursor: pointer;
    &:hover path {
      fill: ${({ theme }) => theme.palette.dina.highEmphasis};
      fill-opacity: 1;
    }
  }
`;

export const SubText = styled(Text)`
  font-size: 9px;
  color: ${({ theme }) => theme.palette.dina.mediumEmphasis};
`;
