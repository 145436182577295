import { useMemo, useState } from 'react';
import type { ColumnDef, DisplayColumnDef } from '@tanstack/react-table';
import type { Dictionary } from 'lodash';

import StatusCell from 'features/grids/common/components/cells/StatusCell';
import UserCell from 'features/grids/common/components/cells/UserCell';
import { ColumnVisibilityMenu } from 'features/grids/common/components/ColumnVisibilityMenu';
import { FilterVariant } from 'features/grids/common/types';
import { type ParsedOrderType, useGetMdfColumns } from 'features/mdf/useGetMdfColumns';
import OpenPreviewButton from 'features/orderForm/components/OpenPreviewButton';
import { Color } from 'features/reusableStyled';
import useCustomDateTimeUtils from 'hooks/useCustomDateTimeUtils';
import { HStack } from 'layouts/box/Box';
import type { Order } from 'types/forms/forms';
import type { Mdf } from 'types/graphqlTypes';
import type { OrderFormMemberType } from 'types/memberTypes/order_form';

import { ExtendedTableMeta, OrderActions } from '../components/rowActions/RowActions';

export interface OrderGridColumnsType {
  orderFormMap: Dictionary<OrderFormMemberType>;
}

/** Action column, positioned last */
const actionColumn: DisplayColumnDef<Order> = {
  cell: ({ table, row }) => (
    <OrderActions row={row} tableMeta={table.options.meta as ExtendedTableMeta} />
  ),
  id: 'actions',
  enableHiding: false,
  enableResizing: false,
  enableSorting: false,
  enableGrouping: false,
  size: 140,
};

/** Get the columns for the order grid */
export function useGetOrderGridColumns(
  orders: ParsedOrderType[],
  mdfs: Mdf[],
  orderFormMap: Dictionary<OrderFormMemberType>,
) {
  // Using the rowIds to prevent unnecessary re-renders
  const [rowIds, setRowIds] = useState<string[]>([]);

  const { isoToLocaleShort } = useCustomDateTimeUtils();

  if (orders.length !== rowIds.length) {
    setRowIds(orders.map((item) => item.mId));
  }

  /** Default non-mdf order columns */
  const defaultColumns: ColumnDef<Order>[] = useMemo(
    () => [
      {
        accessorKey: 'mFormId',
        header: ({ table }) => <ColumnVisibilityMenu table={table}>Type</ColumnVisibilityMenu>,
        id: 'visibilityMenu',
        cell: ({ table, row }) => {
          const openPreview = () => {
            if (table.options.meta?.setPreview) {
              // @ts-expect-error - fix: combine order and member previews.
              table.options.meta.setPreview(row.original);
            }
          };

          return (
            <OpenPreviewButton onPreviewClick={openPreview}>
              <HStack width="100%" gap="3px">
                {orderFormMap[row.original?.mFormId]?.mColor && (
                  <Color
                    $color={orderFormMap[row.original?.mFormId]?.mColor ?? undefined}
                    $size={12}
                  />
                )}
                {orderFormMap[row.original?.mFormId]?.mDescription ?? 'Error - form not found'}
              </HStack>
            </OpenPreviewButton>
          );
        },
        size: 160,
      },
      {
        accessorKey: 'mStatus',
        header: 'Status',
        cell: ({ row, table }) => (
          <StatusCell row={row} orderFormMap={orderFormMap} table={table} />
        ),
        size: 140,
        meta: {
          filterVariant: FilterVariant.STATUS,
        },
      },
      {
        accessorKey: 'mAssignee',
        header: 'Assignee',
        cell: ({ getValue, row, table }) => (
          <UserCell
            getValue={getValue}
            title="Assignee"
            row={row}
            table={table}
            fieldId="mAssignee"
          />
        ),
        size: 200,
        meta: {
          filterVariant: FilterVariant.MEMBER,
        },
      },
      {
        accessorKey: 'mOwner',
        header: 'Task owner',
        cell: ({ getValue, row, table }) => (
          <UserCell
            getValue={getValue}
            title="Task owner"
            row={row}
            table={table}
            fieldId="mOwner"
          />
        ),
        size: 200,
        meta: {
          filterVariant: FilterVariant.MEMBER,
        },
      },
      {
        accessorKey: 'mCreatedAt',
        header: 'Created',
        cell: ({ getValue }) => isoToLocaleShort(getValue() as string),
        size: 120,
      },
      {
        accessorKey: 'mUpdatedAt',
        header: 'Updated',
        cell: ({ getValue }) => isoToLocaleShort(getValue() as string),
        size: 120,
      },
    ],
    [],
  );

  /** MDF columns */
  const mdfColumns = useGetMdfColumns({ items: orders, mdfs });

  const columns = useMemo(() => {
    return [...defaultColumns, ...mdfColumns, actionColumn];
  }, [rowIds, orderFormMap]);

  return columns;
}
