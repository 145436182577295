import { MetadataFormFieldType, MMetaDataFieldInput } from 'types/graphqlTypes';

/**
 * Ensures that the metadata is stored with corresponding mostag if defined
 * in the form structure
 * @param {*} formFields
 * @param {*} metadata
 * @returns
 */
const getMetadataWithMosTag = (
  formFields: MetadataFormFieldType[],
  metadata: MMetaDataFieldInput[],
) => {
  if (!Array.isArray(metadata) || !metadata.length) return [];
  const useForms = Array.isArray(formFields) && formFields.length;
  return metadata.map((md) => {
    const { key, value, autoValue, manual } = md;

    // Do this to remove any other properties (like __typename)
    const field: MMetaDataFieldInput = { key, value };

    // to filter out both undefined and null
    if (autoValue != null) field.autoValue = autoValue;
    if (manual != null) field.manual = manual;

    const formField = useForms && formFields.find((f) => f.id === key);
    if (formField && formField.mostag) field.mostag = formField.mostag;
    return field;
  });
};

export default getMetadataWithMosTag;
