/**
 * This must be included for each command item that may not have unique text content.
 *
 * This is necessary for cmdk to look at the contents as a unique value. For example, many feed
 * items may have practically the same content. Without including a unique id in the actual DOM
 * tree of the item, cmdk struggles seeing the difference and treats it as the same item.
 */
const UniqueId = ({ id }: { id: string }) => <div style={{ display: 'none' }}>{id}</div>;

export default UniqueId;
