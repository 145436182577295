import { useCallback, useContext, useMemo } from 'react';
import { useQuery } from '@apollo/client';

import { useGetMdfs } from 'api/mdf/useGetMdfs';
import { ReactComponent as Copy } from 'assets/icons/systemicons/copy.svg';
import Avatar from 'components/avatar/Avatar';
import { Button, IconButton } from 'components/buttons';
import { MdfEditor } from 'components/mdfEditor/MdfEditor';
import Scrollbar from 'components/scrollbar';
import Text from 'components/text/Text';
import UserCtx from 'contexts/UserContext';
import useCopyText from 'hooks/useCopyText';
import useGetMembersOf from 'hooks/useGetMembersOf';
import useImageUpload, { UploadProps } from 'hooks/useImageUpload';
import useImageUrl from 'hooks/useImageUrl';
import useObjectUrl from 'hooks/useObjectUrl';
import { Box, HStack, VStack } from 'layouts/box/Box';
import memberTypes from 'operations/memberTypes';
import GET_USER from 'operations/queries/getUser';
import { AssignedMember, User } from 'types';
import { NewFieldValue } from 'types/forms/forms';
import type { GetMemberInput, MemberType, Metadata } from 'types/graphqlTypes';
import { getPhotoUrl } from 'utils/member/member-utils';

import {
  useChangedProfileDepartments,
  useChangedProfileMetadata,
  useChangedProfilePicture,
  useChangedProfileTeams,
} from '../../../../../atomsTs';

import ProfileDetails from './ProfileDetails';
import useUpdateProfile from './useUpdateProfile';

import { AvatarWrapper, Header, HeaderWrapper, IdWrapper, TitleWrapper } from './styled';

type GetUserReturnType = {
  getMember: User;
};

type GetUserInputType = {
  input: GetMemberInput;
};

interface ProfileProps {
  groupPolicies: MemberType[];
}

const getParsedMD = (val?: string | null) => {
  try {
    return JSON.parse(val ?? '{}') as Metadata | null | undefined;
  } catch (err) {
    return null;
  }
};

function Profile({ groupPolicies }: Readonly<ProfileProps>) {
  const { mId, groups: userGroups } = useContext(UserCtx);
  const { copyTooltip, onCopy } = useCopyText('Copy Id to the clipboard.');
  const { mdfsByMType } = useGetMdfs();
  const [changedProfileMetadata, setChangedProfileMetadata] = useChangedProfileMetadata();
  const [changedProfilePicture, setChangedProfilePicture] = useChangedProfilePicture();
  const [changedProfileTeams, setChangedProfileTeams] = useChangedProfileTeams();
  const [changedProfileDepts, setChangedProfileDepts] = useChangedProfileDepartments();

  const { createOrUpdateObjectURL } = useObjectUrl();

  const { data: user } = useQuery<GetUserReturnType, GetUserInputType>(GET_USER, {
    variables: { input: { mId } },
  });
  const [teams] = useGetMembersOf(mId, memberTypes.TEAM_USER) as [
    { getMembersOf: AssignedMember[] },
  ];
  const [departments] = useGetMembersOf(mId, memberTypes.DEPARTMENT_USER) as [
    { getMembersOf: AssignedMember[] },
  ];
  const { addTeamOrDept } = useUpdateProfile();

  const parsedMetadata = useMemo(() => {
    return getParsedMD(user?.getMember.metadata) ?? {};
  }, [user?.getMember?.metadata]);

  const title = `${((changedProfileMetadata ?? parsedMetadata)?.firstName as string) ?? ''} ${
    ((changedProfileMetadata ?? parsedMetadata)?.surname as string) ?? ''
  }`;

  const groups = useMemo(
    () =>
      groupPolicies.filter(
        (groupPolicy) =>
          groupPolicy?.mRefId && userGroups && userGroups.includes(groupPolicy.mRefId),
      ),
    [groupPolicies, userGroups],
  );

  const { mAvatarKey } = user?.getMember ?? {};

  const updateFieldValue = useCallback(
    (val: NewFieldValue[]) => {
      const updatedMd: Metadata = {};
      for (const update of val) {
        updatedMd[update.fieldId] = update.value;
      }
      const newMetadata = {
        ...(changedProfileMetadata ?? parsedMetadata),
        ...updatedMd,
      };

      setChangedProfileMetadata(newMetadata);
    },
    [changedProfileMetadata, parsedMetadata, setChangedProfileMetadata],
  );

  const addTeams = useCallback(() => {
    addTeamOrDept(
      'team',
      title,
      teams?.getMembersOf,
      setChangedProfileTeams,
      changedProfileTeams?.new,
    );
  }, [addTeamOrDept, title, teams?.getMembersOf, setChangedProfileTeams, changedProfileTeams?.new]);

  const deleteTeams = useCallback(
    (members: AssignedMember[]) => {
      setChangedProfileTeams({ old: teams?.getMembersOf, new: members });
    },
    [setChangedProfileTeams, teams?.getMembersOf],
  );

  const addDepts = useCallback(
    () =>
      addTeamOrDept(
        'department',
        title,
        departments?.getMembersOf,
        setChangedProfileDepts,
        changedProfileDepts?.new,
      ),
    [
      addTeamOrDept,
      changedProfileDepts?.new,
      departments?.getMembersOf,
      setChangedProfileDepts,
      title,
    ],
  );

  const deleteDepts = useCallback(
    (members: AssignedMember[]) => {
      setChangedProfileDepts({ old: departments?.getMembersOf, new: members });
    },
    [departments?.getMembersOf, setChangedProfileDepts],
  );

  const imageUrl = useImageUrl(mAvatarKey ?? '') ?? '';

  const onImageLoad = useCallback(
    (file: File | UploadProps[]) => {
      const photoUrl = getPhotoUrl(user?.getMember);
      setChangedProfilePicture({ file: file as File, key: photoUrl, mId: user?.getMember?.mId });
    },
    [setChangedProfilePicture, user?.getMember],
  );
  const captureImage = useImageUpload({ onImageLoad });

  const tempURL = useMemo(
    () =>
      changedProfilePicture?.file
        ? createOrUpdateObjectURL(changedProfilePicture?.file)
        : undefined,
    [changedProfilePicture?.file, createOrUpdateObjectURL],
  );

  return (
    <VStack flex="1" justifyContent="start">
      <HeaderWrapper>
        <Box flex="1" padding="12px">
          <Header>
            <AvatarWrapper>
              <Avatar size={96} src={tempURL ?? imageUrl} title={title} />
              <Button usage="story" variant="outlined" height={32} onClick={captureImage}>
                Update
              </Button>
            </AvatarWrapper>
            <TitleWrapper>
              <Text variant="h5" color="highEmphasis">
                {title}
              </Text>
              <Text variant="h7" color="mediumEmphasis">
                {(changedProfileMetadata ?? parsedMetadata)?.jobTitle as string}
              </Text>
              <IdWrapper>
                <Text variant="body2" color="disabled">
                  {mId}
                </Text>
                <IconButton
                  usage="text"
                  title={copyTooltip}
                  size={24}
                  iconSize={18}
                  onClick={() => onCopy(mId, 'Copied!')}
                >
                  <Copy />
                </IconButton>
              </IdWrapper>
            </TitleWrapper>
          </Header>
        </Box>
        <Box flex="1" overflow="auto" padding="4px 0px">
          <ProfileDetails variant="chip" title="Groups" members={groups} />
        </Box>
      </HeaderWrapper>
      <Scrollbar>
        <HStack flex="1" alignItems="start" width="100%" overflow="scroll">
          <Box padding="8px 12px" flex="1">
            {mdfsByMType.user && (
              <MdfEditor
                fields={mdfsByMType.user?.fields}
                defaultLayoutSettings={mdfsByMType.user.views.default}
                layoutSettings={mdfsByMType.user.views.default}
                metadata={changedProfileMetadata ?? parsedMetadata}
                // passing empty object to bypass permission (only for the profile page)
                permissions={{ read: {}, write: {} }}
                updateFieldValue={(fieldValue) => updateFieldValue(fieldValue)}
                view="contact_view"
              />
            )}
          </Box>
          <Box flex="1">
            <ProfileDetails
              variant="chip"
              title="Teams"
              members={changedProfileTeams?.new ?? teams?.getMembersOf ?? []}
              avatarVariant="team"
              onDelete={deleteTeams}
              onAdd={addTeams}
            />
            <ProfileDetails
              variant="chip"
              title="Departments"
              members={changedProfileDepts?.new ?? departments?.getMembersOf ?? []}
              avatarVariant="department"
              onDelete={deleteDepts}
              onAdd={addDepts}
            />
          </Box>
        </HStack>
      </Scrollbar>
    </VStack>
  );
}

export default Profile;
