import styled from '@emotion/styled';

import { MemberMdfEditor } from 'components/mdfEditor/MemberMdfEditor';
import Scrollbar from 'components/scrollbar';
import EditableText from 'components/text/EditableText';
import Text from 'components/text/Text';
import { Box, VStack } from 'layouts/box/Box';
import { MemberTypeEnum } from 'types/graphqlTypes';
import { User } from 'types/members';

import LinkedMembers from './LinkedMembers';
import useMemberDetails from './useMemberDetails';

const Wrapper = styled(VStack)`
  width: 100%;
  overflow: hidden;
  justify-content: flex-start;
  background-color: ${({ theme }) => theme.palette.dina.surfaceAppBackgroundNavLevel2};
`;

interface Props {
  member: User;
}

function Details({ member }: Readonly<Props>) {
  const { users, teams, departments, updateDescription, updateUser, updateTeam, updateDepartment } =
    useMemberDetails(member);

  return (
    <Wrapper>
      <Scrollbar top={5} bottom={10}>
        <Box width="100%" padding="12px">
          {member.mType === MemberTypeEnum.User || member.mType === MemberTypeEnum.Contact ? (
            <MemberMdfEditor view="default" member={member} />
          ) : (
            <VStack gap="8px">
              <Text variant="overline">Description</Text>
              <EditableText
                variant="rightColumnHeaderText"
                value={member?.mDescription ?? ''}
                onUpdate={(value) => void updateDescription(value)}
                multiline={8}
                placeholder="Add description"
              />
            </VStack>
          )}
        </Box>
        {users && (
          <LinkedMembers
            type="user"
            listTitle="Users"
            members={users ?? []}
            updateMember={updateUser}
            onItemClick={undefined}
          />
        )}
        {teams && (
          <LinkedMembers
            type="team"
            listTitle="Teams"
            members={teams ?? []}
            updateMember={updateTeam}
            onItemClick={undefined}
          />
        )}
        {departments && (
          <LinkedMembers
            type="department"
            listTitle="Departments"
            members={departments ?? []}
            updateMember={updateDepartment}
            onItemClick={undefined}
          />
        )}
      </Scrollbar>
    </Wrapper>
  );
}

export default Details;
