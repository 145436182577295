import React from 'react';
import PropTypes from 'prop-types';

import mainSettingsView from 'utils/settings/schemas/mainSettingsView';

import { BodyWrapper, SettingsMenuWrapper, SettingsDetailsWrapper } from '../../../styled';
import SystemSettingsMenu from './menu';
import SystemSettingsDetails from './details';
import filterVisibleCategories from '../../../utils/filterVisibleCategories';

const SystemSettings = ({
  systemSettings,
  form,
  links,
  instanceTypes,
  panels,
  webhooks,
  rundowns,
}) => {
  const categories = filterVisibleCategories(mainSettingsView);
  const linkFields = links?.mProperties?.fields;
  const metadataFields = form?.mProperties?.fields;

  return (
    <BodyWrapper>
      <SettingsMenuWrapper>
        <SystemSettingsMenu
          categories={categories}
          fields={metadataFields}
          instanceTypes={instanceTypes}
          links={linkFields}
          panels={panels}
        />
      </SettingsMenuWrapper>
      <SettingsDetailsWrapper>
        <SystemSettingsDetails
          systemSettings={systemSettings}
          categories={categories}
          fields={metadataFields}
          webhooks={webhooks}
          rundowns={rundowns}
        />
      </SettingsDetailsWrapper>
    </BodyWrapper>
  );
};

SystemSettings.propTypes = {
  systemSettings: PropTypes.object,
  form: PropTypes.object,
  links: PropTypes.object,
  instanceTypes: PropTypes.array,
  webhooks: PropTypes.array,
  panels: PropTypes.array,
};

SystemSettings.defaultProps = {
  systemSettings: {},
  form: {},
  links: {},
  instanceTypes: [],
  webhooks: [],
  panels: [],
};

export default SystemSettings;
