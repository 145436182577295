import { useEffect, useState } from 'react';
import { atom, useAtom } from 'jotai';

import Dialog from 'components/dialogs/DialogBuilder';
import { StyledTextField } from 'components/mdfEditor/fields/text/styled';
import { FieldHeader } from 'components/mdfEditor/styled';
import { getFocusRestorer } from 'utils/dom/focus';

export interface EditString {
  onConfirm: (val: string) => void;
  /**
   * Optional validator function for the text. If this function is provided and returns something
   * else than `true`, the confirm button will be disabled.
   * If a `string` is returned, the text will be displayed under the input field explaining
   * why the confirm button is disabled.
   * If `false`is returned no message will be displayed, but the confirm button will be disabled.
   * (This can be used to disable the initial value for a rename operation.)
   */
  validator?: (val: string) => boolean | string;
  /** The initial value for the text input field */
  startValue?: string;
  headerText: string;
  inputLabel?: string;
  required?: boolean;
  confirmLabel?: string;

  onCancel?: () => void;
}

const editStringDialog = atom<EditString | null>(null);

export const useEditStringDialog = () => useAtom(editStringDialog);

export type GetTextFromUserProps = Omit<EditString, 'onConfirm' | 'onCancel'>;

/**
 * A function that asynchronously returns a text entered by the user.
 * @param props Information about how the dialog that
 * @returns     A `Promise` resolving to a `string` containing the entered text or `false` if
 *              cancelled.
 */
export type GetTextFromUserAsyncFunc = (props: GetTextFromUserProps) => Promise<string | false>;

/**
 * This function returns a {@link GetTextFromUserAsyncFunc} function that asynchronously requests
 * a text from the user.
 */
export function useGetTextFromUserAsync(): GetTextFromUserAsyncFunc {
  const [state, setState] = useEditStringDialog();
  return async (props: GetTextFromUserProps) => {
    if (state) return false;
    const restoreFocus = getFocusRestorer(1);
    let resolve: ((result: string | false) => void) | undefined;
    function onConfirm(result: string) {
      resolve?.(result);
      restoreFocus();
    }
    function onCancel() {
      resolve?.(false);
      restoreFocus();
    }
    const result = new Promise<string | false>((r) => (resolve = r));
    setState({ ...props, onConfirm, onCancel });
    return result;
  };
}

export function EditStringDialog() {
  const [value, setValue] = useState('');
  const [open, setOpen] = useState(false);
  const [helperText, setHelperText] = useState('');
  const [state, setState] = useEditStringDialog();

  useEffect(() => {
    if (state === null) {
      setOpen(false);
    } else {
      setOpen(true);
      if (state?.startValue) {
        setValue(state.startValue);
      }
    }
  }, [state]);

  useEffect(() => {
    if (state?.validator) {
      const result = state.validator(value) || ' ';
      const requiredText = value.length > 0 || !state.required ? '' : 'Required value';
      setHelperText(typeof result === 'string' ? result : requiredText);
    } else if (state?.required) {
      setHelperText(value.length > 0 ? '' : 'Required value');
    } else {
      setHelperText('');
    }
  }, [value, state?.required, state?.validator]);

  const onCancel = () => {
    if (state?.onCancel) {
      state.onCancel();
    }
    setValue('');
    setState(null);
  };

  const onConfirm = () => {
    if (state?.onConfirm) {
      state.onConfirm(value);
    }
    setOpen(false);
    onCancel();
  };

  const handleKeyDown = (ev: React.KeyboardEvent<HTMLDivElement>) => {
    if (ev.key === 'Enter' && helperText === '') {
      onConfirm();
    }
  };

  return (
    <Dialog open={open} onClose={onCancel}>
      <Dialog.Header>{state?.headerText}</Dialog.Header>
      <Dialog.Body>
        {state?.inputLabel && <FieldHeader>{state.inputLabel}</FieldHeader>}
        <StyledTextField
          error={helperText !== ''}
          helperText={helperText.trim()}
          value={value}
          autoFocus
          variant="filled"
          onChange={(ev) => setValue(ev.target.value)}
          onFocus={(ev) => ev.currentTarget.select()}
          onKeyUp={(ev) => handleKeyDown(ev)}
          fullWidth
        />
      </Dialog.Body>
      <Dialog.Footer>
        <Dialog.CancelButton />
        <Dialog.ConfirmButton
          label={state?.confirmLabel ?? 'Confirm'}
          onClick={onConfirm}
          disabled={helperText !== ''}
        />
      </Dialog.Footer>
    </Dialog>
  );
}
