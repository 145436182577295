import { memo, useCallback, useEffect, useState } from 'react';

import { getDefaultLayoutSettings } from 'components/editMdfDialog/utils';
import Tooltip from 'components/tooltip';
import useCustomDateTimeUtils from 'hooks/useCustomDateTimeUtils';
import { FieldTypeEnum } from 'types/graphqlTypes';

import type CellProps from '../cellProps';
import { getValue } from '../text/TextField';

import { useDatePicker } from './DatePicker';

import { DateLabel } from './styled';

function ChoiceCell({
  setValue,
  value,
  disableEdit,
  fieldSettings,
  fieldModel,
}: Readonly<CellProps>) {
  const { isoToLocaleShort } = useCustomDateTimeUtils();
  const [localValue, setLocalValue] = useState<string>('');
  const [, setPicker] = useDatePicker();
  const { hint } = fieldSettings ?? getDefaultLayoutSettings(fieldModel);

  useEffect(() => {
    const sanitizedValue = getValue(value, FieldTypeEnum.text);
    if (typeof sanitizedValue === 'string' && sanitizedValue !== localValue) {
      setLocalValue(sanitizedValue);
    }
  }, [value]);

  const showPicker = useCallback(
    (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      setPicker({
        anchorEl: ev.currentTarget,
        startValue: typeof localValue === 'string' ? localValue : undefined,
        selectDate: (vals) => {
          setLocalValue(vals.startDate);
          setValue(vals.startDate);
        },
      });
    },
    [setPicker, localValue],
  );

  const onClick = useCallback(
    (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (!disableEdit) {
        showPicker(ev);
      }
    },
    [showPicker, disableEdit],
  );

  const hasValue = localValue.length > 0;

  return (
    <Tooltip title={disableEdit ? 'You are not allowed to edit this field' : hint}>
      <DateLabel onClick={onClick} $hasValue={hasValue}>
        {hasValue ? isoToLocaleShort(localValue, true) : 'Set date'}
      </DateLabel>
    </Tooltip>
  );
}

export default memo(ChoiceCell);
