import updateInstanceToStory from 'operations/utils/cache/notification/transaction/updateInstanceToStory';
import memberTypes from 'operations/memberTypes';
import updateStoryNotes from './updateStoryNotes';
import updatePlanningBlocks from './updatePlanningBlocks';
import { updateScriptsCache } from './updateScriptsCache';

const dispatchMessage = (client, item) => {
  switch (item.mType) {
    case memberTypes.INSTANCE:
      updateInstanceToStory(client, item);
      break;
    case memberTypes.ARCHIVED_INSTANCE:
      updateInstanceToStory(client, { ...item, crudAction: 'REMOVE' });
      break;
    case memberTypes.NOTE:
      updateStoryNotes(client, item);
      break;
    case memberTypes.BLOCK:
      updatePlanningBlocks(client, item);
      break;
    case memberTypes.SCRIPT:
      updateScriptsCache(client, item);
      break;
    default:
      break;
  }
};

const updateCacheWithTransaction = (client, item) => {
  dispatchMessage(client, item);
};

export default updateCacheWithTransaction;
