import DialogBuilder from 'components/dialogs/DialogBuilder';
import Text from 'components/text';
import { VStack } from 'layouts/box/Box';

interface DeleteInstanceDialogProps {
  onDelete: () => void;
  canDelete: boolean;
  open: boolean;
  onClose: () => void;
}

export function DeleteInstanceDialog({
  open,
  onClose,
  onDelete,
  canDelete,
}: Readonly<DeleteInstanceDialogProps>) {
  return (
    <DialogBuilder open={open} onClose={onClose}>
      <DialogBuilder.Header>Delete this Instance?</DialogBuilder.Header>
      <DialogBuilder.Body padding="18px">
        {canDelete ? (
          <VStack gap="12px">
            <DialogBuilder.Checkbox
              label="Please confirm that you want to delete this Instance"
              required
            />
            <Text color="statusWarning" variant="label">
              Once deleted, the Instance cannot be restored
            </Text>
          </VStack>
        ) : (
          <>This instance is scheduled. You can not delete already scheduled instances.</>
        )}
      </DialogBuilder.Body>
      <DialogBuilder.Footer>
        <DialogBuilder.CancelButton />
        <DialogBuilder.ConfirmButton
          onConfirm={onDelete}
          disabled={!canDelete}
          label="Delete"
          usage="danger"
        />
      </DialogBuilder.Footer>
    </DialogBuilder>
  );
}
