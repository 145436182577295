import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import fieldTypes from 'utils/rundown/fieldTypes';
import useCheckUserRight from 'hooks/useCheckUserRight';
import Switch from 'components/switch/Switch';
import InputField from 'components/inputField/InputField';
import { getAllTimezones } from 'hooks/useTimezoneSettings';
import AutoComplete from 'components/autoCompleteBase/Autocomplete';
import Select from 'components/select';
import ShortcutInput from 'components/ShortcutField';

import {
  FormControlLabel,
  ContentWrapper,
  InputWrapper,
  LabelWrapper,
  Label,
  BetaLabel,
  SelectWrapper,
} from './settingsContent-styled';

const SettingsContent = ({
  type,
  label,
  description,
  value,
  placeholder,
  disabled,
  options,
  onChange,
  contentKey,
  changedSettings,
  width,
  isBetaLabel,
  children,
  onFocus,
  onBlur,
  severity,
}) => {
  const [checkUserRight] = useCheckUserRight();
  const [settingValue, setSettingValue] = useState(value);
  const hasTimezonePermission = checkUserRight('feature', 'timezone');

  const timezones = useMemo(() => getAllTimezones(), []);

  useEffect(() => {
    const changedValue = changedSettings?.find(
      (changedSetting) => changedSetting.key === contentKey,
    );
    if (changedValue) {
      setSettingValue(changedValue.value);
    } else {
      setSettingValue(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changedSettings, value]);

  const handleOnChange = (val) => {
    setSettingValue(val);
    onChange(val, contentKey, value);
  };

  const selectInputField = useMemo(() => {
    if (type === fieldTypes.BOOLEAN)
      return (
        <FormControlLabel
          control={
            <Switch disabled={disabled} selected={!!settingValue} onClick={handleOnChange} />
          }
          label={description}
        />
      );

    if (type === fieldTypes.SELECT)
      return (
        <SelectWrapper>
          <Select
            hideLabel
            disabled={disabled}
            items={options}
            selectedValue={settingValue}
            onChange={handleOnChange}
          />
          {children}
        </SelectWrapper>
      );

    if (type === fieldTypes.AUTOCOMPLETE)
      return (
        <AutoComplete
          usage="timezone"
          options={timezones}
          disabled={disabled}
          placeholder={placeholder}
          value={settingValue}
          onChange={handleOnChange}
        />
      );

    if (type === fieldTypes.SHORTCUT)
      return (
        <ShortcutInput
          placeholder={placeholder}
          description={description}
          value={settingValue}
          disabled={disabled}
          onChange={handleOnChange}
        />
      );

    return (
      <InputField
        severity={severity}
        placeholder={placeholder}
        description={description}
        value={settingValue}
        disabled={disabled}
        disableLabel
        usage="editor"
        hideEndAdornment
        onChange={handleOnChange}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    );
  }, [
    type,
    severity,
    settingValue,
    disabled,
    options,
    children,
    handleOnChange,
    placeholder,
    description,
    onFocus,
    onBlur,
  ]);

  if (contentKey === 'app.timezone' && !hasTimezonePermission) return null;

  return (
    <ContentWrapper>
      <LabelWrapper>
        <Label>{label}</Label>
        {isBetaLabel && <BetaLabel>(Beta)</BetaLabel>}
      </LabelWrapper>
      <InputWrapper $width={width}>{selectInputField}</InputWrapper>
    </ContentWrapper>
  );
};

SettingsContent.propTypes = {
  /** type of the input value  */
  type: PropTypes.string,
  /** label of the input fields  */
  label: PropTypes.string,
  /** Assistive help text shown at the bottom if defined (text)  */
  description: PropTypes.string,
  /** Value of the metaData in system settings  */
  value: PropTypes.any,
  /** A short hint displayed in the input */
  placeholder: PropTypes.string,
  /** Options for select field  */
  options: PropTypes.array,
  /** Function used onChange of input fields */
  onChange: PropTypes.func,
  /** on focus callback for input */
  onFocus: PropTypes.func,
  /** on blur callback for input */
  onBlur: PropTypes.func,
  /** Key value of input field in system settings  */
  contentKey: PropTypes.string,
  /** changed settings value */
  changedSettings: PropTypes.array,
  /** whether the field is disabled or not */
  disabled: PropTypes.bool,
  /** width of the field */
  width: PropTypes.number,
  /** whether the field will have beta label */
  isBetaLabel: PropTypes.bool,
  /** children for select */
  children: PropTypes.element,
  /** severity of the input field */
  severity: PropTypes.string,
};

SettingsContent.defaultProps = {
  type: '',
  label: '',
  description: '',
  value: '',
  placeholder: '',
  options: [],
  onChange: () => {},
  onFocus: () => {},
  onBlur: () => {},
  contentKey: '',
  changedSettings: [],
  disabled: false,
  width: undefined,
  isBetaLabel: false,
  children: null,
  severity: 'regular',
};

export default SettingsContent;
