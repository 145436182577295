import { useEffect, useState } from 'react';
import styled from '@emotion/styled';

import Text from 'components/text';
import Tooltip from 'components/tooltip';
import useCopyText from 'hooks/useCopyText';
import useCustomDateTimeUtils from 'hooks/useCustomDateTimeUtils';
import useDateTimeUtils from 'hooks/useDateTimeUtils';
import useTimezoneSettings from 'hooks/useTimezoneSettings';
import { Box, VStack } from 'layouts/box/Box';
import is24HourFormat from 'utils/dateTime/is24HourFormat';

const ClockContainer = styled('div')<{ $copyOnClick: boolean; $is24HourFormat?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  height: 100%;
  width: ${({ $is24HourFormat }) => ($is24HourFormat ? '64px' : '94px')};
  background-color: transparent;
  cursor: ${({ $copyOnClick }) => ($copyOnClick ? 'pointer' : 'text')};
`;

interface Props {
  copyOnClick: boolean;
}

const DigitalClock = ({ copyOnClick }: Props) => {
  const timezone = useTimezoneSettings();
  const { format } = useDateTimeUtils();
  const { getGMTOffset } = useCustomDateTimeUtils();
  const [time, setTime] = useState(new Date());
  const { onCopy } = useCopyText();
  const formatStr = is24HourFormat() ? 'HH:mm:ss' : 'hh:mm:ss a';

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const onClick = () => {
    if (!copyOnClick) return;
    onCopy(format(time, formatStr), 'Timestamp copied to clipboard').then(
      () => {},
      () => {},
    );
  };

  return (
    <Tooltip
      title={
        <Box
          container
          flexDirection="column"
          gap="4px"
          width={is24HourFormat() ? '108px' : '160px'}
          alignItems="flex-end"
        >
          <Text variant="h5" color="whiteHighEmphasis">
            {format(time, formatStr)}
          </Text>
          <VStack style={{ alignSelf: 'flex-end' }} gap="4px">
            <Text variant="caption" color="whiteMediumEmphasis">
              {timezone}
            </Text>
            <Text variant="caption" color="whiteMediumEmphasis">
              {getGMTOffset(time)}
            </Text>
          </VStack>
        </Box>
      }
    >
      <ClockContainer
        $copyOnClick={copyOnClick}
        $is24HourFormat={is24HourFormat()}
        onClick={onClick}
      >
        <Text variant="listItemLabelMedium" color="whiteMediumEmphasis">
          {format(time, formatStr)}
        </Text>
      </ClockContainer>
    </Tooltip>
  );
};

export default DigitalClock;
