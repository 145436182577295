import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { createScope, molecule, useMolecule } from 'jotai-molecules';

import { CUME_TIME_FIELD_KEY } from 'features/mdf/mdf-defaults';
import { DateToTimeString } from 'screens/rundown/utils/updateDuration';
import type { SessionUser } from 'types/sessionUser';
import { sortByMIDOrder } from 'utils/sortByOrder';

import {
  generateTimings,
  getBackSegments,
  getCumeSegments,
  produceTimings,
} from '../hooks/useProduceTimings';
import { getGroupSegments, getPlaceholderRow, produceRows } from '../utils/helper';
import { calculateTotalTime } from '../utils/timing';
import {
  type AllEditableData,
  type AllRundownAssets,
  type AllRundownStories,
  type CalculatedTimings,
  type RundownCellData,
  type RundownTableState,
  Segment,
  type SegmentWithTiming,
  type Sequences,
  type SlimRow,
} from '../utils/types';

export const RundownScope = createScope<string | null>(null);

export const defaultTableState: RundownTableState = {
  expanded: {},
  columnVisibility: {},
  columnSizing: {},
  columnOrder: [],

  // Used to track selected state
  layoutId: null,
};

/**
 * Initially this atom will contain the value that's coming from the master template.
 * For easy of development, we expose it like this so we can toggle back & forth for
 * quick testing reasons as well.
 */
const rundownV2Atom = atom<boolean>(false);
export const useRundownV2Atom = () => useAtom(rundownV2Atom);

/**
 * Used to focus the title input of an inserted instance, the title cell listens
 * to this atom and will auto focus if the id matches.
 */
const doFocusAtom = atom<string | null>(null);
export const useDoFocusAtom = () => useAtom(doFocusAtom);

/**
 * Store the second rundown id per rundown, to persist across reloads.
 */
const secondRundownIdStorage = atomWithStorage<Record<string, string>>(
  'RundownSecondPaneStorage',
  {},
  undefined,
  {
    unstable_getOnInit: true,
  },
);
export const useSecondRundownId = () => useAtom(secondRundownIdStorage);

/**
 * Holds a map of stories detected in the current rundown, together with its
 * members that are in the rundown. This is not scoped to the molecule, since
 * we need this data across multiple rundowns at once.
 */
export const storiesAtom = atom<AllRundownStories>({});
export const useRundownStories = () => useAtom(storiesAtom);

/**
 * Holds all asset data for assets side panel
 */
export const assetsAtom = atom<AllRundownAssets>({
  media: [],
  graphics: [],
});
export const useRundownAssets = () => useAtom(assetsAtom);

/**
 * A reactive cache of all READY planned durations, used for inline rundowns
 * applying their planned duration to total timing.
 */
const rundownPlannedDurations = atom<Record<string, number>>({});
export const useRundownPlannedDurations = () => useAtom(rundownPlannedDurations);

export type RundownLocation = 'left' | 'right' | 'left-sub' | 'right-sub';

/**
 * Used to keep track in which rundown a user is active, useful for tracking
 * what rundown to trigger keyboard shortcuts for
 */
const currentRundownFocus = atom<{
  sequenceId: string;
  location: RundownLocation;
} | null>(null);
export const useSetCurrentRundownFocus = () => useSetAtom(currentRundownFocus);
export const useCurrentRundownFocus = () => useAtomValue(currentRundownFocus);

const rundownMolecule = molecule((_getMol, getScope) => {
  const rundownId = getScope(RundownScope);

  const layoutAtom = atomWithStorage<RundownTableState>(
    `${rundownId}-table-state`,
    defaultTableState,
    undefined,
    {
      unstable_getOnInit: true,
    },
  );

  // Holds the rundown sequences ready & preparing
  const sequenceAtom = atom<Sequences>({ ready: null, preparing: null });

  // Holds all the rows the tanstack table will draw. These objects
  // are designed to be as small as possible - all cell data is stored in
  // instancesAtom instead.
  const rowsAtom = atom<{ ready: SlimRow[]; preparing: SlimRow[] }>((get) => {
    const sequences = get(sequenceAtom);
    return {
      ready: sequences.ready ? produceRows(sequences.ready) : [getPlaceholderRow()],
      preparing: sequences.preparing ? produceRows(sequences.preparing) : [getPlaceholderRow()],
    };
  });

  // Holds state with regards to who has focused cells within the grid
  const focusMapAtom = atom<Record<string, SessionUser>>({});

  // Holds all members rendered across the grid
  const instancesAtom = atom<AllEditableData>({ ready: {}, preparing: {} });

  // Holds all instances, where active playback state is injected.
  const enrichedInstancesAtom = atom<AllEditableData>((get) => {
    const editableData = get(instancesAtom);
    const sequences = get(sequenceAtom);

    const rPlay = sequences.ready?.playState;
    if (rPlay?.currentInstance?.mId && editableData.ready[rPlay.currentInstance.mId]) {
      // This ensures a new cumetime segment is created and consequently recalculates
      // We must read out startTime from server instead of override time on the instance
      // to avoid race conditions
      const copy = { ...editableData.ready[rPlay.currentInstance.mId] };
      copy.metadata[CUME_TIME_FIELD_KEY] = {
        manual: true,
        value: DateToTimeString(rPlay.currentInstance.startTime as string),
      };

      copy.isCurrentInstance = true;

      const newReady = {
        ...editableData.ready,
        [rPlay.currentInstance.mId]: copy,
      };
      return {
        ready: newReady,
        preparing: editableData.preparing,
      };
    }

    return editableData;
  });

  // Holds all the instances ordered according to mOrder vs mPreorder
  const orderedInstances = atom((get) => {
    const editableData = get(enrichedInstancesAtom);
    const sequences = get(sequenceAtom);
    return {
      ready: sortByMIDOrder(
        Object.values(editableData.ready),
        sequences.ready?.order ?? [],
      ) as RundownCellData[],
      preparing: sortByMIDOrder(
        Object.values(editableData.preparing),
        sequences.preparing?.order ?? [],
      ) as RundownCellData[],
    };
  });

  // Holds all segments within the Ready rundown
  const readySegments = atom<{ cume: Segment[]; back: Segment[] }>((get) => {
    const orderedMembers = get(orderedInstances);
    return {
      cume: getCumeSegments(orderedMembers.ready),
      back: getBackSegments(orderedMembers.ready),
    };
  });

  // Holds all timing info
  const timingsAtom = atom<CalculatedTimings>((get) => {
    const orderedMembers = get(orderedInstances);
    const sequences = get(sequenceAtom);
    const ready = get(readySegments);

    const currentlyPlayingInstance = sequences.ready?.playState?.currentInstance?.mId;
    return {
      ready: produceTimings(ready.cume, ready.back, currentlyPlayingInstance),
      preparing: generateTimings(orderedMembers.preparing),
    };
  });

  // Holds all timing data related to groups in the rundown
  const groupsAtom = atom<{
    ready: Record<string, SegmentWithTiming>;
    preparing: Record<string, SegmentWithTiming>;
  }>((get) => {
    const rows = get(rowsAtom);
    const instances = get(instancesAtom);
    return {
      ready: getGroupSegments(instances.ready, rows.ready),
      preparing: getGroupSegments(instances.preparing, rows.preparing),
    };
  });

  // Holds the total duration of all members in the rundown list
  const totalTimeAtom = atom((get) => {
    const editableData = get(instancesAtom);

    const readyTime = calculateTotalTime(Object.values(editableData.ready));
    const preparingTime = calculateTotalTime(Object.values(editableData.preparing));

    return {
      ready: readyTime,
      preparing: preparingTime,
    };
  });

  return {
    instancesAtom,
    timingsAtom,
    groupsAtom,
    totalTimeAtom,
    focusMapAtom,
    useReadyTiming: () => useAtomValue(readySegments),
    useTableState: () => useAtom(layoutAtom),
    useRows: () => useAtomValue(rowsAtom),
    useOrderedInstances: () => useAtomValue(orderedInstances),
    useGridData: () => useAtomValue(instancesAtom),
    useSequence: () => useAtom(sequenceAtom),
    useFocusMap: () => useAtom(focusMapAtom),
    useUpdateInstances: () => useSetAtom(instancesAtom),
    useInstancesAtom: () => useAtomValue(instancesAtom),
    useStoriesInRundown: () => useAtom(storiesAtom),
    useRundownGroups: () => useAtom(groupsAtom),
  };
});

export const useRundownV2 = () => useMolecule(rundownMolecule);
