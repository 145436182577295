import { ChangeEvent, useState } from 'react';

import useCreateOrganization from 'api/organization/useCreateOrganization';
import AutoComplete from 'components/autoCompleteBase/Autocomplete';
import Dialog from 'components/dialogs/DialogBuilder';
import { StyledTextField } from 'components/mdfEditor/fields/text/styled';
import { Box, VStack } from 'layouts/box/Box';
import { CreateOrganizationInput } from 'types/graphqlTypes';

const emptyCreateOrganizationInput: CreateOrganizationInput = {
  title: '',
  timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
};

const timezones = Intl.supportedValuesOf('timeZone').map((timezone) => ({
  id: timezone,
  value: timezone,
}));

function CreateNewOrganization({ onClose }: Readonly<{ onClose: () => void }>) {
  const { createOrganization, loading } = useCreateOrganization();
  const [organizationInput, setOrganizationInput] = useState<CreateOrganizationInput>(
    emptyCreateOrganizationInput,
  );

  const setTitle = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setOrganizationInput((pre) => ({ ...pre, title: event.target.value }));
  };
  const setDescription = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setOrganizationInput((pre) => ({ ...pre, description: event.target.value }));
  };

  const changeTimezone = (value: string) => {
    setOrganizationInput((pre) => ({ ...pre, timeZone: value }));
  };

  const onConfirm = async () => {
    await createOrganization(organizationInput);
    onClose();
  };

  return (
    <Dialog open onClose={onClose}>
      <Dialog.Header>Create new Organization</Dialog.Header>
      <Dialog.Body>
        <VStack gap="4px" justifyContent="flex-start" alignItems="flex-start">
          <div>Organization name</div>
          <StyledTextField
            error={false}
            autoFocus
            variant="filled"
            fullWidth
            placeholder="Type organization name"
            value={organizationInput.title}
            onChange={setTitle}
            required
          />
          <div>Organization description</div>
          <StyledTextField
            multiline
            minRows={4}
            maxRows={4}
            error={false}
            variant="filled"
            fullWidth
            placeholder="Type organization description"
            value={organizationInput.description}
            onChange={setDescription}
          />
          <div>Default timezone</div>
          <Box width="100%">
            <AutoComplete
              onChange={changeTimezone}
              options={timezones}
              value={organizationInput.timeZone}
            />
          </Box>
        </VStack>
      </Dialog.Body>
      <Dialog.Footer>
        <Dialog.CancelButton />
        <Dialog.ConfirmButton
          onConfirm={onConfirm}
          disabled={loading || organizationInput.title === '' || organizationInput.timeZone === ''}
          loading={loading}
        />
      </Dialog.Footer>
    </Dialog>
  );
}

export default CreateNewOrganization;
