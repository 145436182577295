/**
 *  Hook for sending a story to archive *
 */

import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';

import useUpdateLeftSidebarCache from 'hooks/useUpdateLeftSidebarCache';
import memberTypes from 'operations/memberTypes';
import { Story } from 'types';

const ARCHIVE_STORY = gql`
  mutation ArchiveStory($input: ArchiveStoryInput) {
    archiveStory(input: $input) {
      mId
    }
  }
`;

const getMemberType = (memberType: string) => {
  if (memberType === memberTypes.STORY) return memberTypes.USER_STORY;
  if (memberType === memberTypes.PITCH) return memberTypes.USER_PITCH;
  return memberType;
};

const getUpdatedMemberType = (memberType: string) => {
  if (memberType === memberTypes.STORY) return memberTypes.ARCHIVED_STORY;
  if (memberType === memberTypes.PITCH) return memberTypes.ARCHIVED_PITCH;
  if (memberType === memberTypes.RESTRICTED_STORY) return memberTypes.ARCHIVED_RESTRICTED_STORY;
  if (memberType === memberTypes.RESTRICTED_PITCH) return memberTypes.ARCHIVED_RESTRICTED_PITCH;
  return memberType;
};

const useArchiveStory = () => {
  const [mutate] = useMutation<{ archiveStory: Story }>(ARCHIVE_STORY);
  const [update] = useUpdateLeftSidebarCache();
  const archive = useCallback(
    async (mId: string, memberType: string, story?: Story) => {
      await mutate({
        variables: {
          input: {
            mId,
          },
        },
        update: (cache) => {
          cache.modify({
            id: cache.identify({ mId: mId, mRefId: mId }),
            fields: { mType: () => getUpdatedMemberType(memberType) },
          });
          update(story, getMemberType(memberType), false);
        },
      });
    },
    [mutate, update],
  );

  return [archive];
};

export default useArchiveStory;
