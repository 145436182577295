import PrioritySelect from 'features/storyHub/components/prioritySelect/PrioritySelect';
import { MemberType } from 'types/graphqlTypes';

import { GroupInline } from '../Group';

function Priority({ story }: Readonly<{ story: MemberType }>) {
  return (
    <GroupInline label="Priority">
      <PrioritySelect item={story} height="20px" borderRadius="6px" />
    </GroupInline>
  );
}

export default Priority;
