import { useAtomValue, useSetAtom } from 'jotai';
import { atomWithReset, useResetAtom } from 'jotai/utils';

import type { ExtendedFeedItem } from 'features/feedViewer/FeedViewer';
import type { MemberType } from 'types/graphqlTypes';
import type { ParsedMemberType } from 'types/members';

export type PreviewType = MemberType | ExtendedFeedItem | null | ParsedMemberType | string;

const previewAtom = atomWithReset<PreviewType>(null);

/** Set preview atom, will be displayed in the drawer */
export const useSetPreview = () => useSetAtom(previewAtom);
/** Get preview atom value. Used by the drawer to display content */
export const usePreviewValue = () => useAtomValue(previewAtom);
/** Close the drawer by resetting the preview atom to null */
export const useClosePreview = () => useResetAtom(previewAtom);
