import { useEffect, useState } from 'react';

import { useSearch } from 'api/search';
import { VStack } from 'layouts/box/Box';
import { Loading } from 'screens/loading/loading-styled';

import { baseToolbarState } from './Activity';
import GroupedAction from './GroupedAction';

export default function Past({ memberId }: Readonly<{ memberId: string }>) {
  const [isFetchData, setIsFetchData] = useState(false);
  const now = new Date();
  const sParams = {
    skip: false,
    searchString: '',
    toolbarState: {
      ...baseToolbarState,
      assignedIds: [memberId],
      rangeByTopic: [
        {
          topic: 'scheduledAt',
          range: {
            to: now.toISOString().split('T')[0],
          },
        },
      ],
    },
    mdfId: undefined,
  };

  const { items, loading, handleLoadMore, hasMore, nextToken } = useSearch(sParams);

  useEffect(() => {
    if (isFetchData && hasMore) {
      void handleLoadMore();
    }
  }, [isFetchData, handleLoadMore]);
  if (loading)
    return (
      <VStack height="100%" width="100%" justifyContent="center" alignItems="center">
        <Loading />
      </VStack>
    );
  return (
    <GroupedAction
      activityList={items}
      setIsFetchData={setIsFetchData}
      hasMore={hasMore}
      nextToken={nextToken}
    />
  );
}
