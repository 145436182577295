import { useMemo, useRef } from 'react';
import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { createScope, molecule, useMolecule } from 'jotai-molecules';
import { ReactEditor } from 'slate-react';
import type { Destination, EditorFontSize, EditorValue, Instance, Platform } from 'types';

import { useInstancePreviewMolecule } from 'features/preview/components/instancePreviewContext';
import { useRundownMolecule } from 'screens/rundown/store/rundown';
import { useStoryPaneMolecule } from 'screens/storyV2/store/storyPane';
import type { ExportContentInput, MMetaDataField } from 'types/graphqlTypes';
import { PlatformVariant } from 'utils/mergePlatforms';

export const InstanceScope = createScope<string | null>(null);

export const instanceMolecule = molecule((_getMol, getScope) => {
  getScope(InstanceScope);

  const { scope: storyScope } = useStoryPaneMolecule();
  const { scope: previewScope } = useInstancePreviewMolecule();
  const { scope: rundownScope } = useRundownMolecule();

  const instanceAtom = atom<Instance | null>(null);
  const savingInstanceAtom = atom(false);
  const hasChangesAtom = atom(false);
  const metadataAtom = atom<MMetaDataField[]>([]);
  const platformAtom = atom<Platform | null>(null);
  const platformVariantAtom = atom<PlatformVariant | null>(null);
  const isCancelledAtom = atom(false);
  const currentDestinationAtom = atom<Destination | null>(null);
  const disableEditAtom = atom(false);
  const publishingPointAtom = atom((get) => {
    const instance = get(instanceAtom);
    return instance?.mProperties?.platform ?? 'default';
  });
  const hideTemplateOptionsAtom = atom(false);
  const viewAtom = atom<'edit' | 'preview'>('edit');
  const metadataViewAtom = atomWithStorage('editor-metadata-view', true);
  const showCmsEditDialog = atom(false);
  const editorDataAtom = atom<EditorValue | null>(null);
  const skipDownloadAtom = atom(false);
  const lockedByAtom = atom<string>('Someone');
  const lockedByCurrentUserAtom = atom(false);
  const lockingAtom = atom(false);
  const readLockAtom = atom(false);
  const writeLockAtom = atom(false);
  const ownsLockElsewhereAtom = atom(false);
  const publishAnchorElAtom = atom<EventTarget | null>(null);
  const editorAtom = atom<ReactEditor | null>(null);
  const contentDataAtom = atom<EditorValue | null>(null);
  const contentLoadingAtom = atom(false);
  const wordCountAtom = atom(0);
  const durationsAtom = atom<{ clip: string; script: string; total: string }>({
    clip: '',
    script: '',
    total: '',
  });
  const downloadDataAtom = atom<ExportContentInput | null>(null);
  const showPrintDialog = atom(false);
  const editorFontSizeAtom = atom<EditorFontSize>('small');

  const initialContentRef = useRef<EditorValue | null>(null);
  const editorValueRef = useRef<EditorValue | null>(null);
  const writeLockRef = useRef<boolean>(false);
  const instanceRef = useRef<Instance | null>(null);
  const lockedIdRef = useRef<string | null>(null);
  const notificationRef = useRef<HTMLDivElement | null>(null);
  const loadingRef = useRef<boolean>(false);
  const contentRefetchRef = useRef<(() => Promise<EditorValue | null>) | null>(null);
  const beforeUnloadRef = useRef<
    ((lastInstance: Instance | null, latestContent: EditorValue | null) => Promise<void>) | null
  >(null);

  const currentEditingScope = useMemo(() => {
    if (storyScope) return storyScope;
    if (previewScope) return previewScope;
    if (rundownScope) return rundownScope;
    return 'instance_scope';
  }, [previewScope, rundownScope, storyScope]);

  return {
    useInstanceValue: () => useAtomValue(instanceAtom),
    useSetInstance: () => useSetAtom(instanceAtom),
    useIsSavingInstance: () => useAtom(savingInstanceAtom),
    useHasChanges: () => useAtom(hasChangesAtom),
    useMetadata: () => useAtom(metadataAtom),
    usePlatform: () => useAtom(platformAtom),
    usePlatformVariant: () => useAtom(platformVariantAtom),
    useIsCancelled: () => useAtom(isCancelledAtom),
    useCurrentDestination: () => useAtom(currentDestinationAtom),
    useDisableEdit: () => useAtom(disableEditAtom),
    usePublishingPointValue: () => useAtomValue(publishingPointAtom),
    useHideTemplateOptions: () => useAtom(hideTemplateOptionsAtom),
    useView: () => useAtom(viewAtom),
    useMetadataView: () => useAtom(metadataViewAtom),
    useShowCmsEditDialog: () => useAtom(showCmsEditDialog),
    useEditorData: () => useAtom(editorDataAtom),
    useSkipDownload: () => useAtom(skipDownloadAtom),
    useLockedBy: () => useAtom(lockedByAtom),
    useLockedByCurrentUser: () => useAtom(lockedByCurrentUserAtom),
    useLocking: () => useAtom(lockingAtom),
    useReadLock: () => useAtom(readLockAtom),
    useWriteLock: () => useAtom(writeLockAtom),
    useOwnsLockElsewhere: () => useAtom(ownsLockElsewhereAtom),
    usePublishAnchorEl: () => useAtom(publishAnchorElAtom),
    useEditor: () => useAtom(editorAtom),
    useInstanceContentData: () => useAtom(contentDataAtom),
    useInstanceContentLoading: () => useAtom(contentLoadingAtom),
    useWordCount: () => useAtom(wordCountAtom),
    useDurations: () => useAtom(durationsAtom),
    useDownloadData: () => useAtom(downloadDataAtom),
    usePrintDialog: () => useAtom(showPrintDialog),
    useEditorFontSize: () => useAtom(editorFontSizeAtom),
    initialContentRef,
    writeLockRef,
    editorValueRef,
    instanceRef,
    lockedIdRef,
    contentRefetchRef,
    notificationRef,
    onDoneHotkeyVersion: 'ManualSave',
    onSaveHotkeyVersion: 'UserInitiated',
    loadingRef,
    beforeUnloadRef,
    currentEditingScope,
  };
});
export const useInstanceMolecule = () => useMolecule(instanceMolecule);
