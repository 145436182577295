import { READ_SPEED_FIELD_KEY } from 'features/mdf/mdf-defaults';
import { MemberType, Metadata, MMetaDataField } from 'types/graphqlTypes';

const getHostReadSpeed = (metadata: MMetaDataField[], defaultReadSpeed = 150) => {
  if (!Array.isArray(metadata)) return defaultReadSpeed;
  const readSpeedField = metadata.find((val) => val.key === 'hostReadSpeed');
  const rr = Number(readSpeedField?.value);
  return !isNaN(rr) && rr > 0 ? rr : defaultReadSpeed;
};

export const getReadSpeedFromPresenter = (
  presenter: MemberType | undefined,
  defaultReadSpeed: number | string | undefined,
) => {
  const parsedDefault = !isNaN(Number(defaultReadSpeed)) ? Number(defaultReadSpeed) : 160;
  if (!presenter) return parsedDefault;
  try {
    const presenterMetadata = JSON.parse(presenter.metadata ?? '{}') as Metadata;
    const rr = Number(presenterMetadata[READ_SPEED_FIELD_KEY]);
    return !isNaN(rr) && rr > 0 ? rr : parsedDefault;
  } catch (err) {
    return parsedDefault;
  }
};

export default getHostReadSpeed;
