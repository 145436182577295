import { DragPreviewImage } from 'react-dnd';
import styled from '@emotion/styled';
import { Skeleton } from '@material-ui/lab';

import { publishingPoints } from 'assets/icons/publishingPoints';
import Avatar from 'components/avatar/Avatar';
import Text from 'components/text';
import useCommonDrag from 'hooks/useCommonDrag';
import useCustomDateTimeUtils from 'hooks/useCustomDateTimeUtils';
import { HStack } from 'layouts/box/Box';
import { useStoryPaneMolecule } from 'screens/storyV2/store/storyPane';
import { useSetPreview } from 'store/preview';
import { Instance as InstanceType } from 'types';
import dndTypes from 'utils/dndTypes';

const InstanceWrapper = styled(HStack)`
  cursor: pointer;
  width: 100%;
  height: 32px;
  padding-inline: 12px 12px;
  gap: 6px;
  :hover {
    background-color: ${({ theme }) => theme.palette.dina.hoverOverlay};
  }
`;

export function InstanceSkeleton() {
  return (
    <HStack width="100%" height="32px" padding="0 12px" gap="6px">
      <Skeleton animation="wave" variant="rect" height="24px" width="24px" />
      <Skeleton animation="wave" variant="text" style={{ flex: 1 }} />
      <Skeleton animation="wave" variant="text" width="64px" />
    </HStack>
  );
}

function Instance({ instance }: Readonly<{ instance: InstanceType }>) {
  const { distanceInAbbreviatedWords } = useCustomDateTimeUtils();
  const setPreview = useSetPreview();

  const { useMoveItemDialog } = useStoryPaneMolecule();
  const [, setMoveItemProps] = useMoveItemDialog();

  const { dragRef, previewConnect, previewSrc } = useCommonDrag([instance.mId], setMoveItemProps, {
    id: instance.mId,
    type: dndTypes.STORY_INSTANCE,
    storyId: instance.mStoryId,
    unscoped: true,
  });

  return (
    <InstanceWrapper ref={dragRef} onClick={() => setPreview(instance)}>
      <DragPreviewImage connect={previewConnect} src={previewSrc} />
      <Avatar
        size={20}
        src={publishingPoints[instance.mProperties.platform]}
        borderRadius={4}
        borderColor="transparent"
      />
      <HStack flex="1">
        <Text variant="listItemLabel" color="highEmphasis" truncate style={{ width: '100%' }}>
          {instance.mTitle}
        </Text>
      </HStack>
      <Text variant="captionMedium">{distanceInAbbreviatedWords(instance.mUpdatedAt)}</Text>
    </InstanceWrapper>
  );
}

export default Instance;
