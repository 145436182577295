import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { CellContext } from '@tanstack/react-table';
import { Dictionary } from 'lodash';

import { ReactComponent as Chevron } from 'assets/icons/systemicons/arrows/disclosurearrow_discreet_down.svg';
import { ReactComponent as ChevronActive } from 'assets/icons/systemicons/arrows/disclosurearrow_discreet_up.svg';
import Button from 'components/buttons/button/Button';
import DropdownMenu from 'components/dropdownMenu/DropdownMenu';
import Text from 'components/text';
import { ParsedOrderType } from 'features/mdf/useGetMdfColumns';
import { Color } from 'features/reusableStyled';
import { getStatusColors, getStatusOption } from 'features/sidepanel/utils/utils';
import { HStack, VStack } from 'layouts/box/Box';
import { FieldValue } from 'types/graphqlTypes';
import { OrderFormMemberType } from 'types/memberTypes/order_form';

type StatusCellProps = Pick<CellContext<ParsedOrderType, string>, 'row' | 'table'> & {
  orderFormMap: Dictionary<OrderFormMemberType>;
};

function StatusCell({ row, orderFormMap, table }: Readonly<StatusCellProps>) {
  const { mStatus, mFormId } = row.original;

  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState<string | undefined>(mStatus);

  const updateData = table.options.meta?.updateData;

  const form = orderFormMap[mFormId];

  const options = useMemo(() => {
    return form !== undefined ? getStatusOption(form) : [];
  }, []);

  const colors = useMemo(() => {
    if (!form) return {};
    return getStatusColors(form);
  }, [form]);

  const selectedAlternative = useMemo(() => {
    if (!selected) return options?.[0];
    return options.find((alt) => alt.value === selected);
  }, [selected]);

  const doSetValue = useCallback(
    (ev: Event, val: FieldValue) => {
      ev?.stopPropagation();

      if (typeof val === 'string') {
        setSelected(val);
        updateData?.(row.original, [{ fieldId: 'mStatus', value: val }]);
      }
    },
    [updateData],
  );

  const onOpenChange = useCallback((val: boolean) => {
    setOpen(val);
  }, []);

  /** Sync external changes */
  useEffect(() => setSelected(mStatus), [mStatus]);

  return (
    <DropdownMenu onOpenChange={onOpenChange}>
      <DropdownMenu.Trigger>
        <VStack gap="4px">
          <Button variant="outlined" usage="outlined" size="sm">
            <HStack gap="4px">
              {colors[selected ?? ''] && (
                <Color $color={colors[selected ?? '']} $size={12} style={{ marginRight: '2px' }} />
              )}
              <Text variant="listItemLabel" truncate>
                {selectedAlternative?.label ?? selected}
              </Text>
            </HStack>
            {open ? <ChevronActive /> : <Chevron />}
          </Button>
        </VStack>
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content style={{ minWidth: '120px' }} align="start">
          <div onClick={(ev) => ev.stopPropagation()}>
            {options.map((alt) => (
              <DropdownMenu.Item onSelect={(ev) => doSetValue(ev, alt.value)} key={alt.id}>
                <HStack width="100%" gap="4px">
                  {colors[alt?.value ?? ''] && (
                    <Color $color={colors[alt?.value ?? '']} $size={12} />
                  )}
                  {alt.label}
                </HStack>
              </DropdownMenu.Item>
            ))}
          </div>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu>
  );
}

export default memo(StatusCell);
