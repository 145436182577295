import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
  InputAdornment,
  List as MuiList,
  MenuItem as MuiMenuItem,
  Paper,
  Popover,
  TextField,
} from '@material-ui/core';

import { ReactComponent as SnippetsSvg } from 'assets/icons/search/snippet.svg';
import { ReactComponent as TaskSvg } from 'assets/icons/search/task.svg';
import { ReactComponent as ArrowDownSvg } from 'assets/icons/systemicons/arrows/disclosurearrow_discreet_down.svg';
import { ReactComponent as ArrowUpSvg } from 'assets/icons/systemicons/arrows/disclosurearrow_discreet_up.svg';
import { ReactComponent as CloseIconSvg } from 'assets/icons/systemicons/close.svg';
import transientOptions from 'theme/helpers';

import { Position } from '../../../editor/components/mdf/hook/useCustomCombobox';
interface ListWrapperProps {
  position: Position<string>;
}
export const Info = styled('p', transientOptions)<{ $color?: string }>`
  ${({ theme }) => theme.typography.dina.overline};
  color: ${({ theme, $color }) => $color ?? theme.palette.dina.highEmphasis};
  font-weight: normal;
  margin: 0;
  margin-left: 32px;
  text-align: right;
  flex: 1;
`;
export const Header = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline: 12px;
  background-color: ${({ theme }) => theme.palette.dina.backgroundHover};
  border-bottom: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
  height: 40px;
  user-select: none;
`;

export const Title = styled('div')`
  ${({ theme }) => theme.typography.dina.instanceDialogHeader};
  color: ${({ theme }) => theme.palette.dina.highEmphasis};
  width: 100%;
`;
export const Content = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 8px;
`;
export const StyledTextField = styled(TextField, transientOptions)<{
  exists?: boolean;
  textColor?: string;
}>`
  svg path {
    fill: ${({ theme }) => theme.palette.dina.highEmphasis};
  }

  .MuiFormHelperText-contained {
    margin: 2px 0 -2px 2px;
    color: ${({ theme }) => theme.palette.dina.statusWarning};
  }

  &.MuiFormControl-root {
    width: 100%;
  }

  .MuiInputBase-root {
    width: 100%;
    padding: 4px 0px 4px 4px;
    font-size: 14px;
    color: ${({ theme }) => theme.palette.dina.highEmphasis};

    &.Mui-disabled {
      color: ${({ theme }) => theme.palette.dina.disabled};
    }
  }
  .MuiInputBase-input {
    padding: 4px 0px 4px 4px;
    font-size: 14px;
    color: ${({ theme, exists, textColor }) => {
      if (textColor) return textColor;
      if (exists) return 'red';
      return theme.palette.dina.highEmphasis;
    }};
  }
  .MuiFilledInput-root {
    padding: 2px !important;
    width: 100%;
    display: inline-flex;
    flex-flow: row wrap;
    min-height: 24px;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.palette.dina.inputBackground};
    border: 1px solid ${({ theme }) => theme.palette.dina.inputBorderResting};
    &:hover {
      background-color: ${({ theme }) => theme.palette.dina.hoverOverlay};
    }
    &.Mui-focused {
      ${({ theme }) => css`
        background: ${theme.palette.dina.statusOnFocusedDiscreetFill};
        border-color: ${theme.palette.dina.statusOnFocused} !important;
      `}
    }
    &.Mui-error {
      border-color: ${({ theme }) => theme.palette.dina.warningBorder};
    }
  }
  .MuiInputLabel-root {
    width: 100%;
    color: white;
    background-color: transparent;
    padding: 0 4px;
    font-size: 14px;
    margin: -8px 8px -8px 8px;
    transform: none;
  }

  textarea {
    width: 100%;
    font-size: 14px;
    padding: 4px !important;

    &::-webkit-input-placeholder,
    &::-moz-placeholder,
    &:-ms-input-placeholder,
    &::-ms-input-placeholder {
      color: ${({ theme }) => theme.palette.dina.disabled};
    }
  }
  .MuiInputBase-inputAdornedEnd {
    padding: 4px 0px 4px 4px !important;
  }
  .MuiFilledInput-underline {
    &:hover:not($disabled):not($focused):not($error):before {
      border-bottom: none;
    }
    &:after {
      border-color: transparent;
    }
    &:before {
      border-color: transparent;
    }
  }
`;
export const MUIPopover = styled(Popover)`
  .MuiPopover-paper {
    background-color: ${({ theme }) => theme.palette.dina.surfaceAppBackgroundNavLevel1};
    border-radius: 8px;
  }
`;
export const MUIInputAdornment = styled(InputAdornment)`
  margin-left: auto;
  margin-right: 4px;
  margin-top: -24px;
`;
export const DialogWrapper = styled('div')`
  width: 360px;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.dina.surfaceDialog};
  overflow: hidden;
`;
export const TextButton = styled('button')`
  height: 32px;
  position: relative;
  cursor: pointer;
  padding: 0px;
  margin: 0px;
  background-color: transparent;
  border: none;
  outline: none;
  color: ${({ theme }) => theme.palette.dina.mediumEmphasis};
  :hover {
    background-color: transparent;
    border: none;
    outline: none;
    color: ${({ theme }) => theme.palette.dina.highEmphasis};
  }
  padding: 0px 8px;
  max-width: 150px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
export const Footer = styled('div')`
  margin-top: 4px;
  height: 46px;
  border-top: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
  padding-inline: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
export const Close = styled('div')`
  position: relative;
  left: 4px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.7;
  background-color: transparent;
  cursor: pointer;
  border: none;
  &:hover {
    opacity: 1;
  }
  &:focus {
    opacity: 1;
    outline: none;
  }
`;
export const CloseIcon = styled(CloseIconSvg)``;
export const List = styled(MuiList)`
  max-height: 350px;
  min-height: 350px;
  overflow-y: auto;
`;
export const ListWrapper = styled(Paper)<ListWrapperProps>`
  position: absolute;
  ${({ position }) =>
    position.bottom
      ? {
          bottom: position.bottom,
          left: position.left,
        }
      : {
          top: position.top,
          left: position.left,
        }};
  z-index: 1400;
  background-color: ${({ theme }) => theme.palette.dina.surfaceAppBackgroundNavLevel1};
  border-radius: 4px;
  overflow: hidden;
`;
export const MenuItem = styled(MuiMenuItem)`
  color: ${({ theme }) => theme.palette.dina.highEmphasis};
  height: 32px;
  padding: 0 8px;
  svg {
    margin-left: 8px;
  }
`;

export const TaskIcon = styled(TaskSvg, transientOptions)<{ $selected?: boolean }>`
  path {
    fill: ${({ theme, $selected }) => ($selected ? 'white' : theme.palette.dina.highEmphasis)};
    fill-opacity: 0.8;
  }
  rect {
    stroke: ${({ theme, $selected }) => ($selected ? 'white' : theme.palette.dina.highEmphasis)};
    stroke-opacity: 0.7;
  }
`;

export const ArrowDown = styled(ArrowDownSvg, transientOptions)<{ $selected?: boolean }>`
  path {
    fill: ${({ theme, $selected }) => ($selected ? 'white' : theme.palette.dina.highEmphasis)};
    fill-opacity: 0.8;
  }
  rect {
    stroke: ${({ theme, $selected }) => ($selected ? 'white' : theme.palette.dina.highEmphasis)};
    stroke-opacity: 0.7;
  }
`;

export const ArrowUp = styled(ArrowUpSvg, transientOptions)<{ $selected?: boolean }>`
  path {
    fill: ${({ theme, $selected }) => ($selected ? 'white' : theme.palette.dina.highEmphasis)};
    fill-opacity: 0.8;
  }
  rect {
    stroke: ${({ theme, $selected }) => ($selected ? 'white' : theme.palette.dina.highEmphasis)};
    stroke-opacity: 0.7;
  }
`;

export const SnippetIcon = styled(SnippetsSvg, transientOptions)<{
  $selected?: boolean;
  $color?: string;
}>`
  path {
    fill: ${({ theme, $color }) => $color ?? theme.palette.dina.highEmphasis};
  }
  rect {
    stroke: ${({ theme, $color }) => $color ?? theme.palette.dina.highEmphasis};
  }
`;
