import { FormEventHandler, useReducer } from 'react';

import InputField from 'components/inputField/InputField';
import { useAuthContext } from 'contexts/AuthContext';

import { useCurrentForm, useIsReset, useLoading, useTempUser } from '../store/atom';

import {
  Form,
  FormContainer,
  FormTitle,
  InfoText,
  LinksWrapper,
  StyledButton,
  StyledLink,
} from './forms-styled';
import { getErrorText } from './utils';

const INITIAL_STATE = {
  code: '',
  password: '',
  codeDescription: 'Type your Verification Code here...',
  passwordDescription: 'Type your New Password here...',
  severity: 'mandatory',
};

const reducer = (
  state: typeof INITIAL_STATE,
  { type, payload }: { type: string; payload: string },
) => {
  switch (type) {
    case 'updateCode':
      return state.severity === 'error'
        ? { ...INITIAL_STATE, code: payload, password: state.password }
        : { ...state, code: payload };
    case 'updatePassword':
      return state.severity === 'error'
        ? { ...INITIAL_STATE, code: state.code, password: payload }
        : { ...state, password: payload };
    case 'resetError':
      return {
        ...state,
        severity: 'error',
        codeDescription: payload,
        passwordDescription: payload,
      };
    default:
      throw new Error(`Action ${type} is not defined`);
  }
};

const ResetPassword = () => {
  const { forgotPasswordSubmit } = useAuthContext();
  const [tempUser] = useTempUser();
  const [, isReset] = useIsReset();
  const [loading, setLoading] = useLoading();
  const [, setCurrentForm] = useCurrentForm();
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  async function doReset(username: string) {
    setLoading(true);
    try {
      await forgotPasswordSubmit?.(username, state.code.trim(), state.password.trim());
      isReset(true);
      setLoading(false);
      setCurrentForm('DEFAULT_LOGIN');
    } catch (error) {
      dispatch({ type: 'resetError', payload: getErrorText(error) });
      setLoading(false);
    }
  }
  const handleReset: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    if (typeof tempUser === 'string') {
      void doReset(tempUser);
    }
  };

  return (
    <FormContainer>
      <FormTitle>Reset Password</FormTitle>
      <InfoText>A Verification Code has been sent to your email</InfoText>
      <Form onSubmit={handleReset}>
        <InputField
          label="Verification Code"
          type="text"
          autoComplete="verification-code"
          value={state.code}
          severity={state.severity}
          description={state.codeDescription}
          onChange={(value: string) => dispatch({ type: 'updateCode', payload: value })}
        />
        <InputField
          label="New Password"
          type="password"
          autoComplete="new-password"
          value={state.password}
          severity={state.severity}
          description={state.passwordDescription}
          onChange={(value: string) => dispatch({ type: 'updatePassword', payload: value })}
        />
        <StyledButton
          type="submit"
          disabled={state.severity === 'error' || !(state.code && state.password) || loading}
        >
          SUBMIT
        </StyledButton>
      </Form>
      <LinksWrapper>
        <StyledLink component="button" onClick={() => setCurrentForm('DEFAULT_LOGIN')}>
          Back to Login
        </StyledLink>
      </LinksWrapper>
    </FormContainer>
  );
};

export default ResetPassword;
