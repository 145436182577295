import gql from 'graphql-tag';

export default gql`
  query GetOrganizations($input: GetOrganizationsInput) {
    getOrganizations(input: $input) {
      id
      type
      title
      description
      mdfId
      metadata
      createdAt
      createdById
      updatedAt
      updatedById
      timeZone
      isArchived
      isRestricted
    }
  }
`;
