/**
 *  Hook for getting a single member
 */
import { gql, useQuery } from '@apollo/client';

import { getMembersQuery } from 'operations/queryVariables';
import { Form } from 'types/forms/forms';

interface FetchMember {
  getMembers: Form[];
}

export const GET_FORM_FROM_MEMBER = gql`
  query GetRundownForms($input: GetMemberInput) {
    getMembers(input: $input) {
      mId
      mRefId
      mTitle
      mType
      mProperties {
        __typename
        ... on MetadataFormType {
          fields {
            id
            name
            type
            description
            label
            options {
              id
              title
              value
              icon
            }
          }
        }
      }
    }
  }
`;

// We only support one form attached to a specific member.
const useGetFormsFromMember = (mId: string | null) => {
  const { data, error, loading, refetch } = useQuery<FetchMember>(GET_FORM_FROM_MEMBER, {
    variables: getMembersQuery(mId ?? '', 'form'),
    fetchPolicy: 'network-only',
    skip: !mId,
  });
  return { forms: data?.getMembers ?? [], error, loading, refetch };
};

export default useGetFormsFromMember;
