import styled from '@emotion/styled';
import { Skeleton } from '@material-ui/lab';

import { Block } from 'api/mdfBlocks/types';
import { ReactComponent as PlanIcon } from 'assets/icons/systemicons/calendar_small_off.svg';
import Text from 'components/text';
import { HStack } from 'layouts/box/Box';

const PlanWrapper = styled(HStack)`
  cursor: pointer;
  width: 100%;
  height: 32px;
  padding-inline: 10px 12px;
  gap: 4px;
  :hover {
    background-color: ${({ theme }) => theme.palette.dina.hoverOverlay};
  }
`;

export function PlanSkeleton() {
  return (
    <HStack width="100%" height="32px" padding="0 12px" gap="6px">
      <Skeleton animation="wave" variant="rect" height="24px" width="24px" />
      <Skeleton animation="wave" variant="text" style={{ flex: 1 }} />
    </HStack>
  );
}

function Plan({ plan }: Readonly<{ plan: Block }>) {
  return (
    <PlanWrapper>
      <PlanIcon />
      <HStack flex="1">
        <Text variant="listItemLabel" color="highEmphasis" truncate style={{ width: '100%' }}>
          {plan.mTitle}
        </Text>
      </HStack>
    </PlanWrapper>
  );
}

export default Plan;
