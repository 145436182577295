import { useCallback } from 'react';

import { useGetAllPlatforms } from './useGetPlatforms';

const usePlatformWorkflow = () => {
  const [platforms] = useGetAllPlatforms();

  const getScheduledState = useCallback(
    (platformName?: string | null) => {
      if (!platformName) return null;
      const platform = platforms?.getPlatforms?.find((p) => p.mRefId === platformName);

      return platform?.workflowSettings?.stateOnSchedule ?? null;
    },
    [platforms],
  );

  const getApprovalState = useCallback(
    (platformName?: string | null) => {
      if (!platformName) return null;
      const platform = platforms?.getPlatforms?.find((p) => p.mRefId === platformName);

      return platform?.workflowSettings?.approvalState ?? null;
    },
    [platforms],
  );

  const getCopyState = useCallback(
    (platformName?: string | null) => {
      if (!platformName) return null;
      const platform = platforms?.getPlatforms?.find((p) => p.mRefId === platformName);

      return platform?.workflowSettings?.stateOnCopy ?? null;
    },
    [platforms],
  );

  return { getApprovalState, getScheduledState, getCopyState };
};

export default usePlatformWorkflow;
