import { useCallback } from 'react';
import { gql, useMutation } from '@apollo/client';
import { capitalize } from 'lodash';

import { getErrorMessage } from 'api/commonKeys';
import useToast from 'components/toast/useToast';
import { ProviderDTO } from 'types/graphqlTypes';
import useLogger from 'utils/useLogger';

import { writeProviderToCache } from './useCreateProvider';

const UPDATE_PROVIDER = gql`
  mutation UpdatePlatformProvider($input: UpdatePlatformProviderInput) {
    updatePlatformProvider(input: $input) {
      id
      title
      baseUrl
      endPoints {
        id
        url
      }
      auth {
        type
        headers {
          tenant
          apikeyId
        }
      }
    }
  }
`;

interface UpdateProvider {
  updatePlatformProvider: ProviderDTO;
}

interface UpdateInput {
  input: ProviderDTO;
}

export const useUpdateProvider = () => {
  const { toast } = useToast();
  const [updateMutation] = useMutation<UpdateProvider, UpdateInput>(UPDATE_PROVIDER);
  const logger = useLogger('UpdateProvider');
  const updateProvider = useCallback(
    async (input: ProviderDTO) => {
      try {
        const result = await updateMutation({
          variables: {
            input: {
              ...input,
            },
          },
          update: (proxy, mutationResult) => {
            toast({
              title: 'Updated ' + input.id,
              description: `${capitalize(input.id)}  updated successfully`,
              type: 'success',
            });
            const updatedProvider = mutationResult.data?.updatePlatformProvider;
            if (updatedProvider) {
              writeProviderToCache(proxy, updatedProvider);
            }
          },
        });
        return result?.data?.updatePlatformProvider;
      } catch (err) {
        logger.log(getErrorMessage(err, input));
      }
    },
    [logger, toast, updateMutation],
  );

  return { updateProvider };
};
