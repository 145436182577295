/* eslint-disable react/no-array-index-key */
import React, { memo, useState, useMemo, useCallback, useEffect } from 'react';
import { useSlate, useReadOnly } from 'slate-react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { elementTypes } from 'components/editor/constants/types';
import useGetFieldsForBlock from 'hooks/useGetFieldsForBlock';
import fieldEnums from 'utils/constants/fieldEnums';
import stringifyList from 'components/editor/components/tags/utils/stringifyList';
import { checkIfDragDisabled } from 'components/editor/utils';
import AutoComplete from 'components/autoCompleteBase';
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import { useEditorContext } from 'components/editor/EditorContext';
import { ReactComponent as TagIconComponent } from 'assets/icons/systemicons/editor/hashtags.svg';
import updateBlock from 'components/editor/utils/updateBlock';
import useChangeCollapse from 'components/editor/hooks/useChangeCollapse';
import Box from '../box';
import ChipGroup from './components/chipGroup';
import DragAndDrop from '../dragAndDrop';
import getContent, { checkIfString } from './utils/getContent';
import useSettingsValue from 'hooks/useSettingsValue';

const AutoCompleteBase = memo(AutoComplete);

export const TagIcon = styled(TagIconComponent)`
  margin: 8px;
`;

export const ContentWrapper = styled('div')`
  margin-bottom: 16px;
`;

const Tags = ({ attributes, children, element, direction }) => {
  const [getFieldsForBlock] = useGetFieldsForBlock();
  const { data } = element;
  const editor = useSlate();
  const readOnly = useReadOnly();
  const { update, variant } = useEditorContext();
  const [getSettings] = useSettingsValue();

  const allowOnlyPredefinedTags =
    variant === 'cms' ? true : getSettings(`app.instance.${variant}.predefinedTags`) === 'true';

  const {
    content: dataContent = [],
    enableAutocomplete = allowOnlyPredefinedTags,
    collapsed = false,
  } = data;
  const [onChangeCollapse] = useChangeCollapse(element);

  /** add backward compatibility */
  const content = useMemo(() => getContent(dataContent), [dataContent]);
  const id = variant === 'cms' ? fieldEnums.CUSTOM_TAGS : `${variant}-${fieldEnums.CUSTOM_TAGS}`;
  const field = enableAutocomplete && getFieldsForBlock(id, { options: [] });
  const options = field?.options || [];
  const [value, setValue] = useState(null);

  const [inputValue, setInputValue] = useState('');

  const updateTags = useCallback(
    (updatedData) => {
      updateBlock(editor, element, updatedData, update, false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [element, update],
  );

  const handleChange = useCallback(
    (event, newValue) => {
      event.preventDefault();
      /** to filter out unnecessarry fields i.e. __typename and title */
      const newTag = checkIfString(newValue)
        ? { id: newValue, value: newValue }
        : { id: newValue.id, value: newValue.value };

      if (newTag) {
        const isIncluded = enableAutocomplete && content.find((opt) => opt.value === newTag.value);

        if (!isIncluded) {
          const updatedData = {
            ...data,
            content: [...data.content, newTag],
          };
          updateTags(updatedData);
        }
        setInputValue('');
        setValue(null);
      }
    },
    [content, data, enableAutocomplete, updateTags],
  );

  const removeTag = useCallback(
    (event, index) => {
      event.preventDefault();
      const updatedData = {
        ...data,
        content: content.filter((_, pos) => pos !== index),
      };
      updateTags(updatedData);
    },
    [content, data, updateTags],
  );

  const sortedOptions = [...options].sort((a, b) => a.value.localeCompare(b.value));

  useEffect(() => {
    setInputValue('');
  }, [readOnly]);

  const {
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    getClearProps,
    groupedOptions,
    getPopupIndicatorProps,
    popupOpen,
  } = useAutocomplete({
    id: 'tag-box',
    options: sortedOptions,
    autoHighlight: true,
    freeSolo: !enableAutocomplete,
    getOptionLabel: (option) => (checkIfString(option) ? option : option.value),
    value,
    onChange: handleChange,
    inputValue,
    onInputChange: (_, newValue) => setInputValue(newValue),
  });

  const tagsView = useMemo(
    () => {
      const collapsedContent = stringifyList(content);

      return (
        <Box
          iconComponent={<TagIcon className="skipOverride" />}
          title="Tags"
          readOnly={readOnly}
          hideEllipsisButton
          collapsed={collapsed}
          collapsedContent={collapsedContent}
          updateCollapsed={onChangeCollapse}
        >
          <ContentWrapper>
            <AutoCompleteBase
              content={content}
              readOnly={readOnly}
              value={inputValue}
              placeholder={`Start typing to ${enableAutocomplete ? 'find' : 'add'} Tags`}
              getRootProps={getRootProps}
              getInputProps={getInputProps}
              getListboxProps={getListboxProps}
              getOptionProps={getOptionProps}
              getClearProps={getClearProps}
              groupedOptions={groupedOptions}
              getPopupIndicatorProps={getPopupIndicatorProps}
              popupOpen={popupOpen}
              enableAutocomplete={enableAutocomplete}
              direction={direction}
            />
            <ChipGroup list={content} removeChip={removeTag} />
          </ContentWrapper>
        </Box>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      readOnly,
      collapsed,
      content,
      onChangeCollapse,
      inputValue,
      enableAutocomplete,
      popupOpen,
      removeTag,
    ],
  );

  return (
    <div {...attributes}>
      <DragAndDrop element={element} isDragDisabled={checkIfDragDisabled(variant)}>
        {children}
        {tagsView}
      </DragAndDrop>
    </div>
  );
};

Tags.propTypes = {
  /** Attributes of SlateJS children */
  attributes: PropTypes.shape({}),
  /** SlateJS children */
  children: PropTypes.node,
  /** SlateJS element */
  element: PropTypes.shape({}),
};

Tags.defaultProps = {
  attributes: {},
  children: null,
  element: {
    children: [],
    data: { content: [] },
    type: elementTypes.TAGS,
  },
};

export default memo(Tags);
