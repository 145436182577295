import {
  MMetaDataFieldInput,
  Rundown,
  RundownPlaybackState,
  RundownSequenceState,
} from 'types/graphqlTypes';

import {
  getRundownActiveState,
  getRundownPlayState,
  RundownPlayStates,
  RundownState,
} from './rundownStates';
import { toBoolean, toNumber } from './typeUtils';

/**
 * Enumeration representing the different rundown instance lists (sequence)
 *
 * - `mOrder`: Used for instances in the ready rundown (synonym to "ready").
 * - `mPreorder`: Used for instances in the preparing rundown  (synonym to "preparing").
 *
 * Relates to the corresponding RundownEntity properties `mOrder` and `mPreorder` respectively
 */
export enum RundownSequenceId {
  mOrder = 'mOrder',
  mPreorder = 'mPreorder',
}

/**
 * Enumeration representing the different rundown lists within a rundown.
 *
 * - `READY`: Refers to the rundown ready list
 * - `PREPARING`: Refers to the rundown preparing list
 *
 * Additional properties for these rundown lists (sequences) are stored in
 * RundownEntity mProperties
 *  - ready: RundownSequenceState;
 *  - preparing: RundownSequenceState;
 *
 * Corresponding instance lists are stored in RundownEntity properties `mOrder` and `mPreorder`.
 */
export enum RundownSequenceName {
  READY = 'ready',
  PREPARING = 'preparing',
}

/**
 * Enumeration / map between rundown list (sequence) > order type identifiers:
 *
 * This map helps in translating between the sequence type and its associated order type:
 * - `mOrder` - corresponds to `ready`.
 * - `mPreorder` - corresponds to `preparing`.
 */
const SequenceIdToSequenceNameMap = new Map<RundownSequenceId, RundownSequenceName>([
  [RundownSequenceId.mOrder, RundownSequenceName.READY],
  [RundownSequenceId.mPreorder, RundownSequenceName.PREPARING],
]);

/**
 * Enumeration / map between rundown order > sequence type identifiers:
 *
 * This map helps in translating between the order type and its associated sequence type:
 * - `ready` - corresponds to `mOrder`.
 * - `preparing` - corresponds to `mPreorder`.
 */
const SequenceNameToSequenceIdMap = new Map<RundownSequenceName, RundownSequenceId>([
  [RundownSequenceName.READY, RundownSequenceId.mOrder],
  [RundownSequenceName.PREPARING, RundownSequenceId.mPreorder],
]);

/**
 * Converts an `RundownSequenceName` to the corresponding `RundownSequenceId`.
 *
 * Translates between and rundown sequence name to corresponding sequence id
 * - `ready`- corresponds to `mOrder`
 * - `preparing` - corresponds to `mPreorder`
 *
 * @param name - The sequence name (ready,preparing) identifier to convert to (mOrder, mPreorder)
 * @returns
 *   The corresponding rundown sequence id for the given sequence name.
 *   Returns undefined if the provided name is invalid or not recognized.
 */
export const getSequenceIdFromSequenceName = (
  name: RundownSequenceName | string,
): RundownSequenceId | undefined => {
  if (!name) return;

  const sequence = SequenceNameToSequenceIdMap.get(name as RundownSequenceName);
  if (!sequence) return;

  return sequence;
};

/**
 * Converts an `RundownSequenceId` to the corresponding `RundownSequenceName`.
 *
 * Translates between and rundown sequence id to corresponding sequence name
 * - `mOrder`- corresponds to `ready`
 * - `mPreorder` - corresponds to `preparing`
 *
 * @param sequence - The sequence id (mOrder, mPreorder) to convert to (ready, preparing)
 * @returns
 *   The corresponding rundown sequence name for the given sequence id.
 *   Returns undefined if the provided sequence name is invalid or not recognized.
 */
export const getSequenceNameFromSequenceId = (
  sequence: RundownSequenceId | string,
): RundownSequenceName | undefined => {
  if (!sequence) return;

  const name = SequenceIdToSequenceNameMap.get(sequence as RundownSequenceId);
  if (!name) return;

  return name;
};

/**
 * Contains identifiers for a rundown sequence.
 * A rundown has two sequences referred to as ready and preparing respectively.
 *  - **"ready"**: The main sequence used for onair purposes. Typically played via MOS Gateway.
 *  - **"preparing"**: A sequence being used to contain instances
 *       that may be used in the ready sequence. I.e. preparing for onair.
 *       Note that this sequence is also possible to play via MOS Gateway merely for practising
 */
export interface RundownSequenceIds {
  /** The rundown id where the sequence belong */
  roId: string;
  /** The sequence id, either mPreorder or mOrder */
  sequenceId: RundownSequenceId;
  /** The sequence name, either PREPARING or READY */
  sequenceName: RundownSequenceName;
}

/**
 * Represents a single sequence within a rundown.
 *
 * A rundown typically has two distinct sequences:
 *  - **"ready"**: The main sequence used for onair purposes. Typically played via MOS Gateway.
 *  - **"preparing"**: A sequence being used to contain instances
 *       that may be used in the ready sequence. I.e. preparing for onair.
 *       Note that this sequence is also possible to play via MOS Gateway merely for practising
 *
 * This interface acts as a Data Transfer Object (DTO)
 * for encapsulating the core information about a rundown sequence.
 */
export interface RundownSequence extends RundownSequenceIds {
  /**
   * The rundown sequence state.
   * Determines whether the rundown sequence is active
   */
  state: RundownState; // mState, mPreparingState

  /**
   * State properties used when playing the rundown sequence.
   * I.e. when the rundown sequence is onair
   */
  playState?: RundownSequenceState; // Aka. RundownPlayState

  /** The rundown sequence list of instance ids  */
  instances: string[]; // rundown.mOrder, mPreorder

  /** Rundown planned duration, in milliseconds */
  plannedDuration: number; // mPlannedDuration, mPrePlannedDuration
  /**
   * The planned publication date and time of the rundown sequence
   * in ISO 8601 format (e.g., "2024-07-05T17:30:00Z").
   */
  publishingAt?: string; // mPublishingAt
  /**
   * The time zone associated with the rundown sequence. IANA
   * (e.g., "America/Los_Angeles").
   */
  timeZone?: string; // recurrence.timeZone
}

/**
 * Returns the rundown sequence id from a sequence name or sequence id
 * @param event {RundownStatusEvent} - Generic rundown status event
 * @param sequence - Sequence identifier or name
 * @returns {RundownSequenceId} - Rundown sequence id
 *  - Returns undefined if invalid or nor recognized sequence name
 */
const getRundownSequenceId = (
  sequence: RundownSequenceName | RundownSequenceId | string,
): RundownSequenceId | undefined => {
  if (Object.values(RundownSequenceId).includes(sequence as RundownSequenceId))
    return sequence as RundownSequenceId;
  return getSequenceIdFromSequenceName(sequence);
};

/**
 * Ensures that the state as stored in the input playbackStats is valid.
 * This for legacy use.
 * @param playbackState
 * @returns validated playbackState
 */
export const getRundownPlaybackState = <T extends RundownPlaybackState>(
  playbackState?: T,
): T | undefined => {
  if (!playbackState) return;
  const { state, ...rest } = playbackState;
  return {
    ...rest,
    state: getRundownPlayState(state),
  } as T;
};

/**
 * Extracts a rundown sequence object from a rundown.
 * The rundown sequence object contains all related information from
 * either the ready or preparing sequence.
 * @param rundown {Partial<RundownEntity>} - Rundown database entity
 * @param sequence {RundownSequenceName | RundownSequenceId | string}
 *   - Rundown sequence name or identifier
 * @returns {RundownSequence} - Rundown sequence object
 */
export const getRundownSequence = (
  rundown: Partial<Rundown>,
  sequence: RundownSequenceName | RundownSequenceId | string,
): RundownSequence | undefined => {
  const { mId: roId } = rundown;
  if (!roId) return;

  const sequenceId = getRundownSequenceId(sequence) ?? RundownSequenceId.mOrder;

  if (sequenceId === RundownSequenceId.mOrder) {
    // READY SEQUENCE
    const instances = Array.isArray(rundown.mOrder) ? rundown.mOrder : [];
    return {
      roId,
      sequenceId,
      sequenceName: getSequenceNameFromSequenceId(sequenceId)!,
      state: getRundownActiveState(rundown.mState),
      playState: getRundownPlaybackState(rundown?.mProperties?.ready),
      instances,
      plannedDuration: toNumber(rundown.mPlannedDuration, 0),
      publishingAt: rundown.mPublishingAt,
      timeZone: rundown.recurrence?.timeZone,
    };
  } else {
    // PREPARING SEQUENCE
    const instances = Array.isArray(rundown.mPreorder) ? rundown.mPreorder : [];
    return {
      roId,
      sequenceId,
      sequenceName: getSequenceNameFromSequenceId(sequenceId)!,
      state: getRundownActiveState(rundown.mPreparingState),
      playState: getRundownPlaybackState(rundown?.mProperties?.preparing),
      instances,
      plannedDuration: toNumber(rundown.mPrePlannedDuration ?? 0),
      timeZone: rundown.recurrence?.timeZone,
    };
  }
};

export interface LinearProvider extends RundownPlaybackState {
  state?: RundownPlayStates;
}

/**
 * Defines instance properties used for a rundown linear instance
 * Typically used in rundown functions.
 */
export interface RundownInstance {
  mId: string;
  mTitle: string;
  mMetaData?: MMetaDataFieldInput[];
  mProperties?: {
    provider: LinearProvider;
  };
}

/**
 * Returns true if the instance is floated.
 * Whether the instances is floated is determined by presence of
 * an "isFloat" metadata field in the instance key/value metadata.
 * Note that the metadata key only requires the literal 'isFloat' to be part of the key.
 * This for legacy use.
 * @param instance {RundownInstance}
 * @returns {boolean}
 */
export const isFloated = (instance: RundownInstance) => {
  const { mMetaData } = instance;
  const floatField = mMetaData?.find((field) => field.key.includes('isFloat'));
  if (!floatField) return false;
  return toBoolean(floatField.value);
};
