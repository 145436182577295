import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { FormControlLabel as MuiFormControlLabel, Typography } from '@material-ui/core';

export const DateTime = styled('div')`
  display: flex;
  justify-content: space-between;
  position: relative;
`;

export const DatePicker = styled('div')`
  width: 194px;
`;

export const TimePicker = styled('div')`
  width: 134px;
`;

export const ResetTextButton = styled(Typography)<{ $disabled: boolean }>`
  ${({ theme }) => css`
    ${theme.typography.dina.captionRegular};
    color: ${theme.palette.dina.disabled};
  `}
  text-decoration-line: underline;
  cursor: pointer;
  text-align: right;
  :hover {
    color: ${({ theme }) => theme.palette.dina.highEmphasis};
  }
  opacity: ${({ $disabled }) => ($disabled ? 0.35 : 1)};
`;

export const FormControlLabel = styled(MuiFormControlLabel)`
  margin: 0;
  height: 40px;
  & .MuiFormControlLabel-label {
    ${({ theme }) => theme.typography.dina.listItemLabel};
    margin-left: 4px;
  }
`;
