import { css } from '@emotion/react';
import styled from '@emotion/styled';

import Text from 'components/text/Text';
import { Flex, VStack } from 'layouts/box/Box';

const rollingTimes = [
  {
    value: '-14days',
    calenderLabel: '- 14 days',
    label: 'Today - 14 days',
  },
  {
    value: '-7days',
    calenderLabel: '- 7 days',
    label: 'Today - 7 days',
  },
  {
    value: 'yesterday',
    calenderLabel: 'Yesterday',
    label: 'Yesterday',
  },
  {
    value: 'today',
    calenderLabel: 'Today',
    label: 'Today',
  },
  {
    value: 'this-week',
    calenderLabel: 'This Week',
    label: 'This Week',
  },
  {
    value: 'next-week',
    calenderLabel: 'Next Week',
    label: 'Next Week',
  },
  {
    value: '+7days',
    calenderLabel: '+ 7 days',
    label: 'Today + 7 days',
  },
  {
    value: '+14days',
    calenderLabel: '+ 14 days',
    label: 'Today + 14 days',
  },
  {
    value: '+30days',
    calenderLabel: '+ 30 days',
    label: 'Today + 30 days',
  },
  {
    value: '+90days',
    calenderLabel: '+ 90 days',
    label: 'Today + 90 days',
  },
  {
    value: 'all-time',
    calenderLabel: 'All Time',
    label: 'All Time',
  },
];

export const getRollingLabel = (rollingDate: string) =>
  rollingTimes.find((rolling) => rolling.value === rollingDate)?.label ?? rollingDate;

const StyledVStack = styled(VStack)`
  border-right: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
`;

const StyledFlex = styled(Flex)<{ selected: boolean }>`
  cursor: pointer;
  user-select: none;
  padding-inline: 8px;
  border-radius: 6px;
  :hover {
    background-color: ${({ selected, theme }) =>
      selected ? theme.palette.dina.onSelected : theme.palette.dina.hoverOverlay};
  }
  ${({ selected, theme }) =>
    selected
      ? css`
          background-color: ${theme.palette.dina.onSelected};
          span {
            color: ${theme.palette.dina.whiteHighEmphasis};
          }
        `
      : ''}
`;

interface RollingDateProps {
  rollingDate: string;
  updateRollingDate: (date: string) => void;
}

function RollingDate({ rollingDate, updateRollingDate }: Readonly<RollingDateProps>) {
  return (
    <StyledVStack padding="8px" alignItems="flex-start" justifyContent="flex-start">
      <Text style={{ padding: 8 }} variant="overline" color="highEmphasis">
        Rolling dates
      </Text>
      {rollingTimes.map((item) => (
        <StyledFlex
          key={item.value}
          width="100%"
          height="28px"
          justifyContent="flex-start"
          selected={item.value === rollingDate}
          onClick={() => updateRollingDate(item.value)}
        >
          <Text variant="button">{item.label}</Text>
        </StyledFlex>
      ))}
    </StyledVStack>
  );
}

export default RollingDate;
