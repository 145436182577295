import { useEffect, useState } from 'react';

import { useSearch } from 'api/search';
import { VStack } from 'layouts/box/Box';
import { Loading } from 'screens/loading/loading-styled';

import { baseToolbarState } from './Activity';
import GroupedAction from './GroupedAction';

export default function Upcoming({ memberId }: Readonly<{ memberId: string }>) {
  const [isFetchData, setIsFetchData] = useState(false);
  const now = new Date();
  const sParams = {
    skip: false,
    searchString: '',
    toolbarState: {
      ...baseToolbarState,
      assignedIds: [memberId],
      rangeByTopic: [
        {
          topic: 'scheduledAt',
          range: {
            from: now.toISOString().split('T')[0],
          },
        },
      ],
    },
    mdfId: undefined,
  };

  const { items, loading, handleLoadMore, hasMore, nextToken } = useSearch(sParams);

  useEffect(() => {
    if (isFetchData && hasMore) {
      void handleLoadMore();
    }
  }, [isFetchData, handleLoadMore]);
  if (loading)
    return (
      <VStack height="100%" width="100%" justifyContent="center" alignItems="center">
        <Loading />
      </VStack>
    );
  return (
    <>
      {items?.length > 0 ? (
        <GroupedAction
          activityList={items}
          setIsFetchData={setIsFetchData}
          hasMore={hasMore}
          nextToken={nextToken}
        />
      ) : (
        <VStack
          height="100%"
          width="100%"
          padding="10px"
          justifyContent="start"
          alignItems="center"
        >
          <span>You are all caught up! No upcoming activities are scheduled.</span>
        </VStack>
      )}
    </>
  );
}
