import { useEffect, useMemo } from 'react';

import useContentResolver from 'hooks/useContentResolver';

import { useInstanceMolecule } from '../store/instance';

const useLoadAndSetContent = () => {
  const {
    useInstanceValue,
    useInstanceContentData,
    useInstanceContentLoading,
    useSkipDownload,
    useWriteLock,
    contentRefetchRef,
  } = useInstanceMolecule();

  const instance = useInstanceValue();
  const [content, setInstanceContentData] = useInstanceContentData();
  const [, setInstanceContentLoading] = useInstanceContentLoading();
  const [writeLock] = useWriteLock();
  const [skip, setSkip] = useSkipDownload();

  const contentKey = useMemo(() => instance?.mContentKey, [instance?.mContentKey]);

  const shouldSkipFetching = content === null ? false : skip || !contentKey || writeLock;

  const { data, loading, refetch } = useContentResolver(contentKey, shouldSkipFetching);

  useEffect(() => {
    if (data) {
      setInstanceContentData(data);
      setSkip(true);
    }
  }, [data, setInstanceContentData, setSkip]);

  useEffect(() => {
    // do not skip if content key updates
    setSkip(false);
  }, [contentKey, setSkip]);

  useEffect(() => {
    setInstanceContentLoading(loading);
  }, [loading, setInstanceContentLoading]);

  useEffect(() => {
    contentRefetchRef.current = refetch;
  }, [contentRefetchRef, refetch]);
};

export default useLoadAndSetContent;
