import { css } from '@emotion/react';
import styled from '@emotion/styled';

import transientOptions from 'theme/helpers';

export const Wrapper = styled('div', transientOptions)<{
  $showBorderBottom?: boolean;
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px 0;
  border-bottom: ${({ $showBorderBottom, theme }) =>
    $showBorderBottom && `1px solid ${theme.palette.dina.dividerLight}`};
  > :not(:first-of-type) {
    margin-top: 8px;
  }
`;

export const RadioButtonWrapper = styled('div')`
  width: 100%;
  height: 24px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const RadioLabel = styled('span')`
  ${({ theme }) => css`
    ${theme.typography.dina.captionRegular};
    color: ${theme.palette.dina.highEmphasis};
  `}
  margin-left: 5px;
`;

export const Subtitle = styled('span')`
  ${({ theme }) => theme.typography.dina.caption};
  flex: 1;
  text-align: right;
`;
