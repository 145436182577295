import { useCallback } from 'react';

/**
 * Creates a stable function that takes an argument and passes it to one function and passes
 * the result of that first function to a second function and returs the result of the second
 * function.
 * @param first  The function to transform the input parameter
 * @param second The function to be called with the result of the first function.
 * @returns      The stable function that calls the piped function.
 * @template I   The input for the resulting function (and the `first` function)
 * @template T   The result of the `first` function and input to the `second function`
 * @template O   The result of the resulting function (and the `second` function)
 */
export default function usePipedCallback<I, T, O>(
  first: (input: I) => T,
  second: (input: T) => O,
): (input: I) => O {
  return useCallback((input: I) => second(first(input)), [first, second]);
}
