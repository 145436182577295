import { RundownStateEnum } from 'types/graphqlTypes';

/**
 * @typedef {("active" | "notReady" | "ready")} RundownState
 * @description A type representing valid values of RundownStateEnum.
 */
export type RundownState = (typeof RundownStateEnum)[keyof typeof RundownStateEnum];

/**
 * @function getRundownActiveState
 * @description Determines the rundown active state based on the given state string.
 * @param {string|null|undefined} [state] - The input state to normalize and validate.
 * @returns {RundownState} The matched rundown state or `notReady` if no match is found.
 */
export const getRundownActiveState = (state?: string | null): RundownState => {
  if (!state) {
    return RundownStateEnum.NotReady; // Default state for undefined or null
  }

  const normalizedState = state.toLowerCase();
  const match = Object.values(RundownStateEnum).find(
    (validState) => validState.toLowerCase() === normalizedState,
  );

  return match || RundownStateEnum.NotReady; // Return matched state or NOTREADY
};

/**
 * Utility functions for RundownStatesEnum.
 */
export const RundownActiveStatesUtils = {
  /** Checks if the given rundown state is `notReady`
   * When in this state, no rundown changes are sent to MOS Gateways.
   */
  isNotReady: (state?: string): boolean =>
    getRundownActiveState(state) === RundownStateEnum.NotReady,
  /**
   * Checks if the given rundown state is `active`
   * When in this state, rundown changes are transmitted to MOS Gateways.
   */
  isActive: (state?: string): boolean => getRundownActiveState(state) === RundownStateEnum.Active,
  /** Checks if the given rundown state is `ready`
   * When in this state, rundown changes are transmitted to MOS Gateways (same as `active`).
   * The rundown is also flagged as onair within the MOS protocol [READY TO AIR]
   */
  isReady: (state?: string): boolean => getRundownActiveState(state) === RundownStateEnum.Ready,
  /**
   * Checks whether the given rundown state is `active` or `ready`.
   * Both these states are considered as `activated` which sends information to MOS Gateways
   */
  isActivated: (state?: string | null): boolean => {
    const rundownState = getRundownActiveState(state);
    return rundownState === RundownStateEnum.Active || rundownState === RundownStateEnum.Ready;
  },
};

/**
 * Type for RundownStates.
 */
export type RundownStates = (typeof RundownStateEnum)[keyof typeof RundownStateEnum];

/**
 * @const {Object} RundownPlayStatesEnum
 * @description Defines the different play states of rundown and rundown elements (instance+assets).
 *   Note: This state can be undefined for non-active rundowns
 *   Rundown that has been active keeps the last known playing state
 * @property {string} PLAY - The element is activated and playing
 * @property {string} READY - The element is activated but not playing
 * @property {string} STOP - The element is stopped
 * @property {string} NOT_READY - The element is not available (assets only)
 * @property {string} UNKNOWN - Status unknown
 */
export const RundownPlayStatesEnum = {
  READY: 'READY',
  PLAY: 'PLAY',
  STOP: 'STOP',

  // ASSETS ONLY
  NOT_READY: 'NOT READY',

  // ALIASES
  'NOT READY': 'NOT READY',

  // SYSTEM ALIASES
  UNKNOWN: 'UNKNOWN',
} as const;

/**
 * Type for RundownPlayStates.
 */
export type RundownPlayStates = (typeof RundownPlayStatesEnum)[keyof typeof RundownPlayStatesEnum];

/**
 * Returns the RundownPlayStates from a string state.
 * @param state - string representing the rundown play state.
 * @returns corresponding RundownPlayState, Returns UNKNOWN if not found.
 */
export const getRundownPlayState = (state?: string | null): RundownPlayStates => {
  if (!state) {
    return RundownPlayStatesEnum.UNKNOWN; // Default state for undefined or null
  }
  const normalizedState = state.toUpperCase();
  return (
    RundownPlayStatesEnum[normalizedState as keyof typeof RundownPlayStatesEnum] ??
    RundownPlayStatesEnum.UNKNOWN
  );
};
/**
 * Utility functions for RundownPlayStates.
 */
export const RundownPlayStateUtils = {
  isPlaying: (state?: string): boolean => getRundownPlayState(state) === RundownPlayStatesEnum.PLAY,
  isReady: (state?: string): boolean => getRundownPlayState(state) === RundownPlayStatesEnum.READY,
  isStopped: (state?: string): boolean => getRundownPlayState(state) === RundownPlayStatesEnum.STOP,
  isNotReady: (state?: string): boolean =>
    getRundownPlayState(state) === RundownPlayStatesEnum.NOT_READY,
};
