import { Button } from 'components/buttons';
import { VStack } from 'layouts/box/Box';

import { Caption } from './styled';

function RefreshButton({
  label,
  onClick,
  caption1,
  caption2,
}: {
  label: string;
  onClick: () => void;
  caption1: string;
  caption2: string;
}) {
  return (
    <VStack flex="1" gap="8px">
      <Button variant="contained" usage="significant" onClick={onClick}>
        {label}
      </Button>
      <Caption>
        {caption1} <br /> {caption2}
      </Caption>
    </VStack>
  );
}

export default RefreshButton;
