import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { isPointInTimeField, TOTAL_TIME_FIELD_KEY } from 'features/mdf/mdf-defaults';
import { isTimingValue } from 'screens/rundown/rundownV2/utils/timing';
import type { TimingValue } from 'types/graphqlTypes';

import type CellProps from '../cellProps';

import TimingCell from './TimingCell';

import { StyledCellText } from './styled';

/**
 * Represents a TimingCell that's by default rendered.
 * This component needs to stay as lightweight as possible.
 */
function LightWeightTimingCell({
  fieldModel,
  value,
  mdf,
  setValue,
  fieldSettings,
  disableEdit: disableEditProp,
  onFocus,
  onBlur,
}: Readonly<CellProps>) {
  const [flashError, setFlashError] = useState(false);
  const [editing, setEditing] = useState(false);
  const [cellValue, setCellValue] = useState<TimingValue | null>(null);
  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const disableEdit = disableEditProp || fieldModel.fieldId === TOTAL_TIME_FIELD_KEY;

  useEffect(() => {
    setCellValue(isTimingValue(value) ? value : null);
  }, [value]);

  useEffect(() => {
    if (flashError) {
      clearTimeout(timerRef.current as unknown as number | string);
      timerRef.current = setTimeout(() => {
        setFlashError(false);
      }, 2000);
    }
    return () => {
      if (timerRef.current) clearTimeout(timerRef as unknown as number | string);
    };
  }, [flashError]);

  const toggleEdit = useCallback(() => {
    if (!disableEdit) setEditing((prev) => !prev);
  }, [disableEdit]);

  const valueToShow = useMemo(() => {
    if (typeof cellValue?.value === 'string')
      return cellValue.value.length === 0 ? '-' : cellValue.value;
    return isPointInTimeField[fieldModel.fieldId] ? '-' : '00:00';
  }, [fieldModel, cellValue]);

  return (
    <>
      {editing && (
        <TimingCell
          disableEdit={disableEdit}
          fieldModel={fieldModel}
          fieldSettings={fieldSettings}
          mdf={mdf}
          setValue={setValue}
          value={cellValue}
          onBlur={onBlur}
          onFocus={onFocus}
          setFlashError={setFlashError}
          setCellValue={setCellValue}
          setEditing={setEditing}
        />
      )}
      <StyledCellText
        onClick={toggleEdit}
        onFocus={toggleEdit}
        tabIndex={disableEdit ? -1 : 0}
        $grayOut={!value || valueToShow === '-'}
        $showError={flashError}
        $orange={cellValue?.manual === true}
        // Hide when editing, this element must stay in the DOM
        // for the onClick/onFocus events to fire correctly
        $hide={editing}
      >
        {valueToShow}
      </StyledCellText>
    </>
  );
}

export default memo(LightWeightTimingCell);
