import React from 'react';

interface Props {
  type: string;
  style?: React.CSSProperties;
}

export function ErrorField({ type, style }: Props) {
  return <div style={style}>Could not resolve type: {type}</div>;
}
