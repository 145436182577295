const noOp = () => undefined;

/**
 * Gets a function that will restore focus to the element that was focused when this function was
 * called. If this element is no longer in DOM (under the BODY tag) when focus is to be restored
 * it wont be focused.
 * @param deferTime The time to defer refocusing when the focus restorer is called
 * @returns         The function to restore focus.
 */
export function getFocusRestorer(deferTime?: number): () => void {
  const prevActiveEl = document.activeElement as HTMLElement | null;
  if (!prevActiveEl) return noOp;
  const focusEl = prevActiveEl;
  function restoreFocus() {
    let el = focusEl.parentElement;
    while (el && el.tagName !== 'BODY') el = el.parentElement;
    if (el) {
      // We don't want to focus an element that is no longer in DOM.
      focusEl.focus();
    }
  }
  if (deferTime === undefined) {
    return restoreFocus;
  }
  return () => setTimeout(restoreFocus, deferTime);
}
