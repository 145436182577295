/* eslint-disable import/no-extraneous-dependencies */
import { useCallback } from 'react';
import { gql, useMutation } from '@apollo/client';
import { v4 as uuid } from 'uuid';

import memberTypes from 'operations/memberTypes';
import useCreateView from 'screens/space/api/useCreateSpaceView';
import type { MemberType } from 'types/graphqlTypes';

const DELETE_SPACE = gql`
  mutation DeleteSingleMember($input: DeleteSingleMemberInput) {
    deleteSingleMember(input: $input) {
      mId
    }
  }
`;

const CREATE_SPACE = gql`
  mutation CreateSpace($input: CreateMemberInput) {
    createMember(input: $input) {
      mId
      mRefId
      mType
      mTitle
      mUpdatedAt
    }
  }
`;

const UPDATE_SPACE = gql`
  mutation UpdateSpace($input: UpdateMemberInput) {
    updateMember(input: $input) {
      mId
      mRefId
      mTitle
      mType
      mUpdatedAt
    }
  }
`;

interface CreateSpace {
  createMember: MemberType;
}
interface UpdateSpace {
  updateMember: MemberType;
}
interface DeleteSpace {
  deleteMember: MemberType;
}

interface CreateSpaceInput {
  spaceId?: string; // Provide from outside if it's team/dept space
  title: string;
  description: string | undefined;
}

const useCreateSpace = () => {
  const { create: createView } = useCreateView();
  const [create] = useMutation<CreateSpace>(CREATE_SPACE);
  const [update] = useMutation<UpdateSpace>(UPDATE_SPACE);
  const [deleteMember] = useMutation<DeleteSpace>(DELETE_SPACE);

  const updateSpace = useCallback(
    async (mId: string, mRefId: string, updatedTitle: string, updatedDescription: string) => {
      const updateInput = {
        mId,
        mRefId,
        mTitle: updatedTitle,
        mDescription: updatedDescription,
      };
      const result = await update({
        variables: { input: updateInput },
      });
      return result.data?.updateMember ?? null;
    },
    [update],
  );

  const createSpace = useCallback(
    async ({ title, description, spaceId }: CreateSpaceInput) => {
      const newSpaceId = spaceId ?? uuid();
      const input = {
        mId: newSpaceId,
        mRefId: newSpaceId,
        mTitle: title,
        mDescription: description,
        mType: memberTypes.SPACE,
      };

      const result = await create({
        variables: { input },
      });

      if (result.data?.createMember?.mId) {
        await createView({
          spaceId: result.data.createMember.mId,
          mTitle: 'Untitled view',
          layout: 'horizontal',
        });
      }

      return result.data?.createMember ?? null;
    },
    [create],
  );

  const deleteSpace = useCallback(
    async (mId: string) => {
      const result = await deleteMember({
        variables: { input: { mId, mRefId: mId } },
      });
      return result.data?.deleteMember;
    },
    [deleteMember],
  );

  return { createSpace, updateSpace, deleteSpace };
};

export default useCreateSpace;
