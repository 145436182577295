/**
 * Returns a list (array) of states properties according to view
 */

import { InstanceStateType, InstanceStateViewType } from 'types/graphqlTypes';

const returnStates = (
  viewVariant: string,
  states: InstanceStateType[],
  views: InstanceStateViewType[],
) => {
  const view =
    views.find((v) => v.id === viewVariant) ?? views.find((v) => v.id === 'status-normal');
  if (!view) return [];
  const { states: viewingStates } = view;
  if (viewingStates === undefined || viewingStates.length === 0) return [];
  const stateList = viewingStates
    .map((s) => states.find((state) => state.id === s.id))
    .filter(Boolean) as InstanceStateType[];
  return stateList;
};

export default returnStates;
