import { memo, useCallback, useState } from 'react';
import { useDrop } from 'react-dnd';
import { useSlate } from 'slate-react';

import { ReactComponent as BlockQuoteIcon } from 'assets/icons/systemicons/block_quote.svg';
import DragAndDrop from 'components/editor/components/dragAndDrop';
import { useEditorContext } from 'components/editor/EditorContext';
import { Update } from 'components/editor/types';
import updateBlock from 'components/editor/utils/updateBlock';
import useInputEvents from 'hooks/useInputEvents';
import { Box } from 'layouts/box/Box';
import { BlockElement, CustomData } from 'types';
import { MemberType, MemberTypeEnum } from 'types/graphqlTypes';
import dndTypes from 'utils/dndTypes';

import { BylineInput, ContactDrop, QuoteBlockWrapper, QuoteInput } from './styled';

const QuoteBlock = ({ attributes, children, element }: Readonly<BlockElement>) => {
  const { data } = element ?? {};
  const { quote = 'Your quote text here', byline = 'Your byline' } = data as CustomData;
  const [quoteValue, setQuoteValue] = useState(quote);
  const [bylineValue, setBylineValue] = useState(byline);
  const editor = useSlate();
  const { update, doLock, isLockedByAnotherUser, onHotKeys } = useEditorContext();

  const updateQuote = useCallback(
    (updatedValue: string) => {
      const updatedData = {
        ...data,
        quote: updatedValue,
      };
      updateBlock(editor, element, updatedData, update as Update, false, undefined);
    },
    [element, updateBlock, editor, update],
  );

  const updateByline = useCallback(
    (updatedValue: string) => {
      const updatedData = {
        ...data,
        byline: updatedValue,
      };
      updateBlock(editor, element, updatedData, update as Update, false, undefined);
    },
    [element, updateBlock, editor, update],
  );

  const onQuoteUpdate = useCallback(
    (newValue: string) => {
      if (newValue === quote) setQuoteValue(quote);
      else updateQuote(newValue);
    },
    [quote, updateQuote],
  );

  const onBylineUpdate = useCallback(
    (newValue: string) => {
      if (newValue === byline) setBylineValue(byline);
      else updateByline(newValue);
    },
    [byline, updateQuote],
  );

  const [quoteInputRef, quoteHandleKeydown, quoteHandleBlur] = useInputEvents<HTMLTextAreaElement>(
    onQuoteUpdate,
    quoteValue,
    quote,
    false,
    onHotKeys,
  );

  const [bylineInputRef, bylineHandleKeydown, bylineHandleBlur] =
    useInputEvents<HTMLTextAreaElement>(onBylineUpdate, bylineValue, byline, false, onHotKeys);

  const [{ isOver }, drop] = useDrop({
    accept: [dndTypes.MEMBER],
    canDrop: (item: MemberType) =>
      [MemberTypeEnum.Contact, MemberTypeEnum.User].includes(item.mType!),
    drop(item: MemberType) {
      if (item.mTitle) {
        setBylineValue(item.mTitle);
        onBylineUpdate(item.mTitle);
      }
    },
    collect: (monitor) => ({
      canDrop: monitor.canDrop(),
      isOver: monitor.isOver(),
    }),
  });

  return (
    <div contentEditable={false} {...attributes} style={{ padding: '8px' }}>
      <DragAndDrop element={element} isDragDisabled={false}>
        {children}
        <QuoteBlockWrapper
          container
          flexDirection="column"
          alignItems="start"
          justifyContent="start"
          ref={(ref) => drop(ref)}
        >
          {isOver && (
            <ContactDrop>
              <span>Drop to add name to byline</span>
            </ContactDrop>
          )}
          <Box container width="100%" padding="0 0 8px 0">
            <Box padding="0 8px 0 0">
              <BlockQuoteIcon />
            </Box>
            <QuoteInput
              ref={quoteInputRef}
              value={quoteValue}
              onChange={(ev) => setQuoteValue(ev.target.value)}
              onBlur={quoteHandleBlur}
              onFocus={doLock}
              onKeyDown={quoteHandleKeydown}
              readOnly={isLockedByAnotherUser}
              $locked={!!isLockedByAnotherUser}
            />
          </Box>
          <Box container justifyContent="end" width="100%">
            <BylineInput
              ref={bylineInputRef}
              value={bylineValue}
              onChange={(ev) => setBylineValue(ev.target.value)}
              onBlur={bylineHandleBlur}
              onFocus={doLock}
              onKeyDown={bylineHandleKeydown}
              readOnly={isLockedByAnotherUser}
              $locked={!!isLockedByAnotherUser}
            />
          </Box>
        </QuoteBlockWrapper>
      </DragAndDrop>
    </div>
  );
};

export default memo(QuoteBlock);
