import { useState, useEffect, forwardRef, lazy, Suspense, useContext } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import UserContext from 'contexts/UserContext';
import useDinaNavigate from 'hooks/useDinaNavigate';
import useTabs from 'hooks/useTabs';
import memberTypes from 'operations/memberTypes';
import usePublishDateUpdate from 'hooks/usePublishDateUpdate';
import { UNTITLED_STORY } from 'utils/constants';
import useShareStory from 'hooks/useShareStory';
import useToggleBookmark from 'hooks/useToggleBookmark';
import useGetAssignedMembers from 'hooks/useGetAssignedMembers';
import useArchiveStory from 'hooks/useArchiveStory';
import useUnarchiveMember from 'hooks/useUnarchiveMember';
import useCustomDateTimeUtils from 'hooks/useCustomDateTimeUtils';
import useImageUrl from 'hooks/useImageUrl';
import useLogger from 'utils/useLogger';
import { useCurrentTabId, useCurrentTabType, useSidebar } from 'store';
import fallbackImage from 'assets/images/default/defaultThumbnail.png';
import LoadingIndicator from 'components/loadingIndicator/LoadingIndicator';
import Popper from 'components/shared/popper';

import Story from './ListItem';
import { useUserBookmarks } from 'store/bookmarks';

const StoryBox = lazy(() => import('screens/storybox'));

export const StoryBoxWrapper = styled('div')`
  width: 327px;
  height: 75vh;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: ${({ theme }) =>
    theme.palette.mode === 'light'
      ? `0px 0px 2px rgba(0, 0, 0, 0.15), 0px 0px 6px rgba(0, 0, 0, 0.15),
    0px 12px 24px rgba(0, 0, 0, 0.15)`
      : `0px 0px 2px rgba(0, 0, 0, 0.25), 0px 0px 6px rgba(0, 0, 0, 0.25),
    0px 12px 24px rgba(0, 0, 0, 0.25)`};
`;

const {
  PITCH,
  ARCHIVED_STORY,
  ARCHIVED_PITCH,
  RESTRICTED_PITCH,
  ARCHIVED_RESTRICTED_STORY,
  ARCHIVED_RESTRICTED_PITCH,
} = memberTypes;

const archivedTypesSet = new Set([
  ARCHIVED_STORY,
  ARCHIVED_PITCH,
  ARCHIVED_RESTRICTED_STORY,
  ARCHIVED_RESTRICTED_PITCH,
]);

const pitchTypesSet = new Set([PITCH, ARCHIVED_PITCH, RESTRICTED_PITCH, ARCHIVED_RESTRICTED_PITCH]);

const StoryContainer = forwardRef(
  (
    {
      title,
      updatedAt,
      dateRange,
      assignedMembers,
      id,
      refId,
      imgUrl,
      mThumbnailKey,
      setShowStorybox,
      groupType,
      storyType,
      setSelectedId,
      setPreviewItemId,
      isStorySelected,
      isStoryFocused,
      isRestricted,
      canUpdatePitch,
      canUpdateStory,
      canSelectRange,
      canArchivePitch,
      canArchiveStory,
      handleContextClick,
    },
    ref,
  ) => {
    const { distanceInWords } = useCustomDateTimeUtils();
    const { navigateTo } = useDinaNavigate();
    const isPitch = pitchTypesSet.has(storyType);
    const showArchiveButton = isPitch ? canArchivePitch : canArchiveStory;
    const timeString = distanceInWords(updatedAt);
    const [currentTabId] = useCurrentTabId();
    const [currentTabType] = useCurrentTabType();
    const { closeTab } = useTabs();

    const [{ rightHidden, rightSelection }] = useSidebar();
    const dragSourceEnabled =
      ['rundown', 'rundowntemplate', 'home', 'space'].includes(currentTabType) ||
      (!rightHidden && ['users', 'tea_usr', 'dpr_usr', 'contacts'].includes(rightSelection));
    const [getAssignedUsers] = useGetAssignedMembers(assignedMembers || []);
    const [assignedUsers] = getAssignedUsers();
    const [assignMemberToStory, shareStoryWith] = useShareStory();
    const toggleBookmark = useToggleBookmark();
    const [archive] = useArchiveStory();
    const [unarchive] = useUnarchiveMember();
    const [updatePublishDate] = usePublishDateUpdate();

    const { mId: userId } = useContext(UserContext);
    const [bookmarks] = useUserBookmarks();
    const bookmarked = !!bookmarks[id];

    const [archived, setArchived] = useState(false);
    const [joined, setJoined] = useState(assignedUsers.map((usr) => usr.mId).includes(userId));
    const [disableLeaveButton, setDisableLeaveButton] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const logger = useLogger('left area/sidebar/stories/list item/story container');
    const thumbnailUrl = useImageUrl(mThumbnailKey);

    useEffect(() => {
      const joinedStatus = assignedUsers.map((usr) => usr.mId).includes(userId);
      setJoined(joinedStatus);
      const canLeave = joinedStatus && assignedUsers.length > 1;
      setDisableLeaveButton(!canLeave);
    }, [assignedUsers, userId]);

    useEffect(() => {
      setArchived(archivedTypesSet.has(storyType));
    }, [storyType]);

    const addMemberToStory = async (memberId, storyId, memberType) => {
      assignMemberToStory(storyId, memberId, memberType);
    };

    const joinStory = async () => {
      if (joined) {
        try {
          await shareStoryWith(
            id,
            assignedUsers.filter((usr) => usr.mId !== userId),
            assignedUsers,
            memberTypes.USER_STORY,
          );
          setJoined(false);
        } catch (error) {
          // eslint-disable-next-line no-console
          logger.error(error);
        }
      } else {
        try {
          await assignMemberToStory(id, userId, memberTypes.USER_STORY);
          setJoined(true);
        } catch (error) {
          // eslint-disable-next-line no-console
          logger.error(error);
        }
      }
    };

    const archiveStory = () => {
      const story = {
        mId: id,
        mRefId: refId,
        mType: storyType,
        mPublishingAt: dateRange.mPublishingAt,
      };
      archive(id, groupType, story);
      closeTab({ id, type: 'story' });
    };

    const onUpdatePublishDate = (newTimes) => {
      const { startDate = null, endDate = null } = newTimes || {};
      updatePublishDate(id, startDate, endDate);
    };

    const unarchiveStory = (mType) => {
      unarchive(id, mType);
    };

    const subMenuActionHandler = (label, mType) => {
      switch (label) {
        case 'bookmark':
          toggleBookmark({
            bookmarkedId: id,
            bookmarkedType: memberTypes.STORY,
          });
          break;
        case 'archive':
          archived ? unarchiveStory(mType) : archiveStory();
          break;
        case 'join':
          joinStory();
          break;
        default:
          break;
      }
    };

    const page = isPitch ? memberTypes.PITCH : memberTypes.STORY;

    const enableUpdate = isPitch ? canUpdatePitch : canUpdateStory;

    const handleOpenStory = () => navigateTo(page, id);

    const handleClose = () => {
      setAnchorEl(null);
      setPreviewItemId(null);
      setSelectedId(null);
      setPreviewItemId(null);
      setSelectedId(null);
    };

    const image = thumbnailUrl || fallbackImage;

    const onInstanceClick = (_, instanceId) => {
      handleClose();
      navigateTo('story', id, { tab: 'instances', entityId: instanceId });
    };

    const onSetShowStorybox = () =>
      setShowStorybox({
        id,
        image: imgUrl,
        title,
        page: isPitch ? memberTypes.PITCH : memberTypes.STORY,
      });

    const handleClick = () => {
      const { current } = ref;
      setAnchorEl(current);
      setSelectedId(id);
      setPreviewItemId(id);
    };

    useEffect(() => {
      if (isStorySelected) {
        const { current } = ref;
        setAnchorEl(current);
        setSelectedId(id);
        setPreviewItemId(id);
      } else {
        setAnchorEl(null);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isStorySelected]);

    const handleSameClick = () => {
      setAnchorEl(null);
      setPreviewItemId(null);
      setSelectedId(null);
    };

    const handleDrop = (memberId, storyId, memberType) => {
      console.log('adding to story', memberId, storyId, memberType);
      addMemberToStory(memberId, storyId, memberType);
    };

    const handleOpenStoryFromStoryBox = () => {
      handleClose();
      handleOpenStory();
    };

    return (
      <>
        <Story
          title={title || UNTITLED_STORY}
          updatedAt={timeString}
          mUpdatedAt={updatedAt}
          selectedDateRange={{
            startDate: dateRange.mPublishingAt,
            endDate: dateRange.mPublishingEnd,
          }}
          image={image}
          id={id}
          anchorEl={anchorEl}
          selected={id === currentTabId}
          dragSourceEnabled={dragSourceEnabled}
          onDoubleClick={handleOpenStory}
          onClick={handleClick}
          onSameClick={handleSameClick}
          isPitch={isPitch}
          isRestricted={isRestricted}
          handleDrop={handleDrop}
          setShowStorybox={onSetShowStorybox}
          handleContextClick={handleContextClick}
          onUpdatePublishDate={onUpdatePublishDate}
          assignedUsers={assignedUsers}
          subMenuActionHandler={subMenuActionHandler}
          groupType={groupType}
          archived={archived}
          bookmarked={bookmarked}
          joined={joined}
          dropTargetType={page}
          showArchiveButton={showArchiveButton}
          isStorySelected={isStorySelected}
          isStoryFocused={isStoryFocused}
          storyType={storyType}
          enableUpdate={enableUpdate}
          canSelectRange={canSelectRange}
          disableLeaveButton={disableLeaveButton}
        />
        <Popper style={{ zIndex: 1150 }} anchorEl={anchorEl} position="right-start">
          <StoryBoxWrapper>
            <Suspense fallback={<LoadingIndicator />}>
              <StoryBox
                storySpec={{ id, image }}
                dialogHeight="810px"
                onClose={handleClose}
                onOpenStory={handleOpenStoryFromStoryBox}
                onInstanceClick={onInstanceClick}
                assignedUsers={assignedUsers}
              />
            </Suspense>
          </StoryBoxWrapper>
        </Popper>
      </>
    );
  },
);

StoryContainer.propTypes = {
  /** Story id */
  id: PropTypes.string.isRequired,
  /** Story title */
  title: PropTypes.string,
  /** Thumbnail Url */
  imgUrl: PropTypes.string,
};

StoryContainer.defaultProps = {
  title: UNTITLED_STORY,
  imgUrl: null,
};

export default StoryContainer;
