import { useCallback, useEffect, useMemo, useState } from 'react';

import { ReactComponent as Add } from 'assets/icons/systemicons/plus_small.svg';
import { Button } from 'components/buttons';
import Dialog from 'components/dialogs/DialogBuilder';
import { StyledTextField } from 'components/mdfEditor/fields/text/styled';
import Text from 'components/text/Text';
import useToast from 'components/toast/useToast';
import Tooltip from 'components/tooltip';
import { HStack, VStack } from 'layouts/box/Box';
import {
  Alternative,
  PlatformDTOSettings,
  ProviderDTO,
  ProviderEndPoint,
} from 'types/graphqlTypes';

import { useUpdateProvider } from '../../../hooks/useUpdateProvider';
import { OptionWrapper, Remove } from '../styled';

interface Props {
  open: boolean;
  setOpen: (val: boolean) => void;
  type: string;
  endPoints: ProviderEndPoint[];
  doUpdate: (val: {}) => void;
  setSelectedPoints: (val: ProviderEndPoint[]) => void;
  platform?: PlatformDTOSettings;
  platformProvider?: ProviderDTO;
  endpointOptions?: Alternative[];
  setEendpointOptions?: (val: Alternative[]) => void;
}

const prepareProviderData = (provider: ProviderDTO, newEndpoint: ProviderEndPoint): ProviderDTO => {
  // Clean the `endPoints` array and ensure it's typed correctly
  const cleanData: ProviderDTO = {
    id: provider.id,
    title: provider.title,
    auth: provider.auth,
    baseUrl: provider.baseUrl,
    endPoints: (provider.endPoints || []).map(({ id, url }) => ({ id, url })),
  };

  // Add the new endpoint
  cleanData.endPoints?.push(newEndpoint);

  // Ensure `endPoints` is a unique set based on the `id` field
  cleanData.endPoints = cleanData.endPoints?.reduce((unique, endpoint) => {
    if (!unique.some((item) => item.id === endpoint.id)) {
      unique.push(endpoint);
    }
    return unique;
  }, [] as ProviderEndPoint[]);

  return cleanData;
};

function Point({
  index,
  point,
  endPoints,
  updatePoint,
  removePoint,
}: Readonly<{
  index: number;
  point: ProviderEndPoint;
  endPoints: ProviderEndPoint[];
  updatePoint: (index: number, point: ProviderEndPoint) => void;
  removePoint: (val: ProviderEndPoint) => void;
}>) {
  const [localPoint, setLocalPoint] = useState(point);
  const [finalized, setFinalized] = useState(false); // Track whether the user has finished typing

  const updateEndPoint = useCallback(
    (idx: number, pnt: ProviderEndPoint) => {
      setLocalPoint(pnt);
      updatePoint(idx, pnt);
    },
    [setLocalPoint, updatePoint],
  );

  const handleBlur = () => {
    setFinalized(true);
  };

  // Check for ID duplication only after the input is finalized (on blur)
  const isIdDisabled = useMemo(() => {
    return (
      finalized &&
      endPoints.some((endpoint) => endpoint.id === localPoint.id && localPoint.newPoint)
    );
  }, [endPoints, localPoint.id, finalized]);

  const isUrlDisabled = useMemo(() => {
    return (
      finalized &&
      endPoints.some((endpoint) => endpoint.url === localPoint.url && localPoint.newPoint)
    );
  }, [endPoints, localPoint.url, finalized]);

  let IDtooltipMessage = '';
  let URLtooltipMessage = '';

  if (!localPoint.id) {
    IDtooltipMessage = 'This field is required.';
  } else if (isIdDisabled) {
    IDtooltipMessage = 'ID already exists in endpoints.';
  }
  if (!localPoint.url) {
    URLtooltipMessage = 'This field is required.';
  } else if (isUrlDisabled) {
    URLtooltipMessage = 'URL already exists in endpoints.';
  }

  return (
    <OptionWrapper>
      <VStack>
        <Text variant="caption">ID:</Text>
      </VStack>
      <VStack>
        <Tooltip title={IDtooltipMessage}>
          <StyledTextField
            name="id"
            variant="filled"
            value={localPoint.id}
            onChange={(ev) => {
              setFinalized(false); // Reset finalized state while typing
              updateEndPoint(index, { ...localPoint, id: ev.target.value });
            }}
            onBlur={handleBlur} // Mark the input as finalized when it loses focus
            error={!localPoint.id || isIdDisabled}
            required
            disabled={isIdDisabled}
          />
        </Tooltip>
      </VStack>
      <VStack>
        <Text variant="caption">Endpoint:</Text>
      </VStack>
      <VStack>
        <Tooltip title={URLtooltipMessage}>
          <StyledTextField
            name="endpointUrl"
            variant="filled"
            value={localPoint.url}
            placeholder={'/endpoint'}
            onChange={(ev) => updateEndPoint(index, { ...localPoint, url: ev.target.value })}
            required
            error={!localPoint.url || isUrlDisabled}
            disabled={isUrlDisabled}
          />
        </Tooltip>
      </VStack>
      <Remove className="remove" onClick={() => removePoint(point)} />
    </OptionWrapper>
  );
}

export function EditEndPointDialogue({
  open,
  setOpen,
  doUpdate,
  endPoints,
  type,
  setSelectedPoints,
  platform,
  platformProvider,
  endpointOptions,
  setEendpointOptions,
}: Readonly<Props>) {
  const { updateProvider } = useUpdateProvider();
  const [points, setPoints] = useState<ProviderEndPoint[]>(endPoints);
  const [disableConfirm, setDisableConfirm] = useState(true);
  const { errorToast } = useToast();

  const confirmChanges = async () => {
    // Delete newPoint from newly added point before confirm
    if (points?.length) {
      const lastPoint = points[points.length - 1];
      if ('newPoint' in lastPoint) {
        delete lastPoint.newPoint;
      }
    }
    const provider = platform?.platformInfo?.provider;
    const providerId = provider?.id ?? 'None';

    if (!points || points.length === 0) {
      setOpen(false);
      return;
    }

    const isDuplicate = endpointOptions?.some((option) => option.id === points[0].id);

    if (isDuplicate && (type === 'page-endpoint' || type === 'publish-endpoint')) {
      setOpen(false);
      errorToast({}, 'ID already exists in endpoints.');
      return;
    }

    switch (type) {
      case 'provider-endpoint':
        doUpdate({ endPoints: points });
        break;

      case 'page-endpoint':
        doUpdate({
          platformInfo: {
            ...platform?.platformInfo,
            platform: platform?.platformInfo?.platform ?? '',
            provider: {
              ...platform?.platformInfo?.provider,
              id: providerId,
              pagesEndpoint: points[0],
            },
          },
        });
        break;

      case 'publish-endpoint':
        doUpdate({
          platformInfo: {
            ...platform?.platformInfo,
            platform: platform?.platformInfo?.platform ?? '',
            provider: {
              ...platform?.platformInfo?.provider,
              id: providerId,
              publishEndpoint: points[0],
            },
          },
        });
        break;

      default:
        return;
    }

    setSelectedPoints(points);

    if (setEendpointOptions) {
      setEendpointOptions([
        ...(endpointOptions || []),
        {
          id: points[0].id,
          label: points[0].url,
          value: points[0].url,
        },
      ]);
    }

    if (type === 'page-endpoint' || type === 'publish-endpoint') {
      const updatedProvider = prepareProviderData(platformProvider as ProviderDTO, points[0]);
      await updateProvider(updatedProvider);
    }
    setOpen(false);
  };

  const removeEndPoint = (pointToRemove: ProviderEndPoint) => {
    setPoints(points.filter((point) => point !== pointToRemove));
  };

  const addEndPoint = () => {
    if ((type === 'page-endpoint' || type === 'publish-endpoint') && points.length !== 0) {
      setPoints([...points]);
    } else {
      setPoints([
        ...points,
        { id: '', url: '', newPoint: true }, // Add an empty publishing point
      ]);
    }
  };

  const validatePoints = useCallback(() => {
    const seenIds = new Set();
    const seenUrls = new Set();

    return points.every((point) => {
      if (!point.id || !point.url) return false;

      // Ensure ID and URL are unique
      if (seenIds.has(point.id) || seenUrls.has(point.url)) return false;

      seenIds.add(point.id);
      seenUrls.add(point.url);
      return true;
    });
  }, [points]);

  useEffect(() => {
    setDisableConfirm(!validatePoints());
  }, [validatePoints]);

  const updatePoint = useCallback(
    (index: number, value: ProviderEndPoint) => {
      setPoints((prevState) => prevState.toSpliced(index, 1, value));
    },
    [setPoints],
  );

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      style={{ minWidth: '600px', maxWidth: '800px' }}
    >
      <Dialog.Header>Configure Endpoints</Dialog.Header>
      <Dialog.Body>
        <HStack alignItems="end" gap="10px" justifyContent="space-between" margin="0 0 10px 0">
          <Button
            width={120}
            height={32}
            variant="outlined"
            usage="outlined"
            onClick={addEndPoint}
            title={'Add Publishing Point'}
          >
            <Add className="skipOverride" />
            Add
          </Button>
        </HStack>
        {points.map((point, index) => (
          <Point
            key={index}
            index={index}
            point={point}
            endPoints={endPoints}
            updatePoint={updatePoint}
            removePoint={removeEndPoint}
          />
        ))}
      </Dialog.Body>
      <Dialog.Footer>
        <Dialog.CancelButton />
        <Dialog.ConfirmButton
          label="Confirm"
          onConfirm={confirmChanges}
          disabled={disableConfirm}
        />
      </Dialog.Footer>
    </Dialog>
  );
}
