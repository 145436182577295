import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { TextField } from '@material-ui/core';

import transientOptions from 'theme/helpers';

export const StyledNativeButton = styled('button')`
  cursor: pointer;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: none;
  width: 100%;
  height: 100%;
`;

export const FakeStyledTextField = styled('div')`
  padding: 2px 4px;
  width: 100%;
  font-size: 14px;
  min-height: 24px;
  border-radius: 4px;
  color: rgba(255, 255, 255, 0.55);
  background-color: ${({ theme }) => theme.palette.dina.inputBackground};
  border: 1px solid ${({ theme }) => theme.palette.dina.inputBorderResting};
  &:hover {
    background-color: ${({ theme }) => theme.palette.dina.hoverOverlay};
  }
`;

export const StyledTextField = styled(TextField, transientOptions)`
  svg path {
    fill: ${({ theme }) => theme.palette.dina.highEmphasis};
  }

  .MuiFormHelperText-contained {
    margin: 2px 0 -2px 2px;
    color: ${({ theme }) => theme.palette.dina.statusWarning};
  }

  &.MuiFormControl-root {
    width: 100%;
  }

  .MuiFilledInput-root {
    padding: 2px !important;
    width: 100%;
    display: inline-flex;
    flex-flow: row wrap;
    min-height: 24px;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.palette.dina.inputBackground};
    border: 1px solid ${({ theme }) => theme.palette.dina.inputBorderResting};
    &:hover {
      background-color: ${({ theme }) => theme.palette.dina.hoverOverlay};
    }
    &.Mui-focused {
      ${({ theme }) => css`
        background: ${theme.palette.dina.statusOnFocusedDiscreetFill};
        border-color: ${theme.palette.dina.statusOnFocused} !important;
      `}
    }
    &.Mui-error {
      border-color: ${({ theme }) => theme.palette.dina.warningBorder};
    }
  }
  input,
  textarea {
    width: 100%;
    font-size: 14px;
    padding: 4px !important;
    &::-webkit-input-placeholder,
    &::-moz-placeholder,
    &:-ms-input-placeholder,
    &::-ms-input-placeholder {
      color: ${({ theme }) => theme.palette.dina.disabled};
    }
  }
  .MuiInputBase-inputAdornedEnd {
    padding: 4px 32px 4px 4px !important;
  }
  .MuiFilledInput-underline {
    &:hover:not($disabled):not($focused):not($error):before {
      border-bottom: none;
    }
    &:after {
      border-color: transparent;
    }
    &:before {
      border-color: transparent;
    }
  }
`;

export const ColorStripe = styled('div', transientOptions)<{
  $color?: string;
  $height?: number;
  $selected?: boolean;
}>`
  width: 3px;
  height: ${({ $height }) => $height ?? 14}px;
  border-radius: 1px;
  flex-shrink: 0;
  cursor: pointer;
  background: ${({ $color }) => $color ?? 'transparent'};
  border: 1px solid ${({ $selected }) => ($selected ? 'white' : 'rgba(255,255,255,.2)')};
`;

export const Color = styled('div', transientOptions)<{
  $color?: string;
  $size: number;
  $selected?: boolean;
}>`
  width: ${({ $size }) => $size}px;
  height: ${({ $size }) => $size}px;
  border-radius: 50%;
  flex-shrink: 0;
  cursor: pointer;
  background: ${({ $color }) => $color ?? 'transparent'};
  border: 1px solid
    ${({ $selected, theme }) =>
      $selected ? theme.palette.dina.highEmphasis : theme.palette.dina.lowEmphasis};
`;

export const Emphasis = styled('em')`
  font-style: normal;
  color: ${({ theme }) => theme.palette.dina.info} !important;
`;
