import { ModifierKey, ShortcutSequence, SpecialKey } from 'types/keyboardShortcut';
/**
 * `true` if on a Mac, otherwise `false` (Windows/Linux)
 */
export const isMac = navigator.userAgent.toLowerCase().includes('mac');

const specialKeys: Record<ModifierKey | SpecialKey, { symbol: string; label: string }> = {
  shift: { symbol: '⇧', label: 'Shift' },
  mod: isMac ? { symbol: '⌘', label: 'Command' } : { symbol: 'Ctrl', label: 'Control' },
  alt: isMac ? { symbol: '⌥', label: 'Option' } : { symbol: 'Alt', label: 'Alt' },
  ctrl: { symbol: isMac ? '⌃' : 'Ctrl', label: 'Control' },
  up: { symbol: '↑', label: 'Arrow Up' },
  down: { symbol: '↓', label: 'Arrow Down' },
  left: { symbol: '←', label: 'Arrow Left' },
  right: { symbol: '→', label: 'Arrow Right' },
  enter: { symbol: 'Enter', label: 'Enter' },
  escape: { symbol: 'Esc', label: 'Escape' },
  space: { symbol: 'Space', label: 'Space' },
  tab: { symbol: 'Tab', label: 'Tab' },
  backspace: isMac
    ? { symbol: 'Delete', label: 'Delete' }
    : { symbol: 'Backspace', label: 'Backspace' },
  delete: { symbol: 'Delete', label: 'Delete' },
  insert: { symbol: 'Ins', label: 'Insert' },
};

export const getSymbol = (key: string) =>
  specialKeys[key as ModifierKey | SpecialKey]?.symbol ?? key;

export const getLabel = (key: string) => specialKeys[key as ModifierKey | SpecialKey]?.label ?? key;

export const getKeybinding = (data: ShortcutSequence) => {
  return data
    .split('+')
    .map((key) => getSymbol(key))
    .join('+');
};
