import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { isEqual } from 'lodash';

import { useCreateMdf } from 'api/mdf/useCreateMdf';
import { useDeleteMdf } from 'api/mdf/useDeleteMdf';
import { isMdfCategory, MdfCategory, useGetMdfCategory, useGetMdfs } from 'api/mdf/useGetMdfs';
import { useCreateOptionList } from 'api/optionLists/useCreateOptionList';
import { useDeleteOptionList } from 'api/optionLists/useDeleteOptionList';
import { useGetOptionLists } from 'api/optionLists/useGetOptionLists';
import { EditMdf } from 'components/editMdfDialog/EditMdf';
import { TreeNode } from 'components/editMdfDialog/utils';
import Infobar from 'components/infobar';
import LoadingIndicator from 'components/loadingIndicator';
import Scrollbar from 'components/scrollbar';
import SplitBar from 'components/split';
import useToast from 'components/toast/useToast';
import { NameMappings } from 'features/mdf/conversion';
import { getExistingFieldInfoMap } from 'features/mdf/mdf-utils';
import useGetPlatforms from 'hooks/useGetPlatforms';
import { ChoiceOptionList, Mdf, OptionList, TreeChoiceOptionList } from 'types/graphqlTypes';

import {
  OptionListChangeInfo,
  useChangedMdfs,
  useChangedOptionLists,
  useSelectMdfSchema,
} from '../../atomsTs';
import { OptionListComponent } from '../optionList/OptionList';
import { TreeOptionListComponent } from '../optionList/TreeOptionList';

import CreateMdfItemDialog from './CreateMdfItemDialog';
import CreateSchemaDialog, { MdfCreationGroup } from './CreateSchemaDialog';
import MdfSchemasNavBar, {
  buildSelection,
  getMdfIdFromSelection,
  getOptionListIdFromSelection,
  Selection,
} from './MdfSchemasNavBar';
import {
  OPTIONS_INFO_MAPPER_EX,
  updateOptionListLabel,
  updateOptionListOptions,
  updateOptionTreeOptions,
} from './optionListsHelpers';
import { useMdfSchemaInfoMapper } from './schemaHelpers';
import { SectionId } from './types';

import { FormsWrapper, MainWrapper, Wrapper } from './styled';

const defaultSelection: Selection = 'defaults:story-mdf';

export function MdfSchemas() {
  const reFocusEl = useRef<HTMLElement>();
  const navBarRef = useRef<HTMLUListElement>(null);
  const { errorToast } = useToast();
  const { mdfsSeparated, mdfs, loading, error } = useGetMdfs({ all: true });
  const { allOptionLists, optionLists, optionTrees } = useGetOptionLists();
  const { createMdf } = useCreateMdf();
  const { deleteMdf } = useDeleteMdf();
  const { createOptionList } = useCreateOptionList();
  const { deleteOptionList } = useDeleteOptionList();
  const [schemaGroup, setSchemaGroup] = useState<MdfCreationGroup>('custom');

  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [createMdfItemType, setCreateMdfItemType] = useState<'choice' | 'treechoice' | 'subTypes'>(
    'choice',
  );
  const [createMdfItemModalOpen, setCreateMdfItemModalOpen] = useState(false);
  const [reportedError, setReportedError] = useState('');
  const [reportedErrorSource, setReportedErrorSource] = useState('');
  const [selectOnCreate, setSelectOnCreate] = useSelectMdfSchema();
  const [selectListOnCreate, setSelectListOnCreate] = useState<string | null>(null);
  const [creatingMdf, setCreatingMdf] = useState(false);
  const [creatingList, setCreatingList] = useState(false);
  const [selection, setSelection] = useState<Selection>(defaultSelection);
  const [changedMdfs, setChangedMdfs] = useChangedMdfs();
  const [changedOptionLists, setChangedOptionLists] = useChangedOptionLists();
  const getMdfCategory = useGetMdfCategory();
  const mdfSchemaInfoMapper = useMdfSchemaInfoMapper();
  const { platformVariants } = useGetPlatforms();

  const selectedSchemaId = useMemo(() => getMdfIdFromSelection(selection), [selection]);
  const selectedListId = useMemo(() => getOptionListIdFromSelection(selection), [selection]);
  // Create memoed nameMappings
  const jsonSubtypeNames = useMemo(
    () =>
      JSON.stringify(Object.fromEntries(mdfsSeparated.subTypes.map((mdf) => [mdf.id, mdf.label]))),
    [mdfsSeparated.subTypes],
  );
  const subtypeNames = useMemo(
    () => JSON.parse(jsonSubtypeNames) as Readonly<Record<string, string>>,
    [jsonSubtypeNames],
  );
  const jsonOptionListNames = useMemo(
    () =>
      JSON.stringify(Object.fromEntries(mdfsSeparated.subTypes.map((mdf) => [mdf.id, mdf.label]))),
    mdfsSeparated.subTypes,
  );
  const optionListNames = useMemo(
    () => JSON.parse(jsonOptionListNames) as Readonly<Record<string, string>>,
    [jsonOptionListNames],
  );
  const jsonOptionTreeNames = useMemo(
    () =>
      JSON.stringify(Object.fromEntries(mdfsSeparated.subTypes.map((mdf) => [mdf.id, mdf.label]))),
    mdfsSeparated.subTypes,
  );
  const optionTreeNames = useMemo(
    () => JSON.parse(jsonOptionTreeNames) as Readonly<Record<string, string>>,
    [jsonOptionTreeNames],
  );
  const nameMappings: NameMappings = useMemo(
    () => ({ subtypes: subtypeNames, optionLists: optionListNames, optionTrees: optionTreeNames }),
    [subtypeNames, optionListNames, optionTreeNames],
  );
  const selectedForm = useMemo(() => {
    if (!selectedSchemaId) return null;
    return changedMdfs[selectedSchemaId] ?? mdfs.find((mdf) => mdf.id === selectedSchemaId) ?? null;
  }, [mdfsSeparated, changedMdfs, selectedSchemaId]);

  // Create memoed information about field types
  const existingFieldInfoMap = useMemo(() => getExistingFieldInfoMap(mdfs), [mdfs]);

  const selectedList = useMemo(() => {
    if (!selectedListId) return null;
    return (changedOptionLists[selectedListId]?.item ??
      allOptionLists.find((l) => l.id === selectedListId) ??
      null) as OptionList | null;
  }, [selectedListId, allOptionLists, changedOptionLists]);

  /**
   * Sets the selected schema ID and ensures that the selected list id is cleared if schema is
   * selected. Also makes sure to remove the reported error
   */
  const setSelectionEx = useCallback(
    (arg: Selection | ((prevId: Selection) => Selection)) => {
      if (typeof arg === 'function') {
        setSelection((prev) => arg(prev));
        setReportedErrorSource('');
      } else {
        setSelection(arg);
        setReportedErrorSource('');
      }
    },
    [setSelection, setReportedErrorSource],
  );

  const setSelectedMdf = useCallback(
    (mdf: Mdf) => {
      setSelectionEx(buildSelection(getMdfCategory(mdf), mdf.id));
    },
    [setSelectionEx, getMdfCategory],
  );

  const setSelectedOptions = useCallback(
    (options: OptionList) => {
      setSelectionEx(
        buildSelection(options.optionListType === 'choice' ? 'lists' : 'trees', options.id),
      );
    },
    [setSelectionEx],
  );

  useLayoutEffect(() => {
    if (mdfs?.length > 0 && !selectedForm) {
      setSelectionEx(defaultSelection);
    }
  }, [mdfs]);

  async function deleteItemAsync(type: 'mdf' | 'options', id: string) {
    try {
      await (type === 'mdf' ? deleteMdf : deleteOptionList)(id);
    } catch (e) {
      const details = e instanceof Error ? ` Reported error: ${e.message}` : '';
      throw new Error(`Deletion failed.${details}`);
    }
  }
  const doDeleteItemRef = useRef(deleteItemAsync);
  doDeleteItemRef.current = deleteItemAsync;

  const onMdfChange = useCallback(
    (mdf: Mdf | null, originalMdf: Mdf | null) => {
      if (mdf === null || originalMdf === null) return;
      if (!changedMdfs[mdf.id] && !isEqual(mdf, originalMdf)) {
        setChangedMdfs({
          ...changedMdfs,
          [mdf.id]: mdf,
        });
      } else if (changedMdfs[mdf.id]) {
        if (isEqual(mdf, originalMdf)) {
          const copy = { ...changedMdfs };
          delete changedMdfs[mdf.id];
          setChangedMdfs(copy);
        } else {
          setChangedMdfs((prevState) => {
            return {
              ...prevState,
              [mdf.id]: mdf,
            };
          });
        }
      }
      setSelectedMdf(mdf);
    },
    [changedMdfs, setChangedMdfs, setSelectedMdf],
  );

  const reportMdfSchemaError = useCallback(
    (schemaId: string, problem: string) => {
      setReportedError(problem);
      setReportedErrorSource(schemaId);
    },
    [setReportedError, setReportedErrorSource],
  );

  const onListChange = useCallback(
    (list: ChoiceOptionList, originalList: ChoiceOptionList) => {
      setChangedOptionLists((prevState) => updateOptionListOptions(prevState, list, originalList));
    },
    [setChangedOptionLists],
  );

  const onOptionTreeChange = useCallback(
    (
      updatedOptions: readonly TreeNode[],
      originalOptions: readonly TreeNode[],
      originalList: TreeChoiceOptionList,
    ) => {
      setChangedOptionLists((prevState) =>
        updateOptionTreeOptions(prevState, updatedOptions, originalOptions, originalList),
      );
    },
    [setChangedOptionLists],
  );

  function renameItemImpl(type: 'mdf' | 'options', id: string, label: string) {
    if (type === 'mdf') {
      const origMdf = mdfs.find((item) => item.id === id);
      const prevMdf = changedMdfs[id] ?? origMdf;
      if (origMdf) {
        onMdfChange({ ...prevMdf, label }, origMdf);
      }
    } else {
      const list = allOptionLists.find((item) => item.id === id);
      if (list) {
        setChangedOptionLists((prev) => updateOptionListLabel(prev, label, list));
      }
    }
  }
  const renameItemRef = useRef(renameItemImpl);
  renameItemRef.current = renameItemImpl;
  const renameItem = useCallback(
    (type: 'mdf' | 'options', id: string, label: string) => renameItemRef.current(type, id, label),
    [renameItemRef],
  );

  function addItemImpl(category: Exclude<SectionId, 'defaults'>) {
    reFocusEl.current = (document.activeElement ?? undefined) as HTMLElement | undefined;
    if (category === 'subTypes') {
      setCreateMdfItemType(category);
      setCreateMdfItemModalOpen(true);
    } else if (isMdfCategory(category)) {
      setCreateModalOpen(true);
      setSchemaGroup(category);
    } else {
      setCreateMdfItemType(category === 'lists' ? 'choice' : 'treechoice');
      setCreateMdfItemModalOpen(true);
    }
  }
  const onAddItemRef = useRef(addItemImpl);
  onAddItemRef.current = addItemImpl;
  const onAddItem = useCallback(
    (category: Exclude<SectionId, 'defaults'>) => onAddItemRef.current(category),
    [onAddItemRef],
  );
  const onConfirmCreateMdf = useCallback(
    (label: string, group: Exclude<MdfCategory, 'defaults'>, id?: string) => {
      setCreatingMdf(true);
      createMdf({ label, isSubtype: group === 'subTypes', id })
        .then((val) => {
          if (val) setSelectOnCreate(val.id);
        })
        .catch(errorToast)
        .finally(() => {
          setCreatingMdf(false);
          setCreateModalOpen(false);
          setCreateMdfItemModalOpen(false);
        });
    },
    [setCreatingMdf, setSelectOnCreate, setCreateMdfItemModalOpen],
  );

  const doOpenOptionList = useCallback(
    (id: string) => {
      const listOrTree = allOptionLists.find((item) => item.id === id);
      if (listOrTree) {
        setSelectedOptions(listOrTree);
      }
    },
    [setSelectedOptions, allOptionLists],
  );

  const onConfirmCreateOptions = useCallback(
    (label: string, category: 'choice' | 'treechoice', id: string) => {
      setCreatingList(true);
      createOptionList({ label, id, optionListType: category })
        .then((val) => {
          if (val) setSelectListOnCreate(val.id);
        })
        .catch(errorToast)
        .finally(() => {
          setCreatingList(false);
          setCreateMdfItemModalOpen(false);
        });
    },
    [setCreatingList, setCreateMdfItemModalOpen, setSelectListOnCreate],
  );

  const getSubtypeIdProblem = useCallback(
    (id: string) => {
      if (Object.values(mdfsSeparated.subTypes).some((mdf) => mdf.label === id)) {
        return `'${id}' is used as label for a subtype with a legacy ID`;
      }
      const platform = Object.values(platformVariants).find((p) => p.id === id);
      if (platform) {
        return `'${id}' is reserved for instance schema for ${platform.label}`;
      }
      return undefined;
    },
    [mdfsSeparated.subTypes, platformVariants],
  );
  useEffect(() => {
    if (selectOnCreate) {
      const createdMdf = mdfs.find((mdf) => mdf.id === selectOnCreate);
      if (createdMdf) {
        setSelectedMdf(createdMdf);
        setSelectOnCreate(null);
      }
    }
  }, [selectOnCreate, mdfs, setSelectOnCreate, setSelectedMdf]);

  useEffect(() => {
    if (selectListOnCreate) {
      const createdList = allOptionLists.find((list) => list.id === selectListOnCreate);
      if (createdList) {
        setSelectedOptions(createdList);
        setSelectListOnCreate(null);
      }
    }
  }, [
    selectListOnCreate,
    allOptionLists,
    setSelectListOnCreate,
    setSelectedOptions,
    buildSelection,
  ]);

  useEffect(() => {
    if (!createModalOpen && !createMdfItemModalOpen && reFocusEl.current) {
      reFocusEl.current.focus();
      reFocusEl.current = undefined;
    }
  }, [createModalOpen, createMdfItemModalOpen]);
  const SideBar = useMemo(() => {
    return (
      <FormsWrapper>
        {loading || (error && <LoadingIndicator className={undefined} height={32} width={32} />)}
        <Scrollbar>
          <MdfSchemasNavBar
            treeRef={navBarRef}
            selection={selection}
            updateSelection={setSelectionEx}
            mdfsSeparated={mdfsSeparated}
            optionLists={optionLists}
            optionTrees={optionTrees}
            changedMdfs={changedMdfs}
            changedListsAndTrees={changedOptionLists}
            renameItem={renameItem}
            doDeleteItem={doDeleteItemRef.current}
            onAddItem={onAddItem}
          />
        </Scrollbar>
      </FormsWrapper>
    );
  }, [
    loading,
    error,
    selection,
    setSelectionEx,
    mdfsSeparated,
    optionLists,
    optionTrees,
    changedMdfs,
    changedOptionLists,
  ]);

  return (
    <Wrapper>
      <SplitBar
        split={undefined}
        style={{
          height: '100%',
        }}
        primary="first"
        pane1Style={{
          minWidth: '180px',
          maxWidth: '300px',
        }}
        pane2Style={{
          minWidth: '200px',
          height: '100%',
        }}
      >
        {SideBar}
        {(selectedForm || selectedList) && (
          <MainWrapper>
            {reportedError && reportedErrorSource === selectedForm?.id && (
              <Infobar border="statusWarning">{reportedError}</Infobar>
            )}
            {selectedForm && (
              <EditMdf
                mdf={selectedForm}
                onChange={onMdfChange}
                nameMappings={nameMappings}
                existingFieldInfoMap={existingFieldInfoMap}
                doOpenOptionList={doOpenOptionList}
                reportError={reportMdfSchemaError}
              />
            )}
            {!selectedForm &&
              selectedList &&
              (selectedList.optionListType === 'choice' ? (
                <OptionListComponent
                  list={
                    (changedOptionLists[selectedList.id]?.item as ChoiceOptionList | undefined) ??
                    selectedList
                  }
                  listIsUpdated={!!changedOptionLists[selectedListId ?? '']}
                  onChange={onListChange}
                />
              ) : (
                <TreeOptionListComponent
                  listId={selectedList.id}
                  label={changedOptionLists[selectedList.id]?.item.label ?? selectedList.label}
                  changedOptions={changedOptionLists[selectedListId ?? '']?.treeOptions}
                  onChange={onOptionTreeChange}
                />
              ))}
          </MainWrapper>
        )}
      </SplitBar>
      <CreateSchemaDialog
        mdfsSeparated={mdfsSeparated}
        schemaGroup={schemaGroup}
        open={createModalOpen}
        setOpen={setCreateModalOpen}
        creatingMdf={creatingMdf}
        onCreate={onConfirmCreateMdf}
      />
      {!isMdfCategory(createMdfItemType) && (
        <CreateMdfItemDialog<OptionList, OptionListChangeInfo, OptionList['optionListType']>
          items={allOptionLists}
          changeMap={changedOptionLists}
          open={createMdfItemModalOpen}
          setOpen={setCreateMdfItemModalOpen}
          infoMapper={OPTIONS_INFO_MAPPER_EX}
          category={createMdfItemType}
          creating={creatingList}
          onCreate={onConfirmCreateOptions}
        />
      )}
      {isMdfCategory(createMdfItemType) && (
        <CreateMdfItemDialog<Mdf, Mdf, 'subTypes'>
          items={mdfs}
          changeMap={changedMdfs}
          open={createMdfItemModalOpen}
          setOpen={setCreateMdfItemModalOpen}
          infoMapper={mdfSchemaInfoMapper}
          category={createMdfItemType}
          creating={creatingList}
          onCreate={onConfirmCreateMdf}
          getIdProblem={getSubtypeIdProblem}
        />
      )}
    </Wrapper>
  );
}
