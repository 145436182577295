import { useEffect } from 'react';
import { NetworkStatus, useQuery } from '@apollo/client';

import GET_MESSAGES_OF_CONVERSATION from 'operations/queries/getMessagesOfConversation';
import { Conversation } from 'types/messageHub';

function useGetMessages(mId: string) {
  const variables = {
    input: { mId },
    limit: 25,
  };

  const { data, loading, error, fetchMore, refetch, networkStatus } = useQuery<{
    getMessagesOfConversation: { items: Conversation[]; nextToken: string };
  }>(GET_MESSAGES_OF_CONVERSATION, {
    variables,
    skip: !mId,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    const triggerRefetch = () => void refetch();
    window.addEventListener('online', triggerRefetch);
    return () => {
      window.removeEventListener('online', triggerRefetch);
    };
  }, [refetch]);

  const handleLoadMore = async () => {
    if (data?.getMessagesOfConversation?.nextToken) {
      await fetchMore({
        variables: {
          ...variables,
          nextToken: data?.getMessagesOfConversation.nextToken,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => ({
          getMessagesOfConversation: {
            items: [
              ...previousResult.getMessagesOfConversation.items,
              ...fetchMoreResult.getMessagesOfConversation.items,
            ],
            nextToken: fetchMoreResult.getMessagesOfConversation.nextToken,
            __typename: 'PaginatedMessageType',
          },
        }),
      });
    }
  };

  return {
    messages: (data?.getMessagesOfConversation?.items || []).toSorted(
      (a, b) => new Date(b.mCreatedAt ?? '').getTime() - new Date(a.mCreatedAt ?? '').getTime(),
    ),
    loading,
    error,
    hasMore: Boolean(data?.getMessagesOfConversation?.nextToken),
    fetchMore: handleLoadMore,
    refetch,
    initialLoading:
      networkStatus === NetworkStatus.loading || networkStatus === NetworkStatus.setVariables,
  };
}

export default useGetMessages;
