import { Amplify, ResourcesConfig } from 'aws-amplify';

import { getAuthConfiguration } from 'features/auth';

const awsConfig: ResourcesConfig = {
  Auth: getAuthConfiguration(),
  Storage: {
    S3: {
      bucket: import.meta.env.REACT_APP_S3_BUCKET_NAME as string,
      region: import.meta.env.REACT_APP_AWS_APPSYNC_REGION as string,
    },
  },
};

if (import.meta.env.REACT_APP_API_EVENTS_ENDPOINT) {
  const endpoint = import.meta.env.REACT_APP_API_EVENTS_ENDPOINT as string;
  const region = import.meta.env.REACT_APP_AWS_APPSYNC_REGION as string;
  awsConfig.API = { Events: { endpoint, region, defaultAuthMode: 'userPool' } };
}

export function configureAmplify() {
  Amplify.configure(awsConfig);
}
