import { useCallback } from 'react';
import { useVirtualizer } from '@tanstack/react-virtual';

import { TableBody } from 'components/dataTable/Table';
import { EmptyState } from 'features/grids/common/components/EmptyState';

import { DataTableBody } from '../types';

import { MemoTableRowWrapper } from './TableRowWrapper';

const estimateSize = () => 32;

export function Body<T>({ containerRef, table, enableDragging }: Readonly<DataTableBody<T>>) {
  const { rows } = table.getRowModel();

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    getScrollElement: () => containerRef.current,
    getItemKey: useCallback((index: number) => rows[index]?.id ?? index, [rows]),
    estimateSize,
    overscan: 50,
    measureElement:
      typeof window !== 'undefined' && navigator.userAgent.indexOf('Firefox') === -1
        ? (element) => element?.getBoundingClientRect().height
        : undefined,
  });

  const virtualRows = rowVirtualizer.getVirtualItems();
  const columnVisibility = table.getState().columnVisibility;

  return (
    <TableBody style={{ height: `${rowVirtualizer.getTotalSize()}px` }}>
      {virtualRows.length > 0 ? (
        <>
          {virtualRows.map((virtualRow) => {
            const isSkeletonRow = virtualRow.index >= rows.length;
            return (
              <MemoTableRowWrapper
                enableDragging={enableDragging}
                rows={rows}
                key={isSkeletonRow ? `skeleton_${virtualRow.key}` : virtualRow.key}
                measureElement={rowVirtualizer.measureElement}
                start={virtualRow.start}
                isSkeletonRow={isSkeletonRow}
                rowMeta={table.options.meta?.rowMeta}
                // All below props are only sent in to break memoization.
                // If you find rerenders are lacking due to taking new table features
                // in use, ensure you pass values here.
                staticRowIndex={virtualRow.index}
                table={table}
                columnVisibility={columnVisibility}
              />
            );
          })}
        </>
      ) : (
        <EmptyState>{table.options.meta?.emptyStateText}</EmptyState>
      )}
    </TableBody>
  );
}
