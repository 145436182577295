import { atom, useAtom, useSetAtom } from 'jotai';
import {
  isMos,
  parseMos,
  getMosId,
  getMosChanged,
  getObjId,
  getMosValue,
} from 'components/editor/utils/oldEditor/mos';
import { parseJSON } from 'utils/jsonUtils';
import { externalUrlsAtom } from './externalUrls';

const responseTypes = {
  NCSAPPINFO: 'ncsAppInfo',
  ACK: 'ack',
};

const getMosVariant = (mosId, externalUrls) => {
  try {
    const extUrl = externalUrls.find((url) => url.mosId === mosId && url.type === 'mos');
    if (extUrl) return 'mos';
  } catch (error) {
    return 'graphic';
  }
  return 'graphic';
};

const getPanel = (origin, externalUrls) => {
  try {
    const extUrl = externalUrls.find((url) => url?.externalUrl?.trim().startsWith(origin));
    if (extUrl) return extUrl;
  } catch (error) {
    return undefined;
  }
  return 'pilotedge';
};

const getXmlValue = (xmlDoc, itemTag) => getMosValue(xmlDoc, itemTag);

const getAutomationIconUrl = (xmlDoc, mosID, externalUrls) => {
  const extUrl = externalUrls.find((url) => url.mosId === mosID);
  if (!extUrl) return undefined;
  const { iconUrl, iconXmlTag = 'icon' } = extUrl;
  const url = getXmlValue(xmlDoc, iconXmlTag);
  if (!url) return undefined;
  return `${iconUrl}${url}`;
};

const mapMimirItem = (data) => {
  const mimirItem = parseJSON(data);
  const { itemType, itemState, proxy, thumbnail, mediaDuration } = mimirItem;
  const derivedItem = {
    ...mimirItem,
    thumbnailUrl:
      !proxy && itemState === 'new' ? 'https://dev.mimir.tv/assets/missing-item.png' : thumbnail,
    itemDuration: mediaDuration,
    proxy: !proxy && itemType === 'video' ? 'missingproxy' : proxy,
    item: mimirItem,
    itemState,
  };
  return derivedItem;
};

const mapAmppItems = (data) => {
  const parsedData = parseJSON(data);

  if (Array.isArray(parsedData)) {
    return parsedData.map((item) => ({
      ...item,
      thumbnailUrl: item.thumbnail,
      proxy: item.proxy,
      itemDuration: item.mediaDuration,
      item,
    }));
  }

  return [
    {
      ...parsedData,
      thumbnailUrl: parsedData.thumbnail,
      proxy: parsedData.proxy,
      itemDuration: parsedData.mediaDuration,
      item: parsedData,
    },
  ];
};

const sendToPanel = (data, externalUrls, responseType) => {
  const extUrl = getPanel(data.origin, externalUrls);
  if (!extUrl) return;
  let response = `<mos><ncsAck><status>OK</status></ncsAck></mos>`;

  if (responseType === responseTypes.NCSAPPINFO) {
    const { mosId = '' } = extUrl;
    response = `<mos><ncsID>dina.app</ncsID><mosID>${mosId}</mosID><ncsAppInfo><ncsInformation><userID>dina</userID></ncsInformation></ncsAppInfo></mos>`;
  }
  let frameId = 'pilotedge';

  switch (extUrl.type) {
    case 'mos':
      frameId = 'mos';
      break;
    case 'generic':
      frameId = extUrl?.mTitle || 'origin';
      break;
  }

  const ifrm = document.getElementById(frameId);
  if (!ifrm) return;
  ifrm?.contentWindow.postMessage(response, data.origin);
  console.log(`Send message to:${frameId} ${data.origin}\n`, response);
};

const verifyMimirItem = (data) => {
  const mimirItem = parseJSON(data);
  return mimirItem && mimirItem?.self && mimirItem.provider === 'Mimir';
};

export const verifyMosItem = (data) => isMos(data);

/**
 * Serializes a specified XML element as a string with whitespace between tags removed.
 *
 * @param {Document|Element} xmlRoot - The root XML document or element to search within.
 * @param {string} elementName - The name of the XML element to serialize.
 * @returns {string|undefined} The serialized XML string of the specified element, or `undefined` if the element is not found.
 */
export const getXMLElementAsString = (xmlRoot, elementName) => {
  const itemElement = xmlRoot?.querySelector(elementName);
  if (itemElement) {
    const serializer = new XMLSerializer();
    const serializedItem = serializer.serializeToString(itemElement);
    return serializedItem.replace(/>(\s+)</g, '><');
  }
};

const verifyAmppItems = (data) => {
  if (!data) return false;
  const parsedData = parseJSON(data);
  return Array.isArray(parsedData)
    ? parsedData.every((item) => item?.provider && item.provider === 'ampp')
    : parsedData?.provider && parsedData.provider === 'ampp';
};

// atoms

const externalDataAtom = atom([]);
export const useExternalData = () => useAtom(externalDataAtom);

const scratchpadDataAtom = atom([]);
export const useScratchpadData = () => useAtom(scratchpadDataAtom);

const newScratchpadItemCountAtom = atom(0);
export const useNewScratchpadItemCount = () => useAtom(newScratchpadItemCountAtom);

const mosItemReplaceAtom = atom('');
export const useMosItemReplace = () => useAtom(mosItemReplaceAtom);

const addItemToScratchpadAtom = atom(null, (get, set, nextValue) => {
  const data = nextValue?.data;

  if (
    !data ||
    data.source === 'react-devtools-bridge' ||
    data.source === 'react-devtools-content-script' ||
    data.source === 'react-devtools-inject-backend' ||
    data.message === 'action-hook-fired' ||
    data.message === 'devtools-initialized' ||
    data.action === 'mimir_loaded' ||
    data.type === 'webpackOk' ||
    data.type === 'webpackStillOk' ||
    data.type === 'webpackInvalid'
  ) {
    return;
  }

  if (verifyMimirItem(data)) {
    const mappedMimirItem = mapMimirItem(data);
    set(addMimirItemToScratchpadAtom, mappedMimirItem);
    set(addMimirItemToThumbnailAtom, mappedMimirItem);
    return;
  }

  if (verifyMosItem(data)) {
    const xmlDoc = parseMos(data);
    const mos = xmlDoc.querySelector('mos');
    const ncsAck = xmlDoc.querySelector('ncsAck');
    const ncsStoryRequest = xmlDoc.querySelector('ncsStoryRequest');
    if (ncsStoryRequest) return;
    if (ncsAck) return;
    const ncsReqAppInfo = xmlDoc.querySelector('ncsReqAppInfo');
    const externalUrls = get(externalUrlsAtom);
    if (ncsReqAppInfo) {
      sendToPanel(nextValue, externalUrls, responseTypes.NCSAPPINFO);
      return;
    }
    const mosItemReplace = xmlDoc.querySelector('mosItemReplace');
    const mosTxt = mos.outerHTML;
    set(addMosItemToScratchpadAtom, mosTxt);
    set(addMosItemToThumbnailAtom, mosTxt);
    if (mosItemReplace) {
      const mosItemStr = getXMLElementAsString(mosItemReplace, 'item');
      // Note revert to the former mosTxt in case (should never happen) an item element is not found.
      const mosReplace = mosItemStr ? `<mos><ncsItem>${mosItemStr}</ncsItem></mos>` : mosTxt;
      set(addMosItemReplaceAtom, mosReplace);
    }
    sendToPanel(nextValue, externalUrls, responseTypes.ACK);
    return;
  }

  if (verifyAmppItems(data)) {
    const items = mapAmppItems(data);
    set(addAmppItemToScratchPadAtom, items);
    set(addAmppItemToThumbnailAtom, items);
  }
});

export const useSetAddItemToScratchpad = () => useSetAtom(addItemToScratchpadAtom);

const addMosItemToScratchpadAtom = atom(null, (get, set, nextValue) => {
  const externalUrls = get(externalUrlsAtom);
  const xmlDoc = parseMos(nextValue);
  const mosId = getMosId(xmlDoc);
  const abstract = getXmlValue(xmlDoc, 'mosAbstract');
  const objId = getObjId(xmlDoc);

  const changed = getMosChanged(xmlDoc);

  const variant = getMosVariant(mosId, externalUrls);

  const iconUrl = getAutomationIconUrl(xmlDoc, mosId, externalUrls);

  const scratchpadData = get(scratchpadDataAtom);

  const itemExistsInScratchPad = scratchpadData.find((data) => data.id === objId);

  if (!itemExistsInScratchPad) {
    const newItem = {
      item: nextValue,
      id: objId,
      fileName: abstract,
      date: changed,
      variant,
      isNew: true,
      image: iconUrl,
      iconUrl,
    };

    set(scratchpadDataAtom, [newItem, ...scratchpadData]);
    set(newScratchpadItemCountAtom, (prev) => prev + 1);
  }
});

const addMosItemToThumbnailAtom = atom(null, (get, set, nextValue) => {
  const externalUrls = get(externalUrlsAtom);
  const xmlDoc = parseMos(nextValue);
  const objId = getObjId(xmlDoc);
  const mosId = getMosId(xmlDoc);
  const title = getXmlValue(xmlDoc, 'mosAbstract');
  const variant = getMosVariant(mosId, externalUrls);
  const iconUrl = getAutomationIconUrl(xmlDoc, mosId, externalUrls);
  const filteredExternalData = get(externalDataAtom).filter((data) => data.id !== objId);
  const externalData = { id: objId, title, variant, image: iconUrl, iconUrl, item: nextValue };
  objId && set(externalDataAtom, [externalData, ...filteredExternalData]);
});

const addMosItemReplaceAtom = atom(null, (get, set, nextValue) => {
  const externalUrls = get(externalUrlsAtom);
  const xmlDoc = parseMos(nextValue);
  const objId = getObjId(xmlDoc);
  const mosId = getMosId(xmlDoc);
  const itemId = getXmlValue(xmlDoc, 'itemID');
  const title = getXmlValue(xmlDoc, 'mosAbstract');
  const variant = getMosVariant(mosId, externalUrls);
  const iconUrl = getAutomationIconUrl(xmlDoc, mosId, externalUrls);
  const externalData = {
    mosId,
    id: objId,
    objId,
    itemId,
    title,
    variant,
    image: iconUrl,
    iconUrl,
    item: nextValue,
  };
  objId && set(mosItemReplaceAtom, externalData);
});

const addMimirItemToScratchpadAtom = atom(null, (get, set, nextValue) => {
  const { id, title, itemCreatedOn, thumbnailUrl, itemDuration } = nextValue;
  const itemExists = get(scratchpadDataAtom).find((data) => data.id === id);

  if (!itemExists) {
    const newItem = {
      item: nextValue,
      id,
      fileName: title,
      date: itemCreatedOn,
      image: thumbnailUrl,
      variant: 'video',
      isNew: true,
      duration: itemDuration,
    };
    set(scratchpadDataAtom, (prev) => [newItem, ...prev]);
    set(newScratchpadItemCountAtom, (prev) => prev + 1);
  }
});

const addMimirItemToThumbnailAtom = atom(null, (get, set, nextValue) => {
  const { id, thumbnailUrl, itemDuration } = nextValue;
  const filteredExternalData = get(externalDataAtom).filter((data) => data.id !== id);
  const newData = {
    id,
    variant: 'video',
    image: thumbnailUrl,
    duration: itemDuration,
    item: nextValue,
  };
  set(externalDataAtom, [newData, ...filteredExternalData]);
});

const addAmppItemToScratchPadAtom = atom(null, (get, set, nextValue) => {
  const scratchpadData = get(scratchpadDataAtom);

  const newItems = nextValue.reduce(
    (acc, cur) => {
      const { id, title, itemCreatedOn, thumbnailUrl, itemDuration } = cur;
      const itemExists = scratchpadData.find((sData) => sData.id === id);

      if (!itemExists) {
        const newItem = {
          item: cur,
          id,
          fileName: title,
          date: itemCreatedOn,
          image: thumbnailUrl,
          variant: 'video',
          isNew: true,
          duration: itemDuration,
        };

        return {
          itemsToAdd: [newItem, ...acc.itemsToAdd],
          count: acc.count + 1,
        };
      }
      return acc;
    },
    { itemsToAdd: [], count: 0 },
  );

  set(scratchpadDataAtom, (prev) => [...newItems.itemsToAdd, ...prev]);
  set(newScratchpadItemCountAtom, (prev) => prev + newItems.count);
});

const addAmppItemToThumbnailAtom = atom(null, (get, set, nextValue) => {
  const externalData = get(externalDataAtom);

  const newExternalItems = nextValue.reduce((acc, cur) => {
    const { id, thumbnailUrl, itemDuration } = cur;
    const filteredAccumulator = acc.filter((data) => data.id !== id);
    const newData = {
      id,
      variant: 'video',
      image: thumbnailUrl,
      duration: itemDuration,
      item: cur,
    };
    return [newData, ...filteredAccumulator];
  }, externalData);

  set(externalDataAtom, [...newExternalItems]);
});
