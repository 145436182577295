import { useCallback } from 'react';
import { useMutation } from '@apollo/client';

import CREATE_ORGANIZATION from 'operations/mutations/createOrganization';
import GET_ORGANIZATIONS from 'operations/queries/getOrganizations';
import {
  type CreateOrganizationInput,
  OrgAccessTypeEnum,
  type Organization,
} from 'types/graphqlTypes';

import { FetchOrganizations } from './useGetOrganizations';

interface CreateOrganization {
  createOrganization: Organization;
}

function useCreateOrganization() {
  const [create, { loading, error }] = useMutation<
    CreateOrganization,
    { input: CreateOrganizationInput }
  >(CREATE_ORGANIZATION);

  const createOrganization = useCallback(
    async ({ title, description, timeZone, isRestricted }: CreateOrganizationInput) => {
      const result = await create({
        variables: { input: { title, description, timeZone, isRestricted } },
        update: (cache, mutationResult) => {
          const newOrg = mutationResult.data?.createOrganization;
          if (!newOrg?.id) return;
          const cachedResult = cache.readQuery<FetchOrganizations>({
            query: GET_ORGANIZATIONS,
            variables: { input: { accessType: OrgAccessTypeEnum.Readable } },
          });
          if (!cachedResult) return;
          cache.writeQuery<FetchOrganizations>({
            query: GET_ORGANIZATIONS,
            variables: { input: { accessType: OrgAccessTypeEnum.Readable } },
            data: {
              ...cachedResult,
              getOrganizations: [
                newOrg,
                ...cachedResult.getOrganizations.filter((o) => o.id !== newOrg.id),
              ],
            },
          });
        },
      });
      return result.data?.createOrganization;
    },
    [create],
  );

  return { createOrganization, loading, error };
}

export default useCreateOrganization;
