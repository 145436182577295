import { gql } from '@apollo/client';

export default gql`
  mutation CreateOrganization($input: CreateOrganizationInput) {
    createOrganization(input: $input) {
      id
      type
      title
      description
      mdfId
      metadata
      createdAt
      createdById
      updatedAt
      updatedById
      timeZone
      isArchived
      isRestricted
    }
  }
`;
