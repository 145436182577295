import { useQuery, WatchQueryFetchPolicy } from '@apollo/client';

import GET_ORGANIZATION_USERS from 'operations/queries/getOrganizationUsers';
import type { OrganizationUser } from 'types/graphqlTypes';

export interface FetchOrganizationsUsers {
  getOrganizationUsers: OrganizationUser[];
}

const EMPTY_RESULT: OrganizationUser[] = [];

function useGetOrganizationUsers(
  orgId: string,
  fetchPolicy: WatchQueryFetchPolicy = 'cache-and-network',
  skip: boolean = false,
) {
  const { data, loading, error } = useQuery<FetchOrganizationsUsers>(GET_ORGANIZATION_USERS, {
    variables: { input: { orgId } },
    fetchPolicy,
    nextFetchPolicy: fetchPolicy,
    skip: skip || orgId === '',
  });

  return { users: data?.getOrganizationUsers ?? EMPTY_RESULT, loading, error };
}

export default useGetOrganizationUsers;
