import { useCallback, useMemo, useState } from 'react';

import Group, { Empty } from '../Group';

import InstanceItem, { InstanceSkeleton } from './Instance';

interface InstancesProps {
  instanceIds: string[];
  label: string;
  loading?: boolean;
}
export default function Instances({ instanceIds, label, loading }: Readonly<InstancesProps>) {
  const [collapsed, setCollapsed] = useState(true);

  const toggleCollapsed = useCallback(() => setCollapsed(!collapsed), [collapsed]);
  const moreCount = useMemo(() => {
    if (instanceIds) return Math.max(instanceIds?.length - 3, 0);
    return 0;
  }, [instanceIds]);

  return (
    <Group
      label={label}
      collapsed={moreCount > 0 ? collapsed : undefined}
      toggleCollapsed={toggleCollapsed}
      moreCount={moreCount}
    >
      <>
        {loading ? (
          Array.from({ length: 3 }, (_, index) => <InstanceSkeleton key={index} />)
        ) : instanceIds?.length === 0 ? (
          <Empty label="Instances" />
        ) : (
          (
            (collapsed && instanceIds?.length > 3 ? instanceIds?.slice(0, 3) : instanceIds) ?? []
          ).map((instance) => <InstanceItem key={instance} mId={instance} />)
        )}
      </>
    </Group>
  );
}
