import { useCallback, useContext, useRef } from 'react';
import { atom, useSetAtom } from 'jotai';
import { molecule, useMolecule } from 'jotai-molecules';

import { getWords } from 'components/editor/utils';
import useToast from 'components/toast/useToast';
import UserContext from 'contexts/UserContext';
import useInstanceMetadata from 'features/instance/hooks/useInstanceMetadata';
import { InstanceScope } from 'features/instance/store/instance';
import useDebouncedCallback from 'hooks/useDebouncedCallback';
import { getTime } from 'screens/rundown/components/editor/utils';
import { CustomElement, EditorValue } from 'types';
import {
  getDinaSessionIdFromLockedId,
  getScopeFromLockedId,
  getUserIdFromLockedId,
} from 'utils/lock/lockTokenV2';
import { getDinaSessionId } from 'utils/sessionId';
import useLogger from 'utils/useLogger';

import useUpdateScript from '../api/useUpdateScript';
import useScriptScope from '../hooks/useScriptScope';

import { scriptMolecule, scriptStateMutex } from '.';

export const scriptContentMolecule = molecule((getMol, getScope) => {
  getScope(InstanceScope);

  const { updateScript } = useUpdateScript();

  const { baseAtom } = getMol(scriptMolecule);

  const { mId: currentUserId } = useContext(UserContext);
  const currentEditingScope = useScriptScope();

  const { error: errorLog } = useLogger('scriptStore:content');
  const { errorToast } = useToast();

  /** current editor value, keeps track of latest changes */
  const editorValueRef = useRef<EditorValue | null>(null);
  /** interval ref for debounced save */
  const saveTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  /** interval ref for periodic save */
  const periodicSaveRef = useRef<NodeJS.Timeout | null>(null);

  const updateScriptDurationAtom = atom(null, (get, set, scriptDuration: string) => {
    set(baseAtom, (prev) => ({ ...prev, scriptDuration }));
  });

  const updateContentAtom = atom(null, (get, set, content: EditorValue | null) => {
    const { script } = get(baseAtom);

    if (script) {
      set(baseAtom, (prev) => ({ ...prev, content }));
    }
  });

  const saveContentAtom = atom(
    null,
    async (
      get,
      set,
      {
        content,
        /** doesn't update content state, only saves to s3 */
        silent = false,
        createVersion = false,
      }: { content: EditorValue | null; silent?: boolean; createVersion?: boolean },
    ) => {
      const { content: initialContent, lockedBy, script, isLocked, canUpdate } = get(baseAtom);

      const isSameScope = currentEditingScope === getScopeFromLockedId(lockedBy);
      const lockedUserId = getUserIdFromLockedId(lockedBy);

      if (
        script &&
        canUpdate &&
        content &&
        isLocked &&
        isSameScope &&
        lockedUserId === currentUserId &&
        getDinaSessionId(currentUserId) === getDinaSessionIdFromLockedId(lockedBy)
      ) {
        const release = await scriptStateMutex.acquire();

        set(baseAtom, (prev) => ({
          ...prev,
          isSaving: true,
          content: silent ? prev.content : content,
        }));

        try {
          await updateScript(
            {
              mId: script.mId,
              mRefId: script.mRefId,
              content: JSON.stringify(content),
              locked: lockedBy,
              audit: {
                message: 'update script content',
              },
              createVersion,
            },
            true,
          );

          set(baseAtom, (prev) => ({
            ...prev,
            content: silent ? prev.content : content,
            isSaving: false,
          }));
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error);
          errorToast(error, 'There was an error trying to save content');
          if (error instanceof Error) errorLog(error);

          set(baseAtom, (prev) => ({ ...prev, isSaving: false, content: initialContent }));
        } finally {
          release();
        }
      }
    },
  );

  return {
    updateScriptDurationAtom,
    updateContentAtom,
    saveContentAtom,
    editorValueRef,
    periodicSaveRef,
    saveTimeoutRef,
  };
});

export const useScriptContent = () => {
  const {
    updateScriptDurationAtom,
    updateContentAtom,
    saveContentAtom,
    editorValueRef,
    periodicSaveRef,
    saveTimeoutRef,
  } = useMolecule(scriptContentMolecule);

  const { hostReadSpeed } = useInstanceMetadata();

  const updateContent = useSetAtom(updateContentAtom);
  const saveContent = useSetAtom(saveContentAtom);
  const setScriptDuration = useSetAtom(updateScriptDurationAtom);

  const updateDuration = useCallback(
    (document: CustomElement[]) => {
      const words = getWords(document);
      const wordCount = words ? words.length : 0;
      const readRate = hostReadSpeed; // words per minute
      const wordsPerSecond = readRate / 60;
      const newSpeakDuration = Math.ceil(wordCount / wordsPerSecond);
      const scriptDuration = getTime(newSpeakDuration);
      setScriptDuration(scriptDuration);
    },
    [hostReadSpeed, setScriptDuration],
  );
  const [debouncedDurationUpdate] = useDebouncedCallback(updateDuration, 1000);

  const getEditorValue = useCallback(() => editorValueRef.current, [editorValueRef]);
  const setEditorValue = useCallback(
    (value: EditorValue | null) => {
      editorValueRef.current = value;
      if (value === null) return;

      void debouncedDurationUpdate(value.document);
    },
    [debouncedDurationUpdate, editorValueRef],
  );

  return {
    getEditorValue,
    setEditorValue,
    updateContent,
    saveContent,
    periodicSaveRef,
    saveTimeoutRef,
  };
};
