import { memo, useMemo, useRef } from 'react';
import styled from '@emotion/styled';

import Scrollbar from 'components/scrollbar';
import Text from 'components/text';
import Tooltip from 'components/tooltip';
import useCalculateContainerDimension from 'hooks/useCalculateContainerDimension';
import { Flex, HStack } from 'layouts/box/Box';
import { TabsContent } from 'lib/tabs';
import { KeyMap } from 'types/keyboardShortcut';

import { getLabel, getSymbol } from '../utils';

export const RTabsContent = styled(TabsContent)`
  height: 100%;
  outline: none;
  flex-grow: 1;
  overflow: hidden;
  &[data-state='active'] {
    display: flex;
    overflow: auto;
  }
`;

export const ShortcutWrapper = styled('div')<{ $overflowed?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  height: 100%;
  width: ${({ $overflowed }) => ($overflowed ? 'max-content' : '100%')};
  align-content: ${({ $overflowed }) => ($overflowed ? 'flex-start' : 'center')};
  gap: 8px;
  column-gap: 48px;
  padding: 12px;
  user-select: none;
`;

export const Shortcut = styled(HStack)`
  width: 320px;
  height: 28px;
  gap: 8px;
`;

export const ItemTitle = styled(Text)`
  flex: 1;
  height: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
  display: flex;
  align-items: center;
`;

export const Key = styled(Flex)`
  height: 28px;
  min-width: 28px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.palette.dina.buttonBorderOutlined};
  padding-inline: 6px;
  justify-content: center;
  align-items: center;
`;

const KeySequence = memo(({ sequence }: { sequence: string }) => (
  <HStack gap="2px">
    {sequence.split('+').map((pressedKey) => (
      <Tooltip key={pressedKey} title={getLabel(pressedKey)}>
        <Key>{getSymbol(pressedKey)}</Key>
      </Tooltip>
    ))}
  </HStack>
));
KeySequence.displayName = 'KeySequence';

const ShortcutItem = memo(({ itemKey, item }: { itemKey: string; item: KeyMap }) => (
  <Shortcut key={itemKey}>
    <ItemTitle variant="listItemLabel" color="highEmphasis" truncate title={item.tooltip}>
      {item.label}
    </ItemTitle>
    <HStack gap="4px">
      {item.sequences.map((sequence, index) => (
        <HStack key={sequence} gap="4px">
          {index > 0 && <span>or</span>}
          <KeySequence sequence={sequence} />
        </HStack>
      ))}
    </HStack>
  </Shortcut>
));
ShortcutItem.displayName = 'ShortcutItem';

interface ContentProps {
  value: string;
  keyMap: Record<string, KeyMap>;
}

function Content({ value, keyMap }: Readonly<ContentProps>) {
  const ref = useRef<HTMLDivElement>(null);
  const size = useCalculateContainerDimension(ref);

  const overflowed = useMemo(
    () => Math.ceil(Object.entries(keyMap).length / 3) * 368 > size.width,
    [keyMap, size.width],
  );

  const keyMapEntries = useMemo(() => Object.entries(keyMap), [keyMap]);

  return (
    <RTabsContent
      value={value}
      ref={ref}
      role="tabpanel"
      id={`panel-${value}`}
      aria-labelledby={value}
    >
      <Scrollbar showHorizontal>
        <ShortcutWrapper $overflowed={overflowed}>
          {keyMapEntries.map(([key, item]) => (
            <ShortcutItem key={key} itemKey={key} item={item} />
          ))}
        </ShortcutWrapper>
      </Scrollbar>
    </RTabsContent>
  );
}

export default memo(Content);
