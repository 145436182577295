import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import uuidv1 from 'uuid/v1';

import {
  useSelectedMenuSystemSettings,
  useMetadataFields,
  useNewMetadataFieldId,
  useChangedMetadataField,
  useSelectedCategory,
} from '../../../../../atoms';
import fieldTypes from '../../../../../utils/fieldTypes';
import SETTING_TYPES from '../../../../../utils/settingTypes';

import List from '../../../../collapseList';

const Metadata = ({ fields }) => {
  const [selectedMenu, setSelectedMenu] = useSelectedMenuSystemSettings();
  const [metadataFields, setMetadataFields] = useMetadataFields(fields);
  const [newMetadataFieldId, setNewMetadataFieldId] = useNewMetadataFieldId();
  const [changedMetadataField, setChangedMetadataField] = useChangedMetadataField();
  const [category, setCategory] = useSelectedCategory();

  useEffect(() => {
    setMetadataFields(fields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields]);

  const onAddNewMetaDataField = () => {
    const id = `${uuidv1()}`;
    const name = 'Untitled metadata field';
    setNewMetadataFieldId(id);
    setMetadataFields([
      {
        __typename: 'MetadataFormFieldType',
        id,
        name,
        type: fieldTypes.TEXT,
      },
      ...metadataFields,
    ]);
    setSelectedMenu(id);
    setCategory(SETTING_TYPES.METADATA);
    setChangedMetadataField([
      ...changedMetadataField,
      {
        key: `${id}:id`,
        value: name,
        originalValue: '',
      },
    ]);
  };

  const onClickPlusIcon = () => {
    onAddNewMetaDataField();
  };

  const onClickMetadataField = (event, field) => {
    event?.stopPropagation();
    setSelectedMenu(field?.id);
    setCategory(SETTING_TYPES.METADATA);
  };

  const createButtonTooltip = !newMetadataFieldId
    ? 'Add new metadata field'
    : 'Please save Untitled metadata field first';

  return (
    <List>
      <List.Header
        onCreateClick={onClickPlusIcon}
        itemCount={metadataFields?.length}
        createButtonDisabled={!!newMetadataFieldId}
        createButtonTooltip={createButtonTooltip}
        selected={category === SETTING_TYPES.METADATA}
        onClick={(event)=> onClickMetadataField(event, metadataFields[0])}
      >
        Metadata fields
      </List.Header>
      <List.Body>
        {metadataFields?.map((field) => (
          <List.Item
            key={field.id}
            selected={selectedMenu === field?.id}
            onClick={(event) => onClickMetadataField(event, field)}
            italic={newMetadataFieldId === field?.id}
          >
            {field.name}
          </List.Item>
        ))}
      </List.Body>
    </List>
  );
};

Metadata.propTypes = {
  fields: PropTypes.array,
};

Metadata.defaultProps = {
  fields: [],
};

export default Metadata;
