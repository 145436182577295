import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { DropTargetMonitor, useDrag, useDrop } from 'react-dnd';
import { useLocation, useNavigate } from 'react-router-dom';
import capitalize from 'lodash/capitalize';

import { ReactComponent as CloseSmall } from 'assets/icons/systemicons/close_small.svg';
import { ReactComponent as MasterRundownOff } from 'assets/icons/systemicons/HeaderNavbar/master_rundown_off.svg';
import { ReactComponent as MasterRundownOn } from 'assets/icons/systemicons/HeaderNavbar/master_rundown_on.svg';
import { ReactComponent as PitchOff } from 'assets/icons/systemicons/HeaderNavbar/pitch_off.svg';
import { ReactComponent as PitchOn } from 'assets/icons/systemicons/HeaderNavbar/pitch_on.svg';
import { ReactComponent as RundownOff } from 'assets/icons/systemicons/HeaderNavbar/rundown_off.svg';
import { ReactComponent as RundownOn } from 'assets/icons/systemicons/HeaderNavbar/rundown_on.svg';
import { ReactComponent as SpacesOff } from 'assets/icons/systemicons/HeaderNavbar/spaces_off.svg';
import { ReactComponent as SpacesOn } from 'assets/icons/systemicons/HeaderNavbar/spaces_on.svg';
import { ReactComponent as StoryArchivedOff } from 'assets/icons/systemicons/HeaderNavbar/stories_archived_off.svg';
import { ReactComponent as StoryArchivedOn } from 'assets/icons/systemicons/HeaderNavbar/stories_archived_on.svg';
import { ReactComponent as StoryOff } from 'assets/icons/systemicons/HeaderNavbar/stories_off.svg';
import { ReactComponent as StoryOn } from 'assets/icons/systemicons/HeaderNavbar/stories_on.svg';
import LinearProgress from 'components/linearProgress';
import NotificationIndicator from 'components/statusIndicators/Notification';
import Tooltip from 'components/tooltip';
import { DropItem } from 'hooks/useCommonDrag';
import useCustomDateTimeUtils from 'hooks/useCustomDateTimeUtils';
import useDateTimeUtils from 'hooks/useDateTimeUtils';
import useTabs from 'hooks/useTabs';
import { ContentTab, RundownTab, useSetOnDropTabItem } from 'store/tabs';
import dndTypes from 'utils/dndTypes';

import ContextMenu from '../contextMenu';
import TabToolTip from '../tabTooltip';

import {
  NotificationWrapper,
  StyledIconButton,
  StyledLabel,
  StyledTabContentWrapper,
  StyledTabIconWrapper,
  StyledTabSubtitle,
  StyledTabTitle,
} from './styled';

export const icons = {
  create: [StoryOff, StoryOn],
  story: [StoryOff, StoryOn],
  pitch: [PitchOff, PitchOn],
  rundown: [RundownOff, RundownOn],
  rundowntemplate: [MasterRundownOff, MasterRundownOn],
  space: [SpacesOff, SpacesOn],
};

export const archivedIcons = {
  create: [StoryOff, StoryOn],
  story: [StoryArchivedOff, StoryArchivedOn],
  pitch: [PitchOff, PitchOn],
  rundown: [RundownOff, RundownOn],
  rundowntemplate: [MasterRundownOff, MasterRundownOn],
  space: [SpacesOff, SpacesOn],
};

const initialContextMenuPos = {
  mouseX: null,
  mouseY: null,
};

const isValidDrop = (monitor: DropTargetMonitor, tab: ContentTab) => {
  const itemType = monitor.getItemType()?.valueOf();
  const storyId = monitor.getItem<DropItem>()?.storyId;

  switch (itemType) {
    case dndTypes.FEED_ITEM:
      return tab.type === 'story' || tab.type === 'pitch';
    case dndTypes.STORY_INSTANCE:
      return tab.type === 'story' && tab.id !== storyId;
    case dndTypes.STORY_NOTE:
      return (tab.type === 'story' || tab.type === 'pitch') && tab.id !== storyId;
    case dndTypes.GRID_INSTANCE:
      return tab.type === 'story';
    default:
      return false;
  }
};

interface Props {
  index: number;
  width: number;
  tab: ContentTab;
  isSelected: boolean;
  notificationCount?: number;
}

const ContentTabView = ({ index, width, tab, isSelected, notificationCount }: Props) => {
  const { isToday } = useDateTimeUtils();
  const { formatNearbyDate } = useCustomDateTimeUtils();
  const navigate = useNavigate();
  const { search } = useLocation();
  const { updateTab, closeTab } = useTabs();
  const onDropTabItem = useSetOnDropTabItem();
  const tabRef = useRef(null);
  const [dropEffect, setDropEffect] = useState(false);

  const [, dragRef] = useDrag({
    type: dndTypes.CONTENT_TAB,
    item: () => ({ index }),
  });

  const handleDropEffect = () => {
    setDropEffect(true);

    setTimeout(() => {
      setDropEffect(false);
    }, 1500);
  };

  useEffect(() => {
    // story & pitch tabs search is updated by useStoryPanes
    if (tab.type === 'story' || tab.type === 'pitch') return;

    if (isSelected && search.length > 1) {
      updateTab({ id: tab.id, search });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab.id, isSelected, search, updateTab]);

  const [{ hovered, handlerId }, dropRef] = useDrop({
    accept: [
      dndTypes.CONTENT_TAB,
      dndTypes.FEED_ITEM,
      dndTypes.STORY_INSTANCE,
      dndTypes.STORY_NOTE,
      dndTypes.GRID_INSTANCE,
    ],
    drop: (item: { index: number }, monitor) => {
      if (isValidDrop(monitor, tab)) {
        handleDropEffect();
        return { storyId: tab.id, storyType: tab.type };
      }

      if (monitor.getItemType()?.valueOf() === dndTypes.CONTENT_TAB) {
        onDropTabItem({ itemIndex: item.index, dropIndex: index });
      }
    },
    collect: (monitor) => {
      return {
        hovered: isValidDrop(monitor, tab) && monitor.isOver(),
        handlerId: monitor.getHandlerId(),
      };
    },
  });

  const getTabTitle = (t: ContentTab, count?: number) => {
    const fallbackTitle = `[Untitled ${capitalize(tab.type)}]`;
    const title = t.title?.length ? t.title : fallbackTitle;
    if (!count) return title;
    return `${title} (${count} new messages)`;
  };

  const [contextMenuPos, setContextMenuPos] = useState<{
    mouseX: number | null;
    mouseY: number | null;
  }>(initialContextMenuPos);

  const tabTitle = getTabTitle(tab, notificationCount);

  const shortDate = tab.type === 'rundown' ? formatNearbyDate(tab.selectedDate) : '';

  const Icon =
    (tab.type === 'story' || tab.type === 'pitch') && tab?.archived
      ? archivedIcons[tab.type][isSelected ? 1 : 0]
      : icons[tab.type][isSelected ? 1 : 0];

  const handleCloseIconClick = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();
      closeTab(tab);
    },
    [closeTab, tab],
  );

  const handleContextMenuClick = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    setContextMenuPos({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
  }, []);

  const handleContextMenuClose = useCallback(() => {
    setContextMenuPos(initialContextMenuPos);
  }, []);

  dragRef(dropRef(tabRef));

  return (
    <>
      <Tooltip title={<TabToolTip title={tabTitle} />} hide={contextMenuPos.mouseX !== null}>
        <StyledTabContentWrapper
          tabIndex={0}
          ref={tabRef}
          width={width}
          $tab={tab}
          $isSelected={isSelected}
          onClick={() => {
            const url =
              tab.type === 'story' || tab.type === 'pitch'
                ? `/${tab.type}/${tab.id}`
                : `/${tab.type}/${tab.id}/${tab.search ?? ''}`;
            navigate(url);
          }}
          data-handler-id={handlerId}
          $hovered={hovered}
          onContextMenu={handleContextMenuClick}
          $isTodayForRundownTab={isToday((tab as RundownTab)?.selectedDate ?? '')}
        >
          {dropEffect && <LinearProgress right={10} position="bottom" height={1} />}
          {Boolean(notificationCount && notificationCount > 0) && (
            <NotificationWrapper>
              <NotificationIndicator notificationCount={notificationCount} />
            </NotificationWrapper>
          )}
          <StyledTabIconWrapper>
            <Icon className="skipOverride" />
          </StyledTabIconWrapper>
          <StyledLabel>
            <StyledTabTitle>{tabTitle}</StyledTabTitle>
            <StyledTabSubtitle>{shortDate}</StyledTabSubtitle>
          </StyledLabel>
          <StyledIconButton
            usage="text"
            size={24}
            iconSize={10}
            onClick={handleCloseIconClick}
            tabIndex={-1}
          >
            <CloseSmall className="skipOverride" />
          </StyledIconButton>
        </StyledTabContentWrapper>
      </Tooltip>
      {contextMenuPos.mouseX !== null && (
        <ContextMenu contextMenuPos={contextMenuPos} tab={tab} onClose={handleContextMenuClose} />
      )}
    </>
  );
};

export default memo(ContentTabView);
