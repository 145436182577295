import { useExternalUrls } from 'store/externalUrls';
import { useRightHidden, useRightSelection } from 'store/sidebar';
import { CustomElement } from 'types';
import { postMosMessage } from 'utils/postMessage';

import { getPanelId } from './useOpenSearchResult';

/**
 * Custom hook for opening a MOS asset item in the PILOT EDGE panel within the right sidebar.
 */
const useOpenAssetInPilotEdge = () => {
  const [, setRightHidden] = useRightHidden();
  const [, setRightSelection] = useRightSelection();
  const [externalUrls] = useExternalUrls();

  /**
   * Opens the specified MOS object in the PILOT EDGE panel.
   *
   * @param mosObj  The MOS object to open.
   * @param element The custom element to open.
   */
  const openInPilotEdge = (mosObj?: string, element?: Partial<CustomElement>) => {
    if (!mosObj) return;

    const provider = element?.data?.provider;

    const externalUrl = provider
      ? externalUrls.find((_) => provider === _.mTitle || provider === _.mosId)
      : null;

    const externalId =
      externalUrl?.type === 'pilotedge' ? 'pilotedge' : externalUrl?.id ?? 'pilotedge';

    postMosMessage(mosObj, externalId, element);
    setRightHidden(false);

    setRightSelection(externalUrl ? getPanelId(externalUrl) : 'graphicsbox');
  };

  return openInPilotEdge;
};

export default useOpenAssetInPilotEdge;
