import React, { memo, useCallback, useEffect, useState } from 'react';

import { getDefaultLayoutSettings } from 'components/editMdfDialog/utils';
import LWCheckbox from 'features/orderForm/components/LWCheckbox';

import type CellProps from '../cellProps';

import { getCheckboxValue } from './CheckboxField';

function CheckboxCell({
  fieldSettings,
  fieldModel,
  setValue,
  value,
  disableEdit,
  onFocus,
  onBlur,
}: Readonly<CellProps>) {
  const [localValue, setLocalValue] = useState(value);
  const { hint } = fieldSettings ?? getDefaultLayoutSettings(fieldModel);

  useEffect(() => {
    if (localValue !== value) {
      setLocalValue(value);
    }
  }, [value]);

  const toggle = useCallback(() => {
    setLocalValue((prev) => {
      setValue(!prev);
      return !prev;
    });
  }, [setValue]);

  const onKeyDown = useCallback(
    (ev: React.KeyboardEvent<HTMLInputElement>) => {
      if (ev.code === 'Space' || ev.code === 'Enter') {
        toggle();
        ev.preventDefault();
      }
    },
    [toggle],
  );

  return (
    <LWCheckbox
      onClick={(ev) => ev.stopPropagation()}
      onKeyDown={onKeyDown}
      title={hint}
      setValue={toggle}
      selected={getCheckboxValue(localValue)}
      disabled={disableEdit}
      onFocus={onFocus}
      onBlur={onBlur}
      style={{ marginLeft: '8px', marginTop: '4px' }}
    />
  );
}

export default memo(CheckboxCell);
