import { atom, useAtom } from 'jotai';

import useToast from 'components/toast/useToast';
import { ConfirmationDialogProps, ConfirmationProps } from 'types/ui';
import { getFocusRestorer } from 'utils/dom/focus';

const dialogProps = atom<ConfirmationDialogProps | null>(null);

const useAsyncConfirmationDialogProps = () => useAtom(dialogProps);
export const useAsyncConfirmationDialogPropsState = () => useAtom(dialogProps)[0];
/**
 * A function that asynchronously returns a text entered by the user.
 * @param props        Information about how the dialog appearance
 * @param executeAsync Optional async function to be executed when dialog is confirmed.
 *                     If this function throws an error this error will be displayed using
 *                     `errorToast` and cause the confirmation to return false.
 * @returns            A `Promise` resolving to a `true` if confirmed and execution succeeded and
 *                     `false` otherwise.
 */
export type AsyncConfirmationFunc = (
  props: ConfirmationProps,
  executeAsync?: () => Promise<void>,
) => Promise<boolean>;

/**
 * This function returns an {@link AsyncConfirmationFunc} function that asynchronously requests
 * confirmation from the user.
 *
 * NOTE that in order to use this function `AsyncConfirmationDialog` must be rendered in the
 * main layout.
 * @returns A {@link AsyncConfirmationFunc} function
 */
export function useAsyncConfirmation(): AsyncConfirmationFunc {
  const [state, setState] = useAsyncConfirmationDialogProps();
  const { errorToast } = useToast();
  return async (props: ConfirmationProps, executeAsync?: () => Promise<void>) => {
    if (state) return false;
    let myState: ConfirmationDialogProps | undefined = undefined;
    let resolve: ((result: boolean) => void) | undefined;
    const restoreFocus = getFocusRestorer(1);
    const onClick = executeAsync
      ? async () => {
          try {
            if (myState) setState({ ...myState, loading: true });
            await executeAsync();
            resolve?.(true);
          } catch (e) {
            errorToast(e, e instanceof Error ? e.message : undefined);
            resolve?.(false);
          } finally {
            if (myState) setState({ ...myState, loading: false });
          }
          setState(null);
          restoreFocus();
        }
      : () => {
          resolve?.(true);
          setState(null);
        };
    function onClose() {
      resolve?.(false);
      setState(null);
      restoreFocus();
    }
    myState = { ...props, onClick, onClose, loading: false };
    setState(myState);
    const result = new Promise<boolean>((r) => (resolve = r));
    return result;
  };
}
