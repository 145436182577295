import gql from 'graphql-tag';

export default gql`
  query GetAllPlatforms($input: GetAllPlatformsInput) {
    getAllPlatforms(input: $input) {
      id
      title
      platformInfo {
        platform
        platformIcon
        platformStructure {
          id
          name
          variant
          sections {
            blocks
            id
            name
          }
          config {
            block
            mMetaData {
              key
              value
            }
          }
          blocks
          allowVideoInPhotogallery
        }
        accounts {
          accountId
          accountTitle
          accountUrl
          accountLogo
          isStaticAccount
          publishingAt
          rundownTemplateId
        }
      }
    }
  }
`;
