import gql from 'graphql-tag';

export default gql`
  query GetRundown($input: GetRundownInput) {
    getRundown(input: $input) {
      __typename
      ... on Rundown {
        groupInfo
        mId
        mRefId
        mOrder
        mPreorder
        mRefId
        mTitle
        mType
        mState
        mSecRefId
        mPreparingState
        startTime
        duration
        mPlannedDuration
        mPrePlannedDuration
        mPublishingAt
        mCoverPhotoKey
        mThumbnailKey
        mSyncActive
        mAssignedMembers {
          mId
          mType
        }
        mRundownTemplateId
        metadata
        useV2
        mdfId
        mStudios
        platformKind
        mProperties {
          __typename
          ... on RundownStateType {
            ready {
              state
              startTime
              stopTime
              currentInstance {
                mId
                startTime
              }
            }
            preparing {
              state
              startTime
              stopTime
              currentInstance {
                mId
                startTime
              }
            }
          }
        }
        version
        recurrence {
          duration
          startTime
          timeZone
          dailyExclusive
        }
        mMetaData {
          key
          value
        }
        mSyncProviders {
          provider
          mMetaData {
            key
            value
          }
        }
        permissions {
          read
          write
        }
      }

      ... on RestrictedErrorType {
        mId
        mRefId
        mType
        message
      }
    }
  }
`;
