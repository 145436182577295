import { useEffect, useState } from 'react';

import { ReactComponent as StoryIcon } from 'assets/icons/systemicons/story_off.svg';
import { Button } from 'components/buttons';
import { useCreateMemberDialog } from 'components/createNewV3/CreateNewDialog';
import { DraftHeader } from 'features/preview/components/Headers';
import type { PreviewProps } from 'features/preview/utils/childMap';
import useCheckUserRight from 'hooks/useCheckUserRight';
import useCustomDateTimeUtils from 'hooks/useCustomDateTimeUtils';
import { Flex } from 'layouts/box/Box';
import { MemberType } from 'types/graphqlTypes';

import useUpdateDraft from './api/useUpdateDraft';

import {
  ActionButtons,
  AssignMetadata,
  DateValue,
  DescriptionInput,
  EmptyStateText,
  Footer,
  LeftSideContent,
  RightSideContent,
  SidebarTitle,
  Wrapper,
} from './styled';

export default function Draft({
  member,
  onClose,
}: Readonly<PreviewProps & { member: MemberType }>) {
  const { isoToLocaleShort } = useCustomDateTimeUtils();
  const { mTitle = '', mDescription = '', mCreatedAt, mUpdatedAt } = member ?? {};
  const [title, setTitle] = useState(mTitle);
  const [description, setDescription] = useState(mDescription);

  const [, showCreateMemberDialog] = useCreateMemberDialog();
  const [checkUserRight] = useCheckUserRight();
  const canCreateStory = checkUserRight('story', 'create');
  const [updateDraft] = useUpdateDraft();

  useEffect(() => {
    const incomingTitle = member.mTitle ?? '';
    const incomingDescription = member.mDescription ?? '';
    if (incomingTitle !== title) setTitle(incomingTitle);
    if (incomingDescription !== description) setDescription(incomingDescription);
  }, [member]);

  if (!member) {
    return <div>No preview found</div>;
  }

  const stopEventBubble = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const showMemberDialog = (event: React.MouseEvent<HTMLElement>) => {
    stopEventBubble(event);
    showCreateMemberDialog({
      anchorEl: event.currentTarget,
      mTitle: title,
      mContent: description,
    });
  };

  const handleDescChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(event.target.value);
  };

  const handleSave = async () => {
    await updateDraft({
      mId: member?.mId,
      mRefId: member?.mRefId,
      mTitle: title,
      mDescription: description,
    });
    onClose();
  };

  const hasChanged = title !== mTitle || description !== mDescription;

  return (
    <Wrapper>
      <DraftHeader draft={member} title={title} setTitle={setTitle} onClose={onClose} />
      <Flex height="100%" width="100%" gap="16px">
        <LeftSideContent>
          <DateValue>{`Created ${isoToLocaleShort(mCreatedAt ?? mUpdatedAt)}`}</DateValue>
          <DescriptionInput
            name="draft-description"
            value={description}
            onChange={handleDescChange}
            placeholder="Add a description (optional)"
          />
          <Footer>
            <Button width={140} variant="outlined" usage="outlined" onClick={onClose}>
              Cancel
            </Button>
            <Button
              width={140}
              usage="significant"
              disabled={!hasChanged}
              onClick={() => void handleSave()}
            >
              Save
            </Button>
          </Footer>
        </LeftSideContent>

        <RightSideContent>
          <AssignMetadata>
            <span>
              <SidebarTitle>Assignees</SidebarTitle>
              <EmptyStateText>Add assignees...</EmptyStateText>
            </span>
          </AssignMetadata>
          <ActionButtons>
            {canCreateStory && (
              <Button
                variant="discreet"
                usage="text"
                height={36}
                startIcon={<StoryIcon />}
                align="left"
                onClick={(event: React.MouseEvent<HTMLElement>) => showMemberDialog(event)}
              >
                Convert to Story
              </Button>
            )}
          </ActionButtons>
        </RightSideContent>
      </Flex>
    </Wrapper>
  );
}
