import { useCallback } from 'react';
import { useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import { Skeleton } from '@material-ui/lab';

import { publishingPoints } from 'assets/icons/publishingPoints';
import fallbackImage from 'assets/images/default/defaultThumbnail.png';
import Avatar from 'components/avatar/Avatar';
import Text from 'components/text';
import useCustomDateTimeUtils from 'hooks/useCustomDateTimeUtils';
import { HStack } from 'layouts/box/Box';
import GET_INSTANCE_FROM_CACHE from 'operations/queries/getInstanceFromLocalResolver';
import { useSetPreview } from 'store/preview';
import { Instance } from 'types';

const InstanceWrapper = styled(HStack)`
  cursor: pointer;
  width: 100%;
  height: 28px;
  padding-inline: 12px 12px;
  gap: 6px;
  :hover {
    background-color: ${({ theme }) => theme.palette.dina.hoverOverlay};
  }
`;

const InstanceNumber = styled('span')`
  ${({ theme }) => theme.typography.dina.listItemLabelBold};
  border: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
  border-radius: 4px;
  padding: 1px 0px;
  min-width: 20px;
  text-align: center;
`;

export function InstanceSkeleton() {
  return (
    <HStack width="100%" height="28px" padding="0 12px" gap="6px">
      <Skeleton animation="wave" variant="rect" height="24px" width="24px" />
      <Skeleton animation="wave" variant="text" style={{ flex: 1 }} />
      <Skeleton animation="wave" variant="text" width="64px" />
    </HStack>
  );
}

function InstanceItem({
  mId,
}: Readonly<{
  mId: string;
}>) {
  const { distanceInAbbreviatedWords } = useCustomDateTimeUtils();
  const setPreview = useSetPreview();

  const skip = mId === '-';

  const { data } = useQuery<{ instance: Instance }>(GET_INSTANCE_FROM_CACHE, {
    variables: {
      input: {
        mId,
        mRefId: mId,
      },
    },
    skip,
  });
  const { mTitle, mUpdatedAt, mProperties, mMetaData } = data?.instance || {};

  const orderValue = mMetaData?.find((item) => item.key === '11-order')?.value;

  const handlePreview = useCallback(() => {
    if (data?.instance) {
      setPreview(data.instance);
    }
  }, [data, setPreview]);

  return (
    <InstanceWrapper onClick={handlePreview}>
      <HStack gap="1px">
        <Avatar
          size={20}
          src={publishingPoints[mProperties?.platform ?? ''] ?? fallbackImage}
          borderRadius={4}
          borderColor="transparent"
        />
        {orderValue && <InstanceNumber>{orderValue}</InstanceNumber>}
      </HStack>
      <HStack flex="1">
        <Text variant="listItemLabel" color="highEmphasis" truncate style={{ width: '100%' }}>
          {mTitle}
        </Text>
      </HStack>
      {mUpdatedAt && <Text variant="captionMedium">{distanceInAbbreviatedWords(mUpdatedAt)}</Text>}
    </InstanceWrapper>
  );
}

export default InstanceItem;
