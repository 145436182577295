import { gql } from '@apollo/client';

export default gql`
  mutation UpdateOrganization($input: UpdateOrganizationInput) {
    updateOrganization(input: $input) {
      id
      type
      title
      description
      mdfId
      metadata
      createdAt
      createdById
      updatedAt
      updatedById
      timeZone
      isArchived
      isRestricted
    }
  }
`;
