import { memo, useCallback, useEffect } from 'react';
import styled from '@emotion/styled';
import { ReactEditor, RenderElementProps, useReadOnly, useSelected, useSlate } from 'slate-react';

import { useGetMdfs } from 'api/mdf/useGetMdfs';
import { useGetBlock } from 'api/mdfBlocks/useGetMdfBlock';
import { elementTypes } from 'components/editor/constants';
import useToggleSidePanel from 'components/editor/hooks/useToggleSidePanel';
import { PlanningItem } from 'features/sidepanel/ComponentUtils';
import { useSplitViewMolecule } from 'features/splitView/store';
import { useEditorCommandsKeyed } from 'store';
import transientOptions from 'theme/helpers';
import preventDefaultAndPropagation from 'utils/preventDefaultAndStopPropagation';

import DragAndDrop from '../dragAndDrop';

export const FocusWrapper = styled('div', transientOptions)`
  padding: 8px;
  max-width: 616px;
`;

function MdfBlock({
  attributes = { 'data-slate-node': 'element', ref: null },
  children = null,
  element = {
    type: elementTypes.PARAGRAPH,
    data: {},
    children: [],
  },
}: Readonly<RenderElementProps>) {
  const [editorCommands] = useEditorCommandsKeyed();
  const editor = useSlate();
  const isReadOnly = useReadOnly();
  const isSelected = useSelected();
  const selected = !isReadOnly && isSelected;
  const { toggleSidePanel } = useToggleSidePanel();
  const { useSelectedBlockId, useBlockExpandState } = useSplitViewMolecule();
  const [selectedBlockId, setSelectedBlockId] = useSelectedBlockId();
  const [expandState, setExpandState] = useBlockExpandState();
  const { mdfsSeparated } = useGetMdfs();

  useEffect(() => {
    if (isSelected) {
      setSelectedBlockId(element.data?.mId);
    }
  }, [isSelected, isReadOnly]);

  const data = element.data as { mId: string; mResourceId: string; mFormId: string; mdfId: string };
  const { mId, mResourceId, mFormId } = data ?? {};
  const { block } = useGetBlock(mResourceId, mId);
  const isBlockSelected = selectedBlockId === mId;

  const command = editorCommands[mFormId];
  const updatedBlock = block
    ? {
        ...block,
        commandLabel: command?.mTitle ?? '',
        color: command?.mColor,
      }
    : null;

  const selectBlock = useCallback(
    (event: React.MouseEvent) => {
      if (mId) setExpandState({ ...expandState, [mId]: !expandState[mId] });

      if (isReadOnly) preventDefaultAndPropagation(event);
      else ReactEditor.focus(editor);
      setSelectedBlockId(element.data?.mId);
    },
    [isReadOnly, editor, mId, expandState],
  );

  const openSidepanel = useCallback(() => {
    if (!updatedBlock) return;
    setSelectedBlockId(updatedBlock.mRefId);
    toggleSidePanel(updatedBlock.mRefId, 'planning');
  }, [updatedBlock]);

  return (
    <div {...attributes}>
      <DragAndDrop element={element} enableReadOnly={false}>
        {children}
        <FocusWrapper contentEditable={false}>
          <PlanningItem
            mdf={mdfsSeparated.custom.find((m) => m.id === updatedBlock?.mdfId)}
            open={expandState[mId ?? ''] ?? false}
            block={updatedBlock}
            resourceId={mResourceId}
            selected={isReadOnly ? isBlockSelected : selected}
            onSelect={selectBlock}
            openSidepanel={openSidepanel}
          />
        </FocusWrapper>
      </DragAndDrop>
    </div>
  );
}

export default memo(MdfBlock);
