import * as React from 'react';
import styled from '@emotion/styled';
import { DialogProps } from '@radix-ui/react-dialog';
import { Command as CommandPrimitive, useCommandState } from 'carloslfu-cmdk-internal';

import { ReactComponent as SearchIconSvg } from 'assets/icons/systemicons/search.svg';
import { CommandPath } from 'components/command/CommandPath';
import { LoadingButtonIndicator } from 'components/loadingIndicator';
import { Dialog, DialogContent } from 'lib/dialog';
import transientOptions from 'theme/helpers';

import { StyledCommand } from './styled';

const Command = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive> & {
    innerHeight?: number;
    mounted?: boolean;
  }
>(({ ...props }, ref) => <StyledCommand ref={ref} {...props} />);
Command.displayName = CommandPrimitive.displayName;

interface CommandDialogProps extends DialogProps {
  onKeyDown?: React.KeyboardEventHandler<HTMLDivElement>;
  onDialogFocus?: () => void;
  shouldFilter?: boolean;
  dialogWidth: number;
  innerHeight: number;
}

const StyledDialogContent = styled(DialogContent, transientOptions)<{ $dialogWidth: number }>`
  width: ${({ $dialogWidth }) => $dialogWidth}rem;
`;

const CommandDialog = ({
  children,
  onKeyDown,
  shouldFilter,
  innerHeight,
  dialogWidth,
  onDialogFocus,
  ...props
}: CommandDialogProps) => {
  return (
    <Dialog {...props}>
      <StyledDialogContent
        large
        className="command"
        $dialogWidth={dialogWidth}
        onDialogFocus={onDialogFocus}
      >
        <Command onKeyDown={onKeyDown} shouldFilter={shouldFilter} innerHeight={innerHeight}>
          {children}
        </Command>
      </StyledDialogContent>
    </Dialog>
  );
};

type CommandMountedProps = Omit<CommandDialogProps, 'dialogWidth' | 'innerHeight'>;

const CommandMounted = ({ children, onKeyDown, shouldFilter }: CommandMountedProps) => {
  return (
    <Command onKeyDown={onKeyDown} shouldFilter={shouldFilter} innerHeight={0} mounted={true}>
      {children}
    </Command>
  );
};

const CommandInput = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Input>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Input> & {
    loading?: boolean;
    hideSearchIcon?: boolean;
  }
>(({ loading, hideSearchIcon = false, ...props }, ref) => (
  // eslint-disable-next-line react/no-unknown-property
  <div cmdk-input-wrapper="">
    {loading ? (
      <LoadingButtonIndicator inline size={16} style={{ marginRight: '5px' }} />
    ) : (
      !hideSearchIcon && <SearchIconSvg />
    )}
    <CommandPath />
    <CommandPrimitive.Input ref={ref} {...props} />
  </div>
));

CommandInput.displayName = CommandPrimitive.Input.displayName;

const CommandList = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.List>
>(({ ...props }, ref) => <CommandPrimitive.List ref={ref} {...props} />);

CommandList.displayName = CommandPrimitive.List.displayName;

const CommandEmpty = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Empty>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Empty>
>((props, ref) => <CommandPrimitive.Empty ref={ref} {...props} />);

CommandEmpty.displayName = CommandPrimitive.Empty.displayName;

const CommandGroup = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Group>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Group>
>(({ ...props }, ref) => <CommandPrimitive.Group ref={ref} {...props} />);

CommandGroup.displayName = CommandPrimitive.Group.displayName;

const CommandSeparator = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Separator>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Separator>
>(({ ...props }, ref) => <CommandPrimitive.Separator ref={ref} {...props} />);
CommandSeparator.displayName = CommandPrimitive.Separator.displayName;

const CommandItem = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Item>
>(({ ...props }, ref) => <CommandPrimitive.Item ref={ref} {...props} />);

CommandItem.displayName = CommandPrimitive.Item.displayName;

const CommandShortcut = ({ ...props }: React.HTMLAttributes<HTMLSpanElement>) => {
  return <span {...props} />;
};
CommandShortcut.displayName = 'CommandShortcut';

export {
  CommandPrimitive,
  Command,
  CommandDialog,
  CommandMounted,
  CommandInput,
  CommandList,
  CommandEmpty,
  CommandGroup,
  CommandItem,
  CommandShortcut,
  CommandSeparator,
  useCommandState,
};
