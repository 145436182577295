import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const RootWrapper = styled('div')`
  ${({ theme }) => css`
    border-top: 1px solid ${theme.palette.dina.dividerLight};
    background: ${theme.palette.dina.overlaysHeaderFooter};
  `}
  display: flex;
  width: 100%;
  align-items: center;
  overflow: hidden;
  justify-content: flex-end;
  gap: 4px;
  padding: 8px;
`;
