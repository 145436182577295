import { useCallback, useMemo, useState } from 'react';
import { noop } from 'lodash';

import useGetOrganizations from 'api/organization/useGetOrganizations';
import useSelectActiveOrganization from 'api/organization/useSelectActiveOrganization';
import Avatar from 'components/avatar/Avatar';
import { ConfirmDialog } from 'components/dialogs/CommonDialogs';
import ListItem from 'components/listItem';
import LoadingIndicator from 'components/loadingIndicator';
import Text from 'components/text';
import { useAuthContext } from 'contexts/AuthContext';
import { VStack } from 'layouts/box/Box';
import { useSelectedOrgId } from 'store/organization';
import { OrgAccessTypeEnum, type Organization } from 'types/graphqlTypes';

interface OrgComponentProps extends Organization {
  selectActiveOrganization: (orgId: string) => void;
}

function OrganizationComponent({
  id,
  title,
  selectActiveOrganization,
}: Readonly<OrgComponentProps>) {
  const [currentOrganization] = useSelectedOrgId();

  const selectOrganization = useCallback(() => {
    if (currentOrganization !== id) selectActiveOrganization(id);
  }, [currentOrganization, id, selectActiveOrganization]);

  const ICON = useMemo(() => <Avatar title={title} />, [title]);

  return (
    <ListItem
      key={id}
      text={title ?? ''}
      onClick={selectOrganization}
      icon={ICON}
      info={currentOrganization === id ? 'Active' : undefined}
    />
  );
}

const overlayStyle = { zIndex: 1351 };

function Organizations() {
  const { organizations, error, loading } = useGetOrganizations(OrgAccessTypeEnum.Switchable);
  const [, setSelectedOrganization] = useSelectedOrgId();

  const { logout } = useAuthContext();
  const { selectActiveOrganization } = useSelectActiveOrganization();
  const [newOrg, setNewOrg] = useState<string | undefined>();

  const selectOrganization = useCallback(async () => {
    if (!newOrg) return;
    const result = await selectActiveOrganization(newOrg);
    if (result) {
      setSelectedOrganization(newOrg);
      await logout?.().catch(noop);
    }
  }, [selectActiveOrganization, newOrg, logout, setSelectedOrganization]);

  const handleSelection = useCallback((orgId: string) => {
    if (!orgId) return;
    setNewOrg(orgId);
  }, []);

  if (error) return null;

  return (
    <VStack padding="8px 0px" position="relative">
      <Text variant="overline" style={{ padding: '0px 8px' }}>
        Organizations
      </Text>
      {loading && organizations.length === 0 && <LoadingIndicator />}
      {organizations.map((organization) => (
        <OrganizationComponent
          key={organization.id}
          {...organization}
          selectActiveOrganization={handleSelection}
        />
      ))}
      <ConfirmDialog
        open={Boolean(newOrg)}
        onClose={() => setNewOrg(undefined)}
        onClick={selectOrganization}
        title="Change active organization?"
        message="Are you sure you want to change active organizations?
        You will be force to login again."
        overlayStyle={overlayStyle}
      />
    </VStack>
  );
}

export default Organizations;
