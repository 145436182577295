import { keyBy } from 'lodash';

import { getMdfFromKeyedMdfs } from 'features/grids/common/components/utils/mdfUtils';
import { getErrorMap } from 'hooks/useMdfErrorMap';
import { PlatformAccount } from 'types';
import { FieldTypeEnum, type Mdf, type MdfField, type Metadata } from 'types/graphqlTypes';
import { getDefaultValues } from 'utils/mdf/utils';

interface GetMdfParams {
  mdfId?: string;
  mType?: string;
  mProperties?: {
    platformKind?: string;
    account?: PlatformAccount;
    platform?: string;
  };
  mdfs: Mdf[];
}

// eslint-disable-next-line @typescript-eslint/comma-dangle
const keyByForEach = <T,>(arr: T[], f: (x: T) => string): Record<string, T> => {
  return arr.reduce((acc, x) => {
    acc[f(x)] = x;
    return acc;
  }, {} as Record<string, T>);
};

function getMdf({ mdfId, mType, mProperties, mdfs }: GetMdfParams): Mdf | undefined {
  if (mdfId) return mdfs.find((m) => m.id === mdfId);
  const keyedMdfs = keyBy(mdfs, (m) => m.id);

  return getMdfFromKeyedMdfs({
    mType,
    mProperties,
    keyedMdfs,
  });
}

const getFieldMap = (mdf?: Mdf): Record<string, MdfField> => {
  if (!mdf) return {};
  return keyByForEach<MdfField>(mdf.fields, (f) => f.fieldId);
};

const getSubTypeByLabelMap = (subTypes: Mdf[]): Record<string, Mdf> => {
  return keyByForEach<Mdf>(subTypes, (s) => s.label);
};

const getValidFieldMap = (
  fieldMap: Record<string, MdfField>,
  metadata: Metadata,
  subTypeByLabelMap: Record<string, Mdf>,
): Record<string, MdfField> => {
  if (!metadata) return {};

  let allFields: Record<string, MdfField> = { ...fieldMap };
  for (const [key, value] of Object.entries(metadata)) {
    if (fieldMap[key]?.type === FieldTypeEnum.subtype && subTypeByLabelMap[value as string]) {
      allFields = {
        ...allFields,
        ...keyByForEach<MdfField>(subTypeByLabelMap[value as string].fields, (f) => f.fieldId),
      };
    }
  }

  return allFields;
};

const getMemberErrorMap = (metadata: Metadata, subTypes: Mdf[], mdf?: Mdf) => {
  const fieldMap = getFieldMap(mdf);
  const subTypeByLabelMap = getSubTypeByLabelMap(subTypes);
  const validFieldMap = getValidFieldMap(fieldMap, metadata, subTypeByLabelMap);
  const errorMap = mdf ? getErrorMap(validFieldMap, metadata) : {};

  return { errorMap, validFieldMap };
};

function getMergedMetadata(metadata: Metadata, subTypes: Mdf[], mdf?: Mdf): Metadata {
  if (!mdf) return {};
  const defaultValues = getDefaultValues(metadata, mdf, subTypes);
  return {
    ...metadata,
    ...defaultValues,
  };
}

interface ExtendedRowItemType extends Pick<GetMdfParams, 'mdfId' | 'mType' | 'mProperties'> {
  metadata: Metadata;
}

/** Prepares the data for the grid by adding metadata, errorMap, and validFieldMap to each row */
export function extendGridItems<T extends ExtendedRowItemType>(
  data: T[],
  mdfs: Mdf[],
  subTypes: Mdf[] = [],
): T[] {
  return data.map((row) => {
    const { mdfId, mType, mProperties } = row;
    const mdf = getMdf({ mdfId, mType, mProperties, mdfs });
    const metadata = getMergedMetadata(row.metadata, subTypes, mdf);
    const { errorMap, validFieldMap } = getMemberErrorMap(metadata, subTypes, mdf);

    return { ...row, mdf, metadata, errorMap, validFieldMap };
  });
}
