import MUISkeleton from '@material-ui/lab/Skeleton';

import { HStack, VStack } from 'layouts/box/Box';

function Skeleton() {
  return (
    <VStack width="100%" height="100%">
      <HStack width="100%" alignItems="end" justifyContent="end">
        <MUISkeleton animation="wave" width="40%" height={40} />
      </HStack>

      <HStack width="100%" gap="8px" padding="8px" height="100px" style={{ borderBottom: 'red' }}>
        <MUISkeleton
          variant="rect"
          width={48}
          height={48}
          animation="wave"
          style={{ borderRadius: '50%' }}
        />
        <VStack flex="1">
          <MUISkeleton animation="wave" width="60%" height={32} />
          <MUISkeleton animation="wave" width="80%" />
        </VStack>
      </HStack>

      <HStack justifyContent="space-between" width="100%">
        <MUISkeleton animation="wave" width="48%" height={40} />
        <MUISkeleton animation="wave" width="48%" height={40} />
      </HStack>

      <HStack justifyContent="space-between" width="100%">
        <MUISkeleton animation="wave" width="100%" height={40} />
      </HStack>

      <VStack width="100%" gap="16px">
        {Array(5)
          .fill(null)
          .map((_u, i) => (
            <HStack justifyContent="space-between" key={i} width="100%">
              <MUISkeleton animation="wave" width="5%" height={32} />
              <MUISkeleton animation="wave" width="74%" height={32} />
              <MUISkeleton animation="wave" width="15%" />
            </HStack>
          ))}
      </VStack>
    </VStack>
  );
}

export default Skeleton;
