import { useCallback, useEffect, useState } from 'react';
import { isEqual } from 'lodash';

import { Participant } from './types';

/**
 * A participant represents a user in a browser session (browser tab) in a given scope
 */
export interface ScopedParticipant<T> extends Participant<T> {
  /** A scope ID representing where/when in the session the participant exists */
  readonly scopeId: string;
}

/**
 * Removes participants that were hidden more that a minute ago
 * @param participants The original participants
 * @returns            An array containing the participants that have not been hidden for more than
 *                     a minute. If all original participants are included, the SAME array will be
 *                     returned.
 */
function removeInactive<T>(participants: readonly ScopedParticipant<T>[]) {
  const now = Date.now();
  const updated = participants.filter((o) => isNaN(o.hiddenTime) || o.hiddenTime > now - 60000);
  return updated.length < participants.length ? Object.freeze(updated) : participants;
}

const NONE = Object.freeze([]);

export interface InitParticipant {
  /**
   * The Dina user id of the participant (`User.mId`)
   */
  readonly userId: string;
  /**
   * the lock owned by the participant or `undefined` if no such lock
   */
  readonly lock: string | undefined;
}

export interface OtherParticipantsUsage<T> {
  /**
   * An array of participants. This array will be maintained in a way so that when
   * the first participant of a given session is removed, he will be replaced
   * with a later participant from the same session if there are any.
   * It is also guaranteed that a participant can only be hidden if there are no other participants
   * from the same session.
   * (We do this to make it easy to make a quite stable active users array containing at
   * most one participant per session.)
   */
  participants: readonly ScopedParticipant<T>[];

  /**
   * A stable function that updates the `items` array with changes from one participant.
   * @param sessionId The session id of the participant
   * @param scopeId   The scope id of the participant
   * @param state     The state of the participant
   * @param initInfo  The user id of the participant (if known)
   * @returns         The updated array of participants
   */
  updateParticipant: (
    sessionId: string,
    scopeId: string,
    state: T,
    initInfo?: InitParticipant,
  ) => void;

  /**
   * A stable function that does what is necessary when a participant leaves.
   * If it is the only participant of the session he will be marked as hidden (hidden time set to
   * now) for about one minute before he is completely removed.
   * If he was the first participant of the session, he will be replaced with a later participant
   * if any.
   * @param sessionId The session id of the participant
   * @param scopeId   The scope id of the participant
   */
  removeParticipant: (sessionId: string, scopeId: string) => void;

  /**
   * Updates the locked state of a participant
   * @param sessionId The session id of the participant
   * @param scopeId   The scope id of the participant
   * @param lock      The new lock owned by the participant or `undefined if no such lock`
   */
  updateParticipantLocking: (sessionId: string, scopeId: string, lock: string | undefined) => void;

  /**
   * Clears the `items` array
   */
  clearParticipants: () => void;
}

function updateItem<T>(
  prevItems: readonly ScopedParticipant<T>[],
  sessionId: string,
  scopeId: string,
  itemUpdater: (prev: ScopedParticipant<T> | undefined) => ScopedParticipant<T> | undefined,
): readonly ScopedParticipant<T>[] {
  const prevPos = prevItems.findIndex((p) => p.sessionId === sessionId && p.scopeId === scopeId);
  const prev = prevPos >= 0 ? prevItems[prevPos] : undefined;
  const updated = itemUpdater(prev);
  if (!updated) return prevItems;

  // Check if we should move the item forward (use state of the last updated participant)
  const item = prev && isEqual(prev, updated) ? prev : updated;
  const firstPos = prevItems.findIndex((p) => p.sessionId === sessionId);
  if (item === prev && firstPos === prevPos) {
    // We return the incoming value to avoid update
    return prevItems;
  }
  if (firstPos < 0) {
    // None from session in array, just add at end (or at beginning if locked)
    const addAtEnd = !updated.lock || prevItems.some((p) => !!p.lock);
    return Object.freeze(addAtEnd ? [...prevItems, updated] : [updated, ...prevItems]);
  }
  const result = [...prevItems];
  if (firstPos === prevPos) {
    // Just replace the updated item
    result[firstPos] = item;
  } else if (!isNaN(result[firstPos].hiddenTime)) {
    // Remove the updated from its previous position
    result.splice(prevPos, 1);
    // And replace the hidden item with the updated
    result[firstPos] = item;
  } else {
    // Remove from previous position if already in array
    if (prevPos >= 0) result.splice(prevPos, 1);
    // and insert just before the previously first item of same session
    result.splice(firstPos, 0, item);
  }
  if (updated.lock && firstPos > 0 && !result.some((p, i) => p.lock && i !== firstPos)) {
    // If the updated item is locked and there are no other locked items, we move it to the front
    result.splice(firstPos, 1);
    result.unshift(updated);
  }
  return Object.freeze(result);
}

/**
 * This is a helper hook for `useSharedResource`
 * @returns The resulting {@link OtherParticipantsUsage}
 */
export default function useParticipants<T>(): OtherParticipantsUsage<T> {
  const [items, setItems] = useState<readonly ScopedParticipant<T>[]>(NONE);

  /**
   * A stable function that updates the `items` array with changes from one item.
   * @param sessionId The session id of the participant
   * @param scopeId   The scope id of the participant
   * @param state     The state of the participant
   * @param initInfo  Some extra information available in init messages
   * @returns         The updated array of participants
   */
  const updateParticipant = useCallback(
    (sessionId: string, scopeId: string, state: T, initInfo?: InitParticipant) => {
      setItems((prevItems) => {
        return updateItem(prevItems, sessionId, scopeId, (prev) => {
          const userId = initInfo?.userId ?? prev?.userId ?? '<unknown>';
          const lock = initInfo?.lock ?? prev?.lock ?? undefined;
          return Object.freeze({ sessionId, scopeId, userId, lock, state, hiddenTime: NaN });
        });
      });
    },
    [setItems],
  );

  const removeParticipant = useCallback(
    (sessionId: string, scopeId: string) => {
      setItems((prevItems) => {
        const pos = prevItems.findIndex((o) => o.sessionId === sessionId && o.scopeId === scopeId);
        if (pos < 0) return prevItems;
        const p2 = prevItems.findIndex((o) => o.sessionId === sessionId && o.scopeId !== scopeId);
        if (p2 >= 0 && p2 < pos) {
          // There is an earlier participant in the same session, just remove the one to be hidden
          return Object.freeze(prevItems.toSpliced(pos, 1));
        } else if (p2 >= 0) {
          // We should move the a later participant to the
          const result = [...prevItems];
          const replacement = result.splice(p2, 1)[0];
          result.splice(pos, 1, replacement);
          return Object.freeze(result);
        } else {
          const updated = Object.freeze({ ...prevItems[pos], hiddenTime: Date.now() });
          return Object.freeze(prevItems.toSpliced(pos, 1, updated));
        }
      });
    },
    [setItems],
  );

  const updateParticipantLocking = useCallback(
    (sessionId: string, scopeId: string, lock: string | undefined) => {
      setItems((prevItems) => {
        return updateItem(prevItems, sessionId, scopeId, (prev) => {
          if (!prev) return undefined;
          return Object.freeze({ ...prev, lock });
        });
      });
    },
    [setItems],
  );

  const clearParticipants = useCallback(() => setItems(NONE), [setItems]);

  // The only purpose with this effect is to remove items that have been hidden for a while
  useEffect(() => {
    const interval = setInterval(() => {
      setItems(removeInactive);
    }, 10000);
    return () => clearInterval(interval);
  }, [setItems]);

  return {
    participants: items,
    updateParticipant,
    updateParticipantLocking,
    removeParticipant,
    clearParticipants,
  };
}
