import { useContext } from 'react';
import { OnDataOptions } from '@apollo/client';

import UserContext from 'contexts/UserContext';
import useApolloSubscription from 'hooks/useApolloSubscriptionV2';
import useSyncedRef from 'hooks/useSyncedRef';
import GET_MEMBER from 'operations/queries/getMember';
import NOTIFY_MEMBER_UPDATE_SUBSCRIPTION from 'operations/subscriptions/notifyMemberUpdate';
import { DailyNote } from 'types';
import { getDinaSessionIdFromLockedId, getUserIdFromLockedId } from 'utils/lock/lockTokenV2';
import { getDinaSessionId } from 'utils/sessionId';

import updateDailyNoteCache from '../api/updateDailyNoteCache';
import { useCurrentDailyNote, useHasUpdatedSubscription, useSelectedDailyNoteDate } from '../store';

import useGetUTCDate from './useGetUTCDate';

type DailyNoteSubscriptionType = {
  notifyMemberUpdateSubscription: DailyNote;
};

const useDailyNoteSubscription = () => {
  const { getUTCDateString } = useGetUTCDate();
  const { mId: currentUserId } = useContext(UserContext);
  const [selectedDate] = useSelectedDailyNoteDate();
  const [currentDailyNote] = useCurrentDailyNote();
  const [, setHasUpdatedSubscription] = useHasUpdatedSubscription();

  const currentDailyNoteRef = useSyncedRef(currentDailyNote);
  const selectedMrefIdRef = useSyncedRef(getUTCDateString(selectedDate));

  useApolloSubscription(NOTIFY_MEMBER_UPDATE_SUBSCRIPTION, {
    variables: {
      mIdSubscribed: 'dailyNote',
    },
    onSubscriptionData: ({
      client,
      data: subscriptionData,
    }: OnDataOptions<DailyNoteSubscriptionType>) => {
      const updatedData = subscriptionData.data?.notifyMemberUpdateSubscription;

      if (!updatedData) return;
      // return if current user is editing here
      if (
        updatedData.locked &&
        getUserIdFromLockedId(updatedData.locked) === currentUserId &&
        getDinaSessionIdFromLockedId(updatedData.locked) === getDinaSessionId(currentUserId)
      ) {
        return;
      }

      const cachedData = client.readQuery({
        query: GET_MEMBER,
        variables: {
          input: {
            mId: updatedData.mId,
            mRefId: updatedData.mRefId,
          },
        },
      }) as { getMember: DailyNote | null };

      if (!cachedData?.getMember) {
        // insert new cache item
        updateDailyNoteCache(client, updatedData);
      }

      // don't show badge when locked
      if (!currentDailyNoteRef.current?.locked && updatedData.locked) return;

      // update state to show badge & to refetch content
      if (updatedData.mRefId === selectedMrefIdRef.current) {
        setHasUpdatedSubscription(true);
      }
    },
    source: 'dailyNote',
  });
};

export default useDailyNoteSubscription;
