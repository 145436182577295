import { useCallback } from 'react';
import capitalize from 'lodash/capitalize';

import useDateTimeUtils from 'hooks/useDateTimeUtils';
import memberTypes from 'operations/memberTypes';
import { User } from 'types';
import { AssignedMember } from 'types/members';
import { Conversation } from 'types/messageHub';
import conversationTypes from 'utils/constants/conversationTypes';

import AvatarCell from '../components/newMessage/AvatarCell';

const useAssembleData = () => {
  const { formatDistanceToNow } = useDateTimeUtils();

  const getUsersConversationData = useCallback((conversations: Conversation[], userId: string) => {
    const foundConversation = conversations.find((conversation) =>
      conversation?.mAssignedMembers?.find(({ mId }) => mId === userId),
    );
    if (foundConversation)
      return {
        conversationMId: foundConversation.mId,
        conversationMRefId: foundConversation.mRefId,
        chatHistory: capitalize(
          formatDistanceToNow(foundConversation?.mUpdatedAt ?? '', { addSuffix: true }),
        ),
        mUpdatedAt: foundConversation.mUpdatedAt,
        mAssignedMembers: foundConversation.mAssignedMembers,
        convoType: foundConversation.convoType,
      };
    const tenDaysFromNow = new Date(new Date().getTime() + 10 * 86400000).toISOString();
    return {
      chatHistory: 'Never',
      mAssignedMembers: [{ mId: userId, mType: memberTypes.USER }],
      mUpdatedAt: tenDaysFromNow,
    };
  }, []);

  const assemblePeopleData = useCallback(
    (users: User[], userId: string, conversations: Conversation[] = []) =>
      users
        .filter(({ mId }) => userId !== mId)
        .map(({ mId, mTitle, mAvatarKey }) => {
          const userData = {
            rowId: mId,
            mId,
            name: AvatarCell({ mTitle, mAvatarKey }),
            mTitle,
            mAvatarKey,
            convoType: conversationTypes.DIRECT,
            type: 'Person',
          };
          const conversationData = getUsersConversationData(conversations, mId);
          return { ...userData, ...conversationData };
        }),
    [getUsersConversationData],
  );

  const assembleTeamData = useCallback(
    (conversations: Conversation[] = [], skipAvatarComponent = false) =>
      conversations.map((conversation) => {
        const { mId, mRefId, mTitle, mUpdatedAt, mAvatarKey } = conversation;
        return {
          ...conversation,
          conversationMId: mId,
          conversationMRefId: mRefId,
          rowId: mId,
          type: 'Team',
          name: skipAvatarComponent ? (
            mTitle
          ) : (
            <AvatarCell mTitle={mTitle} mAvatarKey={mAvatarKey} avatarVariant="team" />
          ),
          chatHistory: mUpdatedAt
            ? capitalize(formatDistanceToNow(mUpdatedAt, { addSuffix: true }))
            : '',
          convoType: conversationTypes.TEAM,
          mAssignedMembers: [{ mId, mRefId, mTitle, mAvatarKey }] as AssignedMember[],
        } as Conversation;
      }),
    [],
  );

  const assembleDepartmentData = useCallback(
    (conversations: Conversation[] = [], skipAvatarComponent = false) =>
      conversations.map((conversation) => {
        const { mId, mRefId, mTitle, mUpdatedAt, mAvatarKey } = conversation;
        return {
          ...conversation,
          conversationMId: mId,
          conversationMRefId: mRefId,
          rowId: mId,
          type: 'Department',
          name: skipAvatarComponent ? (
            mTitle
          ) : (
            <AvatarCell mTitle={mTitle} mAvatarKey={mAvatarKey} avatarVariant="department" />
          ),
          chatHistory: mUpdatedAt
            ? capitalize(formatDistanceToNow(mUpdatedAt, { addSuffix: true }))
            : '',
          convoType: conversationTypes.DEPARTMENT,
          mAssignedMembers: [{ mId, mRefId, mTitle, mAvatarKey }] as AssignedMember[],
        } as Conversation;
      }),
    [],
  );

  return { assembleDepartmentData, assembleTeamData, assemblePeopleData };
};

export default useAssembleData;
