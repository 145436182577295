import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import styled from '@emotion/styled';

import { StyledDragHandle } from '../styled';

const StyledFieldWrapper = styled('div')`
  display: flex;
  justify-content: flex-start;
  gap: 2px;
`;

interface Props {
  item: { id: string };
  children: React.ReactNode;
  iconStyle?: React.CSSProperties;
}

export function SortableItemWrapper({ item, children, iconStyle }: Props) {
  const { attributes, listeners, setNodeRef, transform, transition, setActivatorNodeRef } =
    useSortable({
      id: item.id,
    });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    listStyleType: 'none',
  };

  return (
    <li key={item.id} ref={setNodeRef} style={style} {...attributes} tabIndex={-1}>
      <StyledFieldWrapper>
        <div style={iconStyle}>
          {
            // @ts-expect-error - ref coming from library
            <StyledDragHandle {...listeners} ref={setActivatorNodeRef} className="dragHandle" />
          }
        </div>
        {children}
      </StyledFieldWrapper>
    </li>
  );
}
