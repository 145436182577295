import { useMemo } from 'react';

import useMemoMap from 'utils/hooks/useMemoMap';

import { SchemaTreeLeaf, SchemaTreeSection, SectionId } from './types';

const sectionNames: Readonly<Record<SectionId, string>> = Object.freeze({
  defaults: 'System defaults',
  instances: 'Instance schemas',
  blocks: 'Instance blocks',
  custom: 'Custom schemas',
  subTypes: 'Sub types',
  lists: 'Option lists',
  trees: 'Option trees',
});

/**
 * Creates a section for a given category
 * @param category        The category for the section
 * @param items           The items that belongs to the category
 * @param getChangedLabel A function that takes the ID of an item and returns `undefined` if the
 *                        item is unchanged and otherwise the label (possibly changed) of the item.
 * @returns               The created {@link SchemaTreeSection}
 */
export function useSection(
  category: SectionId,
  items: readonly { id: string; label: string }[],
  getChangedLabel: (id: string) => string | undefined,
): SchemaTreeSection {
  const map = useMemoMap(
    Object.fromEntries(
      items.map((item) => {
        const changedLabel = getChangedLabel(item.id);
        return [
          item.id,
          Object.freeze({
            category,
            id: item.id,
            label: changedLabel ?? item.label,
            changed: changedLabel !== undefined,
          }),
        ];
      }),
    ),
  );
  return useMemo(() => {
    const children: readonly SchemaTreeLeaf[] = Object.freeze(
      Object.values(map).sort((a, b) =>
        a.label.toLocaleLowerCase().localeCompare(b.label.toLocaleLowerCase()),
      ),
    );
    return Object.freeze({
      id: category,
      category: 'section',
      label: sectionNames[category],
      children,
      changed: false,
    });
  }, [map]);
}
