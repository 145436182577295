import Scrollbar from 'components/scrollbar';
import { VStack } from 'layouts/box/Box';
import { ProviderDTO } from 'types/graphqlTypes';

import { ContentWrapper } from '../styled';

import { EditFieldProvider } from './EditProviderFields';

interface EditPlatformProps {
  provider: ProviderDTO;
}

export function EditProvider({ provider }: Readonly<EditPlatformProps>) {
  return (
    <ContentWrapper>
      {provider && (
        <VStack height="60vh" gap="40px">
          <Scrollbar
            valueChanged={undefined}
            closeToBottom={undefined}
            top={undefined}
            bottom={undefined}
            dark={undefined}
            showHorizontal={undefined}
          >
            <EditFieldProvider selectedProvider={provider} />
          </Scrollbar>
        </VStack>
      )}
    </ContentWrapper>
  );
}
