import React from 'react';
import PropTypes from 'prop-types';

import { useSelectedMenuSystemSettings, useSelectedCategory } from '../../../../../atoms';
import SETTING_TYPES from '../../../../../utils/settingTypes';
import List from '../../../../collapseList';

const Instances = ({ instanceTypes }) => {
  const [selectedMenu, setSelectedMenu] = useSelectedMenuSystemSettings();
  const [category, setCategory] = useSelectedCategory();

  const onClickInstanceType = (event, platform) => {
    event?.stopPropagation();
    setSelectedMenu(platform.mRefId);
    setCategory(SETTING_TYPES.INSTANCES);
  };

  return (
    <List>
      <List.Header
        itemCount={instanceTypes?.length}
        selected={category === SETTING_TYPES.INSTANCES}
        hideCreateButton
        onClick={(event) => onClickInstanceType(event, instanceTypes[0])}
      >
        Instances
      </List.Header>
      <List.Body>
        {instanceTypes?.map((instanceType) => (
          <List.Item
            key={instanceType.mRefId}
            selected={selectedMenu === instanceType?.mRefId}
            onClick={(event) => onClickInstanceType(event, instanceType)}
          >
            {instanceType.mTitle}
          </List.Item>
        ))}
      </List.Body>
    </List>
  );
};

Instances.propTypes = {
  instanceTypes: PropTypes.array,
};

Instances.defaultProps = {
  instanceTypes: [],
};

export default Instances;
