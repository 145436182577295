import { createScope, molecule, useMolecule } from 'jotai-molecules';

export const InstancePreviewScope = createScope<string | null>(null);

const instancePreviewMolecule = molecule((_getMol, getScope) => {
  const scope = getScope(InstancePreviewScope);

  return { scope };
});

export const useInstancePreviewMolecule = () => useMolecule(instancePreviewMolecule);
