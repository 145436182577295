import { useMemo } from 'react';
import styled from '@emotion/styled';
import { ScopeProvider } from 'jotai-molecules';

import Scrollbar from 'components/scrollbar';
import { Box } from 'layouts/box/Box';
import ItemMoveDialog from 'screens/storyV2/ItemMoveDialog';
import { StoryScope } from 'screens/storyV2/store/story';
import { MemberType } from 'types/graphqlTypes';
import { User } from 'types/members';
import { isStory } from 'utils/member/mTypes';

import Assets from './Assets';
import Assignees from './Assignees';
import Contacts from './Contacts';
import Description from './Description';
import Instances from './Instances';
import Notes from './Notes';
import Planning from './Planning';
import Priority from './Priority';
import Schedule from './Schedule';
import Tasks from './Tasks';

const ScrollContent = styled('div')`
  // Target all children except the first one
  & > div {
    position: relative;
  }
  // Add divider before each child after the first
  & > div:not(:first-child)::before {
    z-index: 100;
    content: '';
    position: absolute;
    top: 0;
    left: 12px;
    right: 12px;
    height: 1px;
    background-color: ${({ theme }) => theme.palette.dina.dividerLight};
  }
`;

function Story({ story }: Readonly<{ story: MemberType }>) {
  const isStoryType = useMemo(() => isStory(story?.mType ?? ''), [story.mType]);
  return (
    <ScopeProvider scope={StoryScope} value={`${story.mId}:inlinePreview`}>
      <Box height="100%" width="100%">
        <Scrollbar>
          <ScrollContent>
            <Description description={story.mDescription} />
            <Schedule story={story} />
            {isStoryType && <Priority story={story} />}
            <Assignees
              storyId={story.mId ?? ''}
              assignedMembers={story.mAssignedMembers as User[]}
            />
            <Contacts
              storyId={story.mId ?? ''}
              assignedMembers={story.mAssignedMembers as User[]}
            />
            <Assets storyId={story.mId ?? ''} />
            {isStoryType && <Instances storyId={story.mId ?? ''} />}
            <Notes storyId={story.mId ?? ''} />
            <Tasks story={story} />
            <Planning story={story} />
          </ScrollContent>
        </Scrollbar>
        <ItemMoveDialog />
      </Box>
    </ScopeProvider>
  );
}

export default Story;
