import gql from 'graphql-tag';

export default gql`
  mutation moveInstanceToRundown($input: MoveInstanceToRundownInput) {
    moveInstanceToRundown(input: $input) {
      mId
      mRefId
      mTitle
      mState
      mType
      mUpdatedAt
      mUpdatedById
      mPublishingAt
      mStoryId
      locked
      mTemplateId
      isRestricted
      isTemplateInstance
      mThumbnailKey
      mContentKey
      mCreatedAt
      mDefaultContentKey
      mRundownTemplateId
      items {
        itemId
        title
        templateType
        templateVariant
        iconUrl
        state
        eventTime
        startTime
        stopTime
        subItems {
          itemId
          title
          templateType
          templateVariant
          iconUrl
        }
      }
      mMetaData {
        key
        value
        autoValue
        manual
      }
      mRelatedMembers
      mProperties {
        __typename
        ... on PlatformType {
          id
          platform
          platformKind
          account {
            id
            accountUrl
            accountLogo
            accountTitle
            accountId
            accountRefId
            orderType
          }
          provider {
            id
            requestId
            state
            eventTime
            startTime
            stopTime
            message
          }
        }
      }
      mAssignedMembers {
        mId
        mType
      }
      assets {
        mId
        assetRefId
        itemId
        itemType
        state
        eventTime
        startTime
        stopTime
      }
    }
  }
`;
