import { useEffect, useState } from 'react';
import { CellContext } from '@tanstack/react-table';
import { intersectionBy } from 'lodash';

import MemberLabel from 'components/addMember/MemberLabel';
import { useAllMembers, useMembersDialog } from 'store';
import { AssignedMember } from 'types';

interface UserCellProps<T> extends Pick<CellContext<T, string>, 'getValue' | 'table' | 'row'> {
  /** Title of the assign member dialog */
  title: string;
  /** Field id to update */
  fieldId: string;
}

interface RowData {
  [key: string]: unknown;
}

/**
 * User cell displays avatar + username.
 * onClick will open useMembersDialog dialog
 *
 * TODO - duplicate of MDF UserCell. Combine the two.
 */
export default function UserCell<T extends RowData>({
  title,
  table,
  row,
  fieldId,
}: Readonly<UserCellProps<T>>) {
  const memberId = row.original[fieldId];

  // Array used to comply with the useMembersDialog hook
  const [mAssignedMembers, setMAssignedMembers] = useState([{ mId: memberId }]);
  const [loading, setLoading] = useState(true);

  const [, setGetMembers] = useMembersDialog();
  const [allMembers] = useAllMembers();

  /** Sync external changes */
  useEffect(() => setMAssignedMembers([{ mId: memberId }]), [memberId]);

  /** Loading on first render to improve initial table render time */
  useEffect(() => {
    if (loading) setLoading(false);
  }, []);

  if (loading) return 'Loading...';

  const members = intersectionBy(allMembers, mAssignedMembers, 'mId');

  const openDialog = (): void => {
    setGetMembers({
      open: true,
      removeTooltipText: 'Remove',
      dialogTitle: title,
      singleChoice: true,
      startWith: members,
      variant: 'user',
      onOk: (newMembers: AssignedMember[]): void => {
        const update = table?.options?.meta?.updateData;

        if (newMembers && update) {
          const member = newMembers[0];
          setMAssignedMembers(newMembers);
          update(row.original, [{ fieldId, value: member.mId }]);
        }
      },
    });
  };

  const assignedMemberId = members[0]?.mId;

  return (
    <MemberLabel
      variant="grid"
      memberId={assignedMemberId}
      style={{ overflow: 'hidden', width: '100%' }}
      onClick={openDialog}
    />
  );
}
