import { MutableRefObject, useCallback, useMemo, useRef } from 'react';

interface CancellablePromise<T = unknown> {
  promise: Promise<T>;
  cancel: () => void;
}

interface PromiseApi {
  appendPendingPromise: (promise: CancellablePromise<unknown>) => void;
  removePendingPromise: (promise: CancellablePromise<unknown>) => void;
  clearPendingPromises: () => void;
}

const usePromiseApi = (): PromiseApi => {
  const pendingPromises: MutableRefObject<CancellablePromise<unknown>[]> = useRef([]);

  const appendPendingPromise = useCallback((promise: CancellablePromise<unknown>): void => {
    pendingPromises.current = [...pendingPromises.current, promise];
  }, []);

  const removePendingPromise = useCallback((promise: CancellablePromise<unknown>): void => {
    pendingPromises.current = pendingPromises.current.filter((p) => p !== promise);
  }, []);

  const clearPendingPromises = useCallback((): void => {
    pendingPromises.current.forEach((p) => p.cancel());
    pendingPromises.current = [];
  }, []);

  const api: PromiseApi = useMemo(
    () => ({
      appendPendingPromise,
      removePendingPromise,
      clearPendingPromises,
    }),
    [appendPendingPromise, clearPendingPromises, removePendingPromise],
  );

  return api;
};

export default usePromiseApi;
