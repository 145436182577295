import { memo, useCallback, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

import { ReactComponent as Close } from 'assets/icons/systemicons/close.svg';
import { IconButton } from 'components/buttons';
import { useCreateMemberDialog } from 'components/createNewV3/CreateNewDialog';
import Tabs from 'components/tabs/regular/Tabs';
import Text from 'components/text';
import { Flex } from 'layouts/box/Box';

import Content from './components/Content';
import Triggers from './components/Triggers';
import useGetKeyMaps from './hooks/useGetKeyMaps';
import { getKeybinding } from './utils';

import { Body, Container, Header } from './styled';

function ShortcutsComponent() {
  const { keyMaps } = useGetKeyMaps();
  const [, showCreateMemberDialog] = useCreateMemberDialog();

  const availableTabs = Object.keys(keyMaps) as (keyof typeof keyMaps)[];
  const [orderedTabs, setOrderedTabs] = useState<(keyof typeof keyMaps)[]>(availableTabs);
  const [activeTab, setActiveTab] = useState<keyof typeof keyMaps>(availableTabs[0]);
  const [isVisible, setIsVisible] = useState(false);

  const handleTabReorder = useCallback((newTabOrder: (keyof typeof keyMaps)[]) => {
    setOrderedTabs(newTabOrder);
  }, []);

  // Toggle visibility hotkey
  useHotkeys(
    keyMaps.General.SHOW_KEYS.sequences,
    () => setIsVisible((prev) => !prev),
    {
      preventDefault: true,
    },
    [keyMaps.General.SHOW_KEYS.sequences],
  );

  // Create story hotkey
  useHotkeys(
    keyMaps.General.CREATE_STORY.sequences,
    () => {
      showCreateMemberDialog({
        mTitle: '',
        forceShow: true,
        anchorEl: null,
      });
    },
    { preventDefault: true },
    [showCreateMemberDialog, keyMaps.General.CREATE_STORY.sequences],
  );

  // Set active tab with proper type handling
  const handleSetActiveTab = useCallback((tab: string) => {
    setActiveTab(tab as keyof typeof keyMaps);
  }, []);

  // Close the shortcuts panel
  const handleClose = useCallback(() => {
    setIsVisible(false);
  }, []);

  if (!isVisible) return null;

  return (
    <Container aria-label="Keyboard shortcuts">
      <Tabs
        defaultTab={orderedTabs[0]}
        activeTab={activeTab}
        setActiveTab={handleSetActiveTab}
        direction="vertical"
      >
        <Header>
          <Text variant="h7" color="highEmphasis" style={{ minWidth: 200 }}>
            Keyboard shortcuts{' '}
            <Text variant="captionMedium" color="mediumEmphasis">
              (
              {keyMaps.General.SHOW_KEYS.sequences
                .map((sequence) => getKeybinding(sequence))
                .join(' | ')}
              )
            </Text>
          </Text>
          <Flex flex="1" height="100%">
            <Triggers
              tabs={orderedTabs}
              onTabsReorder={handleTabReorder}
              activeTab={activeTab}
              setActiveTab={handleSetActiveTab}
            />
          </Flex>
          <IconButton usage="text" onClick={handleClose} aria-label="Close keyboard shortcuts">
            <Close />
          </IconButton>
        </Header>
        <Body>
          {orderedTabs.map((tab) => (
            <Content key={tab} value={tab} keyMap={keyMaps[tab]} />
          ))}
        </Body>
      </Tabs>
    </Container>
  );
}

export default memo(ShortcutsComponent);
