import { useCallback, useMemo, useState } from 'react';
import keyBy from 'lodash/keyBy';

import { useGetOrderForms } from 'api/order_forms/useGetOrderForms';
import { OrderWithLabel } from 'features/sidepanel/ComponentUtils';
import { useGetOrders } from 'screens/space/api/useGetOrdersAndForms';
import { GetOrderEnum, MemberType, TaskStatusEnum } from 'types/graphqlTypes';

import Group, { Empty } from '../Group';

import Task, { TaskSkeleton } from './Task';

function Tasks({ story }: Readonly<{ story: MemberType }>) {
  const [collapsed, setCollapsed] = useState(true);
  const { orders, loading } = useGetOrders(
    story?.mId ?? '',
    GetOrderEnum.Resource,
    TaskStatusEnum.all,
  );

  const { orderForms } = useGetOrderForms();
  const orderFormMap = useMemo(() => {
    return keyBy(orderForms, (orderForm) => orderForm.mRefId);
  }, [orderForms]);

  const orderWithLabel: OrderWithLabel[] = useMemo(() => {
    return orders
      .map((order) => {
        const orderForm = orderFormMap?.[order.mFormId];
        return {
          ...order,
          formLabel: orderForm?.mDescription ?? '',
          formColor: orderForm?.mColor,
          statusLabel: '',
          statusOptions: [],
        };
      })
      .sort((a, b) => a.formLabel.localeCompare(b.formLabel));
  }, [orders, orderFormMap]);

  const toggleCollapsed = useCallback(() => setCollapsed(!collapsed), [collapsed]);

  const moreCount = useMemo(() => Math.max(orders.length - 3, 0), [orders.length]);

  return (
    <Group
      label="Tasks"
      collapsed={moreCount > 0 ? collapsed : undefined}
      toggleCollapsed={toggleCollapsed}
      moreCount={moreCount}
    >
      <>
        {loading ? (
          Array.from({ length: 3 }, (_, index) => <TaskSkeleton key={index} />)
        ) : orderWithLabel?.length === 0 ? (
          <Empty label="Tasks" />
        ) : (
          (
            (collapsed && orderWithLabel.length > 3
              ? orderWithLabel.slice(0, 3)
              : orderWithLabel) ?? []
          ).map((task) => <Task key={task.mId} task={task} />)
        )}
      </>
    </Group>
  );
}

export default Tasks;
