import React from 'react';
import styled from '@emotion/styled';
import { capitalize } from 'lodash';

import Text from 'components/text';
import Tooltip from 'components/tooltip';
import { Box, HStack } from 'layouts/box/Box';

const StyledBox = styled(Box)`
  background-color: ${({ theme }) => theme.palette.dina.iconInactiveUltraDiscreet};
`;

const StyledText = styled(Text)`
  min-width: 12px;
  text-align: center;
`;

const BadgeWrapper = styled(HStack)`
  :hover {
    span {
      color: ${({ theme }) => theme.palette.dina.highEmphasis};
    }
  }
`;

interface BadgeProps {
  readonly badgeType: string;
  readonly icon: React.ReactNode;
  readonly count?: number;
}
function Badge({ count, badgeType, icon }: BadgeProps) {
  if (!count) return null;
  return (
    <Tooltip title={`${capitalize(badgeType)}: ${count}`}>
      <BadgeWrapper borderRadius="2px" overflow="hidden" height="16px">
        {icon}
        <StyledBox container height="100%" padding="0 2px">
          <StyledText variant="captionSmall">{count}</StyledText>
        </StyledBox>
      </BadgeWrapper>
    </Tooltip>
  );
}

export default Badge;
