import React from 'react';

import { ReactComponent as Add } from 'assets/icons/systemicons/add_small_1px.svg';
import { IconButton } from 'components/buttons';
import Text from 'components/text';

import { Header } from './styled';

interface Props {
  label: string;
  children: React.ReactNode;
  disableAddClick?: boolean;
  tooltip?: string;
  onAddClick?: () => void;
}

export function SettingsListSection({
  label,
  tooltip,
  children,
  disableAddClick,
  onAddClick,
}: Readonly<Props>) {
  return (
    <>
      <Header>
        <Text variant="overline" color="highEmphasis">
          {label}
        </Text>
        {onAddClick && (
          <IconButton
            title={tooltip ?? 'Add'}
            size={24}
            usage="text"
            disabled={!!disableAddClick}
            onClick={onAddClick}
          >
            <Add />
          </IconButton>
        )}
      </Header>
      {children}
    </>
  );
}
