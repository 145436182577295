import { useQuery, WatchQueryFetchPolicy } from '@apollo/client';

import GET_ORGANIZATIONS from 'operations/queries/getOrganizations';
import { OrgAccessTypeEnum, type Organization } from 'types/graphqlTypes';

export interface FetchOrganizations {
  getOrganizations: Organization[];
}

const EMPTY_RESULT: Organization[] = [];

function useGetOrganizations(
  accessType: OrgAccessTypeEnum = OrgAccessTypeEnum.Switchable,
  fetchPolicy: WatchQueryFetchPolicy = 'cache-and-network',
) {
  const { data, loading, error } = useQuery<FetchOrganizations>(GET_ORGANIZATIONS, {
    variables: { input: { accessType } },
    fetchPolicy,
    nextFetchPolicy: fetchPolicy,
  });

  return { organizations: data?.getOrganizations ?? EMPTY_RESULT, loading, error };
}

export default useGetOrganizations;
