import { useContext } from 'react';
import { useApolloClient, useMutation } from '@apollo/client';
import type { EditorValue } from 'types';

import configCtx from 'contexts/configContext';
import UPDATE_INSTANCE from 'operations/mutations/updateInstance';
import GET_INSTANCE_METADATA_FROM_CACHE from 'operations/queries/getInstanceMetadataFromCache';
import type {
  Item,
  MemberType,
  MetadataFormFieldType,
  MMetaDataField,
  MMetaDataFieldInput,
  PlatformType,
  UpdateInstanceInput,
} from 'types/graphqlTypes';
import getMetadataWithMosTag from 'utils/metadata/addMostag';
import useLogger from 'utils/useLogger';

import useUpdateFloatedItems from './useUpdateFloatedItems';

type Params = {
  instanceId: string;
  metadata?: MMetaDataField[]; // Replace 'any' with the actual type if known
  items?: Item[];
  unlock?: boolean;
  autosave?: boolean;
  version?: string;
  content?: EditorValue;
  audit?: { source: string };
  onMetadataUpdated?: (instanceId: string, metadata: MMetaDataField[]) => void;
};

type OptimisticResponce = {
  __typename: string;
  updateInstance: MemberType;
};

const useUpdateInstance = () => {
  const logger = useLogger('use update instance metadata');
  const [updateInstanceMutation] = useMutation(UPDATE_INSTANCE);
  const { metadataForms } = useContext(configCtx);
  const client = useApolloClient();
  const [updateFloatedItems] = useUpdateFloatedItems();

  /**
   * Returns true if the given array is a valid array and not empty
   * @param {*} array
   * @returns true if the given array is not empty
   */
  const IsNotEmptyArray = <T>(array: T[]): boolean => Array.isArray(array) && array.length > 0;

  const { fields: formFields = [] } = IsNotEmptyArray(metadataForms) ? metadataForms[0] : {};

  /**
   * Returns true if the given params has no changes.
   * @param {*} params
   */
  const noChanges = (params: Params) => {
    const { metadata, items, unlock, version } = params;
    return !unlock && !items && !IsNotEmptyArray(metadata || []) && !version;
  };

  const mergeMetaData = (
    mMetaDataState: MMetaDataField[],
    mMetaData: MMetaDataField[],
  ): MMetaDataField[] => {
    const metaDataMap = new Map<string, MMetaDataField>();

    // Add existing metadata to the map
    mMetaDataState.forEach((meta) => {
      metaDataMap.set(meta.key, meta);
    });

    // Add new metadata, overwriting existing keys
    mMetaData.forEach((meta) => {
      metaDataMap.set(meta.key, meta);
    });

    // Convert the map back to an array
    return Array.from(metaDataMap.values());
  };

  const createOptimisticResponse = (
    input: UpdateInstanceInput,
    instanceState: MemberType,
  ): OptimisticResponce => {
    const optInput = JSON.parse(JSON.stringify(input)) as MemberType;
    const { mMetaData = [], mId } = optInput;
    const { mMetaData: mMetaDataState = [] } = instanceState;
    const mergedMetaData = mergeMetaData(mMetaDataState, mMetaData);

    const mMetadataWithTypeName: MMetaDataFieldInput[] = mergedMetaData.map((m) => ({
      __typename: 'mMetaDataField',
      key: m.key,
      value: m.value,
      mostag: m.mostag ? m.mostag : null,
      manual: m.manual ? m.manual : null,
      autoValue: m.autoValue ? m.autoValue : null,
    }));

    return {
      __typename: 'Mutation',
      updateInstance: {
        __typename: 'MemberType',
        mId: mId,
        mRefId: mId,
        mMetaData: mMetadataWithTypeName as MMetaDataField[],
      },
    };
  };
  const readInstanceFromCache = (mId: string) => {
    const result = client.readFragment<MemberType>({
      fragment: GET_INSTANCE_METADATA_FROM_CACHE,
      id: mId,
      fragmentName: 'currentInstanceMetaData',
    });

    return result || { mMetaData: [] };
  };
  const findField = (key: string, metadata: MMetaDataField[]) =>
    metadata.find((m) => m.key.includes(key));

  const getBooleanFieldValue = (field?: MMetaDataField, defaultValue = 'false') =>
    field ? field.value : defaultValue;

  const getFloatDataChange = (
    oldMetadata: MMetaDataField[] | undefined,
    newMetadata: MMetaDataField[] | undefined,
  ) => {
    if (!oldMetadata || !newMetadata) return { oldValue: 'false', newValue: 'false' };
    const floatFieldKey = 'isFloat';
    const oldFloatField = findField(floatFieldKey, oldMetadata);
    const floatField = findField(floatFieldKey, newMetadata);
    const oldValue = getBooleanFieldValue(oldFloatField);

    // if no float information in updated metadata then consider it is same as old value
    return { oldValue, newValue: getBooleanFieldValue(floatField, oldValue) };
  };

  const getRundownId = (instance: MemberType = {}) => {
    const { mProperties } = instance;
    const { account, platform } = (mProperties ?? {}) as PlatformType;
    if (platform !== 'linear') return null;
    return account?.accountId;
  };

  /**
   * Automation Item representation
   * @typedef {object} AutomationItem
   * @property {string} itemId - Unique item id within the instance
   * @property {string} templateType - Main automation template type (main category)
   * @property {string} templateVariant - Automation variant (subcategory)
   * @property {string} title - String representation of the template
   */

  /**
   * Callback for updating metadata after storing instance to the database
   * @callback onMetadataUpdated
   * @param {string} instanceId - The instance.mId
   * @param {} metadata - Array of [key,value] to update the instance metadata
   */

  /**
   * Updates the instance representation in the database.
   * Invokes the graphql updateInstance mutation
   * @param {object} params - Instance properties for update
   * @param {string} params.instanceId - The instance.mId
   * @param {*} params.metadata - Array of [key,value] to update the instance metadata
   * @param {AutomationItem[] | undefined } params.items - Array of first 3 automation
   * items within the instance
   * @param {boolean} params.unlock - True if the instance shall be unlocked
   * @param {boolean} params.autosave - True if the instance update is due an autosave
   * @param {string | undefined} params.version - version
   * @param {*} params.content - Editor content object in slate format
   * @param {{source:string} | undefined} params.audit - audit information
   * @param {onMetadataUpdated} params.onMetadataUpdated - Callback for updating metadata after save
   */
  const updateInstance = async (params: Params) => {
    if (noChanges(params)) return;

    const { metadata = [], items, instanceId } = params;
    const hasMetadata = IsNotEmptyArray(metadata);

    const input: UpdateInstanceInput = { mId: instanceId };
    if (items) input.items = items;

    const getMetaDataForFormFields = (
      existingFormfields: MetadataFormFieldType[],
      existingMetadata: MMetaDataField[],
    ) => {
      return getMetadataWithMosTag(existingFormfields, existingMetadata);
    };

    if (hasMetadata) {
      input.mMetaData = formFields ? getMetaDataForFormFields(formFields, metadata) : metadata;
    }

    const { unlock, content, version, autosave, audit } = params;

    let sContent: string | undefined;
    try {
      sContent = JSON.stringify(content);
    } catch (error) {
      if (error instanceof Error) {
        logger.error(error);
      } else {
        logger.error(String(error));
      }
      sContent = undefined;
    }

    if (unlock) input.locked = null;
    if (content) input.content = sContent;
    if (version) input.version = version;
    if (autosave) input.autosave = true;
    if (audit) input.audit = { ...audit, timestamp: new Date().toISOString() };

    try {
      const instanceState = readInstanceFromCache(input.mId);
      const rundownId = getRundownId(instanceState);
      const optimisticResponse = createOptimisticResponse(input, instanceState);
      const response = await updateInstanceMutation({
        variables: { input },
        optimisticResponse,
      });

      if (!hasMetadata) return response;

      const { onMetadataUpdated } = params;
      if (onMetadataUpdated) {
        onMetadataUpdated(instanceId, input.mMetaData as MMetaDataField[]);
      }

      if (!rundownId) return response;

      const { newValue: newFloatValue, oldValue: oldFloatValue } = getFloatDataChange(
        instanceState.mMetaData,
        input.mMetaData as MMetaDataField[],
      );

      if (oldFloatValue !== newFloatValue) {
        await updateFloatedItems(rundownId, [input.mId], newFloatValue);
      }
      return response;
    } catch (error) {
      if (error instanceof Error) {
        logger.error(error);
      } else {
        logger.error(String(error));
      }

      return null;
    }
  };

  return [updateInstance];
};

export default useUpdateInstance;
