import React, { useCallback } from 'react';
import styled from '@emotion/styled';

import useOpenMember from 'components/contextMenu/useOpenMember';
import { Icon } from 'components/memberIcon/MemberIcon';
import Text from 'components/text';
import Scheduled, {
  CUSTOM_PUBLISHED_DATE_FORMAT,
} from 'features/storyHub/components/Scheduled/Scheduled';
import { HStack } from 'layouts/box/Box';
import { MemberType } from 'types/graphqlTypes';

const Wrapper = styled(HStack)`
  height: 28px;
  justify-content: space-between;
  :hover {
    cursor: pointer;
  }
`;

export default function ActivityItem({ itemData }: Readonly<{ itemData: MemberType }>) {
  const { openItem } = useOpenMember();

  const doOpenItem = useCallback(
    (ev: React.MouseEvent<HTMLElement, MouseEvent>) => {
      ev.stopPropagation();
      if (itemData) openItem(itemData);
    },
    [openItem, itemData],
  );
  return (
    <Wrapper onClick={doOpenItem}>
      <HStack gap="6px">
        <Icon member={itemData} />
        <Text variant="buttonMedium" truncate>
          {itemData.mTitle}
        </Text>
      </HStack>
      <div>
        <Scheduled
          mPublishingAt={itemData.mPublishingAt}
          dateFormat={CUSTOM_PUBLISHED_DATE_FORMAT}
          isIcon={false}
        />
      </div>
    </Wrapper>
  );
}
