import Switch from 'components/switch/Switch';
import Text from 'components/text';
import { Box, HStack, VStack } from 'layouts/box/Box';
import { KanbanBoardStateType } from 'types';

import StateGroup from './StateGroup';

import { StyledGroupWrapper } from './styled';

function WorkflowGroup({
  title,
  description,
  switchSelected,
  onSwitchClick,
  stateOn,
  states,
  onStateChange,
  stateLabel,
  subChoiceLabel,
  warningLabel,
}: Readonly<{
  title: string;
  description: string;
  switchSelected: boolean;
  onSwitchClick: () => void;
  stateOn: string | null;
  states: KanbanBoardStateType[];
  onStateChange: (state: string | null) => void;
  stateLabel: string;
  subChoiceLabel: string;
  warningLabel?: string;
}>) {
  return (
    <StyledGroupWrapper gap="0.5rem" overflow="visible">
      <Text variant="h7" color="highEmphasis">
        {title}
      </Text>
      <Text variant="caption" color="mediumEmphasis">
        {description}
      </Text>
      <HStack overflow="visible">
        <Switch selected={switchSelected} onClick={onSwitchClick} disabled={false} />
        <Text variant="listItemLabel" color="highEmphasis">
          {stateLabel}
        </Text>
      </HStack>
      {switchSelected && (
        <Box container padding="0 0 0 3rem" flexDirection="column" alignItems="flex-start">
          <VStack gap="0.5rem" overflow="visible">
            <Text variant="overline">{subChoiceLabel}</Text>
            {warningLabel && (
              <Text variant="caption" color="statusWarning">
                {warningLabel}
              </Text>
            )}
          </VStack>
          <StateGroup states={states} selectedState={stateOn} onStateChange={onStateChange} />
        </Box>
      )}
    </StyledGroupWrapper>
  );
}

export default WorkflowGroup;
