import { capitalize } from 'lodash';
import { v4 } from 'uuid';

import { UnsavedMdfField } from 'features/mdf/mdf-utils';
import {
  type Alternative,
  FieldTypeEnum,
  type FieldValue,
  type LayoutSettings,
  type Mdf,
  type MdfField,
  type Metadata,
} from 'types/graphqlTypes';

export const getDefaultPayload = (mdf: Mdf) => {
  const payload = mdf.fields.reduce((acc: Metadata, curr: MdfField) => {
    const value = curr.defaultValue.value;
    if (value && (!Array.isArray(value) || value.length)) {
      acc[curr.fieldId] = curr.defaultValue.value as FieldValue;
    }
    return acc;
  }, {});
  return payload;
};

export const createNewField = (fieldId: string): UnsavedMdfField => ({
  fieldId,
  type: FieldTypeEnum.text,
  isUnsaved: true,
  existsElseWhere: false,
  defaultValue: {
    value: null,
  },
});

export const getDefaultLayoutSettings = (field: MdfField): LayoutSettings => {
  return {
    fieldId: field.fieldId,
    label: capitalize(field.fieldId),
    hint: '',
    visible: true,
  };
};

export const hasAlternatives = (field: MdfField) =>
  [FieldTypeEnum.multiplechoice, FieldTypeEnum.choice].includes(field.type);

export const normalizeField = (field: MdfField): MdfField => {
  const updatedField = { ...field };
  if (field.type === FieldTypeEnum.choice && !field?.alternatives) {
    updatedField.alternatives = [];
    return updatedField;
  }
  return field;
};

function isObject(a: unknown): a is Record<string, unknown> {
  return typeof a === 'object';
}

export function getAlternativesFromJson(data: unknown): Alternative[] {
  if (!Array.isArray(data)) throw Error('The file does not contain an array (the alternatives).');
  if (!data.length) throw Error('The file does not contain any alternatives.');
  const result: Alternative[] = [];
  const values = new Set<string>();
  for (const alternative of data) {
    const index = result.length + 1;
    if (!isObject(alternative)) {
      throw new Error(`Alternative #${index} is not an object.`);
    }
    const label = 'label' in alternative && alternative.label;
    const value = 'value' in alternative && alternative.value;
    if (typeof label !== 'string') {
      throw new Error(`Alternative #${index} does not contain a valid label.`);
    }
    if (typeof value !== 'string') {
      throw new Error(`Alternative #${index} does not contain a valid value.`);
    }
    if (values.has(value)) {
      throw new Error(`The value of alternative #${index} is a duplicate.`);
    }
    values.add(value);
    result.push({ label, value, id: v4() });
  }
  return result;
}

export function hasDuplicateAlternatives(items: readonly Readonly<Alternative>[]) {
  const values = new Set<string>();
  for (const item of items) {
    if (values.has(item.value)) return true;
    values.add(item.value);
  }
  return false;
}

export function alternativesAreDeeplyEqual(
  a: readonly Readonly<Alternative>[],
  b: readonly Readonly<Alternative>[],
) {
  return (
    a.length === b.length &&
    a.every((aItem, index) => aItem.label === b[index].label && aItem.value === b[index].value)
  );
}

// Tree choice utils
export interface TreeNode {
  readonly id: string;
  readonly value: string;
  readonly children: readonly TreeNode[];
}

interface MutableTreeNode {
  id: string;
  value: string;
  children: MutableTreeNode[];
}

export function createTree(data: readonly (readonly string[])[]): readonly TreeNode[] {
  const tree: MutableTreeNode[] = [];
  for (const item of data) {
    let currentLevel: MutableTreeNode[] = tree;
    for (const nodeValue of item) {
      let found = false;
      for (const child of currentLevel) {
        if (child.value === nodeValue) {
          currentLevel = child.children;
          found = true;
          break;
        }
      }
      if (!found) {
        const newNode: MutableTreeNode = {
          id: v4(),
          value: nodeValue,
          children: [],
        };
        currentLevel.push(newNode);
        currentLevel = newNode.children;
      }
    }
  }
  return tree;
}

export function sortTree(tree: readonly TreeNode[]): readonly TreeNode[] {
  return tree
    .toSorted((a, b) => a.value.localeCompare(b.value))
    .map((node) =>
      node.children.length > 1 ? { ...node, children: sortTree(node.children) } : node,
    );
}

export function revertTree(tree: readonly TreeNode[]): string[][] {
  const result: string[][] = [];

  function reverseNode(node: TreeNode, parentPath: string[] = []): void {
    const fullPath = [...parentPath, node.value];
    result.push(fullPath);

    for (const child of node.children) {
      reverseNode(child, fullPath);
    }
  }

  for (const node of tree) {
    reverseNode(node);
  }

  return result;
}

export const generateNodeName = (currentLevel: readonly TreeNode[]): string => {
  let counter = currentLevel.length + 1;
  let value = `Value ${counter}`;
  // eslint-disable-next-line @typescript-eslint/no-loop-func
  while (currentLevel.some((node) => node?.value === value)) {
    counter++;
    value = `Value ${counter}`;
  }
  return value;
};

function appendOrInsertNode(nodes: readonly TreeNode[], position = -1): TreeNode[] {
  const child = {
    id: v4(),
    value: generateNodeName(nodes),
    children: [],
  };
  return position < 0 ? [...nodes, child] : nodes.toSpliced(position, 0, child);
}

type NodeHandler = (nodes: readonly TreeNode[], index: number) => TreeNode[];

const nodeRemover: NodeHandler = (nodes, index) => nodes.toSpliced(index, 1);

const nodeChildAdder: NodeHandler = (nodes, index) => {
  const node = nodes[index];
  return nodes.toSpliced(index, 1, {
    ...node,
    children: appendOrInsertNode(node.children),
  });
};

const nodeSiblingAdder: NodeHandler = (nodes, index) => {
  return appendOrInsertNode(nodes, index + 1);
};

function updateTree(
  tree: readonly TreeNode[],
  path: readonly string[],
  nodeHandler: NodeHandler,
): readonly TreeNode[] {
  if (!path.length) return tree; // This should not happen
  const [value, ...innerPath] = path;
  const index = tree.findIndex((node) => node.value === value);
  if (index < 0) return tree;
  if (!innerPath.length) {
    return nodeHandler(tree, index);
  }
  const node = tree[index];
  const children = updateTree(node.children, innerPath, nodeHandler);
  return node.children === children ? tree : tree.toSpliced(index, 1, { ...tree[index], children });
}

export function addNode(
  tree: readonly TreeNode[],
  path: string[],
  where: 'asChild' | 'asSibling',
): readonly TreeNode[] {
  const adder = where === 'asChild' ? nodeChildAdder : nodeSiblingAdder;
  return path.length ? updateTree(tree, path, adder) : appendOrInsertNode(tree);
}

export function removeNode(
  tree: readonly TreeNode[],
  path: readonly string[],
): readonly TreeNode[] {
  return updateTree(tree, path, nodeRemover);
}

export function renameNode(
  tree: readonly TreeNode[],
  path: string[],
  newName: string,
): readonly TreeNode[] {
  if (newName === path.at(-1)) return tree;
  return updateTree(tree, path, (nodes, index) =>
    nodes.toSpliced(index, 1, { ...nodes[index], value: newName }),
  );
}

function checkTreeCellFromJson(rowIndex: number, cellIndex: number, cell: unknown): string {
  switch (typeof cell) {
    case 'string':
      if (cell === '') {
        throw new Error(`Cell #${cellIndex + 1} in row #${rowIndex + 1} is empty.`);
      }
      if (cell.includes('▸')) {
        throw new Error(
          `Cell #${cellIndex + 1} in row #${rowIndex + 1} contains the reserved character '▸'.`,
        );
      }
      return cell;
    case 'number':
    case 'boolean':
      return String(cell);
    default:
      throw Error(
        `Cell #${cellIndex + 1} in row #${rowIndex + 1} must be a string, number, or boolean.`,
      );
  }
}

export function treesAreEqual(first: readonly TreeNode[], second: readonly TreeNode[]): boolean {
  return (
    first.length === second.length &&
    first.every((node, index) => {
      const secondNode = second[index];
      return node.value === secondNode.value && treesAreEqual(node.children, secondNode.children);
    })
  );
}

export function getTreeAlternativesFromJson(data: unknown): string[][] {
  if (!Array.isArray(data)) throw Error('The file does not contain an array (the rows).');
  if (!data.length) throw Error('The file contains no rows.');
  const rows: string[][] = [];
  for (const rowData of data) {
    if (!Array.isArray(rowData))
      throw Error(`Row #${rows.length + 1} is not an array (the cells).`);
    if (!rowData.length) throw Error(`Row #${rows.length + 1} is empty.`);
    const row: string[] = [];
    for (const cell of rowData) {
      row.push(checkTreeCellFromJson(rows.length, row.length, cell));
    }
    rows.push(row);
  }
  return rows;
}
