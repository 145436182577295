import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Chip, IconButton, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import searchIcon from 'assets/icons/systemicons/search.svg';
import Icon from 'components/icon/icon-view';

/** Searchbar */
export const Wrapper = styled('section')`
  height: 38px;
  background: ${({ theme }) => theme.palette.dina.blackHoverOverlay};
  display: flex;
  justify-content: stretch;
  border-bottom: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
  width: 100%;
`;

export const InputWrapper = styled('div')`
  display: flex;
  flex-grow: 1;
  position: relative;
  background-image: url(${searchIcon});
  background-repeat: no-repeat;
  background-size: 16px 16px;
  background-position: 8px center;
  :hover {
    background-color: ${({ theme }) => theme.palette.dina.blackHoverOverlay};
  }
`;

export const SearchInput = styled(Autocomplete)`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-left: 28px;
  & input {
    ${({ theme }) => css`
      ${theme.typography.dina.caption};
      color: ${theme.palette.dina.highEmphasis};
    `}
    width: 100%;
    height: 36px;
    border-radius: 4px;
    padding-left: 32px;
    padding: 0;
  }
  .MuiAutocomplete-input,
  .MuiAutocomplete-inputRoot {
    padding: 0px !important;
  }
  .MuiAutocomplete-clearIndicator {
    color: ${({ theme }) => theme.palette.dina.iconInactive};
  }
`;

export const SearchChip = styled(Chip)`
  ${({ theme }) => css`
    ${theme.typography.dina.caption};
    background-color: ${theme.palette.dina.chipBackground};
    color: ${theme.palette.dina.mediumEmphasis};
  `}
  &:hover {
    filter: brightness(130%);
  }
  height: 28px;
  border-radius: 16px;
`;

export const TextInput = styled(TextField)`
  justify-content: center;
  .MuiInput-underline:after {
    border-bottom: none !important;
  }
  .MuiInput-underline:before {
    border-bottom: none !important;
  }
  max-height: 36px;
  border: 1px solid transparent;
`;

export const StyledIconButton = styled(IconButton)`
  width: 38px;
  height: 36px;
  padding: 0;
  &:hover {
    background: ${({ theme }) => theme.palette.dina.blackHoverOverlay};
  }
`;

export const ButtonIcon = styled(Icon)`
  width: 20;
`;
