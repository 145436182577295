import { memo, useMemo } from 'react';
import styled from '@emotion/styled';
import { noop } from 'lodash';

import MemberLabel from 'components/addMember/MemberLabel';
import { FieldTypeEnum } from 'types/graphqlTypes';

import SingleChoice from './choice/SingleChoice';
import DateDefault from './date/DateDefault';
import { StyledCellText } from './timing/styled';
import TreeChoiceDefault from './treechoice/TreeChoiceDefault';
import type CellProps from './cellProps';

type Props = Pick<CellProps, 'fieldModel' | 'fieldSettings' | 'value' | 'disableEdit'> & {
  visible: boolean;
  setIsEditing: (val: boolean) => void;
};

const StyledStyledCellText = styled(StyledCellText)`
  text-align: left;
  padding: 0 0 0 4px;
  height: 100%;
  * {
    font-size: 11px !important;
  }

  .member-wrapper {
    height: 100% !important;
  }
`;

/**
 * Represents a LightWeight value cell for mdf fields that's by default rendered.
 *
 * This optimization is primarily born out of need to avoid attaching event listeners on the fly
 * to input fields or other similar editing workflows, cell components that are expensive to render
 * should be rendered by this component by default.
 */
function ValueOnlyCell({
  fieldModel,
  fieldSettings,
  disableEdit,
  value,
  visible,
  setIsEditing,
}: Readonly<Props>) {
  const { type } = fieldModel;

  const valueToShow = useMemo(() => {
    switch (type) {
      case FieldTypeEnum.treechoice: {
        const parsed = Array.isArray(value) ? (value as string[]) : [];
        return <TreeChoiceDefault onClick={noop} choice={parsed} variant="caption" />;
      }
      case FieldTypeEnum.user:
        return (
          <MemberLabel memberId={typeof value === 'string' ? value : ''} variant="grid" hideOpen />
        );
      case FieldTypeEnum.text:
      case FieldTypeEnum.number:
        return value as string;
      case FieldTypeEnum.choice: {
        return (
          <SingleChoice
            defaultFieldSettings={fieldSettings!}
            fieldModel={fieldModel}
            fieldSettings={fieldSettings}
            value={value}
          />
        );
      }
      case FieldTypeEnum.date: {
        return <DateDefault date={value as string} onClick={noop} variant="caption" />;
      }
      // These are not rendered by the value only cell because they are by
      // themselves lightweight enough.
      case FieldTypeEnum.subtype:
      case FieldTypeEnum.link:
      case FieldTypeEnum.relation:
      case FieldTypeEnum.multiplechoice:
      case FieldTypeEnum.checkbox:
      case FieldTypeEnum.timing:
        return <div>N/A</div>;
      default:
        return <div>Unknown field type</div>;
    }
  }, [value]);

  const toggleEdit = () => {
    setIsEditing(true);
  };

  return (
    <StyledStyledCellText
      onClick={toggleEdit}
      onFocus={toggleEdit}
      $hide={!visible}
      tabIndex={disableEdit ? -1 : 0}
    >
      {valueToShow}
    </StyledStyledCellText>
  );
}

export default memo(ValueOnlyCell);
