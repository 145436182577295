import { useCallback } from 'react';
import { ApolloCache, gql, useMutation } from '@apollo/client';
import { capitalize } from 'lodash';

import { getErrorMessage } from 'api/commonKeys';
import useToast from 'components/toast/useToast';
import { getAllPlatformsQuery } from 'operations/queryVariables/query-variables';
import { Scalars } from 'types';
import { PlatformDTOSettings } from 'types/graphqlTypes';
import useLogger from 'utils/useLogger';

import { GET_ALL_PLATFORMS, GetPlatformType } from './useGetAllPlatforms';

const CREATE_PLATFORM = gql`
  mutation CreatePlatformSettings($input: CreatePlatformSettingsInput) {
    createPlatformSettings(input: $input) {
      id
      title
      platformInfo {
        platform
        provider {
          id
          pagesEndpoint {
            id
            url
          }
          publishEndpoint {
            id
            url
          }
        }
        platformIcon
        accounts {
          accountUrl
          accountLogo
          accountTitle
          isStaticAccount
          isActive
        }
      }
    }
  }
`;

export const writePlatformToCache = (
  client: ApolloCache<unknown>,
  updatedPlatform: PlatformDTOSettings,
  logger: {
    log: (message: string) => void;
    info: (message: string) => void;
    warn: (message: string) => void;
    error: (label: string | Error, err?: Error) => void;
    debug: (message: string) => void;
  },
) => {
  try {
    const variables = getAllPlatformsQuery(false, true);
    const cachedPlatforms = client.readQuery<GetPlatformType>({
      query: GET_ALL_PLATFORMS,
      variables,
    });
    const platforms = cachedPlatforms?.getAllPlatforms ?? [];
    let newPlatforms: PlatformDTOSettings[] = [];
    const existingPlatform = platforms.findIndex((a) => a.id === updatedPlatform.id);
    if (existingPlatform >= 0) {
      newPlatforms = [...platforms];
      newPlatforms.splice(existingPlatform, 1, updatedPlatform);
    } else {
      newPlatforms = [updatedPlatform, ...platforms];
    }

    client.writeQuery<GetPlatformType>({
      query: GET_ALL_PLATFORMS,
      variables,
      data: {
        getAllPlatforms: newPlatforms,
      },
    });
  } catch (err) {
    logger.log(getErrorMessage(err, undefined));
  }
};

export type CreatePlatformSettingsInput = {
  id: Scalars['String'];
  service: Scalars['String'];
  provider: Scalars['String'];
  title: Scalars['String'];
};

interface CreatePlatform {
  createPlatformSettings: PlatformDTOSettings;
}

interface CreateInput {
  input: CreatePlatformSettingsInput;
}

interface CreatPlatformPayload {
  platform: string;
  provider: string;
  service: string;
  title: string;
}

export const useCreatePlatform = () => {
  const { toast, errorToast } = useToast();
  const [create] = useMutation<CreatePlatform, CreateInput>(CREATE_PLATFORM);
  const logger = useLogger('CreatePlatform');

  const createPlatform = useCallback(
    async (data: CreatPlatformPayload) => {
      const { platform, provider, title, service } = data;
      try {
        const result = await create({
          variables: {
            input: {
              id: platform,
              provider: provider,
              service: service,
              title: title,
            },
          },
          update: (proxy, mutationResult) => {
            toast({
              title: 'Created ' + platform,
              description: `${capitalize(platform)} created successfully`,
              type: 'success',
            });
            const newPlatform = mutationResult.data?.createPlatformSettings;
            if (newPlatform) {
              writePlatformToCache(proxy, newPlatform, logger);
            }
          },
          onError: (error: unknown) => {
            errorToast(error);
          },
        });
        return result.data?.createPlatformSettings;
      } catch (err) {
        logger.log(getErrorMessage(err, platform));
      }
    },
    [create, errorToast, logger, toast],
  );
  return { createPlatform };
};
