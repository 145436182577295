import React, { memo, useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import AutoCompleteBase from 'components/autoCompleteBase';
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import ChipGroup from 'components/editor/components/tags/components/chipGroup';
import styled from '@emotion/styled';
import InputField from 'components/inputField';
import { CheckboxWithLabel } from 'lib/checkbox';
import useInputEvents from 'hooks/useInputEvents';
import Picker from './components/picker';
import { useEditorContext } from 'components/editor/EditorContext';

const Checkbox = memo(CheckboxWithLabel);

export const SourceWrapper = styled('div')`
  margin-top: 16px;
`;

const InputComponent = memo(InputField);

const Input = ({ value: inputValue, onChange, readOnly, direction }) => {
  const initialValue = inputValue;
  const [value, setValue] = useState(initialValue);
  const { onHotKeys } = useEditorContext();

  const handleClear = useCallback(() => {
    onChange(undefined);
    setValue(undefined);
  }, [onChange]);

  const onUpdate = useCallback(
    (newValue) => {
      if (newValue === initialValue) setValue(initialValue);
      else {
        if (newValue === '') {
          handleClear();
          return;
        }

        const numberValue = Number(newValue);

        if (isNaN(numberValue)) {
          setValue(initialValue);
          return;
        }

        onChange(numberValue);
      }
    },
    [handleClear, initialValue, onChange],
  );

  const [inputRef, handleKeydown, handleBlur] = useInputEvents(
    onUpdate,
    value,
    initialValue,
    false,
    onHotKeys,
  );

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <InputComponent
      onChange={setValue}
      value={value}
      usage="editor"
      disabled={readOnly}
      label="DVR Hours"
      description="Add DVR Hours"
      inputRef={inputRef}
      onKeyDown={handleKeydown}
      onBlur={handleBlur}
      onClear={handleClear}
      direction={direction}
    />
  );
};

const MetaData = ({
  dvrHours,
  endTime,
  startTime,
  readOnly,
  updateMetadata,
  isCoverphoto,
  source,
  sourceOptions,
  direction,
}) => {
  /** combobox value setters */
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = useCallback(
    (newValue) => {
      const outputObject = {};
      outputObject.dvrHours = newValue;
      updateMetadata(outputObject);
    },
    [updateMetadata],
  );

  const handleCheckboxClick = useCallback(() => {
    if (readOnly) return;
    const outputObject = {};
    outputObject.isCoverphoto = !isCoverphoto;
    updateMetadata(outputObject);
  }, [isCoverphoto, readOnly, updateMetadata]);

  const handleChange = (event, newObj) => {
    event.preventDefault();
    if (newObj) {
      const isIncluded = source.find((opt) => opt.value === newObj.value);

      if (!isIncluded) {
        updateMetadata({ source: [...source, { id: newObj.id, value: newObj.value }] });
      }
      setInputValue('');
      setValue(null);
    }
  };

  const removeChip = (event, index) => {
    event.preventDefault();
    updateMetadata({ source: source.filter((_, pos) => pos !== index) });
  };

  const {
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    getClearProps,
    groupedOptions,
    getPopupIndicatorProps,
    popupOpen,
  } = useAutocomplete({
    id: 'source-box',
    options: sourceOptions,
    autoHighlight: true,
    getOptionLabel: (option) => option.value,
    value,
    onChange: handleChange,
    inputValue,
    onInputChange: (_, newValue) => setInputValue(newValue),
  });

  return (
    <>
      <SourceWrapper>
        <AutoCompleteBase
          content={source}
          value={inputValue}
          inputUsage="editor"
          placeholder="Start typing to find Source"
          getRootProps={getRootProps}
          getInputProps={getInputProps}
          getListboxProps={getListboxProps}
          getOptionProps={getOptionProps}
          getClearProps={getClearProps}
          groupedOptions={groupedOptions}
          getPopupIndicatorProps={getPopupIndicatorProps}
          popupOpen={popupOpen}
          readOnly={readOnly}
          direction={direction}
        />
        <ChipGroup list={source} removeChip={removeChip} />
      </SourceWrapper>

      <Picker
        dateTime={startTime}
        type="startTime"
        onOK={updateMetadata}
        description="Add Start Time"
        readOnly={readOnly}
        label="Start Time"
        direction={direction}
      />

      <Picker
        dateTime={endTime}
        type="endTime"
        onOK={updateMetadata}
        description="Add End Time"
        label="End Time"
        readOnly={readOnly}
        direction={direction}
      />

      <Input
        value={dvrHours}
        onChange={handleInputChange}
        readOnly={readOnly}
        direction={direction}
      />

      <Checkbox label="Lead Image" onCheckedChange={handleCheckboxClick} checked={isCoverphoto} />
    </>
  );
};

MetaData.propTypes = {
  dvrHours: PropTypes.string,
  endTime: PropTypes.string,
  startTime: PropTypes.string,
  readOnly: PropTypes.bool,
  updateMetadata: PropTypes.func,
  isCoverphoto: PropTypes.bool,
  source: PropTypes.arrayOf(PropTypes.object),
  sourceOptions: PropTypes.arrayOf(PropTypes.object),
};

MetaData.defaultProps = {
  dvrHours: undefined,
  endTime: undefined,
  startTime: undefined,
  readOnly: false,
  updateMetadata: () => {},
  isCoverphoto: false,
  source: [],
  sourceOptions: [],
};

export default memo(MetaData);
