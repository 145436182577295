import styled from '@emotion/styled';

import { Button, type ButtonProps } from 'components/buttons';
import LoadingIndicator from 'components/loadingIndicator';

import { IconButton } from './IconButton';

interface LoadingButtonProps extends ButtonProps {
  loading?: boolean;
  title?: string | React.ReactElement;
}

export const LoadingWrapper = styled('span')`
  position: relative;
  width: 24px;
  height: 24px;
`;

/** Button with loading indicator */
export function LoadingButton({
  loading = false,
  disabled = false,
  startIcon,
  children,
  ...rest
}: Readonly<LoadingButtonProps>) {
  return (
    <Button
      disabled={loading || disabled}
      startIcon={
        loading ? (
          <LoadingWrapper>
            <LoadingIndicator height={20} width={20} />
          </LoadingWrapper>
        ) : (
          startIcon
        )
      }
      {...rest}
    >
      {children}
    </Button>
  );
}

/** IconButton with loading indicator */
export function LoadingIconButton({
  loading = false,
  disabled = false,
  children,
  ...rest
}: Readonly<LoadingButtonProps>) {
  return (
    <IconButton disabled={loading || disabled} {...rest}>
      {loading ? (
        <LoadingWrapper>
          <LoadingIndicator height={20} width={20} />
        </LoadingWrapper>
      ) : (
        children
      )}
    </IconButton>
  );
}
