import { ApolloCache, useMutation } from '@apollo/client';

import { UPDATE_PLATFORM_WORKFLOW } from 'operations/mutations/updatePlatformWorkflow';
import GET_PLATFORMS from 'operations/queries/getPlatforms';
import { Platform } from 'types';
import { UpdatePlatformWorkflowInput, WorkflowSettings } from 'types/graphqlTypes';

export type UpdatePlatformWorkflowReturnType = {
  updatePlatformWorkflow: {
    mId: string;
    mRefId: string;
    mType: string;
    mTitle: string;
    workflowSettings: WorkflowSettings;
  }[];
};

const updateCache = (
  cache: ApolloCache<object>,
  { data: newData }: { data?: UpdatePlatformWorkflowReturnType | null },
) => {
  if (!newData?.updatePlatformWorkflow) return;

  const existingPlatforms = cache.readQuery<{ getPlatforms: Platform[] }>({
    query: GET_PLATFORMS,
  });

  if (!existingPlatforms?.getPlatforms) return;

  const updatedPlatforms = newData.updatePlatformWorkflow;

  const hasNewLinearPlatform = updatedPlatforms.find((platform) => platform.mRefId === 'linear');
  const linearPlatformAlreadyExists = existingPlatforms.getPlatforms.some(
    (platform) => platform.mRefId === 'linear',
  );

  const newPlatforms = existingPlatforms.getPlatforms.map((ePlatform) => {
    const updatedPlatform = updatedPlatforms.find(
      (uPlatform) => uPlatform.mRefId === ePlatform.mRefId,
    );

    return updatedPlatform ? { ...ePlatform, ...updatedPlatform } : ePlatform;
  });

  if (!linearPlatformAlreadyExists && hasNewLinearPlatform) {
    newPlatforms.push({
      ...hasNewLinearPlatform,
      mProperties: {
        platform: 'linear',
        platformIcon: 'linear',
        accounts: [],
        endpoints: [],
        __typename: 'PlatformType',
      },
    });
  }

  cache.writeQuery({
    query: GET_PLATFORMS,
    data: {
      getPlatforms: newPlatforms,
    },
  });
};

const useUpdatePlatformWorkflows = () => {
  const [mutation, { loading, data, error }] = useMutation<
    UpdatePlatformWorkflowReturnType,
    {
      input: UpdatePlatformWorkflowInput;
    }
  >(UPDATE_PLATFORM_WORKFLOW);

  const updatePlatformWorkflows = async ({
    updatedWorkflows,
  }: {
    updatedWorkflows: Record<string, WorkflowSettings>;
  }) => {
    try {
      const result = await mutation({
        variables: {
          input: {
            updatedWorkflows: JSON.stringify(updatedWorkflows),
          },
        },
        update: updateCache,
      });
      return result?.data?.updatePlatformWorkflow;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      return null;
    }
  };

  return { updatePlatformWorkflows, loading, data: data?.updatePlatformWorkflow, error };
};

export default useUpdatePlatformWorkflows;
