import { CustomEventData, JsonSerializable } from 'types/customChannel';

/**
 * A participant represents a user in a browser session (browser tab) that uses a shared resource.
 * @template T The type of the state of the shared resource
 */
export interface Participant<T> {
  /** The session ID of the participant */
  readonly sessionId: string;
  /** The Dina user ID of the user (`mID` of the `User`) */
  readonly userId: string;
  /** The time when the participant was last visible or NaN if visible */
  readonly hiddenTime: number;
  /** The lock owned by the client or `undefined` if no such lock */
  readonly lock: string | undefined;
  /** The current state of the participant */
  readonly state: T;
}

export interface EventBase {
  /** The type of the event */
  readonly type: string;
  /** An ID uniquely identifying the sender's session */
  readonly sourceId: string;
  /** Optional ID of the event to be specified if a received receipt is requested */
  readonly eventId?: string;
}

export interface ConnectEvent<T> extends EventBase {
  readonly type: 'connect' | 'connectionConfirmed' | 'requestConnection' | 'acceptConnection';
  readonly userId: string;
  readonly lock: string | undefined;
  readonly state: T;
}

/**
 * Receipt for unicast events.
 * Will be sent back for all unicast events except for the received event itself that requests
 * a receipt. Unicast events requests a receipt by specifying the `eventId` property of the event.
 */
export interface ReceivedEvent extends EventBase {
  readonly type: 'received';
  /** The ID of the event that was received (indicating that a received receipt is requested) */
  readonly eventId: string;
}

export interface UpdateEvent<T> extends EventBase {
  readonly type: 'update';
  readonly state: T;
}

export interface SimpleEvent extends EventBase {
  readonly type: 'disconnect' | 'connected' | 'heartBeat';
}

export interface LockingEvent extends EventBase {
  readonly type: 'locking';
  readonly lock: string | undefined;
}

/**
 * Event used to forward `CustomChannel` events.
 */
export interface CustomEvent extends EventBase {
  readonly type: 'custom';

  /** The type of custom event (as sent to the `CustomChannel`) */
  readonly customType: string;

  /** The data of the custom event */
  readonly customData?: JsonSerializable;

  /**
   * Whether the data was sent is a `Uint8Array` on the `CustomChannel`.
   * If so `customData` here will be a `string` (since `Uint8Array` is not serializable to JSON).
   * When putting back to the custom channel's event handler the data will be transformed to a
   * `Uint8Array`.
   */
  readonly dataIsUint8Array?: boolean;
}

export type SharingEvent<T> =
  | ConnectEvent<T>
  | UpdateEvent<T>
  | ReceivedEvent
  | SimpleEvent
  | LockingEvent
  | CustomEvent;

export type CustomEventHandler = (
  sourceSessionId: string,
  type: string,
  data?: CustomEventData,
) => void;

export type CustomEventHandlerMap = Record<string, CustomEventHandler[]>;
export type IsReadyHandlers = ((isReady: boolean) => void)[];

/**
 * The state ov a {@link CommunicationChannel}. Can take three values:
 * - {@link Disconnected}
 * - {@link Connecting}
 * - {@link Connected}
 */
export enum ChannelState {
  /** The channel is not connected */
  Disconnected = 0,

  /**
   * The channel is connecting (will be in this state from `connectAsync` has been
   * called until its promise resolves)
   */
  Connecting = 1,

  /** The channel has successfully connected */
  Connected = 2,
}

/**
 * A communication channel enabling peer-to-peer communication
 * @template T The type of the state on the channel (Must be the same for everyone using
 *             the `channelNamespace` used when creating the channel)
 */
export interface CommunicationChannel<T> {
  /** The {@link ChannelState} of this channel*/
  readonly state: ChannelState;

  /**
   * Send a message on the channel
   * @param targetId The ID of the target (or `null` for broadcast)
   * @param event    The {@link SharingEvent} to send
   */
  readonly send: (targetId: string | null, event: SharingEvent<T>) => void;

  /**
   * Asynchronously connects to the channel.
   * @param handler The event handler to use to handle events on the channel
   * @returns       A promise resolving to `true` if the connection was successful, and `false`
   *                otherwise.
   */
  readonly connectAsync: (handler: (event: SharingEvent<T>) => void) => Promise<boolean>;

  /**
   * Disconnects the channel. Can be called while in the connecting state.
   */
  readonly disconnect: () => void;
}

/**
 * Creates a communication channel
 * @param channelNamespace The namespace/ID of the channel
 * @param myId             The ID of the creator of the channel (Not a user ID since it needs to be
 *                         globally unique. If a user creates this channel in two browsers the two
 *                         channels must be distinguishable.)
 * @returns                A communication channel
 * @template T             The type of the state on the channel (Must be the same for everyone using
 *                         the `channelNamespace`)
 */
export type CommunicationChannelFactory<T> = (
  channelNamespace: string,
  myId: string,
) => CommunicationChannel<T>;
