import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';

export const Header = styled(Typography)`
  ${({ theme }) => theme.typography.dina.h5};
  margin: 16px;
`;

export const DialogContainer = styled('div')`
  width: 364px;
  background-color: ${({ theme }) => theme.palette.dina.surfaceCard};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 8px;
`;

export const Content = styled('div')`
  margin: 0 16px;
  flex-grow: 1;
`;

export const SelectLabel = styled('div')`
  ${({ theme }) => theme.typography.dina.overline};
  margin: 10px 0;
`;

export const LinearDateTimePicker = styled('div')`
  width: 100%;
  margin-top: 16px;
  margin-bottom: 16px;
`;

export const AccountInfo = styled('div')`
  height: 40px;
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
  align-items: center;
`;

export const AccountLabel = styled(Typography)`
  ${({ theme }) => css`
    ${theme.typography.dina.button};
    color: ${theme.palette.dina.mediumEmphasis};
  `}
  margin-left: 4px;
`;
