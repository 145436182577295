/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import path from 'path-browserify';
import { v4 as uuidv4 } from 'uuid';

import { AssetInput } from 'api/assets/useCreateAsset';
import { RenditionInput, RenditionVariantEnum } from 'types/graphqlTypes';

const SUBTITLE_MEDIA_TYPE = 'application/srt';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getAssetData: (storyId: string, data: any) => AssetInput = (storyId, data) => {
  const {
    id,
    itemType,
    mediaType,
    itemState,
    duration: mediaDuration,
    originalFileName: fileName,
    thumbnailUrl,
    proxy,
  } = data;

  const rendition = {
    mId: storyId,
    mRefId: id,
    source: thumbnailUrl,
    fileName,
    variant: 'thumbnail' as RenditionVariantEnum,
  };

  const renditions: RenditionInput[] = [];

  if (
    thumbnailUrl &&
    ((itemType?.includes('video') && mediaDuration > 0) || itemType?.includes('image'))
  ) {
    renditions.push(rendition);
  }

  // in case of image store both thumbnail and proxy.
  if (itemType === 'image' && proxy) {
    renditions.push({
      ...rendition,
      source: proxy,
      variant: 'main' as RenditionVariantEnum,
    });
  }
  /**
   * When an asset is drag and dropped irrespective to valid/placeholder, we get the following:
   * A valid Asset's mediaType looks like - 'image/jpeg'.
   * A placeholder Asset's mediaType looks like - 'image'.
   * For Consistency, mediaType should be- 'image/placeholder' (if it is a placeholder)
   * And mediaType should be as the same as valid asset's mediaType (for valid asset)
   *
   * @returns what will be the mediaType value.
   */
  const findMediaType = () => {
    if (mediaType && mediaType.includes('/')) return mediaType;

    // IBC hack. Work around for mimir bug not providing mediaType when uploading an asset.
    if ((mediaType === 'image' || !mediaType) && itemType === 'image' && itemState === 'complete') {
      return 'image/png';
    }

    if (!mediaType && ['video', 'image'].includes(itemType as string)) {
      return `${itemType as string}/placeholder`;
    }
    return `${mediaType as string}/placeholder`;
  };

  return {
    mId: storyId,
    mRefId: id,
    mediaType: mediaType.includes('graphics') ? mediaType : findMediaType(),
    renditions,
    data,
  };
};

const getFileAssetData = (storyId: string, file: File) => {
  const fileExtension = path.extname(file.name);
  let mediaType;

  if (file.type && file.type !== 'text/plain') mediaType = file.type;
  else if (!file.type && fileExtension.includes('srt')) mediaType = SUBTITLE_MEDIA_TYPE;
  else mediaType = `application/${fileExtension.replace('.', '')}`;

  const title =
    mediaType === SUBTITLE_MEDIA_TYPE ? file.name : path.basename(file.name, fileExtension);

  return {
    mId: storyId,
    mRefId: uuidv4(),
    mTitle: title,
    extension: fileExtension,
    mediaType,
    data: {},
    file,
  };
};

const findItemType = ({ type }: { type: string }) => {
  if (!type) return 'file';
  if (type.includes('application/mxf')) return 'video';

  return type.split('/')[0];
};

const findFileMimeType = ({
  name: fileName,
  type: fileMimeType,
}: {
  name: string;
  type: string;
}) => {
  if (!fileMimeType && fileName.includes('.srt')) return SUBTITLE_MEDIA_TYPE;
  return fileMimeType;
};

const findmRefId = (itemType: string, assetId: string) => {
  if (['file'].includes(itemType)) return;
  // eslint-disable-next-line consistent-return
  return assetId || undefined;
};

export { getFileAssetData, getAssetData, findItemType, findFileMimeType, findmRefId };
