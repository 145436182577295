import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const RootWrapper = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const TableHead = styled('div')`
  ${({ theme }) => css`
    ${theme.typography.dina.overline};
    border-bottom: 1px solid ${theme.palette.dina.dividerLight};
    background: ${theme.palette.dina.backgroundResting};
  `}
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: center;
`;

export const InstanceMetadataLabel = styled('div')`
  ${({ theme }) => theme.typography.dina.h7}
  width: 100%;
  margin-left: 16px;
`;
