import { gql } from '@apollo/client';

export default gql`
  mutation AddUserToOrganization($input: AddUserToOrgInput) {
    addUserToOrg(input: $input) {
      id
      type
      title
      description
      mdfId
      metadata
      createdAt
      createdById
      updatedAt
      updatedById
      groups
      isArchived
      isDisabled
      permissions
    }
  }
`;
