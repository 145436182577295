import gql from 'graphql-tag';

export const UPDATE_PLATFORM_WORKFLOW = gql`
  mutation UpdatePlatformWorkflow($input: UpdatePlatformWorkflowInput!) {
    updatePlatformWorkflow(input: $input) {
      mId
      mRefId
      mType
      mTitle
      workflowSettings {
        stateOnSchedule
        approvalState
        stateOnCopy
      }
    }
  }
`;
