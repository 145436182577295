import { useCallback } from 'react';
import { css, Theme } from '@emotion/react';
import styled from '@emotion/styled';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { isEqual } from 'lodash';

import { PlatformDTOSettings, ProviderDTO } from 'types/graphqlTypes';

import { useChangedPlatforms } from '../../../atomsTs';

import { PlatformRow } from './DisplayPlatformRow';

export const FieldHeader = styled('div')`
  ${({ theme }) => theme.typography.dina.overline}
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 1px;
  margin-bottom: 5px;
  user-select: none;
`;
export const Root = styled('div')`
  width: 100%;
`;
const CellStyle = ({ theme }: { theme: Theme }) => css`
  padding: 8px;
  height: 40px;
  border-bottom: 1px solid ${theme.palette.dina.dividerLight};
  ${theme.typography.dina.listItemLabel};
`;
export const HeaderCell = styled(TableCell)`
  ${CellStyle};
  ${({ theme }) => theme.typography.dina.overline};
  background-color: ${({ theme }) => theme.palette.dina.surfaceDialogs};
`;

interface Props {
  selectedPlatform: PlatformDTOSettings;
  providers: ProviderDTO[] | undefined;
}
export interface Column {
  id: string;
  name: string;
}
const columns: Column[] = [
  {
    id: 'platform',
    name: 'Platform',
  },
  {
    id: 'service',
    name: 'Service',
  },
  {
    id: 'provider',
    name: 'Provider',
  },
  {
    id: 'platformIcon',
    name: 'Platform Icon',
  },
  {
    id: 'title',
    name: 'Title',
  },
  {
    id: 'pagesEndpoint',
    name: 'PagesEndpoint',
  },
  {
    id: 'publishEndpoint',
    name: 'PublishEndpoint',
  },
  {
    id: 'accounts',
    name: 'Accounts',
  },
];

export function EditFieldPlatform({ selectedPlatform, providers }: Readonly<Props>) {
  const [changedPlatformAtom, setChangedPlatformAtom] = useChangedPlatforms();

  const onPlatformChange = useCallback(
    (platformUpdates: Partial<PlatformDTOSettings>, originalPlatform: PlatformDTOSettings) => {
      const updatedPlatform: PlatformDTOSettings = {
        ...originalPlatform,
        ...platformUpdates,
      };
      if (
        !changedPlatformAtom[originalPlatform.id] &&
        !isEqual(updatedPlatform, originalPlatform)
      ) {
        setChangedPlatformAtom((prevState) => {
          return {
            ...prevState,
            [originalPlatform.id]: updatedPlatform,
          };
        });
      } else if (changedPlatformAtom[originalPlatform.id]) {
        const updatedChangedPlatform: PlatformDTOSettings = {
          ...changedPlatformAtom[originalPlatform.id],
          ...platformUpdates,
        };
        if (isEqual(updatedChangedPlatform, originalPlatform)) {
          const copy = { ...changedPlatformAtom };
          delete copy[originalPlatform.id];
          setChangedPlatformAtom(copy);
        } else {
          setChangedPlatformAtom((prevState) => {
            return {
              ...prevState,
              [originalPlatform.id]: updatedChangedPlatform,
            };
          });
        }
      }
    },
    [changedPlatformAtom, setChangedPlatformAtom],
  );

  return (
    <Root>
      <Table aria-label="mdf-table">
        <TableHead key="mdf-table-head">
          <TableRow key="mdf-header">
            {columns.map((col) => (
              <HeaderCell key={col.id}>{col.name}</HeaderCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody key="studio-table-body">
          <PlatformRow
            key={selectedPlatform.id}
            platformData={selectedPlatform}
            providers={providers}
            onUpdatePlatform={(val) => onPlatformChange(val, selectedPlatform)}
          />
        </TableBody>
      </Table>
    </Root>
  );
}
