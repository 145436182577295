import { useMemo } from 'react';

import useDateComparisonUtils from './useDateComparisonUtils';
import useDateDifferenceUtils from './useDateDifferenceUtils';
import useDateFormattingUtils from './useDateFormattingUtils';
import useDateManipulationUtils from './useDateManipulationUtils';

const useDateTimeUtils = () => {
  const dateManipulationUtils = useDateManipulationUtils();
  const comparisonUtils = useDateComparisonUtils();
  const formattingUtils = useDateFormattingUtils();
  const differenceUtils = useDateDifferenceUtils();

  const memoizedUtils = useMemo(
    () => ({
      ...comparisonUtils,
      ...dateManipulationUtils,
      ...formattingUtils,
      ...differenceUtils,
    }),
    [comparisonUtils, dateManipulationUtils, formattingUtils, differenceUtils],
  );

  return memoizedUtils;
};

export default useDateTimeUtils;
