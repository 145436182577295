import { isArray } from 'lodash';

import { MMetaDataFieldInput } from 'types/graphqlTypes';

/**
 * Type guard function to verify wether an value of an unknown type is boolean
 * @param {unknown} value - The value to check.
 * @returns {value is boolean} - True if the value is a boolean, false otherwise.
 */
export const isBoolean = (value: unknown): value is boolean => {
  return typeof value === 'boolean';
};
/**
 * Type guard function to verify wether an value of an unknown type is a number
 * @param {unknown} value - The value to check.
 * @returns {value is number} - True if the value is a number, false otherwise.
 */
export const isNumber = (value: unknown): value is number => {
  return typeof value === 'number';
};
/**
 * Type guard function to verify wether an value of an unknown type is a string
 * @param {unknown} value - The value to check.
 * @returns {value is string} - True if the value is a string, false otherwise.
 */
export const isString = (value: unknown): value is string => {
  return typeof value === 'string';
};

/**
 * Simple type converter from string to boolean.
 * @param {string} value - The value to convert
 * @returns {boolean} true if value is 'true' (case-insensitive), false otherwise
 */
export const string2boolean = (value: string): boolean => {
  return value.toLowerCase() === 'true';
};

/**
 * Converts the given value to a number.
 * @param {unknown} value - The value to convert.
 * @param {number} defaultValue - The default value to return if conversion fails. Defaults to NaN.
 * @returns {number} - The converted number, or the default value if conversion fails.
 */
export const toNumber = (value: unknown, defaultValue: number = NaN): number => {
  if (value == null || isArray(value)) return defaultValue;
  if (isNumber(value)) return isNaN(value) ? defaultValue : value;
  if (isBoolean(value)) return value ? 1 : 0;
  const num = Number(value);
  return isNaN(num) ? defaultValue : num;
};

/**
 * Converts the given value to a boolean.
 * @param {unknown} value - The value to convert.
 * @param {boolean} defaultValue
 *   - The default value to return if conversion fails. Defaults to false.
 * @returns {boolean} - The converted boolean, or the default value if conversion fails.
 */
export const toBoolean = (value: unknown, defaultValue: boolean = false): boolean => {
  if (!value || isArray(value)) return defaultValue;
  if (isBoolean(value)) return value;
  if (isNumber(value)) return value !== 0;
  if (isString(value)) return string2boolean(value);
  return defaultValue;
};

/**
 * Retrieves the value of a metadata item by its key.
 *
 * Converts the value to according to the generic type.
 * If no matching key is found, or if the value is `undefined`,
 * the provided `defaultValue` is returned.
 *
 * @template T - The expected type of the returned value (`string`, `number` or `boolean`).
 * @param metadata - The array of metadata items to search.
 * @param key - The key of the metadata item to retrieve.
 * @param defaultValue - The value to return if the key is not found or the value is undefined.
 * @returns The value of the metadata item if found, otherwise the `defaultValue`.
 */
export const getMetadataValue = <T = string | number | boolean>(
  metadata: MMetaDataFieldInput[],
  key: string,
  defaultValue?: T,
): T | undefined => {
  const item = metadata.find((entry) => entry.key === key);
  if (!item?.value) return defaultValue;

  const value = item.value;

  // Convert to number if defaultValue is a number.
  if (typeof defaultValue === 'number') {
    return toNumber(value, defaultValue) as T;
  }
  // Convert to boolean if defaultValue is a boolean.
  if (typeof defaultValue === 'boolean') {
    return toBoolean(value, defaultValue) as T;
  }

  // Otherwise, assume the value is a string.
  return value as T;
};
