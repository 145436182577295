import { useInstancePreviewMolecule } from 'features/preview/components/instancePreviewContext';
import { useRundownMolecule } from 'screens/rundown/store/rundown';
import { useStoryPaneMolecule } from 'screens/storyV2/store/storyPane';

/**
 * Hook to get the scope of the script
 * This uses the same scope as the instance
 *
 * We'll move this to the instance directory in the future and directly use that
 *
 * @returns The scope of the script
 */
const useScriptScope = () => {
  const { scope: storyScope } = useStoryPaneMolecule();
  const { scope: previewScope } = useInstancePreviewMolecule();
  const { scope: rundownScope } = useRundownMolecule();

  if (storyScope) return storyScope;
  if (previewScope) return previewScope;
  if (rundownScope) return rundownScope;

  return 'script_scope';
};

export default useScriptScope;
