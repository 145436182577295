import { MemberType } from 'types/graphqlTypes';

/**
 * Determines whether a subscription update should be accepted based on the entity's update time
 * and the user who updated it.
 *
 * @template T - The type of the entity, extending MemberType.
 * @param {T | null} entity - The new entity to be compared.
 * @param {T | null} currentEntity - The current entity in the cache or local state.
 * @param {string} currentUserId - The ID of the current user.
 * @returns {boolean} - Returns true if the subscription update should be accepted, otherwise false.
 *
 * @remarks
 * - If the current user is the one who updated the entity and the new entity's update time is more
 *   recent, the subscription update should be accepted.
 * - If the current user is the one who updated the entity but the new entity's update time is the
 *   same or older, the subscription update should not be accepted.
 * - If the current user is not the one who updated the entity, the subscription update should be
 *   accepted.
 */
export const isSubscriptionUpdateNeeded = <T extends MemberType>(
  entity: T | null,
  currentEntity: T | null,
  currentUserId: string,
): boolean => {
  if (!entity) return true;

  const currentUpdatedTime = new Date(currentEntity?.mUpdatedAt ?? 0).toISOString();
  const newUpdatedTime = new Date(entity?.mUpdatedAt ?? 0).toISOString();

  /** If the current user is the one who updated the entity, we should compare the updated time */
  if (currentUserId === entity?.mUpdatedById) {
    /** The new update time is more recent */
    if (currentUpdatedTime < newUpdatedTime) return true;

    /** If the updated time is the same or older, we should not update the local state */
    return false;
  }

  /** If entity is updated by someone else, we should always accept the updated value */
  return true;
};
