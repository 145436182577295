import { useCallback } from 'react';
import { css, Theme } from '@emotion/react';
import styled from '@emotion/styled';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { isEqual } from 'lodash';

import { ProviderDTO } from 'types/graphqlTypes';

import { useChangedProviders } from '../../../atomsTs';

import { ProviderRow } from './DisplayProviderRow';

export const FieldHeader = styled('div')`
  ${({ theme }) => theme.typography.dina.overline}
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 1px;
  margin-bottom: 5px;
  user-select: none;
`;

export const Root = styled('div')`
  width: 100%;
`;

const CellStyle = ({ theme }: { theme: Theme }) => css`
  padding: 8px;
  height: 40px;
  border-bottom: 1px solid ${theme.palette.dina.dividerLight};
  ${theme.typography.dina.listItemLabel};
`;

export const HeaderCell = styled(TableCell)`
  ${CellStyle};
  ${({ theme }) => theme.typography.dina.overline};
  background-color: ${({ theme }) => theme.palette.dina.surfaceDialogs};
`;

interface Props {
  selectedProvider: ProviderDTO;
}
export interface Column {
  id: string;
  name: string;
}
const columns: Column[] = [
  {
    id: 'provider',
    name: 'Provider',
  },
  {
    id: 'title',
    name: 'Title',
  },
  {
    id: 'baseUrl',
    name: 'BaseUrl',
  },
  {
    id: 'auth',
    name: 'Auth',
  },
  {
    id: 'endpoints',
    name: 'EndPoints',
  },
];

export function EditFieldProvider({ selectedProvider }: Readonly<Props>) {
  const [changedProviderAtom, setChangedProviderAtom] = useChangedProviders();

  const onProviderChange = useCallback(
    (providerUpdates: Partial<ProviderDTO>, originalProvider: ProviderDTO) => {
      const updatedProvider: ProviderDTO = {
        ...originalProvider,
        ...providerUpdates,
      };
      if (
        !changedProviderAtom[originalProvider.id] &&
        !isEqual(updatedProvider, originalProvider)
      ) {
        setChangedProviderAtom((prevState) => {
          return {
            ...prevState,
            [originalProvider.id]: updatedProvider,
          };
        });
      } else if (changedProviderAtom[originalProvider.id]) {
        const updatedChangedProvider: ProviderDTO = {
          ...changedProviderAtom[originalProvider.id],
          ...providerUpdates,
        };
        if (isEqual(updatedChangedProvider, originalProvider)) {
          const copy = { ...changedProviderAtom };
          delete copy[originalProvider.id];
          setChangedProviderAtom(copy);
        } else {
          setChangedProviderAtom((prevState) => {
            return {
              ...prevState,
              [originalProvider.id]: updatedChangedProvider,
            };
          });
        }
      }
    },
    [changedProviderAtom, setChangedProviderAtom],
  );

  return (
    <Root>
      <Table aria-label="mdf-table">
        <TableHead key="mdf-table-head">
          <TableRow key="mdf-header">
            {columns.map((col) => (
              <HeaderCell key={col.id}>{col.name}</HeaderCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody key="studio-table-body">
          <ProviderRow
            key={selectedProvider.id}
            providerData={selectedProvider}
            onUpdateProvider={(val) => onProviderChange(val, selectedProvider)}
          />
        </TableBody>
      </Table>
    </Root>
  );
}
