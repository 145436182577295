import { memo, useCallback, useRef } from 'react';

import { getDefaultLayoutSettings } from 'components/editMdfDialog/utils';
import { useEditFieldValueDialog } from 'features/mdf/mdf-utils';

import type CellProps from '../cellProps';

import Link from './LinkDefault';

/**
 * Known issue with Link cell workflow - when clicking on URL it will open the URL but
 * also gain focus, and therefore bring up the edit dialog.
 *
 * To be fixed
 */
function LinkCell({
  fieldModel,
  fieldSettings,
  mdf,
  setValue,
  value,
  disableEdit,
  onBlur,
  onFocus,
}: Readonly<CellProps>) {
  const [, showEditFieldDialog] = useEditFieldValueDialog();
  const { hint } = fieldSettings ?? getDefaultLayoutSettings(fieldModel);
  const ref = useRef<HTMLDivElement | null>(null);

  const showFieldEditor = useCallback(() => {
    const onDone = () => {
      onBlur?.();
      // Timeout is needed because of race conditions
      // when user is clicking confirm button in dialog.
      setTimeout(() => {
        ref.current?.closest('td')?.focus();
      }, 100);
    };

    if (!disableEdit) {
      onFocus?.();
      showEditFieldDialog({
        startValue: value,
        fieldId: fieldModel.fieldId,
        headerText: `Edit ${fieldSettings?.label ?? fieldModel.fieldId}`,
        viewType: 'default',
        mdf,
        onConfirm: (v) => {
          setValue(v);
          onDone();
        },
        onCancel: () => {
          onDone();
        },
      });
    }
  }, [showEditFieldDialog, onFocus, disableEdit, mdf, fieldModel, fieldSettings, value]);

  const doOnFocus = useCallback(() => {
    showFieldEditor();
  }, [showFieldEditor]);

  return (
    <Link
      url={value as string}
      hint={hint}
      onEditClick={showFieldEditor}
      ref={ref}
      onFocus={doOnFocus}
    />
  );
}

export default memo(LinkCell);
