import { useCallback, useContext, useEffect, useMemo } from 'react';
import { OnDataOptions } from '@apollo/client';
import { ScopeProvider } from 'jotai-molecules';
import isEqual from 'lodash/isEqual';

import useGetMember from 'api/useGetMember';
import { useCollaboration } from 'components/collaboration';
import DebouncedLoadingComponent from 'components/debouncedLoadingIndicator/DebouncedLoadingIndicator';
import Download from 'components/download/Download';
import UserContext from 'contexts/UserContext';
import useCommentSubscription from 'features/comments/hooks/useCommentSubscription';
import InstancePrint from 'features/print/InstancePrint';
import Sidepanel, { SidepanelProps } from 'features/sidepanel/Sidepanel';
import { RightCollapsiblePane, RightCollapsiblePaneForStory } from 'features/splitView/SplitView';
import useApolloSubscription from 'hooks/useApolloSubscriptionV2';
import useResourceDetails from 'hooks/useResourceDetails';
import { VStack } from 'layouts/box/Box';
import NOTIFY_MEMBER_UPDATE_SUBSCRIPTION from 'operations/subscriptions/notifyMemberUpdate';
import { EditorFontSize, Instance } from 'types';
import { MemberType } from 'types/graphqlTypes';

import Body from './components/Body';
import DNDProvider from './components/DNDProvider';
import Footer from './components/Footer';
import Header from './components/Header';
import useInstanceAssignees from './hooks/useInstanceAssignees';
import useInstanceCore from './hooks/useInstanceCore';
import useInstancePermissions from './hooks/useInstancePermissions';
import { InstanceScope, useInstanceMolecule } from './store/instance';
import { propertiesToMapIntoCache } from './utils';

import { StyledPaper } from './styled';

interface Props {
  instance: Instance;
  hideTemplateOptions?: boolean;
  onClose?: () => void;
  onOpen?: () => void;
  autoUpdate?: boolean;
  editorFontSize?: EditorFontSize;
  isSearchItem?: boolean;
  showComment?: boolean;
  updatePane?: () => void;
}

interface SubscriptionEvent {
  notifyMemberUpdateSubscription: MemberType;
}

const fieldsMapper = (item: MemberType) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const mapper: Record<string, any> = {};
  propertiesToMapIntoCache.forEach((property) => {
    mapper[property] = () => item[property] ?? null;
  });
  return mapper;
};

const InstanceComponent = ({
  instance,
  hideTemplateOptions,
  onOpen,
  onClose,
  autoUpdate,
  isSearchItem,
  editorFontSize,
  showComment,
  updatePane,
}: Readonly<Props>) => {
  const { mId: currentUserId } = useContext(UserContext);
  const {
    instanceRef,
    useSetInstance,
    useInstanceValue,
    useHideTemplateOptions,
    useDurations,
    useDownloadData,
    usePrintDialog,
    useEditorFontSize,
    beforeUnloadRef,
    editorValueRef,
  } = useInstanceMolecule();
  const {
    addLockReleasedOnSaveHandler,
    updateLock,
    handleEditorUpdate,
    handleLockInstance,
    onDone,
    handleCancel,
    onForceUnlock,
    handleClipDurationChange,
    handleScriptDurationChange,
    onCreateDuplicate,
    onInstanceChanged,
    onDeleteInstance,
    onMetadataChanged,
    saveAll,
    cancelDebounce,
    resetEditorValue,
    updateRelatedMembersMutation,
    placeholderConfigs,
    lockedByUser,
    lockedByCurrentUser,
    twitterThreadCount,
    wordCount,
    locking,
    lockedBy,
    writeLock,
    readLock,
    editorValue,
    updatingRelatedMembers,
    loading,
  } = useInstanceCore();

  const { canUpdateInstance } = useInstancePermissions();
  const { assignMemberToInstance } = useInstanceAssignees();
  const setInstance = useSetInstance();
  const [, setHideTemplateOptions] = useHideTemplateOptions();
  const instanceValue = useInstanceValue();
  const [downloadData, setDownloadData] = useDownloadData();
  const [showPrintDialog, setShowPrintDialog] = usePrintDialog();
  const [, setEditorFontSize] = useEditorFontSize();
  const [durations] = useDurations();

  const lockAsync = useCallback(
    async (lockTime: string) => (await handleLockInstance(lockTime)) ?? null,
    [currentUserId, handleLockInstance],
  );
  const { editorInfo, lockBarInfo } = useCollaboration('instance', instance?.mRefId ?? '', {
    lockedBy,
    locking,
    writeLock,
    lockAsync,
    addLockReleasedOnSaveHandler,
    collaborationFeature: 'collaborative-instance-editing',
  });
  useEffect(() => {
    const triggerOnBeforeUnload = () => {
      beforeUnloadRef.current?.(instanceRef.current, editorValueRef.current).catch(() => {});
    };
    window.addEventListener('beforeunload', triggerOnBeforeUnload);

    return () => {
      triggerOnBeforeUnload();

      window.removeEventListener('beforeunload', triggerOnBeforeUnload);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instance?.mId]);

  const handleAddDocumentToClipBoard = useCallback(async () => {
    const clipBoardData = {
      mTitle: instance?.mTitle,
      instanceId: instance?.mId,
      storyId: instance?.mStoryId,
      s3Data: { ...editorValue },
      instanceData: { ...instance },
    };

    await navigator.clipboard.writeText(JSON.stringify(clipBoardData, null, 2));
  }, [editorValue, instance]);

  const onKeyDown = (ev: React.KeyboardEvent<HTMLDivElement>) => {
    if ((ev.metaKey || ev.ctrlKey) && ev.shiftKey && ev.key === 'S') {
      void handleAddDocumentToClipBoard();
    }
  };

  const onDownloadClose = useCallback(() => {
    setDownloadData(null);
  }, [setDownloadData]);

  const { data } = useGetMember<Instance>({
    mId: instance?.mId,
    mRefId: instance?.mRefId,
    fetchPolicy: 'cache-and-network',
    type: 'instance',
  });

  useCommentSubscription([instance?.mId]);
  useApolloSubscription(NOTIFY_MEMBER_UPDATE_SUBSCRIPTION, {
    variables: {
      mIdSubscribed: instance?.mStoryId ?? data?.mStoryId ?? '',
    },
    skip: !autoUpdate,
    onSubscriptionData: ({ data: subscriptionData, client }: OnDataOptions<SubscriptionEvent>) => {
      if (!subscriptionData.data) return;

      client.cache.modify({
        id: client.cache.identify({
          mId: instance.mId,
          mRefId: instance.mRefId,
          __typename: isSearchItem ? 'SearchItem' : 'MemberType',
        }),
        fields: fieldsMapper(subscriptionData.data.notifyMemberUpdateSubscription ?? {}),
      });
    },
    source: 'instanceView',
  });

  useEffect(() => {
    if (!autoUpdate && !isEqual(instanceValue, instance)) {
      updateLock(instance?.locked);
      setInstance(instance);
      instanceRef.current = instance;
    }
  }, [instance, instanceValue, setInstance, instanceRef, autoUpdate, updateLock]);

  useEffect(() => {
    if (autoUpdate && data && !isEqual(instanceValue, data)) {
      updateLock(data.locked);
      setInstance(data);
      instanceRef.current = data;
    }
  }, [autoUpdate, data, instanceRef, instanceValue, setInstance, updateLock]);

  useEffect(() => {
    setHideTemplateOptions(!!hideTemplateOptions);
  }, [hideTemplateOptions, setHideTemplateOptions]);

  useEffect(() => {
    if (editorFontSize) {
      setEditorFontSize(editorFontSize);
    }
  }, [editorFontSize, setEditorFontSize]);

  return (
    <DNDProvider
      canUpdateInstance={canUpdateInstance}
      instance={instance}
      handleUserDrop={assignMemberToInstance}
      storyId={instance.mStoryId}
      updateRelatedMembersMutation={updateRelatedMembersMutation}
    >
      <VStack flex="1">
        <StyledPaper $writeLock={writeLock} $readLock={readLock} onKeyDown={onKeyDown}>
          <DebouncedLoadingComponent isLoading={loading || updatingRelatedMembers} />
          <Header
            lockedByCurrentUser={lockedByCurrentUser}
            updateLock={updateLock}
            saveAll={saveAll}
            cancelDebounce={cancelDebounce}
            handleLockInstance={handleLockInstance}
            resetEditorValue={resetEditorValue}
            onMetadataChanged={onMetadataChanged}
            readLock={readLock}
            writeLock={writeLock}
            onOpen={onOpen}
            onClose={onClose}
            showComment={showComment}
            updatePane={updatePane}
            onInstanceChanged={onInstanceChanged}
            onDeleteInstance={onDeleteInstance}
            lockedByUser={lockedByUser}
            editorValue={editorValue}
            onForceUnlock={onForceUnlock}
            onCreateDuplicate={onCreateDuplicate}
          />
          <Body
            placeholderConfigs={placeholderConfigs}
            editorValue={editorValue}
            loading={loading}
            handleEditorUpdate={handleEditorUpdate}
            handleLockInstance={handleLockInstance}
            onDone={onDone}
            collaborationInfo={editorInfo}
          />
          <Footer
            collaborationInfo={lockBarInfo}
            onDone={onDone}
            handleCancel={handleCancel}
            onForceUnlock={onForceUnlock}
            handleClipDurationChange={handleClipDurationChange}
            handleScriptDurationChange={handleScriptDurationChange}
            onInstanceChanged={onInstanceChanged}
            twitterThreadCount={twitterThreadCount}
            wordCount={wordCount}
          />
          {!!downloadData && <Download download={downloadData} onClose={onDownloadClose} />}
          {showPrintDialog && (
            <InstancePrint
              instance={instance}
              isDialogOpen={showPrintDialog}
              onCloseDialog={() => setShowPrintDialog(false)}
              clipDuration={durations.clip}
              scriptDuration={durations.script}
              totalDuration={durations.total}
            />
          )}
        </StyledPaper>
      </VStack>
    </DNDProvider>
  );
};

/* Single instance when sidepanel is not needed */
function InstanceRoot({
  instance,
  hideTemplateOptions,
  autoUpdate = false,
  isSearchItem = false,
  ...rest
}: Readonly<Props>) {
  return (
    <ScopeProvider scope={InstanceScope} value={`${instance.mId}${autoUpdate && ':preview'}`}>
      <InstanceComponent
        autoUpdate={autoUpdate}
        isSearchItem={isSearchItem}
        instance={instance}
        hideTemplateOptions={hideTemplateOptions}
        {...rest}
      />
    </ScopeProvider>
  );
}

/* Single instance when right collapsible pane needed */
export function InstanceWithRightCollapsiblePane({
  storyPaneIndex,
  instance,
  hideTemplateOptions,
  autoUpdate = false,
  isSearchItem = false,
  view = 'story',
  sidepanelTab,
  sidepanelSelectedBlockId,
  sidepanelShowComment,
  updatePane,
  toggle,
  setToggle,
  ...rest
}: Readonly<
  Props &
    SidepanelProps & {
      storyPaneIndex?: number;
      view?: 'story' | 'rundown' | 'preview';
      updatePane?: () => void;
      toggle?: boolean;
      setToggle?: (value: boolean) => void;
    }
>) {
  const resourceDetails = useResourceDetails({
    resource: instance,
  });

  const CollapsiblePane = useMemo(
    () => (view === 'story' ? RightCollapsiblePaneForStory : RightCollapsiblePane),
    [view],
  );

  return (
    <ScopeProvider
      scope={InstanceScope}
      value={`${instance.mId}${
        storyPaneIndex !== undefined ? ':' + storyPaneIndex.toString() : ''
      }${autoUpdate ? ':preview' : ''}`}
    >
      <CollapsiblePane
        view={view}
        pane1={
          <InstanceComponent
            autoUpdate={autoUpdate}
            isSearchItem={isSearchItem}
            instance={instance}
            hideTemplateOptions={hideTemplateOptions}
            showComment={!sidepanelSelectedBlockId && sidepanelShowComment}
            updatePane={updatePane}
            {...rest}
          />
        }
        pane2={
          resourceDetails ? (
            <Sidepanel resourceDetails={resourceDetails} updatePane={updatePane} />
          ) : null
        }
        sidepanelTab={sidepanelTab}
        sidepanelSelectedBlockId={sidepanelSelectedBlockId}
        sidepanelShowComment={sidepanelShowComment}
        toggle={toggle}
        setToggle={setToggle}
      />
    </ScopeProvider>
  );
}

export default InstanceRoot;
