import { ApolloClient } from '@apollo/client';

import AUDIT_FAILURE from 'operations/mutations/auditFailure';

type ErrorLogData = {
  message: string;
  code?: string | number; // Make code optional since it's optional in CustomError
  stack?: string;
  componentStack?: string | null;
  origin: string;
};

const auditFailure = async (client: ApolloClient<object>, eobj: ErrorLogData, timeStamp: Date) => {
  const source = (import.meta.env.REACT_APP_REPO_NAME as string) || '7m-frontend';
  const branchName = (import.meta.env.REACT_APP_REPO_BRANCHNAME || import.meta.env.MODE) as string;
  const commitId = (import.meta.env.REPO_COMMIT_ID as string) || '';

  const dinaVersion = (import.meta.env.REACT_APP_VERSION as string) || 'v0.0.0';
  // const dinaVersionDate =  import.meta.env.REACT_APP_VERSION_DATE || new Date().toISOString();
  const { code, message, stack, origin } = eobj;

  try {
    const input = {
      mActionId: 'failure',
      mId: `${source}`,
      mType: source,
      mTimestamp: timeStamp.toISOString(),
      mPayload: {
        __typename: 'AuditFailureType',
        version: dinaVersion,
        origin,
        error: {
          message,
          code,
          stack,
        },
        source: {
          repository: source,
          branchName,
          commitId,
        },
      },
    };
    const resp = await client.mutate({
      mutation: AUDIT_FAILURE,
      variables: { input },
    });
    // eslint-disable-next-line no-console
    console.error('auditFailure:', JSON.stringify(resp, null, 2));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('auditFailure:', err);
  }
};

// eslint-disable-next-line import/prefer-default-export
export { auditFailure };
