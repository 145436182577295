import { v4 } from 'uuid';

const SESSION_ID_KEY = 'dinaSessionId';

function buildVolatileSessionId(uuid: string) {
  return `volatile-${uuid}`;
}

/**
 * Check if a session ID is volatile (i.e. not persistent)
 * @param sessionId The session ID to check (returned from {@link getDinaSessionId})
 */
export function isVolatileSessionId(sessionId: string) {
  return sessionId.startsWith('volatile-');
}

/**
 * Try to get a session ID from sessionStorage. If there is none, create a new one and store
 * it in the session storage.
 * If the session storage is unavailable, a volatile session ID will be returned.
 * @returns The session ID
 */
function acquireSessionId(userId: string) {
  try {
    const key = `${SESSION_ID_KEY}-${userId}`;
    const storedSessionId = sessionStorage.getItem(key);
    if (storedSessionId) return storedSessionId;
    const newSessionId = v4();
    sessionStorage.setItem(key, newSessionId);
    return newSessionId;
  } catch {
    return buildVolatileSessionId(v4());
  }
}

const DINA_SESSION_IDS: Record<string, string> = {};

/**
 * Gets a session ID for the provided user ID.
 * @param userId The user ID to get a session ID for. (We need the user ID so that we don't reuse
 *               a session if someone reuses a browser tab and signs in as a different user)
 * @returns      The session ID
 */
export function getDinaSessionId(userId: string) {
  if (userId in DINA_SESSION_IDS) return DINA_SESSION_IDS[userId];
  const sessionId = acquireSessionId(userId);
  DINA_SESSION_IDS[userId] = sessionId;
  return sessionId;
}
