import { ChangeEvent, useCallback, useMemo } from 'react';
import styled from '@emotion/styled';
import { atom, useAtom } from 'jotai';
import { keyBy } from 'lodash';

import AvatarGroup from 'components/assignees/AvatarGroup';
import { useAuthContext } from 'contexts/AuthContext';
import useUserEnrichedItems from 'hooks/useEnrichedUsers';
import useSharedResource, {
  getSessionUser,
  getUserIdFromParticipant,
} from 'hooks/useSharedResource';
import { TextInput } from 'screens/feeds/components/search/styled';
import { User } from 'types';

import { CollaborativeEditor } from './CollaborativeEditor';

const myContentAtom = atom('initial');
const useTestContent = () => useAtom(myContentAtom);

const myRoomAtom = atom('hall');
const useRoomContent = () => useAtom(myRoomAtom);

const Container = styled('div')`
  height: 100%;
`;

const UpperPane = styled('div')`
  height: 50%;
  box-sizing: border-box;
  padding: 8px;
  color: white;
`;

const LowerPane = styled('div')`
  height: 50%;
  box-sizing: border-box;
  padding: 8px;
  color: white;
  background-color: #000080;
  display: flex;
  flex-direction: column;
  & > :not(:last-child) {
    flex: 0;
  }
  & > :last-child {
    flex: 1;
  }
`;

const SelfHeader = styled('div')`
  margin: 8px;
  padding-bottom: 8px;
`;

const OtherList = styled('ul')`
  list-style-type: disc !important;
  padding: 8px 8px 8px 20px !important;
  li {
  }
`;

function getUserName(keyedUsers: Readonly<Record<string, User>>, userId: string) {
  const user = keyedUsers[userId];
  return user?.mTitle ?? userId;
}

const TestPage = () => {
  const context = useAuthContext();
  const [text, setText] = useTestContent();
  const [room, setRoom] = useRoomContent();
  const { configured, others, updateState, customChannel } = useSharedResource(
    `default/test-${room}`,
    {
      initialState: text,
    },
  );
  const otherUsers = useUserEnrichedItems(others, getUserIdFromParticipant, getSessionUser);
  const keyedUsers = useMemo(() => keyBy(otherUsers, 'mId'), [otherUsers]);

  const onTextChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setText(e.target.value);
      updateState(e.target.value, 500);
    },
    [setText, updateState],
  );

  const onRoomChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setRoom(e.target.value);
    },
    [setRoom],
  );

  if (!configured) return <div />;

  return (
    <Container>
      <UpperPane>
        <SelfHeader>
          {context.user?.username ?? ''} ({context.user?.dinaUserId}):
        </SelfHeader>
        <TextInput
          value={room}
          onChange={onRoomChange}
          variant="filled"
          size="small"
          label="Room"
          style={{ width: '100%', marginBottom: '20px' }}
        />
        <TextInput
          label="State"
          value={text}
          onChange={onTextChange}
          variant="filled"
          size="small"
          style={{ width: '100%', marginBottom: '4px' }}
        />
        <AvatarGroup users={otherUsers} useUserTitleAsTooltip />
        <OtherList>
          {Object.entries(others)
            .toSorted((a, b) => a[1].userId.localeCompare(b[1].userId))
            .map(([id, other]) => (
              <li key={id}>
                <b>{getUserName(keyedUsers, other.userId)}:</b> {other.state}
              </li>
            ))}
        </OtherList>
      </UpperPane>
      <LowerPane>
        <div>Collaborative Editor in {room}</div>
        <div>
          <CollaborativeEditor key={room} customChannel={customChannel} />
        </div>
      </LowerPane>
    </Container>
  );
};

export default TestPage;
