import { isMiniMemberArray } from 'components/mdfEditor/fields/relation/relation-utils';
import { FieldValue, MemberType } from 'types/graphqlTypes';

export const getMembersFromRelationField = (
  fieldValue: FieldValue,
  relationMembers: MemberType[],
): MemberType[] => {
  if (!isMiniMemberArray(fieldValue)) return [];

  const stringifiedFieldValue: string[] = fieldValue?.map(
    (aFieldValue) => aFieldValue.connectedId ?? aFieldValue.id,
  );
  const relationsMap = new Map<string, MemberType>();

  relationMembers.forEach((rMember) => {
    if (rMember?.mId) relationsMap.set(rMember.mId, rMember);
  });

  const fieldMembers = stringifiedFieldValue.reduce((acc, cur) => {
    const found = relationsMap.get(cur);
    if (found) acc.push(found);

    return acc;
  }, [] as MemberType[]);

  return fieldMembers;
};

export default getMembersFromRelationField;
