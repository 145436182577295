import RadioButton from 'components/buttons/radioButton';

import scheduleOptions from '../../utils/scheduleOptions';

import { RadioButtonWrapper, RadioLabel, Subtitle, Wrapper } from './styled';

const { UNPUBLISH, UPDATE, REPUBLISH, UNSCHEDULE, SCHEDULE } = scheduleOptions;

interface RadioButtonWithLabelProps {
  onClick: () => void;
  selected: boolean;
  label: string;
  subtitle?: string;
}

function RadioButtonWithLabel({ onClick, selected, label, subtitle }: RadioButtonWithLabelProps) {
  return (
    <RadioButtonWrapper role="presentation" onClick={onClick}>
      <RadioButton selected={selected} size={22} />
      <RadioLabel>{label}</RadioLabel>
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
    </RadioButtonWrapper>
  );
}

interface RepublishRadioButtonsProps {
  selectedOption: string;
  onSelectOption: (option: string) => void;
}

function RepublishRadioButtons({ selectedOption, onSelectOption }: RepublishRadioButtonsProps) {
  return (
    <Wrapper $showBorderBottom>
      <RadioButtonWithLabel
        selected={selectedOption === UPDATE}
        label="Update"
        onClick={() => onSelectOption(UPDATE)}
      />
      <RadioButtonWithLabel
        selected={selectedOption === REPUBLISH}
        label="Re-publish"
        onClick={() => onSelectOption(REPUBLISH)}
        subtitle="Publish to front page"
      />
      <RadioButtonWithLabel
        selected={selectedOption === UNPUBLISH}
        label="Unpublish"
        onClick={() => onSelectOption(UNPUBLISH)}
      />
    </Wrapper>
  );
}

interface ScheduleRadioButtonsProps {
  selectedOption: string;
  onSelectOption: (option: string) => void;
  setConfirmPublish: (confirm: boolean) => void;
}

function ScheduleRadioButtons({
  selectedOption,
  onSelectOption,
  setConfirmPublish,
}: ScheduleRadioButtonsProps) {
  return (
    <Wrapper>
      <RadioButtonWithLabel
        selected={selectedOption === UNSCHEDULE}
        label="Unschedule"
        onClick={() => {
          onSelectOption(UNSCHEDULE);
          setConfirmPublish(false);
        }}
      />
      <RadioButtonWithLabel
        selected={selectedOption === SCHEDULE}
        label="Schedule or publish"
        onClick={() => onSelectOption(SCHEDULE)}
      />
    </Wrapper>
  );
}

interface RadioButtonsProps {
  isCmsRepublish: boolean;
  selectedOption: string;
  onSelectOption: (option: string) => void;
  setConfirmPublish: (confirm: boolean) => void;
}

function RadioButtons({
  isCmsRepublish,
  selectedOption,
  onSelectOption,
  setConfirmPublish,
}: RadioButtonsProps) {
  return isCmsRepublish ? (
    <RepublishRadioButtons selectedOption={selectedOption} onSelectOption={onSelectOption} />
  ) : (
    <ScheduleRadioButtons
      selectedOption={selectedOption}
      onSelectOption={onSelectOption}
      setConfirmPublish={setConfirmPublish}
    />
  );
}

export default RadioButtons;
