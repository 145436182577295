import { useCallback } from 'react';

import { useSelectedInstances } from 'screens/rundown/store/screen';
import { useRundownToolbarButtons } from 'screens/rundown/store/toolbar';

const useSelectRundownInstance = () => {
  const [, setSelectedIds] = useSelectedInstances();
  const [rundownToolbarButtons, setRundownToolbarButtons] = useRundownToolbarButtons();

  const selectRundownInstance = useCallback(
    (instanceIds: string[]) => {
      setSelectedIds(instanceIds);
      setRundownToolbarButtons({
        ...rundownToolbarButtons,
        editor: true,
      });
    },
    [rundownToolbarButtons],
  );

  return { selectRundownInstance };
};

export default useSelectRundownInstance;
