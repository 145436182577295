import { TZDate } from '@date-fns/tz';

export type DateType = Date | string | number;
export type TimezoneConverter = (date: DateType) => TZDate;

export const toTimeZoneDate: (timezone: string) => TimezoneConverter =
  (timezone: string) => (date: DateType) => {
    if (typeof date === 'string') return new TZDate(date, timezone);
    if (typeof date === 'number') return new TZDate(date, timezone);
    return new TZDate(date, timezone);
  };
