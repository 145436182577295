/**
 * This module contains various functions and constants connected to types that are not types
 * themselves.
 * This module should not be included in `index.ts` since that is likely to cause circular
 * dependencies.
 * @module
 */
import { type MemberType, MemberTypeEnum } from './graphqlTypes';
import type {
  AssignedMember,
  ItemAggregations,
  MdfId,
  MId,
  SupportedMetadataTypes,
} from './members';

export const MTypeToMdfId: Record<SupportedMetadataTypes, MdfId> = {
  [MemberTypeEnum.Contact]: 'contact-mdf',
  [MemberTypeEnum.Rundown]: 'rundown-mdf',
  [MemberTypeEnum.Story]: 'story-mdf',
  [MemberTypeEnum.ResStory]: 'story-mdf',
  [MemberTypeEnum.ArchivedStory]: 'story-mdf',
  [MemberTypeEnum.ArchivedResStory]: 'story-mdf',
  [MemberTypeEnum.Pitch]: 'pitch-mdf',
  [MemberTypeEnum.ResPitch]: 'pitch-mdf',
  [MemberTypeEnum.ArchivedPitch]: 'pitch-mdf',
  [MemberTypeEnum.ArchivedResPitch]: 'pitch-mdf',
  [MemberTypeEnum.User]: 'user-mdf',
};

export const MdfIds = ['story-mdf', 'rundown-mdf', 'user-mdf', 'contact-mdf', 'pitch-mdf'] as const;

export const isString = (payload: unknown): payload is string => {
  return typeof payload === 'string';
};

export const isObject = (obj: unknown): obj is object => {
  return typeof obj === 'object';
};

export const hasMID = (obj: unknown): obj is MId => {
  return isObject(obj) && typeof (obj as Partial<MId>).mId == 'string';
};

export const isAssignedMember = (obj: unknown): obj is AssignedMember => {
  if (!isObject(obj)) return false;
  const probablyMember = obj as Partial<AssignedMember>;
  const { mType, mTitle, mId } = probablyMember;
  return (
    mType === ('user' as MemberTypeEnum) && typeof mTitle === 'string' && typeof mId === 'string'
  );
};

export const isMemberType = (obj: unknown): obj is MemberType => {
  if (!isObject(obj)) return false;

  return (
    ('__typename' in obj && obj.__typename === 'MemberType') ||
    ('mType' in obj && Object.values(MemberTypeEnum).includes(obj.mType as MemberTypeEnum))
  );
};

export const parseItemAggregations = (aggregations?: string): ItemAggregations => {
  if (!aggregations) return {};
  try {
    return JSON.parse(aggregations) as ItemAggregations;
  } catch {
    return {};
  }
};
