import { AccessTypeEnum, Permission, ResourcePolicy } from 'types/graphqlTypes';

const mergePermissions = (
  existingPermissions: Permission[],
  newPermissions: Permission[],
  resourceName: string,
  initialPolicies: ResourcePolicy[],
): Permission[] => {
  const permissionMap: Record<string, { visited: boolean; permission: Permission }> = {};
  const initialResource = initialPolicies.find((p) => p.resourceName === resourceName);

  existingPermissions.forEach((permission) => {
    if (!permission?.action) return;
    permissionMap[permission.action] = { visited: false, permission: { ...permission } };
  });

  newPermissions.forEach((permission) => {
    if (!permission?.action) return;
    const isFeatureResource = resourceName === 'feature';
    const initialAccess = initialResource?.permissions?.find(
      ({ action }) => action === permission.action,
    )?.access;

    // present in second not in first
    if (!permissionMap[permission.action]) {
      // add as first
      permissionMap[permission.action] = {
        visited: false,
        permission: isFeatureResource
          ? { ...permission }
          : { ...permission, access: initialAccess ?? AccessTypeEnum.Allow },
      };
    }

    const existingPermission = permissionMap[permission.action].permission;
    permissionMap[permission.action] = {
      visited: true,
      permission:
        permission.access !== existingPermission.access
          ? { ...permission, access: AccessTypeEnum.Allow }
          : { ...permission },
    };
  });

  return Object.values(permissionMap).map(({ visited, permission }) => {
    if (resourceName === 'feature' || visited) return permission;
    const initialAccess = initialResource?.permissions?.find(
      (p) => p.action === permission.action,
    )?.access;

    // present in first not in second
    return {
      ...permission,
      access:
        !initialAccess || initialAccess !== permission.access
          ? AccessTypeEnum.Allow
          : permission.access,
    };
  });
};

const mergePoliciesWithInitial = (
  mergedPolicies: ResourcePolicy[],
  initialPolicies: ResourcePolicy[],
): ResourcePolicy[] => {
  const result = [...mergedPolicies];

  initialPolicies.forEach((initialPolicy) => {
    const existingPolicy = result.find(
      (policy) => policy.resourceName === initialPolicy.resourceName,
    );

    if (!existingPolicy) {
      result.push(initialPolicy);
      return;
    }

    initialPolicy.permissions?.forEach((permission) => {
      if (!existingPolicy.permissions?.some((p) => p.action === permission.action)) {
        existingPolicy.permissions = existingPolicy?.permissions?.length
          ? [...(existingPolicy?.permissions ?? []), permission]
          : [permission];
      }
    });
  });

  return result;
};

const mergeUserRights = (
  initialPolicies: ResourcePolicy[] = [],
  groupPolicies: ResourcePolicy[] = [],
  groupsLength: number = 1,
): ResourcePolicy[] => {
  const mergedResources: Record<string, { resourceVisited: number; resource: ResourcePolicy }> = {};

  groupPolicies.forEach((policy) => {
    if (!policy) return;

    const existingResource = mergedResources[policy.resourceName];
    if (!existingResource) {
      mergedResources[policy.resourceName] = {
        resourceVisited: 1,
        resource: { ...policy },
      };
      return;
    }

    existingResource.resourceVisited += 1;
    existingResource.resource.permissions = mergePermissions(
      existingResource.resource.permissions || [],
      policy.permissions || [],
      policy.resourceName,
      initialPolicies,
    );
  });

  const mergedPolicies = Object.values(mergedResources).map(({ resourceVisited, resource }) => {
    if (resourceVisited === groupsLength) return resource;

    const initialResource = initialPolicies.find((p) => p.resourceName === resource.resourceName);

    return {
      ...resource,
      permissions: resource.permissions?.map(({ action, access }) => {
        if (resource.resourceName === 'feature') return { action, access };

        const initialAccess = initialResource?.permissions?.find(
          (p) => p.action === action,
        )?.access;

        return {
          action,
          access: !initialAccess || initialAccess !== access ? AccessTypeEnum.Allow : access,
        };
      }),
    };
  });

  return mergePoliciesWithInitial(mergedPolicies, initialPolicies);
};

export default mergeUserRights;
