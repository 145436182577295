import { blockTypes } from 'components/editor/utils/oldEditor/types';
import version from '../constants/version';

/**
 * Converts previous slate value to new format
 *
 * @param {Object} value SlateJS v0.47 value
 * @param {Object} properties Properties to set on editor content
 * @returns {Object} SlateJS v0.50 value
 */

const migrateValue = (value, properties) => {
  const { document, properties: props = {}, metadata } = value;
  const { nodes, data } = document;
  return {
    document: nodes.flatMap(convertNode),
    version,
    ...data,
    ...(Array.isArray(metadata) && { metadata }),
    properties: props,
    ...properties,
  };
};

const convertMarks = (marks) =>
  marks.reduce((accumulator, mark) => {
    const { type, data } = mark;

    return {
      ...accumulator,
      [type]: data?.[type] || true,
    };
  }, {});

const convertNode = ({ type, data, text, marks, nodes }) => {
  const isAutomationNode = data?.automationType;

  const secondaryNodes =
    isAutomationNode &&
    nodes.filter((node) => node.data && node.data.automationType === 'SECONDARY');

  const isTextNode = !type;

  const node =
    type === blockTypes.LIST_ITEM_CHILD
      ? convertNode(nodes[0])
      : {
          ...(type && { type }),
          ...(data && { data }),
          ...(isTextNode && { text }),
          ...(marks && convertMarks(marks)),
          ...(nodes && {
            children: isAutomationNode ? [{ text: '' }] : nodes.map(convertNode),
          }),
        };

  return secondaryNodes && secondaryNodes.length > 0
    ? [node, ...secondaryNodes.map(convertNode)]
    : node;
};

export default migrateValue;
