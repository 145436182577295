import { lazy, Suspense } from 'react';

import LoadingIndicator from 'components/loadingIndicator/LoadingIndicator';
import useDinaNavigate from 'hooks/useDinaNavigate';
import { HStack, VStack } from 'layouts/box/Box';
import { Content } from 'store';
import { Story } from 'types';
import { MemberType } from 'types/graphqlTypes';
import { isPitch as checkIsPitch } from 'utils/member/mTypes';

import type { PreviewProps } from '../utils/childMap';

import { StoryPitchHeader } from './Headers';
import { GetStoryPolicies } from './utils';

const StoryView = lazy(() => import('screens/storyV2/StoryV2'));

const WIDTH = '1100px';

export default function StoryDrawer({
  member,
  onClose,
}: Readonly<PreviewProps & { member: MemberType }>) {
  const { navigateTo } = useDinaNavigate();
  const { canUpdateStory, canUpdatePitch, canCreateStory } = GetStoryPolicies();

  const handleOpenStory = () => {
    if (!member?.mId) return;
    onClose();
    navigateTo(member.mType as Content, member.mId);
  };

  const isPitch = checkIsPitch(member?.mType ?? '');
  const canUpdatePublishing = isPitch ? canUpdatePitch : canUpdateStory;

  return (
    <VStack maxWidth={WIDTH} width={WIDTH} height="100%">
      <StoryPitchHeader
        onClose={onClose}
        member={member as Story}
        canCreateStory={isPitch && canCreateStory}
        canUpdatePublishing={canUpdatePublishing}
        onOpen={handleOpenStory}
        isPitch={isPitch}
      />
      <HStack alignItems="stretch" height="100%" width="100%">
        <Suspense fallback={<LoadingIndicator />}>
          <StoryView storyId={member?.mId} />
        </Suspense>
      </HStack>
    </VStack>
  );
}
