import { useCallback, useEffect, useRef } from 'react';
import { useAtom } from 'jotai';

import { newMembersAtom } from 'store/create';
import { MemberType } from 'types/graphqlTypes';

const THIRTY_SEC_IN_MS = 30 * 1000;

export default function useNewItems() {
  const [newMembers, setNewMembers] = useAtom(newMembersAtom);
  const timeoutsRef = useRef<{ [key: string]: NodeJS.Timeout }>({});

  // Function to remove an object
  const removeItem = useCallback(
    (data: MemberType) => {
      setNewMembers((pre) => pre.filter((member) => member.mId !== data.mId));

      // Clear the timeout for this object
      if (timeoutsRef.current[data.mId!]) {
        clearTimeout(timeoutsRef.current[data.mId!]);
        delete timeoutsRef.current[data.mId!];
      }
    },
    [setNewMembers],
  );

  // Function to add a new object
  const addItem = useCallback(
    (data: MemberType) => {
      setNewMembers((pre) => {
        const existingMember = pre.find((member) => member.mId === data.mId);
        const newMember = existingMember ? { ...existingMember, ...data } : data;
        return [newMember, ...pre.filter((member) => member.mId !== data.mId)];
      });

      // Set a timeout to remove the object after 30 seconds
      timeoutsRef.current[data.mId!] = setTimeout(() => {
        removeItem(data);
      }, THIRTY_SEC_IN_MS);
    },
    [removeItem, setNewMembers],
  );

  // Clear all timeouts when the component unmounts
  useEffect(() => {
    return () => {
      Object.values(timeoutsRef.current).forEach(clearTimeout);
    };
  }, []);

  return { newItems: newMembers, addItem, removeItem };
}
