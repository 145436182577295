import React, { useEffect, useState } from 'react';
import uuidv1 from 'uuid/v1';
import { capitalize } from 'lodash';
import { DialogActions, DialogContent } from '@material-ui/core';
import InputField from 'components/inputField/InputField';
import { Button } from 'components/buttons';

import {
  StyledDialogTitle,
  StyledDialog,
  StyledConfirmButtonWrapper,
  StyledCancelButtonWrapper,
} from '../styled';

const createNewField = () => ({
  id: uuidv1(),
  type: 'boolean',
  name: '',
  label: 'New field',
  description: '',
});

function NewFieldDialog({ onNewField, fields, open, setOpen }) {
  const [id, setId] = useState('');
  const [severity, setSeverity] = useState('');
  const [description, setDescription] = useState('');

  const existingFieldIds = fields.map((f) => f.id);

  const onCreate = () => {
    const field = createNewField();
    field.id = id;
    field.label = capitalize(id);
    onNewField(field);
    setOpen(false);
  };

  useEffect(() => {
    if (!open) {
      setId('');
    }
  }, [open, setId]);

  useEffect(() => {
    if (!id) {
      setSeverity('error');
      setDescription('Id is required');
    } else if (existingFieldIds.includes(id)) {
      setSeverity('error');
      setDescription('Id already exists');
    } else {
      setSeverity('');
      setDescription("Programmatic id - Can't be changed after creating the field");
    }
  }, [id, existingFieldIds]);

  return (
    <StyledDialog open={open} onClose={() => setOpen(false)}>
      <StyledDialogTitle>Create new field</StyledDialogTitle>
      <DialogContent>
        <InputField
          severity={severity}
          placeholder="Enter a unique id"
          description={description}
          value={id}
          disableLabel
          usage="editor"
          hideEndAdornment
          onChange={setId}
        />
        <div>Note - id must be unique across all forms</div>
      </DialogContent>
      <DialogActions>
        <StyledCancelButtonWrapper>
          <Button onClick={() => setOpen(false)} variant="outlined" usage="outlined">
            Cancel
          </Button>
        </StyledCancelButtonWrapper>
        <StyledConfirmButtonWrapper>
          <Button disabled={severity === 'error'} onClick={() => onCreate()}>
            Create
          </Button>
        </StyledConfirmButtonWrapper>
      </DialogActions>
    </StyledDialog>
  );
}

export default NewFieldDialog;
