import { EMPTY_ARRAY } from 'utils/arrayUtils';
import { focusInputOfRelatedListItem, getEnclosingListItem } from 'utils/dom/ulTree';

import { TreeItem } from './treeUtils';

function getTreeNodeIndexPath(
  treeElement: HTMLUListElement | null,
  node: HTMLLIElement | null,
): readonly number[] {
  let parent = node?.parentElement;
  if (!treeElement || parent?.tagName !== 'UL') return EMPTY_ARRAY;
  const result: number[] = [];
  do {
    const siblings = parent.children;
    const index = Array.from(siblings).indexOf(node as HTMLLIElement);
    if (index < 0) return EMPTY_ARRAY;
    result.unshift(index);
    if (parent === treeElement) break;
    node = getEnclosingListItem(parent);
    parent = node?.parentElement;
  } while (parent?.tagName === 'UL');
  return parent === treeElement ? Object.freeze(result) : EMPTY_ARRAY;
}

function getPath(items: readonly TreeItem[], indexPath: readonly number[]) {
  const result: string[] = [];
  for (const index of indexPath) {
    if (index < 0 || index >= items.length) return EMPTY_ARRAY;
    const item = items[index];
    result.push(item.id);
    items = item.children ?? EMPTY_ARRAY;
  }
  return result;
}

function lookupIndexPath(items: readonly TreeItem[], indexPath: readonly number[]) {
  let result: TreeItem | null = null;
  for (const index of indexPath) {
    if (index < 0 || index >= items.length) return null;
    result = items[index];
    items = result.children ?? EMPTY_ARRAY;
  }
  return result;
}

export function handleRename<T extends TreeItem>(
  e: React.KeyboardEvent<HTMLUListElement>,
  items: readonly T[],
  ul: HTMLUListElement | null,
  onRenameNode: ((path: readonly string[]) => void) | undefined,
  updateSelectionPath: (path: readonly string[]) => void,
) {
  if (!onRenameNode) return;
  const targetListItem = e.target instanceof HTMLElement ? getEnclosingListItem(e.target) : null;
  const path = getPath(items, getTreeNodeIndexPath(ul, targetListItem));
  if (path.length) {
    e.preventDefault();
    updateSelectionPath(path);
    focusInputOfRelatedListItem(targetListItem, (li) => li, false, 'button');
    onRenameNode(path);
  }
}

export function handleDelete<T extends TreeItem>(
  e: React.KeyboardEvent<HTMLUListElement>,
  items: readonly T[],
  ul: HTMLUListElement | null,
  onDeleteNode: ((path: readonly string[]) => void) | undefined,
) {
  if (!onDeleteNode) return;
  const targetListItem = e.target instanceof HTMLElement ? getEnclosingListItem(e.target) : null;
  const path = getPath(items, getTreeNodeIndexPath(ul, targetListItem));
  if (path.length) {
    e.preventDefault();
    onDeleteNode(path);
  }
}

export function handleAddChildNode<T extends TreeItem>(
  e: React.KeyboardEvent<HTMLUListElement>,
  items: readonly T[],
  ul: HTMLUListElement | null,
  onAddChildNode: ((parentPath: readonly string[]) => void) | undefined,
) {
  if (!onAddChildNode) return;
  const targetListItem = e.target instanceof HTMLElement ? getEnclosingListItem(e.target) : null;
  const indexPath = getTreeNodeIndexPath(ul, targetListItem);
  const treeItem = lookupIndexPath(items, indexPath);
  e.preventDefault();
  if (treeItem?.children) {
    onAddChildNode(getPath(items, indexPath));
  }
}
