/* eslint-disable no-nested-ternary */
import React, { useRef, useState } from 'react';
import { Fade, PopoverActions } from '@material-ui/core';

import { ReactComponent as CalendarOff } from 'assets/icons/systemicons/calendar_off.svg';
import { ReactComponent as CalendarOn } from 'assets/icons/systemicons/calendar_on.svg';
import Divider from 'components/divider';
import LoadingIndicator from 'components/loadingIndicator/LoadingIndicator';
import CategorizedMenu from 'components/menu/createInstanceMenu/MenuItem/CategorizedMenu';
import Popover from 'components/popover';
import Select, { type SelectProps } from 'components/select/Select';
import { PublishSettingsInputType } from 'features/instance/hooks/useInstancePublishing';
import useDateTimeUtils from 'hooks/useDateTimeUtils';
import { Destination, PlatformAccount } from 'types';
import { MMetaDataField } from 'types/graphqlTypes';

import Footer from './components/footer/Footer';
import Picker from './components/picker/Picker';
import RadioButtons from './components/radioButtons/RadioButtons';
import ScheduleOptions from './components/scheduleOptions/ScheduleOptions';
import { ResetTextButton } from './components/scheduleOptions/styled';
import scheduleOptions from './utils/scheduleOptions';
import { usePublishingState } from './usePublishingState';

import {
  AccountInfo,
  AccountLabel,
  Content,
  DialogContainer,
  Header,
  LinearDateTimePicker,
  SelectLabel,
} from './styled';

const { UNPUBLISH, UNSCHEDULE } = scheduleOptions;

interface PublishSettingsProps {
  variant: string;
  publishingPointIcon: string;
  canRepublishInstance: boolean;
  selectedDestination: Destination | null;
  publishMetadata: MMetaDataField[];
  onOk: (newPublishingSettings: PublishSettingsInputType) => Promise<void>;
  onCancel: () => void;
  updateInstanceMetadata: (newMetadata: MMetaDataField[]) => Promise<void>;
  anchorEl?: EventTarget | null;
  isCmsBlock?: boolean;
  publishingPoint?: string;
  publishingTime?: string;
  expiryTime?: string;
  platformKind?: string;
}

interface Item extends Destination {
  icon: React.ReactNode;
}

interface AccountSelectProps {
  isLinear: boolean;
  items?: Item[];
  selectedValue: string;
  onChange: (value: string) => void;
  publishingAt?: string;
  children?: React.ReactNode;
}

const AccountSelect: React.FC<AccountSelectProps> = ({
  isLinear,
  items = [],
  selectedValue,
  onChange,
  publishingAt,
  children,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const actionRef = useRef<PopoverActions | undefined>(undefined);

  if (!items) return null;

  if (!isLinear) {
    return (
      <Select
        items={items as SelectProps['items']}
        hideLabel
        selectedValue={selectedValue}
        onChange={onChange as SelectProps['onChange']}
      />
    );
  }

  return (
    <>
      <div
        role="presentation"
        onClick={(event: React.MouseEvent<HTMLDivElement>) => setAnchorEl(event.currentTarget)}
      >
        <Select
          open={false}
          items={items as SelectProps['items']}
          hideLabel
          selectedValue={selectedValue}
          onChange={onChange as SelectProps['onChange']}
        />
      </div>
      <Popover
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        action={actionRef}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div style={{ width: '332px' }}>
          <CategorizedMenu
            hideDatePicker
            publishingAt={publishingAt || ''}
            items={items.reduce((acc: PlatformAccount[], item) => {
              if (item.account) {
                acc.push(item.account as PlatformAccount);
              }
              return acc;
            }, [])}
            updateOnLoading={
              actionRef?.current?.updatePosition
                ? () => actionRef?.current?.updatePosition()
                : undefined
            }
            onClose={(
              account: PlatformAccount & {
                platformKind?: string;
                publishingAt?: string;
              },
            ) => {
              setAnchorEl(null);
              onChange(account.accountId || account.accountTitle || '');
            }}
            selectedValue={selectedValue}
            dense
            darker
          >
            {children}
          </CategorizedMenu>
        </div>
      </Popover>
    </>
  );
};

const PublishSettings = ({
  variant,
  publishingPointIcon,
  canRepublishInstance,
  selectedDestination,
  publishMetadata,
  onOk,
  onCancel,
  updateInstanceMetadata,
  anchorEl,
  isCmsBlock,
  publishingPoint,
  publishingTime,
  expiryTime,
  platformKind,
}: PublishSettingsProps) => {
  const { isThisMinute } = useDateTimeUtils();
  const {
    forcePublish,
    newPublishingTime,
    showExpiryDate,
    newExpiryTime,
    selectedOption,
    publishNow,
    confirmPublish,
    selectedValue,
    error,
    loading,
    destinations,
    shouldDisablePublish,
    isLinear,
    isCmsRepublish,
    PlatformIcon,
    isApprovalOn,
    hasAccessToApprovalState,
    toggleShowExpiryDate,
    handleForcePublishClick,
    onResetDateTime,
    handleOK,
    handleDateChange,
    handleTimeChange,
    handleExpiryDateChange,
    handleExpiryTimeChange,
    onDestinationChange,
    setSelectedOption,
    setConfirmPublish,
  } = usePublishingState({
    variant,
    publishingPoint,
    platformKind,
    selectedDestination,
    publishingTime,
    expiryTime,
    publishMetadata,
    updateInstanceMetadata,
    canRepublishInstance,
    anchorEl,
    isCmsBlock,
    publishingPointIcon,
    onOk,
  });

  const items = loading
    ? []
    : destinations.map((item) => ({
        ...item,
        icon: <PlatformIcon />,
      }));

  if (error) return <div>{error.message}</div>;

  return (
    <Popover
      anchorEl={anchorEl}
      position="publish-setting-custom"
      onClose={onCancel}
      TransitionComponent={Fade}
      noMargin
    >
      <DialogContainer>
        <Header>Schedule Settings</Header>
        <Divider />
        <Content>
          {loading && <LoadingIndicator />}
          <SelectLabel>Account</SelectLabel>
          {isCmsRepublish ? (
            <AccountInfo>
              <PlatformIcon />
              <AccountLabel>{selectedDestination?.value}</AccountLabel>
            </AccountInfo>
          ) : (
            <AccountSelect
              items={items}
              isLinear={isLinear}
              selectedValue={selectedValue || ''}
              onChange={onDestinationChange}
              publishingAt={newPublishingTime}
            >
              <PlatformIcon />
            </AccountSelect>
          )}
          {!isLinear && (
            <RadioButtons
              selectedOption={selectedOption}
              onSelectOption={setSelectedOption}
              setConfirmPublish={setConfirmPublish}
              isCmsRepublish={isCmsRepublish}
            />
          )}

          {!isLinear && selectedOption !== UNSCHEDULE && selectedOption !== UNPUBLISH && (
            <ScheduleOptions
              toggleShowExpiryDate={toggleShowExpiryDate}
              publishingTime={publishingTime}
              newPublishingTime={newPublishingTime}
              handleDateChange={handleDateChange}
              isLinear={isLinear}
              handleTimeChange={handleTimeChange}
              expiryTime={expiryTime}
              newExpiryTime={newExpiryTime}
              handleExpiryDateChange={handleExpiryDateChange}
              handleExpiryTimeChange={handleExpiryTimeChange}
              handleForcePublishClick={handleForcePublishClick}
              forcePublish={forcePublish}
              isCmsBlock={!!isCmsBlock}
              showExpiryDate={showExpiryDate}
              onResetDateTime={onResetDateTime}
              publishNow={publishNow}
              isCmsRepublish={isCmsRepublish}
              isApprovalOn={isApprovalOn}
              hasAccessToApprovalState={hasAccessToApprovalState}
            />
          )}

          {isLinear && (
            <LinearDateTimePicker>
              <Picker
                Icon={CalendarOff}
                SecondaryIcon={CalendarOn}
                displaySecondaryIcon={!!(publishingTime || !isThisMinute(newPublishingTime))}
                type="date"
                label="Publish Date"
                timeValue={newPublishingTime}
                onChange={handleDateChange}
                disableScheduleDateInPast
              />
              <ResetTextButton onClick={onResetDateTime} $disabled={publishNow}>
                Set to Today
              </ResetTextButton>
            </LinearDateTimePicker>
          )}
        </Content>
        <Divider />
        <Footer
          selectedOption={selectedOption}
          handleCancel={onCancel}
          isLinear={isLinear}
          handleOK={handleOK}
          publishNow={publishNow}
          confirmPublish={confirmPublish}
          shouldDisablePublish={shouldDisablePublish}
          isApprovalOn={isApprovalOn}
          hasAccessToApprovalState={hasAccessToApprovalState}
        />
      </DialogContainer>
    </Popover>
  );
};

export default PublishSettings;
