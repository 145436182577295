import { useContext, useMemo } from 'react';

import { useGetMdfs } from 'api/mdf/useGetMdfs';
import { useGetOptionLists } from 'api/optionLists/useGetOptionLists';
import Dialog from 'components/dialog';
import LoadingIndicator from 'components/loadingIndicator';
import PdfViewer from 'components/pdfViewer/PdfViewer';
import UserContext from 'contexts/UserContext';
import { OrderWithLabel } from 'features/sidepanel/ComponentUtils';
import { useGetOrdersForEntities } from 'screens/space/api/useGetOrdersAndForms';
import { GetOrderEnum, TaskStatusEnum } from 'types/graphqlTypes';

import ErrorView from './components/ErrorView';
import TasksPrintDoc from './docs/TasksPrintDoc';
import useGetContactsAndRelationMembers from './hooks/useGetContactsAndRelationMembers';
import useGetOrdersWithMdf from './hooks/useGetOrdersWithMdf';

import { LoadingWrapper, PrintViewWrapper } from './styled';

interface Props {
  isDialogOpen: boolean;
  onCloseDialog: () => void;
  tasks: OrderWithLabel[];
}

const View = ({ tasks }: Pick<Props, 'tasks'>) => {
  const { groups } = useContext(UserContext);
  const { mdfs, mdfsSeparated, loading, error } = useGetMdfs();

  const mIds = useMemo(() => tasks.map((task) => task.mId), [tasks]);

  const {
    orders,
    loading: ordersLoading,
    error: ordersError,
  } = useGetOrdersForEntities(mIds, GetOrderEnum.Resource, TaskStatusEnum.all);

  const { ordersWithMdf: tasksWithMdf } = useGetOrdersWithMdf(tasks, mdfs, mdfsSeparated);
  const { ordersWithMdf: subTasksWithMdf } = useGetOrdersWithMdf(orders, mdfs, mdfsSeparated);

  const {
    relationMembers,
    relationsError,
    relationsLoading,
    contacts,
    contactsError,
    contactsLoading,
  } = useGetContactsAndRelationMembers([], tasksWithMdf, subTasksWithMdf, mdfsSeparated);

  const { optionLists, loading: optionsLoading, error: optionsError } = useGetOptionLists();

  if (loading || ordersLoading || relationsLoading || contactsLoading || optionsLoading)
    return (
      <LoadingWrapper>
        <LoadingIndicator />
      </LoadingWrapper>
    );

  if (error || ordersError || relationsError || contactsError || optionsError) {
    const errors = {
      error: error ?? null,
      ordersError: ordersError ?? null,
      relationsError: relationsError ?? null,
      contactsError: contactsError ?? null,
      optionsError: optionsError ?? null,
    };

    return <ErrorView errors={errors} />;
  }
  return (
    <PrintViewWrapper>
      <PdfViewer>
        <TasksPrintDoc
          tasks={tasksWithMdf}
          mdfsSeparated={mdfsSeparated}
          subTasks={subTasksWithMdf}
          relationMembers={relationMembers}
          contacts={contacts}
          groups={groups}
          optionLists={optionLists}
        />
      </PdfViewer>
    </PrintViewWrapper>
  );
};

const TasksPrint = ({ isDialogOpen, onCloseDialog, tasks }: Props) => {
  return (
    <Dialog container={undefined} open={isDialogOpen} onClose={onCloseDialog}>
      <View tasks={tasks} />
    </Dialog>
  );
};

export default TasksPrint;
