const legends = {
  story: {
    popoverTitle: 'Create New Story',
    placeholder: 'Type Story title here...',
    title: 'Story Title',
  },
  user: {
    popoverTitle: 'Create New Story',
    placeholder: 'Type Story title here...',
    title: 'Story Title',
  },
  team: {
    popoverTitle: 'Create New Story',
    placeholder: 'Type Story title here...',
    title: 'Story Title',
  },
  department: {
    popoverTitle: 'Create New Story',
    placeholder: 'Type Story title here...',
    title: 'Story Title',
  },
  rundown: {
    popoverTitle: 'This Rundown does not exist. Create a new Rundown?',
    placeholder: 'Type Rundown title here...',
    title: 'Rundown Title',
  },
  rundownInstance: {
    popoverTitle: 'Create New Instance',
    placeholder: 'Type Instance title here...',
    title: 'Instance Title',
  },
  masterRundown: {
    popoverTitle: 'Create New Rundown Template',
    placeholder: 'Type Rundown title here...',
    title: 'Rundown Template Title',
  },
  feedFilter: {
    popoverTitle: 'Save current filters as a new shortcut',
    placeholder: 'Write a label for the filter here ...',
    title: 'Filter label',
  },
  pitch: {
    popoverTitle: 'Create New Pitch',
    placeholder: 'Type Pitch title here...',
    title: 'Pitch Title',
  },
  placeholder: {
    popoverTitle: 'Create Placeholder',
    placeholder: 'Enter a name for the placeholder',
    title: 'Placeholder title',
  },
};

export default legends;
