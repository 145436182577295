import { memo, useMemo } from 'react';
import { css, Theme } from '@emotion/react';
import styled from '@emotion/styled';

import {
  Select,
  SelectContent,
  SelectItem,
  SelectPortal,
  SelectTrigger,
  SelectValue,
} from 'lib/select';
import { ColorVariants } from 'types';
import { InstanceStateType } from 'types/graphqlTypes';

const Trigger = styled(SelectTrigger)<{ $backgroundColor: string }>`
  display: flex;
  align-items: center;
  background: transparent;
  padding: 6px 12px;
  border-radius: 8px;
  font-size: 14px;
  height: 32px;
  cursor: pointer;
  outline: none;
  border: 2px solid
    ${({ $backgroundColor, theme }) => theme.palette.dina[$backgroundColor as keyof ColorVariants]};
  color: ${({ $backgroundColor, theme }) =>
    theme.palette.dina[$backgroundColor as keyof ColorVariants]};

  > svg {
    width: 16px;
    height: 16px;
  }

  &:hover {
    background: ${({ theme }) => theme.palette.dina.surfaceCardDark};
  }
`;

const Content = styled(SelectContent)<{ $backgroundColor: string }>`
  background: ${({ theme }) => theme.palette.dina.surfaceCardDark};
  border: 2px solid
    ${({ $backgroundColor, theme }) => theme.palette.dina[$backgroundColor as keyof ColorVariants]};
  border-radius: 8px;
  padding: 4px 0;
`;

const itemStyles = (theme: Theme) => css`
  padding: 8px 12px;
  cursor: pointer;

  &:hover {
    background: ${theme.palette.dina.blackHoverOverlay};
  }
`;

interface StatusSelectProps {
  value: string;
  onChange: (value: string) => void;
  options: InstanceStateType[];
}

const StatusSelect = ({ value, onChange, options }: StatusSelectProps) => {
  const selected = useMemo(() => options.find((option) => option.id === value), [options, value]);

  return (
    <Select value={value} onValueChange={onChange}>
      <Trigger $backgroundColor={selected?.backgroundColor ?? ''}>
        <SelectValue placeholder={value} />
      </Trigger>
      <SelectPortal>
        <Content $backgroundColor={selected?.backgroundColor ?? ''}>
          {options.map((option) => (
            <SelectItem key={option.id} value={option.id} css={itemStyles}>
              {option.name}
            </SelectItem>
          ))}
        </Content>
      </SelectPortal>
    </Select>
  );
};

export default memo(StatusSelect);
