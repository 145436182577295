import { useCallback, useContext, useMemo } from 'react';

import ConfigContext from 'contexts/configContext';
import returnStates from 'utils/statusBoards/returnStates';

import { useScriptState } from '../store/state';

const useScriptStates = () => {
  const { kanbanBoardStates = [], kanbanBoardViews = [] } = useContext(ConfigContext);
  const { mState, updateMState } = useScriptState();

  const states = useMemo(
    () => returnStates('status-linear', kanbanBoardStates, kanbanBoardViews),
    [kanbanBoardStates, kanbanBoardViews],
  );

  const updateState = useCallback(
    (state: string) => {
      updateMState(state).catch(() => {});
    },
    [updateMState],
  );

  return { mState, states, updateState };
};

export default useScriptStates;
