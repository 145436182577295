import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const StyledText = styled('div')`
  ${({ theme }) => css`
    ${theme.typography.dina.body1};
    color: ${theme.palette.dina.blackHighEmphasis};
  `}
  margin: 12px 24px 12px 16px;
  word-break: break-word;
  user-select: text;
  a: {
    color: ${({ theme }) => theme.palette.dina.url};
  }
`;
