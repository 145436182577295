import { useMemo } from 'react';

import { getInput, getSortByFilter, Queries, SearchProps } from 'api/search';
import useRollingDateUtils from 'components/calendar/rollingDate/useRollingDate';
import { CommandToolbarProps } from 'features/command/command-types';
import useTimezoneSettings, { getLocalTimezone } from 'hooks/useTimezoneSettings';
import { aggregationFieldName, useStoryHubMolecule } from 'screens/storyHub/store/storyHub';
import { AggregateByInput, SearchItemTypeEnum } from 'types/graphqlTypes';
import {
  allSupportedMTypes,
  DEFAULT_CHUNK_SIZE,
  DEFAULT_POLL_INTERVAL,
  hourViewInitialMTypes,
  PlanningView,
  planningViews,
} from 'utils/planningViews';

const queryTypesEnum: { [x in PlanningView]: keyof typeof Queries } = {
  [planningViews.STATUS]: 'storyHubInstances',
  [planningViews.TIMELINE]: 'storyHubHour',
  [planningViews.DAY]: 'storyHub',
  [planningViews.WEEK]: 'storyHubWeek',
  [planningViews.MONTH]: 'storyHubMonth',
};

const hourViewInitialMTypesSet = new Set(hourViewInitialMTypes);

function useGetInput(toolbarStateOverrideProps?: Partial<CommandToolbarProps>) {
  const {
    useStoryHubTab,
    useStoryHubDateRange,
    useStoryHubToolbarState,
    useScheduled,
    useCombinedScheduled,
    useStoryHubPolling,
    useBucketSize,
    useStoryHubSearchString,
    useStoryHubMetadata,
  } = useStoryHubMolecule();
  const timezone = useTimezoneSettings();
  const dateRange = useStoryHubDateRange();
  const [inputString] = useStoryHubSearchString();
  const [metadataFilter] = useStoryHubMetadata();
  const [toolbarState] = useStoryHubToolbarState();
  const [isScheduled] = useScheduled();
  const [isCombinedScheduled] = useCombinedScheduled();

  const [doPoll] = useStoryHubPolling();
  const [bucketSize] = useBucketSize();

  const [tab] = useStoryHubTab();

  const scheduledProps: Partial<CommandToolbarProps> = useMemo(() => {
    if (tab.label === planningViews.STATUS && isCombinedScheduled) return {};
    if (isScheduled) return { isScheduled, rangeBy: { scheduledAt: dateRange } };
    return { isScheduled };
  }, [dateRange, isCombinedScheduled, isScheduled, tab.label]);

  const mTypes: SearchItemTypeEnum[] = useMemo(() => {
    if (toolbarState.mdfId || toolbarState.defaultMdfId) return toolbarState.mTypes;
    // Status View can only show instances
    if (tab.label === planningViews.STATUS) return [SearchItemTypeEnum.instance];
    if (tab.label === planningViews.TIMELINE) {
      if (!isScheduled) {
        return toolbarState.mTypes.length ? toolbarState.mTypes : allSupportedMTypes;
      }
      // filter out types other than story or pitch or rundown
      // instances will be queried separately
      const filteredMTypes = toolbarState.mTypes.filter((type) =>
        hourViewInitialMTypesSet.has(type),
      );
      return filteredMTypes.length > 0 ? filteredMTypes : tab.initialMTypes;
    }
    // backward compatibility with old implementation, only show some of the types by default
    if (toolbarState.mTypes.length === 0)
      return isScheduled ? tab.initialMTypes : allSupportedMTypes;
    return toolbarState.mTypes;
  }, [isScheduled, tab.initialMTypes, tab.label, toolbarState]);

  const tbState: CommandToolbarProps = useMemo(() => {
    const state: CommandToolbarProps = {
      ...toolbarState,
      ...scheduledProps,
      mTypes,
      ...toolbarStateOverrideProps,
    };
    if (toolbarState.sortBy === 'best') {
      if (tab.label === planningViews.STATUS) {
        state.sortBy = isScheduled ? 'scheduledAt' : 'updatedAt';
        state.order = isScheduled ? 'asc' : 'desc';
      } else {
        state.sortBy = 'priority';
        state.order = 'asc';
      }
    }
    return state;
  }, [isScheduled, mTypes, scheduledProps, tab.label, toolbarState, toolbarStateOverrideProps]);

  const searchString = inputString.trim();

  const forceMetadataMTypes = useMemo(
    () =>
      tab.label === planningViews.TIMELINE
        ? [SearchItemTypeEnum.story, SearchItemTypeEnum.pitch]
        : undefined,
    [tab.label],
  );

  const fetchAll = useMemo(
    () => (!isScheduled && tab.label !== planningViews.STATUS ? false : tab.fetchAll),
    [isScheduled, tab.fetchAll, tab.label],
  );

  const perPagelimit = useMemo(() => {
    if (!isScheduled && tab.label !== planningViews.STATUS) return DEFAULT_CHUNK_SIZE;
    return tab.chunkSize ?? undefined;
  }, [isScheduled, tab.chunkSize, tab.label]);

  const aggregations: AggregateByInput[] | undefined = useMemo(
    () =>
      tab.aggregationEnabled && isScheduled
        ? [
            {
              name: 'mPublishingAt',
              fieldName: aggregationFieldName,
              dateHistogram: {
                timeZone: timezone ?? getLocalTimezone(),
                dateInterval: 'day',
              },
              subAggregateBy: {
                name: 'mPriority',
                topHits: {
                  size: bucketSize,
                  sortBy:
                    toolbarState.sortBy === 'best'
                      ? getSortByFilter('priority', 'asc')
                      : getSortByFilter(toolbarState.sortBy, toolbarState.order),
                },
              },
            },
          ]
        : undefined,
    [
      bucketSize,
      isScheduled,
      tab.aggregationEnabled,
      timezone,
      toolbarState.order,
      toolbarState.sortBy,
    ],
  );

  const queryType = useMemo(() => {
    if (tab.label === planningViews.STATUS) return queryTypesEnum[planningViews.STATUS];
    return isScheduled ? queryTypesEnum[tab.label] : queryTypesEnum[planningViews.DAY];
  }, [isScheduled, tab.label]);

  const searchInput: SearchProps = useMemo(
    () => ({
      toolbarState: tbState,
      metadataFilter,
      queryType,
      perPagelimit,
      searchString,
      skip: false,
      doPoll,
      pollInterval: tab.pollInterval ?? DEFAULT_POLL_INTERVAL,
      forceMetadataMTypes,
      fetchAll,
      aggregations,
      mdfId: tbState.mdfId ?? undefined,
    }),
    [
      aggregations,
      doPoll,
      fetchAll,
      forceMetadataMTypes,
      metadataFilter,
      perPagelimit,
      queryType,
      searchString,
      tab.pollInterval,
      tbState,
    ],
  );

  return searchInput;
}

export function useGetQueryInput(toolbarStateOverrideProps?: Partial<CommandToolbarProps>) {
  const searchInput = useGetInput(toolbarStateOverrideProps);
  const { getRollingDateTime } = useRollingDateUtils();

  const {
    searchString,
    toolbarState,
    perPagelimit = DEFAULT_CHUNK_SIZE,
    metadataFilter,
    mdfId,
    aggregations,
    forceMetadataMTypes,
    mIds,
    queryType,
  } = searchInput;

  const input = useMemo(
    () =>
      getInput(
        searchString,
        undefined,
        toolbarState,
        perPagelimit,
        metadataFilter ?? {},
        mdfId,
        forceMetadataMTypes,
        mIds,
        aggregations,
        getRollingDateTime,
      ),
    [
      aggregations,
      forceMetadataMTypes,
      getRollingDateTime,
      mIds,
      mdfId,
      metadataFilter,
      perPagelimit,
      searchString,
      toolbarState,
    ],
  );

  const variables = useMemo(() => ({ input }), [input]);

  return { query: Queries[queryType!], variables };
}

export default useGetInput;
