import { useCallback, useMemo, useRef, useState } from 'react';
import styled from '@emotion/styled';

import useAssets from 'features/assets/hooks/useAssets';
import useCalculateContainerDimension from 'hooks/useCalculateContainerDimension';
import { HStack } from 'layouts/box/Box';
import { MemberTypeEnum } from 'types/graphqlTypes';

import Group, { Empty } from '../Group';

import Asset, { AssetSkeleton } from './Asset';

const ITEM_SIZE = 92;
const ITEM_GAP = 8;

const Wrapper = styled(HStack)<{ $collapsed?: boolean }>`
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding-inline: 12px 12px;
  gap: 8px;
  height: ${({ $collapsed }) => ($collapsed ? '96px' : 'auto')};
`;

function Assets({ storyId }: Readonly<{ storyId: string }>) {
  const container = useRef(null);
  const size = useCalculateContainerDimension(container);

  const [collapsed, setCollapsed] = useState(true);
  const { assets, loading } = useAssets({ mId: storyId, memberType: MemberTypeEnum.Asset });

  const toggleCollapsed = useCallback(() => setCollapsed(!collapsed), [collapsed]);

  const moreCount = useMemo(
    () => Math.max(assets.length - Math.floor((size.width + ITEM_GAP) / (ITEM_SIZE + ITEM_GAP)), 0),
    [assets.length, size.width],
  );

  return (
    <Group
      label="Assets"
      moreCount={moreCount}
      collapsed={moreCount > 0 ? collapsed : undefined}
      toggleCollapsed={toggleCollapsed}
      collapsedHeight={ITEM_SIZE + 2}
    >
      <>
        {assets.length === 0 && !loading ? (
          <Empty label="Assets" />
        ) : (
          <Wrapper $collapsed={collapsed} ref={container}>
            {loading
              ? Array(5)
                  .fill(null)
                  .map((_, index) => <AssetSkeleton key={index} />)
              : assets.map((asset) => <Asset asset={asset} key={asset?.mRefId} />)}
          </Wrapper>
        )}
      </>
    </Group>
  );
}

export default Assets;
