import { CommunicationChannel, SharingEvent } from './types';

/**
 * The number of milliseconds between resending events.
 */
export const RESEND_INTERVAL = 500;

/**
 * The number of milliseconds that we will keep on resending events.
 */
export const RECEIPT_TIMEOUT = 10000;

/**
 * The number of milliseconds that we remember that we have handled an event.
 * If we receive a receipt expecting event he after this we will handle it again.
 */
export const HANDLED_EVENT_KNOWLEDGE_DURATION = 30000;

interface ResendInfo<T> {
  readonly targetId: string;
  readonly event: SharingEvent<T>;
  readonly sendTime: number;
}

/**
 * Creates a communication channel on top of another communication channel. The created channel:
 * * will responds with a receipt for received events with an event ID.
 * * will resend such events every {@link RESEND_INTERVAL} ms until we have received a receipt
 *   or until {@link RECEIPT_TIMEOUT} ms have passed since we sent the event
 * * will prevent that events are handled again if receipt and resend event crosses each other
 *   (unless the resending of an event uses more than {@link HANDLED_EVENT_KNOWLEDGE_DURATION}
 *   minus {@link RECEIPT_TIMEOUT} ms)
 * @param innerChannel The channel to wrap
 * @param myId         The ID of the client
 * @returns
 */
export function createReceiptAwareChannel<T>(
  innerChannel: CommunicationChannel<T>,
  myId: string,
): CommunicationChannel<T> {
  /** Maps from event-IDs to info about events to resend until we have received a recept */
  const missingReceipts = new Map<string, ResendInfo<T>>();
  let resendInterval: ReturnType<typeof setInterval> | undefined;

  /**
   * Maps from event ID to time when event was handled. Allows us to avoid handling an event twice.
   */
  const handledEvents = new Map<string, number>();

  function resend() {
    const now = Date.now();
    const toBeRemoved: string[] = [];
    missingReceipts.forEach((info, id) => {
      if (now - info.sendTime > RECEIPT_TIMEOUT) {
        toBeRemoved.push(id);
      }
      innerChannel.send(info.targetId, info.event);
    });
    toBeRemoved.forEach((id) => missingReceipts.delete(id));
    if (missingReceipts.size === 0) {
      clearInterval(resendInterval);
      resendInterval = undefined;
    }
  }

  return {
    get state() {
      return innerChannel.state;
    },
    send: (targetId, event) => {
      if (event.type === 'received') {
        throw new Error('Cannot send received event');
      }
      if (!targetId && event.eventId) {
        throw new Error('Cannot request receipt for broadcasted events');
      }
      if (event.eventId && targetId) {
        missingReceipts.set(event.eventId, { targetId, event, sendTime: Date.now() });
        if (resendInterval === undefined) {
          resendInterval = setInterval(resend, RESEND_INTERVAL);
        }
      }
      innerChannel.send(targetId, event);
    },
    connectAsync: (handler) => {
      return innerChannel.connectAsync((event) => {
        if (event.type !== 'received') {
          if (event.eventId) {
            const handledTime = handledEvents.get(event.eventId);
            const isHandled = handledTime !== undefined;
            const now = Date.now();

            // Remove events
            handledEvents.forEach((time, id) => {
              if (now - time > HANDLED_EVENT_KNOWLEDGE_DURATION) handledEvents.delete(id);
            });

            // Send receipt to prevent resend
            innerChannel.send(event.sourceId, {
              type: 'received',
              sourceId: myId,
              eventId: event.eventId,
            });
            if (isHandled) return; // We don't want to handle the event once more

            // Register time when event was first seen (and handled)
            if (handledTime === undefined) handledEvents.set(event.eventId, now);
          }
          handler(event);
        } else if (
          missingReceipts.delete(event.eventId) &&
          !missingReceipts.size &&
          resendInterval === undefined
        ) {
          clearInterval(resendInterval);
          resendInterval = undefined;
        }
      });
    },
    disconnect: () => {
      innerChannel.disconnect();
      if (resendInterval !== undefined) {
        clearInterval(resendInterval);
        resendInterval = undefined;
      }
    },
  };
}
