import { atom, useAtom } from 'jotai';

import { OrderDeleteKeys } from 'components/orderFormDialog/api/useDeleteOrder';
import { type Order } from 'types/forms/forms';
import { TaskStatusEnum } from 'types/graphqlTypes';

const deleteOrderState = atom<OrderDeleteKeys | null>(null);
export const useDeleteOrderAtom = () => useAtom(deleteOrderState);

export const getActiveFromOrder = (order: Pick<Order, 'mActive'>): TaskStatusEnum => {
  return order.mActive ? TaskStatusEnum.active : TaskStatusEnum.inactive;
};
