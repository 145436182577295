import instanceVariants from 'utils/instance/variants';

const variants = {
  ...instanceVariants,
  MESSAGE: 'message',
  DAILYNOTE: 'dailyNote',
  SCRIPT: 'script',
} as const;

export default variants;
