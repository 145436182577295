import { useCallback } from 'react';

import { ReactComponent as DeleteIcon } from 'assets/icons/systemicons/delete_small.svg';
import { ReactComponent as EditIcon } from 'assets/icons/systemicons/edit.svg';
import { IconButton } from 'components/buttons';
import Text from 'components/text';
import Tooltip from 'components/tooltip';
import { HStack } from 'layouts/box/Box';
import { focusEnclosingListItem } from 'utils/dom/ulView';

import { ListTitle } from '../layout/styled';

import { SectionId } from './types';

import { NavBarItemWrapper } from './styled';

interface NavBarLeafItemProps {
  category: SectionId;
  id: string;
  label: string;
  changed: boolean;
  Icon: React.FC<React.SVGProps<SVGSVGElement>>;
  deleteItem?: (category: SectionId, id: string) => void;
  renameItem?: (category: SectionId, id: string) => void;
}

/**
 * Renders the content of a leaf node in the `TreeView` in the navigation bar at the left side
 * of the MDF schemas tab.
 * @param param The {@link NavBarLeafItemProps} specifying how to the content is to be rendered
 */
export default function NavBarLeafItem({
  category,
  id,
  label,
  changed,
  Icon,
  deleteItem,
  renameItem,
}: Readonly<NavBarLeafItemProps>) {
  const onDeleteClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      e.preventDefault();
      focusEnclosingListItem(e.currentTarget);
      deleteItem?.(category, id);
    },
    [deleteItem, category, id],
  );
  const onRenameClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      e.preventDefault();
      focusEnclosingListItem(e.currentTarget);
      renameItem?.(category, id);
    },
    [renameItem, category, id],
  );
  return (
    <Tooltip>
      <NavBarItemWrapper>
        <Icon style={{ width: '18px', marginRight: '2px' }} />
        <ListTitle>
          {changed ? (
            <Text variant="listItemLabelItalic" color="statusWarning">
              {`* ${label}`}
            </Text>
          ) : (
            <Text variant="listItemLabel" color="highEmphasis">
              {label}
            </Text>
          )}
        </ListTitle>
        {category !== 'defaults' && (
          <HStack padding="0 8px 0 0">
            {renameItem && (
              <IconButton
                title="Edit label (F2)"
                usage="text"
                tabIndex={-1}
                size={24}
                iconSize={18}
                onClick={onRenameClick}
                className="actionIcon"
              >
                <EditIcon />
              </IconButton>
            )}
            {deleteItem && (
              <IconButton
                title="Delete (Backspace)"
                usage="text"
                tabIndex={-1}
                size={24}
                onClick={onDeleteClick}
                className="actionIcon"
              >
                <DeleteIcon />
              </IconButton>
            )}
          </HStack>
        )}
      </NavBarItemWrapper>
    </Tooltip>
  );
}
