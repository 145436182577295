import { useCallback, useEffect, useState } from 'react';
import { useAutocomplete } from '@material-ui/lab';

import AutoCompleteBase from './view';

interface DefaultOption {
  readonly id: string;
  readonly value: string;
}
interface AutoCompleteProps<T extends DefaultOption> {
  readonly usage?: string;
  readonly options?: T[];
  readonly value?: string;
  readonly placeholder?: string;
  readonly onChange: (val: string) => void;
  readonly disabled?: boolean;
}
function AutoComplete<T extends DefaultOption = DefaultOption>({
  usage = 'default',
  options = [],
  value = '',
  placeholder = '',
  disabled = false,
  onChange,
}: AutoCompleteProps<T>) {
  const [input, setInput] = useState(value);
  const [rootValue, setRootValue] = useState(options.find((opt) => opt.value === value) ?? null);

  useEffect(() => {
    setRootValue(options.find((opt) => opt.value === value) ?? null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleChange = useCallback((updated: T | null) => {
    onChange(updated?.value ?? '');
    if (updated) setRootValue(updated);
  }, []);

  const {
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    getClearProps,
    groupedOptions,
    getPopupIndicatorProps,
    popupOpen,
  } = useAutocomplete({
    id: `${usage}-autocomplete`,
    options,
    value: rootValue,
    autoHighlight: true,
    getOptionLabel: (option) => option.value,
    inputValue: input,
    onChange: (_, val) => handleChange(val),
    onInputChange: (_, val: string) => setInput(val),
  });

  return (
    <AutoCompleteBase
      content={[]}
      readOnly={disabled}
      placeholder={placeholder}
      listWidthOffset={300}
      getRootProps={getRootProps}
      getInputProps={getInputProps}
      getListboxProps={getListboxProps}
      getOptionProps={getOptionProps}
      getClearProps={getClearProps}
      groupedOptions={groupedOptions}
      getPopupIndicatorProps={getPopupIndicatorProps}
      value={input}
      popupOpen={popupOpen}
    />
  );
}

export default AutoComplete;
