import styled from '@emotion/styled';

import Divider from 'components/divider';
import Text from 'components/text';
import { Flex } from 'layouts/box/Box';

// src/components/errorBoundary/styles.ts
export const Container = styled(Flex)`
  background-color: ${({ theme }) => theme.palette.dina.surfaceAppBackgroundNavLevel1};
`;

export const HDivider = styled(Divider)`
  width: 100%;
  margin-block: 12px;
`;

export const Caption = styled(Text)``;
Caption.defaultProps = {
  variant: 'caption',
  color: 'inactive',
};

export const Wrapper = styled('pre')`
  white-space: pre-wrap;
  font-size: 13;
  color: ${({ theme }) => theme.palette.dina.errorText};
`;
