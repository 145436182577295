import { useEffect } from 'react';
import styled from '@emotion/styled';
import { ScopeProvider } from 'jotai-molecules';

import Scrollbar from 'components/scrollbar';
import { Box } from 'layouts/box/Box';
import useGetRundown, { AdjustedRundownType } from 'screens/rundown/api/useGetRundown';
import useLoadInstances from 'screens/rundown/api/useLoadInstances';
import { RundownScope } from 'screens/rundown/store/rundown';
import ItemMoveDialog from 'screens/storyV2/ItemMoveDialog';
import { User } from 'types';
import { MemberType } from 'types/graphqlTypes';

import Schedule from '../story/Schedule';
import Tasks from '../story/Tasks';

import Assets from './Assets';
import Assignees from './Assignees';
import Instances from './Instances';
import PlannedDuration from './PlannedDuration';
import Status, { type StatusType } from './Status';

const ScrollContent = styled('div')`
  // Target all children except the first one
  & > div {
    position: relative;
  }
  // Add divider before each child after the first
  & > div::before {
    z-index: 100;
    content: '';
    position: absolute;
    top: 0;
    left: 12px;
    right: 12px;
    height: 1px;
    background-color: ${({ theme }) => theme.palette.dina.dividerLight};
  }
`;

function RundownPreview({ rundown }: Readonly<{ rundown: MemberType }>) {
  const { loadInstances, isLoading } = useLoadInstances({
    rundownId: rundown.mId as string,
  });

  const { data } = useGetRundown({ mId: rundown.mId as string });

  const rundownData = data?.getRundown || ({} as AdjustedRundownType);

  const { mId, mOrder, mPreorder } = rundownData;

  useEffect(() => {
    if (mId) {
      // eslint-disable-next-line no-console
      loadInstances([...(mOrder ?? []), ...(mPreorder ?? [])]).catch(console.error);
    }
  }, [loadInstances, mId, mOrder, mPreorder]);

  return (
    <ScopeProvider scope={RundownScope} value={`${rundown.mId}:inlinePreview`}>
      <Box height="100%" width="100%">
        <Scrollbar>
          <ScrollContent>
            <Schedule story={rundown} />
            <PlannedDuration duration={rundown.mPlannedDuration ?? '0'} />
            <Status status={(rundown?.mState ?? 'notReady') as StatusType} />
            <Assignees
              rundownId={rundown.mId ?? ''}
              assignedMembers={rundown.mAssignedMembers as User[]}
            />
            <Instances instanceIds={mOrder ?? []} label="Ready" loading={isLoading} />
            <Instances instanceIds={mPreorder ?? []} label="Preparing" loading={isLoading} />
            {!isLoading && (
              <Assets isInstanceLoading={isLoading} rundownId={rundownData.mId ?? ''} />
            )}
            <Tasks story={rundown} />
          </ScrollContent>
        </Scrollbar>
        <ItemMoveDialog />
      </Box>
    </ScopeProvider>
  );
}

export default RundownPreview;
