import styled from '@emotion/styled';

import { VStack } from 'layouts/box/Box';

export const RadioButtonWithLabel = styled('label')`
  display: flex;
  align-items: center;
  text-transform: capitalize;
  gap: 0.5rem;
  cursor: pointer;
`;

export const StyledGroupWrapper = styled(VStack)`
  .MuiSwitch-root {
    margin-left: -12px;
  }
`;
