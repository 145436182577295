import { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Tooltip } from '@material-ui/core';

import { useGetMdfs } from 'api/mdf/useGetMdfs';
import { CheckboxWithLabel } from 'components/createNewV3/CreateNew';
import Dialog from 'components/dialogs/DialogBuilder';
import { useChangedMdfs } from 'screens/main/components/header/navbar/settings/atomsTs';
import { createSubtypeAlternative } from 'screens/main/components/header/navbar/settings/components/mdf/schemaHelpers';
import { Mutable } from 'types';
import { Alternative, FieldTypeEnum, Mdf, MdfField } from 'types/graphqlTypes';
import { EMPTY_ARRAY } from 'utils/arrayUtils';
import useMemoMap from 'utils/hooks/useMemoMap';
import { getSubtypeValue, isModernSubtypeId } from 'utils/mdf/utils';
import { localeLabelCompare } from 'utils/objects';

enum DependencyState {
  beingChecked = -1,
  independent = 0,
  dependent = 1,
  self = 2,
  invalid = 3,
  circular = 4,
}

interface SubtypeInfo {
  readonly id: string;
  readonly label: string;
  readonly problem?: string;
}

function getSubtypesInfo(subtypes: Record<string, Mdf>, selfSubtype: Mdf): readonly SubtypeInfo[] {
  const selfValue = getSubtypeValue(selfSubtype);
  const dependentSubtypeValues: Record<string, DependencyState> = {};
  dependentSubtypeValues[selfValue] = DependencyState.self;

  function checkSubType([value, subtype]: [string, Mdf]): DependencyState {
    if (value in dependentSubtypeValues) {
      const state = dependentSubtypeValues[value];
      return state === DependencyState.beingChecked ? DependencyState.circular : state;
    }
    dependentSubtypeValues[value] = DependencyState.beingChecked;
    let state = DependencyState.independent;
    for (const subTypeField of subtype.fields.filter((md) => md.type === FieldTypeEnum.subtype)) {
      for (const alternative of subTypeField.alternatives ?? []) {
        if (alternative.value === selfValue) {
          dependentSubtypeValues[value] = DependencyState.dependent;
          return DependencyState.dependent;
        }
        const altSubtype = subtypes[alternative.value];
        state = Math.max(
          state,
          !altSubtype ? DependencyState.invalid : checkSubType([alternative.value, altSubtype]),
        );
      }
    }
    dependentSubtypeValues[value] = state;
    return state;
  }

  Object.entries(subtypes).forEach(checkSubType);
  return Object.freeze(
    Object.entries(subtypes)
      .map(([value, subtype]) => {
        const item: Mutable<SubtypeInfo> = { id: subtype.id, label: subtype.label };
        const dependencyState = dependentSubtypeValues[value];
        switch (dependencyState) {
          case DependencyState.invalid:
            item.problem = 'This subtype uses a deleted subtype';
            break;
          case DependencyState.circular:
            item.problem = 'This subtype is involved in a circular dependency';
            break;
          case DependencyState.dependent:
            item.problem = 'This subtype uses the subtype being defined';
            break;
          case DependencyState.beingChecked:
            item.problem = 'This subtype has an unknown problem'; // This should not happen
            break;
          case DependencyState.self:
            item.problem = 'Cannot use the subtype being defined';
            break;
        }
        return Object.freeze(item);
      })
      .toSorted(localeLabelCompare),
  );
}

interface Props {
  open: boolean;
  setOpen: (val: boolean) => void;
  field: MdfField;
  updateAlternatives: (alts: Alternative[]) => void;
  selectedMdf: Mdf;
}

export function ConfigSubtypeDialog({
  open,
  setOpen,
  updateAlternatives,
  field,
  selectedMdf,
}: Readonly<Props>) {
  const { mdfsSeparated } = useGetMdfs();
  const [changedMdfs] = useChangedMdfs();
  const [fieldToConfigure, setFieldToConfigure] = useState({ ...field });
  /** Maps from the alternative value of other other sub types to their MDFs */
  const subtypes = useMemoMap(
    Object.fromEntries(
      mdfsSeparated.subTypes.map((mdf) => [getSubtypeValue(mdf), changedMdfs[mdf.id] ?? mdf]),
    ),
  );

  useEffect(() => {
    setFieldToConfigure({ ...field });
  }, [field]);

  const toggleSubtype = useCallback(
    (id: string, label: string) => {
      const value = isModernSubtypeId(id) ? id : label;
      setFieldToConfigure((prevValue) => {
        const prevAlternatives = prevValue.alternatives ?? [];
        const wasIncluded = !!prevAlternatives.find((alt) => alt.value === value);
        const rawAlternatives = wasIncluded
          ? prevAlternatives.filter((a) => a.value !== value)
          : [...prevAlternatives, createSubtypeAlternative(id, label)];
        // Make sure that all labels are up to date for subtypes with modern IDs
        const alternatives = rawAlternatives.map((alt) => {
          if (alt.value === alt.label) return alt; // Legacy
          const updatedMdf = subtypes[alt.value];
          if (!updatedMdf || updatedMdf.label === (alt.label || alt.value)) return alt;
          return createSubtypeAlternative(alt.value, updatedMdf.label);
        });
        return { ...prevValue, alternatives };
      });
    },
    [subtypes, setFieldToConfigure],
  );

  const availableSubtypes = useMemo(() => {
    return getSubtypesInfo(subtypes, selectedMdf);
  }, [subtypes, selectedMdf]);

  const selectedSubtypes = useMemo(() => {
    const alternatives = fieldToConfigure.alternatives;
    if (!alternatives?.length) return EMPTY_ARRAY as readonly string[];
    return availableSubtypes
      .filter(({ id, label }) => {
        const value = isModernSubtypeId(id) ? id : label;
        return !!alternatives.find((alt) => alt.value === value);
      })
      .map((info) => info.id);
  }, [fieldToConfigure.alternatives, availableSubtypes]);

  const confirmChanges = useCallback(() => {
    updateAlternatives(fieldToConfigure.alternatives ?? []);
    setOpen(false);
  }, [updateAlternatives, fieldToConfigure]);

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      style={{ minWidth: '300px', maxWidth: '300px' }}
    >
      <Dialog.Header>Set available sub type choices</Dialog.Header>
      <Dialog.Body>
        <Box>
          {availableSubtypes.map((info) => (
            <Box flexDirection="row" key={info.id}>
              <Tooltip title={info.problem ?? ''} placement="left">
                <span>
                  <CheckboxWithLabel
                    selected={selectedSubtypes.includes(info.id)}
                    onClick={() => {
                      if (!info.problem) toggleSubtype(info.id, info.label);
                    }}
                    label={info.label}
                    disabled={!!info.problem}
                  />
                </span>
              </Tooltip>
            </Box>
          ))}
        </Box>
      </Dialog.Body>
      <Dialog.Footer>
        <Dialog.CancelButton />
        <Dialog.ConfirmButton label="Confirm" onConfirm={confirmChanges} />
      </Dialog.Footer>
    </Dialog>
  );
}
