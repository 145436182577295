import React from 'react';

import { FieldTypeEnum } from 'types/graphqlTypes';

import CheckboxCell from './checkbox/CheckboxCell';
import { CheckboxField } from './checkbox/CheckboxField';
import ChoiceCell from './choice/ChoiceCell';
import ChoiceField from './choice/ChoiceField';
import DateCell from './date/DateCell';
import { DateField } from './date/DateField';
import LinkCell from './link/LinkCell';
import { LinkField } from './link/LinkField';
import MultipleChoiceCell from './multiplechoice/MultipleChoiceCell';
import { MultipleChoiceField } from './multiplechoice/MultipleChoiceField';
import RelationCell from './relation/RelationCell';
import { RelationField } from './relation/RelationField';
import SubTypeCell from './subtype/SubTypeCell';
import TextCell from './text/TextCell';
import { TextField } from './text/TextField';
import LightWeightTimingCell from './timing/LightWeightTimingCell';
import { TimingField } from './timing/TimingField';
import TreeChoiceCell from './treechoice/TreeChoiceCell';
import { TreeChoiceField } from './treechoice/TreeChoiceField';
import UserCell from './user/UserCell';
import { UserField } from './user/UserField';
import CellProps from './cellProps';
import FieldProps from './fieldProps';

export const UserFriendlyLabel: Record<FieldTypeEnum, string> = {
  choice: 'Choice',
  multiplechoice: 'Multiple choice',
  checkbox: 'Checkbox',
  number: 'Number',
  link: 'Link',
  user: 'User/Team/Dept/Contact',
  text: 'Text',
  date: 'Date',
  treechoice: 'Tree choice',
  subtype: 'Sub type',
  relation: 'Relation',
  timing: 'Timing',
};

export const FieldMap: Record<FieldTypeEnum, React.FC<FieldProps>> = {
  choice: ChoiceField,
  multiplechoice: MultipleChoiceField,
  treechoice: TreeChoiceField,
  number: TextField,
  link: LinkField,
  user: UserField,
  checkbox: CheckboxField,
  date: DateField,
  text: TextField,
  subtype: ChoiceField,
  relation: RelationField,
  timing: TimingField,
};

export const CellMap: Record<FieldTypeEnum, React.FC<CellProps>> = {
  choice: ChoiceCell,
  multiplechoice: MultipleChoiceCell,
  treechoice: TreeChoiceCell,
  number: TextCell,
  link: LinkCell,
  user: UserCell,
  checkbox: CheckboxCell,
  date: DateCell,
  text: TextCell,
  subtype: SubTypeCell,
  relation: RelationCell,
  timing: LightWeightTimingCell,
};
