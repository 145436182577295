import { atom, useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { createScope, molecule, useMolecule } from 'jotai-molecules';
import { BaseRange } from 'slate';

export const EditorScope = createScope(undefined);

export interface PinnedState {
  content: boolean;
  note: boolean;
  instance: boolean;
  dailyNote: boolean;
  message: boolean;
}

const editorMolecule = molecule((_, getScope) => {
  getScope(EditorScope);

  const showDeleteAllDialogAtom = atom<boolean>(false);
  const commandTargetAtom = atom<BaseRange | null>(null);
  const printPlanningBlocks = atom(false);
  const printTasks = atom(false);
  const showHoveringToolbarAtom = atom(true);
  const askAIPinAtom = atomWithStorage<PinnedState>('askAiPinState', {
    content: false,
    note: false,
    instance: false,
    dailyNote: false,
    message: false,
  });

  return {
    useShowDeleteAllDialog: () => useAtom(showDeleteAllDialogAtom),
    usePrintPlanningBlocks: () => useAtom(printPlanningBlocks),
    useCommandTarget: () => useAtom(commandTargetAtom),
    usePrintTasks: () => useAtom(printTasks),
    useShowHoveringToolbar: () => useAtom(showHoveringToolbarAtom),
    useAskAIPin: () => useAtom(askAIPinAtom),
  };
});

export const useEditorMolecule = () => useMolecule(editorMolecule);
