/* eslint-disable no-param-reassign */
import { memo, useCallback, useMemo } from 'react';
import { FixedSizeList as List } from 'react-window';
import { InputAdornment, Paper } from '@material-ui/core';
import memoize from 'memoize-one';
import PropTypes from 'prop-types';

import { ReactComponent as ArrowDown } from 'assets/icons/systemicons/arrows/disclosurearrow_down.svg';
import { ReactComponent as ArrowUp } from 'assets/icons/systemicons/arrows/disclosurearrow_up.svg';
import { ReactComponent as Close } from 'assets/icons/systemicons/close.svg';
import { ReactComponent as Checked } from 'assets/icons/systemicons/statusIndicators/isSelected.svg';
import Avatar from 'components/avatar/Avatar';
import { useEditorContext } from 'components/editor/EditorContext';
import InputField from 'components/inputField';

import useStyles from './styles';

const Input = memo(InputField);

const createItemData = memoize((groupedOptions, renderedContent, getOptionProps) => ({
  groupedOptions,
  renderedContent,
  getOptionProps,
}));

const AutoCompleteBase = ({
  getRootProps,
  getInputProps,
  getClearProps,
  getPopupIndicatorProps,
  getListboxProps,
  getOptionProps,
  groupedOptions,
  popupOpen,
  value,
  content: renderedContent,
  listWidthOffset,
  placeholder,
  showAvatarOnList,
  propToSelect,
  readOnly,
  enableAutocomplete,
  inputUsage,
  direction,
}) => {
  const classes = useStyles({ listWidthOffset });

  const calculatedHeight = groupedOptions.length > 9 ? 256 : groupedOptions.length * 32;

  const { onHotKeys } = useEditorContext();

  const popupList = useMemo(
    () =>
      ({ index, style, data }) => {
        const {
          groupedOptions: options,
          getOptionProps: optionProps,
          renderedContent: content,
        } = data;

        const option = options[index];
        const isSelected = content.find((opt) => opt[propToSelect] === option[propToSelect]);
        return (
          <div
            className={isSelected ? classes.listItemDisabled : classes.listItem}
            {...optionProps({ option, index })}
            style={style}
          >
            <div className={classes.valueStyle}>
              {showAvatarOnList && (
                <div className={classes.avatarContainer}>
                  <Avatar
                    variant="user"
                    imageKey={option?.avatarKey}
                    size={22}
                    title={option.title}
                  />
                </div>
              )}
              {option.value}
            </div>
            {isSelected && <Checked className={classes.optionSelectedIcon} />}
          </div>
        );
      },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const itemData = createItemData(groupedOptions, renderedContent, getOptionProps);

  const rootProps = useMemo(() => getRootProps(), [getRootProps]);
  const onKeyDown = useCallback(
    (event) => {
      rootProps?.onKeyDown(event);
      onHotKeys?.(event);
    },
    [onHotKeys, rootProps],
  );

  return (
    <>
      <div {...rootProps} onKeyDown={onKeyDown}>
        <Input
          disableLabel
          inputProps={{ ...getInputProps() }}
          usage={inputUsage}
          disabled={readOnly}
          placeholder={placeholder}
          direction={direction}
          endAdornment={
            <InputAdornment position="end" className={classes.pointer}>
              {value && <Close {...getClearProps()} />}
              {enableAutocomplete && (
                <div {...getPopupIndicatorProps()}>{popupOpen ? <ArrowUp /> : <ArrowDown />}</div>
              )}
            </InputAdornment>
          }
        />
      </div>
      {enableAutocomplete &&
        (groupedOptions.length > 0 ? (
          <Paper elevation={16} className={classes.listbox} {...getListboxProps()}>
            <List
              height={calculatedHeight}
              width="100%"
              itemSize={32}
              itemCount={groupedOptions.length}
              itemData={itemData}
              outerRef={(elementRefOrNull) => {
                if (elementRefOrNull) {
                  elementRefOrNull.tabIndex = -1;
                }
              }}
            >
              {popupList}
            </List>
          </Paper>
        ) : (
          <Paper className={classes.listbox}>
            {popupOpen && <div className={classes.listItemDisabled}>No Options</div>}
          </Paper>
        ))}
    </>
  );
};

AutoCompleteBase.propTypes = {
  /** getRootProps function for root autocomplete base */
  getRootProps: PropTypes.func.isRequired,
  /** getInputProps function for input props on autocomplete base */
  getInputProps: PropTypes.func.isRequired,
  /** provides clear prop on element responsible for clearing the input */
  getClearProps: PropTypes.func.isRequired,
  /** provides popup indicator props */
  getPopupIndicatorProps: PropTypes.func.isRequired,
  /** provides listbox props */
  getListboxProps: PropTypes.func.isRequired,
  /** provides options props */
  getOptionProps: PropTypes.func.isRequired,
  /** provides grouped option to show on popup list */
  groupedOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  /** provides boolean for whether popup is open or not */
  popupOpen: PropTypes.bool.isRequired,
  /** value dependent to show clear icon */
  value: PropTypes.string.isRequired,
  /** offset to determine the width of the listbox */
  listWidthOffset: PropTypes.number,
  /** content array that is being stored for the block */
  content: PropTypes.arrayOf(PropTypes.object),
  /** whether to show the avatar on list or not */
  showAvatarOnList: PropTypes.bool,
  /** prop to show select icon on */
  propToSelect: PropTypes.string,
  /** input field placeholder */
  placeholder: PropTypes.string,
  /** whether the component is readonly or not */
  readOnly: PropTypes.bool,
  /** enable the advanced feature of autocomplete */
  enableAutocomplete: PropTypes.bool,
  /** whether the input field being used for editor or metadata */
  inputUsage: PropTypes.string,
  /** direction of the input field */
  direction: PropTypes.string,
};

AutoCompleteBase.defaultProps = {
  listWidthOffset: 72,
  content: [],
  showAvatarOnList: false,
  propToSelect: 'value',
  placeholder: '',
  readOnly: false,
  enableAutocomplete: true,
  inputUsage: 'editor',
  direction: undefined,
};

export default AutoCompleteBase;
