import Scrollbar from 'components/scrollbar/scrollbar';
import ListItem from 'components/ellipsisDropdown/listItem-view';
import { useGetProviderConfig } from 'api/providers/useGetChannelProvider';

import { useSelectedUserSettingAtom, useSelectedCategory } from '../../../../atoms';
import USER_SETTING_TYPES from '../../../../utils/userSettingTypes';
import List from '../../../collapseList';

import { RootWrapper } from './menu-styled';

const Menu = () => {
  const [selectedUserSetting, setSelectedUserSetting] = useSelectedUserSettingAtom();
  const [category, setCategory] = useSelectedCategory();

  const { providerConfig: sesProvider } = useGetProviderConfig('SES');
  const { providerConfig: slackProvider } = useGetProviderConfig('slack');

  const toggleLeftMenuProfile = () => {
    setSelectedUserSetting(USER_SETTING_TYPES.PROFILE);
    setCategory(null);
  };

  const toggleLeftMenuUserTour = () => {
    setSelectedUserSetting(USER_SETTING_TYPES.USER_TOUR);
    setCategory(null);
  };

  const toggleLeftMenuUserTheme = () => {
    setSelectedUserSetting(USER_SETTING_TYPES.THEME);
    setCategory(null);
  };

  const toggleLeftMenuNotification = (item) => {
    setCategory(USER_SETTING_TYPES.NOTIFICATION);
    setSelectedUserSetting(item);
  };

  const toggleLeftMenuPreferences = () => {
    setSelectedUserSetting(USER_SETTING_TYPES.PREFERENCES);
    setCategory(null);
  };

  return (
    <RootWrapper>
      <Scrollbar>
        <List>
          <ListItem
            key={USER_SETTING_TYPES.PROFILE}
            text="Profile"
            selected={selectedUserSetting === USER_SETTING_TYPES.PROFILE}
            onClick={toggleLeftMenuProfile}
            excludeDivider
          />
          <ListItem
            key={USER_SETTING_TYPES.USER_TOUR}
            text="User Interface Tours"
            selected={selectedUserSetting === USER_SETTING_TYPES.USER_TOUR}
            onClick={toggleLeftMenuUserTour}
            excludeDivider
          />
          <ListItem
            key={USER_SETTING_TYPES.THEME}
            text="User Interface Theme"
            selected={selectedUserSetting === USER_SETTING_TYPES.THEME}
            onClick={toggleLeftMenuUserTheme}
            excludeDivider
          />
          <ListItem
            key={USER_SETTING_TYPES.PREFERENCES}
            text="Preferences"
            selected={selectedUserSetting === USER_SETTING_TYPES.PREFERENCES}
            onClick={toggleLeftMenuPreferences}
            excludeDivider
          />
          <List.Header
            onClick={() => toggleLeftMenuNotification(USER_SETTING_TYPES.NOTIFICATION_GENERAL)}
            selected={category === USER_SETTING_TYPES.NOTIFICATION}
            hideCreateButton
          >
            Notifications
          </List.Header>
          <List.Body>
            <List.Item
              key={USER_SETTING_TYPES.NOTIFICATION_GENERAL}
              selected={selectedUserSetting === USER_SETTING_TYPES.NOTIFICATION_GENERAL}
              onClick={() => toggleLeftMenuNotification(USER_SETTING_TYPES.NOTIFICATION_GENERAL)}
            >
              General
            </List.Item>
            {slackProvider && (
              <List.Item
                key={USER_SETTING_TYPES.NOTIFICATION_SLACK}
                text="Slack"
                selected={selectedUserSetting === USER_SETTING_TYPES.NOTIFICATION_SLACK}
                onClick={() => toggleLeftMenuNotification(USER_SETTING_TYPES.NOTIFICATION_SLACK)}
              >
                Slack
              </List.Item>
            )}
            {sesProvider && (
              <List.Item
                key={USER_SETTING_TYPES.NOTIFICATION_EMAIL}
                selected={selectedUserSetting === USER_SETTING_TYPES.NOTIFICATION_EMAIL}
                onClick={() => toggleLeftMenuNotification(USER_SETTING_TYPES.NOTIFICATION_EMAIL)}
              >
                E-mail
              </List.Item>
            )}
          </List.Body>
        </List>
      </Scrollbar>
    </RootWrapper>
  );
};

export default Menu;
