import {
  type ImperativePanelHandle,
  Panel,
  PanelGroup,
  PanelResizeHandle,
} from 'react-resizable-panels';

import { ReactComponent as Grip } from 'assets/icons/systemicons/DraggableVertical.svg';
import { Flex } from 'layouts/box/Box';

const ResizablePanelGroup = ({ style, ...props }: React.ComponentProps<typeof PanelGroup>) => (
  <PanelGroup {...props} style={{ ...style, overflowX: 'auto' }} />
);

const ResizablePanel = ({ ...props }) => {
  return <Panel {...props} />;
};

const ResizableHandle = ({
  withHandle,
  ...props
}: React.ComponentProps<typeof PanelResizeHandle> & {
  withHandle?: boolean;
}) => (
  <PanelResizeHandle {...props}>
    {withHandle && (
      <Flex width="4px" height="100%">
        <Grip />
      </Flex>
    )}
    {props.children}
  </PanelResizeHandle>
);

export { ResizablePanelGroup, ResizablePanel, ResizableHandle, type ImperativePanelHandle };
