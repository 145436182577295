import { Mdf, MemberTypeEnum } from 'types/graphqlTypes';
import { PlatformAccount, SupportedMetadataTypes } from 'types/members';
import { MTypeToMdfId } from 'types/utilities';
import { isInstance, isPitch, isStory } from 'utils/member/mTypes';

interface GetMdfsFromKeyedMdfsParams {
  /** MType of the MDF to get */
  mType?: string;
  /** Properties of the MDF to get (instances only) */
  mProperties?: {
    platformKind?: string;
    account?: PlatformAccount;
    platform?: string;
  };
  /** Keyed MDFs object */
  keyedMdfs: Record<string, Mdf | undefined>;
}

/** Get the MDF for a given mType from a keyed MDFs object */
export function getMdfFromKeyedMdfs({ mType, mProperties, keyedMdfs }: GetMdfsFromKeyedMdfsParams) {
  if (!mType) return;
  if (isStory(mType)) return keyedMdfs[MemberTypeEnum.Story];
  if (isPitch(mType)) return keyedMdfs[MemberTypeEnum.Pitch];

  const mdfIdFromType = MTypeToMdfId[mType as SupportedMetadataTypes];
  if (mdfIdFromType) return keyedMdfs[mdfIdFromType];

  if (isInstance(mType)) {
    if (!mProperties) return;
    if (mProperties.platformKind === 'audio') return keyedMdfs['linear-audio'];

    if (mProperties.platformKind) {
      const platformKindMdf = keyedMdfs[mProperties.platformKind];
      if (platformKindMdf) return platformKindMdf;
    }

    const accountId = (mProperties.account as PlatformAccount)?.accountId ?? '';
    if (accountId) {
      const accountMdf = keyedMdfs[accountId];
      if (accountMdf) return accountMdf;
    }
    if (mProperties.platform) return keyedMdfs[mProperties.platform];
  }
  return undefined;
}
