/* eslint-disable max-len */
import { atom, useAtom } from 'jotai';

import Dialog from 'components/dialogs/DialogBuilder';
import { MemberTypeEnum, ScopedConfig, VisiblePlaces } from 'types/graphqlTypes';

import type { GroupPolicy } from './ScopedConfigs';
import ScopedInnerConfigsDialog from './ScopedInnerConfigDialog';

export interface ScopedConfigDialog<T> {
  openDialog: boolean;
  configToUpdate?: ScopedConfig<T>;
  canAdministrate: boolean;
  groups: GroupPolicy[];
  typeLabel: string;
  stateLabel: string;
  currentLocation: VisiblePlaces | 'default';
  data: T | null;
  mType: MemberTypeEnum;
  hideVisibleIn?: boolean;
}

const defaults: ScopedConfigDialog<unknown> = {
  openDialog: false,
  canAdministrate: false,
  groups: [],
  typeLabel: '',
  stateLabel: '',
  currentLocation: 'default',
  data: {},
  mType: MemberTypeEnum.SavedSearch,
};

const dialogProps = atom<ScopedConfigDialog<unknown>>(defaults);
export const useScopedConfigDialog = () => useAtom(dialogProps);

export default function ScopedConfigsDialog() {
  const [state, setState] = useScopedConfigDialog();

  const onClose = () => {
    setState({ ...defaults });
  };

  if (!state.openDialog) {
    return null;
  }

  return (
    <Dialog open={true} style={{ width: '400px' }} modal={false} onClose={onClose}>
      <ScopedInnerConfigsDialog {...state} onClose={onClose} />
    </Dialog>
  );
}
