import { useCallback, useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';

import Text from 'components/text';
import { Box } from 'layouts/box/Box';

import Group, { Empty } from '../Group';

const MAX_LINES = 3;
const LINE_HEIGHT = 24;

const Wrapper = styled(Box)<{ $collapsed?: boolean }>`
  flex: 1;
  width: 100%;
  padding-inline: 12px 12px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${({ $collapsed }) => ($collapsed ? MAX_LINES : 'none')};
  overflow: hidden;
`;

function Description({ description }: Readonly<{ description?: string }>) {
  const [collapsed, setCollapsed] = useState<boolean | undefined>(true);
  const textRef = useRef<HTMLDivElement>(null);

  const toggleCollapsed = useCallback(() => setCollapsed(!collapsed), [collapsed]);

  useEffect(() => {
    if (textRef.current && description?.length) {
      const fullHeight = textRef.current.scrollHeight;
      const maxHeight = MAX_LINES * LINE_HEIGHT;
      setCollapsed(fullHeight > maxHeight ? true : undefined);
    } else {
      setCollapsed(undefined);
    }
  }, [description?.length]);

  return (
    <Group label="Description" collapsed={collapsed} toggleCollapsed={toggleCollapsed}>
      {description ? (
        <Wrapper $collapsed={collapsed} ref={textRef} alignItems="flex-start">
          <Text variant="body2" color="mediumEmphasis">
            {description}
          </Text>
        </Wrapper>
      ) : (
        <Empty label="Description" />
      )}
    </Group>
  );
}

export default Description;
