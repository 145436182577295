/* eslint-disable no-console */
import { memo, ReactElement, useMemo } from 'react';
import { DragSourceMonitor, useDrag } from 'react-dnd';
import { useTheme } from '@emotion/react';
import { uniqBy } from 'lodash';
import type { AssignedMember, Priority, User } from 'types';

import { useGetOrderForms } from 'api/order_forms/useGetOrderForms';
import Assignees from 'components/assignees/Assignees';
import Avatar, { AvatarVariant } from 'components/avatar/Avatar';
import InstanceBadges from 'components/badges/InstanceBadges';
import { Icon } from 'components/memberIcon/MemberIcon';
import Text from 'components/text';
import Tooltip from 'components/tooltip';
import { isHighlight } from 'features/command/command-types';
import { getAccountTitle, getMetadataValue, safeForHTML } from 'features/command/command-utils';
import useCustomDateTimeUtils from 'hooks/useCustomDateTimeUtils';
import useGetStatuses from 'hooks/useGetStatuses';
import useMouseClickEvents from 'hooks/useMouseClickEvents';
import { Box, HStack, VStack } from 'layouts/box/Box';
import { usePriorities } from 'screens/main/components/header/navbar/settings/atomsTs';
import PersistentTheme from 'theme/persistentTheme';
import {
  type AssignedMemberType,
  type InstanceStateType,
  type MemberType,
  MemberTypeEnum,
  type Metadata,
  type SortType,
} from 'types/graphqlTypes';
import { EditorCommandConfigType } from 'types/memberTypes/editorCommands';
import { OrderFormMemberType } from 'types/memberTypes/order_form';
import { parseItemAggregations } from 'types/utilities';
import { colors } from 'utils/constants/colors';
import { formatDuration } from 'utils/formatDuration';
import { canShareWithStory, getHighlightedTitle } from 'utils/member/member-utils';
import { isRestricted } from 'utils/member/mTypes';

import { hasAvatar, integrationIcons, StatusIcons } from '../../command-constants';
import UniqueId from '../../UniqueId';

import {
  AvatarWrapper,
  Container,
  ItemContentWrapper,
  ItemDate,
  ItemDescription,
  ItemFooter as ItemFooterWrapper,
  ItemInstances,
  ItemTitle,
  ItemTitleWrapper,
  PriorityIndicator,
  RestrictedIcon,
  RundownStatus,
  SearchItemWrapper,
  StyledPlanned,
  StyledScheduleOn,
} from './styled';

const ThemePersistentWrapper = ({
  children,
  selected,
}: {
  children: ReactElement;
  selected?: boolean;
}) => {
  const theme = useTheme();

  return theme.palette.mode === 'light' && selected ? (
    <PersistentTheme>{children}</PersistentTheme>
  ) : (
    children
  );
};

interface SearchItemProps {
  openItem: (item: MemberType) => void;
  onContextMenu?: (item: MemberType, event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  shareWith?: (id: string, memberId: string, memberType: MemberTypeEnum) => Promise<void>;
  item: MemberType;
  sortedBy: SortType | 'best';
  commandById: Record<string, EditorCommandConfigType | undefined>;
  index?: number;
  value: string;
  members?: Record<string, AssignedMember>;
  priorityFilterApplied: boolean;
  selected?: boolean;
  onDoubleClick?: (item: MemberType) => void;
}

interface FooterProps {
  item: MemberType;
  instanceStates: Record<string, InstanceStateType>;
  forms: Record<string, OrderFormMemberType>;
  command: EditorCommandConfigType | undefined;
}

interface AvatarProps {
  item: MemberType;
  members: Record<string, AssignedMember>;
}

const sortToMemberTypeProp: Record<
  SortType | 'best',
  keyof Pick<
    MemberType,
    'mTitle' | 'mUpdatedAt' | 'mPublishingAt' | 'mCreatedAt' | 'totalInstances' | 'mPriority'
  >
> = {
  title: 'mTitle',
  best: 'mCreatedAt',
  createdAt: 'mCreatedAt',
  updatedAt: 'mUpdatedAt',
  scheduledAt: 'mPublishingAt',
  totalInstances: 'totalInstances',
  priority: 'mPriority',
};

const sortToTooltip: Record<SortType | 'best', string> = {
  best: 'Updated date',
  title: 'Updated date',
  createdAt: 'Created date',
  updatedAt: 'Last updated date',
  scheduledAt: 'Scheduled date',
  totalInstances: 'Updated date',
  priority: 'Updated date',
};

export const rundownStateLabel: Record<string, string> = {
  ready: 'Ready to air',
  active: 'Activated',
};

const ItemFooter = ({ item, instanceStates, forms, command }: FooterProps) => {
  const { isoToLocaleShort } = useCustomDateTimeUtils();
  const { instance: instanceCount } = parseItemAggregations(item.aggregations);
  switch (item.mType ?? MemberTypeEnum.Story) {
    case MemberTypeEnum.Block: {
      return <ItemDate variant="caption">{command?.mTitle ?? 'Unknown command'}</ItemDate>;
    }
    case MemberTypeEnum.Asset: {
      const provider = getMetadataValue(item.mMetaData, 'provider');
      const copyright = getMetadataValue(item.mMetaData, 'copyrightnotice');
      return (
        <ItemFooterWrapper>
          <ItemDate variant="caption">{provider}</ItemDate>
          <ItemDate variant="caption">{copyright}</ItemDate>
        </ItemFooterWrapper>
      );
    }
    case MemberTypeEnum.StrIns:
    case MemberTypeEnum.RndIns:
    case MemberTypeEnum.Instance: {
      const accountTitle = getAccountTitle(item);
      const instanceState = item.mState ? instanceStates[item.mState] : undefined;
      const StatusIcon = StatusIcons[instanceState?.icon ?? ''];
      return (
        <ItemFooterWrapper>
          <HStack gap="6px">
            <ItemDate variant="caption">{accountTitle}</ItemDate>
            <ItemDate variant="caption">
              <StyledScheduleOn />
              {item.mPublishingAt ? isoToLocaleShort(item.mPublishingAt) : 'Unscheduled'}
            </ItemDate>
            {StatusIcon && <StatusIcon style={{ height: '20px' }} className="skipOverride" />}
            <ItemDate variant="caption" style={{ height: '16px' }}>
              {instanceState?.name}
            </ItemDate>
          </HStack>
        </ItemFooterWrapper>
      );
    }
    case MemberTypeEnum.Note: {
      return (
        <ItemFooterWrapper>
          <ItemDate variant="caption">Note in a story</ItemDate>
        </ItemFooterWrapper>
      );
    }
    case MemberTypeEnum.User:
    case MemberTypeEnum.Contact:
    case MemberTypeEnum.Team:
    case MemberTypeEnum.Department: {
      return null;
    }
    case MemberTypeEnum.Rundown: {
      const rundownState = rundownStateLabel[item.mState ?? 'notReady'] ?? '';
      return (
        <ItemFooterWrapper>
          <ItemDate variant="caption">
            <StyledScheduleOn />
            {item.mPublishingAt ? isoToLocaleShort(item.mPublishingAt) : 'Unscheduled'}
          </ItemDate>

          <ItemDate variant="caption" style={{ position: 'relative', top: '2px' }}>
            <StyledPlanned />
            <span style={{ position: 'relative', top: '-4px' }}>
              {formatDuration(Number(item.mPlannedDuration ?? '0'))}
            </span>
          </ItemDate>

          {rundownState && (
            <RundownStatus
              variant="caption"
              status={(item.mState ?? 'notReady') as 'ready' | 'active' | 'notReady'}
            >
              {rundownState}
            </RundownStatus>
          )}
        </ItemFooterWrapper>
      );
    }
    case MemberTypeEnum.ResPitch:
    case MemberTypeEnum.Pitch:
    case MemberTypeEnum.ResStory:
    case MemberTypeEnum.Story: {
      const InstanceIcon = integrationIcons.instance;
      return (
        <>
          <ItemFooterWrapper>
            <ItemDate variant="caption">
              <StyledScheduleOn />
              {item.mPublishingAt ? isoToLocaleShort(item.mPublishingAt) : 'Unscheduled'}
            </ItemDate>
            <ItemInstances>
              <InstanceIcon height="1rem" width="1rem" />
              <Text variant="caption" color="mediumEmphasis">
                {`${instanceCount?.total ?? 0} instances`}
              </Text>
            </ItemInstances>
          </ItemFooterWrapper>
          <InstanceBadges badgeCountsByType={instanceCount} maxLines={1} />
        </>
      );
    }
    case MemberTypeEnum.Order: {
      const form = forms[item.mFormId ?? ''];
      const statuses = form?.configs?.find((c) => c.key === 'statuses')?.alternatives;
      const status = statuses?.find((s) => s.value === item.mState)?.label;
      return (
        <ItemFooterWrapper>
          <ItemDate variant="caption">{`${
            status ?? (item.mState ?? 'Create#active').split('#')[1]
          }`}</ItemDate>
        </ItemFooterWrapper>
      );
    }
    default:
  }
};

const Avatars = ({ item }: AvatarProps) => {
  const assignedMembers = item.mAssignedMembers;
  const taskAssignee = item.mTertRefId;
  if (!assignedMembers?.length && !taskAssignee) return null;
  const avatarsToDraw =
    item.mType === MemberTypeEnum.Order
      ? [{ mId: taskAssignee } as AssignedMemberType]
      : assignedMembers!;
  // Normalization fix for bad data. Take away any duplicate members to avoid
  // duplicate avatars in the list.
  const filtered = uniqBy(avatarsToDraw, (a) => a.mId);
  return (
    <HStack overflow="visible" gap="1px">
      <Assignees members={filtered as AssignedMember[]} size={18} maxAvatarToShow={2} />
    </HStack>
  );
};

const AvatarIcon = ({ item, members }: AvatarProps) => {
  const member = item.mType === MemberTypeEnum.Contact ? item : members[item.mId ?? ''];

  if (!member) return null;
  return (
    <Avatar
      variant={member.mType as AvatarVariant}
      imageKey={member.mAvatarKey}
      size={20}
      title={member.mTitle}
    />
  );
};

const getHighlight = (stringifiedHighlight?: string) => {
  if (!stringifiedHighlight) return;
  try {
    const text = JSON.parse(stringifiedHighlight) as unknown;
    if (isHighlight(text)) {
      if (text.mDescription?.length) {
        return {
          __html: `${safeForHTML(text.mDescription.join(' '))}`,
        };
      }
      if (text.textContent?.length) {
        return {
          __html: `${safeForHTML(text.textContent.join(' '))}`,
        };
      }
    }
  } catch (err: unknown) {
    console.error(err);
  }
};

const getDescription = (item: MemberType) => {
  if (item.mType === MemberTypeEnum.Contact) {
    try {
      const metadata = JSON.parse(item.metadata ?? '{}') as Metadata;
      return {
        __html: `${safeForHTML((metadata.email as string | undefined) ?? '')}`,
      };
    } catch {
      // continue
    }
  }

  if (item.mType === MemberTypeEnum.Asset) {
    return {
      __html: `${safeForHTML(getMetadataValue(item.mMetaData, 'caption'))}`,
    };
  }

  return (
    getHighlight(item.highlight) ?? {
      __html: `${safeForHTML(item.mDescription ?? '')}`,
    }
  );
};

export const getKey = (item: MemberType) => {
  if (
    item.mType &&
    [MemberTypeEnum.Note, MemberTypeEnum.Order, MemberTypeEnum.Block].includes(item.mType)
  ) {
    return item.mRefId!;
  }
  if (item.mType === MemberTypeEnum.Asset) {
    return `${item.mId}:${item.mRefId}`;
  }
  return item.mId!;
};

interface ListItemProps {
  item: MemberType;
  sortedBy: SortType | 'best';
  command: EditorCommandConfigType | undefined;
  members?: Record<string, AssignedMember>;
  priorityFilterApplied: boolean;
}

const ListItem = ({ item, members, sortedBy, command, priorityFilterApplied }: ListItemProps) => {
  const { isoToLocaleVeryShort } = useCustomDateTimeUtils();
  const { instanceStatusesById } = useGetStatuses();
  const { keyedOrderForms } = useGetOrderForms();
  const [priorityOptions] = usePriorities();

  const timeStamp = useMemo(() => {
    if (sortedBy === 'totalInstances' || sortedBy === 'title' || sortedBy === 'priority') {
      return isoToLocaleVeryShort(item.mUpdatedAt);
    }
    if (!item[sortToMemberTypeProp[sortedBy]]) return '';
    return isoToLocaleVeryShort(item[sortToMemberTypeProp[sortedBy]]);
  }, [isoToLocaleVeryShort, item, sortedBy]);

  const priority = useMemo(() => {
    if (!item.mPriority || (!priorityFilterApplied && sortedBy !== 'priority')) return;
    return (
      priorityOptions.find((p) => p.id === item.mPriority) ??
      ({ id: item.mPriority, label: item.mPriority } satisfies Priority)
    );
  }, [item.mPriority, priorityFilterApplied, priorityOptions, sortedBy]);

  const isRestrictedItem = isRestricted(item.mType ?? '');
  const description = getDescription(item);

  return (
    <VStack width="100%">
      <HStack gap="2px" width="100%">
        <AvatarWrapper>
          {!hasAvatar.includes(item.mType ?? MemberTypeEnum.Story) && (
            <Icon member={item} color={command?.mColor} />
          )}
          {members && hasAvatar.includes(item.mType ?? MemberTypeEnum.Story) && (
            <AvatarIcon item={item} members={members} />
          )}
        </AvatarWrapper>
        <ItemContentWrapper alignItems="start">
          <ItemTitleWrapper justifyContent="space-between">
            {priority && (
              <PriorityIndicator
                $color={priority.color}
                $textColor={colors[priority.color ?? '']?.text ?? undefined}
              >
                {priority.label}
              </PriorityIndicator>
            )}
            {isRestrictedItem && <RestrictedIcon />}
            <ItemTitle dangerouslySetInnerHTML={getHighlightedTitle(item, keyedOrderForms)} />
          </ItemTitleWrapper>
          {description.__html !== '' && <ItemDescription dangerouslySetInnerHTML={description} />}
          <ItemFooter
            forms={keyedOrderForms}
            item={item}
            instanceStates={instanceStatusesById}
            command={command}
          />
        </ItemContentWrapper>
        <VStack flexShrink={0} justifyContent="end" alignItems="end" gap="4px" height="100%">
          {sortedBy === 'totalInstances' ? null : (
            <Box flexShrink={0}>
              <Tooltip title={sortToTooltip[sortedBy]}>
                <ItemDate variant="caption">{timeStamp}</ItemDate>
              </Tooltip>
            </Box>
          )}
          {members && (item.mAssignedMembers || typeof item.mTertRefId === 'string') && (
            <Avatars item={item} members={members} />
          )}
        </VStack>
      </HStack>
    </VStack>
  );
};

const areEqual = (prevProps: ListItemProps, nextProps: ListItemProps) => {
  return JSON.stringify(prevProps) === JSON.stringify(nextProps);
};

export const MemoizedListItem = memo(ListItem, areEqual);

const SearchItem = ({
  openItem,
  shareWith,
  item,
  index,
  members,
  sortedBy,
  value,
  onContextMenu,
  commandById,
  priorityFilterApplied,
  selected,
  onDoubleClick = () => null,
}: SearchItemProps) => {
  const key = getKey(item);
  const isBlock = item.mType === MemberTypeEnum.Block;
  const command = commandById && isBlock && item.mSecId ? commandById[item.mSecId] : undefined;

  const [handleClick, handleDoubleClick] = useMouseClickEvents(
    () => openItem(item),
    () => onDoubleClick(item),
  );

  const [, drag] = useDrag({
    // TODO - refactor this type to be more abstract & generic.
    // It should become the default drag type from cmdk handled everywhere else.
    type: 'STORY_DRAG',
    item,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (_, monitor: DragSourceMonitor<User, { id: string }>) => {
      if (!monitor.didDrop()) return;
      if (
        typeof monitor?.getDropResult()?.id === 'string' &&
        item.mType &&
        canShareWithStory(item.mType)
      ) {
        const dropResId = monitor?.getDropResult()?.id;
        if (dropResId && item.mId && item.mType) {
          void shareWith?.(dropResId, item.mId, item.mType);
        }
      }
    },
  });

  return (
    <ThemePersistentWrapper selected={selected}>
      <Container
        key={key}
        ref={(r) => {
          if (r) drag(r.firstElementChild);
        }}
        $selected={selected}
        onClick={handleClick}
        onDoubleClick={handleDoubleClick}
      >
        <SearchItemWrapper
          className="search-item"
          forceMount={true}
          value={`${key} ${value}`}
          onContextMenu={(ev) => onContextMenu?.(item, ev)}
        >
          <UniqueId id={key ?? `${index}`} />
          <MemoizedListItem
            members={members}
            sortedBy={sortedBy}
            item={item}
            command={command}
            priorityFilterApplied={priorityFilterApplied}
          />
        </SearchItemWrapper>
      </Container>
    </ThemePersistentWrapper>
  );
};

export default SearchItem;
