import { atom, useAtom } from 'jotai';

import { KeyMap } from 'types/keyboardShortcut';

const editor = {
  INSERT_TIMESTAMP: {
    label: 'Insert timestamp',
    sequences: ['mod+alt+shift+T'],
  },
  MAKE_BOLD: {
    label: 'Bold text',
    mark: 'bold',
    sequences: ['mod+B'],
  },
  MAKE_ITALIC: {
    label: 'Italic text',
    mark: 'italic',
    sequences: ['mod+I'],
  },
  MAKE_UNDERLINE: {
    label: 'Underline text',
    mark: 'underline',
    sequences: ['mod+U'],
  },
} satisfies Record<string, KeyMap>;

const editorAtom = atom<Record<keyof typeof editor, KeyMap>>(editor);

export const useEditorKeyMap = () => useAtom(editorAtom);
