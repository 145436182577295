import { memo } from 'react';

import useSharedResource, { STATELESS_PROPS } from 'hooks/useSharedResource';

import ActiveUsers, { getListedSessionUser } from './ActiveUsers';

/**
 * The properties for the ActiveResourceUser component.
 */
export interface ActiveResourceUserProps {
  /**
   * The ID to be used to identify the resource. Must not exceed 50 characters and must uniquely
   * identify the resource even if all non-alphanumerical characters are replaced with a dash (-).
   *
   * An empty string will be considered noop and no connection will be made.
   */
  resourceId: string;

  /**
   * The maximum number of avatars to show. Defaults to 5.
   */
  maxAvatarToShow?: number;
}

function ActiveResourceUsers({
  resourceId,
  maxAvatarToShow = 5,
}: Readonly<ActiveResourceUserProps>) {
  const { configured, others } = useSharedResource(
    resourceId ? `default/resource/${resourceId.replace(/[^a-zA-Z0-9-]/g, '-')}` : '',
    STATELESS_PROPS,
  );
  if (!configured) return <span />;
  return (
    <ActiveUsers
      participants={others}
      enrichUser={getListedSessionUser}
      maxAvatarToShow={maxAvatarToShow}
    />
  );
}

export default memo(ActiveResourceUsers);
