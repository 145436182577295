import React, { ChangeEvent, useCallback, useRef, useState } from 'react';
import {
  Autocomplete,
  AutocompleteRenderGroupParams,
  AutocompleteRenderInputParams,
} from '@material-ui/lab';
import { useVirtualizer } from '@tanstack/react-virtual';
import { capitalize } from 'lodash';

import Avatar, { AvatarVariant } from 'components/avatar/Avatar';
import Chip from 'components/chip';
import LoadingIndicator from 'components/loadingIndicator';
import { StyledTextField } from 'components/mdfEditor/fields/text/styled';
import Text from 'components/text/Text';
import Tooltip from 'components/tooltip';
import { useSearchMembers } from 'hooks/useSearchMembers';
import { Box } from 'layouts/box/Box';
import { SearchItemTypeEnum } from 'types/graphqlTypes';
import { AssignedMember } from 'types/members';
import { getParseMetadata } from 'utils/getParsedMetadata';

import {
  ChipWrapper,
  ItemWrapper,
  ListScrollContainer,
  ListWrapper,
  StyledOption,
  StyledOptionWrapper,
  StyledPopper,
} from './styled';

interface MemberAutoCompleteProps {
  selectedMembers: AssignedMember[];
  allMembers: AssignedMember[];
  setSelectedMembers: (values: AssignedMember[]) => void;
  onRemove: (value: AssignedMember, index: number) => void;
  onClose: () => void;
  autoFocus: boolean;
  selectOnFocus: boolean;
  placeholderText: string;
  noOptionsText: string;
  singleChoice: boolean;
  disableClearable: boolean;
  showChips: boolean;
  doCallApi: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
}
interface ListboxComponentProps {
  children?: React.ReactNode;
}

const ListboxComponent = React.forwardRef<HTMLDivElement, ListboxComponentProps>(
  function ListboxComponent(props, ref) {
    const containerRef = useRef<HTMLDivElement>(null);
    const { children, ...other } = props;
    const itemData = React.Children.toArray(children);
    const rowVirtualizer = useVirtualizer({
      count: itemData.length,
      getScrollElement: () => containerRef.current,
      estimateSize: () => 28,
      overscan: 5,
    });

    return (
      <Box ref={ref} {...other} width="100%" height="420px">
        <ListWrapper ref={containerRef}>
          <ListScrollContainer height={rowVirtualizer.getTotalSize()}>
            {rowVirtualizer.getVirtualItems().map((virtualItem) => {
              const member = itemData[virtualItem.index];

              if (!React.isValidElement(member)) {
                return null;
              }
              return (
                <ItemWrapper
                  key={virtualItem.key}
                  height={virtualItem.size}
                  startPosition={virtualItem.start}
                >
                  {React.cloneElement(
                    member as React.ReactElement<{ style?: React.CSSProperties }>,
                    {
                      key: virtualItem.index,
                      style: {
                        height: virtualItem.size,
                      },
                    },
                  )}
                </ItemWrapper>
              );
            })}
          </ListScrollContainer>
        </ListWrapper>
      </Box>
    );
  },
);

export default function MemberAutoComplete({
  selectedMembers = [],
  allMembers,
  setSelectedMembers = () => {},
  onRemove,
  onClose = () => {},
  onFocus,
  onBlur,
  autoFocus = false,
  selectOnFocus = false,
  placeholderText = 'Type to search, or press down to browse',
  noOptionsText = 'None found, or all available resources already added',
  singleChoice = true,
  disableClearable = true,
  showChips = true,
  doCallApi = false,
}: Readonly<MemberAutoCompleteProps>) {
  const [searchString, setSearchString] = useState('');
  const { items: searchResults, loading } = useSearchMembers({
    inputs: {
      mTypes: [SearchItemTypeEnum.contact],
    },
    searchString: searchString,
    skip: !doCallApi,
  });

  const renderGroup = useCallback((params: AutocompleteRenderGroupParams) => {
    return [
      <Box key={params.key} padding="2px 16px" height="100%">
        <Text variant="listItemLabel" color="highEmphasis">
          {capitalize(params.group)}
        </Text>
      </Box>,
      params.children,
    ];
  }, []);

  const renderOption = useCallback((member: AssignedMember) => {
    return (
      <StyledOptionWrapper>
        <Avatar
          variant={member.mType as AvatarVariant}
          imageKey={member.mAvatarKey}
          size={24}
          title={member.mTitle}
        />
        <StyledOption className="styled-option" variant="listItemLabel" color="highEmphasis">
          {member.mTitle}
        </StyledOption>
      </StyledOptionWrapper>
    );
  }, []);

  const renderTags = useCallback(
    (value: AssignedMember[]) =>
      value.map((member, index) => (
        <Tooltip title={member.mTitle} key={member.mId}>
          <ChipWrapper>
            <Chip
              variant={member?.mType as AvatarVariant}
              onDelete={() => {
                onRemove(member, index);
              }}
              label={member.mTitle}
              avatarKey={member.mAvatarKey}
            />
          </ChipWrapper>
        </Tooltip>
      )),
    [onRemove],
  );

  const onUpdateInput = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setSearchString(e.target.value),
    [],
  );

  const renderInput = useCallback(
    (params: AutocompleteRenderInputParams) => {
      return (
        <StyledTextField
          {...params}
          autoFocus={autoFocus}
          variant="filled"
          placeholder={placeholderText}
          onChange={onUpdateInput}
        />
      );
    },
    [autoFocus, placeholderText, onUpdateInput],
  );

  const onChange = useCallback(
    (newValue: AssignedMember[] | AssignedMember | null) => {
      const updatedMembers = newValue ?? [];
      setSelectedMembers(Array.isArray(updatedMembers) ? updatedMembers : [updatedMembers]);
    },
    [setSelectedMembers],
  );

  const getFilteredSearchResults = useCallback(() => {
    return searchResults?.filter((member) => {
      return !getParseMetadata(member?.metadata)?.notListed;
    }) as AssignedMember[];
  }, [searchResults]);

  const determineOptions = useCallback(
    () => (doCallApi ? getFilteredSearchResults() : allMembers),
    [doCallApi, allMembers, getFilteredSearchResults],
  );

  return (
    <Autocomplete
      noOptionsText={noOptionsText}
      fullWidth
      selectOnFocus={selectOnFocus}
      filterSelectedOptions
      multiple={!singleChoice}
      options={determineOptions()}
      clearOnBlur={false}
      disableClearable={disableClearable}
      forcePopupIcon={false}
      onClose={onClose}
      value={selectedMembers}
      getOptionSelected={(option, value) => option.mId === value.mId}
      PopperComponent={StyledPopper}
      onChange={(_ev, value) => onChange(value)}
      renderTags={showChips ? renderTags : () => null}
      renderInput={renderInput}
      renderOption={renderOption}
      renderGroup={renderGroup}
      disableListWrap
      ListboxComponent={ListboxComponent as React.ComponentType<React.HTMLAttributes<HTMLElement>>}
      groupBy={(option) => option.mType}
      getOptionLabel={(option) => option?.mTitle ?? ''}
      loading={loading}
      loadingText={<LoadingIndicator height={20} width={20} />}
      onFocus={onFocus}
      onBlur={onBlur}
    />
  );
}
