/* eslint-disable no-console */
import { useCallback } from 'react';
import { gql, useMutation } from '@apollo/client';

import { useSnippets } from 'store';
import {
  CreateMemberInput,
  MemberType,
  MemberTypeEnum,
  UpdateMemberInput,
} from 'types/graphqlTypes';
import { MIdRefId } from 'types/members';
import { EditorSnippetsConfigType } from 'types/memberTypes/editorCommands';
import useLogger from 'utils/useLogger';
type CreateSnippetInput = Pick<
  CreateMemberInput,
  'mId' | 'mRefId' | 'mResourceType' | 'metadata' | 'slashCommand' | 'mTitle' | 'mColor'
>;

const BLOCK_PROPS = `
  mId
  mRefId
  mTitle
  metadata
  slashCommand
  mType
  mResourceType
  mColor
  
`;

const CREATE_SNIPPET = gql`
  mutation CreateSnippet($input: CreateMemberInput) {
    createMember(input: $input) {
      ${BLOCK_PROPS}
    }
  }
`;
const UPDATE_SNIPPET = gql`
  mutation updateEditorCommand($input: UpdateMemberInput) {
    updateMember(input: $input) {
      ${BLOCK_PROPS}
    }
  }
`;

const DELETE_SNIPPET = gql`
  mutation DeleteMember($input: DeleteSingleMemberInput) {
    deleteSingleMember(input: $input) {
      mId
      mRefId
    }
  }
`;

interface CreateSnippetResult {
  createMember: MemberType;
}
interface DeleteSnippet {
  deleteSingleMember: MIdRefId;
}
interface UpdateEditorCommandType {
  updateMember: EditorSnippetsConfigType;
}

const getErrorMessage = <E, I>(err: E, input: I): string =>
  `Could not create block: ${err instanceof Error ? err.message : ''} - input: ${JSON.stringify(
    input,
  )}`;

export const useManageSnippets = () => {
  const [createMutation] = useMutation<CreateSnippetResult>(CREATE_SNIPPET);
  const [deleteMember] = useMutation<DeleteSnippet>(DELETE_SNIPPET);
  const [updateMutation] = useMutation<UpdateEditorCommandType>(UPDATE_SNIPPET);

  const [, setSnippets] = useSnippets();
  const logger = useLogger('CreateSnippet');

  const createSnippet = useCallback(
    async (input: CreateSnippetInput) => {
      try {
        await createMutation({
          variables: {
            input: {
              ...input,
              mType: MemberTypeEnum.Snippet,
              mId: 'snippet',
              mResourceType: MemberTypeEnum.Snippet,
              mActive: true,
            },
          },
          update: (_proxy, mutationResult) => {
            if (mutationResult.data?.createMember) {
              setSnippets((prev) => [
                ...prev,
                mutationResult.data?.createMember as EditorSnippetsConfigType,
              ]);
            }
          },
        });
      } catch (err) {
        logger.log(getErrorMessage(err, input));
      }
    },
    [createMutation, logger, setSnippets],
  );

  const updateSnippet = useCallback(
    async (input: UpdateMemberInput) => {
      const variables = {
        input: {
          ...input,
          mActive: true,
        },
      };
      try {
        await updateMutation({
          variables,
          update: (_proxy, mutationResult) => {
            if (mutationResult.data?.updateMember) {
              const updatedSnippet = mutationResult.data?.updateMember;
              const { mRefId } = updatedSnippet;
              setSnippets((prev) =>
                prev.map((snippet) => (snippet.mRefId === mRefId ? updatedSnippet : snippet)),
              );
            }
          },
        });
      } catch (err) {
        logger.log(getErrorMessage(err, input));
      }
    },
    [logger, setSnippets, updateMutation],
  );

  const deleteSnippet = useCallback(
    async (mId: string, mRefId: string) => {
      try {
        await deleteMember({
          variables: { input: { mId, mRefId } },
          update: (_proxy, mutationResult) => {
            const deletedSnippet = mutationResult.data?.deleteSingleMember;
            if (deletedSnippet) {
              setSnippets((prev) =>
                prev.filter((snippet) => snippet.mRefId !== deletedSnippet.mRefId),
              );
            }
          },
        });
      } catch (err) {
        logger.log(getErrorMessage(err, { mId, mRefId }));
      }
    },
    [deleteMember, setSnippets, logger],
  );

  return { createSnippet, deleteSnippet, updateSnippet };
};
