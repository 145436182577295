import { useCallback, useState } from 'react';

import useGetOrganizations from 'api/organization/useGetOrganizations';
import { useSelectedGroupCategoryAtom } from 'screens/main/components/header/navbar/settings/atoms';
import {
  useCurrentOrganization,
  useSelectedLeftMenu,
  useSelectedOrganization,
} from 'screens/main/components/header/navbar/settings/atomsTs';
import List from 'screens/main/components/header/navbar/settings/components/collapseList';
import GROUP_SETTING_TYPES from 'screens/main/components/header/navbar/settings/utils/groupSettingTypes';
import { OrgAccessTypeEnum, Organization } from 'types/graphqlTypes';

import useIsSuperUser from '../../useIsSuperUser';

import CreateNewOrganization from './CreateNewOrganization';

function OrganizationComponent() {
  const isSuperUser = useIsSuperUser();
  const { organizations } = useGetOrganizations(OrgAccessTypeEnum.Readable);
  const [open, setOpen] = useState(false);
  const [selectedLeftMenu, setSelectedLeftMenu] = useSelectedLeftMenu();
  const [category, setCategory] = useSelectedGroupCategoryAtom();
  const [selectedOrganizationMenu, setSelectedOrganizationMenu] = useSelectedOrganization();
  const [, setCurrentOrganization] = useCurrentOrganization();
  const onOpen = useCallback(() => setOpen(true), []);
  const onClose = useCallback(() => setOpen(false), []);

  const onClick = (org: Organization) => {
    setSelectedOrganizationMenu(GROUP_SETTING_TYPES.ORGANIZATION);
    setCategory(GROUP_SETTING_TYPES.ORGANIZATION);
    setSelectedLeftMenu(org.id);
    setCurrentOrganization(org);
  };

  return (
    <>
      <List>
        <List.Header
          itemCount={organizations.length}
          selected={
            category === GROUP_SETTING_TYPES.ORGANIZATION &&
            selectedOrganizationMenu === GROUP_SETTING_TYPES.ORGANIZATION
          }
          hideCreateButton={!isSuperUser}
          createButtonTooltip={undefined}
          createButtonDisabled={undefined}
          onCreateClick={onOpen}
          onClick={() => {
            if (organizations[0]) onClick(organizations[0]);
          }}
        >
          Organizations
        </List.Header>
        <List.Body>
          {organizations?.map((organization) => (
            <List.Item
              key={organization.id}
              selected={
                selectedLeftMenu === organization.id &&
                selectedOrganizationMenu === GROUP_SETTING_TYPES.ORGANIZATION
              }
              onClick={() => onClick(organization)}
              itemCount={undefined}
              id={organization.id}
              italic={undefined}
              className={undefined}
            >
              {organization.title}
            </List.Item>
          ))}
        </List.Body>
      </List>
      {open && <CreateNewOrganization onClose={onClose} />}
    </>
  );
}

export default OrganizationComponent;
