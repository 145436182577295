import MUISkeleton from '@material-ui/lab/Skeleton';

import { HStack, VStack } from 'layouts/box/Box';

function SkeletonLoading() {
  return (
    <VStack width="100%" height="100%">
      <VStack width="100%" gap="16px">
        {Array(2)
          .fill(null)
          .map((_u, i) => (
            <HStack justifyContent="space-between" key={i} width="100%">
              <MUISkeleton animation="wave" width="5%" height={32} />
              <MUISkeleton animation="wave" width="74%" height={32} />
              <MUISkeleton animation="wave" width="15%" />
            </HStack>
          ))}
      </VStack>
    </VStack>
  );
}

export default SkeletonLoading;
