import PropTypes from 'prop-types';
import Divider from 'components/divider';
import Text from 'components/text/Text';
import { chunkArray } from 'utils/arrayUtils';
import useDateTimeUtils from 'hooks/useDateTimeUtils';
import useCustomDateTimeUtils from 'hooks/useCustomDateTimeUtils';
import useGetDates from 'hooks/useGetDates';
import DatePicker from '../datePicker';
import { timeVariants } from 'utils/planningViews';
import {
  RootWrapper,
  CalendarHeader,
  CalendarBody,
  CalendarRow,
  Row,
  Cell,
  DateDivider,
} from './styled';
import useDateSelectorUtils from './useDateSelectorUtils';

const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

const DateSelector = ({
  date,
  setDate,
  onDateSelect,
  startDate,
  endDate,
  selectRange,
  disableScheduleInPast,
  ...rest
}) => {
  const {
    getISOWeek,
    eachDayOfInterval,
    format,
    isToday,
    isSameMonth,
    isWeekend,
    isSameDay,
    isWithinInterval,
  } = useDateTimeUtils();
  const { isBeforeToday } = useCustomDateTimeUtils();
  const { getCellColor, getWeekNumbers, selectCalendarHeaderStyle } = useDateSelectorUtils();
  const { getDates } = useGetDates();
  const { startDate: sDate, endDate: eDate } = getDates(date, timeVariants.MONTH);

  const weekNumbers = getWeekNumbers(sDate);

  const isCurrentWeek = getISOWeek(new Date());

  const chunkedArray = chunkArray(eachDayOfInterval({ start: sDate, end: eDate }), 7);

  const dateSelectedChecker = (selectedRangeDate) => {
    const isoRangeDate = new Date(selectedRangeDate).toISOString();

    // for single date checker without range
    if (!selectRange) return [isSameDay(isoRangeDate, date), null];

    if (!startDate) {
      // date is not selected or not within a range
      return [false, null];
    }

    if (!endDate) {
      if (isSameDay(isoRangeDate, startDate)) {
        // date is selected as the start of the range
        return [true, null];
      }
      // date is not selected or not within a range
      return [false, null];
    }

    if (isSameDay(startDate, endDate)) {
      if (isSameDay(isoRangeDate, startDate)) {
        // date is selected as a single-day range
        return [true, null];
      }
      // date is not selected or not within a range
      return [false, null];
    }

    if (isSameDay(isoRangeDate, endDate)) {
      // date is selected as the end of the range
      return [true, 'right'];
    }

    if (isSameDay(isoRangeDate, startDate)) {
      // date is selected as the start of the range
      return [true, 'left'];
    }

    if (
      startDate < endDate &&
      !isWithinInterval(isoRangeDate, { start: startDate, end: endDate })
    ) {
      // date is not selected or not within a range
      return [false, null];
    }

    if (isSameDay(startDate, endDate)) {
      // date is selected as a single-day range
      return [true, null];
    }

    // date is selected and within the range
    return [true, 'middle'];
  };

  return (
    <RootWrapper>
      <CalendarHeader>
        <Cell>
          <Text variant="overline" color="mediumEmphasis">
            W
          </Text>
        </Cell>
        {daysOfWeek.map((dayOfWeek, index) => (
          <Cell key={dayOfWeek}>
            <Text variant="overline" color={getCellColor(index)}>
              {dayOfWeek}
            </Text>
          </Cell>
        ))}
      </CalendarHeader>
      <Divider />

      <CalendarBody>
        {weekNumbers.map((week, index) => (
          <CalendarRow key={week}>
            <Row>
              <Cell>
                <Text
                  variant="listItemLabel"
                  color={isCurrentWeek === week ? 'onSelected' : 'disabled'}
                >
                  {week}
                </Text>
              </Cell>
              <Divider orientation="vertical" flexItem />

              {chunkedArray[index].map((d) => {
                const disabled = disableScheduleInPast && isBeforeToday(d);
                const [isSelected, rangePosition] = dateSelectedChecker(d);

                return (
                  <Cell
                    role="presentation"
                    onClick={() => {
                      if (disabled) return;
                      if (!startDate || endDate || !selectRange) {
                        setDate(d.toISOString());
                        onDateSelect(d.toISOString(), 'date');
                      } else {
                        onDateSelect(d.toISOString(), 'endDate');
                      }
                    }}
                    key={d}
                  >
                    <DatePicker
                      text={format(d, 'd')}
                      isWeekend={isWeekend(d)}
                      isOtherMonth={!isSameMonth(d, date)}
                      isToday={isToday(d)}
                      isSelected={isSelected}
                      rangePosition={rangePosition}
                      disabled={disabled}
                      selectingRange={selectRange && startDate && !endDate}
                      {...rest}
                    />
                  </Cell>
                );
              })}
            </Row>
            <DateDivider />
          </CalendarRow>
        ))}
      </CalendarBody>
    </RootWrapper>
  );
};

DateSelector.propTypes = {
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]).isRequired,
  setDate: PropTypes.func,
  onDateSelect: PropTypes.func,
  startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  isSelectingRange: PropTypes.bool,
};

DateSelector.defaultProps = {
  setDate: () => {},
  onDateSelect: () => {},
  startDate: '',
  endDate: '',
  isSelectingRange: false,
};

export default DateSelector;
