import { elementTypes, markTypes } from 'components/editor/constants';
import { Box } from 'layouts/box/Box';

import ColorButton from '../colorButton';
import EmojiPicker from '../emojiPicker';
import ListButton from '../listButton';
import MarkButton from '../markButton';
import VerticalDivider from '../verticalDivider';
import Wrapper from '../wrapper';

const { BOLD, ITALIC, UNDERLINE, STRIKE_THROUGH } = markTypes;

const { UNORDERED_LIST, ORDERED_LIST } = elementTypes;

const ScriptToolbar = () => {
  return (
    <Box container width="100%" height="100%" justifyContent="flex-start" flexWrap="wrap">
      <Wrapper showRightMargin rightMarginWidth={8}>
        <MarkButton type={BOLD} />
        <MarkButton type={ITALIC} />
        <MarkButton type={UNDERLINE} />
        <MarkButton type={STRIKE_THROUGH} />
      </Wrapper>
      <VerticalDivider />
      <ColorButton />
      <Wrapper className="list-button" showRightMargin rightMarginWidth={8}>
        <VerticalDivider />
        <ListButton type={ORDERED_LIST} />
        <ListButton type={UNORDERED_LIST} />
      </Wrapper>
      <Wrapper>
        <VerticalDivider />
        <EmojiPicker />
      </Wrapper>
    </Box>
  );
};

export default ScriptToolbar;
