import { memo, useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import isEqual from 'lodash/isEqual';

import editorVariants from 'components/editor/constants/types/editorVariants';
import Editor from 'components/editor/Editor';
import { UpdateInput } from 'components/editor/types';
import removeTrailingEmptyParagraphs from 'components/editor/utils/removeTrailingEmptyParagraphs';
import { useUsers } from 'store/members';
import { EditorValue } from 'types/editor';
import useLogger from 'utils/useLogger';

const Container = styled('div')`
  width: 100%;
  min-height: 42px;
  border-radius: 8px;
  border: ${({ theme }) => `0.5px solid ${theme.palette.dina.inputBorderResting}`};
  overflow: hidden;
  :focus-within {
    border: 0.5px solid ${({ theme }) => theme.palette.dina.inputBorderFocused};
  }
`;

interface Props {
  onSave: (value?: EditorValue) => Promise<void>;
  initialValue?: EditorValue;
  isEditing?: boolean;
}

function CommentInput({ initialValue, onSave, isEditing }: Props) {
  const logger = useLogger('comment mutations');
  const [users] = useUsers();

  const [value, setValue] = useState<EditorValue | null>(null);
  const [editorValue, setEditorValue] = useState<UpdateInput | null>(null);

  useEffect(() => {
    if (initialValue) {
      setValue(initialValue);
    }
  }, [initialValue]);

  const onDone = useCallback(() => {
    if (editorValue?.payload) {
      const tComment = removeTrailingEmptyParagraphs(editorValue.payload as EditorValue);

      onSave(
        !(isEqual(tComment, value) || isEqual(tComment, initialValue)) ? tComment : undefined,
      ).catch((e) => logger.warn(e));

      setValue(null);
      setEditorValue(null);
    } else {
      onSave(undefined).catch((e) => logger.warn(e));
    }
  }, [editorValue?.payload, initialValue, logger, onSave, value]);

  const update = useCallback(async (input: UpdateInput) => {
    setEditorValue(input);
  }, []);

  return (
    <Container>
      <Editor
        autoFocus
        isAllowed
        enableEmoji
        users={users}
        height={40}
        readOnly={false}
        value={value}
        showHoveringTooltip={false}
        padding={0}
        onDone={onDone}
        update={update}
        toolbarPosition="none"
        placeholder="Add comment or use @ to mention..."
        variant={editorVariants.MESSAGE}
        keepFocus={!isEditing}
      />
    </Container>
  );
}

export default memo(CommentInput);
