import { Asset } from 'types';

/**
 * normalize asset data for preview
 * @param newAssetData - getQuery Asset Data
 * @returns normalize asset data
 */

const setAssetProperties = (newAssetData: Asset) => {
  if (!newAssetData) return;

  const assetFromMetadata: Record<string, string | undefined> = {};

  newAssetData.mMetaData?.forEach(({ key, value }) => {
    if (['mediaSize', 'mediaDuration', 'provider', 'mProvider'].includes(key))
      assetFromMetadata[key] = value;
  });

  return {
    ...newAssetData,
    itemDuration: assetFromMetadata.mediaDuration,
    assetType: newAssetData.itemType,
    proxy: newAssetData.mContentUrl,
    mAssetId: newAssetData.mAssetId,
    mProvider: assetFromMetadata?.provider ?? assetFromMetadata.mProvider,
  };
};

export default setAssetProperties;
