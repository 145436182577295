import styled from '@emotion/styled';

import { ReactComponent as Close } from 'assets/icons/systemicons/close.svg';

export const StyledSpanWrapper = styled('span')`
  .link-collapse-item:hover .link-remove {
    opacity: 0.7;
  }
`;

export const StyledWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

export const StyledSpan = styled('span')`
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledClose = styled(Close)`
  opacity: 0;
  align-self: center;
  &:hover {
    opacity: 1;
  }
`;
