import { useCallback } from 'react';

import { convertToRestrictedOnlyTypes, SearchResult } from 'api/search';
import useGroupDate from 'features/storyHub/hooks/useGroupDate';
import useDateTimeUtils from 'hooks/useDateTimeUtils';
import { aggregationFieldName } from 'screens/storyHub/store/storyHub';
import {
  type MemberType,
  type Metadata,
  type SearchItemInput,
  SearchItemTypeEnum,
} from 'types/graphqlTypes';
import { isRestricted } from 'utils/member/mTypes';

export type AggregationOptions = {
  oldBucketKey?: string;
  newBucketKey?: string;
};

const compareFunction = (a: unknown, b: unknown): number => {
  const strA = String(a);
  const strB = String(b);
  if (strA === strB) return 0;
  return strA > strB ? 1 : -1;
};

const useCrudAction = () => {
  const { isAfter, isBefore } = useDateTimeUtils();
  const { getBucketKey } = useGroupDate();

  /**
   * modifies an item in a list of items while preserving the __typename prop of the items
   */
  const modifyItemsExceptTypenameProp = (items: MemberType[], member: MemberType) =>
    items.map((i) => {
      if (i.mId === member.mId && i.mRefId === member.mRefId) {
        // eslint-disable-next-line @typescript-eslint/naming-convention, no-unused-vars
        const { __typename, ...rest } = member;
        return { ...i, ...rest };
      }
      return i;
    });

  /**
   * Inserts the member into search query in cache
   */
  const insert = useCallback(
    (
      result: SearchResult,
      member: MemberType,
      aggregationOptions?: AggregationOptions,
    ): SearchResult => {
      const newResult = { ...result };
      const searchItem = { ...newResult.searchItem };
      const items = [...searchItem.items];

      const item = items.find((i) => i.mId === member.mId && i.mRefId === member.mRefId);

      const newItems = item
        ? modifyItemsExceptTypenameProp(items, member)
        : [{ ...member, __typename: 'SearchItem' }, ...items];

      const aggregations = searchItem.aggregations?.map((aggregation) => {
        if (aggregation.fieldName !== aggregationFieldName) return aggregation;

        const stringKey = getBucketKey(member.mPublishingAt) ?? aggregationOptions?.newBucketKey;

        const aggregationResponse = { ...aggregation };
        const buckets = [...aggregationResponse.buckets];

        const foundBucket = buckets.find((bucket) => getBucketKey(bucket.stringKey) === stringKey);

        const updatedBuckets = foundBucket
          ? buckets.map((b) => {
              if (getBucketKey(b.stringKey) !== stringKey) return b;
              const bucket = { ...b };
              const bucketItems = bucket.items ? [...bucket.items] : undefined;
              if (bucketItems) {
                if (bucketItems.find((i) => i.mId === member.mId && i.mRefId === member.mRefId)) {
                  bucket.items = modifyItemsExceptTypenameProp(bucketItems, member);
                } else {
                  bucket.items = [
                    { ...member, __typename: 'SearchItem' } as unknown as MemberType,
                    ...bucketItems,
                  ];
                  bucket.total = bucket.total! + 1;
                }
              } else {
                bucket.items = [{ ...member, __typename: 'SearchItem' } as unknown as MemberType];
                bucket.total = 1;
              }
              return bucket;
            })
          : [
              {
                stringKey,
                items: [{ ...member, __typename: 'SearchItem' } as unknown as MemberType],
                total: 1,
              },
              ...buckets,
            ];
        aggregationResponse.buckets = updatedBuckets;
        return aggregationResponse;
      });

      const newSearchItem = {
        ...searchItem,
        items: newItems,
        total: searchItem.total + 1,
        aggregations,
      };

      return { searchItem: newSearchItem } as SearchResult;
    },
    [],
  );

  /**
   * Removes the member from search query in cache
   */
  const remove = useCallback((result: SearchResult, member: MemberType): SearchResult => {
    const newResult = { ...result };
    const searchItem = { ...newResult.searchItem };
    const items = searchItem.items ? [...searchItem.items] : [];

    const newItems = items.filter((i) => i.mId !== member.mId && i.mRefId !== member.mRefId);

    const aggregations = searchItem.aggregations?.map((aggregation) => {
      if (aggregation.fieldName !== aggregationFieldName) return aggregation;
      const aggregationResponse = { ...aggregation };
      aggregationResponse.buckets = aggregationResponse.buckets.map((b) => {
        const bucket = { ...b };
        bucket.items = bucket.items?.filter(
          (item) => item.mId !== member.mId && item.mRefId !== member.mRefId,
        );
        return bucket;
      });
      return aggregationResponse;
    });

    const newSearchItem = {
      ...searchItem,
      items: newItems,
      total: searchItem.total - 1,
      aggregations,
    };
    return { searchItem: newSearchItem } as SearchResult;
  }, []);

  /**
   * Modifies the Member in the search query cache, if the item is not found then inserts it
   */
  const modify = useCallback(
    (
      result: SearchResult,
      member: MemberType,
      aggregationOptions?: AggregationOptions,
    ): SearchResult => {
      const newResult = { ...result };
      const searchItem = { ...newResult.searchItem };
      const items = [...searchItem.items];

      const newKey = getBucketKey(member.mPublishingAt) ?? aggregationOptions?.newBucketKey;

      const aggregations = searchItem.aggregations?.map((aggregation) => {
        if (aggregation.fieldName !== aggregationFieldName) return aggregation;
        const aggregationResponse = { ...aggregation };
        let bucketExists = false;
        aggregationResponse.buckets = aggregation.buckets.map((b) => {
          const bucketKey = getBucketKey(b.stringKey);
          if (bucketKey === aggregationOptions?.oldBucketKey) {
            const bucket = { ...b };
            const filteredItems = bucket.items?.filter(
              (i) => i.mId !== member.mId && i.mRefId !== member.mRefId,
            );
            if (filteredItems?.length !== bucket?.items?.length) {
              bucket.items = filteredItems;
              bucket.total = bucket?.total ? bucket.total - 1 : bucket?.total;
            }
            return bucket;
          }
          if (bucketKey === newKey) {
            bucketExists = true;
            let itemExists = false;
            const bucket = { ...b };
            bucket.items = bucket?.items?.reduce(
              (acc, item) => {
                if (item.mId !== member.mId && item.mRefId !== member.mRefId) return [...acc, item];
                itemExists = true;
                return [{ ...item, ...acc[0] }, ...acc.slice(1)];
              },
              [{ ...member, __typename: 'SearchItem' } as unknown as MemberType],
            ) ?? [{ ...member, __typename: 'SearchItem' } as unknown as MemberType];
            bucket.total = bucket?.total
              ? bucket.total + (itemExists ? 0 : 1)
              : bucket.items.length;
            return bucket;
          }
          return b;
        });
        if (!bucketExists) {
          aggregationResponse.buckets.push({
            stringKey: newKey,
            items: [{ ...member, __typename: 'SearchItem' } as unknown as MemberType],
            total: 1,
          });
        }
        return aggregationResponse;
      });

      const item = items.find((i) => i.mId === member.mId && i.mRefId === member.mRefId);

      const newItems = item
        ? modifyItemsExceptTypenameProp(items, member)
        : [{ ...member, __typename: 'SearchItem' }, ...items];
      const newSearchItem = { ...searchItem, items: newItems, aggregations };
      return { searchItem: newSearchItem } as SearchResult;
    },
    [],
  );

  function arraysHaveSameValues(array1: unknown[], array2: unknown[]) {
    if (array1.length !== array2.length) return false;
    return (
      array1.toSorted(compareFunction).toString() === array2.toSorted(compareFunction).toString()
    );
  }

  const shouldRemove = useCallback(
    (
      data: MemberType,
      input: SearchItemInput,
      userId: string,
      restrictedItemsOnly: boolean = false,
    ) => {
      const {
        metaDataFilter: mdf = '{}',
        mTypes = [],
        assignedMemberIds = [],
        createdByIds = [],
        showRestricted = false,
      } = input;
      const metaDataFilter = JSON.parse(mdf) as Metadata;
      const { metadata: md = '{}', mAssignedMembers = [], mCreatedById, mType = '' } = data;

      if (isRestricted(mType)) {
        if (!mAssignedMembers.find((assignedMember) => assignedMember.mId === userId)) return true;
      }

      if (createdByIds.length > 0) {
        if (!mCreatedById) return true;
        if (!createdByIds.some((createdById) => mCreatedById === createdById)) return true;
      }

      if (
        assignedMemberIds.length > 0 &&
        !assignedMemberIds.some((assignedMemberId) =>
          mAssignedMembers.find((assignedMember) => assignedMember.mId === assignedMemberId),
        )
      ) {
        return true;
      }

      if (mTypes.length > 0) {
        const newMTypes = [...mTypes];
        if (showRestricted && !restrictedItemsOnly) {
          if (mTypes.includes(SearchItemTypeEnum.story))
            newMTypes.push(SearchItemTypeEnum.res_story);
          if (mTypes.includes(SearchItemTypeEnum.pitch))
            newMTypes.push(SearchItemTypeEnum.res_pitch);
        }
        if (
          !(restrictedItemsOnly ? convertToRestrictedOnlyTypes(newMTypes) : newMTypes)?.includes(
            mType as SearchItemTypeEnum,
          )
        ) {
          return true;
        }
      }
      const metadata = JSON.parse(md) as Metadata;

      let forceRemove = false;
      Object.entries(metaDataFilter).forEach(([key, value]) => {
        const metadataValue = metadata[key];
        if (forceRemove) return;

        if (Array.isArray(value)) {
          if (!Array.isArray(metadataValue)) {
            forceRemove = true;
            return;
          }
          forceRemove = !arraysHaveSameValues(value, metadataValue as unknown[]);

          return;
        }
        if (typeof value === 'object' && value !== null && 'from' in value && 'to' in value) {
          if (value.to === value.from) {
            forceRemove = value.to !== metadataValue;
            return;
          } else if (value.to === metadataValue || value.from === metadataValue) {
            return;
          }
          if (
            isAfter(metadataValue as string, value.from as string) &&
            isBefore(metadataValue as string, value.to as string)
          ) {
            return;
          }
          forceRemove = false;
          return;
        }
        if (typeof value === 'number' && Number(metadataValue) === value) {
          forceRemove = Number(metadataValue) !== value;
          return;
        }

        forceRemove = metadataValue !== value;
      });
      return forceRemove;
    },
    [],
  );

  return { modify, insert, remove, shouldRemove };
};

export default useCrudAction;
