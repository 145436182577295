import React, { useEffect, useState } from 'react';
import { ClickAwayListener, IconButton, InputAdornment } from '@material-ui/core';
import capitalize from 'lodash/capitalize';

import Calendar from 'components/calendar';
import DateTimePicker from 'components/dateTimePicker';
import InputField from 'components/inputField/InputField';
import Popover from 'components/popover/Popover';
import Popper from 'components/shared/popper';
import useCustomDateTimeUtils from 'hooks/useCustomDateTimeUtils';
import useDateTimeUtils from 'hooks/useDateTimeUtils';
import getLocaleTimeFormat from 'utils/dateTime/getLocaleTimeFormat';

import { CalendarContainer } from './styled';

interface PickerProps {
  /** Icon to be shown as end adornment of the input */
  Icon: React.ElementType;
  /** Icon to be shown instead of the first icon */
  SecondaryIcon?: React.ElementType;
  /** Boolean to show the secondary icon instead of the first */
  displaySecondaryIcon?: boolean;
  /** Type of Picker */
  type?: 'date' | 'time';
  /** Label of the input field */
  label?: string;
  /** Time value to be changed */
  timeValue?: string;
  /** Disables opening the popover */
  disable?: boolean;
  /** Callback to be invoked when time is changed. Value given as Date() */
  onChange?: (date: Date) => void;
  /** String to show if there is no time value */
  blankDisplayValue?: string;
  /** Boolean to disable scheduling in the past */
  disableScheduleDateInPast?: boolean;
}

const WithPopover: React.FC<{
  handleClose: () => void;
  anchorEl: HTMLElement | null;
  children: React.ReactNode;
}> = ({ children, handleClose, anchorEl }) => (
  <Popover onClose={handleClose} anchorEl={anchorEl}>
    <ClickAwayListener onClickAway={handleClose}>
      <div>{children}</div>
    </ClickAwayListener>
  </Popover>
);

function Picker({
  Icon,
  SecondaryIcon,
  displaySecondaryIcon = false,
  type = 'date',
  label = 'Date',
  timeValue,
  disable = false,
  onChange = () => {},
  blankDisplayValue = 'n/a',
  disableScheduleDateInPast,
}: PickerProps) {
  const { format, isThisMinute, isPast, setHours, setMinutes } = useDateTimeUtils();
  const { getRelativeDate } = useCustomDateTimeUtils();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [displayValue, setDisplayValue] = useState<string>(blankDisplayValue);
  const formatString = type === 'time' ? 'HH:mm' : 'yyyy-MM-dd';

  useEffect(() => {
    if (timeValue) {
      if (!disable && type === 'time') {
        setDisplayValue(format(timeValue, getLocaleTimeFormat()));
      }
      if (!disable && (isThisMinute(timeValue) || isPast(timeValue))) {
        setDisplayValue('Now');
      }
      if (type === 'date') {
        setDisplayValue(capitalize(getRelativeDate(timeValue, 'E. d MMM. yyyy')));
      }
    }
  }, [disable, timeValue, type, format, isThisMinute, isPast, getRelativeDate]);

  const openPicker = (event: React.MouseEvent<HTMLElement>) => {
    if (!disable) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => setAnchorEl(null);

  const handleOnChangeDate = (date: { startDate?: string | Date }) => {
    const startDate = date.startDate ? new Date(date.startDate) : new Date();
    onChange(startDate);
    setAnchorEl(null);
  };

  const handleTimeChange = (timeStr: string) => {
    const [hours, minutes] = timeStr.split(':');
    const previousDate = timeValue ? new Date(timeValue) : new Date();
    const newTime = setHours(setMinutes(previousDate, parseInt(minutes)), parseInt(hours));
    onChange(newTime);
    setAnchorEl(null);
  };

  return (
    <div>
      <InputField
        onClick={openPicker}
        label={label}
        type="text"
        readOnly
        usage="editor"
        value={displayValue}
        endAdornment={
          <InputAdornment position="end">
            <IconButton tabIndex={0}>
              {displaySecondaryIcon && SecondaryIcon ? <SecondaryIcon /> : <Icon />}
            </IconButton>
          </InputAdornment>
        }
      />
      {type === 'date' ? (
        <Popper anchorEl={anchorEl} placement="left">
          <ClickAwayListener onClickAway={handleClose}>
            <CalendarContainer>
              <Calendar
                selectedDateRange={{ startDate: timeValue ? new Date(timeValue) : new Date() }}
                changeSelectedDateRange={handleOnChangeDate}
                hideUnscheduleButton
                onClose={handleClose}
                disableScheduleInPast={disableScheduleDateInPast}
              />
            </CalendarContainer>
          </ClickAwayListener>
        </Popper>
      ) : (
        <WithPopover handleClose={handleClose} anchorEl={anchorEl}>
          <DateTimePicker
            type={type}
            defaultDate={format(timeValue || new Date(), formatString)}
            onBlur={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleTimeChange(event.target.value)
            }
          />
        </WithPopover>
      )}
    </div>
  );
}

export default Picker;
