import { createDecoder, readVarUint, readVarUint8Array } from 'lib0/decoding';
import { createEncoder, Encoder, writeVarUint, writeVarUint8Array } from 'lib0/encoding';
import { applyAwarenessUpdate, Awareness, encodeAwarenessUpdate } from 'y-protocols/awareness';
import { messageYjsSyncStep1, messageYjsSyncStep2, readSyncMessage } from 'y-protocols/sync';
import * as Y from 'yjs';

export const messageSync = 0;
export const messageQueryAwareness = 3;
export const messageAwareness = 1;

const enableLogging = false;
export const yjsLog: undefined | typeof console.log = enableLogging
  ? (...args) => console.log('YJS:', ...args) // eslint-disable-line no-console
  : undefined;

export interface Origin {
  readonly awareness: Awareness;
  readonly doc: Y.Doc;
}

export function readYjsMessage(
  origin: Origin,
  buf: Uint8Array,
  sourceIsSynced: boolean,
  syncedCallback: () => void,
): Encoder | null {
  const decoder = createDecoder(buf);
  const encoder = createEncoder();
  const messageType = readVarUint(decoder);
  let sendReply = false;
  const awareness = origin.awareness;
  switch (messageType) {
    case messageSync: {
      writeVarUint(encoder, messageSync);
      const syncMessageType = readSyncMessage(decoder, encoder, origin.doc, origin);
      yjsLog?.('syncMessage', syncMessageType);
      if (syncMessageType === messageYjsSyncStep2 && !sourceIsSynced) {
        syncedCallback();
      }
      if (syncMessageType === messageYjsSyncStep1) {
        sendReply = true;
      }
      break;
    }
    case messageQueryAwareness:
      writeVarUint(encoder, messageAwareness);
      writeVarUint8Array(
        encoder,
        encodeAwarenessUpdate(awareness, Array.from(awareness.getStates().keys())),
      );
      sendReply = true;
      break;
    case messageAwareness:
      applyAwarenessUpdate(awareness, readVarUint8Array(decoder), origin);
      break;
    default:
      // eslint-disable-next-line no-console
      console.error('Unable to compute message');
      return encoder;
  }
  if (!sendReply) {
    // nothing has been written, no answer created
    return null;
  }
  return encoder;
}
