import { useCallback, useEffect, useRef, useState } from 'react';

import resizeImage from 'utils/resizeImage';

import useFileUpload from './useFileUpload';

export interface UploadProps {
  file: File;
  filePath?: string;
}

interface Props {
  onImageLoad: (file: File | UploadProps[], filePath?: string) => void;
  width?: number;
  height?: number;
  quality?: number;
  disableResize?: boolean;
  imageTypes?: ('gif' | 'jpeg' | 'png')[];
  fileName?: string;
  multiple?: boolean;
}

const useImageUpload = ({
  onImageLoad,
  width = 96,
  height = 96,
  quality = 90,
  disableResize,
  imageTypes = ['gif', 'jpeg', 'png'],
  fileName = '',
  multiple,
}: Props) => {
  const [mimeTypes] = useState(imageTypes.map((fileType) => `image/${fileType}`));
  const tempUrls = useRef<string[]>([]);

  const processImage = useCallback(
    async (file: File, filePath?: string) => {
      if (disableResize) {
        return { file, filePath };
      }
      const [imageFile, imageUrl] = await resizeImage({
        file,
        width,
        height,
        quality,
        fileName,
      });
      tempUrls.current.push(imageUrl);
      return { file: imageFile, filePath: imageUrl };
    },
    [disableResize, fileName, height, quality, width],
  );

  const onLoad = useCallback(
    async (files: File | UploadProps[], filePaths: string) => {
      if (multiple && Array.isArray(files)) {
        const outputPromise = files.map(({ file, filePath }) => {
          return processImage(file, filePath);
        });
        const output = await Promise.all(outputPromise);
        onImageLoad(output);
      } else if (!Array.isArray(files)) {
        const { file, filePath } = await processImage(files, filePaths);
        onImageLoad(file, filePath);
      }
    },
    [multiple, onImageLoad, processImage],
  );

  const captureImage = useFileUpload(mimeTypes, onLoad, multiple);

  useEffect(
    () => () => {
      // Clean up any object URLs that were created when the hook is unmounted
      tempUrls.current.forEach((url) => {
        setTimeout(() => {
          URL.revokeObjectURL(url);
        }, 3000);
      });
    },
    [],
  );

  return captureImage;
};

export default useImageUpload;
