import { useCallback, useContext } from 'react';
import { ApolloQueryResult } from '@apollo/client';

import UserContext from 'contexts/UserContext';
import { ResourceDetails } from 'hooks/useResourceDetails';
import { useDoubleClickToLockEditor } from 'store';
import { QueryGetMemberArgs } from 'types/graphqlTypes';
import clickIfNothingSelected from 'utils/clickIfNothingSelected';
import { setLockToken } from 'utils/lock/lockTokenV2';

import useCreateScript from '../api/useCreateScript';
import { GetScriptsReturnType } from '../api/useSyncScript';

const useWrapperMouseEvents = ({
  scope,
  resourceDetails,
  shouldCreateScript,
  onFocusEditor,
  refetch,
}: {
  scope: string;
  shouldCreateScript: boolean;
  resourceDetails: ResourceDetails;
  onFocusEditor: () => Promise<void>;
  refetch: (
    variables?: Partial<QueryGetMemberArgs> | undefined,
  ) => Promise<ApolloQueryResult<GetScriptsReturnType>>;
}) => {
  const { mId: currentUserId } = useContext(UserContext);

  const { createScript, loading: creating, error: createError } = useCreateScript();

  const [doubleClickToLockEditor] = useDoubleClickToLockEditor();

  const onCreate = useCallback(async () => {
    if (!resourceDetails.resource.mId || !resourceDetails.resource.mStoryId) return;

    await createScript({
      mId: resourceDetails.resource.mId,
      mSecId: resourceDetails.resource.mStoryId,
      locked: setLockToken(scope, currentUserId, new Date().toISOString()),
    });
    await refetch();
  }, [
    createScript,
    currentUserId,
    refetch,
    resourceDetails.resource.mId,
    resourceDetails.resource.mStoryId,
    scope,
  ]);

  const onClick = useCallback(
    (ev: React.MouseEvent<HTMLElement, MouseEvent>) => {
      if (doubleClickToLockEditor) return;
      if (shouldCreateScript) {
        onCreate().catch(() => {});
        return;
      } else {
        clickIfNothingSelected(ev, onFocusEditor);
      }
    },
    [doubleClickToLockEditor, onCreate, onFocusEditor, shouldCreateScript],
  );

  const onDoubleClick = useCallback(() => {
    if (!doubleClickToLockEditor) return;
    if (shouldCreateScript) {
      onCreate().catch(() => {});
      return;
    } else {
      onFocusEditor?.().then(
        () => {},
        () => {},
      );
    }
  }, [doubleClickToLockEditor, onCreate, onFocusEditor, shouldCreateScript]);

  return { onClick, onDoubleClick, creating, createError };
};

export default useWrapperMouseEvents;
