/* eslint-disable import/no-extraneous-dependencies */
import { useCallback, useRef } from 'react';
import Autolinker from 'autolinker';
import PropTypes from 'prop-types';

import Tooltip from 'components/tooltip';
import { TooltipPlacement } from 'components/tooltip/Tooltip';
import useReadSpeedValue from 'features/feedViewer/hooks/useReadSpeedValue';
import useHighLightWords from 'hooks/useHighlightWords';

import { StyledText } from './styled';

const getHostnameFromRegex = (url: string) => {
  // eslint-disable-next-line no-useless-escape
  const matches = url.match(/^https?\:\/\/([^\/?#]+)(?:[\/?#]|$)/i);

  return (matches && matches[1]) || '[External URL]';
};

const replaceUrlWithHostname = (input: string) => {
  if (input.length < 20) {
    return input;
  }

  const url = input.substring(1, input.indexOf('<'));

  return ` target="_blank">${getHostnameFromRegex(url)}</a>`;
};

const formatAngleBracketUrls = (input: string) => {
  const regex =
    // eslint-disable-next-line max-len
    />(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})<\/a>/gim;
  const output = input.replaceAll(regex, replaceUrlWithHostname);

  return output;
};

interface ContentProps {
  text: string;
  searchWords: string[];
}

function Content({ text, searchWords }: ContentProps) {
  const contentRef = useRef(null);
  const [readSpeed] = useReadSpeedValue(contentRef, text);
  const highlight = useHighLightWords();

  const createMarkup = useCallback(() => {
    let input = text;

    if (input) {
      input = formatAngleBracketUrls(input);

      if (input.includes('<pre>') && input.includes('</pre>')) {
        input = input.replace('<pre>', '');
        input = input.replace('</pre>', '');
      }
    }

    return {
      __html: `${text && text !== 'null' ? highlight(Autolinker.link(input), searchWords) : ''}`,
    };
  }, [text, searchWords, highlight]);

  return (
    <Tooltip title={readSpeed} open={readSpeed !== '00:00'} placement={TooltipPlacement.TOP}>
      <StyledText ref={contentRef} dangerouslySetInnerHTML={createMarkup()} />
    </Tooltip>
  );
}

Content.propTypes = {
  text: PropTypes.string,
  searchWords: PropTypes.arrayOf(PropTypes.string),
};

Content.defaultProps = {
  text: '',
  searchWords: [],
};

export default Content;
