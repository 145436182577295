import { useCallback, useMemo, useState } from 'react';

import useInstances from 'screens/storyV2/utils/useInstances';

import Group, { Empty } from '../Group';

import Instance, { InstanceSkeleton } from './Instance';

function Instances({ storyId }: Readonly<{ storyId: string }>) {
  const [collapsed, setCollapsed] = useState(true);
  const { instances, loading } = useInstances(storyId, 'cache-and-network');

  const toggleCollapsed = useCallback(() => setCollapsed(!collapsed), [collapsed]);

  const moreCount = useMemo(() => Math.max(instances.length - 3, 0), [instances.length]);

  return (
    <Group
      label="Instances"
      collapsed={moreCount > 0 ? collapsed : undefined}
      toggleCollapsed={toggleCollapsed}
      moreCount={moreCount}
    >
      <>
        {loading ? (
          Array.from({ length: 3 }, (_, index) => <InstanceSkeleton key={index} />)
        ) : instances.length === 0 ? (
          <Empty label="Instances" />
        ) : (
          ((collapsed && instances.length > 3 ? instances.slice(0, 3) : instances) ?? []).map(
            (instance) => <Instance key={instance.mRefId} instance={instance} />,
          )
        )}
      </>
    </Group>
  );
}

export default Instances;
