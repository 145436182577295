import { atom, useAtom } from 'jotai';
import { createScope, molecule, useMolecule } from 'jotai-molecules';

export const SpaceScope = createScope(undefined);

const spaceMolecule = molecule((_getMol, getScope) => {
  getScope(SpaceScope);

  const selectedStoryIdAtom = atom('');
  const useSelectedStoryId = () => useAtom(selectedStoryIdAtom);

  return {
    useSelectedStoryId,
  };
});
export const useSpaceMolecule = () => useMolecule(spaceMolecule);

/* The id of the selected space view (tab) */
const activeSpaceViewAtom = atom<string | undefined>(undefined);

export const useActiveSpaceView = () => useAtom(activeSpaceViewAtom);
