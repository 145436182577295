import gql from 'graphql-tag';

export const CREATE_SCRIPT = gql`
  mutation CreateScript($input: CreateScriptInput) {
    createScript(input: $input) {
      mId
      mRefId
      mSecId
      mTitle
      mType
      mState
      mContentKey
      mLastVersion
      locked
      mUpdatedAt
      mCreatedAt
      mUpdatedById
    }
  }
`;

export const UPDATE_SCRIPT = gql`
  mutation UpdateScript($input: UpdateScriptInput) {
    updateScript(input: $input) {
      mId
      mRefId
      mSecId
      mTitle
      mType
      mState
      mContentKey
      mLastVersion
      locked
      mUpdatedAt
      mCreatedAt
      mUpdatedById
    }
  }
`;

export const GET_SCRIPT = gql`
  query getScript($input: GetScriptInput) {
    getScript(input: $input) {
      mId
      mRefId
      mSecId
      mTitle
      mType
      mState
      locked
      mContentKey
      mLastVersion
      mUpdatedAt
      mCreatedAt
      mUpdatedById
    }
  }
`;
