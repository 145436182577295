import { gql, useQuery } from '@apollo/client';

import { ProviderDTO } from 'types/graphqlTypes';

export const GET_ALL_PROVIDERS = gql`
  query GetAllProviders {
    getAllProviders {
      id
      title
      baseUrl
      endPoints {
        id
        url
      }
      auth {
        type
        headers {
          tenant
          apikeyId
        }
      }
    }
  }
`;

export interface GetProviders {
  getAllProviders: ProviderDTO[];
}

const useGetAllProvider = () => {
  const variables = {};
  const { data, loading, error } = useQuery<GetProviders>(GET_ALL_PROVIDERS, {
    variables,
    fetchPolicy: 'cache-and-network',
  });

  return { data: data?.getAllProviders, loading, error };
};

export default useGetAllProvider;
