import { MutableRefObject, useMemo } from 'react';

import useSyncedRef from './useSyncedRef';

/**
 * Returns a stable `Readonly` `MutableRefObject` providing the value of a getter function
 * @param getter The getter function
 * @returns      The resulting stable `Readonly` `MutableRefObject`
 * @template T   The type of the value returned by the getter function
 */
export default function useReadonlyRefOnGetter<T>(getter: () => T): Readonly<MutableRefObject<T>> {
  const getterRef = useSyncedRef(getter);
  return useMemo(
    () => ({
      get current() {
        return getterRef.current();
      },
    }),
    [getterRef],
  );
}
