/* eslint-disable react/no-array-index-key */
import { memo, useCallback, useMemo, useState } from 'react';
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import PropTypes from 'prop-types';
import { useReadOnly, useSlate } from 'slate-react';

import AutoComplete from 'components/autoCompleteBase';
import ChipComponent from 'components/chip';
import { elementTypes } from 'components/editor/constants/types';
import { useEditorContext } from 'components/editor/EditorContext';
import useChangeCollapse from 'components/editor/hooks/useChangeCollapse';
import updateBlock from 'components/editor/utils/updateBlock';
import useGetFieldsForBlock from 'hooks/useGetFieldsForBlock';
import fieldEnums from 'utils/constants/fieldEnums';

import Box from '../box';
import DragAndDrop from '../dragAndDrop';

import { AutoCompleteWrapper, ChipListWrapper, UserIcon } from './styled';

const Chip = memo(ChipComponent);
const AutoCompleteBase = memo(AutoComplete);

const stringifyUserList = (list = []) =>
  list.reduce((acc, current) => `${acc}${acc ? ', ' : ''}${current.value}`, '');

const Author = ({ attributes, children, element, direction }) => {
  const { data } = element;
  const [getFieldsForBlock] = useGetFieldsForBlock();
  const editor = useSlate();
  const field = getFieldsForBlock(fieldEnums.AUTHORS, { options: [] });
  const options = field?.options || [];

  const [onChangeCollapse] = useChangeCollapse(element);

  const readOnly = useReadOnly();
  const { update } = useEditorContext();
  const { content = [], collapsed = false } = data;

  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState('');

  const authorOptions = useMemo(
    () =>
      options.map((author) => ({
        id: author.id,
        value: author.value,
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const updateAuthors = useCallback(
    (updatedData) => {
      updateBlock(editor, element, updatedData, update, false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [element, update],
  );

  const handleChange = useCallback(
    (event, newAuthor) => {
      event.preventDefault();
      if (newAuthor) {
        const isIncluded = content.find((opt) => opt.value === newAuthor.value);

        if (!isIncluded) {
          const updatedData = {
            ...data,
            content: [...data.content, newAuthor],
          };
          updateAuthors(updatedData);
        }
        setInputValue('');
        setValue(null);
      }
    },
    [content, data, updateAuthors],
  );

  const removeAuthor = useCallback(
    (index) => {
      const updatedData = {
        ...data,
        content: content.filter((_, pos) => pos !== index),
      };
      updateAuthors(updatedData);
    },
    [content, data, updateAuthors],
  );

  const handleInputChange = useCallback((_, newValue) => {
    setInputValue(newValue);
  }, []);

  const {
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    getClearProps,
    groupedOptions,
    getPopupIndicatorProps,
    popupOpen,
  } = useAutocomplete({
    id: 'author-box',
    options: authorOptions,
    autoHighlight: true,
    getOptionLabel: (option) => option.value,
    value,
    onChange: handleChange,
    inputValue,
    onInputChange: handleInputChange,
  });

  const renderContent = useMemo(
    () => {
      const collapsedContent = stringifyUserList(content);

      return (
        <Box
          iconComponent={<UserIcon className="skipOverride" />}
          title="Author"
          readOnly={readOnly}
          hideEllipsisButton
          updateCollapsed={onChangeCollapse}
          collapsed={collapsed || false}
          collapsedContent={collapsedContent}
          direction={direction}
        >
          <AutoCompleteWrapper>
            <AutoCompleteBase
              content={content}
              readOnly={readOnly}
              value={inputValue}
              placeholder="Start typing to find Authors"
              showAvatarOnList
              {...{
                getRootProps,
                getInputProps,
                getListboxProps,
                getOptionProps,
                getClearProps,
                groupedOptions,
                getPopupIndicatorProps,
                popupOpen,
              }}
            />
            <ChipListWrapper>
              {content.map((option, idx) => (
                <li key={`${option.id}-${idx}`}>
                  <Chip
                    label={option.value}
                    width="100%"
                    height={40}
                    onDelete={() => removeAuthor(idx)}
                  />
                </li>
              ))}
            </ChipListWrapper>
          </AutoCompleteWrapper>
        </Box>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [collapsed, content, inputValue, popupOpen, readOnly, removeAuthor, onChangeCollapse],
  );

  return (
    <div {...attributes}>
      <DragAndDrop element={element}>
        {children}
        {renderContent}
      </DragAndDrop>
    </div>
  );
};

Author.propTypes = {
  /** Attributes of SlateJS children */
  attributes: PropTypes.shape({}),
  /** SlateJS children */
  children: PropTypes.node,
  /** SlateJS element */
  element: PropTypes.shape({}),
};

Author.defaultProps = {
  attributes: {},
  children: null,
  element: {
    children: [],
    data: { content: [] },
    type: elementTypes.AUTHOR,
  },
};

export default memo(Author);
