import { useEffect, useMemo } from 'react';
import { useQuery } from '@apollo/client';

import useInstancePermissions from 'features/instance/hooks/useInstancePermissions';
import { Script } from 'types';
import { GetScriptInput, QueryGetMemberArgs } from 'types/graphqlTypes';

import { useScriptStore } from '../store';

import { GET_SCRIPT } from './graphql';

export type GetScriptsInputType = {
  input: GetScriptInput;
};

export type GetScriptsReturnType = {
  getScript: Script | null;
};

const useSyncScript = (instanceId: string) => {
  const { updateState, updateEditPermission } = useScriptStore();
  const { canUpdateInstance } = useInstancePermissions();

  const input: GetScriptInput = useMemo(
    () => ({
      mId: instanceId,
    }),
    [instanceId],
  );

  const { data, loading, error, refetch } = useQuery<GetScriptsReturnType, QueryGetMemberArgs>(
    GET_SCRIPT,
    {
      variables: {
        input,
      },
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-and-network',
    },
  );

  useEffect(() => {
    if (data?.getScript) {
      updateState(data.getScript);
    } else {
      updateState(null);
    }
  }, [data, updateState]);

  useEffect(() => {
    updateEditPermission(canUpdateInstance);
  }, [canUpdateInstance, updateEditPermission]);

  return {
    data: data?.getScript,
    loading,
    error,
    refetch,
  };
};

export default useSyncScript;
