import styled from '@emotion/styled';

import { ReactComponent as AddInstance } from 'assets/icons/search/add_instance.svg';
import { ReactComponent as AddSpace } from 'assets/icons/search/add_space.svg';
import { ReactComponent as AddStory } from 'assets/icons/search/add_story.svg';
import { ReactComponent as ArchivedStory } from 'assets/icons/search/archived_story.svg';
import { ReactComponent as Assignees } from 'assets/icons/search/assignees.svg';
import { ReactComponent as AudioIcon } from 'assets/icons/search/audio.svg';
import { ReactComponent as Caret } from 'assets/icons/search/caret.svg';
import { ReactComponent as ClearAll } from 'assets/icons/search/clear_all.svg';
import { ReactComponent as CSV } from 'assets/icons/search/csv.svg';
import { ReactComponent as Date } from 'assets/icons/search/date.svg';
import { ReactComponent as FileIcon } from 'assets/icons/search/file.svg';
import { ReactComponent as Fuzzy } from 'assets/icons/search/fuzzy.svg';
import { ReactComponent as GraphicsIcon } from 'assets/icons/search/graphics.svg';
import { ReactComponent as ImageIcon } from 'assets/icons/search/image.svg';
import {
  ReactComponent as Metadata,
  ReactComponent as MetadataInactive,
} from 'assets/icons/search/metadata.svg';
import { ReactComponent as MetadataActive } from 'assets/icons/search/metadata_on.svg';
import { ReactComponent as Organization } from 'assets/icons/search/Organization_off.svg';
import { ReactComponent as Planning } from 'assets/icons/search/planning_item.svg';
import { ReactComponent as Rundown } from 'assets/icons/search/rundown.svg';
import { ReactComponent as Search } from 'assets/icons/search/search.svg';
import { ReactComponent as Semantic } from 'assets/icons/search/semanticSearch.svg';
import { ReactComponent as Snippet } from 'assets/icons/search/snippet.svg';
import { ReactComponent as Sort } from 'assets/icons/search/sort.svg';
import { ReactComponent as Space } from 'assets/icons/search/space.svg';
import { ReactComponent as Status } from 'assets/icons/search/status.svg';
import { ReactComponent as Task } from 'assets/icons/search/task.svg';
import { ReactComponent as TypesIcon } from 'assets/icons/search/types.svg';
import { ReactComponent as VideoIcon } from 'assets/icons/search/video.svg';
import { ReactComponent as InstanceOn } from 'assets/icons/systemicons/ContainerLeft_Navbar/instances_on.svg';
import { ReactComponent as Draft } from 'assets/icons/systemicons/draft_off.svg';
import { ReactComponent as FeedsOn } from 'assets/icons/systemicons/HeaderNavbar/feeds_off.svg';
import { ReactComponent as HomeOn } from 'assets/icons/systemicons/HeaderNavbar/home_off.svg';
import { ReactComponent as NavigateOn } from 'assets/icons/systemicons/HeaderNavbar/navigate_off.svg';
import { ReactComponent as Instance } from 'assets/icons/systemicons/instance_purple.svg';
import { ReactComponent as LocationOn } from 'assets/icons/systemicons/location_off.svg';
import { ReactComponent as Ellipsis } from 'assets/icons/systemicons/more_vertical.svg';
import { ReactComponent as LockOn_White } from 'assets/icons/systemicons/padlock_on.svg';
import { ReactComponent as Pitch } from 'assets/icons/systemicons/pitch_green.svg';
import { ReactComponent as Settings } from 'assets/icons/systemicons/settings_off.svg';
import { ReactComponent as SidepanelActive } from 'assets/icons/systemicons/sidebar_active.svg';
import { ReactComponent as SidepanelInactive } from 'assets/icons/systemicons/sidebar_inactive.svg';
import { ReactComponent as Approved } from 'assets/icons/systemicons/status/approved.svg';
import { ReactComponent as Archived } from 'assets/icons/systemicons/status/archived.svg';
import { ReactComponent as Error } from 'assets/icons/systemicons/status/error.svg';
import { ReactComponent as Expired } from 'assets/icons/systemicons/status/expired.svg';
import { ReactComponent as InProgress } from 'assets/icons/systemicons/status/inProgress.svg';
import { ReactComponent as InReview } from 'assets/icons/systemicons/status/inReview.svg';
import { ReactComponent as Pending } from 'assets/icons/systemicons/status/pending.svg';
import { ReactComponent as Published } from 'assets/icons/systemicons/status/published.svg';
import { ReactComponent as Ready } from 'assets/icons/systemicons/status/ready.svg';
import { ReactComponent as ToDo } from 'assets/icons/systemicons/status/toDo.svg';
import { ReactComponent as Story } from 'assets/icons/systemicons/story_blue.svg';
import PlatformIcons from 'components/menu/createInstanceMenu/PlatformIcons';
import { Close } from 'components/searchbar/components/input/input-styled';
import {
  DefaultMdfTypes,
  Destination,
  Instruction,
  ItemType,
  Option,
  Section,
  SortOption,
  ToolbarIcon,
} from 'features/command/command-types';
import { ParsedMemberType } from 'types';
import { MemberType, MemberTypeEnum, SearchItemTypeEnum } from 'types/graphqlTypes';
import { getPlatformIcon } from 'utils/instance/platform';

const LockOn = styled(LockOn_White)`
  path {
    fill: ${({ theme }) => theme.palette.dina.statusWarning} !important;
  }
`;

export const CommandIcons: Record<
  Destination,
  React.FC<React.SVGProps<SVGSVGElement>> | undefined
> = {
  home: HomeOn,
  feeds: FeedsOn,
  storyhub: NavigateOn,
  maps: LocationOn,
  settings: Settings,
};

export type SearchAssetType = 'image' | 'video' | 'audio' | 'file' | 'graphics';
export type IntegrationIcons =
  | 'image'
  | 'video'
  | 'audio'
  | 'file'
  | 'story'
  | 'space'
  | 'instance'
  | 'note'
  | 'rundown'
  | 'contact'
  | 'inProgress'
  | 'inReview'
  | 'published'
  | 'ready'
  | 'todo'
  | 'error'
  | 'failed'
  | 'pending'
  | 'expired'
  | 'approved'
  | 'archived'
  | 'youtube';

export const integrationIcons: Record<IntegrationIcons, React.FC<React.SVGProps<SVGSVGElement>>> = {
  image: ImageIcon,
  video: VideoIcon,
  audio: AudioIcon,
  file: FileIcon,
  story: Story,
  instance: InstanceOn,
  note: FileIcon,
  space: Space,
  rundown: Rundown,
  contact: Assignees,
  inProgress: InProgress,
  inReview: InReview,
  published: Published,
  ready: Ready,
  todo: ToDo,
  error: Error,
  failed: Error,
  pending: Pending,
  expired: Expired,
  approved: Approved,
  archived: Archived,
  youtube: PlatformIcons.youtube,
};

export const AssetIcons: Record<SearchAssetType, React.FC<React.SVGProps<SVGSVGElement>>> = {
  image: ImageIcon,
  video: VideoIcon,
  audio: AudioIcon,
  file: FileIcon,
  graphics: GraphicsIcon,
};

export const mTypeToLabel: Record<SearchItemTypeEnum, string> = {
  [SearchItemTypeEnum.story]: 'Stories',
  [SearchItemTypeEnum.order_form]: '',
  [SearchItemTypeEnum.rnd_ins]: '',
  [SearchItemTypeEnum.space]: 'Spaces',
  [SearchItemTypeEnum.scheduleentry]: '',
  [SearchItemTypeEnum.str_ins]: '',
  [SearchItemTypeEnum.archived_story]: '',
  [SearchItemTypeEnum.archived_pitch]: '',
  [SearchItemTypeEnum.archived_res_story]: '',
  [SearchItemTypeEnum.archived_res_pitch]: '',
  [SearchItemTypeEnum.pitch]: 'Pitches',
  [SearchItemTypeEnum.res_story]: '',
  [SearchItemTypeEnum.res_pitch]: '',
  [SearchItemTypeEnum.note]: 'Notes',
  [SearchItemTypeEnum.asset]: 'Assets',
  [SearchItemTypeEnum.order]: 'Tasks',
  [SearchItemTypeEnum.instance]: 'Instances',
  [SearchItemTypeEnum.archived_instance]: '',
  [SearchItemTypeEnum.marker]: '',
  [SearchItemTypeEnum.user]: 'Users',
  [SearchItemTypeEnum.contact]: 'Contacts',
  [SearchItemTypeEnum.team]: '',
  [SearchItemTypeEnum.department]: '',
  [SearchItemTypeEnum.rundown]: 'Rundowns',
  [SearchItemTypeEnum.message]: 'Message',
  [SearchItemTypeEnum.draft]: 'Draft',
  [SearchItemTypeEnum.block]: 'Planning item',
};

export const mTypeToType: Record<SearchItemTypeEnum, string> = {
  [SearchItemTypeEnum.story]: 'Story',
  [SearchItemTypeEnum.order_form]: '',
  [SearchItemTypeEnum.rnd_ins]: 'Instance',
  [SearchItemTypeEnum.space]: 'Space',
  [SearchItemTypeEnum.scheduleentry]: '',
  [SearchItemTypeEnum.str_ins]: 'Instance',
  [SearchItemTypeEnum.archived_story]: 'Story',
  [SearchItemTypeEnum.archived_pitch]: 'Pitch',
  [SearchItemTypeEnum.archived_res_story]: 'Story',
  [SearchItemTypeEnum.archived_res_pitch]: 'Pitch',
  [SearchItemTypeEnum.pitch]: 'Pitch',
  [SearchItemTypeEnum.res_story]: 'Story',
  [SearchItemTypeEnum.res_pitch]: 'Pitch',
  [SearchItemTypeEnum.note]: 'Note',
  [SearchItemTypeEnum.asset]: 'Asset',
  [SearchItemTypeEnum.order]: 'Task',
  [SearchItemTypeEnum.instance]: 'Instance',
  [SearchItemTypeEnum.archived_instance]: 'Instance',
  [SearchItemTypeEnum.marker]: '',
  [SearchItemTypeEnum.user]: 'User',
  [SearchItemTypeEnum.contact]: 'Contact',
  [SearchItemTypeEnum.team]: 'Team',
  [SearchItemTypeEnum.department]: 'Department',
  [SearchItemTypeEnum.rundown]: 'Rundown',
  [SearchItemTypeEnum.draft]: 'Draft',
  [SearchItemTypeEnum.block]: 'Planning item',
  [SearchItemTypeEnum.message]: 'Message',
};

export const sectionToMType: Record<Section, SearchItemTypeEnum[]> = {
  home: [],
  feeds: [], // Not yet in main search, using old hook for a separate search
  assets: [], // Mimir search
  notes: [SearchItemTypeEnum.note],
  stories: [SearchItemTypeEnum.story],
  instances: [SearchItemTypeEnum.str_ins, SearchItemTypeEnum.rnd_ins, SearchItemTypeEnum.instance],
  contacts: [SearchItemTypeEnum.contact],
  rundowns: [SearchItemTypeEnum.rundown],
  tasks: [SearchItemTypeEnum.order],
};

export const rundownStatusLabels: Record<string, string> = {
  ready: 'Ready to air',
  active: 'Activated',
  notReady: 'Not active',
};

export const ToolbarIcons: Record<ToolbarIcon, React.FC<React.SVGProps<SVGSVGElement>>> = {
  Sort: Sort,
  Caret: Caret,
  Date: Date,
  Clear: Close,
  ClearAll: ClearAll,
  Assignees: Assignees,
  Status: Status,
  Csv: CSV,
  MoreOptions: Ellipsis,
  Types: TypesIcon,
  SemanticSearch: Semantic,
  Restricted: LockOn,
  MetadataActive: MetadataActive,
  MetadataInactive: MetadataInactive,
  SidePanelActive: SidepanelActive,
  SidePanelInActive: SidepanelInactive,
  Fuzzy: Fuzzy,
  Organization: Organization,
};

export const StatusIcons: Record<string, React.FC<React.SVGProps<SVGSVGElement>>> = {
  inProgress: InProgress,
  inReview: InReview,
  published: Published,
  ready: Ready,
  todo: ToDo,
  error: Error,
  failed: Error,
  pending: Pending,
  expired: Expired,
  approved: Approved,
  archived: Archived,
};

export const hasAvatar = [
  MemberTypeEnum.Team,
  MemberTypeEnum.Department,
  MemberTypeEnum.User,
  MemberTypeEnum.Contact,
];

export const SearchIcons: Record<
  MemberTypeEnum,
  React.FC<React.SVGProps<SVGSVGElement>> | undefined
> = {
  [MemberTypeEnum.Asset]: undefined,
  [MemberTypeEnum.Booking]: undefined,
  [MemberTypeEnum.Config]: undefined,
  [MemberTypeEnum.Contact]: Assignees,
  [MemberTypeEnum.Contenttemplate]: undefined,
  [MemberTypeEnum.DailyNote]: undefined,
  [MemberTypeEnum.DepRnd]: undefined,
  [MemberTypeEnum.DepStr]: undefined,
  [MemberTypeEnum.DepTea]: undefined,
  [MemberTypeEnum.DepUsr]: undefined,
  [MemberTypeEnum.DepIns]: undefined,
  [MemberTypeEnum.DepPitch]: undefined,
  [MemberTypeEnum.Department]: undefined,
  [MemberTypeEnum.Draft]: Draft,
  [MemberTypeEnum.Folder]: undefined,
  [MemberTypeEnum.Group]: undefined,
  [MemberTypeEnum.Instance]: Instance,
  [MemberTypeEnum.Job]: undefined,
  [MemberTypeEnum.MarStr]: undefined,
  [MemberTypeEnum.Marker]: undefined,
  [MemberTypeEnum.Note]: FileIcon,
  [MemberTypeEnum.OrgDep]: undefined,
  [MemberTypeEnum.OrgUsr]: undefined,
  [MemberTypeEnum.Organization]: undefined,
  [MemberTypeEnum.OrderForm]: undefined,
  [MemberTypeEnum.Mdf]: undefined,
  [MemberTypeEnum.Order]: Task,
  [MemberTypeEnum.Platform]: undefined,
  [MemberTypeEnum.Resource]: undefined,
  [MemberTypeEnum.RndIns]: undefined,
  [MemberTypeEnum.RndRnd]: undefined,
  [MemberTypeEnum.RndUsr]: undefined,
  [MemberTypeEnum.Rundown]: Rundown,
  [MemberTypeEnum.Rundowntemplate]: Rundown,
  [MemberTypeEnum.ResourceBooking]: undefined,
  [MemberTypeEnum.Session]: undefined,
  [MemberTypeEnum.Snippet]: Snippet,
  [MemberTypeEnum.Space]: Space,
  [MemberTypeEnum.SpaceView]: undefined,
  [MemberTypeEnum.SpaceContact]: undefined,
  [MemberTypeEnum.Story]: Story,
  [MemberTypeEnum.Scheduleentry]: undefined,
  [MemberTypeEnum.StrCon]: undefined,
  [MemberTypeEnum.StrIns]: Instance,
  [MemberTypeEnum.StrJob]: undefined,
  [MemberTypeEnum.TeaCon]: undefined,
  [MemberTypeEnum.TeaRnd]: undefined,
  [MemberTypeEnum.TeaStr]: undefined,
  [MemberTypeEnum.TeaUsr]: undefined,
  [MemberTypeEnum.TeaIns]: Instance,
  [MemberTypeEnum.Team]: undefined,
  [MemberTypeEnum.User]: Assignees,
  [MemberTypeEnum.UsrData]: undefined,
  [MemberTypeEnum.UsrCon]: undefined,
  [MemberTypeEnum.UsrIns]: undefined,
  [MemberTypeEnum.UsrJob]: undefined,
  [MemberTypeEnum.UsrPitch]: Pitch,
  [MemberTypeEnum.UsrStr]: Story,
  [MemberTypeEnum.ArchivedDepartment]: undefined,
  [MemberTypeEnum.ArchivedTeam]: undefined,
  [MemberTypeEnum.ArchivedStory]: ArchivedStory,
  [MemberTypeEnum.ArchivedPitch]: Pitch,
  [MemberTypeEnum.ArchivedInstance]: Instance,
  [MemberTypeEnum.ArchivedRundown]: Rundown,
  [MemberTypeEnum.ArchivedResStory]: Story,
  [MemberTypeEnum.ArchivedResPitch]: Pitch,
  [MemberTypeEnum.ArchivedUser]: undefined,
  [MemberTypeEnum.DisabledUser]: undefined,
  [MemberTypeEnum.Pitch]: Pitch,
  [MemberTypeEnum.Convo]: undefined,
  [MemberTypeEnum.ConUsr]: undefined,
  [MemberTypeEnum.Message]: undefined,
  [MemberTypeEnum.ResStory]: Story,
  [MemberTypeEnum.ResPitch]: Pitch,
  [MemberTypeEnum.TeaPitch]: undefined,
  [MemberTypeEnum.Block]: Planning,
  [MemberTypeEnum.Bookmark]: undefined,
  [MemberTypeEnum.Schedule]: undefined,
  [MemberTypeEnum.Script]: undefined,
  [MemberTypeEnum.SavedSearch]: undefined,
  [MemberTypeEnum.RundownLayout]: undefined,
};

export const mdfIdToMType: Record<DefaultMdfTypes, SearchItemTypeEnum> = {
  'story-mdf': SearchItemTypeEnum.story,
  'contact-mdf': SearchItemTypeEnum.contact,
  'user-mdf': SearchItemTypeEnum.user,
  'rundown-mdf': SearchItemTypeEnum.rundown,
  'pitch-mdf': SearchItemTypeEnum.pitch,
};

export const ItemTypeIcons: Record<ItemType, React.FC<React.SVGProps<SVGSVGElement>> | undefined> =
  {
    feed: FeedsOn,
    search: Search,
    'search-item': undefined,
    asset: undefined,
    paging: undefined,
    metadata: Metadata,
  };

export const InstructionIcons: Record<
  Instruction,
  React.FC<React.SVGProps<SVGSVGElement>> | undefined
> = {
  create_instance: AddInstance,
  create_story: AddStory,
  open_tasks: Task,
  create_space: AddSpace,
};

export const CommandLabel: Record<Destination, string> = {
  home: 'Home',
  feeds: 'Feeds',
  storyhub: 'StoryHub',
  maps: 'Maps',
  settings: 'System settings',
};

export const getIcon = (item: MemberType | ParsedMemberType) => {
  if (item.mType === MemberTypeEnum.Asset) {
    return AssetIcons[(item.itemType as SearchAssetType) ?? 'file'] ?? AssetIcons.file;
  }

  if (item.mType === MemberTypeEnum.Instance) {
    const mProperties = item.mProperties ?? {};
    const platform = mProperties.platform as string | undefined;
    const platformKind = mProperties.platformKind as string | undefined;
    return getPlatformIcon(platform, platformKind);
  }

  if (item.mType === MemberTypeEnum.Block) {
    return SearchIcons[MemberTypeEnum.Block];
  }

  return item.mType ? SearchIcons[item.mType] : undefined;
};

export const sortToOption: Record<SortOption, Option> = {
  best: {
    key: 'best',
    label: 'Best matches',
    shortLabel: 'Sort',
  },
  updatedAt: {
    key: 'updatedAt',
    label: 'Last updated',
    shortLabel: 'updated',
  },
  createdAt: {
    key: 'createdAt',
    label: 'Created',
    shortLabel: 'created',
  },
  scheduledAt: {
    key: 'scheduledAt',
    label: 'Scheduled',
    shortLabel: 'sched.',
  },
  title: {
    key: 'title',
    label: 'Title',
    shortLabel: 'title',
  },
  totalInstances: {
    key: 'totalInstances',
    label: 'Total instances',
    shortLabel: 'tot. instances',
  },
  priority: {
    key: 'priority',
    label: 'Priority',
    shortLabel: 'priority',
  },
};
