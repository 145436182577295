import React, { useState, memo, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as CalendarOff } from 'assets/icons/systemicons/calendar_off.svg';
import { ReactComponent as CalendarOn } from 'assets/icons/systemicons/calendar_on.svg';
import { ReactComponent as TimeIcon } from 'assets/icons/systemicons/time.svg';
import Picker from 'components/instanceCard/components/header/components/publishSettings/components/picker';
import Divider from 'components/divider';
import Popover from 'components/popover';
import InputField from 'components/inputField';
import {
  DateTimeWrapper,
  DatePickerWrapper,
  TimePickerWrapper,
  ButtonWrapper,
  CancelButton,
  OkButton,
} from './styled';
import useDateTimeUtils from 'hooks/useDateTimeUtils';

const Input = memo(InputField);

const PickerView = ({ dateTime, type, description, label, onOK, readOnly, direction }) => {
  const {
    format,
    setDate,
    setYear,
    setMonth,
    getYear,
    getMonth,
    getDate,
    setHours,
    setMinutes,
    getHours,
    getMinutes,
  } = useDateTimeUtils();
  const [anchorEl, setAnchorEl] = useState();

  const getInitialTimeISOString = useCallback(() => {
    if (!dateTime) return new Date().toISOString();
    return new Date(dateTime).toISOString();
  }, [dateTime]);

  const [newDateTime, setNewDateTime] = useState(getInitialTimeISOString());

  useEffect(() => {
    if (anchorEl) setNewDateTime(getInitialTimeISOString());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anchorEl]);

  const handleDateChange = useCallback(
    (changedDate) => {
      if (!changedDate) return;
      const updatedDate = setYear(
        setMonth(setDate(newDateTime, getDate(changedDate)), getMonth(changedDate)),
        getYear(changedDate),
      );
      setNewDateTime(updatedDate.toISOString());
    },
    [newDateTime],
  );

  const handleTimeChange = useCallback(
    (changedTime) => {
      if (!changedTime) return;
      const updatedTime = setHours(
        setMinutes(newDateTime, getMinutes(changedTime)),
        getHours(changedTime),
      );
      setNewDateTime(updatedTime.toISOString());
    },
    [newDateTime],
  );

  const handleCancel = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      setNewDateTime(dateTime);
      setAnchorEl(null);
    },
    [dateTime],
  );

  const handleOK = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      onOK({ [type]: newDateTime });
      setAnchorEl(null);
    },
    [newDateTime, onOK, type],
  );

  const handleOpenPopover = useCallback(
    (event) => {
      if (!readOnly) setAnchorEl(event.currentTarget);
    },
    [readOnly],
  );

  const handleClear = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      setNewDateTime('');
      onOK({ [type]: '' });
    },
    [type, onOK, setNewDateTime],
  );

  useEffect(() => {
    setNewDateTime(getInitialTimeISOString());
  }, [getInitialTimeISOString]);

  return (
    <>
      <div role="presentation" onClick={handleOpenPopover}>
        <Input
          value={dateTime ? format(dateTime, 'd-MMM-yyyy HH:mm:ss') : ''}
          usage="editor"
          label={label}
          description={description}
          disabled={readOnly}
          direction={direction}
          onClear={handleClear}
        />
      </div>
      <Popover anchorEl={anchorEl} position="publish-setting-custom" onClose={handleCancel}>
        <>
          <DateTimeWrapper>
            <DatePickerWrapper>
              <Picker
                Icon={CalendarOff}
                SecondaryIcon={CalendarOn}
                type="date"
                label="Publish Date"
                timeValue={newDateTime}
                onChange={handleDateChange}
              />
            </DatePickerWrapper>
            <TimePickerWrapper>
              <Picker
                Icon={TimeIcon}
                type="time"
                label="Publish Time"
                timeValue={newDateTime}
                onChange={handleTimeChange}
              />
            </TimePickerWrapper>
          </DateTimeWrapper>
          <div>
            <Divider />
            <ButtonWrapper>
              <CancelButton onClick={handleCancel}>Cancel</CancelButton>
              <OkButton onClick={handleOK}>Ok</OkButton>
            </ButtonWrapper>
          </div>
        </>
      </Popover>
    </>
  );
};

PickerView.propTypes = {
  /** selected time in the picker */
  dateTime: PropTypes.string,
  /** field type of picker */
  type: PropTypes.string.isRequired,
  /** description under the input */
  description: PropTypes.string,
  /** label for the input */
  label: PropTypes.string,
  /** callback to choose a date from the picker */
  onOK: PropTypes.func,
  /** boolean to disable the picker */
  readOnly: PropTypes.bool,
};

PickerView.defaultProps = {
  dateTime: undefined,
  description: '',
  label: '',
  onOK: () => {},
  readOnly: false,
};

export default memo(PickerView);
