import { useCallback } from 'react';
import {
  differenceInCalendarDays,
  differenceInCalendarYears,
  differenceInDays,
  differenceInHours,
  differenceInMilliseconds,
  differenceInMinutes,
  differenceInMonths,
  differenceInSeconds,
  differenceInWeeks,
  differenceInYears,
} from 'date-fns';

import { DateType, TimezoneConverter, toTimeZoneDate } from 'utils/dateTime';

import useTimezoneSettings from './useTimezoneSettings';

type DateDifferenceFunction<Options = unknown> = (
  laterDate: DateType,
  earlierDate: DateType,
  options?: Options,
) => number;

const withTimezoneDateDifference =
  (convertToTimezoneDate: TimezoneConverter) =>
  <Options = unknown>(differenceFn: DateDifferenceFunction<Options>) =>
  (laterDate: DateType, earlierDate: DateType, options?: Options) =>
    differenceFn(convertToTimezoneDate(laterDate), convertToTimezoneDate(earlierDate), options);

const useDateDifferenceUtils = () => {
  const timezone = useTimezoneSettings();

  const timezoneSafeFn = useCallback(withTimezoneDateDifference(toTimeZoneDate(timezone)), [
    timezone,
  ]);

  return {
    differenceInCalendarDays: timezoneSafeFn(differenceInCalendarDays),
    differenceInCalendarYears: timezoneSafeFn(differenceInCalendarYears),
    differenceInDays: timezoneSafeFn(differenceInDays),
    differenceInHours: timezoneSafeFn(differenceInHours),
    differenceInMilliseconds: timezoneSafeFn(differenceInMilliseconds),
    differenceInMinutes: timezoneSafeFn(differenceInMinutes),
    differenceInMonths: timezoneSafeFn(differenceInMonths),
    differenceInSeconds: timezoneSafeFn(differenceInSeconds),
    differenceInWeeks: timezoneSafeFn(differenceInWeeks),
    differenceInYears: timezoneSafeFn(differenceInYears),
  };
};

export default useDateDifferenceUtils;
