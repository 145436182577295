import { memo, useCallback, useEffect, useMemo } from 'react';

import { ReactComponent as Close } from 'assets/icons/systemicons/close.svg';
import { ReactComponent as EditOff } from 'assets/icons/systemicons/edit_off.svg';
import { ReactComponent as EditOn } from 'assets/icons/systemicons/edit_on.svg';
import { ReactComponent as VisibilityOff } from 'assets/icons/systemicons/editor/visibility.svg';
import { ReactComponent as VisibilityOn } from 'assets/icons/systemicons/editor/visibility_on.svg';
import { ReactComponent as Open } from 'assets/icons/systemicons/open.svg';
import Assignees from 'components/assignees/Assignees';
import { IconButton } from 'components/buttons';
import Divider from 'components/divider';
import ToggleButtons from 'components/toggleButtons';
import Comments from 'features/comments';
import { HStack } from 'layouts/box/Box';
import { EditorValue, Instance } from 'types';
import { MMetaDataField } from 'types/graphqlTypes';
import { onImageLoadingError } from 'utils/image/imageUtils';

import useInstanceCardHeader from '../hooks/useInstanceCardHeader';
import { InstanceChangeObject, UpdateInputParameters } from '../hooks/useInstanceCore';
import useInstanceViewUtils from '../hooks/useInstanceViewUtils';
import { useInstanceMolecule } from '../store/instance';
import {
  Destination,
  DestinationAvatar,
  HeaderBottom,
  HeaderTop,
  HeaderWrapper,
  PublishingPointImg,
  ThumbnailImg,
} from '../styled';

import Menu from './menu/Menu';
import PublishSettings from './publishSettings/PublishSettings';
import SchedulingButtons from './schedulingButtons/SchedulingButtons';
import TitleBox from './TitleBox';

interface HeaderProps {
  writeLock: boolean;
  readLock: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  showComment?: boolean;
  updatePane?: () => void;
  onInstanceChanged: (instanceChangeObject: InstanceChangeObject) => Promise<unknown>;
  onDeleteInstance: () => Promise<void>;
  lockedByUser: string;
  lockedByCurrentUser: boolean;
  editorValue: EditorValue;
  updateLock: (lockedId?: string) => void;
  onForceUnlock: () => Promise<void>;
  onCreateDuplicate: (rundownId: string) => Promise<void>;
  onMetadataChanged: (newMetadata: MMetaDataField[], newInstance: Instance) => Promise<void>;
  saveAll: (params: UpdateInputParameters) => Promise<void>;
  cancelDebounce: () => void;
  resetEditorValue: (newValue: EditorValue) => void;
  handleLockInstance: () => Promise<string | undefined>;
}

const Header = ({
  onOpen,
  onClose,
  onInstanceChanged,
  showComment,
  updatePane,
  onDeleteInstance,
  onMetadataChanged,
  lockedByUser,
  lockedByCurrentUser,
  updateLock,
  editorValue,
  readLock,
  writeLock,
  onForceUnlock,
  onCreateDuplicate,
  saveAll,
  cancelDebounce,
  resetEditorValue,
  handleLockInstance,
}: Readonly<HeaderProps>) => {
  const { useInstanceValue, usePublishingPointValue } = useInstanceMolecule();
  const {
    assignedMembers,
    onAssigneesClick,
    publishingIcon,
    destination,
    canOpenDestination,
    openDestination,
    showToggleButton,
    publishAnchorEl,
    publishingTime,
    canRepublishInstance,
    publishMetadata,
    onPublishConfirm,
    onPublishCancel,
    canOpenStory,
    openStory,
  } = useInstanceCardHeader(onInstanceChanged);

  const {
    view,
    setView,
    thumbnail,
    variant,
    isCmsBlock,
    isEmbedPresent,
    backgroundColor,
    opacity,
  } = useInstanceViewUtils();

  const instance = useInstanceValue();
  const publishingPoint = usePublishingPointValue();

  const toggleButtonList = useMemo(
    () => [
      {
        icon: (
          <IconButton usage="text" size={28} iconSize={20} selected={view === 'edit'}>
            {view === 'edit' ? <EditOn className="skipOverride" /> : <EditOff />}
          </IconButton>
        ),
        value: 'edit',
      },
      {
        icon: (
          <IconButton usage="text" size={28} iconSize={20} selected={view === 'preview'}>
            {view === 'preview' ? <VisibilityOn /> : <VisibilityOff />}
          </IconButton>
        ),
        value: 'preview',
        disabled: !isEmbedPresent,
      },
    ],
    [isEmbedPresent, view],
  );

  useEffect(() => {
    if (isEmbedPresent) {
      setView('preview');
    }
  }, [isEmbedPresent, setView]);

  const handleOpenStory = useCallback(() => {
    if (onOpen) {
      openStory();
      onOpen();
    }
  }, [onOpen, openStory]);

  const handleOpenDestination = useCallback(() => {
    openDestination();
    if (onClose) onClose();
  }, [onClose, openDestination]);

  const updateInstanceMetadata = useCallback(
    async (newMetadata: MMetaDataField[]) => {
      if (instance) {
        await onMetadataChanged(newMetadata, instance);
      }
    },
    [instance, onMetadataChanged],
  );

  return (
    <HeaderWrapper $backgroundColor={backgroundColor} $opacity={opacity} alignItems="flex-start">
      <HeaderTop gap="0.5rem">
        <TitleBox onInstanceChanged={onInstanceChanged} />
        {instance?.mId && (
          <Comments
            blockId={instance.mId}
            resourceId={instance.mStoryId}
            resourceType={instance.mType}
            showComment={showComment}
            updatePane={updatePane}
          />
        )}
        <Assignees
          variant="toolbar"
          members={assignedMembers}
          size={24}
          maxAvatarToShow={2}
          onClick={onAssigneesClick}
        />
        {canOpenStory && onOpen && (
          <IconButton
            title="Open"
            usage="text"
            variant="contained"
            onClick={handleOpenStory}
            round
            size={32}
          >
            <Open />
          </IconButton>
        )}
        <Menu
          cancelDebounce={cancelDebounce}
          handleLockInstance={handleLockInstance}
          resetEditorValue={resetEditorValue}
          saveAll={saveAll}
          onInstanceChanged={onInstanceChanged}
          onDeleteInstance={onDeleteInstance}
          lockedByUser={lockedByUser}
          editorValue={editorValue}
          onForceUnlock={onForceUnlock}
          onCreateDuplicate={onCreateDuplicate}
          lockedByCurrentUser={lockedByCurrentUser}
          updateLock={updateLock}
        />
        {onClose && (
          <IconButton
            title="Close"
            usage="text"
            variant="contained"
            onClick={onClose}
            round
            size={32}
          >
            <Close />
          </IconButton>
        )}
      </HeaderTop>
      <Divider style={{ width: '100%' }} />
      <HeaderBottom gap="0.5rem">
        <HStack gap="0.5rem" flex="1 0 auto">
          <DestinationAvatar>
            <ThumbnailImg
              src={thumbnail ?? ''}
              onError={onImageLoadingError}
              alt="destination-thumbnail"
            />
            <PublishingPointImg src={publishingIcon} alt="publishing-thumbnail" />
          </DestinationAvatar>
          <Destination $canOpenDestination={canOpenDestination} onClick={handleOpenDestination}>
            {destination?.title}
          </Destination>
        </HStack>
        {showToggleButton && (
          <ToggleButtons selectedValue={view} list={toggleButtonList} onChange={setView} />
        )}
        <SchedulingButtons
          readLock={readLock}
          writeLock={writeLock}
          onInstanceChanged={onInstanceChanged}
        />
        <PublishSettings
          publishingPoint={publishingPoint}
          platformKind={instance?.mProperties?.platformKind}
          publishingTime={publishingTime}
          onCancel={onPublishCancel}
          variant={variant}
          selectedDestination={destination}
          canRepublishInstance={!!canRepublishInstance}
          publishMetadata={publishMetadata}
          publishingPointIcon={publishingIcon}
          anchorEl={publishAnchorEl}
          expiryTime={instance?.mPublishingEnd}
          isCmsBlock={isCmsBlock}
          updateInstanceMetadata={updateInstanceMetadata}
          onOk={onPublishConfirm}
        />
      </HeaderBottom>
    </HeaderWrapper>
  );
};

export default memo(Header);
