import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

import { ReactComponent as PadlockOn } from 'assets/icons/systemicons/padlock_on_small.svg';
import { ReactComponent as BookmarkOff } from 'assets/icons/systemicons/storyCard/bookmark_small_off.svg';
import { ReactComponent as BookmarkOn } from 'assets/icons/systemicons/storyCard/bookmark_small_on.svg';
import { ReactComponent as More } from 'assets/icons/systemicons/storyCard/more_vertical.svg';
import Text from 'components/text/Text';
import transientOptions from 'theme/helpers';

export type Sizes = 'default' | 'compact';

interface ContainerProps {
  $size?: Sizes;
}

interface OptionsProps {
  $hovered: boolean;
}

interface BodyProps {
  $hideBottomBorder: boolean;
}

export const Body = styled('div', transientOptions)<BodyProps>`
  width: 280px;
  flex: 1;
  padding-bottom: 4px;
  display: flex;
  flex-direction: column;
  gap: 2px;
  ${({ $hideBottomBorder, theme }) =>
    !$hideBottomBorder
      ? css`
          border-bottom: 1px solid ${theme.palette.dina.dividerLight};
        `
      : ''}
`;

export const Timings = styled('div')`
  width: 100%;
  padding-right: 12px;
  display: flex;
  align-items: center;
`;

export const ScheduleWrapper = styled('div')`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Schedule = styled('div')`
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${({ theme }) => theme.palette.dina.mediumEmphasis};
  path {
    fill: ${({ theme }) => theme.palette.dina.highEmphasis};
    fill-opacity: 0.54;
  }
  :hover {
    ${({ theme }) => css`
      color: ${theme.palette.dina.highEmphasis};
      path {
        fill: ${theme.palette.dina.highEmphasis};
        fill-opacity: 1;
      }
    `}
  }
`;

export const ScheduleIcon = styled('div')`
  height: 14px;
  width: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ScheduleText = styled(Text)`
  color: inherit !important;
`;

export const Details = styled('div')`
  width: 100%;
  display: flex;
  padding-right: 12px;
  gap: 4px;
`;

export const Title = styled(Text)`
  flex: 1;
  align-self: flex-start;
  display: -webkit-box;
  overflow: hidden;
  white-space: pre-wrap;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
`;

export const PadlockIcon = styled(PadlockOn)`
  width: 24px;
  height: 24px;
  path {
    fill: ${({ theme }) => theme.palette.dina.statusWarning};
    fill-opacity: 1;
  }
`;

export const BookmarkWrapper = styled('div')`
  overflow: hidden;
  width: 0px;
  transition: width 200ms;
  display: flex;
  align-items: center;
`;

export const BookmarkOffIcon = styled(BookmarkOff)`
  height: 24px;
  width: 24px;
  :hover {
    path {
      fill-opacity: 1;
    }
  }
`;

export const BookmarkOnIcon = styled(BookmarkOn)`
  height: 24px;
  width: 24px;
  :hover {
    path {
      fill-opacity: 1;
    }
  }
`;

export const AssigneesWrapper = styled('div')`
  overflow: hidden;
  width: 0px;
  padding-inline: 0px;
  transition: max-width 200ms;
`;

export const EllipsisIcon = styled(More)`
  overflow: hidden;
  height: 24px;
  width: 0px;
  transition: width 200ms;
  :hover {
    path {
      fill-opacity: 1;
    }
  }
`;

export const Options = styled('div', transientOptions)<OptionsProps>`
  align-self: flex-end;
  display: flex;
  align-items: center;
  path {
    fill: ${({ theme }) => theme.palette.dina.highEmphasis};
    fill-opacity: 0.54;
  }
  ${({ $hovered }) => {
    if ($hovered) {
      return css`
        ${BookmarkWrapper} {
          width: 24px;
        }
        ${AssigneesWrapper} {
          width: auto;
          padding-inline: 2px;
        }
        ${EllipsisIcon} {
          width: 24px;
        }
      `;
    }
  }}
`;

export const Container = styled('div', transientOptions)<ContainerProps>`
  width: 100%;
  display: flex;
  gap: 8px;
  ${({ $size }) =>
    $size === 'compact'
      ? css`
          padding: 4px 0 0 8px;
          ${Body} {
            padding-bottom: 4px;
          }
          ${Title} {
            height: 16px;
            -webkit-line-clamp: 1;
          }
          ${Options} {
            height: 18px;
          }
        `
      : css`
          padding: 8px 0 0 8px;
          ${Body} {
            padding-bottom: 8px;
          }
          ${Title} {
            height: 32px;
            -webkit-line-clamp: 2;
          }
          ${Details} {
            height: 32px;
          }
        `}
`;
