import capitalize from 'lodash/capitalize';

import { InstanceAggregation } from 'types';

/**
 * Transforms badge type keys for display purposes.
 * Currently handles the special case of transforming 'twitter' to 'x'.
 */
export const transformKey = (key: string) => (key === 'twitter' ? 'x' : key);

/**
 * Generates tooltip content for hidden badges by combining
 * the badge type and count in a formatted string.
 *
 * @param hiddenBadges - Array of badge keys that are hidden
 * @param badgeCountsByType - Mapping of badge types to their counts
 * @returns A newline-separated string of badge types and their counts
 */
export const renderHiddenBadgeTooltip = (
  hiddenBadges: string[],
  badgeCountsByType: InstanceAggregation,
) =>
  hiddenBadges
    .map((key) => `${capitalize(transformKey(key))}: ${badgeCountsByType[key]}`)
    .join('\n');
