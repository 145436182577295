import { MutableRefObject, useRef } from 'react';

/**
 * Custom hook that returns a mutable ref object which is synchronized with the given value.
 *
 * @template T - The type of the value to be synchronized.
 * @param {T} value - The value to be synchronized with the ref.
 * @returns {RefObject<T>} - A mutable ref object containing the synchronized value.
 *
 * @example
 * ```typescript
 * const MyComponent = () => {
 *   const [count, setCount] = useState(0);
 *   const countRef = useSyncedRef(count);
 *
 *   useEffect(() => {
 *     console.log(countRef.current); // Logs the current count value
 *   }, [count]);
 *
 *   return (
 *     <div>
 *       <p>{count}</p>
 *       <button onClick={() => setCount(count + 1)}>Increment</button>
 *     </div>
 *   );
 * };
 * ```
 */
const useSyncedRef = <T>(value: T): Readonly<MutableRefObject<T>> => {
  const ref = useRef<T>(value);
  if (ref.current !== value) {
    ref.current = value;
  }
  return ref;
};

export default useSyncedRef;
