import { MouseEvent } from 'react';

import { Button, type ButtonProps } from 'components/buttons';
import useInstanceMdf from 'features/instance/hooks/useInstanceMdf';
import { Box } from 'layouts/box/Box';

import scheduleOptions from '../../utils/scheduleOptions';

const { UNPUBLISH, UPDATE, UNSCHEDULE, SCHEDULE } = scheduleOptions;

type ClassName = 'okButton' | 'confirmPublishButton' | 'scheduleButton' | 'publishButton';

type ButtonText =
  | 'Unschedule'
  | 'Publish Now'
  | 'Schedule'
  | 'Send to Review'
  | 'Unpublish'
  | 'Confirm Publish Now'
  | 'Confirm Schedule'
  | 'Confirm Send to Review'
  | 'Confirm Unpublish'
  | 'Update'
  | 'Re-publish';

const getScheduleStyles = (
  publishNow: boolean,
  confirmPublish: boolean,
  selectedOption: string,
  isApprovalOn: boolean,
  hasAccessToApprovalState: boolean,
): [className: ClassName, buttonText: ButtonText] => {
  if (selectedOption === UNSCHEDULE) return ['okButton', 'Unschedule'];
  if (selectedOption === SCHEDULE || selectedOption === UNPUBLISH) {
    const requireApproval = isApprovalOn && !hasAccessToApprovalState;

    const publishLabelWithAccess = publishNow ? 'Publish Now' : 'Schedule';
    const publishLabel = requireApproval ? 'Send to Review' : publishLabelWithAccess;

    const label = selectedOption === SCHEDULE ? publishLabel : 'Unpublish';

    const isSchedule = !publishNow && selectedOption === SCHEDULE;
    return confirmPublish
      ? [
          'confirmPublishButton',
          `Confirm ${label}` as
            | 'Confirm Publish Now'
            | 'Confirm Schedule'
            | 'Confirm Send to Review'
            | 'Confirm Unpublish',
        ]
      : [
          isSchedule ? 'scheduleButton' : 'publishButton',
          label as 'Publish Now' | 'Schedule' | 'Send to Review' | 'Unpublish',
        ];
  }
  if (selectedOption === UPDATE) return ['okButton', 'Update'];
  return ['scheduleButton', 'Re-publish'];
};

interface FooterProps {
  handleCancel: () => void;
  isLinear: boolean;
  selectedOption: string;
  handleOK: (e: MouseEvent<HTMLButtonElement>) => Promise<void>;
  publishNow: boolean;
  confirmPublish: boolean;
  shouldDisablePublish: boolean;
  isApprovalOn: boolean;
  hasAccessToApprovalState: boolean;
}

const getButtonUsage = (clssName: string): ButtonProps['usage'] => {
  switch (clssName) {
    case 'okButton':
      return 'cta';
    case 'confirmPublishButton':
      return 'danger';
    case 'scheduleButton':
      return 'significant';
    case 'publishButton':
      return 'warning';
    default:
      return undefined;
  }
};

function Footer({
  handleCancel,
  isLinear,
  selectedOption,
  handleOK,
  publishNow,
  confirmPublish,
  shouldDisablePublish,
  isApprovalOn,
  hasAccessToApprovalState,
}: FooterProps) {
  const { errorMap } = useInstanceMdf();
  const [className, buttonText] = getScheduleStyles(
    publishNow,
    confirmPublish,
    selectedOption,
    isApprovalOn,
    hasAccessToApprovalState,
  );
  const isErrorMapEmpty = Object.keys(errorMap).length === 0;

  const handleConfirm = (e: MouseEvent<HTMLButtonElement>) => {
    handleOK(e).catch(() => {});
  };

  const usage = getButtonUsage(className);

  return (
    <Box container alignItems="center" padding="0.5rem" gap="0.5rem">
      <Button height={40} variant="outlined" usage="text" onClick={handleCancel}>
        Cancel
      </Button>
      {!isLinear && (
        <Button
          height={40}
          usage={usage}
          variant="contained"
          onClick={handleConfirm}
          disabled={!isErrorMapEmpty && selectedOption !== 'unschedule'}
        >
          {buttonText}
        </Button>
      )}
      {isLinear && (
        <Button
          height={40}
          usage="significant"
          variant="contained"
          onClick={handleConfirm}
          disabled={shouldDisablePublish}
        >
          Schedule
        </Button>
      )}
    </Box>
  );
}

export default Footer;
