import { omit } from 'lodash';

import type {
  DefaultValueWrapper,
  LayoutSettings,
  LayoutSettingsEntity,
  Mdf,
  MdfField,
  MdfFieldEntity,
  MdfType,
  Views,
  ViewsEntity,
  ViewTypes,
} from 'types/graphqlTypes';

function removeTypeNameAndNullProps<T extends Record<string, unknown>>(obj: T): T {
  Object.keys(obj).forEach((key) => (obj[key] === null || key === '__typename') && delete obj[key]);
  return obj;
}

function getFieldParsedProps(field: MdfFieldEntity) {
  try {
    return {
      defaultValue: JSON.parse(field.defaultValue ?? '{"value": ""}') as DefaultValueWrapper,
      constraint: JSON.parse(field.constraint ?? '{}') as Record<string, unknown>,
      filters: JSON.parse(field.filters ?? '{}') as Record<string, unknown>,
    };
  } catch {
    return {
      defaultValue: { value: null },
      constraint: {},
      filters: undefined,
    };
  }
}

export const toFieldMdfDto = (field: MdfFieldEntity): MdfField => {
  const { defaultValue, constraint, filters } = getFieldParsedProps(field);
  const result = removeTypeNameAndNullProps({
    ...field,
    defaultValue,
    constraint,
    filters,
  });
  if (field.alternatives) {
    // the alternatives seems to be frozen so we cannot use `removeTypeNameAndNullProps`
    result.alternatives = field.alternatives.map((alt) => {
      if (!('__typename' in alt)) return alt;
      const a = { ...alt };
      delete a.__typename;
      return a;
    });
  }
  return result;
};

/**
 * Colors are stored in database as a string containing the JSON stringified record with mapping
 * from alternative to color. (This function also fixes error in db caused by #5166,
 * (both having a string value ("{}") instead of an empty object and having an object with
 * properties "0" and "1" caused by mutating the invalid "{}" value))
 * @param dbColors the colas stored in the database
 * @returns        The record mapping from alternative value to color code.
 */
function parseColors(dbColors: string | undefined): Record<string, string> {
  const result: unknown = JSON.parse(dbColors ?? '{}');
  if (!result || typeof result !== 'object') {
    return {};
  }
  if ('0' in result && result['0'] === '{') delete result['0'];
  if ('1' in result && result['1'] === '}') delete result['1'];
  return result as Record<string, string>;
}

const toLayoutSettings = (settings: LayoutSettingsEntity): LayoutSettings => {
  try {
    return removeTypeNameAndNullProps({
      ...settings,
      colors: parseColors(settings.colors),
    });
  } catch {
    return removeTypeNameAndNullProps(omit(settings, 'colors'));
  }
};

const toViews = (views: ViewsEntity): Views => {
  const keys = Object.keys(views) as (ViewTypes | '__typename')[];
  const emptyCopy: Record<string, LayoutSettings[]> = {};
  for (const key of keys) {
    if (key === '__typename') continue;
    emptyCopy[key] = [...(views[key] ?? []).map(toLayoutSettings)];
  }
  return emptyCopy as Views;
};

export const toMdfDto = (mdf: MdfType): Mdf => {
  const read = JSON.parse(mdf.permissions.read) as Record<string, string[]>;
  const write = JSON.parse(mdf.permissions.write) as Record<string, string[]>;

  return removeTypeNameAndNullProps({
    ...mdf,
    views: toViews(mdf.views),
    permissions: {
      read,
      write,
    },
    fields: mdf.fields.map(toFieldMdfDto),
  });
};
