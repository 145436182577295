import React from 'react';
import type {
  CellContext,
  ColumnDef,
  ColumnOrderState,
  ColumnSizingState,
  ExpandedState,
  Row,
  RowSelectionState,
  TableMeta,
  Updater,
  VisibilityState,
} from '@tanstack/react-table';
import type { Virtualizer } from '@tanstack/react-virtual';
import type { ParsedMemberType } from 'types';

import { DinaTable } from 'features/grids/common/types';
import {
  CLIP_TIME_FIELD_KEY,
  SPEAK_TIME_FIELD_KEY,
  TOTAL_TIME_FIELD_KEY,
} from 'features/mdf/mdf-defaults';
import type { AdjustedRundownType } from 'screens/rundown/api/useGetRundown';
import { RundownState } from 'screens/rundown/utils/rundownStates';
import type { NewFieldValue } from 'types/forms/forms';
import type { MemberType, RundownSequenceState } from 'types/graphqlTypes';

import { RundownLocation } from '../state/rundown';

export type MemberEditableData = Record<string, RundownCellData>;
export type AllEditableData = Record<'ready' | 'preparing', MemberEditableData>;

/**
 * This is automatically synchronized to local storage,
 * and acts as a source of truth for stored rundwon layouts.
 */
export type RundownTableState = {
  expanded: Record<string, boolean>;
  columnVisibility: Record<string, boolean>;
  columnSizing: Record<string, number>;
  columnOrder: string[];
  layoutId: string | null;
};

/**
 * A segment is a part of the rundown where the start instance
 * denotes an absolute time, and all of the following/previous
 * instances will use this as a calculation.
 */
export interface Segment {
  members: RundownCellData[];
  totalTime: number;
}

/**
 * A TimeData contains the value, and whether
 * the object is base of a timing calculation
 * in other words, should be drawn orange in the UI.
 */
export interface TimeData {
  isBase: boolean;
  value: string | null;
}

export interface Data {
  cume: TimeData | null;
  back: TimeData | null;
}

/**
 * TimeData will contain all updated back/cume timings, to be consumed by the user interface.
 */
export type CalculatedTimeData = Record<string, Data>;
export type CalculatedTimings = Record<'ready' | 'preparing', CalculatedTimeData>;

export type SegmentWithTiming = {
  id: string;
  [TOTAL_TIME_FIELD_KEY]: number;
  [CLIP_TIME_FIELD_KEY]: number;
  [SPEAK_TIME_FIELD_KEY]: number;
};

export type SingleRundownStories = {
  ready: Record<string, string[]>;
  preparing: Record<string, string[]>;
};

export type AllRundownStories = Record<string, SingleRundownStories>;
export type Sequences = {
  ready: RundownSequence | null;
  preparing: RundownSequence | null;
};

export type RundownAsset = {
  mId: string;
  mRefId: string;
  instanceId: string;
  state?: string;
};
export type AllRundownAssets = Record<'media' | 'graphics', RundownAsset[]>;

export type LightWeightCellProps = Pick<CellContext<SlimRow, string>, 'row' | 'table'> & {
  setEditing: (val: boolean) => void;
  cellId: string;
  value: string;
  setValue: (val: string) => void;
};

export interface RundownListProps {
  sequence: RundownSequence;
  isSubRundown?: boolean;
  onFocusCell: (cellId: string) => void;
  onBlurCell: () => void;
  location: RundownLocation;
}

export interface GroupInfo {
  color: string;
  name: string;
}

export type AllGroupInfo = Record<string, GroupInfo>;

export type SlimRow = {
  mId: string;
  sequenceId: string;
  sequenceType: 'ready' | 'preparing';
  isTemplate: boolean;
  isCurrentPlayingInstance: boolean;
  hasRun: boolean;
  isRunning: boolean;
  color?: string;
  label?: string;
  children?: SlimRow[];
};

/**
 * Set of functions injected per table that should be rundown wide,
 * eg these will need to have an understanding between ready / preparing
 * to for example handle selection state across.
 */
export interface RundownsMeta {
  /**
   * Create and add an instance to rundown
   * @param sequenceId Rundown id to add instance to
   * @param title New instance title
   * @param index Which index to place the instance at
   * @param type Which list type, ready vs preparing
   * @param callback A callback invoked with the
   *  newly created instance, or null if something went wrong
   * @param skipCreateStory Whether to skip creating a story
   */
  createInstance: (
    sequenceId: string,
    title: string,
    index: number,
    type: 'ready' | 'preparing',
    skipCreateStory: boolean,
    callback: (val: MemberType | null) => void,
  ) => void;
  /**
   * Update a single metadata field value
   * @param row The row to update the metadata for
   * @param val The new field value
   */
  updateMetadata: (row: SlimRow, val: NewFieldValue[]) => void;
  /**
   * Update the rundown object. Use with care - this will also update
   * the rundown optimistically in the cache.
   *
   * @param sequenceId Rundown identifier
   * @param updates The props to update
   */
  updateRundown: (sequenceId: string, updates: Partial<AdjustedRundownType>) => void;

  /**
   * Fired when a cell is focused for input by the user
   * @param cellId Which cell is focused
   */
  onFocusCell?: (cellId: string) => void;

  /**
   * Fired when a cell is blurred for input by the user
   * @param cellId Which cell is blurred
   */
  onBlurCell?: (cellId: string) => void;

  /**
   * Insert a placeholder to the provided rundown
   * @param sequenceId The rundown id to insert the placeholder in
   * @param index At which index to insert the placeholder TODO - should not be index based
   * @param type Which list to insert a placeholder in
   */
  insertPlaceholder: (sequenceId: string, index: number, type: 'ready' | 'preparing') => void;

  /**
   * Insert a group into a sequence
   * @param sequenceId The rundown id to insert the placeholder in
   * @param start Start index of the list where the group header should be
   * @param end The index of the last item that should be part of the group
   * @param type Which list the group should be part of - TODO - factor out
   * @returns The newly created group identifier
   */
  insertGroup: (
    sequenceId: string,
    start: number,
    end: number,
    type: 'ready' | 'preparing',
  ) => string | null;

  /**
   * Given a group id, ungroup all its rows and remove the group.
   * @param id The group id to remove
   * @param sequence which sequence the operation should be performed against
   */
  unGroup: (id: string, sequence: RundownSequence) => void;

  /**
   * Remove the target id placeholder from the provided sequence
   * @param id Id of the placeholder to be removed
   * @param sequence The sequence the placeholder is part of
   */
  removePlaceholder: (id: string, sequence: RundownSequence) => void;

  /**
   * Given a group id, change its color
   * @param sequenceId The rundown the group is part of
   * @param id The group id to change
   * @param color The new color
   */
  setGroupColor: (sequenceId: string, rowId: string, color: string) => void;

  /**
   * Given a group id, change its name
   * @param sequenceId The rundown the group is part of
   * @param rowId The group id to change
   * @param name The new name
   * @returns
   */
  applyGroupName: (sequenceId: string, rowId: string, name: string) => void;
}

export interface RundownCellDragData {
  item: SlimRow;
  sourceSequence: string;
  sourceList: 'ready' | 'preparing';
}

export interface RundownStoryDragData {
  story: MemberType;
}

export type RundownGridProps = {
  data: SlimRow[];
  columns: ColumnDef<SlimRow>[];
  meta: TableMeta<SlimRow>;
  type: 'ready' | 'preparing';
  columnVisibility: Record<string, boolean>;
  setColumnVisibility: (upd: Updater<RowSelectionState>) => void;
  columnSizing: Record<string, number>;
  setColumnSizing: (upd: Updater<ColumnSizingState>) => void;
  expanded: ExpandedState;
  setExpanded: (upd: Updater<ExpandedState>) => void;
  columnOrder: string[];
  setColumnOrder: (upd: Updater<ColumnOrderState>) => void;
};

export type TableRowWrapperProps = Pick<TableMeta<SlimRow>, 'rowMeta'> & {
  rows: Row<SlimRow>[];
  measureElement: Virtualizer<HTMLDivElement, Element>['measureElement'];
  start: number;
  isSkeletonRow: boolean;

  /* Passed in as a prop to break row memoization */
  columnVisibility?: VisibilityState;
  expanded?: ExpandedState;
  table: DinaTable<SlimRow>;
  staticRowIndex: number;
};

export type RundownRowProps = Pick<TableMeta<SlimRow>, 'rowMeta'> & {
  row: Row<SlimRow>;
  measureElement: Virtualizer<HTMLDivElement, Element>['measureElement'];
  start: number;
  isSkeletonRow: boolean;
  table: DinaTable<SlimRow>;
  staticRowIndex: number;
  onDrop: (
    droppedOn: SlimRow,
    droppedData: RundownCellDragData | MemberType,
    selectedRows: Record<string, boolean>,
    offset: number,
  ) => void;
  onClick: (ev: React.MouseEvent<HTMLElement>) => void;
};

export type RundownTableProps = {
  data: SlimRow[];
  sequence: RundownSequence;
  isSubRundown?: boolean;
  onFocusCell: (cellId: string) => void;
  onBlurCell: () => void;
  location: RundownLocation;
};

export interface ContainerProps {
  onFocusCell: (cellId: string) => void;
  onBlurCell: () => void;
  location: RundownLocation;
}

export type RundownCellData = Pick<
  ParsedMemberType,
  | 'mId'
  | 'mTitle'
  | 'metadata'
  | 'mAssignedMembers'
  | 'mThumbnailKey'
  | 'items'
  | 'mStoryId'
  | 'assets'
  | 'mProperties'
> & {
  lastUpdatedBy?: string;
  order: string | undefined;
  presenterId: string | undefined;
  isRundownOnly: boolean;
  isFloated: boolean;
  lineStyle: string;
  readSpeed: number;
  wordCount: number;
  isCurrentInstance: boolean;
  cumeOverrideTime: string | undefined;
};

export interface RundownSequence {
  id: string; // Rundown mId
  order: string[]; // Rundown order
  title: string; // Rundown title
  plannedDuration: string | undefined;

  /**
   * The planned publication date and time of the rundown sequence
   * in ISO 8601 format (e.g., "2024-07-05T17:30:00Z").
   */
  publishingAt?: string; // mPublishingAt

  // playback
  state: RundownState; // mState, mPreparingState

  /**
   * State properties used when playing the rundown sequence.
   * I.e. when the rundown sequence is onair
   */
  playState?: RundownSequenceState; // Aka. RundownPlayState

  // This is still needed downstream to read out the right state
  // in tableCells - we want to avoid having to send timing state
  // as props down many components to avoid table rerenders.
  type: 'ready' | 'preparing';

  // Contains information about groups
  groupInfo: Record<string, GroupInfo>;

  // Are we in a rundown template
  isTemplate: boolean;

  // If a subrundown, which rundown does it belong to.
  // Stored in mSecRefId
  mainRundown?: string;
}

type MappedDurationKeys = keyof Pick<
  AdjustedRundownType,
  'mPlannedDuration' | 'mPrePlannedDuration'
>;

type MappedOrderKeys = keyof Pick<AdjustedRundownType, 'mOrder' | 'mPreorder'>;

// Maps ready/preparing to the right rundown property
export const typeToOrderProp: Record<'ready' | 'preparing', MappedOrderKeys> = {
  ready: 'mOrder',
  preparing: 'mPreorder',
};

// Maps ready/preparing to the right rundown property
export const typeToDurationProp: Record<'ready' | 'preparing', MappedDurationKeys> = {
  ready: 'mPlannedDuration',
  preparing: 'mPrePlannedDuration',
};
