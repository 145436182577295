/**
 * The two possible values of the {@link VisibilityProvider.visibilityState} property:
 * `'visible'` and `'hidden'`
 */
export type Visibility = 'visible' | 'hidden';

/**
 * An interface providing the visibility of the application and also allows monitoring it.
 * This interface is implemented by the browser's `document` object.
 */
export default interface VisibilityProvider {
  /**
   * The current visibility state of the application.
   * It is `'visible'` when the application is visible and `'hidden'` when it is not.
   */
  readonly visibilityState: Visibility;

  /**
   * Adds a listener that gets called when the visibility changes.
   *
   * Remember to remove the listener when notifications are no longer needed using
   * {@link VisibilityProvider.removeEventListener}.
   * @param event    The event type, which must be `'visibilitychange'`.
   * @param listener The event listener that gets called when the visibility changes.
   */
  readonly addEventListener: (event: 'visibilitychange', listener: () => void) => void;

  /**
   * Removes a listener previously added with {@link VisibilityProvider.addEventListener}.
   * @param event    The event type, which must be `'visibilitychange'`.
   * @param listener The event listener that should be removed. Must be the exact same listener
   *                 that was earlier passed to {@link VisibilityProvider.addEventListener}!
   */
  readonly removeEventListener: (event: 'visibilitychange', listener: () => void) => void;
}

/**
 * Implements the {@link VisibilityProvider} interface and provides a setter for the
 * `visibilityState` property that will cause the `'visibilitychange'` event to be fired
 * if visibility changes.
 */
export class ManualVisibilityProvider implements VisibilityProvider {
  readonly #listeners: (() => void)[] = [];

  #visibilityState: Visibility;

  /**
   * Constructs the {@link ManualVisibilityProvider}
   * @param visibilityState The initial value for the `visibilityState` property
   */
  constructor(visibilityState: Visibility) {
    this.#visibilityState = visibilityState;
  }

  get visibilityState() {
    return this.#visibilityState;
  }

  /**
   * Sets the value of the `visibilityState` property and fires the `'visibilitychange'` event
   * if the value changes.
   * @param visibilityState The new value for the `visibilityState` property
   */
  set visibilityState(visibilityState: Visibility) {
    if (this.#visibilityState === visibilityState) return;
    this.#visibilityState = visibilityState;
    [...this.#listeners].forEach((listener) => listener());
  }

  /**
   * Gets the number of registered listeners (mostly useful for testing, I guess).
   */
  get listenerCount() {
    return this.#listeners.length;
  }

  addEventListener = (_event: 'visibilitychange', listener: () => void) => {
    this.#listeners.push(listener);
  };

  removeEventListener = (_event: 'visibilitychange', listener: () => void) => {
    const pos = this.#listeners.indexOf(listener);
    if (pos >= 0) {
      this.#listeners.splice(pos, 1);
    }
  };
}
