import { useEffect, useRef } from 'react';

import { useAsyncConfirmationDialogPropsState } from 'hooks/useAsyncConfirmation';
import { ConfirmationDialogProps } from 'types/ui';

import Dialog from './DialogBuilder';

interface Props extends ConfirmationDialogProps {
  open: boolean;
}

const noOp = () => undefined;

function Template({
  open,
  onClose,
  onClick,
  title,
  loading,
  message,
  usage,
  confirmLabel,
  cancelLabel,
  style,
  overlayStyle,
}: Readonly<Props>) {
  const messageDivRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (open) {
      setTimeout(() => {
        const buttons =
          messageDivRef.current?.parentElement?.nextElementSibling?.querySelectorAll('button');
        if (buttons?.length === 2) {
          buttons.item(1).focus();
        }
      }, 0);
    }
  }, [open]);
  return (
    <Dialog open={open} onClose={onClose} style={style} overlayStyles={overlayStyle}>
      <Dialog.Header showCloseIcon={false}>{title}</Dialog.Header>
      <Dialog.Body>
        <div ref={messageDivRef}>{message}</div>
      </Dialog.Body>
      <Dialog.Footer>
        <Dialog.CancelButton label={cancelLabel} />
        <Dialog.ConfirmButton
          onClick={onClick}
          usage={usage}
          label={confirmLabel}
          loading={loading}
        />
      </Dialog.Footer>
    </Dialog>
  );
}

export const ConfirmDialog = ({ confirmLabel, usage, ...rest }: Props) => (
  <Template {...rest} confirmLabel={confirmLabel ?? 'Ok'} usage={usage ?? 'cta'} />
);
export const WarningDialog = ({ confirmLabel, ...rest }: Props) => (
  <Template {...rest} usage="warning" confirmLabel={confirmLabel ?? 'Ok'} />
);
export const DeleteDialog = ({ confirmLabel, ...rest }: Props) => (
  <Template {...rest} usage="danger" confirmLabel={confirmLabel ?? 'Delete'} />
);

/**
 * This dialog MUST be rendered in the main layout in order to use the `useAsyncConfirmation`
 * function.
 */
export function AsyncConfirmationDialog() {
  const state = useAsyncConfirmationDialogPropsState();
  const props = state
    ? { ...state, open: true }
    : { title: '', message: '', open: false, onClose: noOp, onClick: noOp };
  return <Template {...props} />;
}
