import { useEffect, useRef, useState } from 'react';

import Divider from 'components/divider';
import TimePicker from 'components/timePicker';
import useDateTimeUtils from 'hooks/useDateTimeUtils';
import { DTInputWrapper, StyledDateInput, TimePickerWrapper } from './styled';

const DateTimeInput = ({
  allowTimeSelection,
  showTimePicker,
  date,
  time,
  onTimeSelect,
  timeFor,
  onDateSelect,
  otherDateRange,
  otherTimeRange,
  selectRange,
  handleRangeSelect,
  interval,
  format,
}) => {
  const { isEqual, format: fnsFormat, parseISO } = useDateTimeUtils();
  const [localDate, setLocalDate] = useState(date ? fnsFormat(new Date(date), 'yyyy-MM-dd') : '');
  const [localTime, setLocalTime] = useState(date ? time : '');
  const [isFocused, setIsFocused] = useState(false);
  const isFirstRender = useRef(true);
  const noStartDate = timeFor !== 'date' && !otherDateRange;

  const onFocus = () => {
    setIsFocused(true);
  };

  const onBlur = (e) => {
    setIsFocused(false);
    if (timeFor !== 'date' && e.target.validity.valid && e.target.value.length === 10) {
      handleRangeSelect(e.target.value, timeFor);
    }
  };

  const setTime = (value, timeFor = 'date') => {
    setLocalTime(value);
    onTimeSelect(value, timeFor);
  };

  const handleDateChange = (e) => {
    const selectedDate = e.target.value;
    setLocalDate(selectedDate);
    const parsedISODate = parseISO(selectedDate);
    // Only trigger the onDateSelect callback if the selected date is complete
    if (e.target.validity.valid && e.target.value.length === 10) {
      const isoDate = new Date(parsedISODate).toISOString();
      onDateSelect(isoDate, timeFor);
    }
  };

  const handleTimeChange = (e) => {
    if (selectRange) {
      const parsedLocalDate = Date.parse(`${localDate} ${e.target.value}:00`);
      const parsedOtherDate = Date.parse(
        `${fnsFormat(new Date(otherDateRange), 'yyyy-MM-dd')} ${otherTimeRange}:00`,
      );

      // should cancel if new date time for start date is higher than end date time
      // or if new date time for end date is lower than start date time
      const shouldCancel =
        timeFor === 'date'
          ? !!parsedOtherDate
            ? parsedLocalDate > parsedOtherDate
            : false
          : parsedLocalDate < parsedOtherDate;

      if (shouldCancel) return;
      setTime(e.target.value, timeFor);
    } else {
      setTime(e.target.value, timeFor);
    }
  };

  useEffect(() => {
    const formatedDate = date ? fnsFormat(new Date(date), 'yyyy-MM-dd') : '';
    if (!isEqual(localDate, formatedDate) && (isFirstRender.current || !isFocused)) {
      if (formatedDate) {
        setLocalDate(formatedDate);
        setLocalTime(time);
        isFirstRender.current = false;
        return;
      }
      setLocalDate('');
      setLocalTime('');
      return;
    }
    if (!isEqual(time, localTime)) {
      setLocalTime(time);
    }
  }, [date, isFocused, localDate, localTime, time]);

  return (
    <DTInputWrapper>
      <StyledDateInput
        id="calendarDate"
        type="date"
        value={date ? localDate : ''}
        onChange={handleDateChange}
        onFocus={onFocus}
        onBlur={onBlur}
        disabled={noStartDate}
        fullWidth
        InputLabelProps={{
          shrink: true,
          hidden: true,
        }}
        InputProps={{
          disableUnderline: true,
          autoFocus: timeFor === 'date',
        }}
      />
      {/** to use the old time picker */}
      {allowTimeSelection && (
        <>
          <Divider orientation="vertical" flexItem />
          <TimePickerWrapper>
            <TimePicker time={time} onUpdateTime={setTime} interval={interval} format={format} />
          </TimePickerWrapper>
        </>
      )}

      {showTimePicker && !allowTimeSelection && (
        <>
          <Divider orientation="vertical" flexItem />
          <StyledDateInput
            id="calendarTime"
            type="time"
            value={date ? localTime : ''}
            disabled={timeFor !== 'date' ? noStartDate || !date : !date}
            onChange={handleTimeChange}
            fullWidth
            InputLabelProps={{
              shrink: true,
              hidden: true,
            }}
            InputProps={{
              disableUnderline: true,
            }}
          />
        </>
      )}
    </DTInputWrapper>
  );
};

export default DateTimeInput;
