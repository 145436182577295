import { useCallback, useEffect, useMemo, useState } from 'react';
import { Skeleton } from '@material-ui/lab';
import keyBy from 'lodash/keyBy';

import { useEditStringDialog } from 'components/editStringDialog/EditStringDialog';
import EmptyState from 'components/emptyState/EmptyState';
import Scrollbar from 'components/scrollbar';
import SplitBar from 'components/split';
import useToast from 'components/toast/useToast';
import { VStack } from 'layouts/box/Box';
import { useCreateProvider } from 'screens/main/components/header/navbar/settings/hooks/useCreateProvider';
import { PlatformDTOSettings, ProviderDTO } from 'types/graphqlTypes';
import { getPlatformIcon } from 'utils/instance/platform';

import { useChangedPlatforms, useChangedProviders } from '../../atomsTs';
import useGetAllPlatforms from '../../hooks/useGetAllPlatforms';
import useGetAllProvider from '../../hooks/useGetAllProviders';
import SettingsListItem from '../layout/SettingsListItem';
import { SettingsListSection } from '../layout/SettingsListSection';

import { CreatePlatformDialogue } from './dialogs/CreatePlatformDialog';
import { EditPlatform } from './platformSettings/EditPlatformSettings';
import { EditProvider } from './providerSettings/EditProviderSettings';

import { FormsWrapper, Wrapper } from './styled';
export const programmaticIdRegex = /^[a-zA-Z0-9-_.]*$/;
interface PlatformListItemProps {
  platform: PlatformDTOSettings | null | undefined;
  selectedPlatform: PlatformDTOSettings | null;
  changedPlatform: Record<string, PlatformDTOSettings>;
  icon?: React.FC<React.SVGProps<SVGSVGElement>>;
  setSelectedPlatformId: React.Dispatch<React.SetStateAction<string | null | undefined>>;
  getPlatformIcon?: (platform: string) => React.FC<React.SVGProps<SVGSVGElement>> | undefined;
}

function PlatformListItem({
  changedPlatform,
  platform,
  setSelectedPlatformId,
  selectedPlatform,
}: Readonly<PlatformListItemProps>) {
  const updatedPlatform = changedPlatform[platform?.id as unknown as string];
  const Icon = getPlatformIcon(platform?.platformInfo?.platformIcon) ?? null;

  return (
    <SettingsListItem
      Icon={Icon ?? undefined}
      selected={platform?.id === selectedPlatform?.id}
      tooltip={updatedPlatform && `${updatedPlatform?.id ?? platform?.id} has unsaved changes`}
      onClick={() => {
        setSelectedPlatformId(platform?.id as unknown as string);
      }}
      label={(updatedPlatform?.id as unknown as string) ?? platform?.id}
      hasChange={!!updatedPlatform}
    />
  );
}

interface ProviderListItemProps {
  provider: ProviderDTO | null | undefined;
  selectedProvider: ProviderDTO | null;
  changedProvider: Record<string, ProviderDTO>;
  setSelectedProviderId: React.Dispatch<React.SetStateAction<string | null | undefined>>;
}

function ProviderListItem({
  changedProvider,
  provider,
  setSelectedProviderId,
  selectedProvider,
}: Readonly<ProviderListItemProps>) {
  const updatedProvider = changedProvider[provider?.id as unknown as string];
  return (
    <SettingsListItem
      selected={provider?.id === selectedProvider?.id}
      tooltip={updatedProvider && `${updatedProvider?.id ?? provider?.id} has unsaved changes`}
      onClick={() => {
        setSelectedProviderId(provider?.id as unknown as string);
      }}
      label={(updatedProvider?.id as unknown as string) ?? provider?.id}
      hasChange={!!updatedProvider}
    />
  );
}

export function Platform() {
  const { errorToast } = useToast();
  const { data: allPlatforms, loading, error } = useGetAllPlatforms();
  const { data: allProvider, loading: providerLoading, error: providerError } = useGetAllProvider();

  const [selectedOption, setSelectedOption] = useState<string | null>();
  const [changedPlatformAtom] = useChangedPlatforms();
  const [changedProviderAtom] = useChangedProviders();
  const [showCreatePlatformDialog, setShowCreatePlatformDialog] = useState(false);
  const [, showEditStringDialog] = useEditStringDialog();
  const { createProvider } = useCreateProvider();

  const [platforms, setPlatforms] = useState(allPlatforms || []);
  const [providers, setProviders] = useState(allProvider || []);

  useEffect(() => {
    if (allPlatforms) {
      setPlatforms(allPlatforms);
    }
  }, [allPlatforms]);

  useEffect(() => {
    if (allProvider) {
      setProviders(allProvider);
    }
  }, [allProvider]);

  const providerById = useMemo(() => {
    return keyBy(providers, (provider) => provider.id);
  }, [providers]);

  const doCreateProvider = useCallback(() => {
    showEditStringDialog({
      headerText: 'Create new Provider',
      required: true,
      inputLabel: 'Provider',
      validator: (val: string) => {
        if (val.length < 3) {
          return 'Minimum length is 3 characters';
        }
        if (!programmaticIdRegex.test(val)) {
          return 'Only alphanumeric characters allowed';
        }
        if (providerById[val]) {
          return 'Provider already exists';
        }
        return true;
      },
      onConfirm: (val) => {
        createProvider(val).then(
          (res) => {
            setSelectedOption(res?.id);
          },
          (err) => {
            errorToast(err, 'On create error');
          },
        );
      },
    });
  }, [createProvider, errorToast, providerById, showEditStringDialog]);

  const selectedPlatform = useMemo(() => {
    if (!selectedOption) return null;
    return (
      changedPlatformAtom[selectedOption] ??
      platforms?.find((platform) => platform?.id === selectedOption) ??
      null
    );
  }, [platforms, changedPlatformAtom, selectedOption]);

  const selectedProvider = useMemo(() => {
    if (!selectedOption) return null;
    return (
      changedProviderAtom[selectedOption] ??
      providers?.find((provider) => provider?.id === selectedOption) ??
      null
    );
  }, [changedProviderAtom, providers, selectedOption]);

  const SideBar = useMemo(() => {
    if (loading || providerLoading) {
      return (
        <VStack flex="1" justifyContent="flex-start" margin="0.5rem 0 0 0" gap="0.25rem">
          <Skeleton animation="wave" width="100%" />
          <Skeleton animation="wave" width="100%" />
          <Skeleton animation="wave" width="100%" />
        </VStack>
      );
    }
    if (error || providerError) errorToast(error?.message ?? providerError?.message);
    return (
      <FormsWrapper>
        <Scrollbar>
          <SettingsListSection
            label="Platforms"
            onAddClick={() => {
              setShowCreatePlatformDialog(true);
            }}
            tooltip="Create platform"
          >
            {!error &&
              platforms?.map((platform) => {
                return (
                  <PlatformListItem
                    key={platform.id}
                    platform={platform}
                    changedPlatform={changedPlatformAtom}
                    selectedPlatform={selectedPlatform}
                    setSelectedPlatformId={setSelectedOption}
                  />
                );
              })}
          </SettingsListSection>
          <SettingsListSection
            label="Providers"
            onAddClick={doCreateProvider}
            tooltip="Create provider"
          >
            {providers?.map((provider) => (
              <ProviderListItem
                key={provider.id}
                provider={provider}
                changedProvider={changedProviderAtom}
                selectedProvider={selectedProvider}
                setSelectedProviderId={setSelectedOption}
              />
            ))}
          </SettingsListSection>
        </Scrollbar>
      </FormsWrapper>
    );
  }, [
    loading,
    providerLoading,
    error,
    providerError,
    errorToast,
    platforms,
    doCreateProvider,
    providers,
    changedPlatformAtom,
    selectedPlatform,
    changedProviderAtom,
    selectedProvider,
  ]);

  return (
    <Wrapper>
      <SplitBar
        split={undefined}
        style={{
          height: '100%',
        }}
        primary="first"
        pane1Style={{
          minWidth: '180px',
          maxWidth: '300px',
        }}
        pane2Style={{
          minWidth: '200px',
          height: '100%',
        }}
      >
        {SideBar}
        {!selectedOption && <EmptyState message="Please select an option" />}
        {selectedPlatform && <EditPlatform platform={selectedPlatform} providers={allProvider} />}
        {selectedProvider && <EditProvider provider={selectedProvider} />}
      </SplitBar>
      {showCreatePlatformDialog && (
        <CreatePlatformDialogue
          open={showCreatePlatformDialog}
          setOpen={setShowCreatePlatformDialog}
          providers={allProvider}
          platforms={platforms}
          setSelectedOption={setSelectedOption}
        />
      )}
    </Wrapper>
  );
}
