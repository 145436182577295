import styled from '@emotion/styled/macro';

export const SideBarWrapper = styled('div')<{ height: number }>`
  height: ${({ height }) => `${height}px`};
  overflow: auto;
`;

export const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const FormsWrapper = styled('div')`
  width: 100%;
  height: 100%;
`;

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: hidden;
  & > first-child:not(:last-child) {
    flex: 0 0 auto;
  }
  & > :last-child:not(:first-child) {
    flex: 1 1 0;
  }
  & > :first-child:last-child {
    height: 100%;
  }
`;

export const NavBarSectionWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  min-width: calc(100% - 24px);
  & > :first-child {
    flex: 1 1 0;
  }
  & > :last-child:not(:first-child) {
    flex: 0 0 auto;
  }
`;

export const NavBarItemWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  min-width: 100%;
  & > :nth-child(2) {
    flex: 1 1 0;
  }
  & > :not(:nth-child(2)) {
    flex: 0 0 auto;
  }
  .actionIcon {
    transition: width 150ms;
    width: 0px;
    overflow: hidden;
  }
  &:hover {
    button.actionIcon.actionIcon {
      width: 24px;
      svg {
        path {
          fill: ${({ theme }) => theme.palette.dina.mediumEmphasis};
        }
      }
      &:hover {
        svg {
          path {
            fill: ${({ theme }) => theme.palette.dina.highEmphasis};
          }
        }
      }
    }
  }
`;
