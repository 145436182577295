import { useContext, useState } from 'react';

import Divider from 'components/divider';
import Tabs from 'components/tabs/contained';
import configCtx from 'contexts/configContext';
import { HStack } from 'layouts/box/Box';
import { MetadataFormType, MMetaDataField } from 'types/graphqlTypes';

import { useSelectedMenuSystemSettings } from '../../../../../atoms';

import WorkflowSettings from './workflowSettings/WorkflowSettings';
import InstanceFields from './InstanceFields';

import { RootWrapper } from './styled';

const tabs = ['Choose available Metadata fields', 'Workflow settings'];

const Instances = (props: { fields: MMetaDataField[] }) => {
  const { fields } = props;
  const { metadataForms } = useContext(configCtx);
  const [selectedMenu] = useSelectedMenuSystemSettings() as unknown as [string];
  const { views } = metadataForms[0] as MetadataFormType;

  const defaultView = views?.find((view) => view.id === 'instance-metadata-1');
  const selectedView = views?.find((view) => view.id === `${selectedMenu}-instance-metadata-1`);

  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  const viewHelper = () => {
    switch (selectedTab) {
      case tabs[0]:
        return <InstanceFields fields={fields} selectedView={selectedView || defaultView} />;
      case tabs[1]:
        return <WorkflowSettings />;
      default:
        return <div />;
    }
  };

  return (
    <RootWrapper>
      <HStack width="100%" padding="6px">
        <Tabs
          tabs={tabs}
          activeTab={selectedTab}
          setActiveTab={setSelectedTab}
          styleProps={{
            fullWidth: true,
          }}
        />
      </HStack>
      <Divider />
      {viewHelper()}
    </RootWrapper>
  );
};

export default Instances;
