import React, { forwardRef, useState } from 'react';

import { ReactComponent as Copy } from 'assets/icons/systemicons/copy.svg';
import { ReactComponent as Edit } from 'assets/icons/systemicons/edit.svg';
import { IconButton } from 'components/buttons';
import stopAllPropagation from 'components/editor/utils/stopAllPropagation';
import Text from 'components/text/Text';
import Tooltip from 'components/tooltip';
import { HStack } from 'layouts/box/Box';

import { IconWrapper, LinkWrapper, StyledA } from './styled';

interface LinkProps {
  url: string | undefined;
  hint: string | undefined;
  onEditClick: () => void;
  onFocus?: () => void;
}

function Link(
  { url, hint, onEditClick, onFocus }: Readonly<LinkProps>,
  ref: React.Ref<HTMLDivElement>,
) {
  const [copyTooltip, setCopyTooltip] = useState('Copy value');
  const doCopy = (v: string | undefined) => {
    void navigator.clipboard.writeText(v ?? '');
    setCopyTooltip('Copied!');
    setTimeout(() => setCopyTooltip('Copy value'), 2000);
  };

  return (
    <Tooltip title={url && url.length > 0 ? 'Click to open in a new tab' : 'Edit link'}>
      <LinkWrapper
        $url={!!url}
        $height="100%"
        ref={ref}
        tabIndex={0}
        onFocus={onFocus}
        className="link-wrapper"
      >
        {url && url.length > 0 ? (
          <HStack justifyContent="space-between" width="100%">
            <StyledA
              href={url}
              target="_blank"
              rel="noreferrer"
              title={hint ?? url}
              onMouseDown={stopAllPropagation}
            >
              {url}
            </StyledA>
            <IconWrapper className="edit-icn">
              <IconButton title="Edit" usage="text" size={24} iconSize={16} onClick={onEditClick}>
                <Edit />
              </IconButton>
              <IconButton
                title={copyTooltip}
                usage="text"
                size={24}
                iconSize={16}
                onClick={() => doCopy(url)}
              >
                <Copy />
              </IconButton>
            </IconWrapper>
          </HStack>
        ) : (
          <Text
            variant="listItemLabel"
            color="lowEmphasis"
            onClick={onEditClick}
            style={{ margin: 'auto', fontSize: '11px' }}
          >
            Set a link
          </Text>
        )}
      </LinkWrapper>
    </Tooltip>
  );
}

export default forwardRef(Link);
