import RadioButton from 'components/buttons/radioButton';
import { VStack } from 'layouts/box/Box';
import { KanbanBoardStateType } from 'types';

import { RadioButtonWithLabel } from './styled';

function StateGroup({
  states,
  selectedState,
  onStateChange,
}: Readonly<{
  states: KanbanBoardStateType[];
  selectedState: string | null;
  onStateChange: (state: string) => void;
}>) {
  return (
    <VStack gap="0.5rem" overflow="visible" padding="0.5rem 0">
      {states.map((state) => (
        <RadioButtonWithLabel
          key={state.id}
          style={{ display: 'flex', alignItems: 'center' }}
          onClick={() => onStateChange(state.id)}
        >
          <RadioButton
            size={20}
            key={state.id}
            value={state.id}
            selected={selectedState === state.id}
          />
          {state.name}
        </RadioButtonWithLabel>
      ))}
    </VStack>
  );
}

export default StateGroup;
