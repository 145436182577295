/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React from 'react';
import PropTypes from 'prop-types';
import { useDrag } from 'react-dnd';
import { Typography } from '@material-ui/core';

import dndTypes from 'utils/dndTypes';
import useDateTimeUtils from 'hooks/useDateTimeUtils';

import Thumbnail from './thumbnail-view';
import useStyles from './mediaCard-styles';

const MediaEnum = {
  video: dndTypes.CLIP,
  graphic: dndTypes.GRAPHICS,
  mos: dndTypes.MOS,
};

const MediaCard = ({
  item,
  fileName,
  date,
  fileSize,
  image,
  iconUrl,
  variant,
  isNew,
  duration,
  onPlay,
}) => {
  const { format } = useDateTimeUtils();
  const classes = useStyles();

  const type = MediaEnum[variant] || dndTypes.GRAPHICS;

  const [, dragRef] = useDrag({
    type,
    item: { type, payload: item, iconUrl },
  });
  return (
    <div className={classes.container} tabIndex="-1" ref={dragRef}>
      <div className={classes.thumbnail}>
        <Thumbnail {...{ image, variant, isNew, duration, onPlay }} />
      </div>
      <div className={classes.contentCell}>
        <Typography classes={{ root: classes.fileName }}>{fileName}</Typography>
        <Typography classes={{ root: classes.date }}>
          {date ? format(date, 'd MMM yyyy') : ''}
        </Typography>
        <Typography classes={{ root: classes.date }}>{fileSize}</Typography>
      </div>
    </div>
  );
};

MediaCard.propTypes = {
  /** Name of the file */
  fileName: PropTypes.string,
  /** Adding date */
  date: PropTypes.string,
  /** Size of the file */
  fileSize: PropTypes.string,
  /** Thumbnail image of the item */
  image: PropTypes.string,
  /** Variant of item */
  variant: PropTypes.string,
  /** Indicates if the item is recently added */
  isNew: PropTypes.bool,
  /** duration of the video */
  duration: PropTypes.number,
  /** Callback to be invoked when play media button is pressed */
  onPlay: PropTypes.func,
};

MediaCard.defaultProps = {
  fileName: '',
  date: '',
  fileSize: '',
  image: null,
  variant: 'video',
  isNew: false,
  duration: 0,
  onPlay: () => {},
};

export default MediaCard;
