import { useState } from 'react';

import AddMemberDropdown from 'components/addMember';
import MemberLabel from 'components/addMember/MemberLabel';
import { MdfEditor } from 'components/mdfEditor/MdfEditor';
import { FieldHeader } from 'components/mdfEditor/styled';
import { SelectAssigneeWrapper } from 'components/orderFormDialog/styled';
import Scrollbar from 'components/scrollbar/scrollbar';
import Text from 'components/text/Text';
import PreviewPlanningConfig from 'features/sidepanel/PreviewPlanningConfig';
import { Mdf, MemberTypeEnum } from 'types/graphqlTypes';
import { AssignedMember } from 'types/members';
import { EditorCommandConfigType } from 'types/memberTypes/editorCommands';

interface PreviewProps {
  selectedCommand: EditorCommandConfigType;
  mdf?: Mdf;
}

function OrderFormPreview({ mdf }: Readonly<Omit<PreviewProps, 'selectedCommand'>>) {
  const [isFindingAssignee, setIsFindingAssignee] = useState(false);
  const [assignee, setAssignee] = useState<AssignedMember | null>(null);

  const onSelectAssignee = (newAssignee: AssignedMember[]) => {
    setAssignee(newAssignee[0]);
  };

  const onClose = () => {
    setIsFindingAssignee(false);
  };

  const onRemoveClick = (event: React.MouseEvent<Element, MouseEvent>) => {
    event.stopPropagation();
    setAssignee(null);
  };

  return mdf ? (
    <>
      <Text variant="overline">Order form:</Text>
      <MdfEditor
        view="order_form"
        fields={mdf.fields}
        updateFieldValue={() => {}}
        metadata={{}}
        layoutSettings={mdf.views.order_form}
        defaultLayoutSettings={mdf.views.default}
        permissions={mdf.permissions}
      />
      <SelectAssigneeWrapper>
        <FieldHeader>Assignee</FieldHeader>
        {isFindingAssignee ? (
          <AddMemberDropdown
            variant="all"
            singleChoice
            autoFocus
            disableClearable={true}
            selectedMembers={assignee ? [assignee] : []}
            setSelectedMembers={onSelectAssignee}
            placeholderText="Set assignee"
            noOptionsText="No matches found"
            onClose={onClose}
            injectedMembers={undefined}
          />
        ) : (
          <MemberLabel
            variant="form"
            memberId={assignee?.mId}
            onClick={() => setIsFindingAssignee(true)}
            onRemoveClick={onRemoveClick}
          />
        )}
      </SelectAssigneeWrapper>
    </>
  ) : null;
}

function PlanningItemPreview({ selectedCommand, mdf }: Readonly<PreviewProps>) {
  return (
    <Scrollbar>
      {selectedCommand.mResourceType === MemberTypeEnum.Mdf && (
        <PreviewPlanningConfig config={selectedCommand} mdf={mdf} />
      )}
      {selectedCommand.mResourceType === MemberTypeEnum.OrderForm && <OrderFormPreview mdf={mdf} />}
    </Scrollbar>
  );
}

export default PlanningItemPreview;
