import React, { memo, useCallback, useEffect } from 'react';
import { useQuery } from '@apollo/client';

import LoadingIndicator from 'components/loadingIndicator/LoadingIndicator';
import useToast from 'components/toast/useToast';
import useObjectUrl from 'hooks/useObjectUrl';
import GET_DOWNLOAD from 'operations/queries/getDownload';
import { ExportContentInput, ExportContentType } from 'types/graphqlTypes';

interface DownloadQueryResponse {
  exportContent: ExportContentType;
}

interface DownloadProps {
  download: ExportContentInput;
  onClose?: () => void;
}

interface WindowSize {
  width: number;
  height: number;
}

const getWindowSize = (): WindowSize => ({
  width: window.innerWidth || document.body.clientWidth,
  height: window.innerHeight || document.body.clientHeight,
});

const Download: React.FC<DownloadProps> = ({ download: input, onClose = () => {} }) => {
  const { errorToast } = useToast();
  const { createOrUpdateObjectURL } = useObjectUrl();

  const { data, error, loading } = useQuery<DownloadQueryResponse, { input: ExportContentInput }>(
    GET_DOWNLOAD,
    {
      variables: { input },
      fetchPolicy: 'no-cache',
    },
  );

  const handleDownload = useCallback(
    (responseData: DownloadQueryResponse): void => {
      try {
        const { exportContent } = responseData;
        if (!exportContent.data) return;

        const buff = Buffer.from(exportContent.data);

        if (input.contentType === 'application/xml') {
          const bstring = buff.toString('utf8');
          const file = new Blob([bstring], { type: 'application/xml' });
          const url = createOrUpdateObjectURL(file);

          if (!url) {
            throw new Error('Failed to create download URL');
          }

          const element = document.createElement('a');
          element.href = url;
          element.download = `${exportContent.mTitle}.xml`;
          element.target = '_blank';
          document.body.appendChild(element);
          element.click();
          document.body.removeChild(element);
        } else if (input.contentType === 'application/pdf') {
          const file = new Blob([buff], { type: 'application/pdf' });
          const url = createOrUpdateObjectURL(file);

          if (!url) {
            throw new Error('Failed to create PDF URL');
          }

          const { width, height } = getWindowSize();
          // eslint-disable-next-line max-len
          const winOptions = `fullscreen=yes,menubar=no,titlebar=no,copyhistory=no,location=no,resizable=yes,height=${height},width=${width}`;

          window.open(url, '_blank', winOptions)?.focus();
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('Error handling file:', err);
        errorToast(
          err instanceof Error ? err : new Error('Download error'),
          'Error processing the file',
        );
      } finally {
        onClose();
      }
    },
    [createOrUpdateObjectURL, errorToast, input.contentType, onClose],
  );

  useEffect(() => {
    if (!data || loading) return;

    handleDownload(data);
  }, [data, handleDownload, loading]);

  if (loading) return <LoadingIndicator />;

  if (error) {
    // eslint-disable-next-line no-console
    console.error('Error downloading the file:', error);
    errorToast(error, 'Error downloading the file');
    onClose();
    return null;
  }

  return null;
};

export default memo(Download);
