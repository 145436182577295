import { useState } from 'react';

import { SearchParameters } from 'api/search';
import CommandMenu from 'components/command/Command';
import { SortOption } from 'features/command/command-types';
import { Metadata, SearchItemTypeEnum } from 'types/graphqlTypes';

import CreateContact from './memberList/CreateContact';

const availableSorts: SortOption[] = ['createdAt', 'updatedAt', 'title'];
const initialValue: SearchParameters = {
  searchString: '',
  skip: false,
  forceMetadataMTypes: [SearchItemTypeEnum.contact],
  mdfId: 'contact-mdf',
  perPagelimit: 50,
  toolbarState: {
    assignedIds: [],
    createdByIds: [],
    defaultMdfId: 'contact-mdf',
    isFiltering: true,
    mdfId: 'contact-mdf',
    mTypes: [SearchItemTypeEnum.contact],
    order: 'asc',
    sortBy: 'title',
    statusFilter: [],
    showRestricted: false,
    rangeBy: null,
  },
};

const STABLE_REF: Metadata = {};

function Contacts() {
  const [params, setParams] = useState<SearchParameters>(initialValue);

  return (
    <CommandMenu
      isMounted
      hideToolbar
      darkHeader
      allowStaticSearchOptions={false}
      location="contacts_sidebar"
      searchParams={params}
      setSearchParams={setParams}
      headerAppendSlot={<CreateContact startMetadata={params.metadataFilter ?? STABLE_REF} />}
      availableSorts={availableSorts}
    />
  );
}

export default Contacts;
