import React from 'react';

const getKeyString = (event: KeyboardEvent | React.KeyboardEvent) => {
  const { code, ctrlKey, shiftKey, altKey, metaKey } = event;
  const ctrl = ctrlKey ? 'ctrl+' : '';
  const shift = shiftKey ? 'shift+' : '';
  const alt = altKey ? 'alt+' : '';
  const meta = metaKey ? 'meta+' : '';
  const keyCode = code?.toLowerCase()?.replace(/key|digit/gi, '');
  const eventKey = `${ctrl}${shift}${alt}${meta}${keyCode}`;
  return eventKey;
};

const validateShortCut = (event: KeyboardEvent, shortCut: string) => {
  const keyString = getKeyString(event);
  if (keyString === shortCut) return true;
  return false;
};

/**
 * `true` if on a Mac, otherwise `false` (Windows/Linux)
 */
export const isMac = navigator.userAgent.toLowerCase().includes('mac');

/**
 * Gets the text to display for a keyboard shortcut from the shortcut as used on Mac.
 * The following replacements will be made if running on Windows or Linux
 * * `Cmd` => `Ctrl`
 * * `Option` => `Alt`
 * @param macShortcut The shortcut text on Mac
 * @returns           The shortcut text on the used operating system
 */
export function getKeyboardShortcutText(macShortcut: string) {
  return isMac ? macShortcut : macShortcut.replace('Cmd', 'Ctrl').replace('Option', 'Alt');
}

const MOD_KEYS = ['shiftKey', 'ctrlKey', 'altKey', 'metaKey'] as const;

export type KeyCombo = Readonly<Partial<Record<(typeof MOD_KEYS)[number], boolean>>> & {
  readonly key: string;
  readonly mac?: boolean;
};

function isKeyComboMatch<E>(e: React.KeyboardEvent<E>, keyCombo: KeyCombo): boolean {
  if (keyCombo.key !== e.key) return false;
  if (keyCombo.mac !== undefined && keyCombo.mac !== isMac) return false;
  for (const prop of MOD_KEYS) {
    if (keyCombo[prop] !== undefined && keyCombo[prop] !== e[prop]) return false;
  }
  return true;
}

function isSingleKeyCombo(c: KeyCombo | readonly KeyCombo[]): c is KeyCombo {
  return !Array.isArray(c);
}

export type KeyCommandMap<C extends string> = Readonly<Record<C, KeyCombo | readonly KeyCombo[]>>;

/**
 * Gets a key command from a keyboard event.
 * map so that the return value of this method
 * @param e      The keyboard event
 * @param cmdMap The command map mapping from a command to a key combo or an array of key combos.
 *               Note that it if you use `as const` when defining the the map the return value
 *               is type safely returning only the keys in the map.
 * @returns      The command or `null` if no match.
 */
export function getKeyCommand<E, C extends string>(
  e: React.KeyboardEvent<E>,
  cmdMap: KeyCommandMap<C>,
): C | null {
  for (const cmd in cmdMap) {
    const combos: KeyCombo | readonly KeyCombo[] = cmdMap[cmd];
    if (
      isSingleKeyCombo(combos)
        ? isKeyComboMatch(e, combos)
        : combos.some((c) => isKeyComboMatch(e, c))
    )
      return cmd;
  }
  return null;
}

export { validateShortCut, getKeyString };
