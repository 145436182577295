import { ReactComponent as Task } from 'assets/icons/search/task.svg';
import { ReactComponent as Instance } from 'assets/icons/systemicons/ContainerLeft_Navbar/instances_off.svg';
import { ReactComponent as Story } from 'assets/icons/systemicons/ContainerLeft_Navbar/Left_AllStories_off.svg';
import { ReactComponent as Rundown } from 'assets/icons/systemicons/ContainerLeft_Navbar/rundowns_off.svg';
import { ReactComponent as Pitch } from 'assets/icons/systemicons/pitch_green.svg';
import { ReactComponent as Space } from 'assets/icons/systemicons/spaces_off.svg';
import { ResourceType } from 'types/forms/forms';

const IconMap = {
  story: Story,
  pitch: Pitch,
  instance: Instance,
  space: Space,
  rundown: Rundown,
  order: Task,
  block: Task,
  res_story: Story,
  res_pitch: Pitch,
};

export const getMemberIcon = (resourceType: ResourceType) => {
  return IconMap[resourceType];
};
