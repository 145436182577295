import gql from 'graphql-tag';

export default gql`
  fragment currentAsset on MemberType {
    mId
    mRefId
    mAssetId
    mTitle
    mThumbnailUrl
    mContentUrl
    mType
    mMetaData {
      key
      value
    }
    mSyncProviders {
      mId
      provider
      mMetaData {
        key
        value
      }
      endpoint
    }
    mediaType
    itemType
    mosObj
  }
`;
