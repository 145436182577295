import { useCallback } from 'react';
import { ApolloCache, gql, useMutation } from '@apollo/client';
import { capitalize } from 'lodash';

import { getErrorMessage } from 'api/commonKeys';
import useToast from 'components/toast/useToast';
import { Scalars } from 'types';
import { ProviderDTO } from 'types/graphqlTypes';
import useLogger from 'utils/useLogger';

import { GET_ALL_PROVIDERS, GetProviders } from './useGetAllProviders';

const CREATE_PROVIDER = gql`
  mutation CreateProvider($input: CreatePlatformProviderInput) {
    createPlatformProvider(input: $input) {
      id
      title
      baseUrl
      endPoints {
        id
        url
      }
      auth {
        type
        headers {
          tenant
          apikeyId
        }
      }
    }
  }
`;

export const writeProviderToCache = (
  client: ApolloCache<unknown>,
  updatedProvider: ProviderDTO,
) => {
  const cachedProviders = client.readQuery<GetProviders>({
    query: GET_ALL_PROVIDERS,
  });
  const providers = cachedProviders?.getAllProviders ?? [];
  let newProviders: ProviderDTO[] = [];
  const existingProvider = providers.findIndex((a) => a.id === updatedProvider.id);
  if (existingProvider >= 0) {
    newProviders = [...providers];
    newProviders.splice(existingProvider, 1, updatedProvider);
  } else {
    newProviders = [updatedProvider, ...providers];
  }
  client.writeQuery<GetProviders>({
    query: GET_ALL_PROVIDERS,
    data: {
      getAllProviders: newProviders,
    },
  });
};

export type CreatePlatformProviderInput = {
  id: Scalars['String'];
};

interface CreateProvider {
  createPlatformProvider: ProviderDTO;
}

interface CreateInput {
  input: CreatePlatformProviderInput;
}

export const useCreateProvider = () => {
  const { toast, errorToast } = useToast();
  const [create] = useMutation<CreateProvider, CreateInput>(CREATE_PROVIDER);
  const logger = useLogger('CreatePlatformProvider');

  const createProvider = useCallback(
    async (refferenceId: string) => {
      try {
        const result = await create({
          variables: {
            input: {
              id: refferenceId,
            },
          },
          update: (proxy, mutationResult) => {
            toast({
              title: 'Created ' + refferenceId,
              description: `${capitalize(refferenceId)} created successfully`,
              type: 'success',
            });
            const newProvider = mutationResult.data?.createPlatformProvider;
            if (newProvider) {
              writeProviderToCache(proxy, newProvider);
            }
          },
          onError: (error: unknown) => {
            errorToast(error);
          },
        });
        return result.data?.createPlatformProvider;
      } catch (err) {
        logger.log(getErrorMessage(err, refferenceId));
      }
    },
    [create, errorToast, logger, toast],
  );
  return { createProvider };
};
