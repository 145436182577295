import { gql, useMutation } from '@apollo/client';

import { MemberType, UpdateMemberInput } from 'types/graphqlTypes';

import { BLOCK_PROPS } from './utils';

const UPDATE_BLOCK = gql`
  mutation UpdateBlockTitle($input: UpdateMemberInput) {
    updateMember(input: $input) {
      ${BLOCK_PROPS}
    }
  }
`;

interface UpdateBlockTitle {
  updateMember: MemberType;
}

interface UpdateBlockInput {
  input: UpdateMemberInput;
}

export const useUpdateBlock = () => {
  const [update] = useMutation<UpdateBlockTitle, UpdateBlockInput>(UPDATE_BLOCK);

  const updateBlock = async (
    mId: string,
    mRefId: string,
    prop: keyof Pick<MemberType, 'mTitle' | 'mPublishingAt'>,
    value: string | null,
  ) => {
    const input: UpdateMemberInput = {
      mId,
      mRefId,
      [prop]: value,
    };

    return update({
      variables: {
        input,
      },
    });
  };

  return updateBlock;
};
