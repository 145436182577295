import { useSelectedGroupCategoryAtom } from 'screens/main/components/header/navbar/settings/atoms';
import {
  useSelectedLeftMenu,
  useSelectedOrganization,
} from 'screens/main/components/header/navbar/settings/atomsTs';
import List from 'screens/main/components/header/navbar/settings/components/collapseList';
import GROUP_SETTING_TYPES from 'screens/main/components/header/navbar/settings/utils/groupSettingTypes';
import { MemberType } from 'types/graphqlTypes';

const tabItems = [
  {
    mId: 'active-users',
    mRefId: 'active-users',
    mTitle: 'Active Users',
  },
  {
    mId: 'disabled-users',
    mRefId: 'disabled-users',
    mTitle: 'Disabled Users',
  },
];

const MenuItem = () => {
  const [selectedOrganizationMenu, setSelectedOrganizationMenu] = useSelectedOrganization();
  const [category, setCategory] = useSelectedGroupCategoryAtom();
  const [selectedLeftMenu, setSelectedLeftMenu] = useSelectedLeftMenu();

  const onClickGroups = (userType: MemberType) => {
    setSelectedOrganizationMenu(GROUP_SETTING_TYPES.USERS);
    setCategory(GROUP_SETTING_TYPES.USERS);
    if (userType.mRefId) setSelectedLeftMenu(userType.mRefId);
  };
  return (
    <List>
      <List.Header
        itemCount={2}
        selected={
          category === GROUP_SETTING_TYPES.USERS &&
          selectedOrganizationMenu === GROUP_SETTING_TYPES.USERS
        }
        hideCreateButton
        createButtonTooltip={undefined}
        createButtonDisabled={undefined}
        onCreateClick={undefined}
        onClick={() => onClickGroups(tabItems[0])}
      >
        Users
      </List.Header>
      <List.Body>
        {tabItems?.map((item) => (
          <List.Item
            key={item.mRefId}
            id={item.mRefId}
            selected={
              selectedLeftMenu === item?.mRefId &&
              selectedOrganizationMenu === GROUP_SETTING_TYPES.USERS
            }
            onClick={() => onClickGroups(item)}
            itemCount={undefined}
            italic={undefined}
            className={undefined}
          >
            {item.mTitle}
          </List.Item>
        ))}
      </List.Body>
    </List>
  );
};

export default MenuItem;
