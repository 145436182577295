import { useCallback } from 'react';
import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { createScope, molecule, useMolecule } from 'jotai-molecules';
import { focusAtom } from 'jotai-optics';

import { SearchDateRange } from 'api/search';
import { CommandToolbarProps } from 'features/command/command-types';
import { toolbarFilterDefaults } from 'features/command/command-utils';
import useDateTimeUtils from 'hooks/useDateTimeUtils';
import { MemberType, Metadata } from 'types/graphqlTypes';
import { defaultTabs, Tab, TimeVariant, timeVariants } from 'utils/planningViews';

export type SearchResult = {
  items: MemberType[];
  total: number;
  loading: boolean;
};

const defaultTab = defaultTabs.find((tab) => tab.index === 1) ?? defaultTabs[0];

export const StoryHubScope = createScope(undefined);

const defaults: CommandToolbarProps = {
  ...toolbarFilterDefaults,
};

const storyHubMolecule = molecule((_getMol, getScope) => {
  getScope(StoryHubScope);
  const {
    startOfDay,
    startOfMonth,
    addDays,
    endOfDay,
    getISODay,
    lastDayOfISOWeek,
    startOfISOWeek,
    subDays,
  } = useDateTimeUtils();

  const calculateDateRange = useCallback(
    (dateIso: string, timeVariant: TimeVariant): SearchDateRange => {
      const date = new Date(dateIso);
      switch (timeVariant) {
        case timeVariants.MONTH: {
          const firstDayInMonth = startOfDay(startOfMonth(date));
          const startDate = subDays(firstDayInMonth, getISODay(firstDayInMonth) - 1).toISOString();
          return {
            from: startDate,
            to: endOfDay(addDays(startDate, 41)).toISOString(),
          };
        }
        case timeVariants.WEEK:
          return {
            from: startOfDay(startOfISOWeek(date)).toISOString(),
            to: endOfDay(lastDayOfISOWeek(date)).toISOString(),
          };
        case timeVariants.DAY:
        default:
          return {
            from: startOfDay(date).toISOString(),
            to: endOfDay(date).toISOString(),
          };
      }
    },
    [],
  );

  /** base atom */
  interface StoryHubStates {
    tb: CommandToolbarProps;
    tab: Tab;
    scheduled: boolean;
    combinedScheduled: boolean;
    date: string;
    metadata: Metadata;
    sS: string;
    showMDF: boolean;
    statesView: string;
    statesCollapsed: Record<string, boolean>;
    selectedBookmarkId?: string;
  }

  const storyHubStatesAtom = atomWithStorage<StoryHubStates>('storyHub', {
    tb: defaults,
    tab: defaultTab,
    scheduled: true,
    combinedScheduled: false,
    date: new Date().toISOString(),
    metadata: {},
    sS: '',
    showMDF: true,
    statesView: 'status-normal',
    statesCollapsed: {},
  } satisfies StoryHubStates);

  const toolbarState = focusAtom(storyHubStatesAtom, (optic) => optic.prop('tb'));

  /** Metadata */
  const metadataAtom = focusAtom(storyHubStatesAtom, (optic) => optic.prop('metadata'));

  /** SearchString */
  const searchStringAtom = focusAtom(storyHubStatesAtom, (optic) => optic.prop('sS'));

  /** Tabs */
  const tabAtom = focusAtom(storyHubStatesAtom, (optic) => optic.prop('tab'));

  /** Scheduled */
  const scheduledAtom = focusAtom(storyHubStatesAtom, (optic) => optic.prop('scheduled'));
  const combinedScheduledAtom = focusAtom(storyHubStatesAtom, (optic) =>
    optic.prop('combinedScheduled'),
  );

  /** Date */
  const dateAtom = focusAtom(storyHubStatesAtom, (optic) => optic.prop('date'));

  const dateRangeAtom = atom((get) => {
    const timeVariant = get(tabAtom).timeVariant;
    const date = get(dateAtom);
    return calculateDateRange(date, timeVariant);
  });

  /** View */

  const showMDFAtom = focusAtom(storyHubStatesAtom, (optic) => optic.prop('showMDF'));

  const statesViewAtom = focusAtom(storyHubStatesAtom, (optic) => optic.prop('statesView'));

  const statesCollapsedAtom = focusAtom(storyHubStatesAtom, (optic) =>
    optic.prop('statesCollapsed'),
  );

  const activeItemAtom = atom<MemberType | null>(null);

  /** Polling */
  const pollingAtom = atom(true);

  /** Aggregation */
  const bucketSizeAtom = atom<number>(1);

  /** Search Result */

  const searchResultsAtom = atom<SearchResult>({
    items: [],
    total: 0,
    loading: false,
  });

  const itemsAtom = focusAtom(searchResultsAtom, (optic) => optic.prop('items'));
  const loadingAtom = focusAtom(searchResultsAtom, (optic) => optic.prop('loading'));
  const totalAtom = focusAtom(searchResultsAtom, (optic) => optic.prop('total'));

  /** Bookmark */
  const selectedBookmarkIdAtom = focusAtom(storyHubStatesAtom, (optic) =>
    optic.prop('selectedBookmarkId'),
  );

  return {
    useStoryHubToolbarState: () => useAtom(toolbarState),
    useStoryHubMetadata: () => useAtom(metadataAtom),
    useStoryHubSearchString: () => useAtom(searchStringAtom),
    useStoryHubTab: () => useAtom(tabAtom),
    useStoryHubDate: () => useAtom(dateAtom),
    useStoryHubDateValue: () => useAtomValue(dateAtom),
    useStoryHubDateRange: () => useAtomValue(dateRangeAtom),
    useShowMDF: () => useAtom(showMDFAtom),
    useSearchResults: () => useAtomValue(searchResultsAtom),
    useSetResults: () => useSetAtom(searchResultsAtom),
    useStoryHubLoading: () => useAtom(loadingAtom),
    useTotal: () => useAtom(totalAtom),
    useSearchItems: () => useAtom(itemsAtom),
    useTotalValue: () => useAtomValue(totalAtom),
    useSearchItemsValue: () => useAtomValue(itemsAtom),
    useStatesView: () => useAtom(statesViewAtom),
    useStatesCollapsed: () => useAtom(statesCollapsedAtom),
    useActiveItem: () => useAtom(activeItemAtom),
    useScheduled: () => useAtom(scheduledAtom),
    useCombinedScheduled: () => useAtom(combinedScheduledAtom),
    useStoryHubPolling: () => useAtom(pollingAtom),
    useBucketSize: () => useAtom(bucketSizeAtom),
    useSelectedBookmarkId: () => useAtom(selectedBookmarkIdAtom),
  };
});

export const useStoryHubMolecule = () => useMolecule(storyHubMolecule);

export const aggregationFieldName = 'mPublishingAt';

export const BUCKET_DATE_FORMAT = "yyyy-MM-dd'T'HH:mm:ss.SSSXXX";
