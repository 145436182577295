import mergeUserRights from 'utils/mergeUserRights';
import groupPoliciesConfig from 'config/permissions.json';

const calculateUserRights = (groups, members) => {
  const { policies } = groupPoliciesConfig;

  const initialUserRights = policies.map((policy) => {
    const { permissions } = policy;
    const permissionsNew = permissions.map((permission) => ({
      ...permission,
      access: permission.defaultAccess || 'allow',
    }));
    return { ...policy, permissions: permissionsNew };
  });

  const groupsPolicies = groups
    .map((group) => {
      const matchedPolicy = members?.find((policy) => policy.mRefId === group);
      if (matchedPolicy) return matchedPolicy.mProperties.policies;
    })
    .filter(Boolean)
    .flat();

  return mergeUserRights(initialUserRights, groupsPolicies, groups.length);
};

export default calculateUserRights;
