import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useQuery } from '@apollo/client';
import styled from '@emotion/styled';

import { IconButton } from 'components/buttons/IconButton';
import { ConfirmDialog } from 'components/dialogs/CommonDialogs';
import Popover from 'components/dialogs/PopoverBuilder';
import UserContext from 'contexts/UserContext';
import ScrollArea from 'lib/scrollArea';
import GET_COMMENTS from 'operations/queries/getComments';
import { rightSelectionFromMType, useRightHidden, useRightSelection } from 'store/sidebar';
import { EditorValue } from 'types/editor';
import { User } from 'types/members';
import { Conversation } from 'types/messageHub';
import useLogger from 'utils/useLogger';

import useCreateComments from './hooks/useCreateComments';
import CommentDetails from './CommentDetails';
import { CommentEmpty, CommentResolvedStyled, CommentWithComments } from './CommentIcons';
import CommentInput from './CommentInput';
import ThreadDetails from './ThreadDetails';

const DetailsWrapper = styled('div')`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column-reverse;
  gap: 8px;
  overflow-y: auto;
`;

interface CommentProps {
  blockId: string;
  resourceId?: string;
  resourceType?: string;
  rundownId?: string;
  spaceId?: string;
  formId?: string;
  ownerId?: string;
  assigneeId?: string;
  showComment?: boolean;
  updatePane?: () => void;
  buttonProps?: React.ComponentProps<typeof IconButton>;
}

function Comments({
  blockId,
  resourceId,
  resourceType,
  rundownId,
  spaceId,
  formId,
  ownerId,
  assigneeId,
  showComment,
  updatePane,
  buttonProps,
}: Readonly<CommentProps>) {
  const logger = useLogger('Comments');
  const { mId: userId } = useContext(UserContext);
  const [createComment] = useCreateComments();
  const [, setRightHidden] = useRightHidden();
  const [, setRightSelection] = useRightSelection();

  const [open, setOpen] = useState(showComment);
  const [tempComment, setTempComment] = useState<EditorValue | null>(null);
  const [loading, setLoading] = useState(false);

  const scrollViewportRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (showComment) {
      setOpen(showComment);
    }
  }, [showComment]);

  const { data, refetch } = useQuery<{ getMessagesOfConversation: { items: Conversation[] } }>(
    GET_COMMENTS,
    {
      variables: {
        input: {
          mId: blockId,
        },
      },
      skip: !blockId,
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-and-network',
    },
  );

  useEffect(() => {
    const triggerRefetch = () => void refetch();
    window.addEventListener('online', triggerRefetch);
    return () => {
      window.removeEventListener('online', triggerRefetch);
    };
  }, [refetch]);

  const [comments, parentComment] = useMemo(
    () => [
      (data?.getMessagesOfConversation?.items.filter((item) => !item?.mState) ?? []).sort(
        (a, b) => new Date(b.mCreatedAt ?? '').getTime() - new Date(a.mCreatedAt ?? '').getTime(),
      ),
      data?.getMessagesOfConversation?.items.find((item) => item?.mState) ?? undefined,
    ],
    [data?.getMessagesOfConversation?.items],
  );

  const updateCommentState = useCallback(async () => {
    if (parentComment) {
      await createComment({
        ...parentComment,
        mState: 'created',
        crudAction: 'UPDATE',
      });
    }
  }, [createComment, parentComment]);

  const addComment = useCallback(
    async (newComment?: EditorValue) => {
      await createComment({
        mId: blockId,
        mContent: JSON.stringify(newComment),
        mResourceId: resourceId,
        mResourceType: resourceType,
        mStoryId: rundownId,
        mSecId: spaceId,
        mSecRefId: formId,
        mTertId: ownerId,
        mTertRefId: assigneeId,
        mState: parentComment ? undefined : 'created',
      });

      // Scroll to bottom when a new comment is added
      scrollViewportRef.current?.scrollTo({
        top: scrollViewportRef.current?.scrollHeight,
        behavior: 'smooth',
      });
    },
    [
      assigneeId,
      blockId,
      createComment,
      formId,
      ownerId,
      parentComment,
      resourceId,
      resourceType,
      rundownId,
      spaceId,
    ],
  );

  const handleCommentAction = useCallback(
    async (newComment?: EditorValue) => {
      if (newComment) {
        if (parentComment?.mState === 'done') {
          setTempComment(newComment);
        } else {
          await addComment(newComment);
        }
      }
    },
    [parentComment, addComment],
  );

  const handleConfirmDialog = useCallback(async () => {
    setLoading(true);
    await updateCommentState();
    if (tempComment) await addComment(tempComment);
    setLoading(false);
    setTempComment(null);
  }, [updateCommentState, addComment, tempComment]);

  const openUserDetails = useCallback(
    (user?: User) => {
      if (user) {
        setRightHidden(false);
        setRightSelection(rightSelectionFromMType(user.mType));
      }
    },
    [setRightHidden, setRightSelection],
  );

  const onOpenChange = useCallback(() => {
    setOpen(!open);
    if (updatePane) updatePane();
  }, [open, updatePane]);

  useEffect(() => {
    if (open) {
      refetch().catch((e) => logger.log(e));
    }
  }, [open]);

  return (
    <Popover open={open} onOpenChange={onOpenChange}>
      <Popover.Trigger asChild onClick={(e) => e.stopPropagation()}>
        <IconButton title="Comments" {...buttonProps} padding={0}>
          {parentComment ? (
            parentComment?.mState === 'created' ? (
              <CommentWithComments count={comments.length} />
            ) : (
              <CommentResolvedStyled />
            )
          ) : (
            <CommentEmpty />
          )}
        </IconButton>
      </Popover.Trigger>
      <Popover.Content
        onClick={(e) => e.stopPropagation()}
        onContextMenu={(e) => e.stopPropagation()}
        style={{ padding: '0', maxWidth: '400px' }}
      >
        <ScrollArea viewportRef={scrollViewportRef}>
          <div
            style={{
              width: '400px',
              maxHeight: '50vh',
            }}
          >
            {parentComment && (
              <ThreadDetails
                comment={parentComment}
                currentUserId={userId}
                showDivider={comments.length > 0}
                openUserDetails={openUserDetails}
                setOpen={setOpen}
              />
            )}
            {comments.length > 0 && (
              <DetailsWrapper>
                {comments.map((comment) => (
                  <CommentDetails
                    key={comment.mRefId}
                    comment={comment}
                    currentUserId={userId}
                    openUserDetails={openUserDetails}
                  />
                ))}
              </DetailsWrapper>
            )}
          </div>
        </ScrollArea>

        <CommentInput onSave={handleCommentAction} />
        <ConfirmDialog
          open={!!tempComment}
          onClose={() => setTempComment(null)}
          onClick={handleConfirmDialog}
          title="This thread is marked as resolved"
          message={`
              To add a comment, you need to reopen the thread.
              Would you like to proceed?`}
          confirmLabel="Reopen thread"
          loading={loading}
          overlayStyle={{ zIndex: 1400 }}
        />
      </Popover.Content>
    </Popover>
  );
}

export default Comments;
