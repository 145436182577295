import styled from '@emotion/styled';

import transientOptions from 'theme/helpers';

export const DateLabel = styled('div', transientOptions)<{ $hasValue?: boolean }>`
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 4px;
  color: ${({ theme, $hasValue }) =>
    $hasValue ? theme.palette.dina.highEmphasis : theme.palette.dina.lowEmphasis};
  &:hover {
    color: ${({ theme }) => theme.palette.dina.highEmphasis} !important;
  }
`;

export const DateWrapper = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const CalendarContainer = styled('div')`
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
    0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  & * {
    pointer-events: auto;
  }
`;
