/**
 * Ensure that an object and all its contained objects are frozen.
 
 * **Note!** Does not work if there are circular references in the object tree!
 * @param obj The object to be deeply frozen
 */
export function deepFreeze<T extends Exclude<object, null>>(obj: T): T {
  Object.freeze(obj);
  Object.values(obj).forEach((value) => {
    if (value && typeof value === 'object') deepFreeze(value);
  });
  return obj;
}

/**
 * Clones a value making sure that the returned value contains no common object (or array) with the
 * original value.
 * @param value The value to be cloned
 * @returns     The clone
 */
export function clone<T>(value: T): T {
  if (Array.isArray(value)) {
    return [...value.map((item: unknown) => clone(item))] as T;
  } else if (value && typeof value === 'object') {
    const result: Record<string, unknown> = {};
    Object.entries(value).forEach(([propName, propValue]) => {
      result[propName] = clone(propValue);
    });
    return result as T;
  } else {
    return value;
  }
}

export function deepEquals(a: unknown, b: unknown): boolean {
  if (a === b) return true;
  if (typeof a !== 'object' || typeof b !== 'object' || !a || !b) return false;
  if (Object.keys(a).length !== Object.keys(b).length) return false;
  for (const prop in a) {
    if (!Object.hasOwn(b, prop)) return false;
    const aProp = (a as Record<string, unknown>)[prop];
    const bProp = (b as Record<string, unknown>)[prop];
    if (!deepEquals(aProp, bProp)) return false;
  }
  return true;
}
