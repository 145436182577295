import { memo, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as AtomOff } from 'assets/icons/systemicons/editor/atom_off.svg';
import { menuOptions } from 'components/editor/constants';
import { elementTypes } from 'components/editor/constants/types';
import MetadataEditor from 'components/metadataEditor';
import ProgressBar from 'components/progressBar';

import AddMedia from '../addMedia';
import AddThumbnails from '../addThumbnails';
import Box from '../box';
import Metadata from '../metadata';
import UploadProgress from '../uploadProgress';

import UploadCaptions from './components/uploadCaptions';

import { AudioIcon, Label, ThumbnailWrapper, VideoIcon } from './styled';

const MIN_THUMB_COUNT = 1;

const boxIconComponent = {
  [elementTypes.AUDIO]: <AudioIcon className="skipOverride" />,
  [elementTypes.VIDEO]: <VideoIcon className="skipOverride" />,
};

const AudioVideoBase = ({
  readOnly,
  uploadFile,
  removeMedia,
  mediaSrc,
  thumbnails,
  addThumbnails,
  removeThumbnail,
  showThumbnail,
  onMenuSelect,
  hideEllipsisButton,
  mediaThumbnail,
  showMetadata,
  sourceProps,
  textareaProps,
  titleProps,
  blockForm,
  inputProps,
  element,
  isCmsVariant,
  updateMetadata,
  uploadSubtitle,
  updateSubtitles,
  subtitles,
  mediaDuration,
  isCoverphoto,
  collapsed,
  collapsedContent,
  updateCollapsed,
  isSubtitleAllowed,
  metadata,
  mId,
  mRefId,
  platformId,
  createPlaceholder,
  fileRef,
  cacheRef,
  updateEditorOnUpload,
  uploadFinishedCallback,
  withSignedUrl,
  isUploading,
  uploadProgress,
  showUploadProgress,
  direction,
  assetTitle,
  elementType,
  isVideoElement,
  openInMimir,
}) => {
  const [boxType, setBoxType] = useState('media');
  const { data } = element;
  useEffect(() => {
    if (isCoverphoto) setBoxType('iscoverphoto');
    else setBoxType('media');
  }, [isCoverphoto]);

  const menuItems = useMemo(() => {
    const extendedOptions = [
      {
        title: `${showMetadata ? 'Hide' : 'Show'} Metadata`,
        action: 'show-metadata',
        icon: <AtomOff />,
        divider: true,
      },
    ];
    return [...extendedOptions, ...menuOptions];
  }, [showMetadata]);

  const thumbnailCount = MIN_THUMB_COUNT;
  const thumbnailLabel = 'Add video thumbnail';

  const renderContent = useMemo(
    () => (
      <Box
        type={boxType}
        boxType={elementType}
        platformId={platformId}
        title={elementType}
        subtitle={assetTitle}
        menuItems={menuItems}
        hideEllipsisButton={hideEllipsisButton}
        iconComponent={boxIconComponent[elementType] || null}
        onMenuSelect={onMenuSelect}
        readOnly={readOnly}
        element={element}
        collapsed={collapsed}
        collapsedContent={collapsedContent}
        updateCollapsed={updateCollapsed}
      >
        <AddMedia
          element={element}
          addMedia={uploadFile}
          removeMedia={removeMedia}
          mediaSrc={mediaSrc}
          mediaType={elementType}
          mediaWidth={156}
          mediaDuration={mediaDuration}
          mediaThumbnail={mediaThumbnail || mediaSrc}
          hasPlaceholder={false}
          mId={mId}
          mRefId={mRefId}
          createPlaceholder={createPlaceholder}
          withSignedUrl={withSignedUrl}
          openInMimir={openInMimir}
        />
        {isVideoElement && showThumbnail && (
          <ThumbnailWrapper>
            <Label>{thumbnailLabel}</Label>
            <AddThumbnails
              maxThumbnails={thumbnailCount}
              thumbnails={thumbnails}
              addThumbnails={addThumbnails}
              removeThumbnail={removeThumbnail}
            />
          </ThumbnailWrapper>
        )}
        {showMetadata && (
          <Metadata
            sourceProps={sourceProps}
            textareaProps={textareaProps}
            isCmsVariant={isCmsVariant}
            titleProps={titleProps}
            inputProps={inputProps}
            updateMetadata={updateMetadata}
            isCoverphoto={isCoverphoto}
            direction={direction}
          />
        )}
        {isVideoElement && isSubtitleAllowed && (
          <UploadCaptions
            uploadSubtitle={uploadSubtitle}
            subtitles={subtitles}
            updateSubtitles={updateSubtitles}
          />
        )}
        {blockForm && showMetadata && (
          <MetadataEditor
            model={blockForm}
            setPayload={(u) => updateMetadata(u.value, u.fieldId)}
            payload={metadata}
          />
        )}

        {withSignedUrl && fileRef.current && showUploadProgress && (
          <UploadProgress
            fileRef={fileRef}
            data={data}
            updateEditorOnUpload={updateEditorOnUpload}
            uploadFinishedCallback={uploadFinishedCallback}
          />
        )}
        {!withSignedUrl && isUploading && <ProgressBar percentage={uploadProgress} />}
      </Box>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      addThumbnails,
      assetTitle,
      boxType,
      blockForm,
      collapsed,
      collapsedContent,
      element,
      hideEllipsisButton,
      inputProps,
      isCmsVariant,
      isCoverphoto,
      isSubtitleAllowed,
      mediaThumbnail,
      onMenuSelect,
      mId,
      mRefId,
      createPlaceholder,
      readOnly,
      removeThumbnail,
      removeMedia,
      showMetadata,
      showThumbnail,
      sourceProps,
      subtitles,
      textareaProps,
      thumbnails,
      titleProps,
      updateCollapsed,
      updateMetadata,
      updateSubtitles,
      uploadSubtitle,
      uploadFile,
      mediaSrc,
      fileRef,
      cacheRef,
      withSignedUrl,
      isUploading,
      uploadProgress,
      showUploadProgress,
      updateEditorOnUpload,
    ],
  );

  return renderContent;
};

AudioVideoBase.propTypes = {
  /** Boolean that indicates a read only component */
  readOnly: PropTypes.bool,
  /** Callback to upload a video */
  uploadFile: PropTypes.func,
  /** Callback to remove a video */
  removeMedia: PropTypes.func,
  /** Source of the video */
  mediaSrc: PropTypes.string,
  /** A list of thumbnails */
  thumbnails: PropTypes.arrayOf(
    PropTypes.shape({
      /** Id of the image as asset */
      mId: PropTypes.string,
      /** unique Id of the image as asset */
      mRefId: PropTypes.string,
      /** mContentKey of the thumbnail image, is used to get the link of the image */
      src: PropTypes.string,
    }),
  ),
  /** Callback to add thumbnail */
  addThumbnails: PropTypes.func,
  /** Callback to remove thumbnail */
  removeThumbnail: PropTypes.func,
  /** Boolean to show thumbnail */
  showThumbnail: PropTypes.bool,
  /** Callback to be invoked when a menu is selected */
  onMenuSelect: PropTypes.func,
  /** Maximum number of allowed thumbnails */
  maxThumbnails: PropTypes.number,
  /** whether the video will be a lead image or not */
  isCoverphoto: PropTypes.bool,
  /** function to open in mimir panel */
  openInMimir: PropTypes.func,
};

AudioVideoBase.defaultProps = {
  readOnly: false,
  uploadFile: () => {},
  removeMedia: () => {},
  mediaSrc: '',
  thumbnails: [],
  addThumbnails: () => {},
  removeThumbnail: () => {},
  showThumbnail: false,
  onMenuSelect: () => {},
  maxThumbnails: 4,
  isCoverphoto: false,
};

export default memo(AudioVideoBase);
