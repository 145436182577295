import styled from '@emotion/styled';

import { ReactComponent as Close } from 'assets/icons/systemicons/close.svg';
import { ReactComponent as Info } from 'assets/icons/systemicons/info_off.svg';
import { ReactComponent as DragHandle } from 'assets/icons/systemicons/unionDragHandle.svg';
import { StyledTextField } from 'components/mdfEditor/fields/text/styled';
import transientOptions from 'theme/helpers';

export const SideBarWrapper = styled('div')<{ height: number }>`
  height: ${({ height }) => `${height}px`};
  overflow: auto;
`;

export const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const FormsWrapper = styled('div')`
  width: 100%;
  height: 100%;
`;

export const FakeInput = styled('div')`
  border: 1px solid rgba(172, 184, 198, 0.25);
  background: rgba(15, 20, 26, 0.13);
  min-height: 24px;
  border-radius: 4px;
  padding: 4px 6px;
  width: 240px;
`;

export const TabsWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const StyledClose = styled(Close)`
  opacity: 0;
  margin-top: -4px;
  &:hover {
    opacity: 1;
  }
`;

export const ContentWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const MdfEditorWrapper = styled('div')`
  padding-inline: 12px;
`;

export const RightHeader = styled('div')`
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  padding-inline: 12px;
`;

export const PayloadWrapper = styled.div`
  width: 100%;
  padding: 8px 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const PayloadTitle = styled('div')`
  height: 18px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const PayloadInfo = styled(Info)`
  width: 18px;
  height: 18px;
  path {
    fill: ${({ theme }) => theme.palette.dina.errorText};
    fill-opacity: 1;
  }
`;

export const Payload = styled('pre')`
  height: 200px;
  width: 100%;
  margin: 0px;
  padding: 8px;
  border-radius: 6px;
  background: ${({ theme }) => theme.palette.dina.inputBackground};
  overflow-y: scroll;
  white-space: pre-wrap;
  tab-size: 2;
  font-size: 13px;
`;

export const OptionWrapper = styled.div`
  display: flex;
  flex: 1;
  gap: 6px;
  align-items: center;
  padding-bottom: 1px;
  height: 36px;
  width: 80%;
`;

export const StyledDragHandle = styled(DragHandle)`
  cursor: grab;
  :active {
    cursor: grabbing;
    path {
      fill-opacity: 1;
    }
  }
  :hover {
    path {
      fill-opacity: 1;
    }
  }
  position: relative;
  top: 8px;
`;

export const MultiChoiceWrapper = styled('div')`
  height: 60vh;
  width: 100%;
`;

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ButtonContainer = styled('div')`
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  gap: 8px;
`;

export const TreeViewWrapper = styled('div')`
  min-height: 40vh;
  max-height: 70vh;
  width: 100%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const StyledTreeView = styled('ul')`
  margin: 0;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  padding-left: 0px;
  gap: 4px;
`;

export const StyledTreeNode = styled('li')`
  display: flex;
  flex-direction: column;
  gap: 4px;
  ul {
    padding-left: 16px;
  }
`;

export const NodeBody = styled('div')`
  display: flex;
`;

export const InputField = styled(StyledTextField)`
  margin-right: 4px;
  :first-child {
    margin-left: 24px;
  }
`;

export const Remove = styled(Close, transientOptions)<{ $disabled?: boolean }>`
  cursor: ${({ $disabled }) => ($disabled ? 'default' : 'pointer')};
  opacity: ${({ $disabled }) => ($disabled ? '0.3' : '0.7')};
  &:hover {
    ${({ $disabled }) => ($disabled ? '0.3' : '1')};
  }
`;
