const convertDurationToMillisecond = (input: string | undefined | null): number | null => {
  if (typeof input !== 'string') return null;
  const splitted = input.split(/:|;|\./);
  switch (splitted.length) {
    case 1:
      return Number(splitted[0]) * 1000;
    case 2:
      return (Number(splitted[0]) * 60 + Number(splitted[1])) * 1000;
    case 3:
      return (Number(splitted[0]) * 3600 + Number(splitted[1]) * 60 + Number(splitted[2])) * 1000;
    default:
      return null;
  }
};

export default convertDurationToMillisecond;
