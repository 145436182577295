import { useCallback, useState } from 'react';
import axios from 'axios';
import useLogger from 'utils/useLogger';
import { findFileMimeType } from 'utils/assetData';

const useUploadBySignedUrl = () => {
  const logger = useLogger('use upload by Signed url');
  const [progress, setProgress] = useState(0);
  /**
   * Uploads a file to a given URL using a signed URL.
   *
   * @function
   * @param {string} uploadUrl - The signed URL to which the file will be uploaded.
   * @param {File} file - The file to be uploaded.
   * @param {function} [uploadFinishedCallback] - Optional callback function to be called when the upload is finished.
   * @param {function} [uploadProgressCallback] - Optional callback function to be called with the upload progress.
   * @returns {Promise<void>} A promise that resolves when the upload is complete.
   */
  const UploadBySignedUrl = useCallback(
    async (uploadUrl = '', file, uploadFinishedCallback, uploadProgressCallback) => {
      try {
        axios.put(uploadUrl, file, {
          headers: { 'content-type': findFileMimeType(file) },
          onUploadProgress: (ProgressEvent) => {
            const currentProgress = (ProgressEvent.loaded / ProgressEvent.total) * 100;
            if (Math.trunc(currentProgress) % 10 === 0) {
              setProgress(currentProgress);
              uploadProgressCallback?.(currentProgress);
            }

            if (ProgressEvent.loaded === ProgressEvent.total) {
              setTimeout(() => {
                uploadFinishedCallback?.();
              }, 2000);
            }
          },
        });
      } catch (err) {
        logger.log(`useUploadBySignedUrl -> From put file via axios :::${err}`);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return [UploadBySignedUrl, progress, setProgress];
};

export default useUploadBySignedUrl;
