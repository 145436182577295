import { useCallback } from 'react';
import { useQuery } from '@apollo/client';

import calculateUserRights from 'operations/global-functions/utils/calculateUserRights';
import GET_POLICY from 'operations/queries/getPolicy';
import { usePolicies } from 'store';
import type { MemberType } from 'types/graphqlTypes';

interface FetchPolicies {
  getMemberFromId: MemberType[];
}

function useCalculatePolicies() {
  const [, setPolicies] = usePolicies();

  const { data } = useQuery<FetchPolicies>(GET_POLICY, {
    variables: {
      input: { mId: 'groupPolicy' },
    },
    fetchPolicy: 'cache-only',
  });

  const calculatePolicies = useCallback(
    (groups: string[]) => {
      const userRights = calculateUserRights(groups, data?.getMemberFromId);
      setPolicies(userRights);
    },
    [data?.getMemberFromId, setPolicies],
  );

  return calculatePolicies;
}

export default useCalculatePolicies;
