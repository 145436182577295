import { atom, useAtom } from 'jotai';

import { USR_DATA_KEYS } from 'operations/queries/getUserData';
import { feedDecksAtom } from 'store/decks';
import type { MetadataFormType, MMetaDataField, ResourcePolicy } from 'types/graphqlTypes';

/** User rights policies  */
export const policiesAtom = atom<ResourcePolicy[]>([]);

export const usePolicies = () => useAtom(policiesAtom);

/** User seen notifications */
export const seenHelpAtom = atom({
  mId: undefined,
  mSeen: undefined,
  ...USR_DATA_KEYS,
});

export const useSeenHelp = () => useAtom(seenHelpAtom);

/** User configs, can be extended to fit other user configs
 * Maybe just use key to fetch the JSON.parsed value?
 */
export interface UserConfig {
  mId?: string;
  mRefId: string;
  mType: string;
  mMetaData?: MMetaDataField[];
  metadataForms?: MetadataFormType[];
  settings?: Record<string, string>[]; // TODO: generate type for settings
  groups?: string[];
}

export const userConfig = atom<UserConfig>({
  mId: undefined,
  ...USR_DATA_KEYS,
  mMetaData: [],
  groups: [],
});

export const userConfigAtom = atom(
  (get) => get(userConfig),
  (get, set, val: UserConfig) => {
    if (!val) return;
    set(userConfig, val);

    const { mMetaData } = val;

    if (mMetaData) {
      const decks = mMetaData.find((item) => item.key === 'feedDecks');
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      set(feedDecksAtom, decks?.value ? JSON.parse(decks.value) : []);
    }
  },
);

export const useUserConfig = () => useAtom(userConfigAtom);
