import { useCallback } from 'react';
import { useMutation } from '@apollo/client';

import useToast from 'components/toast/useToast';
import { useCanAdministrateSearches } from 'hooks/useCheckUserRight';
import { ScopedConfig, ScopedConfigEntity } from 'types/graphqlTypes';

import { UPDATE_SCOPED_CONFIG } from './gql';
import { writeToCache } from './handleCacheUpdate';

interface UpdateScopedConfig {
  updateScopedConfig: ScopedConfigEntity;
}

export const getScopedSearchEntity = <T>(s: ScopedConfig<T>): ScopedConfigEntity => {
  return {
    ...s,
    data: JSON.stringify(s.data),
  };
};

export const useUpdateScopedConfig = <T>() => {
  const { errorToast } = useToast();
  const [updateMutation] = useMutation<UpdateScopedConfig>(UPDATE_SCOPED_CONFIG);
  const { canAdministrate } = useCanAdministrateSearches();

  const updateScopedConfig = useCallback(
    async (input: ScopedConfig<T>) => {
      try {
        await updateMutation({
          variables: {
            input: getScopedSearchEntity(input),
          },
          optimisticResponse: {
            updateScopedConfig: {
              ...getScopedSearchEntity(input),
            },
          },
          update: (proxy, mutationResult) => {
            if (mutationResult.data?.updateScopedConfig) {
              writeToCache(
                proxy,
                mutationResult.data?.updateScopedConfig,
                input.mType,
                canAdministrate,
              );
            }
          },
        });
      } catch (err) {
        errorToast(err);
      }
    },
    [updateMutation],
  );
  return { updateScopedConfig };
};
