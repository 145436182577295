import { useEffect, useRef } from 'react';
import { useQuery } from '@apollo/client';

import GET_RUNDOWN from 'operations/queries/getRundown';
import { QueryGetRundownArgs, Rundown } from 'types/graphqlTypes';

import { useRundownV2Atom } from '../rundownV2/state/rundown';

export const rundownTypes = {
  RUNDOWN: 'rundown',
  RUNDOWN_TEMPLATE: 'rundowntemplate',
} as const;

export type RundownMemberType = (typeof rundownTypes)[keyof typeof rundownTypes];

export interface AdjustedRundownType extends Omit<Rundown, 'mType'> {
  mId: string;
  mType: RundownMemberType;
  message?: string;
}
export type GetRundownReturnType = {
  getRundown: AdjustedRundownType;
};

type GetRundownInput = {
  mId: string;
};

const useGetRundown = ({ mId }: GetRundownInput) => {
  const [, setUseV2] = useRundownV2Atom();
  const hasSetV2 = useRef(false);
  const { data, error, loading, refetch } = useQuery<GetRundownReturnType, QueryGetRundownArgs>(
    GET_RUNDOWN,
    {
      variables: {
        input: {
          mId: mId,
          mRefId: mId,
        },
      },
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-and-network',
    },
  );

  useEffect(() => {
    if (!hasSetV2.current && data?.getRundown) {
      setUseV2(!!data?.getRundown.useV2);
      hasSetV2.current = true;
    }
  }, [data]);

  useEffect(() => {
    hasSetV2.current = false;
  }, [mId]);

  return { data, error, loading, refetch };
};

export default useGetRundown;
