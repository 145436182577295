import type { MemberType } from 'types/graphqlTypes';

export const sortByMIDOrder = (members: Pick<MemberType, 'mId'>[], order: string[]) => {
  if (order.length === 0) return members;

  const orderMap = new Map(order.map((mRefId, index) => [mRefId, index]));

  const sortedViews = members.toSorted((a, b) => {
    const indexA = orderMap.get(a.mId as string);
    const indexB = orderMap.get(b.mId as string);

    if (indexA !== undefined && indexB !== undefined) return indexA - indexB;
    if (indexA !== undefined) return -1;
    if (indexB !== undefined) return 1;
    return 0;
  });

  return sortedViews;
};

const sortByOrder = (members: Pick<MemberType, 'mRefId'>[], order: string[]) => {
  if (order.length === 0) return members;

  const orderMap = new Map(order.map((mRefId, index) => [mRefId, index]));

  const sortedViews = members.toSorted((a, b) => {
    const indexA = orderMap.get(a.mRefId as string);
    const indexB = orderMap.get(b.mRefId as string);

    if (indexA !== undefined && indexB !== undefined) return indexA - indexB;
    if (indexA !== undefined) return -1;
    if (indexB !== undefined) return 1;
    return 0;
  });

  return sortedViews;
};

export const sortByOrdering = <T extends { id: string }>(items: T[], order: string[]) => {
  if (order.length === 0) return items;

  const orderMap = new Map(order.map((id, index) => [id, index]));

  const sortedViews = items.toSorted((a, b) => {
    const indexA = orderMap.get(a.id);
    const indexB = orderMap.get(b.id);

    if (indexA !== undefined && indexB !== undefined) return indexA - indexB;
    if (indexA !== undefined) return -1;
    if (indexB !== undefined) return 1;
    return 0;
  });

  return sortedViews;
};

export default sortByOrder;
