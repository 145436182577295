import { getUrl, remove, StorageError, uploadData } from 'aws-amplify/storage';

const DEFAULT_CONTENT_TYPE = 'text/plain';

const uploadToS3 = async (
  path: string | undefined,
  data: Blob,
  progressCallback?: (data: { loaded: number; total?: number }) => void,
) => {
  if (!path) return;

  return uploadData({
    path,
    data,
    options: {
      contentType: data.type || DEFAULT_CONTENT_TYPE,
      onProgress: progressCallback
        ? (event) => progressCallback({ loaded: event.transferredBytes, total: event.totalBytes })
        : undefined,
    },
  });
};

const getSignedUrl = async (path: string, checkExistence: boolean) => {
  try {
    return (await getUrl({ path, options: { validateObjectExistence: checkExistence } }))?.url
      ?.href;
  } catch (error) {
    if (error instanceof StorageError && error.name === 'NotFound') {
      return null;
    }
    throw error;
  }
};

const deleteFromS3 = async (path: string) => remove({ path });

export { uploadToS3, getSignedUrl, deleteFromS3 };
