import { useCallback, useContext, useMemo } from 'react';

import variants from '../constants/types/editorVariants';
import EditorContext from '../EditorContext';
import { useEditorMolecule } from '../store';

const useToolbarPin = () => {
  const { variant } = useContext(EditorContext);
  const { useAskAIPin } = useEditorMolecule();

  const [pinnedState, setPinnedState] = useAskAIPin();

  const stateKey = useMemo(() => {
    switch (variant) {
      case variants.GENERAL:
        return 'content';
      case variants.NOTES:
        return 'note';
      case variants.DAILYNOTE:
        return 'dailyNote';
      case variants.MESSAGE:
        return 'message';
      default:
        return 'instance';
    }
  }, [variant]);

  const isPinned = pinnedState[stateKey];

  const togglePin = useCallback(() => {
    setPinnedState((prev) => ({
      ...prev,
      [stateKey]: !isPinned,
    }));
  }, [isPinned, setPinnedState, stateKey]);

  return { isPinned, togglePin };
};

export default useToolbarPin;
