import { useMutation } from '@apollo/client';
import CREATE_RUNDOWN from 'operations/mutations/createRundownFromTemplate';
import useCheckUserRight from 'hooks/useCheckUserRight';
import GET_RUNDOWNS_BY_PUBLISHING_DATE from 'operations/queries/getRundownByPublishingDate';
import useLogger from 'utils/useLogger';
import useDateTimeUtils from './useDateTimeUtils';

/*
  This function is used to create a schedule for an entity
  @param input: The input to create a schedule of type ScheduleInput
  @returns createSchedule which is a function that returns the ScheduleResponse
*/
const useCreateRundown = () => {
  const logger = useLogger('use create rundown');
  const { startOfDay, endOfDay } = useDateTimeUtils();
  const [createRundownMutation] = useMutation(CREATE_RUNDOWN);
  const [checkUserRight] = useCheckUserRight();
  const canCreateNewRundown = checkUserRight('rundown', 'create');

  const createRundown = async (inputObject) => {
    if (!canCreateNewRundown) return null;
    const { mId, mTitle, selectedDate } = inputObject;
    const input = {
      mId,
      mRefId: mId,
      mTitle,
      mPublishingAt: selectedDate,
    };
    let ret;
    await createRundownMutation({
      variables: { input },
      update: (proxy, mutationResult) => {
        const { createRundownFromTemplate } = mutationResult.data;
        const startDate = startOfDay(createRundownFromTemplate.mPublishingAt).toISOString();
        const endDate = endOfDay(createRundownFromTemplate.mPublishingAt).toISOString();

        const inp = {
          input: {
            mType: 'rundown',
            startDate,
            endDate,
          },
        };

        let existingRundowns;
        try {
          existingRundowns = proxy.readQuery({
            query: GET_RUNDOWNS_BY_PUBLISHING_DATE,
            variables: { ...inp },
          });

          const { getRundownsByPublishingDate } = existingRundowns;
          proxy.writeQuery({
            query: GET_RUNDOWNS_BY_PUBLISHING_DATE,
            variables: inp,
            data: {
              getRundownsByPublishingDate: [
                ...getRundownsByPublishingDate,
                createRundownFromTemplate,
              ],
            },
          });
        } catch (err) {
          logger.log(err);
        }

        ret = createRundownFromTemplate;
      },
    });
    return ret;
  };

  return [createRundown];
};

export default useCreateRundown;
