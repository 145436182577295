import { useCallback } from 'react';
import { useMutation } from '@apollo/client';

import { Script } from 'types';
import { CreateScriptInput } from 'types/graphqlTypes';

import { CREATE_SCRIPT } from './graphql';

export type CreateScriptInputType = {
  input: CreateScriptInput;
};

export type CreateScriptResponseType = {
  createScript: Script | null;
};

const useCreateScript = () => {
  const [createScriptMutation, { data, error, loading }] = useMutation<
    CreateScriptResponseType,
    CreateScriptInputType
  >(CREATE_SCRIPT);

  const createScript = useCallback(
    async (input: CreateScriptInput) => {
      const result = await createScriptMutation({
        variables: {
          input,
        },
      });

      return result?.data?.createScript ?? null;
    },
    [createScriptMutation],
  );

  return {
    createScript,
    data: data?.createScript ?? null,
    error,
    loading,
  };
};

export default useCreateScript;
