import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useSlate } from 'slate-react';

import { useEditorContext } from 'components/editor/EditorContext';
import { Update } from 'components/editor/types';
import updateBlock from 'components/editor/utils/updateBlock';
import useInputEvents from 'hooks/useInputEvents';
import { BlockElement } from 'types/editor';

import { Input } from './styled';

const DescriptionBlock = ({ children, attributes, element }: Readonly<BlockElement>) => {
  const { data } = element ?? {};
  const editor = useSlate();
  const { update, doLock, isLockedByAnotherUser, onHotKeys } = useEditorContext();
  const initialValue = (data?.content as string) || 'Your article sub heading here';
  const [value, setValue] = useState(initialValue);

  const updateTitle = useCallback(
    (newTitle: string) => {
      const updatedData = {
        ...data,
        content: newTitle,
      };
      updateBlock(editor, element, updatedData, update as Update, false, undefined);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [element],
  );

  const onUpdate = useCallback(
    (newValue: string) => {
      if (newValue === initialValue) setValue(initialValue);
      else updateTitle(newValue);
    },
    [initialValue, updateTitle],
  );

  const [inputRef, handleKeydown, handleBlur] = useInputEvents<HTMLTextAreaElement>(
    onUpdate,
    value,
    initialValue,
    false,
    onHotKeys,
  );

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const renderContent = useMemo(
    () => (
      <Input
        ref={inputRef}
        value={value}
        onChange={(ev) => setValue(ev.target.value)}
        onBlur={handleBlur}
        onFocus={doLock}
        onKeyDown={handleKeydown}
        readOnly={isLockedByAnotherUser}
        $locked={!!isLockedByAnotherUser}
      />
    ),
    [handleBlur, handleKeydown, inputRef, isLockedByAnotherUser, value],
  );

  return (
    <div {...attributes} contentEditable={false}>
      {children}
      {renderContent}
    </div>
  );
};

export default memo(DescriptionBlock);
