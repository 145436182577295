/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable max-len */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { keyBy } from 'lodash';

import { isMdfCategory } from 'api/mdf/useGetMdfs';
import Dialog from 'components/dialogs/DialogBuilder';
import Infobar from 'components/infobar';
import { StyledTextField } from 'components/mdfEditor/fields/text/styled';
import { Box } from 'layouts/box/Box';

import { programmaticIdRegex } from '../integrations/EditActions';

import { createLabelValidator, MdfItem, MdfItemCreationInfoMapper } from './mdfItemHelper';

const mdfIdRegex = /^\w*$/;

interface Props<T extends MdfItem, I, C extends string> {
  open: boolean;
  creating: boolean;
  items: readonly T[];
  changeMap: Readonly<Record<string, I>>;
  category: C;
  infoMapper: MdfItemCreationInfoMapper<T, I, C>;
  setOpen: (val: boolean) => void;
  onCreate: (label: string, category: C, id: string) => void;
  getIdProblem?: (id: string) => string | undefined;
}

export default function CreateMdfItemDialog<T extends MdfItem, I, C extends string>({
  items,
  changeMap,
  creating,
  open,
  category,
  infoMapper,
  setOpen,
  onCreate,
  getIdProblem,
}: Readonly<Props<T, I, C>>) {
  const [label, setLabel] = useState('');
  const [id, setId] = useState('');
  const title = `Create new ${infoMapper.getCategoryTypeText(category, false)}`;

  const byId = useMemo(() => {
    return keyBy(items, (e) => e.id);
  }, [items]);

  const onConfirm = useCallback(() => {
    if (label.length > 0 && id.length > 0) {
      onCreate(label, category, id);
    }
  }, [label, id, id, category]);

  const validateLabel = useMemo(
    () =>
      createLabelValidator(
        items,
        changeMap,
        undefined,
        infoMapper.getCategoryTypeText(category, false),
        infoMapper,
      ),
    [items, changeMap, category],
  );

  const labelErrorMessage = useMemo(() => {
    const validationResult = validateLabel(label) || ' ';
    return validationResult === true ? '' : validationResult;
  }, [label, validateLabel]);

  const idErrorMessage = useMemo(() => {
    if (id.length === 0) return 'Required value';
    const existing = byId[id];
    if (existing)
      return `This ID is used by the ${infoMapper.getItemTypeText(existing, false)} named '${
        existing.label
      }'.`;
    const regex = isMdfCategory(category) ? mdfIdRegex : programmaticIdRegex;
    if (!regex.test(id)) return 'Only alphanumeric characters are allowed';
    return getIdProblem?.(id) ?? '';
  }, [id, id, byId]);

  const disabledConfirm = idErrorMessage.length > 0 || labelErrorMessage.length > 0 || creating;
  const onKeyUp = useCallback(
    (ev: React.KeyboardEvent<HTMLDivElement>) => {
      if (ev.key === 'Enter' && !disabledConfirm) {
        onConfirm();
      } else if (ev.key === 'Escape') {
        setOpen(false);
      }
    },
    [onConfirm, setOpen],
  );
  useEffect(() => {
    if (!open) {
      setLabel('');
      setId('');
    }
  }, [open, setLabel, setId]);

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <Dialog.Header>{title}</Dialog.Header>
      <Dialog.Body>
        <Box margin="10px 0">
          <StyledTextField
            error={idErrorMessage.length > 0}
            helperText={idErrorMessage}
            autoFocus
            variant="filled"
            fullWidth
            placeholder="Programmatic identifier"
            value={id}
            onChange={(event) => setId(event.target.value)}
            onKeyUp={onKeyUp}
          />
        </Box>
        <Box margin="10px 0">
          <StyledTextField
            error={labelErrorMessage.length > 0}
            helperText={labelErrorMessage.trim()}
            variant="filled"
            fullWidth
            placeholder="User visible label"
            value={label}
            onChange={(event) => setLabel(event.target.value)}
            onKeyUp={onKeyUp}
          />
        </Box>
        <Infobar>{infoMapper.infoTexts[category]}</Infobar>
      </Dialog.Body>
      <Dialog.Footer>
        <Dialog.CancelButton />
        <Dialog.ConfirmButton
          onConfirm={onConfirm}
          label="Confirm"
          disabled={disabledConfirm}
          loading={creating}
        />
      </Dialog.Footer>
    </Dialog>
  );
}
