import { useContext, useEffect } from 'react';
import { useQuery } from '@apollo/client';

import UserContext from 'contexts/UserContext';
import GET_CONVERSATIONS_OF_USER from 'operations/queries/getConversationsOfUser';
import { Conversation } from 'types/messageHub';

interface FetchConversations {
  getConversationsOfUser: Conversation[];
}

function useGetConversations() {
  const user = useContext(UserContext);
  const { mId } = user;
  const { data, loading, error, refetch } = useQuery<FetchConversations>(
    GET_CONVERSATIONS_OF_USER,
    {
      variables: {
        input: {
          mId,
        },
      },
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-and-network',
    },
  );

  useEffect(() => {
    const triggerRefetch = () => void refetch();
    window.addEventListener('online', triggerRefetch);
    return () => {
      window.removeEventListener('online', triggerRefetch);
    };
  }, [refetch]);

  return { data, loading, error, refetch };
}

export default useGetConversations;
