import styled from '@emotion/styled';

import MemberDetails from 'screens/main/components/rightPanel/memberDetails/MemberDetails';
import { MemberType } from 'types/graphqlTypes';

const ContactsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
export interface PreviewContactProps {
  member: MemberType;
  onClose?: () => void;
  openItem?: (val: MemberType) => void;
}
export default function ContactsPreview({
  member,
  onClose,
  openItem,
}: Readonly<PreviewContactProps>) {
  return (
    <ContactsContainer>
      <MemberDetails
        member={member}
        memberId={member?.mId}
        onClose={onClose ?? (() => {})}
        width={'100%'}
        isPreview={true}
        openItem={openItem ?? (() => {})}
      />
    </ContactsContainer>
  );
}
