import { Editor } from 'slate';

const onEditorKeyDown = ({
  editor,
  event,
  onEscape,
  onHotKeys,
}: {
  editor: Editor;
  event: React.KeyboardEvent<HTMLDivElement>;
  onHotKeys?: (
    event: React.KeyboardEvent,
    callback?: () => void | Promise<void>,
  ) => void | Promise<void>;
  onEscape?: () => void;
}) => {
  if (event.key === 'Escape') {
    onEscape?.();
  }

  if (onHotKeys) {
    onHotKeys(event)?.catch((error) => {
      // eslint-disable-next-line no-console
      console.error(error);
    });
  }

  return editor;
};

export default onEditorKeyDown;
