import { useState } from 'react';

import ScheduleDatePicker from 'components/createNewV3/datePicker/scheduleDatePicker';
import Input from 'components/input/Input';
import { MdfEditor } from 'components/mdfEditor/MdfEditor';
import { FieldHeader } from 'components/mdfEditor/styled';
import useMetadata from 'hooks/useMetadata';
import { Box } from 'layouts/box/Box';
import { Mdf } from 'types/graphqlTypes';
import { EditorCommandConfigType } from 'types/memberTypes/editorCommands';

import { FieldWrapper } from './styled';

interface PreviewPlanningConfigProps {
  config: EditorCommandConfigType;
  mdf?: Mdf;
}

const stableDummyFn = () => {};

function PreviewPlanningConfig({ config, mdf }: Readonly<PreviewPlanningConfigProps>) {
  const [dummyDate, setDummyDate] = useState<string | null>(null);
  const { metadata, updateFieldValues, errorMap } = useMetadata(mdf, undefined, 'default');

  return (
    mdf &&
    config && (
      <div>
        <FieldWrapper>
          <FieldHeader variant="overline">Block title</FieldHeader>
          <Input initialValue="" updateValue={stableDummyFn} />
        </FieldWrapper>
        {config.canSchedule && (
          <FieldWrapper>
            <FieldHeader variant="overline">{config.mDescription ?? 'Scheduled at'}</FieldHeader>
            <ScheduleDatePicker
              showTime
              transparentBackground
              dateRange={dummyDate ? { startDate: dummyDate, endDate: null } : null}
              onDateRangeChange={(dateRange) => {
                setDummyDate(dateRange?.startDate ?? null);
              }}
              canSelectRange={false}
              disableUpdate={false}
              hideCloseIcon
            />
          </FieldWrapper>
        )}
        <Box margin="5px 0 0 0">
          <MdfEditor
            view="default"
            fields={mdf.fields}
            defaultLayoutSettings={mdf.views.default}
            layoutSettings={mdf.views.default}
            metadata={metadata}
            permissions={mdf.permissions}
            updateFieldValue={updateFieldValues}
            errorMap={errorMap}
          />
        </Box>
      </div>
    )
  );
}

export default PreviewPlanningConfig;
