import { useMemo } from 'react';
import { keyBy } from 'lodash';

import { useGetOrderForms } from 'api/order_forms/useGetOrderForms';

import { extendGridItems } from '../common/hooks/useGroomData';

import OrderGridSetup from './OrderGrid';
import { OrderGridType } from './types';

/** Grid for displaying a list of orders */
export default function OrderGridDataLoader({ orders, mdfs, ...rest }: Readonly<OrderGridType>) {
  // ─── Order Forms ─────────────────────────────────────────────────────
  const { orderForms } = useGetOrderForms();

  const orderFormMap = useMemo(() => keyBy(orderForms, (form) => form.mRefId), [orderForms]);
  const extendedOrders = extendGridItems(orders, mdfs);

  return (
    <OrderGridSetup orders={extendedOrders} mdfs={mdfs} orderFormMap={orderFormMap} {...rest} />
  );
}
