import { atom, useAtom } from 'jotai';

import { KeyMap } from 'types/keyboardShortcut';

const general = {
  SHOW_KEYS: {
    label: 'Keyboard Shortcuts Panel',
    sequences: ['shift+?'],
    isFixed: true,
    tooltip: 'Show all the keyboard shortcuts',
  },
  CREATE_STORY: {
    label: 'Create Story or Pitch',
    sequences: ['alt+shift+N'],
  },
} satisfies Record<string, KeyMap>;

const generalKeyMap = atom<Record<keyof typeof general, KeyMap>>(general);

export const useGeneralKeyMap = () => useAtom(generalKeyMap);
