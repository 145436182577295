import { memo, useMemo } from 'react';

import { useGetTypedOptionList } from 'api/optionLists/useGetOptionList';
import Text from 'components/text';
import { Color } from 'features/reusableStyled';
import type { Alternative } from 'types/graphqlTypes';

import type FieldProps from '../fieldProps';

import { StyledOption, StyledOptionWrapper } from './styled';

const STABLE_ARR = [] satisfies Alternative[];

function SingleChoice({
  fieldModel,
  value,
  fieldSettings,
  defaultFieldSettings,
}: Readonly<
  Pick<FieldProps, 'fieldModel' | 'value' | 'fieldSettings' | 'defaultFieldSettings'> & {
    disableClearable?: boolean;
  }
>) {
  const { optionListId } = fieldModel;
  const { colors = {} } = fieldSettings ?? defaultFieldSettings;
  const { optionList } = useGetTypedOptionList(optionListId, 'choice');

  const alternatives = useMemo(
    () => optionList?.alternatives ?? fieldModel.alternatives ?? STABLE_ARR,
    [optionList, fieldModel],
  );

  const selectedValue = useMemo(() => {
    return alternatives.find((opt) => opt.value === value) ?? null;
  }, [value, alternatives]);

  if (!selectedValue) {
    return (
      <Text variant="listItemLabel" color="lowEmphasis">
        Select a choice
      </Text>
    );
  }

  return (
    <StyledOptionWrapper $height="100%">
      {selectedValue && (
        <StyledOption container flexDirection="row" gap="4px" justifyContent="start">
          {colors[selectedValue.label ?? ''] && (
            <Color $color={colors[selectedValue.label ?? '']} $size={12} />
          )}
          <Text variant="listItemLabel" truncate color="highEmphasis">
            {selectedValue.label ?? selectedValue.value ?? 'Invalid option!'}
          </Text>
        </StyledOption>
      )}
    </StyledOptionWrapper>
  );
}

export default memo(SingleChoice);
