import React, { useCallback, useEffect, useState } from 'react';
import { OnDataOptions } from '@apollo/client';
import { groupBy } from 'lodash';

import { DeleteDialog } from 'components/dialogs/CommonDialogs';
import useDeleteOrder, { OrderDeleteKeys } from 'components/orderFormDialog/api/useDeleteOrder';
import Switch from 'components/switch/Switch';
import useToast from 'components/toast/useToast';
import useCommentSubscription from 'features/comments/hooks/useCommentSubscription';
import OrderGrid from 'features/grids/order/DataLoader';
import PrevOrderGrid from 'features/orderForm/components/OrderGrid';
import useApolloSubscription from 'hooks/useApolloSubscriptionV2';
import useCheckUserRight from 'hooks/useCheckUserRight';
import { Flex } from 'layouts/box/Box';
import { UPDATED_ORDERS } from 'operations/subscriptions/notifyOrderUpdate';
import {
  removeOrderFromCache,
  removeOrderFromCacheByType,
  useGetOrdersAndForms,
  writeOrderToCache,
  writeOrderToCacheByType,
} from 'screens/space/api/useGetOrdersAndForms';
import type { Order, RawOrder } from 'types/forms/forms';
import { CrudActionEnum, GetOrderEnum, TaskStatusEnum } from 'types/graphqlTypes';

import {
  HeaderSection,
  HeaderWrapper,
  OrderGridFullWrapper,
  Switches,
  SwitchLabel,
} from '../styled';

import { getActiveFromOrder, useDeleteOrderAtom } from './utils';

/**
 * resourceId can include:
 * resourceId eg the mId of a resource the orders are tied to
 * spaceId eg the mId of the space orders are tied to (where the forms live)
 * assigneeId who is assigned to the order
 * ownerId who owns the order
 */
interface Props {
  resourceId: string;
  resourceType: GetOrderEnum;
  closeDialog?: () => void;
  headerSlot?: React.ReactElement;
  doSubscribe?: boolean;
  hideCompletedSwitch?: boolean;
}

interface SubscriptionEvent {
  notifyOrderUpdateSubscription: RawOrder;
}

type SwitchValues = 'grouped' | 'ungrouped';

export const getTaskStatus = (
  showCompleted: boolean,
  resourceType: GetOrderEnum,
): TaskStatusEnum => {
  if (resourceType === GetOrderEnum.Resource) return TaskStatusEnum.all;
  return showCompleted ? TaskStatusEnum.inactive : TaskStatusEnum.active;
};

const OrderGridFull: React.FC<Props> = ({
  resourceType,
  resourceId,
  closeDialog,
  headerSlot,
  doSubscribe = true,
  hideCompletedSwitch = false,
}): React.JSX.Element | null => {
  const [showCompleted, setShowCompleted] = useState(false);
  const { orders, mdfs, loading } = useGetOrdersAndForms(
    resourceId,
    resourceType,
    getTaskStatus(showCompleted, resourceType),
  );

  const [selected, setSelected] = useState<SwitchValues>('ungrouped');
  const { errorToast } = useToast();
  const [groupedOrders, setGroupedOrders] = useState<Record<string, Order[]>>({});
  const [orderToDelete, setOrderToDelete] = useDeleteOrderAtom();
  const [checkUserRight] = useCheckUserRight();
  const showTanStackOrderGrid = checkUserRight('feature', 'tanstack-order-grid');

  const { deleteOrder } = useDeleteOrder();

  useCommentSubscription([resourceId]);
  useApolloSubscription(UPDATED_ORDERS, {
    variables: { mIdSubscribed: resourceId },
    source: 'OrderGridFull',
    skip: !doSubscribe || !resourceId,
    onSubscriptionData: (data: OnDataOptions<SubscriptionEvent>) => {
      const { data: subscriptionData, client } = data;
      if (!subscriptionData.data) return;
      const order = subscriptionData.data.notifyOrderUpdateSubscription;
      const status = getActiveFromOrder(order);
      switch (order.crudAction) {
        case CrudActionEnum.Remove:
          removeOrderFromCache(client, resourceId, order.mId, status);
          break;
        case CrudActionEnum.Insert:
          writeOrderToCache(client, resourceId, order, status, CrudActionEnum.Insert);
          break;
        case CrudActionEnum.Modify:
          switch (resourceType) {
            case GetOrderEnum.Form:
              if (order.mFormId === resourceId && status === TaskStatusEnum.active) {
                writeOrderToCacheByType(
                  client,
                  resourceId,
                  order,
                  resourceType,
                  TaskStatusEnum.active,
                  CrudActionEnum.Insert,
                );
              } else if (order.mFormId === resourceId && status === TaskStatusEnum.inactive) {
                removeOrderFromCacheByType(
                  client,
                  resourceId,
                  order.mId,
                  resourceType,
                  TaskStatusEnum.active,
                );
              }
              break;
            case GetOrderEnum.Space:
              if (order.mSpaceId === resourceId && status === TaskStatusEnum.active) {
                writeOrderToCacheByType(
                  client,
                  resourceId,
                  order,
                  resourceType,
                  TaskStatusEnum.active,
                  CrudActionEnum.Insert,
                );
              } else if (order.mSpaceId === resourceId && status === TaskStatusEnum.inactive) {
                removeOrderFromCacheByType(
                  client,
                  resourceId,
                  order.mId,
                  resourceType,
                  TaskStatusEnum.active,
                );
              }
              break;
            case GetOrderEnum.Owner:
              if (order.mOwner !== resourceId) {
                removeOrderFromCacheByType(
                  client,
                  resourceId,
                  order.mId,
                  resourceType,
                  TaskStatusEnum.active,
                );
                removeOrderFromCacheByType(
                  client,
                  resourceId,
                  order.mId,
                  resourceType,
                  TaskStatusEnum.inactive,
                );
              } else if (order.mOwner === resourceId && status === TaskStatusEnum.active) {
                writeOrderToCacheByType(
                  client,
                  resourceId,
                  order,
                  resourceType,
                  TaskStatusEnum.active,
                  CrudActionEnum.Insert,
                );
              } else if (order.mOwner === resourceId && status === TaskStatusEnum.inactive) {
                removeOrderFromCacheByType(
                  client,
                  resourceId,
                  order.mId,
                  resourceType,
                  TaskStatusEnum.active,
                );
              }
              break;
            case GetOrderEnum.Assignee:
              if (order.mAssignee !== resourceId) {
                removeOrderFromCacheByType(
                  client,
                  resourceId,
                  order.mId,
                  resourceType,
                  TaskStatusEnum.active,
                );
                removeOrderFromCacheByType(
                  client,
                  resourceId,
                  order.mId,
                  resourceType,
                  TaskStatusEnum.inactive,
                );
              } else if (order.mAssignee === resourceId && status === TaskStatusEnum.active) {
                writeOrderToCacheByType(
                  client,
                  resourceId,
                  order,
                  resourceType,
                  TaskStatusEnum.active,
                  CrudActionEnum.Insert,
                );
              } else if (order.mAssignee === resourceId && status === TaskStatusEnum.inactive) {
                removeOrderFromCacheByType(
                  client,
                  resourceId,
                  order.mId,
                  resourceType,
                  TaskStatusEnum.active,
                );
              }
              break;
          }
      }
    },
  });

  /**
   * The dynamic nature of updating cache based on resourceId/resourceType requires
   * us to resubscribe to ensure changes to these 2 variables are handled by the subscription
   * call back.
   */

  useEffect(() => {
    return () => {
      setOrderToDelete(null);
    };
  }, [doSubscribe, resourceId]);

  useEffect(() => {
    if (selected !== 'grouped' || (orders.length === 0 && Object.keys(groupedOrders).length === 0))
      return;
    const groupByFormId: Record<string, Order[]> = groupBy(orders, (order) => order.mFormId);
    setGroupedOrders(groupByFormId);
  }, [orders, selected]);

  const toggleGrouped = useCallback(() => {
    setSelected(selected === 'grouped' ? 'ungrouped' : 'grouped');
  }, [selected]);

  const closeDeleteDialog = useCallback(() => {
    setOrderToDelete(null);
  }, []);

  const handleDeleteOrder = useCallback(
    async (deleteKeys: OrderDeleteKeys | null) => {
      if (deleteKeys) {
        await deleteOrder(deleteKeys).catch(errorToast);
        closeDeleteDialog();
      }
    },
    [deleteOrder, setOrderToDelete, closeDeleteDialog],
  );

  // We force display a single grid of no orders when using grouped by type
  const noOrders = orders.length === 0 && Object.keys(groupedOrders).length === 0;

  return (
    <OrderGridFullWrapper>
      <HeaderSection>
        <HeaderWrapper>{headerSlot}</HeaderWrapper>
        <Switches>
          {!hideCompletedSwitch && (
            <>
              <Switch
                selected={showCompleted}
                onClick={() => setShowCompleted((prev) => !prev)}
                disabled={false}
              />
              <SwitchLabel onClick={() => setShowCompleted((prev) => !prev)}>
                View completed
              </SwitchLabel>
            </>
          )}

          <Switch
            selected={selected === 'grouped'}
            onClick={() => toggleGrouped()}
            disabled={false}
          />
          <SwitchLabel onClick={() => toggleGrouped()}>Group by type</SwitchLabel>
        </Switches>
      </HeaderSection>
      <Flex
        flex="1"
        flexDirection="column"
        gap="8px"
        alignItems="flex-start"
        justifyContent="flex-start"
        overflow="auto"
      >
        {((mdfs && selected == 'ungrouped') || noOrders) && (
          <>
            {showTanStackOrderGrid ? (
              <OrderGrid loading={loading} orders={orders} mdfs={mdfs} closeDialog={closeDialog} />
            ) : (
              <PrevOrderGrid
                loading={loading}
                formId="default_formId"
                orders={orders}
                mdfs={mdfs ?? []}
                closeDialog={closeDialog}
                showFilters={false}
                maxHeight={500}
              />
            )}
          </>
        )}
        {mdfs &&
          selected == 'grouped' &&
          Object.keys(groupedOrders).map((key) => (
            <React.Fragment key={key}>
              {showTanStackOrderGrid ? (
                <OrderGrid
                  loading={loading}
                  storageKey={key}
                  key={key}
                  closeDialog={closeDialog}
                  orders={groupedOrders[key]}
                  mdfs={mdfs}
                />
              ) : (
                <PrevOrderGrid
                  loading={loading}
                  formId={key}
                  orders={groupedOrders[key]}
                  mdfs={mdfs}
                  closeDialog={closeDialog}
                  showFilters={false}
                  maxHeight={200}
                />
              )}
            </React.Fragment>
          ))}
      </Flex>
      <DeleteDialog
        open={Boolean(orderToDelete)}
        onClose={closeDeleteDialog}
        onClick={() => handleDeleteOrder(orderToDelete)}
        title="Delete order?"
        message="Are you sure you want to delete this order? This cannot be undone!"
      />
    </OrderGridFullWrapper>
  );
};

export default OrderGridFull;
