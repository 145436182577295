export const colors: Record<string, { background: string; text: string }> = {
  '#E0A5F5': { background: '#E0A5F5', text: '#000000' },
  '#B700E5': { background: '#B700E5', text: '#FFFFFF' },
  '#7A05FC': { background: '#7A05FC', text: '#FFFFFF' },
  '#410BFF': { background: '#410BFF', text: '#FFFFFF' },
  '#119BFD': { background: '#119BFD', text: '#000000' },
  '#1CFCFE': { background: '#1CFCFE', text: '#000000' },
  '#6CE880': { background: '#6CE880', text: '#000000' },
  '#19FF47': { background: '#19FF47', text: '#000000' },
  '#00AB11': { background: '#00AB11', text: '#000000' },
  '#DFF401': { background: '#DFF401', text: '#000000' },
  '#FF9800': { background: '#FF9800', text: '#000000' },
  '#FF5722': { background: '#FF5722', text: '#000000' },
  '#DF0100': { background: '#DF0100', text: '#FFFFFF' },
  '#795548': { background: '#795548', text: '#FFFFFF' },
  '#9E9E9E': { background: '#9E9E9E', text: '#000000' },
  '#607D8B': { background: '#607D8B', text: '#000000' },
  '#A9B1BC': { background: '#A9B1BC', text: '#000000' },
};
