import { useCallback } from 'react';
import { useMutation } from '@apollo/client';

import SELECT_ACTIVE_ORGANIZATION from 'operations/mutations/selectActiveOrganization';
import type { OrganizationUser, SwitchUserActiveOrgInput } from 'types/graphqlTypes';

interface SwitchUserActiveOrg {
  switchUserActiveOrg: OrganizationUser;
}

function useSelectActiveOrganization() {
  const [selectOrganization, { loading, error }] = useMutation<
    SwitchUserActiveOrg,
    { input: SwitchUserActiveOrgInput }
  >(SELECT_ACTIVE_ORGANIZATION);

  const selectActiveOrganization = useCallback(
    async (orgId: string) => {
      const result = await selectOrganization({
        variables: { input: { orgId } },
      });
      return result.data?.switchUserActiveOrg;
    },
    [selectOrganization],
  );

  return { selectActiveOrganization, loading, error };
}

export default useSelectActiveOrganization;
