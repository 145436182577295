import { useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import useIntersectionObserver from '@react-hook/intersection-observer';

import Scrollbar from 'components/scrollbar';
import { MemberType } from 'types/graphqlTypes';

import ActivityItem from './ActivityItem';
import SkeletonLoading from './SkeletonLoading';

const ItemsWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 8px;
`;
export default function GroupedAction({
  activityList,
  setIsFetchData,
  hasMore,
  nextToken,
}: Readonly<{
  activityList: MemberType[];
  setIsFetchData: (value: boolean) => void;
  hasMore?: boolean;
  nextToken?: string | null;
}>) {
  const bottomRef = useRef(null);
  const { isIntersecting } = useIntersectionObserver(bottomRef, {
    rootMargin: '350px',
    pollInterval: 500,
    threshold: 0.1,
  });

  useEffect(() => {
    setIsFetchData(isIntersecting);
  }, [isIntersecting]);

  return (
    <Scrollbar>
      <ItemsWrapper>
        {activityList?.map((item) => (
          <ActivityItem key={item.mId} itemData={item} />
        ))}
        <div ref={bottomRef} style={{ height: '10px', background: 'transparent' }} />
        {hasMore && nextToken && (
          <div>
            <SkeletonLoading />
          </div>
        )}
      </ItemsWrapper>
    </Scrollbar>
  );
}
