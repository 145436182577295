import { useCallback } from 'react';
import { ApolloCache, FetchResult, useMutation } from '@apollo/client';

import MODIFY_USER_IN_ORGANIZATION from 'operations/mutations/modifyUserInOrganization';
import GET_ORGANIZATION_USERS from 'operations/queries/getOrganizationUsers';
import type { ModifyUserInOrgInput, OrganizationUser } from 'types/graphqlTypes';

import { FetchOrganizationsUsers } from './useGetOrganizationUsers';

interface CreateOrganization {
  modifyUserInOrg: OrganizationUser;
}

function useModifyUserInOrganization() {
  const [addUser, { loading, error }] = useMutation<
    CreateOrganization,
    { input: ModifyUserInOrgInput }
  >(MODIFY_USER_IN_ORGANIZATION);

  const updateCache = useCallback(
    (
      cache: ApolloCache<unknown>,
      mutationResult: Omit<FetchResult<CreateOrganization>, 'context'>,
      userId: string,
      orgId: string,
    ) => {
      if (!mutationResult.data?.modifyUserInOrg) return;

      const orgCacheResult = cache.readQuery<FetchOrganizationsUsers>({
        query: GET_ORGANIZATION_USERS,
        variables: { input: { orgId } },
      });
      if (orgCacheResult?.getOrganizationUsers) {
        cache.writeQuery<FetchOrganizationsUsers>({
          query: GET_ORGANIZATION_USERS,
          variables: { input: { orgId } },
          data: {
            getOrganizationUsers: [
              mutationResult.data?.modifyUserInOrg,
              ...orgCacheResult.getOrganizationUsers.filter((u) => u.id !== userId),
            ],
          },
        });
      }
    },
    [],
  );

  const modifyUserInOrganization = useCallback(
    async (
      userId: string,
      orgId: string,
      groups?: string[],
      isArchived?: boolean,
      isDisabled?: boolean,
    ) => {
      const result = await addUser({
        variables: { input: { userId, orgId, groups, isArchived, isDisabled } },
        update: (cache, mutationResult) => updateCache(cache, mutationResult, userId, orgId),
      });
      return result.data?.modifyUserInOrg;
    },
    [addUser, updateCache],
  );

  return { modifyUserInOrganization, loading, error };
}

export default useModifyUserInOrganization;
