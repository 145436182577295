import { useMemo, useState } from 'react';
import { sortBy } from 'lodash';

import SplitBar from 'components/split';
import { Box, VStack } from 'layouts/box/Box';
import ScrollArea from 'lib/scrollArea/ScrollArea';
import { Rundown } from 'types/graphqlTypes';

import PermissionsView from './PermissionsView';

import { HeaderText, StyledListItem } from './styled';

const RundownPermissions = ({ rundowns }: { rundowns: { getAllRundownTemplates: Rundown[] } }) => {
  const [selectedRundown, setSelectedRundown] = useState<Rundown | null>(null);

  const rows = useMemo(() => {
    return sortBy(rundowns.getAllRundownTemplates, 'mTitle').map((rundown) => rundown);
  }, [rundowns]);

  return (
    <SplitBar
      style={{
        flex: 1,
        justifyContent: 'flex-start',
        alignSelf: 'flex-start',
        alignItems: 'flex-start',
        width: '100%',
        height: '100%',
        position: 'relative',
      }}
      pane1Style={{
        minWidth: '30%',
        maxWidth: 'calc(100% - 50%)',
        height: '100%',
      }}
      pane2Style={{
        minWidth: '50%',
        maxWidth: 'calc(100% - 30%)',
        display: 'flex',
        flex: 1,
      }}
      split="vertical"
      primary="first"
    >
      <VStack height="100%">
        <ScrollArea>
          {rows.map((row) => (
            <StyledListItem
              key={row.mId}
              as="div"
              variant="listItemLabel"
              onClick={() => setSelectedRundown(row)}
              $selected={selectedRundown?.mId === row.mId}
            >
              {row.mTitle ?? 'Untitled'}
            </StyledListItem>
          ))}
        </ScrollArea>
      </VStack>
      <Box
        container
        width="50%"
        alignItems="flex-start"
        flexDirection="column"
        flex="1"
        gap="0.5rem"
      >
        {selectedRundown ? (
          <PermissionsView rundown={selectedRundown} />
        ) : (
          <HeaderText as="h6" variant="h6">
            Select a rundown template to set permissions
          </HeaderText>
        )}
      </Box>
    </SplitBar>
  );
};

export default RundownPermissions;
