import React, { useCallback, useContext } from 'react';
import {
  ConnectableElement,
  DragSourceMonitor,
  DropTargetMonitor,
  useDrag,
  useDrop,
} from 'react-dnd';

import { ReactComponent as ArrowRight } from 'assets/icons/systemicons/arrows/disclosurearrow_discreet_right.svg';
import { ReactComponent as EmailIcon } from 'assets/icons/systemicons/email.svg';
import { ReactComponent as ChatResting } from 'assets/icons/systemicons/HeaderNavbar/chat_resting.svg';
import Avatar from 'components/avatar/Avatar';
import { IconButton } from 'components/buttons';
import Text from 'components/text/Text';
import useToast from 'components/toast/useToast';
import UserContext from 'contexts/UserContext';
import useCreateConvo from 'hooks/useCreateConvo';
import useGetUser from 'hooks/useGetUser';
import useHover from 'hooks/useHover';
import useShareStory from 'hooks/useShareStory';
import { Box, HStack, VStack } from 'layouts/box/Box';
import { useIsMessageHubOpen, useSelectedConversationId } from 'store';
import { useSetPreview } from 'store/preview';
import { MemberTypeEnum, type Metadata } from 'types/graphqlTypes';
import { User } from 'types/members';
import accessibleOnClick from 'utils/accessibleOnClick';
import dndTypes from 'utils/dndTypes';

import { ContentWrapper, FocusDiv, IconWrapper, MUIListItem } from './styled';
interface Props {
  member: User;
  style?: React.CSSProperties;
}

const Member = ({ member, style = {} }: Props) => {
  const setPreview = useSetPreview();
  const { getUser } = useGetUser();
  const { errorToast } = useToast();
  const user = useContext(UserContext);
  const { createConvo } = useCreateConvo();
  const [assignMemberToStory] = useShareStory();
  const [, setIsMessageHubOpen] = useIsMessageHubOpen();
  const [hoverRef, isHovered] = useHover<HTMLDivElement>();
  const [, setSelectedConvoId] = useSelectedConversationId();

  const [, dragRef] = useDrag({
    type: dndTypes.MEMBER,
    item: () => member,
    end: (_, monitor: DragSourceMonitor<User, { id: string }>) => {
      if (!monitor.didDrop()) return;
      if (typeof monitor?.getDropResult()?.id === 'string') {
        const dropResId = monitor?.getDropResult()?.id;
        if (dropResId)
          assignMemberToStory(dropResId, member.mId, member.mType).catch((err) => errorToast(err));
      }
    },
  });

  const [{ hovered }, dropRef] = useDrop({
    accept: [dndTypes.STORY, dndTypes.PITCH],
    drop: (_, monitor: DropTargetMonitor<{ id: string }, User>) => {
      if (monitor) {
        assignMemberToStory(monitor?.getItem()?.id, member.mId, member.mType).catch((err) =>
          errorToast(err),
        );
      }
    },
    collect: (monitor) => ({ hovered: monitor.isOver() }),
  });

  const attachRef = useCallback(
    (el: ConnectableElement) => {
      dragRef(el);
      dropRef(el);
    },
    [dragRef, dropRef],
  );

  const handleClick = useCallback(() => {
    setPreview(member);
  }, [member, setPreview]);

  const parsedMetadata = (member?.metadata ? JSON.parse(member.metadata) : {}) as Metadata;
  const email = parsedMetadata?.email as string;
  const phone = parsedMetadata?.phone as string;
  const isExternalContact = member.mType === MemberTypeEnum.Contact;

  const handleChat = async (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const memberToChat = getUser(member.mId);
    if (memberToChat)
      await createConvo(memberToChat, (convo) => {
        setSelectedConvoId(convo?.mId);
        setIsMessageHubOpen(true);
      });
  };

  const handleEmail = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    window.location.assign(`mailto: ${email}`);
  };

  const isCurrentUser = user.mId === member.mId;

  return (
    <FocusDiv ref={attachRef} style={{ ...style }}>
      <MUIListItem button={true} onClick={handleClick} ref={hoverRef} hovered={hovered}>
        <Avatar size={40} imageKey={member.mAvatarKey} title={member.mTitle} />
        <ContentWrapper>
          <VStack flex="1" gap="2xp">
            <Text variant="listItemLabel" color="highEmphasis" truncate>
              {member.mTitle}
            </Text>
            <Text variant="caption" color="mediumEmphasis" truncate>
              {email}
            </Text>
            <Text variant="caption" color="mediumEmphasis" truncate>
              {phone}
            </Text>
          </VStack>
          <IconWrapper {...accessibleOnClick(handleClick, 'presentation')}>
            {isHovered && !isCurrentUser && member.mType !== MemberTypeEnum.Contact ? (
              <HStack height="100%">
                {!isExternalContact && (
                  <IconButton
                    onClick={handleChat}
                    variant="discreet"
                    usage="text"
                    noBorder
                    round
                    size={24}
                    iconSize={20}
                    title="Start chat"
                  >
                    <ChatResting />
                  </IconButton>
                )}
                <IconButton
                  onClick={handleEmail}
                  variant="discreet"
                  usage="text"
                  noBorder
                  round
                  size={24}
                  iconSize={20}
                  title="Send email"
                  disabledTitle="Email is not set"
                  disabled={!email}
                >
                  <EmailIcon />
                </IconButton>
              </HStack>
            ) : (
              <Box height="100%" padding="4px 0">
                <ArrowRight />
              </Box>
            )}
          </IconWrapper>
        </ContentWrapper>
      </MUIListItem>
    </FocusDiv>
  );
};

export default Member;
