import styled from '@emotion/styled';
import { css } from '@emotion/react';

import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

export const ScratchPadIconWrapper = styled.div`
  display: flex;
  position: relative;
  cursor: pointer;
  ${({ theme }) => css`
    svg {
      width: 24px;
      height: 32px;
      path {
        fill: ${theme.palette.dina.whiteHighEmphasis};
        fill-opacity: ${theme.palette.mode === 'light' ? 0.65 : 0.54};
      }
    }
  `}
  &:hover {
    svg {
      path {
        fill-opacity: 1;
      }
    }
  }
`;

export const NotificationIndicatorWrapper = styled.div`
  position: absolute;
  left: 14px;
  bottom: 13px;
  &:hover svg path {
    fill-opacity: 1 !important;
  }
`;

export const ScratchpadWrapper = styled.div`
  position: absolute;
  top: 35px;
  right: 140px;
`;

export const ScratchpadInnerRootWrapper = styled('div')`
  ${({ theme }) => css`
    background-color: ${theme.palette.dina.surfaceCard};
    box-shadow: 0px 12px 24px ${theme.palette.dina.boxShadowDark},
      0px 0px 1px ${theme.palette.dina.boxShadowDark};
  `}
  z-index: 1;
  width: 572px;
  height: 440px;
  border-radius: 8px;
  backdrop-filter: blur(30px);
  position: relative;
  box-sizing: border-box;
`;

export const ScratchpadInnerWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const StyledTypography = styled(Typography)`
  ${({ theme }) => theme.typography.dina.h5};
  line-height: 28px;
  letter-spacing: normal;
  height: 64px;
  margin-left: 16px;
  display: flex;
  align-items: center;
`;

export const StyledDivider = styled(Divider)`
  height: 1px;
  background-color: ${({ theme }) => theme.palette.dina.dividerLight};
`;

export const StyledCardContainer = styled('div')`
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  margin: 8px;
  overflow: auto;
`;

export const StyledCard = styled('div')`
  margin: 8px;
  height: 133px;
`;
