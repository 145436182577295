import { markTypes } from './types';

const { BOLD, ITALIC, UNDERLINE } = markTypes;

const hotkeys: Record<string, string> = {
  bold: BOLD,
  italic: ITALIC,
  underline: UNDERLINE,
};

export default hotkeys;
