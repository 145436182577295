import { memo } from 'react';

import useToolbarPin from 'components/editor/hooks/useToolbarPin';
import { EditorVariant } from 'components/editor/types';
import { CreateStringUnionType } from 'types';
import { PlatformStructure } from 'types/graphqlTypes';

import variants from '../../constants/types/editorVariants';
import { getConditionalBlocks } from '../../utils/getConditionalToolbarBlocks';
import HoveringTooltip from '../hoveringTooltip';

import CmsToolbar from './components/cmsToolbar';
import FacebookToolbar from './components/facebookToolbar';
import GeneralToolbar from './components/generalToolbar';
import InstagramToolbar from './components/instagramToolbar';
import LinearToolbar from './components/linearToolbar';
import LinkedInToolbar from './components/linkedInToolbar';
import MessageToolbar from './components/messageToolbar';
import NotesToolbar from './components/notesToolbar';
import ScriptToolbar from './components/scriptToolbar/view';
import TwitterToolbar from './components/twitterToolbar';

import { Container, ToolbarWrapper } from './styled';

const {
  GENERAL,
  LINEAR,
  TWITTER,
  CMS,
  YOUTUBE,
  FACEBOOK,
  MESSAGE,
  NOTES,
  INSTAGRAM,
  LINKEDIN,
  DAILYNOTE,
  SCRIPT,
} = variants;

const preventDefault: React.MouseEventHandler<HTMLDivElement> = (event) => event.preventDefault();

const variantConditions = [GENERAL, TWITTER, CMS, YOUTUBE, FACEBOOK, INSTAGRAM];

interface ToolbarVariantProps {
  variant: EditorVariant;
  isAllowed: boolean;
  platformKind?: string;
  showDoneButton: boolean;
  extraBlocksByPlatform: string[] | undefined;
}

const ToolbarVariant = ({
  variant,
  isAllowed,
  platformKind,
  showDoneButton,
  extraBlocksByPlatform,
}: ToolbarVariantProps) => {
  switch (variant) {
    case CMS:
      return <CmsToolbar skipContainer={false} />;
    case NOTES:
      return <NotesToolbar />;
    case LINEAR:
      return <LinearToolbar platformKind={platformKind} />;
    case MESSAGE:
      return <MessageToolbar showDoneButton={showDoneButton} />;
    case TWITTER:
      return <TwitterToolbar extraBlocks={extraBlocksByPlatform} />;
    case FACEBOOK:
      return <FacebookToolbar extraBlocks={extraBlocksByPlatform} />;
    case INSTAGRAM:
      return <InstagramToolbar extraBlocks={extraBlocksByPlatform} />;
    case LINKEDIN:
      return <LinkedInToolbar extraBlocks={extraBlocksByPlatform} />;
    case SCRIPT:
      return <ScriptToolbar />;
    case GENERAL:
    case DAILYNOTE:
      return <GeneralToolbar isAllowed={isAllowed} />;
    default: {
      return (
        !isAllowed &&
        variantConditions.includes(variant as CreateStringUnionType<typeof variantConditions>) && (
          <GeneralToolbar />
        )
      );
    }
  }
};

export interface ToolbarProps {
  variant?: EditorVariant;
  readOnly: boolean;
  isAllowed: boolean;
  platformStructure?: PlatformStructure;
  isCmsBlock: boolean;
  toolbarPosition: 'top' | 'bottom' | 'none';
  platformKind?: string;
  disableGeneralToolbar: boolean;
  showDoneButton: boolean;
  showSidepanelButton: boolean;
  showHoveringTooltip: boolean;
  hostReadSpeed: number;
  writeLock: boolean;
  containerRef: React.RefObject<HTMLDivElement>;
}

const Toolbar = ({
  variant = GENERAL,
  readOnly,
  isAllowed,
  platformStructure,
  isCmsBlock,
  toolbarPosition,
  platformKind,
  disableGeneralToolbar,
  showDoneButton,
  showHoveringTooltip,
  hostReadSpeed,
  writeLock,
  containerRef,
}: Readonly<ToolbarProps>) => {
  const extraBlocksByPlatform = getConditionalBlocks(variant, platformStructure);
  const autoHeightVariants = [NOTES, LINEAR, CMS, DAILYNOTE];
  const { isPinned, togglePin } = useToolbarPin();

  if (variant === YOUTUBE) return null;

  return (
    <ToolbarWrapper>
      <Container
        onMouseDown={preventDefault}
        $readOnly={readOnly}
        $autoHeight={autoHeightVariants.includes(
          variant as CreateStringUnionType<typeof autoHeightVariants>,
        )}
        $disableGeneralToolbar={disableGeneralToolbar}
        $toolbarPosition={toolbarPosition}
      >
        {variant === CMS && isCmsBlock ? (
          <GeneralToolbar
            isAllowed={isAllowed}
            hideRightButtons
            extras={<CmsToolbar platformStructure={platformStructure} skipContainer={true} />}
          />
        ) : (
          <ToolbarVariant
            variant={variant}
            platformKind={platformKind}
            isAllowed={isAllowed}
            showDoneButton={showDoneButton}
            extraBlocksByPlatform={extraBlocksByPlatform}
          />
        )}
      </Container>
      {(isPinned || showHoveringTooltip) && (
        <HoveringTooltip
          hostReadSpeed={hostReadSpeed}
          writeLock={writeLock}
          isPinned={isPinned}
          togglePin={togglePin}
          containerRef={containerRef}
        />
      )}
    </ToolbarWrapper>
  );
};

export default memo(Toolbar);
