import { forwardRef } from 'react';

import { Button, type ButtonProps } from 'components/buttons';

interface LoadingButtonProps extends ButtonProps {
  title?: string | React.ReactElement;
}

/** Icon button, exported with the most used styles. Override them if needed. */
const IconButton = forwardRef<HTMLButtonElement, LoadingButtonProps>(
  ({ usage = 'text', variant = 'discreet', size = 'icon', children, ...rest }, ref) => {
    return (
      <Button usage={usage} variant={variant} size={size} ref={ref} {...rest}>
        {children}
      </Button>
    );
  },
);

IconButton.displayName = 'IconButton';

export { IconButton };
