import { useQuery } from '@apollo/client';

import GET_NOTES from 'operations/queries/getNotes';
import { getMemberQuery } from 'operations/queryVariables';
import { Note } from 'types';
import { GetNotesInput } from 'types/graphqlTypes';

export type GetNotesInputType = {
  input: GetNotesInput;
};

export type GetNotesReturnType = {
  getNotes: Note[];
};

/**
 * Fetches notes for a given story using a GraphQL query.
 *
 * @param parentId - The ID of the parent to fetch notes for.
 * @param skip - A boolean indicator to skip the query.
 * @returns An object containing the following properties:
 *   - data: An array of `Note` objects representing the fetched notes,
 * or an empty array if no notes are found.
 *   - loading: A boolean indicating whether the query is still loading.
 *   - error: An error object if there was an error fetching notes, otherwise null.
 */
const useGetNotes = (parentId: string, skip: boolean = false) => {
  const { data, loading, error } = useQuery<GetNotesReturnType, GetNotesInputType>(GET_NOTES, {
    variables: getMemberQuery(parentId),
    fetchPolicy: 'cache-and-network',
    skip,
  });

  return { data: data?.getNotes ?? ([] as Note[]), loading, error };
};

export default useGetNotes;
