import { memo } from 'react';

import Avatar, { AvatarProps, AvatarVariant } from 'components/avatar/Avatar';
import { User } from 'types';
import { getUserColor } from 'utils/userColor';

import { Container, OverflowText } from './styled';

interface AvatarGroupProps<T extends User = User>
  extends Pick<AvatarProps, 'size' | 'borderWidth' | 'borderColor'> {
  users: readonly T[];
  maxAvatarToShow?: number;
  onClick?: () => void;
  dark?: boolean;
  disabled?: boolean;
  overflow?: number;
  useUserTitleAsTooltip?: boolean;

  /**
   * Optional function to get the border color for each user's avatar
   * (defaults to `getUserColor(user)` if `borderColor` is set to `'automatic'` and
   * `borderColor` otherwise)
   */
  getUserBorderColor?: (user: T) => string | undefined;

  /** Optional function to get border width for a user (takes precedence over `borderWidth`) */
  getUserBorderWidth?: (user: T) => number;

  /** Optional function to get the opacity for a user */
  getUserOpacity?: (user: T) => number;

  /**
   * Optional function to get the key for each user's avatar (defaults to `user.mId`).
   * Must be specified if `users` contains users with the same `mId`.
   */
  getUserKey?: (user: T) => string;
}

function AvatarGroup<T extends User = User>({
  users,
  size = 32,
  borderWidth = 1.5,
  borderColor,
  onClick,
  dark,
  overflow = 0,
  useUserTitleAsTooltip,
  getUserBorderColor,
  getUserBorderWidth,
  getUserOpacity,
  getUserKey,
}: Readonly<AvatarGroupProps<T>>) {
  return (
    <Container $size={size} $overflow={overflow} onClick={onClick} $dark={dark}>
      {users.map((user) => (
        <Avatar
          key={getUserKey?.(user) ?? user.mId}
          variant={user.mType as AvatarVariant}
          size={size}
          imageKey={user.mAvatarKey}
          title={user.mTitle}
          borderWidth={getUserBorderWidth?.(user) ?? borderWidth}
          borderColor={
            getUserBorderColor?.(user) ??
            (borderColor !== 'automatic' ? borderColor : getUserColor(user))
          }
          opacity={getUserOpacity?.(user)}
          tooltipContent={useUserTitleAsTooltip ? user.mTitle : undefined}
        />
      ))}
      {overflow > 0 && (
        <OverflowText variant="caption" color={dark ? 'whiteMediumEmphasis' : 'mediumEmphasis'}>
          +{overflow}
        </OverflowText>
      )}
    </Container>
  );
}

export default memo(AvatarGroup) as typeof AvatarGroup;
