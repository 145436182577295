import { useMemo } from 'react';
import { useDrag } from 'react-dnd';
import styled from '@emotion/styled';
import { Skeleton } from '@material-ui/lab';

import defaultFallbackSrc from 'assets/images/default/defaultThumbnail.png';
import { ReactComponent as LargeAudioIcon } from 'assets/images/search/audio_on.svg';
import { ReactComponent as AudioIcon } from 'assets/images/search/audio_small.svg';
import { ReactComponent as LargeFileIcon } from 'assets/images/search/document_on.svg';
import { ReactComponent as FileIcon } from 'assets/images/search/document_small.svg';
import { ReactComponent as LargeFolderIcon } from 'assets/images/search/folder_on.svg';
import { ReactComponent as FolderIcon } from 'assets/images/search/folder_small.svg';
import { ReactComponent as LargeImageIcon } from 'assets/images/search/photo_on.svg';
import { ReactComponent as ImageIcon } from 'assets/images/search/photo_on_small.svg';
import { ReactComponent as LargeVideoIcon } from 'assets/images/search/video_on.svg';
import { ReactComponent as VideoIcon } from 'assets/images/search/video_small.svg';
import { Box, Flex } from 'layouts/box/Box';
import { Asset as AssetType } from 'types';
import dndTypes from 'utils/dndTypes';
import { formatDuration } from 'utils/formatDuration';
import getAssetThumbnail from 'utils/getAssetThumbnail';

const colorMap: Record<string, string> = {
  video: '#89B6F940',
  image: '#E272834D',
  audio: '#E459CE33',
  file: '#A0DBAF33',
  folder: 'rgba(255,255,255,.05)',
};

const MUISkeleton = styled(Skeleton)`
  height: 92px;
  width: 92px;
  border-radius: 6px;
`;

const Wrapper = styled(Box)`
  cursor: pointer;
  position: relative;
  height: 92px;
  width: 92px;
  border-radius: 6px;
  overflow: hidden;
`;

const Image = styled('img')`
  position: absolute;
  inset: 0;
  height: inherit;
  width: inherit;
  border-radius: inherit;
  object-fit: cover;
  overflow: hidden;
`;

export const PlaceholderThumbnail = styled(Flex)<{ $type: string }>`
  background: ${({ $type }) => colorMap[$type] ?? colorMap.file};
  border: 2px solid ${({ $type }) => colorMap[$type] ?? colorMap.file};
  position: absolute;
  inset: 0;
  border-radius: 6px;
  svg {
    margin: auto;
  }
`;

export const ThumbnailOverlay = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
`;

export const TypeIcon = styled('div')`
  width: 16px;
  height: 16px;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.5);
  bottom: 3px;
  left: 3px;
  position: absolute;
  svg {
    position: relative;
    left: 2px;
    top: -1px;
  }
`;

export const TimeCode = styled('div')`
  position: absolute;
  right: 3px;
  bottom: 3px;
  border-radius: 6px;
  background: rgba(0, 0, 0, 0.5);
  font-size: 9px;
  padding: 2px 3px 1px 3px;
`;

export const itemTypeToIconMap: Record<string, React.ReactElement> = {
  audio: <AudioIcon />,
  video: <VideoIcon />,
  image: <ImageIcon />,
  file: <FileIcon />,
  folder: <FolderIcon />,
};

export const itemTypeToLargeIconMap: Record<string, React.ReactElement> = {
  audio: <LargeAudioIcon />,
  video: <LargeVideoIcon />,
  image: <LargeImageIcon />,
  file: <LargeFileIcon />,
  folder: <LargeFolderIcon />,
};

export function AssetSkeleton() {
  return <MUISkeleton animation="wave" variant="rect" />;
}

function Asset({ asset }: Readonly<{ asset: AssetType }>) {
  const thumb = useMemo(() => getAssetThumbnail(asset), [asset]);

  const assetType = useMemo(() => asset?.mediaType?.split('/')[0] ?? '', [asset?.mediaType]);

  const duration = useMemo(
    () => asset?.mMetaData?.find((m) => m.key === 'itemDuration')?.value,
    [asset?.mMetaData],
  );

  const typeIcon = itemTypeToIconMap[assetType] ?? <FileIcon />;
  const largeTypeIcon = itemTypeToLargeIconMap[assetType] ?? <LargeFileIcon />;

  const [, dragRef] = useDrag({
    type: dndTypes.CLIP,
    item: () => ({
      type: dndTypes.CLIP,
      payload: {
        id: asset?.mRefId,
        title: asset?.mTitle,
        thumbnailUrl: asset?.mThumbnailUrl,
        duration: duration,
        provider: asset?.mMetaData?.find((m) => m.key === 'provider')?.value,
        itemState: asset?.mMetaData?.find((m) => m.key === 'itemState')?.value,
        assetType: asset?.mMetaData?.find((m) => m.key === 'assetType')?.value,
        itemType: asset?.itemType,
        mediaType: asset?.mediaType,
      },
    }),
    canDrag: () => !!asset,
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
  });

  return (
    <Wrapper ref={dragRef}>
      {typeof thumb === 'string' ? (
        <Image src={thumb ?? defaultFallbackSrc} />
      ) : (
        <PlaceholderThumbnail $type={assetType}>{largeTypeIcon}</PlaceholderThumbnail>
      )}
      <ThumbnailOverlay>
        <TypeIcon>{typeIcon}</TypeIcon>
        {duration && <TimeCode>{formatDuration(Number(duration))}</TimeCode>}
      </ThumbnailOverlay>
    </Wrapper>
  );
}

export default Asset;
