import React, { memo } from 'react';
import type {
  Cell,
  Column,
  ExpandedState,
  Row,
  Table,
  TableMeta,
  TableOptions,
  VisibilityState,
} from '@tanstack/react-table';
import { VirtualItem, Virtualizer } from '@tanstack/react-virtual';
import { Dictionary } from 'lodash';

import { User } from 'types';
import { Alternative } from 'types/graphqlTypes';

export interface DataTableMeta {
  meta?: {
    statusOptions?: Alternative[];
    userMap?: Dictionary<User>;
  };
}

export enum FilterVariant {
  TEXT = 'text',
  SELECT = 'select',
  MEMBER = 'member',
  STATUS = 'status',
  RANGE = 'range',
}

export const typedMemo: <T>(c: T) => T = memo;
export interface ColumnFilterProps<T> extends DataTableMeta {
  column: Column<T>;
}

export interface Option {
  label: string;
  value: string;
  icon?: React.ComponentType<{ className?: string }>;
  withCount?: boolean;
}

export interface DataTableFilterField<T> {
  label: string;
  value: keyof T;
  placeholder?: string;
  options?: Option[];
}

// ─── Dina Table ──────────────────────────────────────────────────────────────
export interface DinaTableOptions<T> extends Omit<TableOptions<T>, 'getCoreRowModel'> {
  /* Storage key for local storage */
  storageKey?: string;
  /** Enable keyboard shortcuts */
  enableKeyboardShortcuts?: boolean;
  /** Export table */
  exportTable?: (rows: Row<T>[]) => void;
  /** Enable row numbers */
  enableRowNumbers?: boolean;
  /** Filter fields for the table */
  filterFields?: DataTableFilterField<T>[];
  /** Initial column visibility. Stored states take priority */
  initialColumnVisibility?: VisibilityState;
}

export interface DinaTable<T> extends Omit<Table<T>, 'options'> {
  options: DinaTableOptions<T>;
}

export interface TableInstance<T> {
  table: DinaTable<T>;
  /** Optional filter component for each column */
  ColumnFilters?: React.ComponentType<ColumnFilterProps<T>>;
  children?: React.ReactNode;
}

export interface DataTableBody<T> {
  table: DinaTable<T>;
  onClick?: (prop: T) => void;
  containerRef: React.RefObject<HTMLDivElement>;
  enableDragging?: boolean;
}

export interface DataTableRows<T> {
  rows: Row<T>[];
  containerRef: React.RefObject<HTMLDivElement>;
}

export interface DataTableRow<T> {
  row: Row<T>;
  onClick?: (prop: T) => void;
  rowVirtualizer: Virtualizer<HTMLDivElement, Element>;
  virtualRow: VirtualItem;
  table: DinaTable<T>;
}

export type EmptyDataTableCell<T> = Pick<DataTableCell<T>, 'cell' | 'staticColumnIndex' | 'style'>;

export interface DataTableCell<T> {
  cell: Cell<T, unknown>;
  row: Row<T>;
  isSkeletonRow: boolean;
  table: DinaTable<T>;
  staticColumnIndex: number;
  rowSpan?: number;
  style?: React.CSSProperties;
  color?: string;
}

export interface TableRowWrapperProps<T> extends Pick<TableMeta<T>, 'rowMeta'> {
  rows: Row<T>[];
  measureElement: Virtualizer<HTMLDivElement, Element>['measureElement'];
  start: number;
  isSkeletonRow: boolean;
  /* Passed in as a prop to break row memoization */
  columnVisibility?: VisibilityState;
  expanded?: ExpandedState;
  selected?: Record<string, boolean>;
  table: DinaTable<T>;
  staticRowIndex: number;
  columnOrder?: string[];

  // Will render a draggable row if true
  enableDragging?: boolean;
}
export interface TableRowProps<T> extends Pick<TableMeta<T>, 'rowMeta'> {
  row: Row<T>;
  measureElement: Virtualizer<HTMLDivElement, Element>['measureElement'];
  start: number;
  isSkeletonRow: boolean;
  table: DinaTable<T>;
  staticRowIndex: number;
}
