import { useCallback, useContext, useMemo } from 'react';

import { isOlderSlateValue, migrateValue } from 'components/editor/utils';
import useToast from 'components/toast/useToast';
import UserContext from 'contexts/UserContext';
import { getContent } from 'hooks/useContentResolver';
import useCreateFolder from 'hooks/useCreateFolder';
import useDeleteFolder from 'hooks/useDeleteFolder';
import useDeleteTemplate from 'hooks/useDeleteTemplate';
import useGetContentTemplateFolders from 'hooks/useGetContentTemplateFolders';
import useGetPlatforms from 'hooks/useGetPlatforms';
import useSaveTemplate from 'hooks/useSaveTemplate';
import useUpdateSettings from 'hooks/useUpdateSettings';
import { EditorValue, Template } from 'types';
import { getUserIdFromLockedId } from 'utils/lock/lockTokenV2';
import { filter, IgnoredTemplateKeys } from 'utils/metadata';

import { useInstanceMolecule } from '../store/instance';

import { UpdateInputParameters } from './useInstanceCore';

const useInstanceTemplate = (
  saveAll: (params: UpdateInputParameters) => Promise<void>,
  cancelDebounce: () => void,
  resetEditorValue: (newValue: EditorValue) => void,
  handleLockInstance: () => Promise<string | undefined>,
) => {
  const { mId: currentUserId } = useContext(UserContext);
  const { editorValueRef, useInstanceValue, useMetadata } = useInstanceMolecule();

  const instance = useInstanceValue();
  const [metadata] = useMetadata();

  const { getPlatformVariant } = useGetPlatforms(instance?.mPublishingAt || new Date());

  const [defaultTemplateKey, defaultTemplateRefId] = useMemo(() => {
    if (!instance) return [undefined, undefined];
    const variant = getPlatformVariant(instance);
    return [variant?.defaultTemplateKey, variant?.defaultTemplate];
  }, [getPlatformVariant, instance]);

  const updateSettings = useUpdateSettings();
  const [deleteTemplate] = useDeleteTemplate();
  const [saveTemplate] = useSaveTemplate(editorValueRef);
  const [createFolder] = useCreateFolder();
  const [deleteFolder] = useDeleteFolder();

  const { errorToast } = useToast();

  const folders = useGetContentTemplateFolders(
    instance?.mProperties?.platform ?? '',
    instance?.mProperties?.platformKind ?? '',
  );

  const onSelectTemplate = useCallback(
    async ({ mContentKey }: { mContentKey: string }) => {
      if (!instance) return;
      try {
        let lockedResponse: string | null | undefined = instance.locked;

        /** lock instance if not already locked */
        if (!lockedResponse) {
          lockedResponse = await handleLockInstance();
          if (!lockedResponse) {
            errorToast(new Error('Failed to lock instance'), 'Failed to lock instance');
            return;
          }
        }

        /** check if template is locked by another user */
        if (getUserIdFromLockedId(lockedResponse) !== currentUserId) {
          errorToast(
            new Error('Template is locked by another user'),
            'Template is locked by another user',
          );
          return;
        }

        const s3content = await getContent(mContentKey, false, () => {}, undefined);

        if (!s3content) {
          errorToast(new Error('Template not found'), 'Template not found');
          return;
        }

        const content = isOlderSlateValue(s3content)
          ? (migrateValue(s3content, {}) as EditorValue)
          : s3content;
        const sanitizedContent = { ...content, properties: editorValueRef.current?.properties };

        const params: UpdateInputParameters = {
          content: sanitizedContent,
          instance: {
            ...instance,
            ...(content?.metadata && { mMetadata: content.metadata }),
          },
          version: 'template',
          metadata: filter(content?.metadata, {
            excludeKeys: IgnoredTemplateKeys,
            ignoreNull: true,
          }),
          audit: { source: 'useInstanceTemplate:onSelectTemplate' },
          autosave: false,
        };

        resetEditorValue(sanitizedContent);
        await saveAll(params);
        cancelDebounce();
      } catch (error) {
        if (error instanceof Error) {
          errorToast(error, error.message ?? 'Failed to load template');
        } else {
          errorToast(new Error('Failed to load template'), 'Failed to load template');
        }
      }
    },
    [
      cancelDebounce,
      currentUserId,
      editorValueRef,
      errorToast,
      handleLockInstance,
      instance,
      resetEditorValue,
      saveAll,
    ],
  );

  const onSaveTemplate = useCallback(
    async (
      folderId: string,
      templateTitle: string,
      description?: string,
      overwriteData?: Template,
    ) => {
      const newMProperties = {
        __typename: 'PlatformType',
        platform: instance?.mProperties?.platform,
        ...(instance?.mProperties?.platformKind && {
          platformKind: instance?.mProperties?.platformKind,
        }),
      };

      await saveTemplate({
        folderId,
        templateTitle,
        description,
        overwriteData,
        metadata,
        mProperties: newMProperties,
      });
    },
    [instance?.mProperties?.platform, instance?.mProperties?.platformKind, metadata, saveTemplate],
  );

  const onSetDefaultTemplate = useCallback(
    async (defaultTemplateId?: string) => {
      if (!defaultTemplateKey) return;
      await updateSettings({
        mId: 'settings',
        mRefId: 'general',
        mMetaData: [{ key: defaultTemplateKey, value: defaultTemplateId }],
      });
    },
    [updateSettings, defaultTemplateKey],
  );

  const onCreateFolder = useCallback(
    async (title: string, parentId: string) => {
      const updatedMProperties = {
        __typename: 'PlatformType',
        platform: instance?.mProperties?.platform,
        ...(instance?.mProperties?.platformKind && {
          platformKind: instance?.mProperties?.platformKind,
        }),
        pinned: false,
      };
      await createFolder(title, parentId, updatedMProperties);
    },
    [createFolder, instance?.mProperties?.platform, instance?.mProperties?.platformKind],
  );

  const onDeleteTemplate = useCallback(
    async (templateId: string, templateRefId: string) => {
      await deleteTemplate(templateId, templateRefId);
    },
    [deleteTemplate],
  );

  const onDeleteFolder = useCallback(
    async (folderId: string, folderRefId: string) => {
      await deleteFolder(folderId, folderRefId);
    },
    [deleteFolder],
  );

  return {
    onSelectTemplate,
    onSetDefaultTemplate,
    onSaveTemplate,
    onCreateFolder,
    onDeleteFolder,
    folders,
    defaultTemplateRefId,
    onDeleteTemplate,
  };
};

export default useInstanceTemplate;
