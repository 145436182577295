import React, { useState, useEffect, useRef } from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import { ReactComponent as Close } from 'assets/icons/systemicons/close_small.svg';
import PropTypes from 'prop-types';
import { InputLabel, FilledInput, IconButton, FormHelperText } from './TextAreaStyled';

const TextArea = ({
  label,
  description,
  severity,
  disabled,
  value,
  onChange,
  optionalLabel,
  type,
  subDescription,
  disableLabel,
  onBlur,
  onFocus,
  onKeyDown,
  lineHeight,
  multiline,
  direction,
  ...other
}) => {
  const [fieldTouched, setFieldTouched] = useState(false);
  const [renderLabel, setRenderLabel] = useState(label);
  const textAreaRef = useRef(null);

  const handleChangeValue = (event) => {
    event.preventDefault();
    onChange(event.target.value);
    setFieldTouched(event.target.value);
  };

  const handleBlur = (event) => {
    event.preventDefault();
    onChange(event.target.value);
    if (!event.target.value) {
      setRenderLabel(label);
    }
    onBlur(event);
  };

  const handleFocus = (event) => {
    event.preventDefault();
    if (!event.target.value) {
      setRenderLabel(optionalLabel);
    }
    onFocus(event);
  };

  useEffect(() => {
    if (value.length > 0) setRenderLabel(optionalLabel);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const returnUnderlineStyle = () => {
    if (severity === 'mandatory' && !fieldTouched) return `${severity}-touched`;
    return severity;
  };

  const handleClickIconButton = (event) => {
    event.preventDefault();
    onChange('');
    setFieldTouched(false);
    textAreaRef.current.focus();
  };

  const isEditorOrSoMe = type === 'editor' || type === 'SoMe';

  return (
    <FormControl
      variant="filled"
      error={severity === 'error'}
      disabled={disabled}
      dir={direction}
      fullWidth
    >
      {!disableLabel && (
        <InputLabel type={type} htmlFor={renderLabel} isEditorOrSoMe={isEditorOrSoMe}>
          {renderLabel}
        </InputLabel>
      )}
      <FilledInput
        underlineStyle={returnUnderlineStyle()}
        isEditorOrSoMe={isEditorOrSoMe}
        lineHeight={lineHeight}
        multiline={multiline}
        fullWidth
        data-testid="input"
        type="text"
        value={value}
        onChange={handleChangeValue}
        disableUnderline={disabled}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onKeyDown={onKeyDown}
        endAdornment={
          type === 'descriptionField' &&
          value && (
            <InputAdornment position="end">
              <IconButton tabIndex="-1" onClick={handleClickIconButton}>
                <Close />
              </IconButton>
            </InputAdornment>
          )
        }
        inputRef={textAreaRef}
        {...other}
      />
      {(description || subDescription) && (
        <FormHelperText type={type}>
          {description} {subDescription}
        </FormHelperText>
      )}
    </FormControl>
  );
};

TextArea.propTypes = {
  /** type of the text area according to usage i.e. editor descriptionField, SoMe */
  type: PropTypes.string,
  /** Label stating the purpose of the input field (text) */
  label: PropTypes.string,
  /** Optional label to show when text field focused */
  optionalLabel: PropTypes.string,
  /** Assistive help text shown at the bottom if defined (text)  */
  description: PropTypes.string,
  /** formatted description text to show beside description */
  subDescription: PropTypes.node,
  /** Values: regular, mandatory, error */
  severity: PropTypes.string,
  /** Values: true or false */
  disabled: PropTypes.bool,
  /** Value of the input field */
  value: PropTypes.string,
  /** onChange function */
  onChange: PropTypes.func,
  /** disables the label of textArea */
  disableLabel: PropTypes.bool,
  /** callback on blur */
  onBlur: PropTypes.func,
  /** callback on focus */
  onFocus: PropTypes.func,
  /** multiline */
  multiline: PropTypes.bool,
};

TextArea.defaultProps = {
  type: 'default',
  label: '',
  optionalLabel: '',
  description: undefined,
  subDescription: null,
  severity: 'regular',
  disabled: false,
  value: '',
  onChange: () => {},
  disableLabel: false,
  onBlur: () => {},
  onFocus: () => {},
  multiline: true,
};

export default TextArea;
