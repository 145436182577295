import {
  BeforeUnloadDocumentTraits,
  UnlockBeforeUnloadDocumentProps,
} from 'components/genericEditor/hooks/useBeforeUnloadDocument';
import { Script } from 'types';
import { AuditMessageInput } from 'types/graphqlTypes';

export const SCRIPT_TRAITS: BeforeUnloadDocumentTraits<Script> = {
  getDocumentId: (script) => script.mId,
  editorType: 'Script',
};
Object.freeze(SCRIPT_TRAITS);

/**
 * The `unlockScript` function returned from `useScriptLock` expects an audit property in the object
 * passed to its parameter, but the `useBeforeUnloadDocument` passes an object with a `source`
 * property.
 * This function transforms from {@link UnlockBeforeUnloadDocumentProps} to props
 * that can be used with the `unlockScript` function.
 * @param props The {@link UnlockBeforeUnloadDocumentProps}
 * @returns     The props for the unlock script mutation
 */
export function transformUnlockProps(
  props: UnlockBeforeUnloadDocumentProps,
): Omit<UnlockBeforeUnloadDocumentProps, 'source'> & { audit: AuditMessageInput } {
  const audit = { source: props.source };
  const unlockScriptProps = { ...props, audit };
  delete (unlockScriptProps as Record<string, unknown>).source;
  return unlockScriptProps;
}
