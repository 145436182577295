import { ReactNode } from 'react';

import { FieldTypeEnum } from 'types/graphqlTypes';

import {
  renderBlockQuoteBlock,
  renderCameraBlock,
  renderCheckListBlock,
  renderDefaultBlock,
  renderGraphicsBlock,
  renderHeaderBlock,
  renderHorizontalRuleBlock,
  renderLinkBlock,
  renderListBlock,
  renderLiveBlock,
  renderMdfBlock,
  renderMentionBlock,
  renderPackageBlock,
  renderParagraphBlock,
} from '../components/editorBlocks';
import {
  renderMdfCheckboxField,
  renderMdfChoiceField,
  renderMdfDateField,
  renderMdfDefaultField,
  renderMdfLinkField,
  renderMdfMultipleChoiceField,
  renderMdfRelationField,
  renderMdfTreeChoiceField,
  renderMdfUserField,
} from '../components/mdfBlocks';
import { DocumentComponentProps, RenderFieldProps } from '../types';
import { getFieldValue } from '../utils/getFieldValue';

export const getMdfFieldComponent = ({
  field,
  settingsMap,
  metadata,
  showBottomDivider,
  getMember,
  getMemberTitle,
  relationMembers = [],
  contacts = [],
  contactFieldsToPrint,
  subMdfs,
  groups,
  mdfsSeparated,
  optionLists,
  timezone,
}: RenderFieldProps) => {
  const label = settingsMap[field.fieldId].label;
  const fieldValue = getFieldValue(metadata, field.fieldId)?.toString() ?? '';

  if (!fieldValue || fieldValue.toString() === '') return null;

  switch (field.type) {
    case FieldTypeEnum.link:
      return renderMdfLinkField({
        key: field.fieldId,
        label,
        value: fieldValue?.toString() ?? '',
        showBottomDivider,
      });

    case FieldTypeEnum.user:
      return renderMdfUserField({
        field,
        label,
        value: fieldValue?.toString() ?? '',
        getMember,
        getMemberTitle,
        relationMembers,
        contacts,
        subMdfs,
        mdfsSeparated,
        showBottomDivider,
        groups,
        contactFieldsToPrint,
        optionLists,
      });

    case FieldTypeEnum.relation:
      return renderMdfRelationField({
        label,
        showBottomDivider,
        field,
        getMember,
        getMemberTitle,
        relationMembers,
        contacts,
        mdfsSeparated,
        subMdfs,
        groups,
        metadata,
        contactFieldsToPrint,
        optionLists,
      });

    case FieldTypeEnum.treechoice:
      return renderMdfTreeChoiceField({
        key: field.fieldId,
        label,
        value: fieldValue?.toString() ?? '',
        showBottomDivider,
      });

    case FieldTypeEnum.multiplechoice:
      return renderMdfMultipleChoiceField({
        key: field.fieldId,
        label,
        value: fieldValue?.toString() ?? '',
        showBottomDivider,
        alternatives: field.alternatives,
        optionListId: field.optionListId,
        optionLists,
      });

    case FieldTypeEnum.choice:
      return renderMdfChoiceField({
        key: field.fieldId,
        label,
        value: fieldValue?.toString() ?? '',
        showBottomDivider,
        alternatives: field.alternatives,
        optionListId: field.optionListId,
        optionLists,
      });

    case FieldTypeEnum.checkbox:
      return renderMdfCheckboxField({
        key: field.fieldId,
        label,
        value: fieldValue?.toString() ?? '',
        showBottomDivider,
      });

    case FieldTypeEnum.date:
      return renderMdfDateField({
        key: field.fieldId,
        label,
        value: fieldValue?.toString() ?? '',
        showBottomDivider,
        timezone,
      });

    case FieldTypeEnum.text:
    case FieldTypeEnum.number:
    default:
      return renderMdfDefaultField({
        key: field.fieldId,
        label,
        value: fieldValue?.toString() ?? '',
        showBottomDivider,
      });
  }
};

export const getDocumentComponent = (options: DocumentComponentProps): ReactNode => {
  const { doc } = options;
  const { type } = doc;

  switch (type) {
    case 'paragraph':
      return renderParagraphBlock(doc);

    case 'heading-one':
    case 'heading-two':
    case 'heading-three':
    case 'heading-four':
    case 'heading-five':
    case 'heading-six':
      return renderHeaderBlock(doc);

    case 'ordered-list':
    case 'unordered-list':
      return renderListBlock(doc);

    case 'block-quote':
      return renderBlockQuoteBlock(doc);

    case 'checklist':
      return renderCheckListBlock(doc);

    case 'link':
      return renderLinkBlock(doc);

    case 'horizontal-rule':
      return renderHorizontalRuleBlock();

    case 'mention':
      return renderMentionBlock(doc);

    case 'camera':
      return renderCameraBlock(doc);

    case 'live':
      return renderLiveBlock(doc);

    case 'overlayGraphics':
    case 'fullscreenGraphics':
      return renderGraphicsBlock(doc);

    case 'package':
    case 'voiceOver':
      return renderPackageBlock(doc);

    case 'mdf-block': {
      if (!('blocks' in options && 'orders' in options && 'mdfsSeparated' in options)) return null;

      return renderMdfBlock(options);
    }

    default:
      return renderDefaultBlock(doc);
  }
};
