import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { usePolicies } from 'store';
import { type ContentTab, useContentTabs, useMaxVisibleTabs } from 'store/tabs';
import { WithOptional } from 'types';
import { insertIntoArray, moveArrayItem } from 'utils/arrayUtils';
import { removeStoryLayoutByStoryId } from 'utils/storyHelper/storyHelper';

type ContentKey = keyof ContentTab;

const useTabs = () => {
  const [policies] = usePolicies();
  const navigate = useNavigate();
  const { id: currentId } = useParams();
  const [, setContentTabs] = useContentTabs();
  const [maxVisibleTabs] = useMaxVisibleTabs();

  const addTab = useCallback(
    (newTab: ContentTab) => {
      const { id } = newTab;
      setContentTabs((prev) => {
        const tabIndex = prev.findIndex((tab) => tab.id === id);
        if (tabIndex === -1) {
          if (prev.length >= maxVisibleTabs) {
            return insertIntoArray(prev, maxVisibleTabs - 1, newTab);
          } else {
            return [...prev, newTab];
          }
        } else if (tabIndex > maxVisibleTabs - 1) {
          return moveArrayItem(prev, tabIndex, maxVisibleTabs - 1);
        }
        return prev;
      });
    },
    [maxVisibleTabs, setContentTabs],
  );

  const updateTab = useCallback(
    (tab: Partial<ContentTab>) => {
      setContentTabs((prev) => {
        const tabToUpdate = prev.find((t) => t.id === tab.id);
        if (!tabToUpdate) return prev;

        const alreadyUpToDate = Object.keys(tab).every(
          (key) =>
            Object.hasOwn(tabToUpdate, key) &&
            tab[key as ContentKey] === tabToUpdate[key as ContentKey],
        );
        if (alreadyUpToDate) return prev;

        const updatedTab = {
          ...tabToUpdate,
          ...tab,
        };
        return prev.map((t) => (t.id === updatedTab.id ? updatedTab : t));
      });
    },
    [policies, setContentTabs],
  );

  const closeTab = useCallback(
    (tab: WithOptional<ContentTab, 'type'>) => {
      const { id } = tab;
      setContentTabs((prev) => {
        const tabIndex = prev.findIndex((t) => t.id === id);
        if (tabIndex === -1) return prev;

        if (tab.type === 'story') removeStoryLayoutByStoryId(id);

        const result = prev.filter((t) => t.id !== id);

        if (currentId === id) {
          const nextTabIndex = tabIndex > 0 ? tabIndex - 1 : tabIndex + 1;
          const nextTab = prev[nextTabIndex];
          navigate(nextTab ? `/${nextTab.type}/${nextTab.id}${nextTab?.search ?? ''}` : '/home');
        }
        return result;
      });
    },
    [currentId, navigate, setContentTabs],
  );

  const closeInactiveTabs = useCallback(() => {
    setContentTabs((prev) => prev.filter((t) => t.id === currentId));
  }, [currentId, setContentTabs]);

  const closeActiveTab = useCallback(() => {
    setContentTabs((prev) => {
      const tabIndex = prev.findIndex((t) => t.id === currentId);
      const nextTabIndex = tabIndex > 0 ? tabIndex - 1 : tabIndex + 1;
      const nextTab = prev[nextTabIndex];

      navigate(nextTab ? `/${nextTab.type}/${nextTab.id}${nextTab?.search ?? ''}` : '/home');

      return tabIndex >= 0 ? prev.toSpliced(tabIndex, 1) : prev;
    });
  }, [currentId, navigate, setContentTabs]);

  const closeOtherTabs = useCallback(
    (tab: ContentTab) => {
      setContentTabs((prev) => {
        const { type, id, search = '' } = tab;
        const tabIndex = prev.findIndex((t) => t.id === String(id));
        if (tabIndex === -1) return prev;

        if (currentId !== id) {
          navigate(`/${type}/${id}${search}`);
        }
        return [tab];
      });
    },
    [currentId, navigate, setContentTabs],
  );

  const closeAllTabs = useCallback(() => {
    setContentTabs([]);
    if (currentId) {
      navigate('/home');
    }
  }, [currentId, navigate, setContentTabs]);

  const closeLeftTabs = useCallback(
    (tab: ContentTab) => {
      setContentTabs((prev) => {
        const { type, id, search = '' } = tab;
        const tabIndex = prev.findIndex((t) => t.id === String(id));
        if (tabIndex === -1) return prev;

        navigate(`/${type}/${id}${search}`);
        return prev.slice(tabIndex);
      });
    },
    [navigate, setContentTabs],
  );

  const closeRightTabs = useCallback(
    (tab: ContentTab) => {
      setContentTabs((prev) => {
        const { type, id, search = '' } = tab;
        const tabIndex = prev.findIndex((t) => t.id === String(id));
        if (tabIndex === -1) return prev;

        navigate(`/${type}/${id}${search}`);
        return prev.slice(0, tabIndex + 1);
      });
    },
    [navigate, setContentTabs],
  );

  return {
    addTab,
    updateTab,
    closeTab,
    closeInactiveTabs,
    closeActiveTab,
    closeOtherTabs,
    closeAllTabs,
    closeLeftTabs,
    closeRightTabs,
  };
};

export default useTabs;
