import { useCallback } from 'react';

import { DateRange } from 'api/search';
import ScheduleDatePicker from 'components/createNewV3/datePicker/scheduleDatePicker';
import useToast from 'components/toast/useToast';
import useCheckUserRight from 'hooks/useCheckUserRight';
import usePublishDateUpdate from 'hooks/usePublishDateUpdate';
import { MemberType } from 'types/graphqlTypes';

import { GroupInline } from '../Group';

function Schedule({ story }: Readonly<{ story: MemberType }>) {
  const { errorToast } = useToast();
  const [checkUserRight] = useCheckUserRight();
  const canChangeScheduleTime = checkUserRight('story', 'schedule-story');
  const canSelectRange = checkUserRight('feature', 'story-date-range');
  const [updatePublishDate] = usePublishDateUpdate();

  const onDateRangeChange = useCallback(
    (dateRange: DateRange | null) => {
      updatePublishDate(
        story?.mId ?? '',
        dateRange?.startDate ?? null,
        dateRange?.endDate ?? null,
      ).catch((err) => errorToast(err, 'Unable to change schedule'));
    },
    [errorToast, story?.mId, updatePublishDate],
  );

  return (
    <GroupInline label="Schedule">
      <ScheduleDatePicker
        dateRange={
          story.mPublishingAt
            ? { startDate: story.mPublishingAt, endDate: story.mPublishingEnd ?? null }
            : null
        }
        onDateRangeChange={onDateRangeChange}
        disableUpdate={!canChangeScheduleTime}
        canSelectRange={canSelectRange}
        height={24}
        hideBorder
        hideCloseIcon
        transparentBackground
      />
    </GroupInline>
  );
}

export default Schedule;
