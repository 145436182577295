import { css } from '@emotion/react';
import styled from '@emotion/styled';

import Text from 'components/text';
import { Box, Flex, HStack, VStack } from 'layouts/box/Box';

const padding = css`
  padding-inline: 12px 12px;
`;

const Label = styled(Text)`
  ${padding};
  height: 28px;
  line-height: 28px;
  width: 100%;
`;

const MoreText = styled(Text)`
  ${padding};
  cursor: pointer;
  :hover {
    color: ${({ theme }) => theme.palette.dina.onSelected};
  }
`;

export function Empty({ label }: Readonly<{ label: string }>) {
  return (
    <Text variant="listItemLabelItalic" color="inactive" style={{ padding: '4px 12px' }}>
      {`No ${label}`}
    </Text>
  );
}

export function GroupInline({
  label,
  children,
}: Readonly<{ label: string; children: React.ReactElement }>) {
  return (
    <VStack width="100%">
      <HStack minHeight="32px" width="100%" justifyContent="space-between" padding="0 12px">
        <Text variant="overline" color="highEmphasis">
          {label}
        </Text>
        {children}
      </HStack>
    </VStack>
  );
}

function Group({
  children,
  label,
  collapsed,
  toggleCollapsed,
  moreCount,
  collapsedHeight = 96,
}: Readonly<{
  children: React.ReactElement;
  label: string;
  collapsed?: boolean;
  toggleCollapsed?: () => void;
  moreCount?: number;
  collapsedHeight?: number;
}>) {
  return (
    <VStack width="100%" padding="6px 0">
      <Label variant="h7" color="highEmphasis" truncate>
        {label}
      </Label>
      <Box maxHeight={collapsed ? `${collapsedHeight}px` : 'auto'} width="100%">
        {children}
      </Box>
      {collapsed !== undefined && (
        <Flex height="24px" justifyContent="flex-start">
          <MoreText variant="captionMedium" color="url" onClick={toggleCollapsed}>
            {collapsed
              ? `Show ${moreCount && moreCount > 0 ? `${moreCount} ` : ''} more`
              : 'Show less'}
          </MoreText>
        </Flex>
      )}
    </VStack>
  );
}

export default Group;
