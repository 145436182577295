import { useCallback } from 'react';
import { gql, useMutation } from '@apollo/client';

import useToast from 'components/toast/useToast';
import { CreateMdfInput, MdfType } from 'types/graphqlTypes';
import useLogger from 'utils/useLogger';

import { STANDARD_MDF_KEYS } from '../commonKeys';

import { writeMdfToCache } from './handleMdfCache';
import { toMdfDto } from './toMdfDto';

const CREATE_MDF = gql`
  mutation CreateMdf($input: CreateMdfInput) {
    createMdf(input: $input) {
      ${STANDARD_MDF_KEYS}
    }
  }
`;

interface CreateMdf {
  createMdf: MdfType;
}

const getErrorMessage = <E, I>(err: E, input: I): string =>
  `Could not create mdf: ${err instanceof Error ? err.message : ''} - input: ${JSON.stringify(
    input,
  )}`;

export const useCreateMdf = () => {
  const [createMutation] = useMutation<CreateMdf>(CREATE_MDF);
  const logger = useLogger('CreateMdf');
  const { errorToast } = useToast();
  const createMdf = useCallback(
    async (input: CreateMdfInput) => {
      try {
        const result = await createMutation({
          variables: {
            input,
          },
          update: (proxy, mutationResult) => {
            const newMdf = mutationResult.data?.createMdf;
            if (newMdf) {
              writeMdfToCache(proxy, newMdf);
            }
          },
        });
        return toMdfDto(result?.data?.createMdf as MdfType);
      } catch (err) {
        if (err instanceof Error) {
          errorToast(err, err.message);
          logger.log(getErrorMessage(err, input));
        }
      }
    },
    [createMutation],
  );
  return { createMdf };
};
