import { useMemo } from 'react';

import { useEditorKeyMap } from '../store/editor';
import { useGeneralKeyMap } from '../store/general';
import { useRundownActionKeyMap } from '../store/rundownAction';
import { useRundownViewKeyMap } from '../store/rundownView';
import { useStoryKeyMap } from '../store/story';

const useGetKeyMaps = () => {
  const [generalKeyMap] = useGeneralKeyMap();
  const [editorKeyMap] = useEditorKeyMap();
  const [storyKeyMap] = useStoryKeyMap();
  const [rundownViewKeyMap] = useRundownViewKeyMap();
  const [rundownActionKeyMap] = useRundownActionKeyMap();

  const keyMaps = useMemo(() => {
    return {
      General: generalKeyMap,
      Editor: editorKeyMap,
      'Story / Pitch': storyKeyMap,
      'Rundown (View)': rundownViewKeyMap,
      'Rundown (Action)': rundownActionKeyMap,
    } as const;
  }, [generalKeyMap, editorKeyMap, storyKeyMap, rundownViewKeyMap, rundownActionKeyMap]);

  return { keyMaps };
};

export default useGetKeyMaps;
