import { CustomElement } from 'types';
import { postMessage } from 'utils/postMessage';

export const allowedProviderSet = new Set(['Mimir', 'ampp']);

const mediaItemSets = new Set(['video', 'image', 'audio']);

const getMimirAssetItem = (element: Partial<CustomElement>) => {
  if (!element?.data) return null;
  const { data } = element;
  // fix for element structure is not consistent.
  if (data?.mProvider && allowedProviderSet.has(data.mProvider)) return data;
  const { assets = [] } = data ?? {};
  const asset = assets.find(({ itemType, assetType }) => {
    const hasItemType = itemType && mediaItemSets.has(itemType);
    const hasAssetType = assetType && mediaItemSets.has(assetType);
    return hasItemType || hasAssetType;
  });

  return asset?.mProvider && allowedProviderSet.has(asset?.mProvider) ? asset : null;
};

const openAssetInMimirLeftSidePanel = (element: Partial<CustomElement>) => {
  const assetItem = getMimirAssetItem(element);

  if (!assetItem) return;
  const { mAssetId, mProvider } = assetItem;
  const destination = mProvider === 'ampp' ? 'FLX' : 'Mimir';
  const message = {
    action: 'open_item',
    payload: mAssetId,
    destination,
  };
  postMessage(message, 'mimir');
};

export const isMimirAssetItem = (element: Partial<CustomElement>) => !!getMimirAssetItem(element);

export const openPreviewInProvider = (provider?: string) => provider === 'ampp';

export default openAssetInMimirLeftSidePanel;
