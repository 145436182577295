import styled from '@emotion/styled';
import { Checkbox } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { ReactComponent as Close } from 'assets/icons/systemicons/close.svg';
import { Box, VStack } from 'layouts/box/Box';
import transientOptions from 'theme/helpers';

export const StyledBox = styled(Box)`
  .dragHandle {
    top: 5px;
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  width: 30px;
`;

export const OptionWrapper = styled('div')`
  display: flex;
  gap: 6px;
  align-items: center;
  padding-bottom: 1px;
  height: 36px;

  .dragHandle {
    top: 5px !important;
  }
`;

export const StyledRemove = styled(Close, transientOptions)<{ $disabled?: boolean }>`
  cursor: ${({ $disabled }) => ($disabled ? 'default' : 'pointer')};

  flex-shrink: 0;
  path {
    fill-opacity: ${({ $disabled }) => ($disabled ? '0.3' : '1')};
  }
  &:hover path {
    fill-opacity: ${({ $disabled }) => ($disabled ? '0.3' : '1')} !important;
  }
`;

export const Root = styled(VStack)`
  padding-block: 8px;
  padding-inline: 16px;
`;

export const ListWrapper = styled('div')`
  width: 100%;
  height: 400px;
  margin-left: 8px;
`;

export const StyledFormControl = styled(FormControlLabel)`
  margin-left: 0;
  .MuiCheckbox-root {
    width: 22px;
    height: 22px;
    margin-right: 6px;
  }
  .MuiFormControlLabel-label {
    font-size: 13px;
  }
`;
