import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Chip } from '@material-ui/core';

export const TitleSection = styled('div')`
  border-radius: 8px 8px 0 0;
  user-select: text;
  min-height: 100px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 12px 16px 16px 16px;
`;

export const SectionChip = styled(Chip)`
  margin-top: 4px;
  margin-bottom: 8px;
  min-width: 40px;
  align-self: flex-start;
  background-color: ${({ theme }) => theme.palette.dina.chipBackground};
  .MuiChip-label {
    ${({ theme }) => css`
      ${theme.typography.dina.overline};
      color: ${theme.palette.dina.blackMediumEmphasis};
    `}
  }
`;

export const Metadata = styled('div')`
  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.palette.dina.dividerLight};
    border-top: 1px solid ${theme.palette.dina.dividerLight};
  `};
  min-height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 24px 0 16px;
  padding: 13px 0;
  flex: wrap;
`;
