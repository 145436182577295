import { memo, useCallback, useEffect, useMemo, useState } from 'react';

import { ReactComponent as ArrowRight } from 'assets/icons/systemicons/arrows/disclosurearrow_discreet_right.svg';
import { CollapsibleContent, CollapsibleTrigger } from 'lib/collapsible';

import { TreeItem } from './treeUtils';

import { Button, Collapsible, UnorderedList } from './styled';

const emptySelection = Object.freeze([]);

export type ContentRenderer = (item: TreeItem) => React.ReactNode;

interface TreeNodeProps {
  item: TreeItem;
  selectableFolders: boolean;
  fillWidth: true | undefined;
  depth: number;
  tabbable?: boolean;
  accordion?: boolean;
  /**
   * The path to the item withing this item that is selected (the first item will equal item.id)
   * or `undefined`if the selection is not within this item.
   */
  selectionPath: readonly string[] | undefined;
  updateSelectionPath: (path: readonly string[]) => void;
  contentRenderer: ContentRenderer;
}

/**
 * Renders a node in the `TreeView`
 */
export const MemoTreeNode = memo(function TreeNode({
  item,
  selectableFolders,
  fillWidth,
  depth,
  tabbable,
  accordion,
  selectionPath,
  updateSelectionPath,
  contentRenderer,
}: Readonly<TreeNodeProps>) {
  const [expanded, setExpanded] = useState(!!selectionPath);
  const tempInnerSel =
    (selectionPath?.length ?? 0) > 1 ? selectionPath?.toSpliced(0, 1) : undefined;
  const jsonInnerSel = JSON.stringify(tempInnerSel);
  const innerSelPath = useMemo(() => tempInnerSel, [jsonInnerSel]);
  const isFolder = !!item.children;
  const setSubSelectionPath = useCallback(
    (path: readonly string[]) => {
      updateSelectionPath(!path.length ? path : [item.id, ...path]);
    },
    [item.id, updateSelectionPath],
  );

  const isSelected = selectionPath?.length === 1;
  const containsSelected = !!selectionPath && !isSelected;

  const onClick = useCallback(() => {
    if (isFolder) {
      if (!selectableFolders && containsSelected && expanded) {
        // clear selection
        updateSelectionPath(emptySelection);
      } else if (selectableFolders && !isSelected) {
        updateSelectionPath([item.id]);
      }
      setExpanded(!expanded);
    } else if (!isSelected) {
      updateSelectionPath([item.id]);
    }
  }, [
    item.id,
    updateSelectionPath,
    selectableFolders,
    isFolder,
    isSelected,
    containsSelected,
    setExpanded,
    expanded,
  ]);

  // This effects is needed to open an already rendered node if selection is programmatically is
  // moved to an ancestor
  useEffect(() => {
    if (!expanded && containsSelected) setExpanded(true);
  }, [expanded, containsSelected, setExpanded]);

  if (!item.children) {
    // Render leaf node
    return (
      <li style={fillWidth && { width: '100%', overflowX: 'hidden' }}>
        <Button
          style={{ paddingLeft: `${depth * 24 + 28}px`, paddingRight: 0 }}
          selected={isSelected}
          variant="discreet"
          tabIndex={isSelected || tabbable ? 0 : -1}
          usage="text"
          align="left"
          radius="none"
          onClick={onClick}
        >
          {contentRenderer(item)}
        </Button>
      </li>
    );
  }

  // Render branch node
  return (
    <li style={fillWidth && { width: '100%', overflowX: 'hidden' }}>
      <Collapsible open={expanded}>
        <CollapsibleTrigger asChild>
          <Button
            style={{ paddingLeft: `${24 * depth}px`, paddingRight: 0 }}
            selected={isSelected}
            variant="discreet"
            usage="text"
            align="left"
            tabIndex={isSelected || tabbable ? 0 : -1}
            radius="none"
            onClick={onClick}
            data-child-selected={containsSelected}
          >
            <ArrowRight />
            {contentRenderer(item)}
          </Button>
        </CollapsibleTrigger>
        <CollapsibleContent>
          <UnorderedList>
            {item.children.map((child) => {
              return (
                <MemoTreeNode
                  key={child.id}
                  item={child}
                  depth={accordion ? depth : depth + 1}
                  selectableFolders={selectableFolders}
                  fillWidth={fillWidth}
                  selectionPath={innerSelPath?.[0] === child.id ? innerSelPath : undefined}
                  updateSelectionPath={setSubSelectionPath}
                  contentRenderer={contentRenderer}
                />
              );
            })}
          </UnorderedList>
        </CollapsibleContent>
      </Collapsible>
    </li>
  );
});
