import { useCallback, useEffect, useRef, useState } from 'react';

export function deferRevokeObjectUrl(url: string | null, ms = 0) {
  if (url) {
    setTimeout(() => URL.revokeObjectURL(url), ms);
  }
}

const useObjectUrl = () => {
  const [objectUrl, setObjectUrl] = useState<string | null>(null);
  const prevUrl = useRef<string | null>(null);

  const createOrUpdateObjectURL = useCallback(
    (source: File | Blob | MediaSource | null) => {
      if (prevUrl.current) {
        URL.revokeObjectURL(prevUrl.current);
      }

      const url = source ? URL.createObjectURL(source) : null;

      setObjectUrl(url);
      prevUrl.current = url;
      return url;
    },
    [setObjectUrl],
  );

  useEffect(() => {
    const urlToRevoke = objectUrl;

    return () => {
      if (urlToRevoke) {
        URL.revokeObjectURL(urlToRevoke);
      }
    };
  }, [objectUrl]);

  return { objectUrl, createOrUpdateObjectURL };
};

export default useObjectUrl;
