import { useCallback } from 'react';
import { MutationFetchPolicy, OptimisticStoreItem, useMutation } from '@apollo/client';

import UPDATE_RUNDOWN_SYNC from 'operations/mutations/updateRundownSync';
import { CrudActionEnum, MutationUpdateRundownSyncArgs } from 'types/graphqlTypes';
import useLogger from 'utils/useLogger';

interface UpdateRundownSyncType extends MutationUpdateRundownSyncArgs {
  fetchPolicy?: MutationFetchPolicy;
  optimisticResponse?: OptimisticStoreItem;
}

const useUpdateRundownSync = () => {
  const logger = useLogger();
  const [updateMutation] = useMutation(UPDATE_RUNDOWN_SYNC);

  const updateRundownSync = useCallback(
    async ({ input, fetchPolicy, optimisticResponse }: UpdateRundownSyncType) => {
      if (!input) return;

      try {
        await updateMutation({
          variables: { input: { ...input, mPayload: [{ crudAction: CrudActionEnum.Update }] } },
          fetchPolicy: fetchPolicy ?? 'network-only',
          optimisticResponse,
        });
      } catch (err) {
        logger.log(err);
      }
    },
    [updateMutation],
  );

  return { updateRundownSync };
};

export default useUpdateRundownSync;
