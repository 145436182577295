import { useEffect, useState } from 'react';

import useUpdateOrganization from 'api/organization/useUpdateOrganization';
import AutoComplete from 'components/autoCompleteBase/Autocomplete';
import { StyledTextField } from 'components/mdfEditor/fields/text/styled';
import useToast from 'components/toast/useToast';
import useInputEvents from 'hooks/useInputEvents';
import { Box, VStack } from 'layouts/box/Box';
import type { Organization, UpdateOrganizationInput } from 'types/graphqlTypes';

import useIsSuperUser from '../../useIsSuperUser';

const timezones = Intl.supportedValuesOf('timeZone').map((timezone) => ({
  id: timezone,
  value: timezone,
}));

const Title = ({
  title: initialTitle,
  isSuperUser,
  update,
}: {
  title?: string;
  isSuperUser: boolean;
  update: <K extends keyof UpdateOrganizationInput>(
    key: K,
    value: UpdateOrganizationInput[K],
  ) => void;
}) => {
  const [title, setTitle] = useState<string>(initialTitle ?? '');

  useEffect(() => {
    if (title !== initialTitle) setTitle(initialTitle ?? '');
  }, [initialTitle]);

  const updateTitle = (value: string) => {
    if (value !== initialTitle) update('title', value);
  };

  const [titleRef, onTitleKeyDown, onTitleBlur] = useInputEvents(
    updateTitle,
    title,
    initialTitle ?? '',
  );

  return (
    <>
      <div>Organization name</div>
      <StyledTextField
        ref={titleRef}
        error={false}
        variant="filled"
        fullWidth
        placeholder="Type organization name"
        value={title}
        onKeyDown={onTitleKeyDown}
        onBlur={onTitleBlur}
        onChange={(event) => setTitle(event.target.value)}
        InputProps={{ readOnly: !isSuperUser }}
      />
    </>
  );
};

const Description = ({
  description: initialDescription,
  isSuperUser,
  update,
}: {
  description?: string;
  isSuperUser: boolean;
  update: <K extends keyof UpdateOrganizationInput>(
    key: K,
    value: UpdateOrganizationInput[K],
  ) => void;
}) => {
  const [description, setDescription] = useState(initialDescription ?? '');

  useEffect(() => {
    if (description !== initialDescription) setDescription(initialDescription ?? '');
  }, [initialDescription]);

  const updateDescription = (value: string) => {
    if (value !== initialDescription) update('description', value);
  };
  const [descriptionRef, onDescriptionKeyDown, onDescriptionBlur] = useInputEvents(
    updateDescription,
    description,
    initialDescription ?? '',
  );

  return (
    <>
      <div>Organization description</div>
      <StyledTextField
        ref={descriptionRef}
        error={false}
        variant="filled"
        fullWidth
        placeholder="Type organization description"
        value={description}
        onKeyDown={onDescriptionKeyDown}
        onBlur={onDescriptionBlur}
        onChange={(event) => setDescription(event.target.value)}
        InputProps={{ readOnly: !isSuperUser }}
        multiline
        minRows={5}
        maxRows={5}
      />
    </>
  );
};

const TimeZone = ({
  timeZone: initialTimeZone,
  isSuperUser,
  update,
}: {
  timeZone?: string;
  isSuperUser: boolean;
  update: <K extends keyof UpdateOrganizationInput>(
    key: K,
    value: UpdateOrganizationInput[K],
  ) => void;
}) => {
  const [timeZone, setTimeZone] = useState(initialTimeZone ?? '');

  useEffect(() => {
    if (timeZone !== initialTimeZone) setTimeZone(initialTimeZone ?? '');
  }, [initialTimeZone]);

  const onTimeZoneChange = (value: string) => {
    if (value && value !== initialTimeZone) update('timeZone', value);
  };

  return (
    <>
      <div>Default timezone</div>
      <Box width="100%">
        <AutoComplete
          onChange={onTimeZoneChange}
          options={timezones}
          value={timeZone}
          disabled={!isSuperUser}
        />
      </Box>
    </>
  );
};

function Details({ id, title, description, timeZone }: Readonly<Organization>) {
  const { errorToast } = useToast();
  const { updateOrganization } = useUpdateOrganization();

  const isSuperUser = useIsSuperUser();

  const update = <K extends keyof UpdateOrganizationInput>(
    key: K,
    value: UpdateOrganizationInput[K],
  ) => {
    if (!isSuperUser) return;
    const updateOrgInput: UpdateOrganizationInput = { id };
    updateOrgInput[key] = value;
    updateOrganization(updateOrgInput).catch((error) => errorToast(error));
  };

  return (
    <VStack gap="4px" justifyContent="flex-start" alignItems="flex-start">
      <Title title={title} isSuperUser={isSuperUser} update={update} />
      <Description description={description} isSuperUser={isSuperUser} update={update} />
      <TimeZone timeZone={timeZone} isSuperUser={isSuperUser} update={update} />
    </VStack>
  );
}

export default Details;
