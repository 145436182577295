import { Autocomplete } from '@material-ui/lab';

import { ReactComponent as Schema } from 'assets/icons/systemicons/schema.svg';
import { StyledOptionWrapper, StyledPopper } from 'components/mdfEditor/fields/choice/styled';
import { StyledTextField } from 'components/mdfEditor/fields/text/styled';
import { HStack } from 'layouts/box/Box';
import { Alternative } from 'types/graphqlTypes';
import { getPlatformIcon } from 'utils/instance/platform';

import { MdfCreationGroup } from './CreateSchemaDialog';

interface Props {
  schemaGroup: MdfCreationGroup;
  placeholder?: string;
  menuOptions: Alternative[];
  selectedOption: Alternative | null;
  onSelect: (alt: Alternative) => void;
  disableFn?: (val: string) => boolean;
}

export default function CreateSchemaDropdown({
  schemaGroup,
  placeholder = 'Select',
  selectedOption,
  menuOptions,
  onSelect,
}: Readonly<Props>) {
  const renderInput = (params: object) => (
    <StyledTextField {...params} variant="filled" placeholder={placeholder} />
  );

  const renderOption = (alternative: Alternative) => {
    const Icon =
      schemaGroup === 'blocks' ? Schema : getPlatformIcon(alternative.icon ?? alternative.id);
    return (
      <StyledOptionWrapper $selected={selectedOption?.id === alternative.value}>
        <HStack gap="6px" alignItems="center">
          <Icon style={{ width: '16px' }} />{' '}
          <div>{alternative.label ?? alternative.value ?? 'Invalid option!'}</div>
        </HStack>
      </StyledOptionWrapper>
    );
  };

  return (
    <Autocomplete
      key={selectedOption?.id}
      openOnFocus
      disableClearable
      fullWidth
      blurOnSelect
      noOptionsText="All available options have been configured"
      selectOnFocus={false}
      options={menuOptions}
      value={selectedOption ?? undefined}
      getOptionSelected={(alternative: Alternative, v: Alternative) => {
        if (!v) return false;
        return alternative.value === v.value;
      }}
      onChange={(_ev, alternative) => onSelect(alternative)}
      renderInput={renderInput}
      renderOption={renderOption}
      PopperComponent={StyledPopper}
      getOptionLabel={(alternative) => alternative?.label ?? alternative?.value}
    />
  );
}
