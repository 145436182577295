import React, { memo, ReactNode, useCallback, useMemo } from 'react';
import styled from '@emotion/styled';
import { RenderElementProps, useReadOnly } from 'slate-react';

import { elementTypes, urlPrefix } from 'components/editor/constants';
import getCleanLink from 'components/editor/utils/getCleanLink';
import useToast from 'components/toast/useToast';
import useCheckUserRight from 'hooks/useCheckUserRight';
import { CustomElement } from 'types';

import getInstanceIdFromLink from '../../utils/getInstanceIdFromLink';

import LinkedInstance from './components/linkedInstance';

const target = '_blank';

const StyledAnchor = styled('a')`
  color: ${({ theme }) => theme.palette.dina.url};
`;

function Link({
  attributes,
  children = null,
  element = {
    type: elementTypes.LINK,
    data: { href: '' },
    children: [],
  },
}: {
  attributes: RenderElementProps['attributes'];
  children: ReactNode;
  element: CustomElement;
}) {
  const { href } = element.data || {};
  const [checkUserRight] = useCheckUserRight();
  const showLinkedInstancePreview = checkUserRight('feature', 'instance-link');
  const isReadOnly = useReadOnly();
  const { errorToast } = useToast();

  const onLinkClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      e.stopPropagation();

      try {
        if (!href) return;
        window.open(getCleanLink(href), target);
      } catch (error) {
        errorToast('Failed to open link');
      }
    },
    [href, errorToast],
  );

  const memoizedLink = useMemo(() => {
    if (
      showLinkedInstancePreview &&
      href &&
      href.startsWith(urlPrefix.LINKED_INSTANCE_HREF_PREFIX)
    ) {
      return (
        <LinkedInstance
          instanceId={getInstanceIdFromLink(href)}
          attributes={attributes as unknown as Record<string, string>}
          shouldShowPreviewOnClick={isReadOnly}
        >
          {children}
        </LinkedInstance>
      );
    }

    return (
      <StyledAnchor
        rel="noopener noreferrer"
        role="presentation"
        href={getCleanLink(href ?? '')}
        target={target}
        {...attributes}
        onClick={isReadOnly ? onLinkClick : undefined}
      >
        {children}
      </StyledAnchor>
    );
  }, [children, href, showLinkedInstancePreview, attributes, onLinkClick, isReadOnly]);

  return memoizedLink;
}

export default memo(Link);
