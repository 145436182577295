import { useCallback } from 'react';
import { ApolloCache, FetchResult, useMutation } from '@apollo/client';

import ADD_USER_TO_ORGANIZATION from 'operations/mutations/addUserToOrganization';
import GET_ORGANIZATION_USERS from 'operations/queries/getOrganizationUsers';
import type { AddUserToOrgInput, OrganizationUser } from 'types/graphqlTypes';

import { FetchOrganizationsUsers } from './useGetOrganizationUsers';

interface AddUserToOrganization {
  addUserToOrg: OrganizationUser;
}

function useAddUserToOrganization() {
  const [addUser, { loading, error }] = useMutation<
    AddUserToOrganization,
    { input: AddUserToOrgInput }
  >(ADD_USER_TO_ORGANIZATION);

  const updateCache = useCallback(
    (
      cache: ApolloCache<unknown>,
      mutationResult: Omit<FetchResult<AddUserToOrganization>, 'context'>,
      userId: string,
      orgId: string,
    ) => {
      if (!mutationResult.data?.addUserToOrg) return;
      const orgCacheResult = cache.readQuery<FetchOrganizationsUsers>({
        query: GET_ORGANIZATION_USERS,
        variables: { input: { orgId } },
      });
      if (orgCacheResult?.getOrganizationUsers) {
        cache.writeQuery<FetchOrganizationsUsers>({
          query: GET_ORGANIZATION_USERS,
          variables: { input: { orgId } },
          data: {
            getOrganizationUsers: [
              mutationResult.data?.addUserToOrg,
              ...orgCacheResult.getOrganizationUsers.filter((u) => u.id !== userId),
            ],
          },
        });
      }
    },
    [],
  );

  const addUserToOrganization = useCallback(
    async (userId: string, orgId: string, groups?: string[]) => {
      const result = await addUser({
        variables: { input: { userId, orgId, groups } },
        update: (cache, mutationResult) => updateCache(cache, mutationResult, userId, orgId),
      });
      return result.data?.addUserToOrg;
    },
    [addUser, updateCache],
  );

  return { addUserToOrganization, loading, error };
}

export default useAddUserToOrganization;
