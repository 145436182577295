import { atom, useAtom } from 'jotai';

import { KeyMap } from 'types/keyboardShortcut';

const story = {
  CREATE_INSTANCE: {
    label: 'Create Instance, Note or Task',
    tooltip: 'Create Instance, Note or Task',
    sequences: ['mod+I'],
  },
} satisfies Record<string, KeyMap>;

const storyAtom = atom<Record<keyof typeof story, KeyMap>>(story);

export const useStoryKeyMap = () => useAtom(storyAtom);
