import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';

import useUpdateLeftSidebarCache from 'hooks/useUpdateLeftSidebarCache';
import memberTypes from 'operations/memberTypes';

const UNARCHIVE_MEMBER = gql`
  mutation UnarchiveStory($input: ArchiveStoryInput) {
    unarchiveMember(input: $input) {
      mId
    }
  }
`;

const getUpdatedMemberType = (memberType: string) => {
  if (memberType === memberTypes.ARCHIVED_STORY) return memberTypes.STORY;
  if (memberType === memberTypes.ARCHIVED_PITCH) return memberTypes.PITCH;
  if (memberType === memberTypes.ARCHIVED_RESTRICTED_STORY) return memberTypes.RESTRICTED_STORY;
  if (memberType === memberTypes.ARCHIVED_RESTRICTED_PITCH) return memberTypes.RESTRICTED_PITCH;
  return memberType;
};

const useUnarchiveMember = () => {
  const [mutate] = useMutation(UNARCHIVE_MEMBER);
  const [update] = useUpdateLeftSidebarCache();

  const unarchive = useCallback(
    async (mId: string, mType: string) => {
      await mutate({
        variables: {
          input: {
            mId,
          },
        },
        update: (cache) => {
          cache.modify({
            id: cache.identify({ mId: mId, mRefId: mId }),
            fields: { mType: () => getUpdatedMemberType(mType) },
          });
          update({ mId, mRefId: mId }, mType, false);
        },
      });
    },
    [mutate, update],
  );

  return [unarchive];
};

export default useUnarchiveMember;
