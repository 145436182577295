import { RundownPlayStateUtils } from './rundownStates';
import { RundownInstanceTiming, RundownTimingInfo } from './rundownTiming';

/**
 * Given a duration in "hh:mm:ss, "mm:ss" or "ss" formats,
 * the function returns total duration in seconds
 * Returns 0 if NaN or empty string
 *
 * @param {string|undefined} time string representation "hh:mm:ss, "mm:ss" or "ss" formats
 * @returns {number} duration in seconds
 */

export const timeToSeconds = (time?: string): number => {
  if (!time) return 0;
  const [b1, b2, b3] = time.split(':');
  let val = 0;
  if (b3) val = Number(b1) * 3600 + Number(b2) * 60 + Number(b3);
  else if (b2) val = Number(b1) * 60 + Number(b2);
  else val = Number(b1);
  return isNaN(val) ? 0 : val;
};

/**
 * Given a duration in seconds, the function returns string representation in "mm:ss" format
 *
 * @param {number} seconds represented as integer number
 * @returns {string} representation of the seconds in "mm:ss" format
 */
export const timeFromSeconds = (seconds: number): string => {
  const isNegative = seconds < 0;
  const absoluteSeconds = Math.abs(seconds);

  const hours = Math.floor(absoluteSeconds / 3600);
  const remainingAfterHours = absoluteSeconds % 3600;
  const minutes = Math.floor(remainingAfterHours / 60);
  const remainingSeconds = remainingAfterHours % 60;

  const formattedMinutes = minutes.toString().padStart(2, '0'); // Two digits for minutes
  const formattedSeconds = remainingSeconds.toString().padStart(2, '0'); // Two digits for seconds

  return [
    isNegative ? '-' : '',
    hours > 0 ? `${hours}:` : '',
    `${formattedMinutes}:${formattedSeconds}`,
  ].join('');
};

/**
 * Given input as a Date instance, the function returns the time in
 * hh:mm:ss according to local time
 * @param {string|Date|number} date - The input date/time to convert. It can be:
 *   - **Date**: A JavaScript `Date` instance.
 *   - **string**: A string representing a date and time (e.g., "2024-12-15T12:34:56Z").
 *   - **number**: A timestamp in milliseconds since the UNIX epoch (e.g., `1700000000000`).
 *   - If the input cannot be parsed into a valid `Date`, the function will return an empty string.
 * @returns {string}
 *  - A string representing the time in `hh:mm:ss` format.
 *    If the input is invalid, an empty string is returned
 */
export const DateToTimeString = (date: Date | string | number): string => {
  const tDate = new Date(date); // Allows date to be a date formatted string
  if (isNaN(tDate.getTime())) return '';

  const hhs = String(tDate.getHours()).padStart(2, '0');
  const mms = String(tDate.getMinutes()).padStart(2, '0');
  const sss = String(tDate.getSeconds()).padStart(2, '0');

  return `${hhs}:${mms}:${sss}`;
};

export const isValidTimeString = (timeString: string | null | undefined): boolean => {
  if (!timeString) return false;
  const hhmmss = timeString.split(':');
  return hhmmss.length > 1;
};

/**
 * Given input as a time in hh:mm:ss according to local time,
 * the function returns the corresponding time as a Date() instance
 * @param {string} timestr
 */
export const TimeStringToDate = (timestr: string): Date => {
  const date = new Date();
  const hhmmss = timestr?.split(':') || ['0'];
  if (hhmmss.length > 2) {
    date.setHours(Number(hhmmss[0]));
    date.setMinutes(Number(hhmmss[1]));
    date.setSeconds(Number(hhmmss[2]));
  } else if (hhmmss.length > 1) {
    date.setMinutes(Number(hhmmss[0]));
    date.setSeconds(Number(hhmmss[1]));
  } else {
    date.setSeconds(Number(hhmmss[0]));
  }
  date.setMilliseconds(0);
  return date;
};

/**
 * Given two duration in "mm:ss" format, the function returns sum of two durations in "mm:ss"
 * format
 *
 * @param {string} duration1 represented in "mm:ss" format
 * @param {string} duration2 represented in "mm:ss" format
 * @returns {string} representation of the sum of duration1 and duration2 in "mm:ss" format
 */
export const addDuration = (duration1: string, duration2: string): string => {
  const var1 = timeToSeconds(duration1 || '00:00');
  const var2 = timeToSeconds(duration2 || '00:00');
  return timeFromSeconds(var1 + var2);
};

/**
 * Given two duration in "mm:ss" format, the function returns subtract of two durations in "mm:ss"
 * format
 *
 * @param {string} duration1 represented in "mm:ss" format
 * @param {string} duration2 represented in "mm:ss" format
 * @returns {string} representation of the subtract of duration1 and duration2 in "mm:ss" format
 * with corresponding sign
 */
export const subtractDuration = (duration1: string, duration2: string): string => {
  const var1 = timeToSeconds(duration1 || '00:00');
  const var2 = timeToSeconds(duration2 || '00:00');
  return `${var1 > var2 ? '-' : ''}${timeFromSeconds(Math.abs(var2 - var1))}`;
};

/**
 * Interface for the calculated rundown timing fields.
 */
export interface RundownTimingFields {
  /** Rundown start time */
  startTime?: Date;
  /**
   * Timestamp when the rundown timing fields was last updated
   * Only set if cumetime or backtime is set.
   * Used to determine if UX timing fields should be updated
   */
  lastUpdate: string;
  /**
   * Array of timing information for all instances in the rundown
   */
  timingFields: RundownInstanceTiming[];
  /** Rundown timing information */
  rundownTimes?: RundownTimingInfo;
  /** Time for next break. In seconds since Unix epoch. */
  nextBreakTime: number;
  /** Time for last break. In seconds since Unix epoch. */
  lastBreakTime: number;
  /** Current duration of next segment. In seconds */
  currentSegmentDuration: number;
  /** Current rundown duration. In seconds */
  currentRundownDuration: number;
  /** Current instance onair. Only set when rundown is playing */
  currentInstanceId?: string;
  /** Current instance duration in seconds. Only set when rundown is playing */
  currentInstanceDur?: number;
  /** Current instance starttime in UTC. Only set when rundown is playing */
  currentInstanceStart?: string;
}

/**
 * @returns Initial rundown timing fields
 */
export const getInitialRundownTimingFields = (): RundownTimingFields => {
  return {
    startTime: undefined,
    currentInstanceDur: undefined,
    currentInstanceStart: undefined,
    currentInstanceId: undefined,
    lastUpdate: '',
    timingFields: [],
    rundownTimes: undefined,
    nextBreakTime: 0,
    lastBreakTime: 0,
    currentSegmentDuration: 0,
    currentRundownDuration: 0,
  };
};

/**
 * Returns the timestamp for the local midnight time of the current day.
 * @returns {number} The timestamp in milliseconds for midnight today in local time.
 */
const getLocalMidnightTimestamp = (): number => {
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);
  return currentDate.getTime();
};

/**
 * Returning the next instance (field) in the rundown sequence that is not floated.
 * @param {RundownInstanceTiming[]} rundownTimingFields -
 *   An array of timing fields (instances) to search through.
 * @param {number} index - The current index to start the search from.
 * @returns {RundownInstanceTiming | undefined} -
 *   The next timing field that is not marked as a float.
 *   Returns undefined if no such field exists.
 */
const getNextField = (
  rundownTimingFields: RundownInstanceTiming[],
  index: number,
): RundownInstanceTiming | undefined => {
  for (let i = index + 1; i < rundownTimingFields.length; i++) {
    if (!rundownTimingFields[i].isFloat) return rundownTimingFields[i];
  }
  return undefined;
};

/**
 * Represents the state for cumulative time calculations.
 */
interface CumeTimeState {
  /** Accumulated cume-time in milliseconds since Unix epoch */
  accCumeTime: number;
  /** Indicates whether cumulative time should be displayed in the UX. */
  showCumeTime: boolean;
}

/**
 * Configuration options for `accCumeTime`.
 */
interface AccCumeTimeOptions {
  /** If `true`, treats the cumulative time as absolute. This will reset the accumulated time. */
  isAbsolute?: boolean;

  /** If `true`, marks the cumulative time as fixed. Shown as bold orange in UX*/
  isFixed?: boolean;
}

/**
 * Accumulates cumulative time based on the provided timing field and updates the state.
 *
 * @param {RundownInstanceTiming} tField - Current timing field
 * @param {CumeTimeState} state - The cumulative time state to update.
 * @param {AccCumeTimeOptions} [options] - Optional configuration options.
 * @returns {CumeTimeState} - The updated cumulative time state.
 */
const accCumeTime = (
  tField: RundownInstanceTiming,
  state: CumeTimeState,
  options: AccCumeTimeOptions = {},
): CumeTimeState => {
  const { isAbsolute = false, isFixed = false } = options;
  const instanceDuration = 1000 * tField.dur;
  if (tField.isCumeTimeAbsolute || isAbsolute) {
    tField.isCumeTimeFixed = tField.isCumeTimeAbsolute || isFixed;
    state.accCumeTime = tField.cumeTimeMs! + instanceDuration;
    state.showCumeTime = true;
  } else if (state.showCumeTime) {
    tField.cumeTime = DateToTimeString(state.accCumeTime);
    tField.cumeTimeMs = state.accCumeTime;
    state.accCumeTime += instanceDuration;
  } else {
    tField.cumeTime = '-';
    state.accCumeTime += instanceDuration;
  }
  return state;
};

/**
 * Sets the cumulative time of a timing field based on the instance start time.
 *
 * @param {RundownInstanceTiming} tField - The timing field to update.
 * @param {string} instanceStartTime - The ISO string representing the start time of the instance.
 */
const setCumeTime = (tField: RundownInstanceTiming, instanceStartTime: string) => {
  const tStart = new Date(instanceStartTime);
  tField.cumeTime = DateToTimeString(tStart);
  tField.cumeTimeMs = tStart.getTime();
};

/**
 * Returns the index of the last instance having a defined hard-hit backtime.
 * If no hard-hit backtimes are defined, the function returns the length of the array.
 * @param {RundownInstanceTiming[]} rundownTimingFields -
 *   Array of timing information for all instances in the rundown
 * @returns {number} - Index of the last instance having a defined hard-hit backtime.
 */
const findLastHardHitBackTimeInstance = (rundownTimingFields: RundownInstanceTiming[]): number => {
  if (!Array.isArray(rundownTimingFields) || !rundownTimingFields.length) return 0;

  const ixLast = rundownTimingFields.findLastIndex(
    (tField) => tField.isBackTimeAbsolute && !tField.isFloat,
  );

  return ixLast >= 0 ? ixLast : rundownTimingFields.length;
};

/**
 * Calculate rundown cume-time and back-time and returns a timing object with fields of timings.
 * @param {RundownInstanceTiming[]} rundownTimingFields -
 *   Array of timing information for all instances in the rundown
 * @param {RundownTimingInfo} rundownProperties -
 *   Rundown timing information for the rundown sequence
 * @returns {RundownTimingFields} -
 *   The calculated rundown timing fields used by the Ux header / grid
 */
export const calculateRundownTimingFields = (
  rundownTimingFields: RundownInstanceTiming[],
  rundownTimes: RundownTimingInfo,
): RundownTimingFields => {
  if (!rundownTimes || !Array.isArray(rundownTimingFields) || !rundownTimingFields.length) {
    return getInitialRundownTimingFields();
  }

  const {
    startTime,
    plannedDuration,
    currentInstance,
    state,
    useDurationBasedTiming,
    updateCumetimeOnTimelineMove,
  } = rundownTimes;

  const isRundownPlaying = RundownPlayStateUtils.isPlaying(state);

  let currentInstanceDur = 0;
  let currentRundownDuration = 0;
  /** Index of the current instance, -1 if no current instance is found */
  let ixCurrentInstance = -1;

  /**
   * Index of instance having the last defined hard-hit backtime.
   * Only instances before this index shall be used in rundown calculations.
   * Note: Equal to the length of the array if no hard-hit backtimes are defined
   * This will ensure all instances are used in rundown calculations
   * if no hard-hit backtimes are defined
   */
  const ixLastHardHitBackTimeInstance = findLastHardHitBackTimeInstance(rundownTimingFields);
  /** True if no defined hard-hit backtime */
  const noHardHitBackTime = ixLastHardHitBackTimeInstance === rundownTimingFields.length;

  const { mId: currentInstanceId, startTime: currentInstanceStart } = currentInstance ?? {};

  const dRundownStart = isRundownPlaying
    ? startTime?.getTime() ?? getLocalMidnightTimestamp()
    : getLocalMidnightTimestamp();

  /**
   * Cume time state
   */
  const cumeState: CumeTimeState = {
    accCumeTime: dRundownStart,
    showCumeTime: useDurationBasedTiming,
  };

  /**
   * If set, will display cumeTime in UX. Always done when useDurationBasedTiming is true
   */
  const useCumeTimeToAired = isRundownPlaying && updateCumetimeOnTimelineMove;

  for (let i = 0; i < rundownTimingFields.length; i++) {
    const tField = rundownTimingFields[i];

    const { isFloat, isCurrentInstance } = tField;
    if (isFloat) continue;

    let instanceStartTime = tField.startTime;
    if (isCurrentInstance) {
      ixCurrentInstance = i;
      currentInstanceDur = tField.dur;
      currentRundownDuration = tField.dur; // Caclulate from current instance and down
      if (!instanceStartTime) instanceStartTime = currentInstanceStart;
    } else if (i < ixLastHardHitBackTimeInstance) {
      // Only calculate for instances before last hard-hit backtime
      currentRundownDuration += tField.dur;
    }

    tField.isAired = isRundownPlaying && !isCurrentInstance && !!instanceStartTime;
    tField.isCumeTimeFixed = false;

    const isInstancePlaying = RundownPlayStateUtils.isPlaying(tField.state);

    /**
     * Used in race conditions where the current instance is set after being played.
     * This can happen when the rundown state changes to playing, but the current instance
     * has not been updated yet. In such cases, we need to ensure that the instance is
     * considered as playing to correctly calculate the cumulative time.
     */
    let useInstancePlaying = false;
    if (ixCurrentInstance >= 0 && isInstancePlaying) {
      const nextField = getNextField(rundownTimingFields, i);
      if (!nextField?.isCurrentInstance) {
        useInstancePlaying = true;
      }
    }

    if (instanceStartTime) {
      if (isCurrentInstance || useInstancePlaying) {
        setCumeTime(tField, instanceStartTime);
        accCumeTime(tField, cumeState, { isAbsolute: true, isFixed: true });
      } else if (useCumeTimeToAired) {
        setCumeTime(tField, instanceStartTime);
        accCumeTime(tField, cumeState, { isAbsolute: true, isFixed: false });
      } else {
        accCumeTime(tField, cumeState);
      }
    } else {
      accCumeTime(tField, cumeState);
    }
  }

  /**
   * Calculate back-time. In duration based timing, back-time always show the backtime
   */
  let showBackTime = useDurationBasedTiming;
  /** Accumulated back time in milliseconds */
  let accBackTime = dRundownStart + plannedDuration;

  let lastBreakTime = accBackTime / 1000;
  let nextBreakTime = lastBreakTime;
  let currentSegmentDuration = 0;
  let segmentDuration = 0;

  let lastIx = rundownTimingFields.length - 1;
  for (let i = lastIx; i >= 0; i--) {
    const tField = rundownTimingFields[i];

    const { isFloat, isCurrentInstance } = tField;
    if (isFloat) {
      // To ensure that the last field using rundown calculations is not a float
      if (i === lastIx) lastIx--;
      continue;
    }

    if (tField.isBackTimeAbsolute) {
      tField.isBackTimeFixed = true;
      accBackTime = tField.backTimeMs!;

      tField.segmentRestDuration = segmentDuration + tField.dur;
      segmentDuration = 0;

      if (!tField.isAired && !isCurrentInstance) nextBreakTime = accBackTime / 1000;
      if (i === lastIx) {
        if (ixCurrentInstance >= ixLastHardHitBackTimeInstance) {
          // Past or at the current hard-hit backtime instance. Stop showing backtime
          lastBreakTime = 0;
          nextBreakTime = 0;
        } else {
          lastBreakTime = accBackTime / 1000;
          if (isCurrentInstance) nextBreakTime = 0;
        }
      }
      showBackTime = true;
    } else if (i > ixLastHardHitBackTimeInstance) {
      // Past the current hard-hit backtime instance. Stop showing backtime
      showBackTime = false;
      tField.backTime = '-';
      tField.isBackTimeFixed = false;
      lastIx--; // To set the lastIx used in rundown calculations
    } else {
      accBackTime -= 1000 * tField.dur;
      // Also show backtime for the last instance if no hard-hit backtime is defined when onair
      tField.backTime =
        showBackTime || (isRundownPlaying && i === lastIx && noHardHitBackTime)
          ? DateToTimeString(accBackTime)
          : '-';
      tField.isBackTimeFixed = false;

      segmentDuration += tField.dur;
      tField.segmentRestDuration = segmentDuration;
    }

    if (isCurrentInstance && i < ixLastHardHitBackTimeInstance) {
      // Only calculate for instances before last hard-hit backtime
      currentSegmentDuration = tField.segmentRestDuration;
    }
  }

  return {
    startTime,
    currentInstanceDur,
    currentInstanceStart,
    currentInstanceId,
    lastUpdate: cumeState.showCumeTime || showBackTime ? new Date().toISOString() : '',
    timingFields: rundownTimingFields,
    rundownTimes,
    nextBreakTime,
    lastBreakTime,
    currentSegmentDuration,
    currentRundownDuration,
  };
};
