/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
import { useEffect, useRef } from 'react';
import {
  ApolloClient,
  ApolloError,
  FetchPolicy,
  OnDataOptions,
  OperationVariables,
  SubscriptionHookOptions,
  TypedDocumentNode,
  useApolloClient,
  useSubscription,
} from '@apollo/client';
import { ExecutableDefinitionNode } from 'graphql';

import useLogger from 'utils/useLogger';

import useDateTimeUtils from './useDateTimeUtils';

export type SubscriptionOption<T, TVariables extends OperationVariables> = {
  variables?: TVariables;
  skip?: boolean;
  ignoreResults?: boolean;
  source: string;
  fetchPolicy?: FetchPolicy;
  onError?: (e: ApolloError) => void;
  onSubscriptionData?: (dataOptions: OnDataOptions<T>) => void;
};

type AuditMessage = {
  event: string;
  name?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  variables: Record<string, any>;
  error?: ApolloError;
  client?: ApolloClient<object>;
  source?: string;
};

/** Error message list for which restart connection will be initiated */
const errorMessages = new Set(['timeout disconnect', 'connection closed']);

const useApolloSubscription = <T, TVariables extends OperationVariables>(
  query: TypedDocumentNode<T, TVariables>,
  options: SubscriptionOption<T, TVariables>,
) => {
  const logger = useLogger('useApolloSubscriptionV2');
  const { format } = useDateTimeUtils();
  const definitionNode = query.definitions?.[0] as ExecutableDefinitionNode;
  const queryName = definitionNode?.name?.value;
  const client = useApolloClient();
  const restartRef = useRef(() => {});
  const reconnectWhenOnline = useRef(false);

  const defaultErrorHandler = (payload: AuditMessage) => {
    const { event, name, variables, error, source } = payload;
    logger.log(`${event} ${source} ${name} ${JSON.stringify(variables)} ${JSON.stringify(error)}`);
  };

  const { variables, onSubscriptionData, onError, skip, source, fetchPolicy, ignoreResults } =
    options;

  const getAuditPayload = (when = new Date()) => {
    const payload = {
      name: queryName,
      variables: variables ?? {},
      timestamp: format(when, 'HH:mm:ss.SSS'),
      ...(source && { source }),
    };

    return payload;
  };

  const onData = (dataOptions: OnDataOptions<T>) => {
    if (dataOptions && onSubscriptionData) {
      onSubscriptionData(dataOptions);
    }
  };

  const shouldReconnect = (errors?: ApolloError['cause'][]) => {
    let reconnect = false;

    if (!errors?.length) return reconnect;

    errors.forEach((e) => {
      if (e?.message && errorMessages.has(e?.message?.toLocaleLowerCase())) {
        reconnect = true;
      }
    });

    return reconnect;
  };

  const onSubscriptionError = (e: ApolloError) => {
    const payload = getAuditPayload();
    if (onError) onError(e);

    const errors = (
      e.cause as unknown as {
        errors: ApolloError['cause'][];
      }
    )?.errors;

    defaultErrorHandler({
      event: 'graphql',
      client,
      ...payload,
      error: e,
    });

    if (!shouldReconnect(errors)) return;
    if (navigator.onLine) {
      logger.info('online, restarting connection');
      restartRef?.current();
    } else {
      logger.info('offline, will retry connection when online again');
      reconnectWhenOnline.current = true;
    }
  };

  const params: SubscriptionHookOptions<T, TVariables> = {
    variables,
    onData,
    onError: onSubscriptionError,
  };

  if (skip != null) params.skip = skip;
  if (fetchPolicy != null) params.fetchPolicy = fetchPolicy;

  const { data, error, loading, restart } = useSubscription<T, TVariables>(query, {
    ...params,
    context: { errorHandledLocally: true },
    ignoreResults,
  });

  useEffect(() => {
    restartRef.current = restart;
  }, [restart]);

  const updateConnectionStatus = (event: { type: string }) => {
    const payload = getAuditPayload();
    if (event.type === 'offline') {
      defaultErrorHandler({ event: 'offline', client, ...payload });
    } else if (event.type === 'online' && reconnectWhenOnline.current) {
      defaultErrorHandler({ event: 'online', client, ...payload });
      restartRef?.current();
      reconnectWhenOnline.current = false;
    }
  };

  useEffect(() => {
    window.addEventListener('offline', updateConnectionStatus);
    window.addEventListener('online', updateConnectionStatus);
    return () => {
      window.removeEventListener('offline', updateConnectionStatus);
      window.removeEventListener('online', updateConnectionStatus);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { data, error, loading };
};

export default useApolloSubscription;
