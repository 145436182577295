import { useEffect, useState } from 'react';

/**
 * Custom hook that tracks the width of a container element.
 * Uses ResizeObserver to update width when container size changes.
 *
 * @param containerRef - Reference to the container element to measure
 * @returns The current width of the container in pixels
 */
const useContainerWidth = (containerRef: React.RefObject<HTMLDivElement>) => {
  const [containerWidth, setContainerWidth] = useState<number>(0);

  useEffect(() => {
    if (!containerRef.current) return;

    const resizeObserver = new ResizeObserver((entries) => {
      const { width } = entries[0].contentRect;
      setContainerWidth(width);
    });

    // Start observing the container
    resizeObserver.observe(containerRef.current);

    return () => resizeObserver.disconnect();
  }, []);

  return { containerWidth };
};

export default useContainerWidth;
