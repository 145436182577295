import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import useKeyPress from 'hooks/useKeyPress';
import useMetaKeyPress from 'hooks/useMetaKeyPress';
import { ReactComponent as CloseIcon } from 'assets/icons/systemicons/close.svg';
import { LoadingButtonIndicator } from 'components/loadingIndicator';
import { Button } from 'components/buttons';
import InputField from 'components/inputField';
import useSettingsValue from 'hooks/useSettingsValue';
import {
  Wrapper,
  Header,
  HeaderLabel,
  CloseButton,
  ContentWrapper,
  Footer,
  ButtonsWrapper,
} from './styled';

const CreateNew = ({
  onCancel,
  onCreate,
  rundownTitle,
  isCreatingAudioRundown,
  isCreatingTVRundown,
}) => {
  const [title, setTitle] = useState(rundownTitle || '');
  const [submitting, setSubmitting] = useState(false);
  const [getSettingsValue] = useSettingsValue();

  const audioEnabled = getSettingsValue('rundown.audioEnabled');

  const enterPressed = useKeyPress('Enter');
  const ctrlPressed = useMetaKeyPress('ctrl');
  useEffect(() => {
    setTitle(rundownTitle);
  }, [rundownTitle]);

  const handleCreateLinearRundown = () => {
    if (submitting) return;
    setSubmitting(true);
    if (title) {
      onCreate(title.trim());
    }
    setSubmitting(false);
  };

  const handleCreateAudioRundown = () => {
    if (submitting) return;
    setSubmitting(true);
    if (title) {
      onCreate(title.trim(), 'audio');
    }
    setSubmitting(false);
  };

  useEffect(() => {
    if (enterPressed) {
      if (ctrlPressed) {
        handleCreateAudioRundown();
      } else {
        handleCreateLinearRundown();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enterPressed, ctrlPressed]);

  return (
    <Wrapper>
      <Header>
        <HeaderLabel>Create New Rundown Template</HeaderLabel>
        <CloseButton onClick={onCancel}>
          <CloseIcon />
        </CloseButton>
      </Header>
      <ContentWrapper>
        <InputField
          autoFocus
          usage="editor"
          disableLabel
          label="Type Rundown Title here..."
          placeholder="Type Rundown Title here..."
          value={title}
          onChange={setTitle}
        />
      </ContentWrapper>
      <Footer>
        <Button variant="discreet" usage="text" autoWidth onClick={onCancel}>
          Cancel
        </Button>
        <ButtonsWrapper>
          {audioEnabled ? (
            <Button
              ariaLabel="Audio"
              disabled={!title?.trim()}
              onClick={handleCreateAudioRundown}
              width={160}
              usage="pitch"
              variant="contained"
            >
              {isCreatingAudioRundown ? <LoadingButtonIndicator className="dark" /> : 'Audio'}
            </Button>
          ) : (
            <></>
          )}
          <Button
            ariaLabel="TV"
            disabled={!title?.trim()}
            onClick={handleCreateLinearRundown}
            usage="story"
            width={160}
            variant="contained"
          >
            {isCreatingTVRundown ? <LoadingButtonIndicator /> : 'TV'}
          </Button>
        </ButtonsWrapper>
      </Footer>
    </Wrapper>
  );
};

CreateNew.propTypes = {
  /** loading indicator when story is being created */
  isCreatingAudioRundown: PropTypes.bool,
  /** loading indicator when pitch is being created */
  isCreatingTVRundown: PropTypes.bool,
  /** onCancel callback */
  onCancel: PropTypes.func,
  /** onCreate callback */
  onCreate: PropTypes.func,

  /** optional pre-filled rundown title */
  rundownTitle: PropTypes.string,
};

CreateNew.defaultProps = {
  isCreatingAudioRundown: false,
  isCreatingTVRundown: false,
  onCreate: undefined,
  onCancel: () => {},
  rundownTitle: '',
};

export default CreateNew;
