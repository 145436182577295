import { useCallback } from 'react';
import {
  closestTo,
  ClosestToOptions,
  compareAsc,
  isAfter,
  isBefore,
  isEqual,
  isFirstDayOfMonth,
  isFuture,
  isMonday,
  isPast,
  isSameDay,
  IsSameDayOptions,
  isSameMonth,
  IsSameMonthOptions,
  isThisMinute,
  isThisMonth,
  isThisWeek,
  isThisYear,
  isToday,
  isTomorrow,
  isValid,
  isWeekend,
  isWithinInterval,
  IsWithinIntervalOptions,
  isYesterday,
  min,
  MinOptions,
} from 'date-fns';

import { DateType, TimezoneConverter, toTimeZoneDate } from 'utils/dateTime';

import useTimezoneSettings from './useTimezoneSettings';

type DateEqualityFunction<Options = unknown> = (date: DateType, options?: Options) => boolean;

const withTimezoneDateEquality =
  (convertToTimezoneDate: TimezoneConverter) =>
  <Options = unknown>(equalityFn: DateEqualityFunction<Options>) =>
  (date: DateType, options?: Options) =>
    equalityFn(convertToTimezoneDate(date), options);

const useDateComparisonUtils = () => {
  const timezone = useTimezoneSettings();

  const parseDate = useCallback(toTimeZoneDate(timezone), [timezone]);
  const timezoneSafeEqualityFn = useCallback(withTimezoneDateEquality(parseDate), [parseDate]);

  const isBeforeTZ = useCallback(
    (date: DateType, dateToCompare: DateType) =>
      isBefore(parseDate(date), parseDate(dateToCompare)),
    [parseDate],
  );
  const isAfterTZ = useCallback(
    (date: DateType, dateToCompare: DateType) => isAfter(parseDate(date), parseDate(dateToCompare)),
    [parseDate],
  );
  const compareAscTZ = useCallback(
    (dateLeft: DateType, dateRight: DateType) =>
      compareAsc(parseDate(dateLeft), parseDate(dateRight)),
    [parseDate],
  );
  const isEqualTZ = useCallback(
    (leftDate: DateType, rightDate: DateType) => isEqual(parseDate(leftDate), parseDate(rightDate)),
    [parseDate],
  );
  const isSameDayTZ = useCallback(
    (laterDate: DateType, earlierDate: DateType, options?: IsSameDayOptions) =>
      isSameDay(parseDate(laterDate), parseDate(earlierDate), options),
    [parseDate],
  );
  const isSameMonthTZ = useCallback(
    (laterDate: DateType, earlierDate: DateType, options?: IsSameMonthOptions) =>
      isSameMonth(parseDate(laterDate), parseDate(earlierDate), options),
    [parseDate],
  );
  const closestToTZ = useCallback(
    (dateToCompare: DateType, dates: DateType[], options?: ClosestToOptions) =>
      closestTo(parseDate(dateToCompare), dates.map(parseDate), options),
    [parseDate],
  );
  const isWithinIntervalTZ = useCallback(
    (
      date: DateType,
      interval: { start: DateType; end: DateType },
      options?: IsWithinIntervalOptions,
    ) =>
      isWithinInterval(
        parseDate(date),
        {
          start: parseDate(interval.start),
          end: parseDate(interval.end),
        },
        options,
      ),
    [parseDate],
  );
  const minTZ = useCallback(
    (dates: DateType[], options?: MinOptions) => min(dates.map(parseDate), options),
    [parseDate],
  );

  return {
    isValid: isValid,
    min: minTZ,
    isAfter: isAfterTZ,
    isBefore: isBeforeTZ,
    isEqual: isEqualTZ,
    isSameDay: isSameDayTZ,
    isSameMonth: isSameMonthTZ,
    compareAsc: compareAscTZ,
    closestTo: closestToTZ,
    isWithinInterval: isWithinIntervalTZ,
    isFirstDayOfMonth: timezoneSafeEqualityFn(isFirstDayOfMonth),
    isFuture: timezoneSafeEqualityFn(isFuture),
    isMonday: timezoneSafeEqualityFn(isMonday),
    isPast: timezoneSafeEqualityFn(isPast),
    isThisMinute: timezoneSafeEqualityFn(isThisMinute),
    isThisMonth: timezoneSafeEqualityFn(isThisMonth),
    isThisWeek: timezoneSafeEqualityFn(isThisWeek),
    isThisYear: timezoneSafeEqualityFn(isThisYear),
    isToday: timezoneSafeEqualityFn(isToday),
    isTomorrow: timezoneSafeEqualityFn(isTomorrow),
    isWeekend: timezoneSafeEqualityFn(isWeekend),
    isYesterday: timezoneSafeEqualityFn(isYesterday),
  };
};

export default useDateComparisonUtils;
