import gql from 'graphql-tag';

export default gql`
  query GetOrganizationUsers($input: GetOrganizationInput) {
    getOrganizationUsers(input: $input) {
      id
      type
      title
      description
      mdfId
      metadata
      createdAt
      createdById
      updatedAt
      updatedById
      groups
      isArchived
      isDisabled
      permissions
    }
  }
`;
