import styled from '@emotion/styled';

import { ReactComponent as SidebarActive } from 'assets/icons/systemicons/side_preview.svg';
import { Button } from 'components/buttons';

export const Wrapper = styled('div')`
  position: relative;
  flex: 1;
  display: flex;
  align-items: center;
  .sidebar-preview {
    display: none;
  }

  &:hover .sidebar-preview {
    display: flex;
    background: ${({ theme }) => theme.palette.dina.surfaceCard};
    position: absolute;
    right: 4px;
  }
`;

export interface OpenPreviewProps {
  onPreviewClick: () => void;
  children: React.ReactElement;
}

export default function OpenPreviewButton({
  onPreviewClick,
  children,
}: Readonly<OpenPreviewProps>) {
  return (
    <Wrapper>
      {children}
      <Button
        className="sidebar-preview"
        variant="outlined"
        usage="outlined"
        onClick={() => onPreviewClick()}
        size="sm"
      >
        <SidebarActive />
        Preview
      </Button>
    </Wrapper>
  );
}
