import { useCallback, useEffect, useMemo, useState } from 'react';
import { css, Theme } from '@emotion/react';
import styled from '@emotion/styled';
import { ClickAwayListener, TableCell, TableRow, Tooltip } from '@material-ui/core';

import { ReactComponent as Edit } from 'assets/icons/systemicons/edit.svg';
import { IconButton } from 'components/buttons';
import Checkbox from 'components/checkbox';
import { StyledFormControl } from 'components/command/toolbar/styled';
import { StyledTextField } from 'components/mdfEditor/fields/text/styled';
import { CloseIcon } from 'components/orderFormDialog/styled';
import Popper from 'components/shared/popper';
import Text from 'components/text';
import { Box, HStack, VStack } from 'layouts/box/Box';
import { AuthType, ProviderDTO, ProviderEndPoint } from 'types/graphqlTypes';

import { EditAuthDialogue } from '../../authDialogue/EditAuthDialogue';
import { TypesSelector } from '../../integrations/styled';
import { authOptions } from '../../integrations/utils';
import { EditEndPointDialogue } from '../dialogs/EditEndPointDialog';

const CellStyle = ({ theme }: { theme: Theme }) => css`
  padding-block: 0;
  padding-inline: 4px;
  height: 40px;
  box-sizing: border-box;
  border-bottom: 1px solid ${theme.palette.dina.dividerLight};
  ${theme.typography.dina.listItemLabel};
  font-size: 12px;
`;
export const RowCell = styled(TableCell)`
  ${CellStyle};
  color: ${({ theme }) => theme.palette.dina.mediumEmphasis};
`;

interface RowProps {
  providerData: ProviderDTO;
  onUpdateProvider: (updates: Partial<ProviderDTO>) => void;
}

export function ProviderRow({ providerData, onUpdateProvider }: Readonly<RowProps>) {
  const { id, title, baseUrl, endPoints, authType, tenant, apiKeyId } = useMemo(() => {
    const {
      id: tmpId,
      title: tmpTitle,
      baseUrl: tmpBaseUrl,
      endPoints: tmpEndpoints,
      auth,
    } = providerData;
    return {
      id: tmpId,
      title: tmpTitle,
      baseUrl: tmpBaseUrl,
      endPoints: tmpEndpoints,
      authType: auth?.type,
      tenant: auth?.headers?.tenant,
      apiKeyId: auth?.headers?.apikeyId,
    };
  }, [providerData]);
  const [providerTitle, setProviderTitle] = useState(title);
  const [providerBaseUrl, setProviderBaseUrl] = useState(baseUrl);
  const [showAuthDialog, setShowAuthDialog] = useState(false);
  const [showEditEndPointDialog, setShowEditEndPointDialog] = useState(false);
  const [anchor, setAnchor] = useState<EventTarget | null>(null);
  const [selectedPoints, setSelectedPoints] = useState(endPoints ?? []);

  useEffect(() => {
    setProviderBaseUrl(providerData.baseUrl);
    setProviderTitle(providerData.title);
  }, [providerData]);

  const showAuth = useCallback(() => {
    setShowAuthDialog(true);
  }, []);

  const isValidURL = (url: string): boolean => {
    try {
      new URL(url); // Throws an error if the URL is invalid
      return true;
    } catch {
      return false;
    }
  };

  const showEndPointDialogue = useCallback(() => {
    setShowEditEndPointDialog(true);
  }, []);

  const selectedLabel = useMemo(() => {
    const selectedEndPoints = selectedPoints ?? [];
    const publishingPointsData = selectedEndPoints.map((point) => point.id)?.join(', ');
    if (publishingPointsData) return publishingPointsData;
    return '';
  }, [selectedPoints]);

  const togglePoints = useCallback(
    (point: ProviderEndPoint) => {
      const updatedPoints = (selectedPoints ?? []).some((p) => p.id === point.id)
        ? (selectedPoints ?? []).filter((p) => p.id !== point.id) // Remove the point
        : [...(selectedPoints ?? []), point]; // Add the point
      setSelectedPoints(updatedPoints);
      onUpdateProvider({ endPoints: updatedPoints });
    },
    [selectedPoints, onUpdateProvider],
  );

  let tooltipMessage = '';
  if (!providerBaseUrl) {
    tooltipMessage = 'This field is required.';
  } else if (!isValidURL(providerBaseUrl)) {
    tooltipMessage = 'Please provide a valid URL.';
  }

  return (
    <TableRow>
      <RowCell>
        <Tooltip title="Provider id">
          <span>{id}</span>
        </Tooltip>
      </RowCell>
      <RowCell>
        <StyledTextField
          name="value"
          variant="filled"
          value={providerTitle}
          onChange={(ev) => {
            setProviderTitle(ev.target.value);
            onUpdateProvider({ title: ev.target.value });
          }}
          placeholder="Title"
        />
      </RowCell>
      <RowCell>
        <Tooltip title={tooltipMessage}>
          <StyledTextField
            name="value"
            variant="filled"
            value={providerBaseUrl}
            onChange={(ev) => {
              setProviderBaseUrl(ev.target.value);
              onUpdateProvider({ baseUrl: ev.target.value });
            }}
            placeholder="https://dina.dev"
            required
            error={!providerBaseUrl || (!!baseUrl && !isValidURL(baseUrl)) ? true : undefined}
          />
        </Tooltip>
      </RowCell>
      <RowCell>
        <Box container alignItems="center" justifyContent="start">
          <IconButton
            usage="text"
            size={24}
            iconSize={20}
            onClick={() => showAuth()}
            title="Configure auth"
          >
            <Edit />
          </IconButton>
          <Text variant="caption"> {authType ?? 'None'} </Text>
        </Box>
      </RowCell>
      <RowCell>
        <HStack>
          <IconButton
            usage="text"
            size={24}
            iconSize={20}
            onClick={() => showEndPointDialogue()}
            title="Configure endpoint"
          >
            <Edit />
          </IconButton>
          <Tooltip title={selectedLabel}>
            <TypesSelector
              container
              padding="4px"
              height="32px"
              maxWidth="200px"
              borderRadius="4px"
              overflow="hidden"
              justifyContent="flex-start"
              onClick={(ev) => setAnchor(ev.currentTarget)}
            >
              {selectedLabel}
            </TypesSelector>
          </Tooltip>
          <Popper anchorEl={anchor} position="bottom">
            <ClickAwayListener onClickAway={() => setAnchor(null)}>
              <Box padding="5px 24px 5px 5px">
                <VStack>
                  {endPoints?.map((point) => {
                    return (
                      <div key={point.id}>
                        <StyledFormControl
                          control={
                            <Checkbox
                              onClick={() => togglePoints(point)}
                              selected={(selectedPoints ?? []).some((p) => p.id === point.id)}
                            />
                          }
                          label={point.id}
                        />
                      </div>
                    );
                  })}
                </VStack>
                <CloseIcon
                  style={{ position: 'absolute', top: '3px', right: '3px' }}
                  onClick={() => setAnchor(null)}
                />
              </Box>
            </ClickAwayListener>
          </Popper>
        </HStack>
      </RowCell>

      {showAuthDialog && (
        <EditAuthDialogue
          open={showAuthDialog}
          setOpen={setShowAuthDialog}
          alternatives={authOptions}
          savedOptionList={authType ?? AuthType.None}
          doUpdate={onUpdateProvider}
          tenant={tenant}
          apiKeyId={apiKeyId}
          providerSettings={true}
        />
      )}

      {showEditEndPointDialog && (
        <EditEndPointDialogue
          open={showEditEndPointDialog}
          setOpen={setShowEditEndPointDialog}
          type={'provider-endpoint'}
          endPoints={selectedPoints}
          doUpdate={onUpdateProvider}
          setSelectedPoints={setSelectedPoints}
        />
      )}
    </TableRow>
  );
}
