import { useCallback, useEffect, useMemo } from 'react';
import { useAtom } from 'jotai';

import { contentTypes, StoryTabValue, useSetCurrentTab } from 'store';
import { atomWithSessionStorage } from 'utils/atoms/atomWithSessionStorage';

import { useStoryMolecule } from './store/story';

export interface Pane {
  tab: StoryTabValue;
  tId?: string;
}
/**
 * Represents a search param pane.
 * @description This type is a string that follows the pattern `${StoryTab}.${string}`.
 * StoryTab represents tab ('content', 'notes') and the string after dot is selected item id (tId).
 * Example: 'notes.123456_note_987' or 'content.'
 */

type SearchParamPane = `${StoryTabValue}.${string}`;
const storyTabStates = atomWithSessionStorage<Record<string, SearchParamPane[]>>(
  'storyTabStates',
  {},
);

const useStoryTabState = () => useAtom(storyTabStates);

const useStoryPanes = () => {
  const { useQueryTagValue, useStoryIdValue } = useStoryMolecule();
  const storyId = useStoryIdValue();
  const queryTag = useQueryTagValue();
  const [storyTabs, setStoryTabs] = useStoryTabState();
  const setCurrentTab = useSetCurrentTab();

  const storyPanes: Pane[] = useMemo(() => {
    if (storyId) {
      return (storyTabs[storyId] ?? ['content.']).map((pane) => {
        const [tab, tId] = pane.split('.') as [StoryTabValue, string];
        return { tab, tId };
      });
    } else {
      return [{ tab: 'content', tId: '' }];
    }
  }, [storyId, storyTabs]);

  const syncToURL = useCallback(
    (newPanes: string[]) => {
      const newParams = new URLSearchParams(window.location.search);
      newParams.set(queryTag, newPanes.join(','));
      setCurrentTab((prev) =>
        prev && contentTypes.includes(prev.type) ? { ...prev, search: newPanes.join(',') } : prev,
      );
      window.history.replaceState({}, '', '?' + newParams.toString());
    },
    [queryTag, setCurrentTab],
  );

  const initNewStory = useCallback(
    (newStoryId: string) => {
      setStoryTabs((prev) => {
        const pane = prev[newStoryId] ?? ['content.'];

        const temp = { ...prev, [newStoryId]: pane };
        syncToURL(pane);
        return temp;
      });
    },
    [setStoryTabs, syncToURL],
  );

  const addStoryPane = useCallback(
    (tab?: StoryTabValue, tId?: string) => {
      if (!storyId) return;

      setStoryTabs((prev) => {
        const storyPane = prev[storyId] ?? ['content.'];
        const tempSPTabs = [...storyPane, `${tab ?? 'content'}.${tId ?? ''}`] as SearchParamPane[];
        const temp = { ...prev, [storyId]: tempSPTabs };
        syncToURL(tempSPTabs);
        return temp;
      });
    },
    [setStoryTabs, storyId, syncToURL],
  );

  const updateStoryPane = useCallback(
    (index: number, tab: StoryTabValue, tId?: string) => {
      if (!storyId) return;

      setStoryTabs((prev) => {
        const storyPane = prev[storyId] ?? ['content.'];
        const tempSPTabs = storyPane.map((tabStr, i) =>
          i === index ? `${tab}.${tId ?? ''}` : tabStr,
        ) as SearchParamPane[];

        const temp = { ...prev, [storyId]: tempSPTabs };
        syncToURL(tempSPTabs);
        return temp;
      });
    },
    [setStoryTabs, storyId, syncToURL],
  );

  const removeStoryPane = useCallback(
    (index: number) => {
      if (!storyId) return;

      setStoryTabs((prev) => {
        const storyPane = prev[storyId] ?? ['content.'];
        const tempSPTabs = storyPane.filter((_, i) => i !== index);
        const temp = { ...prev, [storyId]: tempSPTabs } as Record<string, SearchParamPane[]>;
        syncToURL(tempSPTabs);
        return temp;
      });
    },
    [setStoryTabs, storyId, syncToURL],
  );

  useEffect(() => {
    if (!storyPanes.length) {
      addStoryPane();
    }
  }, [addStoryPane, storyPanes.length]);

  return {
    storyPanes,
    initNewStory,
    addStoryPane,
    updateStoryPane,
    removeStoryPane,
  };
};

export default useStoryPanes;
