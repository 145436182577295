import { ReactComponent as OneByThreeVerticalIcon } from 'assets/icons/layouts/1_3_vertical_layout.svg';
import { ReactComponent as OneByThreeVerticalIconActive } from 'assets/icons/layouts/1_3_vertical_layout_active.svg';
import { ReactComponent as OneByOneHorizontalIcon } from 'assets/icons/layouts/1-1_horizontal_layout.svg';
import { ReactComponent as OneByOneHorizontalIconActive } from 'assets/icons/layouts/1-1_horizontal_layout_active.svg';
import { ReactComponent as OneByOneVerticalIcon } from 'assets/icons/layouts/1-1_vertical_layout.svg';
import { ReactComponent as OneByOneVerticalIconActive } from 'assets/icons/layouts/1-1_vertical_layout_active.svg';
import { ReactComponent as OneByOneByOneHorizontalIcon } from 'assets/icons/layouts/1-1-1_horizontal_layout.svg';
import { ReactComponent as OneByOneByOneHorizontalIconActive } from 'assets/icons/layouts/1-1-1_horizontal_layout_active.svg';
import { ReactComponent as OneByTwoIcon } from 'assets/icons/layouts/1-2_layout.svg';
import { ReactComponent as OneByTwoIconActive } from 'assets/icons/layouts/1-2_layout_active.svg';
import { ReactComponent as ThreeByOneVerticalIcon } from 'assets/icons/layouts/3_1_vertical_layout.svg';
import { ReactComponent as ThreeByOneVerticalIconActive } from 'assets/icons/layouts/3_1_vertical_layout_active.svg';
import { ReactComponent as FourLayoutIcon } from 'assets/icons/layouts/4_layout.svg';
import { ReactComponent as FourLayoutIconActive } from 'assets/icons/layouts/4_layout_active.svg';
import { ReactComponent as HorizontalIcon } from 'assets/icons/layouts/horizontal_layout.svg';
import { ReactComponent as HorizontalIconActive } from 'assets/icons/layouts/horizontal_layout_active.svg';

import { SpaceViewLayout } from './types';

export const LayoutIcons: Record<SpaceViewLayout, React.FC<React.SVGProps<SVGSVGElement>>> = {
  '1-2-horizontal': OneByTwoIcon,
  '1-1-1-horizontal': OneByOneByOneHorizontalIcon,
  '1-1-horizontal': OneByOneHorizontalIcon,
  '1-1-vertical': OneByOneVerticalIcon,
  '1-3-vertical': OneByThreeVerticalIcon,
  '3-1-vertical': ThreeByOneVerticalIcon,
  '2-2': FourLayoutIcon,
  horizontal: HorizontalIcon,
} as const;

export const LayoutIconsActive: Record<SpaceViewLayout, React.FC<React.SVGProps<SVGSVGElement>>> = {
  '1-2-horizontal': OneByTwoIconActive,
  '1-1-1-horizontal': OneByOneByOneHorizontalIconActive,
  '1-1-horizontal': OneByOneHorizontalIconActive,
  '1-1-vertical': OneByOneVerticalIconActive,
  '1-3-vertical': OneByThreeVerticalIconActive,
  '3-1-vertical': ThreeByOneVerticalIconActive,
  '2-2': FourLayoutIconActive,
  horizontal: HorizontalIconActive,
} as const;
