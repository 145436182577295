import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { CircularProgress } from '@material-ui/core';

export const Container = styled('div')<{
  $writeProtected?: boolean;
  $editable?: boolean;
  $collapsed?: boolean;
}>`
  position: relative;
  width: 100%;
  height: ${({ $collapsed }) => ($collapsed ? '0px' : '40px')};
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  padding: 0 0.5rem;
  gap: 8px;
  background-color: ${({ $writeProtected, $editable, theme }) => {
    if ($editable) return theme.palette.dina.statusApproved;
    if ($writeProtected) return theme.palette.dina.statusWarning;
    return 'transparent';
  }};
  border-top: ${({ $writeProtected, $editable, theme }) => {
    return !$editable && !$writeProtected ? `1px solid ${theme.palette.dina.dividerLight}` : 'none';
  }};
`;

export const MessageContainer = styled('div')`
  ${({ theme }) => css`
    ${theme.typography.dina.button};
    background-color: ${theme.palette.dina.blackInactive};
  `};
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

export const Progress = styled(CircularProgress)`
  color: ${({ theme }) => theme.palette.dina.whiteHighEmphasis};
  animation-duration: 1s;
`;
