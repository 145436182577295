import { DateRange } from 'components/mdfEditor/fields/date/DatePicker';
import { GetPropertiesProps } from 'features/widget/WidgetFactory';
import { WidgetProperties, WidgetType } from 'screens/space/types/space';

/** Layout configurations that can be used in a space view */
export const LAYOUTS = [
  'horizontal',
  '1-1-1-horizontal',
  '1-2-horizontal',
  '1-1-horizontal',
  '1-1-vertical',
  '2-2',
  '1-3-vertical',
  '3-1-vertical',
] as const;

/** Type representing the available layout configurations. */
export type SpaceViewLayout = (typeof LAYOUTS)[number];

/** Controls the behavior and content in layout components. */
export interface LayoutProps {
  /** Array of widgets to display in the layout. Undefined entries represent empty slots */
  widgets: (WidgetType | undefined)[];
  /** Function to get widget properties based on widget type and configuration */
  getWidgetProperties: (props: GetPropertiesProps) => WidgetProperties;
  /** Reference to the scrollable container */
  scrollRef: React.RefObject<HTMLDivElement>;
  /** Whether to filter content by date */
  filterByDate: boolean;
  /** Selected date range for filtering */
  selectedDate?: DateRange;
  /** Search string for federated search across widgets */
  federatedSearchString: string;
  writeAccess: boolean;
  spaceId: string;
  viewRef?: string;
}

export type Direction = 'horizontal' | 'vertical';

/** Defines how widgets should be arranged and sized within the layout. */
export type GridConfig = {
  /** Number of rows in the outer dimension of the grid. */
  rows: number;
  /** Number of columns in the outer dimension of the grid. */
  cols: number;
  /** Determines whether the main dimension is horizontal (cols) or vertical (rows). */
  direction: Direction;
  /**
   * Optional weights for each cell in the grid, defining their relative sizes.
   * For horizontal layouts: weights[column][row]
   * For vertical layouts: weights[row][column]
   */
  weights?: readonly (readonly number[])[];
  /** Layout identifier matching one of the available SpaceViewLayout types */
  layoutId: SpaceViewLayout;
};

/** Styles that control the spacing between panels in the layout. */
export type PanelStyles = {
  marginLeft?: string;
  marginRight?: string;
  marginTop?: string;
  marginBottom?: string;
};
