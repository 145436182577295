import { memo, useContext, useEffect, useRef } from 'react';

import Avatar from 'components/avatar/Avatar';
import TourPopup from 'components/tourPopup';
import { useAuthContext } from 'contexts/AuthContext';
import UserCtx from 'contexts/UserContext';
import useCheckUserRight from 'hooks/useCheckUserRight';
import { HStack } from 'layouts/box/Box';
import { tours } from 'store';
import { useReadableOrganizations, useSelectedOrgId } from 'store/organization';

function UserAvatar() {
  const { attributes } = useContext(UserCtx);
  const { mAvatarKey } = attributes || {};
  const { attributes: userAttributes } = useAuthContext();
  const activeOrganizationId = userAttributes?.orgId;
  const [readableOrganizations] = useReadableOrganizations();
  const notificationRef = useRef<HTMLDivElement | null>(null);
  const currentOrganization = readableOrganizations?.find((org) => org.id === activeOrganizationId);
  const [selectedOrgId, setSelectedOrgId] = useSelectedOrgId();
  const [checkUserRight] = useCheckUserRight();
  const canAccessOrganization = checkUserRight('feature', 'organization');

  useEffect(() => {
    if (!selectedOrgId && activeOrganizationId !== selectedOrgId) {
      setSelectedOrgId(activeOrganizationId);
    }
  }, [activeOrganizationId, selectedOrgId, setSelectedOrgId]);

  return (
    <>
      <TourPopup
        position="bottom"
        anchor={notificationRef}
        tourStep={tours.MainTour.steps.Mobile}
        tourId={tours.MainTour.id}
      />
      <HStack ref={notificationRef} cursor="pointer">
        <Avatar size={32} imageKey={mAvatarKey} variant="user" />
        {canAccessOrganization && currentOrganization?.id && (
          <Avatar
            size={32}
            variant="user"
            title={currentOrganization.title ?? currentOrganization.id}
          />
        )}
      </HStack>
    </>
  );
}

export default memo(UserAvatar);
