/* eslint-disable sort-imports */
import { memo, useCallback, useMemo, useState } from 'react';
import { Range } from 'slate';
import { useSlate } from 'slate-react';
import { v4 as uuid } from 'uuid';

import { ReactComponent as Delete } from 'assets/icons/systemicons/delete_small.svg';
import { IconButton } from 'components/buttons';
import Button from 'components/buttons/button';
import { DeleteDialog } from 'components/dialogs/CommonDialogs';
import ColorInput from 'components/input/ColorInput';
import Text from 'components/text/Text';
import useFuseSearch from 'hooks/useFuseSearch';
import { Box, HStack, VStack } from 'layouts/box/Box';
import { useSnippets } from 'store';
import { MemberTypeEnum } from 'types/graphqlTypes';
import { EditorCommandConfigType } from 'types/memberTypes/editorCommands';

import { useManageSnippets } from './hooks/useManageSnippets';

import {
  ArrowDown,
  ArrowUp,
  Close,
  CloseIcon,
  Content,
  DialogWrapper,
  Footer,
  Header,
  Info,
  List,
  MenuItem,
  MUIInputAdornment,
  MUIPopover,
  StyledTextField,
  Title,
} from './styled';

function ManageSnippet({
  anchorEl,
  setAnchorEl,
}: {
  readonly anchorEl: HTMLButtonElement | null;
  readonly setAnchorEl: (value: HTMLButtonElement | null) => void;
}) {
  const editor = useSlate();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [showSnippetsList, setShowSnippetsList] = useState(false);
  const [snippetTitle, setSnippetTitle] = useState('');
  const [snippetCommand, setSnippetCommand] = useState('');
  const [snippetSearch, setSnippetSearch] = useState('');
  const [colorPickerValue, setColorPickerValue] = useState('');

  const hasSelection = editor.selection && !Range.isCollapsed(editor.selection);
  const [titleExists, setTitleExists] = useState(false);
  const { createSnippet, deleteSnippet, updateSnippet } = useManageSnippets();

  const [snippets] = useSnippets();
  const fuseSearch = useFuseSearch({
    shouldSort: true,
  });
  const closeDeleteDialog = () => setDeleteDialogOpen((prev) => !prev);
  const [selectedSnippet, setSelectedSnippet] = useState<EditorCommandConfigType | null>(null);
  const openDeleteDialog = (selecteSnippet: EditorCommandConfigType) => {
    setSelectedSnippet(selecteSnippet);
    setDeleteDialogOpen((prev) => !prev);
  };
  const onClickCancel = useCallback(() => {
    setSnippetCommand('');
    setSnippetTitle('');
    setAnchorEl(null);
  }, [setAnchorEl]);

  const filteredSnippets = useMemo(() => {
    return snippets.filter((editorCmd) => {
      return editorCmd.mResourceType === MemberTypeEnum.Snippet;
    });
  }, [snippets]);

  const matchFunction = useCallback(
    (list: EditorCommandConfigType[]) =>
      fuseSearch(list, ['mTitle', 'slashCommand'], snippetSearch?.substring(1) || ''),
    [fuseSearch, snippetSearch],
  );

  const filteredCommands = useMemo(
    () => matchFunction(filteredSnippets).sort((a, b) => a.mTitle.localeCompare(b.mTitle)),
    [matchFunction, filteredSnippets],
  );

  const handleSaveClick = async () => {
    const frag = editor.getFragment();
    const input = {
      mRefId: uuid(),
      mTitle: snippetTitle,
      slashCommand: snippetCommand,
      metadata: JSON.stringify(frag),
    };
    await createSnippet(input);
    setAnchorEl(null);
  };

  const handleUpdate = async () => {
    const { mId, mRefId } = selectedSnippet as EditorCommandConfigType;
    const input = {
      mId,
      mRefId,
      mTitle: snippetTitle,
      slashCommand: snippetCommand,
      mColor: colorPickerValue,
    };
    await updateSnippet(input);
  };

  const onClickSave = () => {
    handleSaveClick().catch(() => {});
  };

  const onDeleteCommand = async () => {
    await deleteSnippet(selectedSnippet?.mId as string, selectedSnippet?.mRefId as string);
    setSelectedSnippet(null);
    closeDeleteDialog();
  };

  const onTitleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = ev.target;
    if (filteredCommands.some((cmd) => cmd.mTitle === value)) {
      setTitleExists(true);
      setSnippetTitle(value);
    } else {
      setSnippetTitle(value);
      setTitleExists(false);
    }
  };

  const onSnippetClick = (command: EditorCommandConfigType) => {
    setSelectedSnippet(command);
    setSnippetTitle(command.mTitle);
    setSnippetCommand(command.slashCommand);
    setColorPickerValue(command.mColor ?? 'white');
  };
  const updateClick = () => {
    handleUpdate().catch(() => {});
  };

  const saveDisabled = () => {
    if (!snippetTitle || !snippetCommand) return true;
    if (!selectedSnippet && !hasSelection) return true;
  };
  const editDisabled = () => {
    if (!selectedSnippet && !hasSelection) return true;
  };

  return (
    <MUIPopover
      open={anchorEl !== null}
      onClose={onClickCancel}
      elevation={16}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
    >
      <DialogWrapper>
        <Header>
          <Title>{hasSelection ? 'Save as Snippet' : 'Edit Snippets'}</Title>
          <Close>
            <CloseIcon onClick={onClickCancel} />
          </Close>
        </Header>
        <Content>
          <VStack gap="12px" flex="1">
            <StyledTextField
              label={titleExists ? 'Title already exist' : null}
              variant="filled"
              value={snippetTitle}
              onChange={onTitleChange}
              error={!snippetTitle}
              fullWidth
              placeholder="Enter snippet title"
              exists={titleExists}
              disabled={editDisabled()}
            />
            <HStack gap="8px" width="100%">
              <StyledTextField
                variant="filled"
                value={snippetCommand}
                onChange={(ev) => setSnippetCommand(ev.target.value)}
                error={!snippetCommand}
                fullWidth
                placeholder='Slash command (e.g. "slc")'
                disabled={editDisabled()}
                textColor={colorPickerValue}
                InputProps={{
                  endAdornment: (
                    <MUIInputAdornment position="end">
                      <ColorInput
                        color={colorPickerValue}
                        className="color-wrapper"
                        onChange={setColorPickerValue}
                      />
                    </MUIInputAdornment>
                  ),
                }}
              />
            </HStack>
          </VStack>
        </Content>
        <Footer>
          <HStack gap="4px">
            <Button variant="outlined" onClick={onClickCancel} usage="outlined" width={104}>
              Cancel
            </Button>
            <Button
              variant="contained"
              usage={selectedSnippet ? 'cta' : 'pitch'}
              onClick={selectedSnippet ? updateClick : onClickSave}
              width={104}
              disabled={saveDisabled()}
              title="Select content to create a snippet"
            >
              {selectedSnippet ? 'Update' : 'Save'}
            </Button>
          </HStack>
        </Footer>
        <Button
          title={showSnippetsList ? 'Hide snippets' : 'Show snippets'}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          height={10}
          usage="text"
          onClick={() => setShowSnippetsList((prev) => !prev)}
        >
          {!showSnippetsList ? (
            <ArrowDown className="skipOverride" />
          ) : (
            <ArrowUp className="skipOverride" />
          )}
        </Button>
        {showSnippetsList && (
          <List disablePadding>
            <Box container justifyContent="flex-start" height="32px" padding="0 8px 0">
              <StyledTextField
                variant="standard"
                value={snippetSearch}
                onChange={(ev) => setSnippetSearch(ev.target.value)}
                fullWidth
                placeholder="Type to search snippets"
              />
            </Box>
            {filteredCommands.map((command) => {
              const { mRefId, mTitle, slashCommand, mColor } = command;
              return (
                <MenuItem
                  dense
                  disableRipple
                  onClick={() => onSnippetClick(command)}
                  key={mRefId}
                  id={mRefId}
                  disableGutters
                >
                  {mTitle && (
                    <Text variant="listItemLabel" color="highEmphasis">
                      {mTitle}
                    </Text>
                  )}
                  <Info
                    $color={command.mResourceType === MemberTypeEnum.Snippet ? mColor : undefined}
                  >{`/${slashCommand}`}</Info>
                  <IconButton
                    size={24}
                    usage="text"
                    title="Delete command"
                    onClick={() => openDeleteDialog(command)}
                    className="deleteButton"
                  >
                    <Delete className="skipOverride" />
                  </IconButton>
                </MenuItem>
              );
            })}
          </List>
        )}

        <DeleteDialog
          open={deleteDialogOpen}
          onClose={closeDeleteDialog}
          onClick={onDeleteCommand}
          title="Delete command"
          message={`Are you sure you want to delete "${selectedSnippet?.mTitle}"?`}
        />
      </DialogWrapper>
    </MUIPopover>
  );
}

export default memo(ManageSnippet);
