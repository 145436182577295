import gql from 'graphql-tag';

export default gql`
  query GetPlatform($input: GetPlatformInput) {
    getPlatform(input: $input) {
      mId
      mProperties {
        ... on PlatformType {
          platform
          platformAccount
          platformStructure {
            id
            name
            variant
            sections {
              blocks
              id
              name
            }
            config {
              block
              mMetaData {
                key
                value
              }
            }
            allowVideoInPhotogallery
          }
          provider {
            requestId
            state
            eventTime
            startTime
            stopTime
            message
            embeddedEndpoint
            previewEndpoint
            updateEndpoint
          }
          account {
            accountId
            accountTitle
          }
          publishedAt
          scheduledAt
          updatedAt
        }
      }
      mTitle
      mType
    }
  }
`;
