/* eslint-disable max-len */
import styled from '@emotion/styled';
import MuiFormControlLabel from '@material-ui/core/FormControlLabel';

import Checkbox from 'components/checkbox';
import ColorInput from 'components/input/ColorInput';
import Input from 'components/input/Input';
import Text from 'components/text/Text';
import Tooltip from 'components/tooltip';
import { Box, HStack } from 'layouts/box/Box';
import { MemberTypeEnum } from 'types/graphqlTypes';
import { EditorCommandConfigType } from 'types/memberTypes/editorCommands';

import { ChangedEditorCommandType, useChangedEditorCommands } from '../../atomsTs';

import ConfigDropdown, { ConfigType } from './ConfigDropdown';

const Root = styled('div')`
  padding-block: 8px;
  padding-inline: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const FormControlLabel = styled(MuiFormControlLabel)`
  margin-left: 0;
  .MuiCheckbox-root {
    width: 22px;
    height: 22px;
    margin-right: 6px;
  }
  .MuiFormControlLabel-label {
    font-size: 13px;
  }
`;

interface CommandPropertiesProps {
  readonly commandProperties: EditorCommandConfigType;
}

function EditCommandProperties({ commandProperties }: CommandPropertiesProps) {
  const [, setChangedCommands] = useChangedEditorCommands();

  const onChange = (key: keyof ChangedEditorCommandType, value: string | boolean) =>
    setChangedCommands((prev) => {
      const matchedProperty = prev.find((command) => command.mRefId === commandProperties.mRefId);

      if (!matchedProperty) return [...prev, { mRefId: commandProperties.mRefId, [key]: value }];

      return prev.map((command) =>
        command.mRefId === commandProperties.mRefId ? { ...command, [key]: value } : command,
      );
    });

  const updateConfig = (newConfig: ConfigType | null) => {
    if (newConfig?.id) onChange('mSecId', newConfig.id);
    if (newConfig?.type) onChange('mResourceType', newConfig.type);
  };

  const {
    mTitle,
    mDescription,
    slashCommand,
    mActive,
    mSecId,
    mColor,
    mResourceType,
    canSchedule,
  } = commandProperties;

  // Only support schedule workflow on planning items, not tasks
  const supportsScheduling = mResourceType === MemberTypeEnum.Mdf;

  return (
    <Root>
      <ConfigDropdown disableClearable disabled value={mSecId} onSelectConfig={updateConfig} />
      <Text variant="overline">Title</Text>
      <Input initialValue={mTitle} updateValue={(value) => onChange('mTitle', value)} />
      <Tooltip title="Enable inserting this item into editors directly using a slash command">
        <FormControlLabel
          key="active"
          label="Slash command"
          control={<Checkbox selected={mActive} onClick={() => onChange('mActive', !mActive)} />}
        />
      </Tooltip>
      {mActive && (
        <Box padding="0 0 0 10px">
          <Text variant="overline">Slash command</Text>
          <Input
            initialValue={slashCommand}
            updateValue={(value) => onChange('slashCommand', value)}
          />
        </Box>
      )}
      {supportsScheduling && (
        <Tooltip title="Enable this planning item to be scheduled, this will allow these to be displayed in the StoryHub">
          <FormControlLabel
            key="active"
            label="Enable scheduling"
            control={
              <Checkbox
                selected={!!canSchedule}
                onClick={() => onChange('canSchedule', !canSchedule)}
              />
            }
          />
        </Tooltip>
      )}

      {canSchedule === true && supportsScheduling === true && (
        <Box padding="0 0 0 10px">
          <Text variant="overline">Schedule field label</Text>
          <Input
            initialValue={mDescription}
            updateValue={(value) => onChange('mDescription', value)}
          />
        </Box>
      )}
      {mResourceType !== MemberTypeEnum.OrderForm && (
        <HStack height="24px" gap="10px">
          <Text variant="overline">Color:</Text>
          <ColorInput color={mColor} onChange={(val) => onChange('mColor', val)} />
        </HStack>
      )}
    </Root>
  );
}

export default EditCommandProperties;
