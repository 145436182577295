import type { PlatformStructure } from 'types/graphqlTypes';
import type { Platform, PlatformAccount } from 'types/members';
import { getPlatformLabel } from 'utils/instance/platform';
import { getInstanceDefaultTemplateKey } from 'utils/instance/templates/getDefaultTemplateKey';

export interface PlatformVariant {
  id: string;
  platform: string;
  platformKind?: string;
  label: string; // Label / abbrev representing the platform name
  icon: string;
  title: string; // Title from DynamoDB platform entity, mTitle
  defaultTemplate?: string;
  defaultTemplateKey?: string;
  platformStructure?: PlatformStructure;
}

export interface PlatformInfo {
  platform: string;
  platformKind?: string;
  platformIcon?: string;
  platformStructure?: PlatformStructure;
  variants?: Record<string, PlatformVariant>;
  accounts?: PlatformAccount[];
}

export type GetPlatformsQueryReturn = {
  getPlatforms: Platform[];
};

const createVariant = (platform: Platform): PlatformVariant => {
  const { mTitle, mRefId, mProperties } = platform;
  const { platform: pname, platformIcon: icon, platformStructure } = mProperties;
  const id = mRefId ?? pname;
  const label = getPlatformLabel(pname);
  return {
    id,
    platform: pname,
    label,
    title: mTitle.toLowerCase() === pname.toLowerCase() ? label : mTitle,
    icon: icon ?? pname,
    platformStructure,
    defaultTemplateKey: getInstanceDefaultTemplateKey(pname, id),
  };
};

const mergeAccounts = (
  aggAccounts: PlatformAccount[],
  accounts: PlatformAccount[],
  variant: string,
) => {
  for (const account of accounts) {
    aggAccounts.push({ ...account, variant });
  }
  return aggAccounts;
};

const createProps = (platform: Platform): PlatformInfo => {
  const { mProperties } = platform;
  const { platform: pname, accounts = [] } = mProperties;
  const variant = createVariant(platform);
  return {
    platform: pname,
    variants: { [variant.id]: variant },
    accounts: mergeAccounts([], accounts, variant.id),
  };
};

const mergeProps = (pprops: PlatformInfo, platform: Platform) => {
  const { mProperties } = platform;
  const { accounts = [] } = mProperties;
  if (!pprops.variants) pprops.variants = {};
  const variant = createVariant(platform);
  pprops.variants[variant.id] = variant;
  pprops.accounts = mergeAccounts(pprops.accounts ?? [], accounts, variant.id);
  return pprops;
};

/**
 * Returns the first defined property for an object.
 */
const getFirstProperty = <T>(obj: Record<string, T>): T | undefined => {
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key) && obj[key] !== undefined) {
      return obj[key];
    }
  }
  return undefined;
};

export const convertToPlatform = (value: PlatformInfo): Platform => {
  const { variants = {}, platform, accounts } = value;
  const firstVariant = getFirstProperty<PlatformVariant>(variants);
  return {
    mRefId: platform,
    mTitle: platform,
    mProperties: {
      platform: platform,
      accounts,
      platformKind: value.platformKind,
      platformIcon: firstVariant?.icon ?? platform,
      platformStructure: firstVariant?.platformStructure,
    },
  };
};

export const mergePlatforms = (data: GetPlatformsQueryReturn | undefined) => {
  const { getPlatforms = [] } = data ?? {};

  const mergedPlatforms = getPlatforms.reduce((pmap: Map<string, PlatformInfo>, platform) => {
    const { mProperties: { platform: pname } = {} } = platform;
    /**
     * we need to filter out the linear platform because we handle it manually
     * the db item that we receive is used only for workflow settings
     */
    if (!pname || pname === 'linear') return pmap;
    const pprops = pmap.get(pname);
    if (!pprops) {
      pmap.set(pname, createProps(platform));
      return pmap;
    }
    mergeProps(pprops, platform);
    return pmap;
  }, new Map());

  return Array.from(mergedPlatforms.values());
};
