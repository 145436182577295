import styled from '@emotion/styled';

import { ReactComponent as Planned } from 'assets/icons/search/planned_duration.svg';
import Text from 'components/text';
import { HStack } from 'layouts/box/Box';
import { formatDuration } from 'utils/formatDuration';

import { GroupInline } from '../Group';

const PlannedDurationWrapper = styled(HStack)`
  display: flex;
  align-items: center;
  gap: 4px;
`;

function PlannedDuration({ duration }: Readonly<{ duration: string }>) {
  return (
    <GroupInline label="Planned Duration">
      <PlannedDurationWrapper>
        <Planned />
        <Text variant="buttonMedium">{formatDuration(Number(duration ?? '0'))}</Text>
      </PlannedDurationWrapper>
    </GroupInline>
  );
}

export default PlannedDuration;
