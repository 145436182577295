import { useCallback, useMemo } from 'react';

import { useGetBlock } from 'api/mdfBlocks/useGetMdfBlock';
import { useUpdateBlock } from 'api/mdfBlocks/useUpdateMdfBlock';
import ScheduleDatePicker from 'components/createNewV3/datePicker/scheduleDatePicker';
import Input from 'components/input/Input';
import { MdfEditor } from 'components/mdfEditor/MdfEditor';
import { FieldHeader } from 'components/mdfEditor/styled';
import useToast from 'components/toast/useToast';
import useMetadata from 'hooks/useMetadata';
import { Box } from 'layouts/box/Box';
import { useEditorCommands } from 'store';
import type { Mdf, Metadata } from 'types/graphqlTypes';
import preventDefaultAndPropagation from 'utils/preventDefaultAndStopPropagation';

import { FieldWrapper } from './styled';

const stringifyDeps = (mRefId?: string, metadata?: Metadata) =>
  JSON.stringify([mRefId ?? '', metadata ?? '']);

interface EditMdfProps {
  resourceId?: string;
  blockId?: string;
  mdf?: Mdf;
}

function EditPlanningItem({ resourceId, blockId, mdf }: Readonly<EditMdfProps>) {
  const updateBlock = useUpdateBlock();
  const { errorToast } = useToast();
  const { block, loading } = useGetBlock(resourceId, blockId);
  const [configs] = useEditorCommands();

  const config = useMemo(() => {
    return configs.find((c) => c.mRefId === block?.mSecId);
  }, [block, configs]);

  const { metadata, updateFieldValues, errorMap } = useMetadata(
    mdf,
    block?.metadata,
    'default',
    block ?? undefined,
    useMemo(() => stringifyDeps(block?.mRefId, block?.metadata), [block?.mRefId, block?.metadata]),
  );

  const doUpdateTitle = useCallback(
    (val: string) => {
      if (block?.mId && block?.mRefId)
        updateBlock(block.mId, block.mRefId, 'mTitle', val).catch(errorToast);
    },
    [block, updateBlock, errorToast],
  );

  const doUpdateScheduleDate = useCallback(
    (val: string | null) => {
      if (block?.mId && block?.mRefId)
        updateBlock(block.mId, block.mRefId, 'mPublishingAt', val).catch(errorToast);
    },
    [block, updateBlock, errorToast],
  );

  return (
    mdf &&
    config &&
    block &&
    !loading && (
      <div onClick={preventDefaultAndPropagation} onKeyDown={() => {}}>
        <FieldWrapper>
          <FieldHeader variant="overline">Block title</FieldHeader>
          <Input initialValue={block.mTitle ?? ''} updateValue={doUpdateTitle} />
        </FieldWrapper>
        {config.canSchedule && (
          <FieldWrapper>
            <FieldHeader variant="overline">{config.mDescription ?? 'Scheduled at'}</FieldHeader>
            <ScheduleDatePicker
              showTime
              transparentBackground
              dateRange={
                block.mPublishingAt ? { startDate: block.mPublishingAt, endDate: null } : null
              }
              onDateRangeChange={(dateRange) => {
                doUpdateScheduleDate(dateRange?.startDate ?? null);
              }}
              canSelectRange={false}
              disableUpdate={false}
              hideCloseIcon
            />
          </FieldWrapper>
        )}
        <Box margin="5px 0 0 0">
          <MdfEditor
            view="default"
            fields={mdf.fields}
            defaultLayoutSettings={mdf.views.default}
            layoutSettings={mdf.views.default}
            metadata={metadata}
            permissions={mdf.permissions}
            updateFieldValue={updateFieldValues}
            errorMap={errorMap}
          />
        </Box>
      </div>
    )
  );
}

export default EditPlanningItem;
