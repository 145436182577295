import { useGetMdfs } from 'api/mdf/useGetMdfs';
import useOpenMember from 'components/contextMenu/useOpenMember';
import EditPlanningItem from 'features/sidepanel/EditPlanningItem';
import { Box } from 'layouts/box/Box';
import { MemberType } from 'types/graphqlTypes';

import type { PreviewProps } from '../utils/childMap';

import { PlanningItemHeader } from './Headers';

export default function PlanningItemPreview({
  member,
  onClose,
}: Readonly<PreviewProps & { member: MemberType }>) {
  const { openItem } = useOpenMember();
  const { mdfs } = useGetMdfs();
  if (!member) {
    return <div>No preview found</div>;
  }
  const mdf = mdfs.find((m) => m.id === member.mdfId);

  return (
    <Box width="800px" height="100%">
      <PlanningItemHeader
        item={member}
        onClose={onClose}
        title={member?.mTitle ?? ''}
        handleOpen={() => openItem(member)}
      />
      <Box padding="10px" overflow="auto" height="calc(100% - 42px)">
        <EditPlanningItem blockId={member.mRefId} mdf={mdf} resourceId={member.mId} />
      </Box>
    </Box>
  );
}
