/**
 * This function can be used to sort objects with labels
 * @param a The first object
 * @param b The second object
 * @returns
 * * `0` if a's label is the same as b's label,
 * * `-1` if a should be before b
 * * `1` if a should be after b
 */
export function localeLabelCompare<T extends { readonly label: string }>(a: T, b: T): number {
  if (a.label === b.label) return 0;
  const lowerCaseA = a.label.toLocaleLowerCase();
  const lowerCaseB = b.label.toLocaleLowerCase();
  if (lowerCaseA === lowerCaseB) return a < b ? -1 : 1;
  return lowerCaseA.localeCompare(lowerCaseB);
}
