import { useCallback } from 'react';
import { useApolloClient } from '@apollo/client';

import BATCH_GET_ASSETS from 'operations/queries/batchGetAssets';
import GET_ASSET_FROM_CACHE from 'operations/queries/getAssetFromCache';
import { Asset } from 'types';
import { chunkArray } from 'utils/arrayUtils';

export type AssetToFetch = Pick<Asset, 'mId' | 'mRefId'> & {
  instanceId: string;
  state?: string;
};

export type ExtendedAsset = Asset & {
  instanceId: string;
  state?: string;
};

type AssetInput = {
  mId: string;
  mRefId: string;
};

const useBatchGetAssets = () => {
  const client = useApolloClient();

  const batchGetAssets = useCallback(
    async (assetsList: AssetToFetch[], ignoreCache: boolean) => {
      const assetFetchInput: AssetInput[] = [];

      const AssetMap: Map<string, Asset | AssetToFetch> = new Map();
      const allMembersMap: Map<string, Asset> = new Map();

      const populateAssetMap = (asset: AssetToFetch) => {
        const { mId, mRefId } = asset || {};
        if (!mId || !mRefId) return;

        const assetDataInCache = client.readFragment<Asset>({
          id: `${mId}:${mRefId}`,
          fragment: GET_ASSET_FROM_CACHE,
        });

        if (assetDataInCache && !ignoreCache)
          AssetMap.set(mRefId, { ...assetDataInCache, ...asset });
        else {
          AssetMap.set(`fetch-${mRefId}`, { ...asset });
          if (!assetFetchInput.find((a) => a.mRefId === mRefId)) {
            assetFetchInput.push({ mId, mRefId });
          }
        }
      };

      const getAsset = (inputAsset: AssetToFetch) => {
        const requestedAsset = AssetMap.get(`fetch-${inputAsset.mRefId}`);

        if (requestedAsset) {
          const DbAsset = allMembersMap.get(inputAsset.mRefId);
          if (DbAsset) AssetMap.set(inputAsset.mRefId, { ...requestedAsset, ...DbAsset });
          AssetMap.delete(`fetch-${inputAsset.mRefId}`);
        }

        return { ...AssetMap.get(inputAsset.mRefId), instanceId: inputAsset.instanceId };
      };

      assetsList.forEach(populateAssetMap);

      const chunkedInput = assetFetchInput.length ? chunkArray(assetFetchInput, 100) : [];
      const promises = chunkedInput.length
        ? chunkedInput.map((keys) =>
            client.query<{ batchGetMembers: Asset[] }>({
              query: BATCH_GET_ASSETS,
              variables: {
                input: { keys },
              },
              fetchPolicy: 'network-only',
            }),
          )
        : [];

      const responses = promises.length ? await Promise.all(promises) : null;

      if (responses && Array.isArray(responses)) {
        responses.forEach((resp) =>
          resp?.data?.batchGetMembers?.forEach(
            (DbAsset) => DbAsset?.mRefId && allMembersMap.set(DbAsset.mRefId, { ...DbAsset }),
          ),
        );
      }

      return assetsList.map(getAsset);
    },
    [client],
  );

  return [batchGetAssets];
};

export default useBatchGetAssets;
