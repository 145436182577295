import { memo, useCallback, useRef } from 'react';
import isEmpty from 'lodash/isEmpty';

import { getDefaultLayoutSettings } from 'components/editMdfDialog/utils';
import Tooltip from 'components/tooltip';
import { useEditFieldValueDialog } from 'features/mdf/mdf-utils';
import { EmptyValueWrapper } from 'features/orderForm/styled';

import type CellProps from '../cellProps';
import { isSingleArray } from '../utils';

import { ChoiceTags } from './ChoiceTags';

import { ChoiceTagWrapper } from './styled';

function MultipleChoiceCell({
  fieldModel,
  value,
  setValue,
  disableEdit,
  mdf,
  fieldSettings,
  onFocus,
  onBlur,
}: Readonly<CellProps>) {
  const ref = useRef<HTMLDivElement | null>(null);
  const [, showEditFieldDialog] = useEditFieldValueDialog();
  const { hint, colors = {} } = fieldSettings ?? getDefaultLayoutSettings(fieldModel);

  const showFieldEditor = useCallback(() => {
    const onDone = () => {
      onBlur?.();
      // Timeout is needed because of race conditions
      // when user is clicking confirm button in dialog.
      setTimeout(() => {
        ref.current?.closest('td')?.focus();
      }, 100);
    };

    if (!disableEdit) {
      onFocus?.();
      showEditFieldDialog({
        startValue: value,
        fieldId: fieldModel.fieldId,
        headerText: `Edit ${fieldSettings?.label ?? fieldModel.fieldId}`,
        viewType: 'default',
        mdf,
        onConfirm: (v) => {
          setValue(v);
          onDone();
        },
        onCancel: onDone,
      });
    }
  }, [
    showEditFieldDialog,
    setValue,
    onBlur,
    onFocus,
    disableEdit,
    mdf,
    fieldModel,
    fieldSettings,
    value,
  ]);

  if (isSingleArray(value) && !isEmpty(value)) {
    return (
      <Tooltip title={disableEdit ? 'You are not allowed to edit this field' : hint}>
        <ChoiceTagWrapper
          ref={ref}
          tabIndex={disableEdit ? -1 : 0}
          onFocus={() => showFieldEditor()}
        >
          <ChoiceTags
            colors={colors}
            fieldModel={fieldModel}
            value={value}
            onClick={showFieldEditor}
          />
        </ChoiceTagWrapper>
      </Tooltip>
    );
  }
  return (
    <Tooltip title={disableEdit ? 'You are not allowed to edit this field' : hint}>
      <EmptyValueWrapper
        ref={ref}
        tabIndex={disableEdit ? -1 : 0}
        onFocus={() => showFieldEditor()}
        onClick={() => showFieldEditor()}
      >
        Set value
      </EmptyValueWrapper>
    </Tooltip>
  );
}

export default memo(MultipleChoiceCell);
