import { memo } from 'react';

import useDateTimeUtils from 'hooks/useDateTimeUtils';
import { useRundownV2Atom } from 'screens/rundown/rundownV2/state/rundown';
import {
  useCurrentTabArchived,
  useCurrentTabSelectedDate,
  useCurrentTabType,
  useToolbar,
} from 'store';

import { StyledAppBar as AppBar, StyledToolbar as ToolBar } from './styled';

function Toolbar() {
  const [useV2] = useRundownV2Atom();
  const { isToday } = useDateTimeUtils();
  const [toolbar] = useToolbar();
  const [currentTabType] = useCurrentTabType();
  const [currentTabSelectedDate] = useCurrentTabSelectedDate();
  const [currentTabArchived] = useCurrentTabArchived();

  const reduceHeight =
    (currentTabType === 'rundown' || currentTabType === 'rundowntemplate') && useV2;

  return (
    <AppBar
      position="relative"
      $height={reduceHeight ? 10 : 44}
      $mType={currentTabType}
      $isToday={currentTabType === 'rundown' && isToday(currentTabSelectedDate)}
      $isStory={currentTabType === 'story'}
      $isArchived={currentTabArchived}
    >
      <ToolBar $height={reduceHeight ? 10 : 44}>{toolbar}</ToolBar>
    </AppBar>
  );
}

export default memo(Toolbar);
