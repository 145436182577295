import { useCallback, useMemo, useState } from 'react';

import useGetNotes from 'features/notes/api/useGetNotes';

import Group, { Empty } from '../Group';

import Note, { NoteSkeleton } from './Note';

function Notes({ storyId }: Readonly<{ storyId: string }>) {
  const [collapsed, setCollapsed] = useState(true);
  const { data: notes, loading } = useGetNotes(storyId, !storyId);

  const toggleCollapsed = useCallback(() => setCollapsed(!collapsed), [collapsed]);

  const moreCount = useMemo(() => Math.max(notes.length - 3, 0), [notes.length]);

  return (
    <Group
      label="Research Notes"
      collapsed={moreCount > 0 ? collapsed : undefined}
      toggleCollapsed={toggleCollapsed}
      moreCount={moreCount}
    >
      <>
        {loading ? (
          Array.from({ length: 3 }, (_, index) => <NoteSkeleton key={index} />)
        ) : notes?.length === 0 ? (
          <Empty label="Notes" />
        ) : (
          ((collapsed && notes.length > 3 ? notes.slice(0, 3) : notes) ?? []).map((note) => (
            <Note key={note.mRefId} note={note} />
          ))
        )}
      </>
    </Group>
  );
}

export default Notes;
