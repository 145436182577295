import React, { memo, useRef, useState } from 'react';

import { getDefaultLayoutSettings } from 'components/editMdfDialog/utils';
import { isPointInTimeField, TOTAL_TIME_FIELD_KEY } from 'features/mdf/mdf-defaults';
import type { TimingValue } from 'types/graphqlTypes';
import convertInputStringToTimeString from 'utils/convertInputStringToTimeString';

import type CellProps from '../cellProps';

import { StyledCellInput } from './styled';

type TimingCellProps = CellProps & {
  setEditing: (val: boolean) => void;
  setCellValue: React.Dispatch<React.SetStateAction<TimingValue | null>>;
  setFlashError: (val: boolean) => void;
  value: TimingValue | null;
};

const keysToIgnore = ['Space', 'ArrowRight', 'ArrowLeft'];

/**
 * Represents a Timing Cell input, typically used in Rundown workflows.
 *
 * This component is designed to only render when editing.
 */
function TimingCell({
  fieldModel,
  value,
  setValue,
  setCellValue,
  setFlashError,
  setEditing,
  fieldSettings,
  disableEdit: disableEditProp,
  onFocus,
  onBlur,
}: Readonly<TimingCellProps>) {
  const { hint } = fieldSettings ?? getDefaultLayoutSettings(fieldModel);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const startValue = useRef<TimingValue | null>(value);
  const [localValue, setLocalValue] = useState(value?.value);
  const shouldCancel = useRef(false);

  const disableEdit = disableEditProp || fieldModel.fieldId === TOTAL_TIME_FIELD_KEY;

  const doOnFocus = (ev: React.FocusEvent<HTMLInputElement>) => {
    ev.currentTarget.select();
    onFocus?.();
  };

  const doBlur = (ev: React.FocusEvent<HTMLInputElement>) => {
    onBlur?.();

    const originalValue = startValue.current?.value;
    if (originalValue === ev.target.value) {
      setEditing(false);
      return;
    }

    // User is attempting to clear the field
    if (ev.target.value === '-' || ev.target.value === '') {
      setCellValue((prev) => {
        if (prev?.manual) {
          // Value was manual, we revert to autoValue
          const updated = {
            ...prev,
            value: prev.autoValue ?? '',
            manual: false,
          };
          setValue(updated);
          return updated;
        } else if (!prev?.manual) {
          // With manual false, we assume the user
          // is attempting to override autoValue with
          // 00:00
          const updated = {
            ...prev,
            value: '00:00',
            manual: true,
          };
          setValue(updated);
          return updated;
        }
        return prev;
      });
      setEditing(false);
      return;
    }

    const converted = convertInputStringToTimeString(ev.target.value);
    if (originalValue === converted.value || (!ev.target.value && !originalValue)) return;
    if (shouldCancel.current || converted.isError === true) {
      setCellValue(startValue.current);
      if (converted.isError && !shouldCancel.current) {
        setFlashError(true);
      }
      shouldCancel.current = false;
      setEditing(false);
      return;
    }

    setCellValue((prev) => {
      if (prev === null) {
        return {
          value: converted.value,
          manual: true,
        };
      }
      return {
        ...prev,
        value: converted.value,
        manual: true,
      };
    });
    setValue({ ...(value as TimingValue), value: converted.value, manual: true });
    setEditing(false);
  };

  const onKeyDown = (ev: React.KeyboardEvent<HTMLInputElement>) => {
    if (keysToIgnore.includes(ev.code)) {
      ev.stopPropagation();
    }
    if (ev.code === 'Escape') {
      shouldCancel.current = true;
    }
    if (ev.code === 'ArrowDown' || ev.code === 'ArrowUp') {
      inputRef.current?.closest('td')?.focus();
    }
  };

  const onChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setLocalValue(ev.currentTarget.value);
  };

  return (
    <StyledCellInput
      autoFocus
      ref={inputRef}
      disabled={disableEdit}
      title={hint}
      value={localValue}
      placeholder={isPointInTimeField[fieldModel.fieldId] ? '00:00:00' : '00:00'}
      onChange={onChange}
      onBlur={doBlur}
      onFocus={doOnFocus}
      onKeyDown={onKeyDown}
    />
  );
}

export default memo(TimingCell);
