import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import PropTypes from 'prop-types';
import { useUnreadChatNotification } from 'store/chat';
import Tooltip from 'components/tooltip';
import TourPopup from 'components/tourPopup';
import memberTypes from 'operations/memberTypes';
import useCheckUserRight from 'hooks/useCheckUserRight';
import useSettingsValue from 'hooks/useSettingsValue';
import { tours, useCurrentTabValue, useFeedTickerVisible, useExternalUrls } from 'store';
import { ReactComponent as ChatOff } from 'assets/icons/systemicons/ContainerRight_Navbar/story_rundown_chat_off.svg';
import { ReactComponent as ChatOn } from 'assets/icons/systemicons/ContainerRight_Navbar/story_rundown_chat_on.svg';
import NotificationPopup, { notificationIdentifiers } from 'components/notificationPopup';
import { useHasUpdatedSubscription } from 'features/dailyNote';

import { leftMenuItems, rightMenuItems, getHtmlPanels } from './utils/menuItems';
import { navbarPosition } from './utils/navbarPosition';

import {
  ButtonPlaceholder,
  Container,
  StyledTab,
  StyledTabs,
  TabsWrapper,
  TickerButton,
  TickerIcon,
} from './verticalNavbar-styled';

const VerticalNavbar = ({ toggleSidebar, selectionChanged, selectionType, hidden, position }) => {
  const [checkUserRight] = useCheckUserRight();
  const [getSettingsValue] = useSettingsValue();
  const [unreadChatState, setUnreadChatNotification] = useUnreadChatNotification();
  const [isTickerVisible, setTickerVisible] = useFeedTickerVisible();
  const [tabIndex, setTabIndex] = useState(0);
  const notificationRef = useRef();
  const feedsTickerRef = useRef();
  const [externalUrls] = useExternalUrls();
  const [hasUpdatedSub] = useHasUpdatedSubscription();
  const tour = position === navbarPosition.LEFT ? tours.LeftTabTour : tours.RightTabTour;
  const tabRefs = useRef({});

  const currentTab = useCurrentTabValue();
  const { type } = currentTab ?? {};

  const notificationCount = currentTab?.id ? unreadChatState[currentTab.id]?.number : 0;

  const getTitleOfChatTooltip = () => {
    switch (type) {
      case memberTypes.STORY:
        return 'Story chat';
      case memberTypes.RUNDOWN:
        return 'Rundown chat';
      case memberTypes.PITCH:
        return 'Pitch chat';
      case memberTypes.SPACE:
        return 'Space chat';
      default:
        return 'Chat';
    }
  };

  const enabledItems = useMemo(() => {
    if (position === navbarPosition.LEFT) {
      return leftMenuItems.filter((item) => {
        switch (item.selectionType) {
          case memberTypes.USER_BOOKMARK:
            return checkUserRight('search', 'my-bookmarks');
          case memberTypes.USER:
            return checkUserRight('search', 'my-stories');
          case memberTypes.DEPARTMENT_USER:
            return checkUserRight('search', 'my-departments');
          case memberTypes.TEAM_USER:
            return checkUserRight('search', 'my-teams');
          case memberTypes.INSTANCE:
            return checkUserRight('search', 'all-instances');
          case memberTypes.STORY:
            return checkUserRight('search', 'all-stories');
          case memberTypes.PITCH:
            return checkUserRight('search', 'all-pitches');
          default:
            return true;
        }
      });
    }

    if (position === navbarPosition.RIGHT) {
      let ret = [];

      if (checkUserRight('chat', 'access')) {
        ret.push({
          title: getTitleOfChatTooltip(),
          selectionType: 'chat',
          SelectedComponent: ChatOn,
          UnselectedComponent: ChatOff,
          divider: true,
        });
      }

      const otherItems = rightMenuItems.filter((item) =>
        checkUserRight('folder', `show-${item.title.toLowerCase()}`),
      );

      ret.push(...otherItems);

      if (getSettingsValue('mos.usemoshtmlautomationpanel') !== 'true') {
        ret = ret.filter((item) => item.selectionType !== 'mos');
      }

      const htmlPanels = getHtmlPanels(externalUrls);
      return [...htmlPanels, ...ret];
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [position, type, externalUrls]);

  useEffect(() => {
    setTabIndex(enabledItems.findIndex((item) => item.selectionType === selectionType) ?? 0);
  }, [enabledItems, selectionType, externalUrls]);

  const handleTabChange = useCallback(
    (_event, index) => {
      if (tabIndex === index) {
        toggleSidebar();
      } else {
        setTabIndex(index);
        selectionChanged(enabledItems[index].selectionType);
        hidden && toggleSidebar();
      }
    },
    [enabledItems, hidden, selectionChanged, setTabIndex, tabIndex, toggleSidebar, currentTab],
  );

  const activateTicker = () => {
    setTickerVisible(true);
  };

  const onClick = (viewItem) => {
    if (viewItem?.selectionType === 'chat' && notificationCount !== 0 && currentTab !== null) {
      const { id } = currentTab;
      const unreadState = unreadChatState[id]
        ? { ...unreadChatState[id] }
        : {
            number: 0,
            timeStamp: new Date().toISOString(),
          };
      unreadState.number = 0;
      unreadState.timeStamp = new Date().toISOString();

      setUnreadChatNotification((prevValue) => {
        return {
          ...prevValue,
          [currentTab.id]: unreadState,
        };
      });
    }
  };

  return (
    <Container $position={position}>
      <TabsWrapper>
        {position === navbarPosition.LEFT ? (
          <TourPopup
            position="right"
            anchor={notificationRef}
            tourStep={tours.MainTour.steps.LeftPanel}
            tourId={tours.MainTour.id}
          />
        ) : (
          <TourPopup
            position="left"
            anchor={notificationRef}
            tourStep={tours.MainTour.steps.RightPanel}
            tourId={tours.MainTour.id}
          />
        )}
        {enabledItems.map((viewItem) => {
          const tourStep = tour.steps[viewItem.title];

          return (
            <React.Fragment key={viewItem.title}>
              {viewItem.selectionType === 'contacts' && (
                <NotificationPopup
                  position="left"
                  anchor={tabRefs[viewItem.title]}
                  id={notificationIdentifiers.ExternalContacts}
                  title="Contacts have moved here"
                  text="Contacts & users are now separated in two separate tabs, easily search for your contacts here."
                />
              )}
              {tourStep && (
                <TourPopup
                  position="right"
                  anchor={tabRefs[viewItem.title]}
                  tourStep={tourStep}
                  tourId={tour.id}
                />
              )}
            </React.Fragment>
          );
        })}
        <StyledTabs
          ref={notificationRef}
          value={tabIndex}
          orientation="vertical"
          onChange={handleTabChange}
          $hidden={hidden}
          $position={position}
        >
          {enabledItems.map((viewItem) => {
            const { title, SelectedComponent, UnselectedComponent } = viewItem;
            const isSelected = !hidden && selectionType === viewItem.selectionType;
            const Label = isSelected ? SelectedComponent : UnselectedComponent;

            return (
              <StyledTab
                ref={(element) => {
                  tabRefs[title] = element;
                }}
                key={title}
                tabIndex={0}
                disableRipple
                onClick={() => onClick(viewItem)}
                label={<Tooltip title={title}>{<Label />}</Tooltip>}
                $divider={viewItem.divider}
                $hidden={hidden}
                $notificationCount={
                  viewItem.selectionType === 'chat' && notificationCount ? notificationCount : 0
                }
                $hasUpdate={viewItem.selectionType === 'dailyNote' && hasUpdatedSub}
              />
            );
          })}
        </StyledTabs>
      </TabsWrapper>
      {!isTickerVisible &&
        (position === navbarPosition.LEFT ? (
          <>
            <TourPopup
              position="top"
              anchor={feedsTickerRef}
              tourStep={tours.MainTour.steps.FeedsTicker}
              tourId={tours.MainTour.id}
            />
            <Tooltip key="ticker" title="Feeds Ticker">
              <TickerButton role="button" onClick={activateTicker} ref={feedsTickerRef}>
                <TickerIcon />
              </TickerButton>
            </Tooltip>
          </>
        ) : (
          <ButtonPlaceholder />
        ))}
    </Container>
  );
};

VerticalNavbar.propTypes = {
  /**  call parent component func to hide sidebar */
  toggleSidebar: PropTypes.func.isRequired,
  /**  call parent component func to change selection */
  selectionChanged: PropTypes.func.isRequired,
  /** selection type */
  selectionType: PropTypes.string,
  /** is the sidebar hidden */
  hidden: PropTypes.bool.isRequired,
  /** position of the sidebar */
  position: PropTypes.oneOf(Object.values(navbarPosition)).isRequired,
};

VerticalNavbar.defaultProps = {
  selectionType: null,
};

export default memo(VerticalNavbar);
