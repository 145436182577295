import { useCallback } from 'react';

import { useRightHidden, useRightSelection } from 'store/sidebar';
import openAssetInMimir, { isMimirAssetItem } from 'utils/openAssetInMimir';

const useOpenAssetInMimir = () => {
  const [, setRightHidden] = useRightHidden();
  const [, setRightSelection] = useRightSelection();

  const openInMimir = useCallback(
    (element) => {
      if (!isMimirAssetItem(element)) return;
      setRightHidden(false);
      setRightSelection('storybox');
      openAssetInMimir(element);
    },
    [setRightHidden, setRightSelection],
  );
  return openInMimir;
};

export default useOpenAssetInMimir;
