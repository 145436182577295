import { MouseEventHandler, useMemo } from 'react';

import { ReactComponent as CalendarSrc } from 'assets/icons/systemicons/calendar_small_on.svg';
import { ReactComponent as FailedSrc } from 'assets/icons/systemicons/status/error.svg';
import useDateTimeUtils from 'hooks/useDateTimeUtils';
import useTimezoneSettings from 'hooks/useTimezoneSettings';
import { toTimeZoneDate } from 'utils/dateTime';
import getLocaleTimeFormat from 'utils/dateTime/getLocaleTimeFormat';

import useStyles from './datetime-indicator-styles';

interface DatetimeIndicatorProps {
  datetime: string;
  format: string;
  onClick: MouseEventHandler<HTMLDivElement>;
  isFailedState?: boolean;
  isDisabled?: boolean;
}

function DatetimeIndicator({
  datetime,
  onClick,
  format,
  isFailedState,
  isDisabled,
}: Readonly<DatetimeIndicatorProps>) {
  const timezone = useTimezoneSettings();
  const { format: fnsFormat, isToday, isTomorrow } = useDateTimeUtils();
  const classes = useStyles({ isFailedState, isDisabled });
  const dateObject = toTimeZoneDate(timezone)(datetime);

  const dateTimeToShow = useMemo(() => {
    if (isToday(dateObject)) return `Today / ${fnsFormat(dateObject, getLocaleTimeFormat())}`;
    if (isTomorrow(dateObject)) return `Tomorrow / ${fnsFormat(dateObject, getLocaleTimeFormat())}`;
    return fnsFormat(dateObject, format);
  }, [dateObject]);

  return (
    <div className={classes.root} {...{ onClick }} role="presentation">
      {isFailedState ? (
        <FailedSrc className={classes.iconFailed} />
      ) : (
        <CalendarSrc className={classes.iconCalender} />
      )}
      <div className={classes.content}>{dateTimeToShow}</div>
    </div>
  );
}

export default DatetimeIndicator;
