import { memo, useMemo } from 'react';
import capitalize from 'lodash/capitalize';
import intersectionBy from 'lodash/intersectionBy';

import { ReactComponent as Share } from 'assets/icons/systemicons/share_small.svg';
import { AvatarProps } from 'components/avatar/Avatar';
import Text from 'components/text';
import Tooltip from 'components/tooltip/Tooltip';
import { Center } from 'layouts/box/Box';
import { useAllMembers } from 'store';
import { AssignedMember } from 'types';
import { MemberTypeEnum } from 'types/graphqlTypes';

import AvatarGroup from './AvatarGroup';

import { AssignButton, AssigneeWrapper, TooltipText } from './styled';

interface TooltipWrapperProps {
  members: AssignedMember[];
  maxNameToShow?: number;
  children: React.ReactElement;
}

interface AssigneesProps
  extends Pick<AvatarProps, 'size' | 'borderWidth' | 'borderColor'>,
    Pick<TooltipWrapperProps, 'maxNameToShow'> {
  members: readonly AssignedMember[];
  contacts?: readonly AssignedMember[];
  maxAvatarToShow?: number;
  buttonTitle?: string;
  onClick?: () => void;
  disabled?: boolean;
  dark?: boolean;
  variant?: 'default' | 'toolbar';
}

const getVisibleMembers = (
  overflow: number,
  assignedMembers: AssignedMember[],
  maxAvatarToShow: number,
) => (overflow > 0 ? assignedMembers.slice(0, maxAvatarToShow) : assignedMembers);

function Assignees({
  members,
  contacts = [],
  size = 32,
  borderWidth = 1.5,
  borderColor,
  maxAvatarToShow = 3,
  maxNameToShow = 10,
  buttonTitle,
  onClick,
  dark,
  disabled,
  variant = 'default',
}: Readonly<AssigneesProps>) {
  members ??= [];
  const [allMembers] = useAllMembers();
  const assignedMembers = useMemo(
    () => intersectionBy(allMembers, members, 'mId').concat(contacts),
    [allMembers, contacts, members],
  );

  const avatarOverflow = Math.max(0, assignedMembers.length - maxAvatarToShow);
  const visibleAvatars = getVisibleMembers(avatarOverflow, assignedMembers, maxAvatarToShow);

  const titleOverflow = Math.max(0, assignedMembers.length - maxNameToShow);
  const visibleTitles = getVisibleMembers(titleOverflow, assignedMembers, maxNameToShow);

  const tooltipTitle = useMemo(() => {
    const titles = visibleTitles
      .map((member) =>
        member.mType === ('user' as MemberTypeEnum)
          ? member.mTitle
          : `${member.mTitle} (${capitalize(member.mType)})`,
      )
      .join('\n');

    return titleOverflow > 0 ? `${titles}\n...and ${titleOverflow} more` : titles;
  }, [visibleTitles, titleOverflow]);

  return (
    <Tooltip
      title={
        tooltipTitle ? (
          <TooltipText variant="caption" color="whiteHighEmphasis">
            <Text variant="caption" color="whiteMediumEmphasis">
              Assignees{'\n'}
            </Text>
            {tooltipTitle}
          </TooltipText>
        ) : (
          'Assign'
        )
      }
    >
      {variant === 'toolbar' ? (
        <AssigneeWrapper
          $dark={dark}
          $size={size}
          $disabled={disabled}
          $overflow={avatarOverflow > 0 || assignedMembers.length < 1}
          onClick={onClick}
        >
          <AssignButton $size={size} id="assign-button">
            <Share className={dark ? 'skipOverride' : ''} />
            <Text variant="button" color={dark ? 'whiteHighEmphasis' : 'highEmphasis'}>
              {assignedMembers.length > 0 ? buttonTitle : 'Assign'}
            </Text>
          </AssignButton>
          <AvatarGroup
            borderWidth={borderWidth}
            borderColor={borderColor}
            users={visibleAvatars}
            size={size - (size >= 32 ? 8 : 4)}
            overflow={avatarOverflow}
            dark={dark}
          />
        </AssigneeWrapper>
      ) : (
        <Center onClick={onClick} style={{ cursor: 'pointer' }}>
          <AvatarGroup
            borderWidth={borderWidth}
            borderColor={borderColor}
            users={visibleAvatars}
            size={size}
            overflow={avatarOverflow}
            dark={dark}
          />
          {!!onClick && <Share className={dark ? 'skipOverride' : ''} />}
        </Center>
      )}
    </Tooltip>
  );
}

export default memo(Assignees);
