import { atom, useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

import { EditorFontSize } from 'types';

export const toolbarButtonIds = {
  ASSETS: 'assets',
  READY: 'ready',
  PREPARING: 'preparing',
  EDITOR: 'editor',
};

export const assetsLocations = {
  LEFT: 'left',
  RIGHT: 'right',
};

export type ToolbarButtonId = 'assets' | 'ready' | 'preparing' | 'editor';

export interface ToolbarButton {
  id: ToolbarButtonId;
  selected: boolean;
}

export type ToolbarButtons = Record<ToolbarButtonId, boolean>;

const fromLegacyToObject = (state: ToolbarButton[]): ToolbarButtons => {
  return {
    assets: state.find((s) => s.id === 'assets')?.selected ?? true,
    ready: state.find((s) => s.id === 'ready')?.selected ?? true,
    preparing: state.find((s) => s.id === 'preparing')?.selected ?? true,
    editor: state.find((s) => s.id === 'editor')?.selected ?? true,
  };
};

export const toLegacyArray = (state: ToolbarButtons): ToolbarButton[] => {
  const arr: ToolbarButton[] = [];
  for (const [button, selected] of Object.entries(state)) {
    arr.push({ id: button as ToolbarButtonId, selected });
  }
  return arr;
};

const RND_DEFAULT_TOOLBAR_BUTTONS: ToolbarButton[] = [
  { id: 'assets', selected: true },
  { id: 'ready', selected: true },
  { id: 'preparing', selected: true },
  { id: 'editor', selected: true },
];

interface RundownToolbarState {
  toolbarButtons: ToolbarButton[];
  editorFontSize: EditorFontSize;
  assetsLocation: 'left' | 'right';
}

const RND_DEFAULT_STATE: RundownToolbarState = {
  toolbarButtons: RND_DEFAULT_TOOLBAR_BUTTONS,
  editorFontSize: 'small',
  assetsLocation: 'left',
};

const RND_STORAGE_KEY = 'rundown';

const savedRundownStates = JSON.parse(
  window.localStorage.getItem(RND_STORAGE_KEY) ?? '{}',
) as RundownToolbarState;
const savedRundownToolbarButtons = savedRundownStates?.toolbarButtons || [];

const mergedToolbarButtons = RND_DEFAULT_TOOLBAR_BUTTONS.map(
  (toolbarButton) =>
    savedRundownToolbarButtons.find((button) => button.id === toolbarButton.id) || toolbarButton,
);
const mergedValue = {
  ...RND_DEFAULT_STATE,
  ...savedRundownStates,
  toolbarButtons: mergedToolbarButtons,
};

const rundownStorageAtom = atom(mergedValue);

const rundownAtom = atom(
  (get) => get(rundownStorageAtom),
  (_get, set, nextValue: RundownToolbarState) => {
    set(rundownStorageAtom, nextValue);
    localStorage.setItem(RND_STORAGE_KEY, JSON.stringify(nextValue));
  },
);

export const useRundown = () => useAtom(rundownAtom);

const rundownToolbarButtonsAtom = atom(
  (get) => fromLegacyToObject(get(rundownAtom)?.toolbarButtons),
  (get, set, nextValue: ToolbarButtons) => {
    const currentState = get(rundownAtom);
    const toArray = toLegacyArray(nextValue);
    const updatedState = {
      ...currentState,
      toolbarButtons: toArray,
    };
    set(rundownAtom, updatedState);
  },
);

export const useRundownToolbarButtons = () => useAtom(rundownToolbarButtonsAtom);

const rundownEditorFontSizeAtom = atom(
  (get) => get(rundownAtom)?.editorFontSize,
  (get, set, nextValue: EditorFontSize) => {
    const currentState = get(rundownAtom);

    const updatedState = {
      ...currentState,
      editorFontSize: nextValue,
    };
    set(rundownAtom, updatedState);
  },
);

export const useRundownEditorFontSize = () => useAtom(rundownEditorFontSizeAtom);

const rundownAssetsLocationAtom = atom(
  (get) => get(rundownAtom)?.assetsLocation,
  (get, set, nextValue: 'left' | 'right') => {
    const currentState = get(rundownAtom);
    const updatedState = { ...currentState, assetsLocation: nextValue };
    set(rundownAtom, updatedState);
  },
);

export const useRundownAssetsLocation = () => useAtom(rundownAssetsLocationAtom);

const rundownGridViewAtom = atomWithStorage('currentRundownGridview', 'rundown-grid-1');
export const useRundownGridView = () => useAtom(rundownGridViewAtom);

const rundownGridViewListAtom = atom([]);
export const useRundownGridViewList = () => useAtom(rundownGridViewListAtom);

const rundownAutoScroll = atomWithStorage('rundown-autoscroll', true);
export const useRundownAutoScroll = () => useAtom(rundownAutoScroll);

const rundownPreviewInstance = atomWithStorage('rundown-preview-instance', false);
export const useRundownPreviewInstance = () => useAtom(rundownPreviewInstance);

const rundownPreviewNextInstance = atomWithStorage('rundown-preview-next-instance', false);
export const useRundownPreviewNextInstance = () => useAtom(rundownPreviewNextInstance);
