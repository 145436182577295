import { useState } from 'react';

import { Button } from 'components/buttons';
import Dialog from 'components/dialogs/DialogBuilder';
import ChoiceField from 'components/mdfEditor/fields/choice/ChoiceField';
import { StyledTextField } from 'components/mdfEditor/fields/text/styled';
import Text from 'components/text/Text';
import Tooltip from 'components/tooltip';
import { HStack, VStack } from 'layouts/box/Box';
import { Alternative, AuthHeader, AuthType, FieldTypeEnum } from 'types/graphqlTypes';

import { programmaticIdRegex } from '../platforms/PlatformsSettings';

import { OptionWrapper } from './styled';

interface Props {
  open: boolean;
  setOpen: (val: boolean) => void;
  alternatives: Alternative[];
  savedOptionList: string;
  doUpdate: (val: {}) => void;
  tenant?: string; // Optional property
  apiKeyId?: string;
  providerSettings?: boolean;
}

// Utility function for validation
const validateInput = (val: string): boolean => programmaticIdRegex.test(val);

export function EditAuthDialogue({
  open,
  setOpen,
  alternatives,
  savedOptionList,
  doUpdate,
  tenant,
  apiKeyId,
  providerSettings,
}: Readonly<Props>) {
  const [resetKey, setResetKey] = useState<boolean>(false);
  const [selectedListId, setSelectedListId] = useState<string | null>(savedOptionList);
  const [state, setState] = useState({
    apiKeyVal: '',
    apiKey: apiKeyId ?? '',
    providerTenant: tenant ?? '',
  });

  const handleUpdate = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (!(name in state) || typeof value !== 'string' || !validateInput(value)) {
      return;
    }
    // Update the state
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const resetApiKey = () => {
    setResetKey(true);
  };

  const confirmChanges = () => {
    // If selectedListId is 'NONE', set headers to an empty object
    const headers: AuthHeader =
      selectedListId === 'NONE'
        ? {}
        : {
            ...(selectedListId === 'APIKEY' &&
              state.apiKeyVal && { apikey: btoa(state.apiKeyVal) }),
            ...(state.providerTenant && { tenant: state.providerTenant }),
            ...(state.apiKey && { apikeyId: state.apiKey }),
          };

    const authParam: { auth: { type: AuthType; headers?: AuthHeader } } = {
      auth: {
        type: selectedListId as AuthType,
        ...(Object.keys(headers).length > 0 && { headers }), // Only include headers if not empty
      },
    };

    doUpdate(authParam);
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      style={{ minWidth: '600px', maxWidth: '800px' }}
    >
      <Dialog.Header>Configure Auth</Dialog.Header>
      <Dialog.Body>
        <HStack alignItems="end" gap="10px" justifyContent="space-between" margin="0 0 10px 0">
          <ChoiceField
            editorId="AuthDialog"
            fieldModel={{
              fieldId: 'optionLists',
              type: FieldTypeEnum.choice,
              defaultValue: { value: null },
              alternatives: alternatives,
            }}
            fieldSettings={null}
            defaultFieldSettings={{
              fieldId: 'optionLists',
              label: 'Choose Auth Type',
              visible: true,
              hint: '',
            }}
            value={selectedListId}
            setValue={(val) => {
              setSelectedListId(val as string | null);
            }}
            errorMessage={undefined}
            style={{ width: '240px' }}
            view="default"
          />
          {savedOptionList === 'APIKEY' && selectedListId !== 'NONE' && (
            <Button
              width={120}
              height={32}
              variant="contained"
              usage={resetKey ? 'outlined' : 'warning'}
              disabled={resetKey}
              onClick={resetApiKey}
              title={resetKey ? '' : 'Reset APIKey'}
            >
              Reset
            </Button>
          )}
        </HStack>
        {((selectedListId === 'APIKEY' && savedOptionList !== 'APIKEY') ||
          (resetKey && selectedListId !== 'NONE')) && (
          <OptionWrapper>
            {providerSettings && (
              <>
                <VStack>
                  <Text variant="caption"> tenant: </Text>
                </VStack>
                <VStack>
                  <Tooltip
                    title={
                      !state.providerTenant && savedOptionList !== 'APIKEY'
                        ? 'This field is required.'
                        : ''
                    }
                  >
                    <StyledTextField
                      name="providerTenant"
                      variant="filled"
                      value={state.providerTenant}
                      onChange={handleUpdate}
                    />
                  </Tooltip>
                </VStack>
              </>
            )}
            <VStack>
              {providerSettings ? (
                <Tooltip title={!state.apiKey ? 'This field is required.' : ''}>
                  <StyledTextField
                    name="apiKey"
                    variant="filled"
                    value={state.apiKey}
                    placeholder="x-api-key"
                    onChange={handleUpdate}
                    required
                    error={!state.apiKey}
                  />
                </Tooltip>
              ) : (
                <Text variant="caption"> x-api-key : </Text>
              )}
            </VStack>
            <VStack>
              <Tooltip
                title={
                  !state.apiKeyVal && savedOptionList !== 'APIKEY' ? 'This field is required.' : ''
                }
              >
                <StyledTextField
                  name="apiKeyVal"
                  variant="filled"
                  value={state.apiKeyVal}
                  placeholder="API Key value"
                  type="password"
                  onChange={handleUpdate}
                  required
                  error={!state.apiKeyVal && savedOptionList !== 'APIKEY'}
                />
              </Tooltip>
            </VStack>
          </OptionWrapper>
        )}
      </Dialog.Body>
      <Dialog.Footer>
        <Dialog.CancelButton />
        <Dialog.ConfirmButton
          label="Confirm"
          onConfirm={confirmChanges}
          disabled={
            (selectedListId === 'APIKEY' && providerSettings
              ? !state.apiKeyVal || !state.apiKey
              : !state.apiKeyVal) && selectedListId !== 'NONE'
          }
        />
      </Dialog.Footer>
    </Dialog>
  );
}
