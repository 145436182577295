/* eslint-disable react/prop-types */
import React, { forwardRef, MouseEventHandler } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import useGetMember from 'api/useGetMember';
import { ReactComponent as Close } from 'assets/icons/systemicons/close.svg';
import { ReactComponent as Open } from 'assets/icons/systemicons/open_small.svg';
import Avatar, { AvatarVariant } from 'components/avatar/Avatar';
import { Button } from 'components/buttons';
import useOpenMember from 'components/contextMenu/useOpenMember';
import Text from 'components/text';
import Tooltip from 'components/tooltip';
import { Center, HStack } from 'layouts/box/Box';
import { MemberType } from 'types/graphqlTypes';

const MemberWrapper = styled('div')<{ variant: 'grid' | 'form' | 'small' }>`
  cursor: pointer;
  height: 32px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  position: relative;
  gap: 8px;
  background: transparent;
  .member-details {
    font-size: 13px;
  }
  border: 1px solid transparent;
  :hover {
    span {
      color: ${({ theme }) => theme.palette.dina.highEmphasis} !important;
    }
  }
  ${({ variant, theme }) => {
    if (variant === 'grid') {
      return css`
        gap: 4px;
        height: 100%;
        max-width: 100%;
        overflow: hidden;
      `;
    }
    if (variant === 'small') {
      return css`
        gap: 4px;
        height: 16px;
      `;
    }
    if (variant === 'form') {
      return css`
        background: ${theme.palette.dina.buttonBackgroundOutlined};
        border-color: ${theme.palette.dina.inputBorderResting};
        padding-left: 8px;
      `;
    }
  }}
`;

const Details = styled.div`
  width: 100%;
  display: flex;
  white-space: pre;
  flex-direction: column;
  font-size: 14px;
  color: ${({ theme }) => theme.palette.dina.highEmphasis};
  div:last-child {
    font-size: 11px;
  }
`;

const CloseIcon = styled(Close)`
  cursor: pointer;
  &:hover path {
    fill-opacity: 1;
  }
  justify-self: end;
  margin-right: 4px;
`;

const StyledOpen = styled(Open)`
  width: 24px;
  cursor: pointer;
  &:hover path {
    fill-opacity: 1;
  }
`;

const OpenWrapper = styled('div')<{ $rightPos: string }>`
  display: none;
  position: absolute;
  border-radius: 3px;
  right: ${({ $rightPos }) => $rightPos};
  width: 24px;
  height: 24px;
  background-color: ${({ theme }) => theme.palette.dina.surfaceAppBackgroundNavLevel1} !important;
`;

const Wrapper = styled(HStack)`
  height: 100%;
  width: 100%;
  min-width: 50px;
  position: relative;
  &:hover .open-member-details {
    display: block;
  }
`;

const StyledPlaceholder = styled(Text)<{ $color?: string }>`
  color: ${({ theme, $color }) => $color ?? theme.palette.dina.lowEmphasis};
`;

interface Props {
  placeholder?: string;
  memberId?: string;
  member?: MemberType;
  variant: 'grid' | 'form' | 'small';
  style?: React.CSSProperties;
  onClick?: MouseEventHandler;
  onRemoveClick?: MouseEventHandler;
  onFocus?: () => void;
  readOnly?: boolean;
  tabIndex?: number;
  hideDetails?: boolean;
  hideOpen?: boolean;
  textColor?: string;
}

function MemberLabel(
  {
    memberId,
    member,
    variant,
    onClick,
    onFocus,
    onRemoveClick,
    style,
    tabIndex,
    readOnly,
    placeholder,
    hideDetails,
    hideOpen,
    textColor,
  }: Readonly<Props>,
  ref: React.Ref<HTMLButtonElement>,
) {
  const { data } = useGetMember({
    mId: memberId ?? '',
    mRefId: memberId ?? '',
    fetchPolicy: 'cache-first',
    skip: !memberId,
  });
  const { openItem } = useOpenMember();
  const memberToRender = data ?? member;

  const doOpenItem = (ev: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    ev.stopPropagation();
    if (memberToRender) openItem(memberToRender);
  };

  const showClearButton = onRemoveClick && !readOnly;

  return (
    <Wrapper>
      <Button onClick={onClick} asChild tabIndex={tabIndex} onFocus={onFocus} ref={ref}>
        <MemberWrapper variant={variant} style={style} className="member-wrapper">
          {memberToRender ? (
            <>
              <Avatar
                className="avatar"
                variant={memberToRender.mType as AvatarVariant}
                imageKey={memberToRender?.mAvatarKey}
                size={variant === 'small' ? 16 : 20}
                title={memberToRender.mTitle}
              />
              {!hideDetails && (
                <Details className="member-details">{memberToRender?.mTitle}</Details>
              )}
              {showClearButton && (
                <Tooltip title="Remove assignee">
                  <Center>
                    <CloseIcon onClick={onRemoveClick} />
                  </Center>
                </Tooltip>
              )}
            </>
          ) : (
            <StyledPlaceholder variant="listItemLabel" $color={textColor}>
              {readOnly ? 'Unknown' : placeholder ?? 'Find member'}
            </StyledPlaceholder>
          )}
        </MemberWrapper>
      </Button>
      {memberToRender && !hideOpen && (
        <Tooltip title="Open details">
          <OpenWrapper className="open-member-details" $rightPos={showClearButton ? '24px' : '4px'}>
            <StyledOpen onClick={doOpenItem} />
          </OpenWrapper>
        </Tooltip>
      )}
    </Wrapper>
  );
}

export default forwardRef(MemberLabel);
