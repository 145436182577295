import { useCallback, useEffect, useMemo } from 'react';
import { useAtom } from 'jotai';

import memberTypes from 'operations/memberTypes';
import { UploadingFiles, useUploadingFiles } from 'store';
import { Asset } from 'types';
import { MemberTypeEnum } from 'types/graphqlTypes';
import { atomWithSessionStorage } from 'utils/atoms/atomWithSessionStorage';

import useGetAssets from '../api/useGetAssets';

const mediaTypes = ['image', 'video', 'application', 'audio', 'graphics'];

const getAssetGroupLabel = (key: string) => {
  switch (key) {
    case 'image':
      return 'Photos';
    case 'video':
      return 'Videos';
    case 'application':
      return 'Documents';
    case 'audio':
      return 'Audio';
    case 'graphics':
      return 'Graphics';

    default:
      return key;
  }
};

const initialAssetGroups: AssetGroup[] = mediaTypes.map((type) => ({
  key: type,
  label: getAssetGroupLabel(type),
  selected: false,
  count: 0,
}));

/**
 * Filters a list of assets based on the media types defined in asset groups.
 *
 * @param assets - An array of Asset objects representing the assets to be filtered.
 * @param selectedGroups - An array of AssetGroup objects defining the media types for filtering.
 *                    Each AssetGroup object should have a `key` property specifying the media type.
 *
 * @returns A new array containing filtered assets matching media types in asset groups.
 */
const filterAssetsBySelectedGroups = (assets: Asset[], selectedGroups: AssetGroup[]) => {
  const selectedMediaTypes = selectedGroups.map((selectedGroup) => selectedGroup.key);

  return assets.filter((asset) => {
    const itemType = asset.itemType?.toLowerCase() || '';
    const isPdf = asset.mediaType?.includes('pdf') || asset.mTitle?.toLowerCase().includes('.pdf');
    return (
      selectedMediaTypes.includes(itemType) ||
      (selectedMediaTypes.includes('application') && (isPdf || itemType === 'text'))
    );
  });
};

export type AssetGroup = {
  key: string;
  label: string;
  selected: boolean;
  count: number;
};

const assetGroupsAtom = atomWithSessionStorage('assetGroups', initialAssetGroups);

/**
 * Fetches and manages assets for a specific member ID and type.
 *
 * @param props - An object containing the member ID and type.
 *                - `mId`: The unique identifier of the member.
 *                - `memberType` (optional): The type of member (defaults to STORY_ASSET).
 *
 * @returns An object containing state and actions related to the fetched assets.
 *          - `error`: Any error encountered while fetching assets.
 *          - `loading`: Whether assets are currently being fetched (boolean).
 *          - `data`: The fetched asset data.
 *          - `assetGroups`: Groups of assets based on media types.
 *          - `toggleAssetGroup`: Function to toggle an asset group's visibility.
 *          - `assets`: The filtered list of fetched assets based on selected groups.
 *          - `showUploadProgress`: A boolean indicating whether to show the loading indicator.
 */
const useAssets = ({
  mId,
  memberType = memberTypes.STORY_ASSET as MemberTypeEnum,
}: {
  mId: string;
  memberType: MemberTypeEnum;
}) => {
  const [assetGroups, setAssetGroups] = useAtom(assetGroupsAtom);

  const { data, loading, error, refetch } = useGetAssets({ mId, memberType });

  const [uploadingFiles] = useUploadingFiles();

  const showUploadProgress = useMemo(
    () => !!uploadingFiles.find((f: UploadingFiles) => f?.storyId?.includes(mId)),
    [mId, uploadingFiles],
  );

  const disableReset = useMemo(
    () => assetGroups.every((aGroup) => !aGroup.selected),
    [assetGroups],
  );

  const toggleAssetGroup = useCallback(
    (key: string) => {
      setAssetGroups((prev) =>
        prev.map((assetGroup) =>
          assetGroup.key === key ? { ...assetGroup, selected: !assetGroup.selected } : assetGroup,
        ),
      );
    },
    [setAssetGroups],
  );

  const resetAssetGroups = useCallback(() => {
    setAssetGroups((prev) => prev.map((assetGroup) => ({ ...assetGroup, selected: false })));
  }, [setAssetGroups]);

  const selectedGroups = useMemo(
    () => assetGroups.filter((assetGroup) => assetGroup.selected),
    [assetGroups],
  );

  const assets = useMemo(() => {
    if (selectedGroups.length === 0) {
      return data ?? [];
    }

    return data ? filterAssetsBySelectedGroups(data, selectedGroups) : [];
  }, [selectedGroups, data]);

  useEffect(() => {
    setAssetGroups((prev) =>
      prev.map((group) => ({
        ...group,
        count:
          data?.filter((asset) => {
            const itemType = asset.itemType?.toLowerCase() || '';
            const isPdf =
              asset.mediaType?.includes('pdf') || asset.mTitle?.toLowerCase().includes('.pdf');
            return (
              itemType === group.key ||
              (group.key.includes('application') && (isPdf || itemType === 'text'))
            );
          })?.length ?? 0,
      })),
    );
  }, [data, setAssetGroups]);

  return {
    error,
    loading,
    data,
    assetGroups,
    assets,
    showUploadProgress,
    disableReset,
    toggleAssetGroup,
    resetAssetGroups,
    refetch,
  };
};

export default useAssets;
