import { createContext } from 'react';
import type { User } from 'types';

export interface UserContextProps {
  mId: string;
  groups: string[];
  attributes?: User;
}

const UserContext = createContext<UserContextProps>({
  mId: '',
  groups: [],
});
export default UserContext;
