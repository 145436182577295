import React, { ChangeEvent } from 'react';
import {
  InputBase,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  OutlinedInput,
  // eslint-disable-next-line sort-imports
  Select as MaterialSelect,
  Typography,
} from '@material-ui/core';

import { ReactComponent as ArrowDoubleIcon } from 'assets/icons/systemicons/arrows/arrow_double_unboxed.svg';
import { ReactComponent as CheckSrc } from 'assets/icons/systemicons/check.svg';
import Divider from 'components/divider';

import useStyles from './select-styles';

export interface SelectItem {
  value: string | number;
  title: string;
  icon?: string | React.ReactElement;
  info?: string;
  showTopDivider?: boolean;
  disabled?: boolean;
  selectionTitle?: string;
}

export interface SelectProps {
  items: SelectItem[];
  selectedValue: string | number;
  usage?: 'default' | 'gridView' | 'datePicker' | 'editor' | 'details' | 'notes';
  label?: string;
  onChange: (value: string | number) => void;
  hideLabel?: boolean;
  placeholder?: string;
  disabled?: boolean;
  open?: boolean;
}

const Select: React.FC<SelectProps> = ({
  items,
  selectedValue,
  label,
  onChange,
  usage = 'default',
  hideLabel = false,
  placeholder,
  disabled = false,
  open,
  ...rest
}) => {
  const isDatePicker = usage === 'datePicker';
  const isEditor = usage === 'editor';
  const isDetails = usage === 'details';
  const isNotes = usage === 'notes';
  const classes = useStyles({ isEditor, isDatePicker, isNotes });
  const isGridView = usage === 'gridView';

  const handleChange = (
    event: ChangeEvent<{
      name?: string;
      value: unknown;
    }>,
  ) => {
    if (typeof event.target.value === 'string' || typeof event.target.value === 'number') {
      onChange(event.target.value);
    }
  };

  const renderValue = () => {
    if (!selectedValue && selectedValue !== 0)
      return placeholder ? <div className={classes.title}>{placeholder}</div> : '—';

    const selectedItem = items.find(({ value }) => value === selectedValue);

    return selectedItem ? (
      <MenuItem
        disableGutters
        disabled={disabled}
        classes={{
          root: classes.menuItem,
          selected: classes.menuItemSelected,
        }}
        /** @ts-expect-error: Mui v4 prop type error */
        ListItemClasses={{
          button: classes.buttonHoverTransparent,
        }}
      >
        {selectedItem.icon && (
          <ListItemIcon classes={{ root: classes.listItemIcon }}>{selectedItem.icon}</ListItemIcon>
        )}
        <ListItemText
          primary={selectedItem?.selectionTitle || selectedItem.title}
          classes={{
            primary: isGridView || isDatePicker || isNotes ? classes.title : '',
          }}
        />
      </MenuItem>
    ) : (
      <MenuItem
        disableGutters
        disabled={disabled}
        classes={{
          root: classes.menuItem,
          selected: classes.menuItemSelected,
        }}
        /** @ts-expect-error: Mui v4 prop type error */
        ListItemClasses={{
          button: classes.buttonHoverTransparent,
        }}
      >
        <ListItemText
          primary="—"
          classes={{
            primary: isGridView || isDatePicker || isNotes ? classes.title : '',
          }}
        />
      </MenuItem>
    );
  };

  const selectMenuItem = (
    value: string | number,
    title: string,
    icon?: string | React.ReactElement,
    info?: string,
    itemDisabled?: boolean,
  ) => (
    <MenuItem
      value={value}
      key={value}
      selected={selectedValue === value}
      classes={{
        root: classes.menuItem,
        selected: classes.menuItemSelected,
      }}
      /** @ts-expect-error: Mui v4 prop type error */
      ListItemClasses={{
        button: classes.buttonHoverOverlay,
      }}
      data-testid={`input-${value}`}
      disabled={itemDisabled}
      button
    >
      {icon && (
        <ListItemIcon classes={{ root: classes[isEditor ? 'listItemIconEditor' : 'listItemIcon'] }}>
          {icon}
        </ListItemIcon>
      )}
      <ListItemText
        primary={title}
        classes={{ primary: isGridView ? classes.title : classes.publishTitle }}
      />
      {info && (
        <Typography classes={{ root: classes.infoText }} align="right">
          {info}
        </Typography>
      )}
      {(isDatePicker || isNotes) && selectedValue === value && (
        <div className={classes.check}>
          <CheckSrc />
        </div>
      )}
    </MenuItem>
  );

  return (
    <div {...rest}>
      {!isEditor && !isGridView && !isDatePicker && !isNotes && !hideLabel && (
        <InputLabel
          htmlFor={label}
          classes={{
            root: classes.inputLabel,
          }}
        >
          {label}
        </InputLabel>
      )}

      <MaterialSelect
        open={open}
        value={selectedValue || ''}
        onChange={handleChange}
        disabled={disabled}
        classes={{
          icon: classes.gridSelectIcon,
          root: classes.root,
          select: classes.select,
        }}
        IconComponent={ArrowDoubleIcon}
        renderValue={renderValue}
        displayEmpty
        input={
          isDetails ? (
            <InputBase
              fullWidth
              name={label}
              id={label}
              classes={{
                root: classes.outlinedInput,
                focused: classes.focused,
                disabled: classes.disabled,
                error: classes.error,
              }}
            />
          ) : (
            <OutlinedInput
              fullWidth
              name={label}
              labelWidth={0}
              id={label}
              classes={{
                root: isGridView ? classes.modifiedOutlinedInput : classes.outlinedInput,
                notchedOutline: classes.notchedOutline,
                input: isGridView ? classes.input : '',
                focused: classes.focused,
                disabled: classes.disabled,
                error: classes.error,
              }}
            />
          )
        }
        MenuProps={{
          classes: {
            paper: classes.menu,
          },
        }}
        variant="standard"
      >
        {items && items.length > 0 ? (
          items.map(({ value, title, icon, info, showTopDivider, disabled: itemDisabled }) =>
            showTopDivider ? (
              <div key={value}>
                <Divider className={classes.divider} />
                {selectMenuItem(value, title, icon, info, itemDisabled)}
              </div>
            ) : (
              selectMenuItem(value, title, icon, info, itemDisabled)
            ),
          )
        ) : (
          <MenuItem
            value=""
            classes={{
              root: classes.menuItem,
              selected: classes.menuItemSelected,
            }}
          >
            No options available
          </MenuItem>
        )}
      </MaterialSelect>
    </div>
  );
};

export default Select;
