import styled from '@emotion/styled';

import { Button as Btn } from 'components/buttons';
import { Collapsible as CollapsiblePrimitive } from 'lib/collapsible';

export const Collapsible = styled(CollapsiblePrimitive)`
  &[data-state='open'] > button > svg:first-of-type {
    transform: rotate(90deg);
  }
`;

/** Override button styles */
export const Button = styled(Btn)`
  &:focus-visible {
    outline: none;
    box-shadow: inset 0 0 0 2px ${({ theme }) => theme.palette.dina.onFocus};
  }

  // Selected state
  &[data-selected='true'] {
    :focus-visible {
      outline: 1px solid ${({ theme }) => theme.palette.dina.whiteHighEmphasis};
      outline-offset: -3px;
      border: 1px solid transparent;
    }
  }

  // Child selected state
  &[data-child-selected='true'] {
    background-color: ${({ theme }) => theme.palette.dina.selectedNotActive};
  }
`;

export const UnorderedList = styled('ul')`
  list-style: none;
  padding: 0;
  margin: 0;
`;
