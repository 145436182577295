import React, { useState } from 'react';
import { useSetActiveTour, useActiveTour, tours } from 'store';
import useDinaNavigate from 'hooks/useDinaNavigate';
import { ReactComponent as Copy } from 'assets/icons/systemicons/copy.svg';
import { ReactComponent as CopyApproved } from 'assets/icons/systemicons/copyApproved.svg';
import { Button } from 'components/buttons';
import Tooltip from 'components/tooltip';

import {
  ContentWrapper,
  Row,
  TourDetails,
  TourDescription,
  TourTitle,
  ButtonWrapper,
  ToursHeader,
  Tours,
  CopyWrapper,
} from './styled';

const UserTour = ({ handleCloseSettings }) => {
  const { navigateTo } = useDinaNavigate();
  const [, setActiveTour] = useSetActiveTour();
  const [, setCurrentStep] = useActiveTour();
  const [copy, setCopy] = useState({});
  const onCopy = (link) => {
    const baseUrl = window.location.origin + window.location.pathname;
    navigator.clipboard.writeText(`${baseUrl}#tour="${link.id}"`);
    setCopy({ [link.id]: true });
    setTimeout(() => {
      setCopy({ [link.id]: false });
    }, 2000);
  };

  const startTour = (id) => {
    setCurrentStep('RESET');
    if (id === tours.StoryHubTour.id) {
      navigateTo('storyhub');
    }
    setActiveTour(id);
    handleCloseSettings();
  };

  return (
    <ContentWrapper>
      <ToursHeader>User Interface Tours</ToursHeader>
      <Tours>
        {Object.values(tours).map((tour) => (
          <Row key={tour.id}>
            <TourDetails>
              <TourTitle>{tour.title}</TourTitle>
              <TourDescription>{tour.description}</TourDescription>
            </TourDetails>
            <ButtonWrapper>
              <Button onClick={() => startTour(tour.id)}>Start tour</Button>
            </ButtonWrapper>
            <CopyWrapper>
              {copy[tour.id] ? (
                <Tooltip title="Copied!">
                  <CopyApproved />
                </Tooltip>
              ) : (
                <Tooltip title="Copy link to tour">
                  <Copy style={{ cursor: 'pointer' }} onClick={() => onCopy(tour)} />
                </Tooltip>
              )}
            </CopyWrapper>
          </Row>
        ))}
      </Tours>
    </ContentWrapper>
  );
};

export default UserTour;
