/* eslint-disable max-len */

import { useCallback, useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';

import { useCreateOrderForm } from 'api/order_forms/useCreateOrderForm';
import Dialog from 'components/dialogs/DialogBuilder';
import { isMiniMemberArray } from 'components/mdfEditor/fields/relation/relation-utils';
import { RelationField } from 'components/mdfEditor/fields/relation/RelationField';
import { MdfDropdown } from 'components/mdfEditor/MdfDropdown';
import { FieldHeader } from 'components/mdfEditor/styled';
import useToast from 'components/toast/useToast';
import { StyledTextField } from 'features/reusableStyled';
import { VStack } from 'layouts/box/Box';
import { generateOrderFormConfigs, MiniMember } from 'types/forms/forms';
import {
  FieldTypeEnum,
  FieldValue,
  LayoutSettings,
  MdfField,
  MemberTypeEnum,
  SearchItemTypeEnum,
} from 'types/graphqlTypes';

const field: MdfField = {
  fieldId: 'relation',
  type: FieldTypeEnum.relation,
  defaultValue: {
    value: 'home',
  },
  filters: {
    types: [SearchItemTypeEnum.space],
  },
  constraint: {
    max: 1,
  },
};

const fieldSettings: LayoutSettings = {
  visible: true,
  fieldId: 'relation',
  label: 'Default space',
  hint: '',
};

interface Props {
  modalOpen: boolean;
  startSection: string;
  setModalOpen: (val: boolean) => void;
  setSelectId: (val: string) => void;
}

const DEFAULT_SPACE_ID = 'home';
const DEFAULT_SPACE_MEMBER: MiniMember[] = [
  {
    id: 'home',
    type: MemberTypeEnum.Space,
  },
];

export function CreateOrderConfigDialog({
  modalOpen,
  startSection,
  setModalOpen,
  setSelectId,
}: Readonly<Props>) {
  const { errorToast } = useToast();
  const { createOrderForm } = useCreateOrderForm();
  const [selectedSpace, setSelectedSpace] = useState<MiniMember[]>(DEFAULT_SPACE_MEMBER);
  const [sectionName, setSectionName] = useState(startSection);
  const [taskName, setTaskName] = useState('');
  const [chosenMdfId, setChosenMdfId] = useState<string | null>(null);

  const createNewForm = useCallback(
    async (mTitle: string, mDescription: string) => {
      const id = uuid();
      createOrderForm({
        mTitle,
        mDescription,
        mId: selectedSpace?.[0]?.id ?? DEFAULT_SPACE_ID,
        mRefId: id,
        mSecId: chosenMdfId ?? undefined,
        mType: MemberTypeEnum.OrderForm,
        configs: generateOrderFormConfigs(),
      })
        .then(() => setSelectId(id))
        .catch(errorToast)
        .finally(() => setModalOpen(false));
    },
    [chosenMdfId, selectedSpace, setModalOpen],
  );

  const onSelectSpace = useCallback(
    (val: FieldValue) => {
      if (isMiniMemberArray(val)) {
        setSelectedSpace(val);
      }
    },
    [setSelectedSpace],
  );

  const disabledTooltip = useMemo(() => {
    const errors: string[] = [];
    if (!taskName) errors.push('Name required');
    if (!chosenMdfId) errors.push('Schema required');
    if (!selectedSpace.length) errors.push('Space required');
    if (errors.length === 0) return null;
    return (
      <div>
        {errors.map((err) => (
          <div key={err}>{err}</div>
        ))}
      </div>
    );
  }, [taskName, chosenMdfId, selectedSpace]);

  return (
    <Dialog open={modalOpen} onClose={() => setModalOpen(false)}>
      <Dialog.Header>Create order form</Dialog.Header>
      <Dialog.Body>
        <MdfDropdown
          header="Select custom schema"
          value={chosenMdfId}
          onSelect={(mdfId) => setChosenMdfId(mdfId)}
          style={{ marginBottom: '5px' }}
          error={!chosenMdfId}
          disableClearable
          optionsType="customOnly"
        />
        <FieldHeader>Task name</FieldHeader>
        <StyledTextField
          value={taskName}
          variant="filled"
          onChange={(ev) => setTaskName(ev.target.value)}
          error={!taskName}
          fullWidth
          style={{ marginBottom: '0px', marginTop: '5px' }}
        />
        <VStack>
          <RelationField
            editorId="space-selector"
            fieldModel={field}
            fieldSettings={null}
            defaultFieldSettings={fieldSettings}
            value={selectedSpace}
            setValue={onSelectSpace}
            style={{ width: '100%' }}
            errorMessage={undefined}
            view="default"
            required
            disablePreview
          />
        </VStack>
        <FieldHeader>Group in section</FieldHeader>
        <StyledTextField
          value={sectionName}
          variant="filled"
          onChange={(ev) => setSectionName(ev.target.value)}
          style={{ marginBottom: '5px', marginTop: '5px' }}
          fullWidth
          helperText={
            !sectionName
              ? 'This form will not be grouped'
              : 'This form will be grouped under ' + sectionName
          }
        />
      </Dialog.Body>
      <Dialog.Footer>
        <Dialog.CancelButton />
        <Dialog.ConfirmButton
          label="Create"
          title={disabledTooltip ?? ''}
          disabled={disabledTooltip !== null}
          onClick={() => createNewForm(sectionName, taskName)}
        />
      </Dialog.Footer>
    </Dialog>
  );
}
