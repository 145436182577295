import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai';
import { focusAtom } from 'jotai-optics';

import { policiesAtom } from 'store/config';
import { MemberTypeEnum, type ResourcePolicy } from 'types/graphqlTypes';
import { moveArrayItem } from 'utils/arrayUtils';
import checkUserRight from 'utils/checkUserRight';

// Story/Pitch type tabs
export const storyTabs = {
  content: 'content',
  notes: 'research',
  instances: 'instances',
  bookings: 'bookings',
  locations: 'locations',
  assets: 'assets',
  resources: 'resources',
  metadata: 'metadata',
  tasks: 'tasks',
  blocks: 'planning',
} as const;

export type StoryTab = keyof typeof storyTabs;
export type StoryTabValue = (typeof storyTabs)[StoryTab];

// Icon Tabs
export type Icon = 'home' | 'feeds' | 'plans' | 'storyhub' | 'maps' | 'test';

export type IconTab = {
  type: Icon;
  title: string;
};

const iconTabs: IconTab[] = [
  { type: 'home', title: 'Home' },
  { type: 'feeds', title: 'Feeds' },
  { type: 'plans', title: 'StoryHub' },
  { type: 'storyhub', title: 'StoryHub' },
  { type: 'maps', title: 'Maps' },
  { type: 'test', title: 'Test' },
];

const hasIconTabPermission = (tab: IconTab, policies: ResourcePolicy[]) => {
  switch (tab.type) {
    case 'home':
      return true;
    case 'feeds':
      return checkUserRight(policies, 'feeds', 'access');
    case 'storyhub':
      return checkUserRight(policies, 'storyhub', 'access');
    case 'maps':
      return checkUserRight(policies, 'feature', 'maps');
    case 'test':
      return checkUserRight(policies, 'feature', 'appsync-events-test');
    default:
      return false;
  }
};

const iconTabsAtom = atom((get) => {
  const policies = get(policiesAtom);

  return iconTabs.filter((tab) => hasIconTabPermission(tab, policies));
});
export const useIconTabs = () => useAtomValue(iconTabsAtom);

// Content Tabs
export type Content = 'pitch' | 'story' | 'rundown' | 'rundowntemplate' | 'space' | 'create';
export const contentTypes: Content[] = ['pitch', 'story', 'rundown', 'rundowntemplate', 'space'];

/**
 * Some mTypes are special, but should still be navigated to using their original base type.
 */
export const getMappedToOtherMType = (mType: MemberTypeEnum) => {
  switch (mType) {
    case MemberTypeEnum.ArchivedStory:
    case MemberTypeEnum.ResStory:
    case MemberTypeEnum.ArchivedResStory:
      return MemberTypeEnum.Story;
    case MemberTypeEnum.ArchivedPitch:
    case MemberTypeEnum.ResPitch:
    case MemberTypeEnum.ArchivedResPitch:
      return MemberTypeEnum.Pitch;
    case MemberTypeEnum.ArchivedInstance:
      return MemberTypeEnum.Instance;
    case MemberTypeEnum.ArchivedRundown:
      return MemberTypeEnum.Rundown;
    case MemberTypeEnum.ArchivedDepartment:
      return MemberTypeEnum.Department;
    case MemberTypeEnum.ArchivedTeam:
      return MemberTypeEnum.Team;
    case MemberTypeEnum.ArchivedUser:
      return MemberTypeEnum.User;
    default:
      return undefined;
  }
};

export const isContentType = (type: unknown): type is Content => {
  return typeof type === 'string' && contentTypes.includes(type as unknown as Content);
};

export type RundownTab = {
  type: 'rundown' | 'rundowntemplate';
  id: string;
  title?: string;
  selectedDate?: string;
  search?: string;
};

export type StoryPitchTab = {
  type: 'pitch' | 'story' | 'create';
  id: string;
  title?: string;
  image?: string;
  restricted?: boolean;
  search?: string;
  archived?: boolean;
};

export type SpaceTab = {
  type: 'space';
  id: string;
  title?: string;
  search?: string;
};

export type ContentTab = RundownTab | StoryPitchTab | SpaceTab;

export type NavbarTabs = {
  contentTabs: ContentTab[];
  maxVisibleTabs: number;
};

export const initialValues: NavbarTabs = {
  contentTabs: [],
  maxVisibleTabs: 0,
};

const localNavbarTabs = localStorage.getItem('navbar_tabs');
const navbarTabsStorageAtom = atom<NavbarTabs>(
  localNavbarTabs ? (JSON.parse(localNavbarTabs) as NavbarTabs) : initialValues,
);

const navbarTabsAtom = atom(
  (get) => {
    const { contentTabs, maxVisibleTabs } = get(navbarTabsStorageAtom);
    return {
      maxVisibleTabs,
      contentTabs: contentTabs.filter((tab) => 'type' in tab && 'id' in tab),
    };
  },
  (_get, set, nextValue: NavbarTabs) => {
    set(navbarTabsStorageAtom, nextValue);
    localStorage.setItem('navbar_tabs', JSON.stringify(nextValue));
  },
);
export const useNavbarTabs = () => useAtom(navbarTabsAtom);

// contentTabs atom
const contentTabsAtom = focusAtom(navbarTabsAtom, (optic) => optic.prop('contentTabs'));
export const useContentTabs = () => useAtom(contentTabsAtom);

// currentTab atom
export const currentTab = atom<ContentTab | null>(null);
export const useCurrentTabValue = () => useAtomValue(currentTab);
export const useSetCurrentTab = () => useSetAtom(currentTab);

const currentTabId = atom((get) => get(currentTab)?.id ?? null);
export const useCurrentTabId = () => useAtom(currentTabId);
const currentTabType = atom((get) => get(currentTab)?.type);
export const useCurrentTabType = () => useAtom(currentTabType);
const currentTabTitle = atom((get) => get(currentTab)?.title ?? '');
export const useCurrentTabTitle = () => useAtom(currentTabTitle);
const currentTabArchived = atom((get) => {
  const cTab = get(currentTab);
  return cTab?.type === 'story' || cTab?.type === 'pitch' ? cTab.archived || false : false;
});
export const useCurrentTabArchived = () => useAtom(currentTabArchived);
const currentTabSelectedDate = atom((get) => {
  const currentTabValue = get(currentTab);

  if (currentTabValue?.type === 'rundown' && currentTabValue.selectedDate)
    return currentTabValue.selectedDate;

  return '';
});
export const useCurrentTabSelectedDate = () => useAtom(currentTabSelectedDate);

// max content tabs
const maxVisibleTabsAtom = focusAtom(navbarTabsAtom, (optic) => optic.prop('maxVisibleTabs'));
export const useMaxVisibleTabs = () => useAtom(maxVisibleTabsAtom);

// Only get the story / pitch content tabs
const storyTabsAtom = atom((get) => {
  const defaultValues = get(navbarTabsStorageAtom);
  const { contentTabs } = defaultValues;

  return contentTabs.filter(
    (tab) => tab.type === 'pitch' || tab.type === 'story',
  ) as StoryPitchTab[];
});
export const useStoryTabs = () => useAtom(storyTabsAtom);

const onDropTabItemAtom = atom(
  null,
  (get, set, nextValue: { itemIndex: number; dropIndex: number }) => {
    const { itemIndex, dropIndex } = nextValue;
    const contentTabs = get(contentTabsAtom);

    set(contentTabsAtom, moveArrayItem(contentTabs, itemIndex, dropIndex));
  },
);
export const useSetOnDropTabItem = () => useSetAtom(onDropTabItemAtom);
