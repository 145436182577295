import { useCallback, useEffect, useMemo, useState } from 'react';
import { css, Theme } from '@emotion/react';
import styled from '@emotion/styled';
import { TableCell, TableRow, Tooltip } from '@material-ui/core';

import { ReactComponent as Edit } from 'assets/icons/systemicons/edit.svg';
import { IconButton } from 'components/buttons';
import { StyledTextField } from 'components/mdfEditor/fields/text/styled';
import LWSelect from 'features/orderForm/components/LWSelect';
import { Box } from 'layouts/box/Box';
import {
  Alternative,
  PlatformAccountSettings,
  PlatformDTOSettings,
  ProviderDTO,
  ProviderEndPoint,
} from 'types/graphqlTypes';

import { EditEndPointDialogue } from '../dialogs/EditEndPointDialog';

import { AccountSelector } from './AccountSelector';

const CellStyle = ({ theme }: { theme: Theme }) => css`
  padding-block: 0;
  padding-inline: 4px;
  height: 40px;
  box-sizing: border-box;
  border-bottom: 1px solid ${theme.palette.dina.dividerLight};
  ${theme.typography.dina.listItemLabel};
  font-size: 12px;
`;
export const RowCell = styled(TableCell)`
  ${CellStyle};
  color: ${({ theme }) => theme.palette.dina.mediumEmphasis};
`;

interface RowProps {
  platformData: PlatformDTOSettings;
  providers: ProviderDTO[] | undefined;
  onUpdatePlatform: (updates: Partial<PlatformDTOSettings>) => void;
}

export function PlatformRow({ platformData, onUpdatePlatform, providers }: Readonly<RowProps>) {
  const {
    id,
    title,
    platformInfo: {
      provider,
      platform,
      platformIcon,
      accounts = [], // Default to an empty array if undefined
    } = {},
  } = platformData;

  const platformProvider: ProviderDTO | undefined = useMemo(() => {
    return providers?.filter((prv: ProviderDTO) => prv.id === provider?.id)[0];
  }, [provider?.id, providers]);

  const endpoints: ProviderEndPoint[] | undefined = useMemo(() => {
    return providers
      ?.filter((prv: ProviderDTO) => prv.id === provider?.id)
      .flatMap((p: ProviderDTO) => p.endPoints ?? []);
  }, [provider?.id, providers]);

  const EndPointOptions = [
    {
      id: 'None',
      label: 'None',
      value: 'None',
    },
    ...(endpoints
      ? endpoints.map((p) => ({
          id: p.id,
          label: p.url,
          value: p.url,
        }))
      : []),
  ];

  const [platformTitle, setPlatformTitle] = useState(title);
  const [pltfrmIcon, setPltfrmIcon] = useState(platformIcon);
  const [pltfrmPublishPoint, setPltfrmPublishPoint] = useState(
    provider?.publishEndpoint ? [provider.publishEndpoint] : [],
  );
  const [pltfrmPagePoint, setPltfrmPagePoint] = useState(
    provider?.pagesEndpoint ? [provider.pagesEndpoint] : [],
  );

  const [selectedAccounts, setSelectedAccounts] = useState(accounts ?? []);
  const [showEditPageEndpointDialog, setShowEditPageEndpointDialog] = useState(false);
  const [showEditPublishEndpointDialog, setShowEditPublishEndpointDialog] = useState(false);
  const [endpointOptions, setEndpointOptions] = useState(EndPointOptions);

  useEffect(() => {
    setSelectedAccounts(accounts);
  }, [accounts]);

  const selectedLabel = useMemo(() => {
    const selectedAcc = selectedAccounts ?? [];
    const accData = selectedAcc
      .reduce((accumulator, acc) => {
        if (acc.isActive && acc.accountTitle) {
          accumulator.push(acc.accountTitle); // Push only if accountTitle is defined
        }
        return accumulator;
      }, [] as string[])
      .join(', ');
    return accData || 'Create/Select Account';
  }, [selectedAccounts]);

  const toggleAccounts = useCallback(
    (acc: PlatformAccountSettings) => {
      const updatedAccounts = selectedAccounts.map((a) =>
        a.accountTitle === acc.accountTitle && a.isStaticAccount
          ? { ...a, isActive: !a.isActive }
          : a,
      );
      setSelectedAccounts(updatedAccounts);

      onUpdatePlatform({
        platformInfo: {
          ...platformData.platformInfo, // Spread existing platformInfo
          platform: platformData.platformInfo?.platform ?? '', // Ensure platform is defined
          accounts: updatedAccounts,
        },
      });
    },
    [onUpdatePlatform, platformData.platformInfo, selectedAccounts],
  );

  const openEditDialogue = useCallback((type: string) => {
    if (type == 'page') setShowEditPageEndpointDialog(true);
    else if (type == 'publish') setShowEditPublishEndpointDialog(true);
  }, []);

  return (
    <TableRow>
      <RowCell>
        <Tooltip title="Platform ID">
          <span>{id}</span>
        </Tooltip>
      </RowCell>
      <RowCell>
        <Tooltip title="Service">
          <span>{platform}</span>
        </Tooltip>
      </RowCell>
      <RowCell>
        <Tooltip title="Provider">
          <span>{provider?.id}</span>
        </Tooltip>
      </RowCell>
      <RowCell>
        <StyledTextField
          name="value"
          variant="filled"
          value={pltfrmIcon}
          onChange={(ev) => {
            setPltfrmIcon(ev.target.value);
            onUpdatePlatform({
              platformInfo: {
                ...platformData.platformInfo,
                platform: platformData.platformInfo?.platform ?? '',
                platformIcon: ev.target.value,
              },
            });
          }}
          placeholder="Icon"
        />
      </RowCell>
      <RowCell>
        <StyledTextField
          name="value"
          variant="filled"
          value={platformTitle}
          onChange={(ev) => {
            setPlatformTitle(ev.target.value);
            onUpdatePlatform({ title: ev.target.value });
          }}
          placeholder="Title"
        />
      </RowCell>
      <RowCell>
        <Box container alignItems="center" justifyContent="start">
          <IconButton
            usage="text"
            size={24}
            iconSize={20}
            onClick={() => openEditDialogue('page')}
            title="Edit page endpoint"
          >
            <Edit />
          </IconButton>
          <LWSelect
            value={pltfrmPagePoint[0]?.url}
            options={endpointOptions ? (endpointOptions as Alternative[]) : []}
            setValue={(val) => {
              const selectedOption = endpointOptions.find((option) => option.value === val);
              onUpdatePlatform({
                platformInfo: {
                  ...platformData.platformInfo,
                  platform: platformData.platformInfo?.platform ?? '',
                  provider: {
                    ...platformData.platformInfo?.provider,
                    id: provider?.id ?? 'None',
                    pagesEndpoint: { id: selectedOption?.id ?? '', url: val },
                  },
                },
              });
              setPltfrmPagePoint([{ id: selectedOption?.id ?? '', url: val }]);
            }}
          />
        </Box>
      </RowCell>
      <RowCell>
        <Box container alignItems="center" justifyContent="start">
          <IconButton
            usage="text"
            size={24}
            iconSize={20}
            onClick={() => openEditDialogue('publish')}
            title="Edit publish endpoint"
          >
            <Edit />
          </IconButton>
          <LWSelect
            value={pltfrmPublishPoint[0]?.url}
            options={endpointOptions ? (endpointOptions as Alternative[]) : []}
            setValue={(val) => {
              const selectedOption = endpointOptions.find((option) => option.value === val);
              onUpdatePlatform({
                platformInfo: {
                  ...platformData.platformInfo,
                  platform: platformData.platformInfo?.platform ?? '',
                  provider: {
                    ...platformData.platformInfo?.provider,
                    id: provider?.id ?? 'None',
                    publishEndpoint: { id: selectedOption?.id ?? '', url: val },
                  },
                },
              });
              setPltfrmPublishPoint([{ id: selectedOption?.id ?? '', url: val }]);
            }}
          />
        </Box>
      </RowCell>
      <RowCell>
        <AccountSelector
          selectedAccounts={selectedAccounts}
          toggleAccounts={toggleAccounts}
          selectedLabel={selectedLabel}
          platformData={platformData}
          onUpdatePlatform={onUpdatePlatform}
        />
      </RowCell>

      {showEditPageEndpointDialog && (
        <EditEndPointDialogue
          open={showEditPageEndpointDialog}
          setOpen={setShowEditPageEndpointDialog}
          type={'page-endpoint'}
          endPoints={pltfrmPagePoint}
          doUpdate={onUpdatePlatform}
          platform={platformData}
          setSelectedPoints={setPltfrmPagePoint}
          endpointOptions={endpointOptions}
          setEendpointOptions={setEndpointOptions}
          platformProvider={platformProvider}
        />
      )}

      {showEditPublishEndpointDialog && (
        <EditEndPointDialogue
          open={showEditPublishEndpointDialog}
          setOpen={setShowEditPublishEndpointDialog}
          type={'publish-endpoint'}
          endPoints={pltfrmPublishPoint}
          doUpdate={onUpdatePlatform}
          setSelectedPoints={setPltfrmPublishPoint}
          platform={platformData}
          endpointOptions={endpointOptions}
          setEendpointOptions={setEndpointOptions}
          platformProvider={platformProvider}
        />
      )}
    </TableRow>
  );
}
