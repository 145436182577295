import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { rundownStateLabel } from 'components/command/views/search-results/SearchItem';
import Text from 'components/text';
import { HStack } from 'layouts/box/Box';
import transientOptions from 'theme/helpers';

import { GroupInline } from '../Group';

const StatusWrapper = styled(HStack)`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export type StatusType = 'ready' | 'active' | 'notReady';

const statusToColor: Record<StatusType, string> = {
  ready: '#F24822',
  active: 'rgba(255, 152, 0, 1)',
  notReady: 'rgba(255, 255, 255, 0.7)',
};

const RundownStatus = styled(Text, transientOptions)<{
  status: 'ready' | 'active' | 'notReady';
}>`
  text-transform: uppercase;
  ${({ status }) => css`
    color: ${statusToColor[status]};
  `}
`;
function Status({ status }: Readonly<{ status: StatusType }>) {
  const rundownState = rundownStateLabel[status ?? 'notReady'] ?? 'Not Ready';

  return (
    <GroupInline label="Status">
      <StatusWrapper>
        <RundownStatus
          variant="caption"
          status={(status ?? 'notReady') as 'ready' | 'active' | 'notReady'}
        >
          {rundownState}
        </RundownStatus>
      </StatusWrapper>
    </GroupInline>
  );
}

export default Status;
