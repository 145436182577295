import { useCallback } from 'react';

import { ReactComponent as AddIcon } from 'assets/icons/systemicons/add_small_1px.svg';
import { IconButton } from 'components/buttons';
import Text from 'components/text';
import { focusEnclosingListItem } from 'utils/dom/ulView';

import { SectionId } from './types';

import { NavBarSectionWrapper } from './styled';

interface NavBarSectionItemProps {
  id: SectionId;
  label: string;
  addTooltip?: string;
  disableAddClick?: boolean;
  addItem?: (category: SectionId) => void;
}

/**
 * Renders the content of a section node in the `TreeView` used for the navigation bar at the left
 * side of the MDF schemas tab.
 * @param param The {@link NavBarSectionItemProps} specifying how to the content is to be rendered.
 */
export default function NavBarSectionItem({
  id,
  label,
  addTooltip,
  disableAddClick,
  addItem,
}: Readonly<NavBarSectionItemProps>) {
  const onAddClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      e.preventDefault();
      focusEnclosingListItem(e.currentTarget);
      addItem?.(id);
    },
    [addItem, id],
  );
  return (
    <NavBarSectionWrapper>
      <Text variant="overline" color="highEmphasis">
        {label}
      </Text>
      {addItem && (
        <IconButton
          title={`${addTooltip ?? 'Add'} (+)`}
          size={24}
          tabIndex={-1}
          usage="text"
          disabled={!!disableAddClick}
          onClick={onAddClick}
        >
          <AddIcon />
        </IconButton>
      )}
    </NavBarSectionWrapper>
  );
}
