import React, { useCallback } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import styled from '@emotion/styled';
import { flexRender, type Header, type TableMeta } from '@tanstack/react-table';

import { ReactComponent as Caret } from 'assets/icons/systemicons/arrows/arrow_double_unboxed.svg';
import { ReactComponent as SortDown } from 'assets/icons/systemicons/sort_down.svg';
import { ReactComponent as SortUp } from 'assets/icons/systemicons/sort_up.svg';
import { ReactComponent as DragHandle } from 'assets/icons/systemicons/unionDragHandle.svg';
import { ReactComponent as Hide } from 'assets/icons/systemicons/visibility_off.svg';
import { Resizer, SortHandle } from 'components/dataTable/styled';
import { TableHead as Head } from 'components/dataTable/Table';
import { SortDirection, SortIcon } from 'components/dataTable/TableHeadSortIcons';
import Tooltip from 'components/tooltip';
import { parseCellId } from 'features/grids/common/utils/cellUtils';
import { cellKeyboardShortcuts } from 'features/grids/common/utils/keyboardUtils';
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuSeparator,
  ContextMenuShortcut,
  ContextMenuTrigger,
} from 'lib/contextMenu';

import { ColumnFilterProps, DinaTable } from '../types';

import { isFixedSize } from './utils/columnUtils';
interface TableHeadProps<TData> {
  /** The table instance */
  header: Header<TData, unknown>;
  /** Optional column filters */
  ColumnFilters?: React.ComponentType<ColumnFilterProps<TData>>;
  meta?: TableMeta<TData>;
  table: DinaTable<TData>;
  staticColumnIndex: number;
}

const StyledDragHandle = styled(DragHandle)`
  flex-shrink: 0;
  display: none;

  cursor: grab;
  &:focus {
    outline: none;
  }
  &:active {
    cursor: grabbing;
  }

  &:hover path,
  &:active path {
    fill-opacity: 1;
    fill: ${({ theme }) => theme.palette.dina.highEmphasis};
  }
`;

/** Data table header with sort, group, and column filter support*/
export function TableHead<TData>({
  header,
  ColumnFilters,
  meta,
  table,
  staticColumnIndex,
}: Readonly<TableHeadProps<TData>>) {
  const column = header.column;
  const { enableKeyboardShortcuts } = table.options;
  const fixedSize = isFixedSize(column.columnDef.minSize, column.columnDef.maxSize);
  const { attributes, isDragging, listeners, setNodeRef, transform } = useSortable({
    id: column.id,
  });

  const widthStyles: React.CSSProperties = {
    minWidth: `max(calc(var(--${'header'}-${parseCellId(header?.id)}-size) * 1px), ${
      column.columnDef.minSize ?? 30
    }px)`,
    width: `calc(var(--${'header'}-${parseCellId(header?.id)}-size) * 1px)`,
    ...(!fixedSize && { flex: `var(--${'header'}-${parseCellId(header?.id)}-size) 0 auto` }),
    opacity: isDragging ? 0.4 : 1,
    position: 'relative',
    transform: CSS.Translate.toString(transform),
    transition: 'width transform 0.2s ease-in-out',
    whiteSpace: 'nowrap',
    zIndex: isDragging ? 1 : 0,
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTableCellElement>) => {
    cellKeyboardShortcuts({
      event,
      header,
      cellValue: column.columnDef.header,
      table,
    });
  };

  const handleToggleSort = useCallback(() => {
    if (column.getCanSort()) {
      column.toggleSorting();
    }
  }, [column]);

  const enableOrdering = column.columnDef.meta?.enableOrdering === true;

  return (
    <ContextMenu>
      <ContextMenuTrigger asChild>
        <Head
          style={widthStyles}
          colSpan={header.colSpan}
          onKeyDown={handleKeyDown}
          tabIndex={enableKeyboardShortcuts ? 0 : undefined}
          data-index={staticColumnIndex}
          ref={enableOrdering ? setNodeRef : undefined}
        >
          {enableOrdering && (
            <Tooltip title={isDragging ? '' : 'Drag to reorder'}>
              <StyledDragHandle className="dragHandle" {...attributes} {...listeners} />
            </Tooltip>
          )}
          {header.isPlaceholder ? null : (
            <>
              {column.getCanSort() ? (
                <SortHandle onClick={handleToggleSort}>
                  {flexRender(column.columnDef.header, header.getContext())}
                  {{
                    asc: <SortIcon direction={SortDirection.ASC} />,
                    desc: <SortIcon direction={SortDirection.DESC} />,
                  }[header.column.getIsSorted() as string] ?? null}
                </SortHandle>
              ) : (
                <>{flexRender(column.columnDef.header, header.getContext())}</>
              )}
              <ContextMenuContent>
                {column.getCanSort() ? (
                  <>
                    {column.getIsSorted() === 'asc' ? null : (
                      <ContextMenuItem onClick={() => column.toggleSorting(false)}>
                        Sort ascending
                        <ContextMenuShortcut>
                          <SortDown />
                        </ContextMenuShortcut>
                      </ContextMenuItem>
                    )}
                    {column.getIsSorted() === 'desc' ? null : (
                      <ContextMenuItem onClick={() => column.toggleSorting(true)}>
                        Sort descending
                        <ContextMenuShortcut>
                          <SortUp />
                        </ContextMenuShortcut>
                      </ContextMenuItem>
                    )}
                    {column.getIsSorted() === 'asc' || column.getIsSorted() === 'desc' ? (
                      <ContextMenuItem onClick={() => column.clearSorting()}>
                        Clear sorting
                        <ContextMenuShortcut>
                          <Caret />
                        </ContextMenuShortcut>
                      </ContextMenuItem>
                    ) : null}
                    <ContextMenuSeparator />
                  </>
                ) : null}
                {column.getCanGroup() ? (
                  <>
                    <ContextMenuItem onClick={() => column.toggleGrouping()}>
                      {column.getIsGrouped() ? 'Ungroup' : 'Group'} by this column
                      <ContextMenuShortcut>
                        <Caret />
                      </ContextMenuShortcut>
                    </ContextMenuItem>
                    <ContextMenuSeparator />
                  </>
                ) : null}
                {column.getCanHide() ? (
                  <ContextMenuItem onClick={() => column.toggleVisibility()}>
                    Hide column
                    <ContextMenuShortcut>
                      <Hide />
                    </ContextMenuShortcut>
                  </ContextMenuItem>
                ) : null}
              </ContextMenuContent>

              {ColumnFilters && header.column.getCanFilter() ? (
                <ColumnFilters column={header.column} meta={meta} />
              ) : null}
              {header.column.getCanResize() && (
                <Resizer
                  onDoubleClick={() => column.resetSize()}
                  onMouseDown={header.getResizeHandler()}
                  onTouchStart={header.getResizeHandler()}
                  className={`${column.getIsResizing() ? 'isResizing' : ''}`}
                />
              )}
            </>
          )}
        </Head>
      </ContextMenuTrigger>
    </ContextMenu>
  );
}
