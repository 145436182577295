import { useCallback, useMemo } from 'react';
import intersectionBy from 'lodash/intersectionBy';

import AssigneesComponent from 'components/assignees/Assignees';
import useShareStory from 'hooks/useShareStory';
import { HStack } from 'layouts/box/Box';
import { useAllMembers, useMembersDialog } from 'store';
import { AssignedMember, User } from 'types/members';

import { GroupInline } from '../Group';

function Assignees({
  storyId,
  assignedMembers,
}: Readonly<{ storyId: string; assignedMembers?: User[] }>) {
  const [allMembers] = useAllMembers();
  const [, setGetMembers] = useMembersDialog();
  const [, shareStoryWith] = useShareStory();

  const members = useMemo(
    () => intersectionBy(allMembers, assignedMembers ?? [], 'mId'),
    [allMembers, assignedMembers],
  );

  const openDialog = useCallback((): void => {
    setGetMembers({
      open: true,
      removeTooltipText: 'Remove',
      dialogTitle: 'Share with Users/Teams/Departments',
      subHeader: 'Assigned to this story:',
      startWith: members,
      showMessage: true,
      onOk: (newMembers: AssignedMember[], messageAssign: string): void => {
        if (newMembers) {
          void shareStoryWith(storyId, newMembers, members, null, messageAssign, true);
        }
      },
    });
  }, [members, setGetMembers, shareStoryWith, storyId]);

  return (
    <GroupInline label="Assignees">
      <HStack gap="4px">
        <AssigneesComponent members={members} size={24} maxAvatarToShow={2} onClick={openDialog} />
      </HStack>
    </GroupInline>
  );
}

export default Assignees;
