import { ReactComponent as CalendarOff } from 'assets/icons/systemicons/calendar_off.svg';
import { ReactComponent as CalendarOn } from 'assets/icons/systemicons/calendar_on.svg';
import { ReactComponent as TimeIcon } from 'assets/icons/systemicons/time.svg';
import { ReactComponent as WarningIconComponent } from 'assets/icons/systemicons/warning.svg';
import Checkbox from 'components/checkbox';
import Divider from 'components/divider';
import Infobar from 'components/infobar';
import Text from 'components/text';
import Tooltip from 'components/tooltip';
import useInstanceMdf from 'features/instance/hooks/useInstanceMdf';
import useDateTimeUtils from 'hooks/useDateTimeUtils';
import { Box } from 'layouts/box/Box';

import Picker from '../picker/Picker';

import { DatePicker, DateTime, FormControlLabel, ResetTextButton, TimePicker } from './styled';

const forcePublishTooltip =
  // eslint-disable-next-line max-len
  'Will publish the article on schedule time even if there is media that is not yet ready for publishing. When the media is ready, the article will automatically get updated.';

interface ScheduleOptionsProps {
  isCmsBlock: boolean;
  showExpiryDate: boolean;
  toggleShowExpiryDate: () => void;
  publishingTime?: string | null;
  newPublishingTime: string;
  handleDateChange: (date: Date) => void;
  isLinear: boolean;
  handleTimeChange: (time: Date) => void;
  expiryTime?: string | null;
  newExpiryTime?: string;
  handleExpiryDateChange: (date: Date) => void;
  handleExpiryTimeChange: (time: Date) => void;
  handleForcePublishClick: () => Promise<void>;
  forcePublish?: string;
  onResetDateTime: () => void;
  publishNow: boolean;
  isCmsRepublish: boolean;
  isApprovalOn: boolean;
  hasAccessToApprovalState: boolean;
}

function ScheduleOptions({
  isCmsBlock,
  showExpiryDate,
  toggleShowExpiryDate,
  publishingTime,
  newPublishingTime,
  handleDateChange,
  isLinear,
  handleTimeChange,
  expiryTime,
  newExpiryTime,
  handleExpiryDateChange,
  handleExpiryTimeChange,
  handleForcePublishClick,
  forcePublish,
  onResetDateTime,
  publishNow,
  isCmsRepublish,
  isApprovalOn,
  hasAccessToApprovalState,
}: ScheduleOptionsProps) {
  const { isThisMinute } = useDateTimeUtils();
  const { errorMap } = useInstanceMdf();
  const isErrorMapEmpty = Object.keys(errorMap).length === 0;
  return (
    <>
      {!isCmsRepublish && (
        <>
          <DateTime>
            <DatePicker>
              <Picker
                Icon={CalendarOff}
                SecondaryIcon={CalendarOn}
                displaySecondaryIcon={!!(publishingTime || !isThisMinute(newPublishingTime))}
                type="date"
                label="Publish Date"
                timeValue={newPublishingTime}
                onChange={handleDateChange}
                disableScheduleDateInPast
              />
            </DatePicker>
            <TimePicker>
              <Picker
                Icon={TimeIcon}
                type="time"
                label="Publish Time"
                timeValue={newPublishingTime}
                disable={isLinear}
                onChange={handleTimeChange}
              />
            </TimePicker>
          </DateTime>
          <ResetTextButton onClick={onResetDateTime} $disabled={publishNow}>
            Set to Current Time
          </ResetTextButton>
        </>
      )}
      {isErrorMapEmpty && isApprovalOn && !hasAccessToApprovalState && (
        <Box container margin="0.5rem 0">
          <Text variant="captionSmall" color="statusWarning">
            {publishNow
              ? 'A review is required. Once approved, the instance will be published.'
              : // eslint-disable-next-line max-len
                'A review is required. Once approved, the instance will be published at the scheduled time.'}
          </Text>
        </Box>
      )}
      <FormControlLabel
        control={<Checkbox selected={showExpiryDate} onClick={toggleShowExpiryDate} size={24} />}
        label="Add Expiry Date"
      />
      {showExpiryDate && (
        <DateTime>
          <DatePicker>
            <Picker
              Icon={CalendarOff}
              SecondaryIcon={CalendarOn}
              displaySecondaryIcon={!!(expiryTime || !isThisMinute(expiryTime ?? ''))}
              type="date"
              label="Expiry Date"
              timeValue={newExpiryTime}
              onChange={handleExpiryDateChange}
              blankDisplayValue=""
              disableScheduleDateInPast
            />
          </DatePicker>
          <TimePicker>
            <Picker
              Icon={TimeIcon}
              type="time"
              label="Expiry Time"
              timeValue={newExpiryTime}
              onChange={handleExpiryTimeChange}
              blankDisplayValue=""
            />
          </TimePicker>
        </DateTime>
      )}
      {isCmsBlock && (
        <>
          <Divider />
          <Tooltip title={forcePublishTooltip}>
            <FormControlLabel
              control={
                <Checkbox
                  selected={forcePublish === 'true'}
                  onClick={handleForcePublishClick}
                  size={24}
                />
              }
              label="Force Publish"
            />
          </Tooltip>
        </>
      )}
      {!isErrorMapEmpty && (
        <Box container>
          <Infobar
            background="warningBackground"
            border="warningBorder"
            icon={<WarningIconComponent />}
          >
            Some required Metadata fields are empty. You cannot publish until all required fields
            are completed.
          </Infobar>
        </Box>
      )}
    </>
  );
}

export default ScheduleOptions;
