import { useCallback, useMemo, useState } from 'react';

import { useGetBlocks } from 'api/mdfBlocks/useGetMdfBlocks';
import { MemberType } from 'types/graphqlTypes';

import Group, { Empty } from '../Group';

import Plan, { PlanSkeleton } from './Plan';

function Planning({ story }: Readonly<{ story: MemberType }>) {
  const [collapsed, setCollapsed] = useState(true);
  const { blocks, loading } = useGetBlocks(story.mId);

  const toggleCollapsed = useCallback(() => setCollapsed(!collapsed), [collapsed]);

  const moreCount = useMemo(() => Math.max(blocks.length - 3, 0), [blocks.length]);

  return (
    <Group
      label="Planning"
      collapsed={moreCount > 0 ? collapsed : undefined}
      toggleCollapsed={toggleCollapsed}
      moreCount={moreCount}
    >
      <>
        {loading ? (
          Array.from({ length: 3 }, (_, index) => <PlanSkeleton key={index} />)
        ) : blocks?.length === 0 ? (
          <Empty label="Planning" />
        ) : (
          ((collapsed && blocks.length > 3 ? blocks.slice(0, 3) : blocks) ?? []).map((block) => (
            <Plan key={block.mId} plan={block} />
          ))
        )}
      </>
    </Group>
  );
}

export default Planning;
