import type { ColumnDef, TableMeta } from '@tanstack/react-table';

import { DataTable } from 'features/grids/common/DataTable';
import { useDataTable } from 'features/grids/common/useDataTable';
import { ParsedOrderType } from 'features/mdf/useGetMdfColumns';
import { Order } from 'types/forms/forms';

import { useGetOrderGridColumns } from './hooks/useGetColumns';
import useGetTableMeta from './hooks/useMeta';
import { OrderGridParsedType } from './types';

interface GridType {
  data: ParsedOrderType[];
  columns: ColumnDef<ParsedOrderType>[];
  enableColumnFilters?: boolean;
  meta: TableMeta<Order>;
  storageKey?: string;
}

/** Order Grid, initializes the data table state and renders the table */
function OrderGrid({ data, columns, meta, storageKey }: Readonly<GridType>) {
  // ─── Initiate Table Instance w/ Data Columns And Meta ────────────────
  const { table } = useDataTable({
    data,
    columns,
    meta,
    storageKey,
    getRowId: (originalRow) => `${originalRow.mId}`,
  });

  return <DataTable table={table} />;
}

/** Prepares the OrderGrid with the necessary columns and meta.
 *
 * Due to the dynamic nature of the column implementation, this component
 * can not be combined with the OrderGrid component as it will
 * lead to unnecessary re-renders
 */
export default function OrderGridSetup({
  orders,
  mdfs,
  closeDialog,
  orderFormMap,
  storageKey = 'default_formId',
}: Readonly<OrderGridParsedType>) {
  // ─── Columns ─────────────────────────────────────────────────────────
  const columns = useGetOrderGridColumns(orders, mdfs, orderFormMap);

  // ─── Table Meta ──────────────────────────────────────────────────────
  const meta = useGetTableMeta({ orderFormMap, closeDialog });

  return <OrderGrid data={orders} columns={columns} meta={meta} storageKey={storageKey} />;
}
