/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { differenceBy } from 'lodash';
import PropTypes from 'prop-types';
import { useReadOnly, useSelected, useSlate } from 'slate-react';

import { ReactComponent as InfoIcon } from 'assets/icons/systemicons/appMenu/info_off.svg';
import { ReactComponent as EditGraphics } from 'assets/icons/systemicons/edit_graphics.svg';
import HourglassSrc from 'assets/icons/systemicons/hourglass.svg';
import PhotoSrc from 'assets/icons/systemicons/photo.svg';
import { ReactComponent as AddPlaceholder } from 'assets/icons/systemicons/placeholder_add.svg';
import { ReactComponent as DeletePlaceholder } from 'assets/icons/systemicons/placeholder_delete.svg';
import PlaySrc from 'assets/icons/systemicons/Play_WithCircleBackground_small.svg';
import QuestionMark from 'assets/icons/systemicons/question_mark_off.svg';
import defaultImage from 'assets/images/default/defaultThumbnail.png';
import { IconButton } from 'components/buttons';
import { ConfirmDialog } from 'components/dialogs/CommonDialogs';
import PlaceholderDialog from 'components/editor/components/placeholderDialog';
import removePlaceholder from 'components/editor/components/placeholderDialog/utils/removePlaceholder';
import SelectedElement from 'components/editor/components/selectedElement';
import { elementTypes } from 'components/editor/constants/types';
import { useEditorContext } from 'components/editor/EditorContext';
import { refreshSelection } from 'components/editor/utils';
import useGetAsset from 'hooks/useGetAsset';
import useGetAutomationTemplates from 'hooks/useGetAutomationTemplates';
import useGetSignedUrl from 'hooks/useGetSignedUrl';
import useOpenAssetInMimir from 'hooks/useOpenAssetInMimir';
import useOpenAssetInPilotEdge from 'hooks/useOpenAssetInPilotEdge';
import useReferenceStabilizer from 'hooks/useReferenceStabilizer';
import { getThumbnailKey } from 'utils/mediaKey';
import { isMimirAssetItem, openPreviewInProvider } from 'utils/openAssetInMimir';
import preventDefaultAndPropagation from 'utils/preventDefaultAndStopPropagation';
import { allowsGraphics, mediaTypes } from 'utils/rundownItemTypes';

import DragAndDrop from '../dragAndDrop';

import AutomationIcon from './components/automationIcon';
import DetailsDialog from './components/detailsDialog';
import MediaDialog from './components/mediaDialog';
import MediaDropZone from './components/mediaDropZone';
import PrimaryDropZone from './components/primaryDropZone';
import Select from './components/select';
import Title from './components/title';
import getInitialData from './utils/getInitialData';
import setAssetProperties from './utils/assetNormalizer';

import {
  BoxWrapper,
  Icon,
  OpenIcon,
  PlaceholdersWrapper,
  RootWrapper,
  Thumbnail,
  ThumbnailWrapper,
  TitleWrapper,
} from './styled';

const { PACKAGE, ADLIB, VOICE_OVER } = elementTypes;
const videoAutomationTypes = [PACKAGE, ADLIB, VOICE_OVER];

const ThumbnailImage = memo(({ thumbnailUrl }) => {
  const currentSrc = thumbnailUrl ?? defaultImage;

  const handleError = useCallback((e) => {
    e.target.src = defaultImage;
    return;
  }, []);

  return <Thumbnail key={currentSrc} src={currentSrc} alt="asset" onError={handleError} />;
});

ThumbnailImage.displayName = 'ThumbnailImage';

const getAssetIcon = (itemType, hasPlaceholder) => {
  if (!itemType) return QuestionMark;
  if (hasPlaceholder) return HourglassSrc;
  return itemType === mediaTypes.IMAGE ? PhotoSrc : PlaySrc;
};

// Memoized asset data calculation
const useAssetData = (assets) => {
  return useMemo(() => {
    const graphicsAsset = assets.find((asset) => asset.mediaType === mediaTypes.GRAPHICS);
    const hasGraphicsAsset = Boolean(graphicsAsset);
    const [clipAsset] = differenceBy(assets, graphicsAsset ? [graphicsAsset] : [], 'mAssetId');

    const { mId, mRefId, mAssetId } = clipAsset ?? {};
    const hasAssetId = Boolean(mAssetId);

    return {
      graphicsAsset,
      hasGraphicsAsset,
      clipAsset,
      hasClipAsset: Boolean(clipAsset),
    };
  }, [assets]);
};

const PrimaryAutomation = ({ attributes, children, element }) => {
  const editor = useSlate();
  const { update } = useEditorContext();
  const readOnly = useReadOnly();
  const isSelected = useSelected(element);

  const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);
  const [placeholderDialogOpen, setPlaceholderDialogOpen] = useState(false);
  const [mediaDialogOpen, setMediaDialogOpen] = useState(false);
  const [showDeletePlaceholder, setShowDeletePlaceholder] = useState(false);

  const stableElement = useReferenceStabilizer(element);

  const { data, type } = useMemo(() => stableElement, [stableElement]);

  const { assets = [], templateVariant } = data;

  // Use memoized asset data
  const { graphicsAsset, hasGraphicsAsset, clipAsset, hasClipAsset } = useAssetData(assets);

  const isVideoAutomation = useMemo(
    () => videoAutomationTypes.some((automation) => automation === type),
    [type],
  );

  const { templates } = useGetAutomationTemplates();

  const template = useMemo(
    () => templates?.find((item) => item.type === type && item.variant === templateVariant),
    [templateVariant, templates, type],
  );

  const { video = false, graphics = false, image = false } = template?.requires || {};

  const { mId, mRefId, mAssetId } = clipAsset ?? {};
  const hasAssetId = Boolean(mAssetId);

  const [mediaAssetData] = useGetAsset(mId, mRefId, false);
  const newAssetData = useMemo(() => setAssetProperties(mediaAssetData), [mediaAssetData]);

  const initialData = useMemo(() => {
    const assets = [];

    if (newAssetData) assets.push(newAssetData);
    if (graphicsAsset) assets.push(graphicsAsset);
    const syncedData = {
      ...data,
      assets,
    };

    return getInitialData(syncedData);
  }, [data, newAssetData, graphicsAsset]);

  const placeholder = useMemo(() => {
    return (newAssetData || clipAsset)?.mediaType.includes('placeholder');
  }, [clipAsset, newAssetData]);

  const hasPlaceholder = useMemo(() => Boolean(placeholder), [placeholder]);

  const memoizedKey = useMemo(() => mId && mRefId && getThumbnailKey(mId, mRefId), [mId, mRefId]);
  const { url: thumbSrc } = useGetSignedUrl(memoizedKey, false);

  const openAssetInMimir = useOpenAssetInMimir();
  const openAssetInPilotEdge = useOpenAssetInPilotEdge();

  const showCreatePlaceholderOption = useMemo(
    () => Boolean(video) || isVideoAutomation,
    [video, isVideoAutomation],
  );

  const showEditGraphicsOption = useMemo(
    () => Boolean(graphics) || allowsGraphics(type),
    [graphics, type],
  );

  const showOpenInMimir = useMemo(() => {
    if (!newAssetData) return;

    const elem = {
      data: {
        ...data,
        assets: [newAssetData],
      },
    };
    return isMimirAssetItem(elem);
  }, [data, newAssetData]);

  // Memoize all handlers
  const openDetailsDialog = useCallback(() => setDetailsDialogOpen(true), []);
  const closeDetailsDialog = useCallback(() => setDetailsDialogOpen(false), []);
  const openPlaceholderDialog = useCallback(() => setPlaceholderDialogOpen(true), []);
  const closePlaceholderDialog = useCallback(() => setPlaceholderDialogOpen(false), []);

  const handleOpenInMimir = useCallback(
    (asset) => {
      openAssetInMimir({ data: { assets: [{ ...asset }] } });
    },
    [openAssetInMimir],
  );

  const openMediaDialog = useCallback(
    (e) => {
      if (hasGraphicsAsset) return;
      preventDefaultAndPropagation(e);
      if (openPreviewInProvider(newAssetData?.mProvider)) {
        return handleOpenInMimir(newAssetData);
      }
      setMediaDialogOpen(true);
    },
    [newAssetData, handleOpenInMimir, hasGraphicsAsset],
  );

  const closeMediaDialog = useCallback(() => setMediaDialogOpen(false), []);

  const handleEditGraphics = useCallback(
    (e) => {
      if (!hasGraphicsAsset) return;
      preventDefaultAndPropagation(e);
      if (graphicsAsset?.mosobj) {
        openAssetInPilotEdge(graphicsAsset?.mosobj, {
          ...element,
          data: { ...element.data, provider: graphicsAsset.provider },
        });
      }
    },
    [hasGraphicsAsset, graphicsAsset, element, openAssetInPilotEdge],
  );

  const onBlur = useCallback(() => {
    refreshSelection(editor, element);
  }, [editor, element]);

  const openInMimirAction = useCallback(
    (e = undefined) => {
      if (e && readOnly) preventDefaultAndPropagation(e);

      handleOpenInMimir({
        ...newAssetData,
        mProvider: newAssetData.mMetaData.reduce(
          (accumulator, currentValue) =>
            currentValue.key === 'provider' ? currentValue.value : accumulator,
          '',
        ),
        mediaType: newAssetData?.mediaType ?? 'video/placeholder',
      });
    },
    [readOnly, hasAssetId, handleOpenInMimir, editor, element, initialData, newAssetData],
  );

  const onDeletePlaceholderConfirm = useCallback(() => {
    removePlaceholder(editor, initialData, placeholder, update);
    setShowDeletePlaceholder(false);
  }, [editor, initialData, placeholder, update]);

  const onDeletePlaceholder = useCallback(() => {
    setShowDeletePlaceholder(true);
  }, []);

  return (
    <div {...attributes} onBlur={onBlur}>
      <DragAndDrop element={element} hideHighlight>
        <SelectedElement element={element}>
          <PrimaryDropZone element={element}>
            <MediaDropZone
              element={element}
              canDropVideo={Boolean(video)}
              canDropImage={Boolean(image)}
              canDropGraphics={Boolean(graphics)}
            >
              {children}
              <RootWrapper contentEditable={false} $readOnly={readOnly}>
                <BoxWrapper $isSelected={isSelected} $readOnly={readOnly}>
                  <AutomationIcon type={type} />
                  {hasClipAsset && (
                    <ThumbnailWrapper
                      onClick={thumbSrc && !hasPlaceholder ? openMediaDialog : null}
                      aria-disabled={hasGraphicsAsset}
                      $showCursor={!!thumbSrc && !hasPlaceholder && !hasGraphicsAsset}
                    >
                      <ThumbnailImage thumbnailUrl={newAssetData?.mThumbnailUrl || thumbSrc} />
                      <Icon alt="play" src={getAssetIcon(newAssetData?.itemType)} />
                    </ThumbnailWrapper>
                  )}
                  <Select initialData={initialData} element={element} />
                  <TitleWrapper>
                    <Title {...{ initialData, type, templates, template }} />
                  </TitleWrapper>
                  <PlaceholdersWrapper>
                    {showCreatePlaceholderOption && (
                      <>
                        {hasPlaceholder && (
                          <IconButton
                            size={24}
                            iconSize={22}
                            usage="text"
                            onClick={onDeletePlaceholder}
                            title="Delete placeholder"
                            disableEnhancedIconOpacity
                          >
                            <DeletePlaceholder />
                          </IconButton>
                        )}
                        {!hasPlaceholder && !clipAsset && !hasGraphicsAsset && (
                          <IconButton
                            size={24}
                            iconSize={22}
                            usage="text"
                            onClick={openPlaceholderDialog}
                            onMouseEnter={(e) => {
                              if (readOnly) e.stopPropagation();
                            }}
                            title="Create placeholder"
                            disableEnhancedIconOpacity
                          >
                            <AddPlaceholder />
                          </IconButton>
                        )}
                      </>
                    )}
                    {showEditGraphicsOption && hasGraphicsAsset && (
                      <IconButton
                        size={24}
                        iconSize={22}
                        usage="text"
                        onClick={handleEditGraphics}
                        title="Edit graphics"
                        disableEnhancedIconOpacity
                        style={{ pointerEvents: 'all' }}
                      >
                        <EditGraphics />
                      </IconButton>
                    )}
                    <IconButton
                      title="Show details"
                      size={24}
                      iconSize={22}
                      usage="text"
                      onClick={openDetailsDialog}
                      disableEnhancedIconOpacity
                    >
                      <InfoIcon />
                    </IconButton>
                    {showOpenInMimir && (
                      <IconButton
                        title="Open Asset"
                        size={24}
                        iconSize={22}
                        usage="text"
                        onClick={openInMimirAction}
                        disableEnhancedIconOpacity
                        style={{ pointerEvents: 'all' }}
                      >
                        <OpenIcon />
                      </IconButton>
                    )}
                  </PlaceholdersWrapper>
                </BoxWrapper>
              </RootWrapper>
            </MediaDropZone>
          </PrimaryDropZone>
        </SelectedElement>
      </DragAndDrop>

      {detailsDialogOpen && (
        <DetailsDialog
          open={detailsDialogOpen}
          onClose={closeDetailsDialog}
          {...{ initialData, type, element }}
        />
      )}

      <ConfirmDialog
        open={showDeletePlaceholder}
        confirmLabel="Delete"
        onClick={onDeletePlaceholderConfirm}
        onClose={() => {
          setShowDeletePlaceholder(false);
        }}
        usage="danger"
        title="Remove placeholder"
        message="Are you sure you want to remove the placeholder?"
      />

      {placeholderDialogOpen && (
        <PlaceholderDialog
          open={placeholderDialogOpen}
          onClose={closePlaceholderDialog}
          {...{ element }}
        />
      )}

      {mediaDialogOpen && (
        <MediaDialog
          asset={newAssetData || clipAsset}
          open={mediaDialogOpen}
          onClose={closeMediaDialog}
        />
      )}
    </div>
  );
};

PrimaryAutomation.propTypes = {
  /** Attributes of SlateJS children */
  attributes: PropTypes.shape({}),
  /** SlateJS children */
  children: PropTypes.node,
  /** SlateJS element */
  element: PropTypes.shape({}),
};

PrimaryAutomation.defaultProps = {
  attributes: {},
  children: null,
  element: {
    type: elementTypes.CAMERA,
    children: [],
  },
};

export default memo(PrimaryAutomation);
